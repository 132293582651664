<div class="rs-location__item" [dir]="'languageDirection' | translate">
  <div class="rs-location__location" [ngClass]="!!text ? 'mt-3' : 'mt-0'">
    <mat-icon class="rs-location__icon" [svgIcon]="locationIcon"></mat-icon>
    <div>
      <span class="rs-location__text">{{ text }}</span>
      <div
        class="rs-location__description"
        [class.rs-location__not-available]="!description"
      >
        {{ description || ('MENUS.N/A' | translate) }}
      </div>
    </div>
  </div>
</div>
