import { Pipe, PipeTransform } from '@angular/core';

import { groupBy, uniqBy } from 'lodash';

import { Order } from 'src/app/profile/_interfaces/order.model';
import {
  OrderItemStatusTitle,
  OrderStatusColor,
} from '../_constants/order-item-status.constant';
import { OrderItemStatus } from '../_enums/order-item-status.enum';
import { Item } from '../_interfaces/item.model';
import { compareModifierSelections } from '../_utils/items.helper';
import { filterOrdersByText } from '../_utils/menus';

@Pipe({ name: 'orderItemStatusTitle' })
export class OrderItemStatusTitlePipe implements PipeTransform {
  transform(value: OrderItemStatus): string {
    return OrderItemStatusTitle[value] || '';
  }
}

@Pipe({ name: 'orderItemStatusColor' })
export class OrderItemStatusColorPipe implements PipeTransform {
  transform(value: OrderItemStatus): string {
    return (
      OrderStatusColor[value] || OrderStatusColor[OrderItemStatus.Received]
    );
  }
}

@Pipe({ name: 'orderItemPrice' })
export class OrderItemPricePipe implements PipeTransform {
  // todo: change any type on OrderItem type when it will available
  transform(value: any): number {
    return value?.prices[0]?.price?.amount || NaN;
  }
}

@Pipe({ name: 'getUniqItemsByName' })
export class GetUniqItemsByNamePipe implements PipeTransform {
  transform(items: Item[]): Item[] {
    if (!items?.length) {
      return [];
    }

    return uniqBy(items, 'name');
  }
}

@Pipe({ name: 'getUniqItemsByNameForListOfOrders' })
export class GetUniqItemsByNameForListOfOrdersPipe implements PipeTransform {
  transform(orders: Order[]): Item[] {
    if (!orders?.length) {
      return [];
    }
    const items = orders.map(({ items }) => uniqBy(items, 'name')).flat(2);

    return items;
  }
}

@Pipe({ name: 'getItemsNamesFromOrder' })
export class GetItemsNamesFromOrderPipe implements PipeTransform {
  transform(order: Order | null | undefined): string {
    if (!order?.items?.length) {
      return '';
    }

    // group items by their modifiers and count
    const { items } = order;
    let names: string[] = [];

    const grouppedItems = groupBy(items, 'name');
    Object.keys(grouppedItems).forEach(key => {
      const itemsInGroup = grouppedItems[key];
      let name = itemsInGroup?.[0]?.name ?? '';

      if (!name) {
        return;
      }
      name += ' x' + itemsInGroup.length;
      names.push(name);
    });

    return names.join(', ');
  }
}

@Pipe({ name: 'filterItemsByName' })
export class FilterItemsByNamePipe implements PipeTransform {
  transform(items: Item[], filter: string): Item[] {
    if (!items) {
      return [];
    } else if (!filter) {
      return items;
    } else {
      const caseIndependentFilter: string = filter.toLowerCase();

      return items.filter(({ name }) =>
        name?.toLowerCase().indexOf(caseIndependentFilter)
      );
    }
  }
}

@Pipe({ name: 'getItemsWithUniqModifiers' })
export class GetItemsWithUniqModifiersPipe implements PipeTransform {
  transform(items: Item[]): Item[] {
    const middlewareArrayWithUniqModifiers: Item[] = [];
    const itemIdsArray: string[] = items.map(({ itemId }: Item) => itemId);
    const uniqItemIdsSet: Set<string> = new Set<string>(itemIdsArray);
    const uniqItemIdsArray: string[] = Array.from(uniqItemIdsSet);

    uniqItemIdsArray.forEach((id: string) => {
      const filteredItemsByModifiers: Item[] = [];
      const itemsArrayById: Item[] = items.filter(
        ({ itemId }: Item) => itemId === id
      );

      itemsArrayById.forEach((item: Item, index: number) => {
        if (index === 0) {
          filteredItemsByModifiers.push(item);
        } else {
          let isModifiersAlreadyExist: boolean = false;

          filteredItemsByModifiers.forEach((existingItem: Item) => {
            if (
              compareModifierSelections(
                existingItem.metadata.modifierSelections,
                item.metadata.modifierSelections
              )
            ) {
              isModifiersAlreadyExist = true;
            }
          });

          if (!isModifiersAlreadyExist) {
            filteredItemsByModifiers.push(item);
          }
        }
      });

      middlewareArrayWithUniqModifiers.push(...filteredItemsByModifiers);
    });

    return middlewareArrayWithUniqModifiers;
  }
}

@Pipe({ standalone: true, name: 'filterOrdersBySearchText' })
export class FilterOrdersBySearchText implements PipeTransform {
  transform(
    orders: Order[] | null | undefined,
    filter: string | null | undefined
  ) {
    if (!orders?.length) {
      return [];
    }

    if (!filter) {
      return orders;
    }
    return filterOrdersByText(orders, filter);
  }
}
