import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { Store } from '@ngxs/store';

import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';
import { OrderState } from 'src/app/_shared/_ngxs/order.state';
import { SetPaymentMethod } from 'src/app/_shared/_ngxs/cart.actions';

import {
  PaymentMethods,
  PaymentMethodsType,
} from 'src/app/_shared/_enums/payment-methods.enum';
import { PaymentViewDescription } from 'src/app/_shared/_interfaces/payment.model';
import { Card } from 'src/app/profile/_interfaces/payment.model';
import { NewCardToAdd } from 'src/app/_shared/_models/common.interface';

import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';

@Component({
  selector: 'rs-payment-methods-options',
  templateUrl: './payment-methods-options.component.html',
  styleUrls: ['./payment-methods-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodsOptionsComponent {
  @Input() public availablePaymentMethods: PaymentViewDescription[] = [];
  @Input() public selectedPaymentMethod: PaymentMethods = PaymentMethods.card;
  @Input() public selectedCard!: Card | null | undefined;
  @Input() public paymentMethodsType!: PaymentMethodsType;

  public readonly isOpenTab$ = this.store.select(OrderState.isOpenTab);
  public readonly deliveryDataAddress$ = this.store.select(
    OrderDataState.orderAddress
  );

  public supportApplePay: boolean = false;

  public readonly paymentMethods = PaymentMethods;
  public readonly id = TEST_ID;

  @Output() private handleNewPaymentCard = new EventEmitter<NewCardToAdd>();
  @Output() public managePaymentCard = new EventEmitter<void>();
  @Output() public onPaymentMethodChange =
    new EventEmitter<MatButtonToggleChange>();

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: string,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store
  ) {}

  public emitNewPaymentCard(cardDetails: NewCardToAdd): void {
    this.handleNewPaymentCard.emit(cardDetails);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId) && (window as any).ApplePaySession) {
      this.supportApplePay = (window as any).ApplePaySession.canMakePayments();
      this.changeDetectorRef.markForCheck();
    }
  }

  public reactOnPaymentMethodChange(paymentMethod: PaymentMethods): void {
    if (this.paymentMethodsType === PaymentMethodsType.PaymentDetails) {
      this.store.dispatch(new SetPaymentMethod(paymentMethod));
    }
  }
}
