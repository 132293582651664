<mat-expansion-panel
  [expanded]="expanded"
  (closed)="closed.emit()"
  class="rs-authorisation-wrapper-panel"
>
  <mat-expansion-panel-header>
    <mat-panel-title class="fw-bold">
      <ng-content select="[rs-panel-header]"></ng-content>

      <span class="fw-normal ms-2">
        <ng-content select="[rs-panel-header-hint]"></ng-content>
      </span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <ng-content></ng-content>
</mat-expansion-panel>
