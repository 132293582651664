import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import {
  ItemDetails,
  SelectedItemsInBundle,
} from 'src/app/_shared/_interfaces/item.model';
import { ClearItemModifiers } from 'src/app/_shared/_ngxs/bundle-item.actions';
import { BundleItemState } from 'src/app/_shared/_ngxs/bundle-item.state';
import { ItemState } from 'src/app/_shared/_ngxs/item.state';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

@Component({
  selector: 'rs-combo',
  templateUrl: './combo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComboComponent implements AfterContentInit {
  @Input() bundleItemsControl: FormControl = new FormControl([]);
  @Input() metadata: any = {};
  @Input() itemAmount = 1;
  @Input() set selectedSizeId(value: string) {
    this.sizeId = value;
    this.bundleItemsControl?.reset([]);
    this.store.dispatch(new ClearItemModifiers());
    this.changeDetectorRef.detectChanges();
  }
  @Input() disableOrdering = false;

  @Output() bundleItemsSelected = new EventEmitter<SelectedItemsInBundle>();
  @Output() allItemsSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() allModifiersValid: EventEmitter<boolean> = new EventEmitter();

  public itemDetails$: Observable<ItemDetails | null> = this.store.select(
    ItemState.item
  );
  public bundleItemModifiers$ = this.store.select(
    BundleItemState.bundleItemModifiers
  );

  public sizeId!: string;
  private selectedAllItems: boolean = false;
  private modifiersValid: { [index: string]: boolean } = {};

  private destroyed$ = untilDestroyed();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store
  ) {}

  ngAfterContentInit(): void {
    this.store.dispatch(new ClearItemModifiers());
    this.itemDetails$
      .pipe(this.destroyed$())
      .subscribe(_ => this.checkIfSelectedAllBundleItems());
  }

  public updateBundleItemsSelected({
    bundleSectionId,
    items,
  }: SelectedItemsInBundle): void {
    const existingBundlesItems = this.bundleItemsControl.value.filter(
      ({
        bundleSectionId: existingBundleSectionId,
      }: {
        bundleItemId: string;
        bundleSectionId: string;
      }) => existingBundleSectionId !== bundleSectionId
    );
    existingBundlesItems.push(...items);

    this.bundleItemsControl.reset([...existingBundlesItems]);
    this.checkIfAllModifiersValid();
    this.checkIfSelectedAllBundleItems();
    this.bundleItemsSelected.emit({
      bundleSectionId,
      items,
    });
  }

  public checkIfSelectedAllBundleItems(): void {
    this.selectedAllItems =
      this.bundleItemsControl.value?.length === this.itemAmount;

    this.allItemsSelected.next(this.selectedAllItems);
  }

  public updateBundleItem(
    bundleItemId: string,
    bundleItemData: {
      bundleItemId: string;
      modifiers: {
        modifierId: string;
        modifierOptionIds: string[];
      }[];
    }
  ): void {
    const existingBundlesItems: {
      bundleItemId: string;
      modifiers: {
        modifierId: string;
        modifierOptionIds: string[];
      }[];
    }[] = this.bundleItemsControl.value;

    const currentItemIndex: number = existingBundlesItems.findIndex(
      ({ bundleItemId: itemId }) => bundleItemId === itemId
    );

    if (currentItemIndex < 0) {
      existingBundlesItems.push(bundleItemData);
    } else {
      existingBundlesItems[currentItemIndex] = bundleItemData;
    }

    this.bundleItemsControl.reset([...existingBundlesItems]);
    this.metadata.bundleItems = [...existingBundlesItems];
  }

  public reactOnModifiersValidity(
    id: string,
    isModifiersForItemValid: boolean
  ): void {
    this.modifiersValid[id] = isModifiersForItemValid;

    this.checkIfAllModifiersValid();
  }

  public checkIfAllModifiersValid(): void {
    const keys = Object.keys(this.modifiersValid);
    const existingBundlesItems: {
      bundleItemId: string;
    }[] = this.bundleItemsControl.value;

    let isValid: boolean = true;

    keys.forEach((_, index) => {
      if (
        existingBundlesItems.find(
          ({ bundleItemId }) => bundleItemId === keys[index]
        )
      ) {
        isValid = isValid && this.modifiersValid[keys[index]];
      }
    });

    this.allModifiersValid.next(isValid);
  }
}
