import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseForFirstSymbol',
})
export class UppercaseForFirstSymbolPipe implements PipeTransform {
  transform(value: string | null | undefined = '') {
    value = value || '';

    return value?.length ? value[0].toUpperCase() + value.substring(1) : value;
  }
}
