import { distance, Units } from '@turf/turf';

import { CommonIcon } from '../_enums/custom-icons.enum';
import { AddressForm } from '../_interfaces/address.model';
import { Address } from '../_models/common.interface';

export const getMarkerTemplate = (icon: CommonIcon) =>
  `<img style='height: 16px;' src='assets/icons/${icon}.svg'>`;

export const getAddressStringFromAddress = (
  address: Address | AddressForm
): string => {
  if (address) {
    const addressArray = [
      address.address1,
      address.address2,
      address.city,
      address.state,
      address.country,
      (address as Address).zipCode || (address as AddressForm).zipcode || '',
    ];
    let addressString = '';

    addressArray.forEach((addressPart, index) => {
      if (!!addressPart) {
        addressString += index === 0 ? '' : ', ';
        addressString += addressPart;
      }
    });

    return addressString;
  } else {
    return '';
  }
};

export const getDistance = (
  to: number[],
  from: number[],
  units = 'miles' as Units
) => {
  // to = [lng, lat]
  // from = [lng, lat]
  const options = { units };

  return distance(to, from, options);
};
