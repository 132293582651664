import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

@Component({
  selector: 'rs-chip-suggestion',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './chip-suggestion.component.html',
  styleUrls: ['./chip-suggestion.component.scss'],
})
export class RsChipSuggestionComponent {
  @Input() selected!: boolean;
  @Input() disabled!: boolean;
  @Input() disabledLook!: boolean;
  @Input() showActionIcon!: boolean;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  public readonly addIcon = CommonIcons.Add;
  public readonly checkmarkIcon = CommonIcons.Checkmark;

  constructor(private readonly svgIconService: SvgIconService) {
    this.registerActionButtons();
  }

  private registerActionButtons(): void {
    this.svgIconService.registerSvgIcons(
      [this.addIcon, this.checkmarkIcon],
      NEW_ICONS_DIRECTORY
    );
  }

  public toggle(): void {
    this.selected = !this.selected;

    this.change.next(this.selected);
  }
}
