import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, of, switchMap, tap, throwError } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import {
  ChangePassword,
  ForgetPassword,
  GetShadowUserToken,
  Login,
  Logout,
  RefreshToken,
  RemoveShadowAndLoggedUserBoth,
  SaveUserName,
  SendRecoveryLink,
  SetSessionData,
  SignUp,
} from './authentication.actions';
import {
  ClearProfileData,
  GetAddressBook,
  GetProfileInfo,
  InitializePaymentMethods,
} from './profile.actions';
import { ClearHistory, OpenLoginDialog } from './dialog.actions';
import { HideSpinner, ShowSpinner } from '../_components/spinner/spinner.state';
import { ClearOrderDataPartAfterLogout } from './order-data.actions';

import { CookieStorageKeys } from '../_enums/cookie-storage-keys.enum';
import { TokenData } from '../_interfaces/session.model';
import { EMPTY_TOKEN_DATA } from '../_constants/defaults.constants';

import { copy, getVenueName } from '../_utils/common';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { NotificationService } from '../_services/notification.service';
import { CookieEngine } from 'src/app/_services/cookie.service';

export interface SessionStateModel {
  user: any;
  shadowUsername: any;
  name: string;
  accessToken: string;
  refreshToken: string;
  email: string;
  expiresIn: number;
  isShadowUser: boolean;
  isLoggedIn: boolean;
  isTokenActive: boolean;
  expiresAt: number;
}

const DEFAULT_STATE: SessionStateModel = {
  user: null,
  shadowUsername: null,
  name: '',
  accessToken: '',
  refreshToken: '',
  email: '',
  expiresIn: 0,
  isShadowUser: false,
  isLoggedIn: false,
  isTokenActive: false,
  expiresAt: +new Date(),
};

@State<SessionStateModel>({
  name: 'session',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class SessionState {
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private matDialog: MatDialog,
    private readonly ngZone: NgZone,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly cookieEngine: CookieEngine
  ) {}

  @Selector()
  static sessionData(state: SessionStateModel): any {
    return state;
  }

  @Selector()
  static shadowUserInfo({ name, email }: SessionStateModel): any {
    return { name, email };
  }

  @Selector()
  static shadowUsername({ shadowUsername }: SessionStateModel): any {
    return shadowUsername;
  }

  @Selector()
  static userName({ name }: SessionStateModel): string {
    return name;
  }

  @Selector()
  static accessToken({ accessToken }: SessionStateModel): string {
    return accessToken;
  }

  @Selector()
  static email({ email }: SessionStateModel): string {
    return email;
  }

  @Selector()
  static isLoggedIn({ isLoggedIn }: SessionStateModel): boolean {
    return isLoggedIn;
  }

  @Selector()
  static isShadowUser({ isShadowUser }: SessionStateModel): boolean {
    return isShadowUser;
  }

  @Selector()
  static isTokenActive({ isTokenActive }: SessionStateModel): boolean {
    return isTokenActive;
  }

  @Selector()
  static expiresAt({ expiresAt }: SessionStateModel): number {
    return expiresAt;
  }

  @Selector()
  static refreshToken({ refreshToken }: SessionStateModel): string {
    return refreshToken;
  }

  @Action(SendRecoveryLink)
  sendRecoveryLink(
    { dispatch }: StateContext<SessionStateModel>,
    { email, venueId }: SendRecoveryLink
  ) {
    dispatch(new ShowSpinner());

    return this.authenticationService
      .recoveryPassword(email, venueId)
      .pipe(finalize(() => dispatch(new HideSpinner())));
  }

  @Action(Login)
  login(
    { patchState, dispatch }: StateContext<SessionStateModel>,
    { email, password }: Login
  ) {
    dispatch(new ShowSpinner());

    return this.authenticationService.login(email, password).pipe(
      tap((tokenData: TokenData) => {
        const expiresAt = +new Date() + tokenData.expiresIn * 1000;

        patchState({
          email,
          ...tokenData,
          isLoggedIn: true,
          isTokenActive: true,
          isShadowUser: false,
          expiresAt,
        });

        this.cookieEngine.setItem(
          CookieStorageKeys.previouslySavedEmail,
          email
        );

        dispatch([
          new HideSpinner(),
          new GetProfileInfo(),
          new ClearOrderDataPartAfterLogout(),
          new InitializePaymentMethods(),
          new GetAddressBook(),
        ]);
      }),
      catchError(err => {
        dispatch(new Logout());

        throw err;
      })
    );
  }

  @Action(SignUp)
  public signUp(
    { dispatch }: StateContext<SessionStateModel>,
    {
      firstName,
      lastName,
      email,
      phone,
      password,
      allowUnconfirmedLogin,
    }: SignUp
  ) {
    dispatch(new ShowSpinner());

    return this.authenticationService
      .signUp(
        firstName,
        lastName,
        email,
        phone,
        password,
        allowUnconfirmedLogin
      )
      .pipe(
        switchMap(response => {
          if (response.created) {
            return dispatch(
              new Login(
                email || phone?.countryCode + phone?.phoneNumber,
                password
              )
            );
          } else {
            this.notificationService.showError(
              this.translateService.instant('ERRORS.check_your_email')
            );

            return of();
          }
        }),
        tap({
          complete: () => {
            dispatch(new HideSpinner());
            this.matDialog.closeAll();
            dispatch(new ClearHistory());
          },
        })
      );
  }

  @Action(RefreshToken)
  public refreshToken({
    getState,
    patchState,
    dispatch,
  }: StateContext<SessionStateModel>) {
    const refreshToken = getState().refreshToken;
    if (refreshToken) {
      return this.authenticationService.refreshToken(refreshToken).pipe(
        tap((tokenData: TokenData) => {
          const expiresAt = +new Date() + tokenData.expiresIn * 1000;

          patchState({
            ...tokenData,
            isLoggedIn: true,
            isTokenActive: true,
            expiresAt,
          });
        }),
        catchError(error => {
          dispatch([new OpenLoginDialog(true), new Logout()]);
          throw error;
        })
      );
    } else {
      dispatch(new GetShadowUserToken());
    }
    return of();
  }

  @Action(Logout)
  logout({ patchState, dispatch }: StateContext<SessionStateModel>) {
    dispatch([new ClearOrderDataPartAfterLogout(), new ClearProfileData()]);

    patchState({
      ...EMPTY_TOKEN_DATA,
      email: '',
      isLoggedIn: false,
      isTokenActive: false,
    });

    this.redirectWhenLogout();
  }

  // this action is similar to Logout
  // but in case for Logout use we must save isShadowUser
  // otherwise flow is broke
  @Action(RemoveShadowAndLoggedUserBoth)
  resetTokenData({ patchState, dispatch }: StateContext<SessionStateModel>) {
    patchState({
      ...EMPTY_TOKEN_DATA,
      email: '',
      isShadowUser: false,
      isLoggedIn: false,
      isTokenActive: false,
    });

    dispatch([new ClearOrderDataPartAfterLogout(), new ClearProfileData()]);
  }

  private redirectWhenLogout(): void {
    if (this.document.location.href.indexOf('profile') > -1) {
      this.ngZone.run(() => {
        const venueName = getVenueName(this.document.location.href);
        this.router.navigate([`${venueName}/home`]);
      });
    }
  }

  @Action(SaveUserName)
  setShadowUserData(
    { patchState }: StateContext<SessionStateModel>,
    { name }: SaveUserName
  ) {
    patchState({ name });
  }

  @Action(SetSessionData)
  setSessionStateData(
    { patchState }: StateContext<SessionStateModel>,
    { SessionState }: SetSessionData
  ) {
    patchState(SessionState);
  }

  @Action(GetShadowUserToken)
  getShadowUserToken(
    { getState, patchState, dispatch }: StateContext<SessionStateModel>,
    { shadowUserUsername }: GetShadowUserToken
  ) {
    shadowUserUsername?.email == '' && delete shadowUserUsername.email;
    shadowUserUsername?.phone?.phoneNumber == '' &&
      delete shadowUserUsername.phone;

    dispatch(new ShowSpinner());

    return this.authenticationService
      .fetchShadowUserTokenData(shadowUserUsername)
      .pipe(
        tap((tokenData: TokenData) => {
          dispatch([new HideSpinner()]);

          let state = copy(getState()) as SessionStateModel;
          const expiresAt = +new Date() + tokenData.expiresIn * 1000;

          shadowUserUsername?.email && (state.email = shadowUserUsername.email);
          shadowUserUsername?.phone &&
            (state.shadowUsername = shadowUserUsername.phone);

          state = {
            ...state,
            ...tokenData,
            isShadowUser: true,
            isLoggedIn: false,
            isTokenActive: true,
            expiresAt,
          };

          patchState({ ...state });
        })
      );
  }

  @Action(ChangePassword)
  changePassword(
    {}: StateContext<SessionStateModel>,
    { data }: ChangePassword
  ) {
    return this.authenticationService.changePassword(data);
  }

  @Action(ForgetPassword)
  forgetPassword(_: StateContext<SessionStateModel>, { data }: ForgetPassword) {
    return this.authenticationService.confirmResetPassword(data).pipe(
      tap(() => {
        this.ngZone.run(() => {
          const venueName = getVenueName(this.document.location.href);
          this.router.navigate([`${venueName}/home`], {
            queryParams: { login: 'login' },
          });
        });
      }),
      catchError(error => {
        this.notificationService.showError(
          this.translateService.instant('Token is expired')
        );
        return throwError(error);
      })
    );
  }
}
