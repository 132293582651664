<section
  class="rs-error-info"
  [ngClass]="{ 'rs-error-info--with-hide-button': showClose }"
>
  <button
    mat-icon-button
    class="rs-error-info__hide-button"
    (click)="close()"
    *ngIf="showClose"
  >
    <mat-icon>close</mat-icon>
  </button>

  <ng-content></ng-content>
</section>
