import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

import moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

import { SessionStorageKeys } from '../_enums/session-storage-keys.enum';

const DEFAULT_DATE_FORMAT = 'dddd, MMM D YYYY';
const DEFAULT_TIME_FORMAT = 'hh:mm A';
const DEFAULT_TIMEZONE = 'America/Los_Angeles';

@Pipe({ name: 'rsFormateDateTimeString' })
export class FormateDateTimeStringPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}
  transform(
    value: string,
    dateFormat: string = DEFAULT_DATE_FORMAT,
    timeFormat = DEFAULT_TIME_FORMAT,
    at: string = ''
  ): string {
    const titleCasePipe = new TitleCasePipe();
    const language =
      this.translateService.currentLang ||
      localStorage.getItem(SessionStorageKeys.language) ||
      '';

    const date = titleCasePipe.transform(
      moment(value).locale(language).format(dateFormat)
    );
    const time = moment(value).locale(language).format(timeFormat);

    return `${date} ${at || ','} ${time}`;
  }
}

@Pipe({ name: 'transformDeliveryDate' })
export class TransformDeliveryDatePipe implements PipeTransform {
  public transform(
    date: string | undefined,
    venueTimezone: string = DEFAULT_TIMEZONE
  ): string {
    if (!date) {
      return '';
    }

    return (
      moment(date).calendar(null, {
        sameDay: '[Today at] ',
        nextDay: '[Tomorrow at] ',
        nextWeek: 'dddd [at] ',
        lastDay: '[Yesterday at] ',
        lastWeek: '[Last] dddd [at] ',
        sameElse: 'DD/MM/YYYY [at] ',
      }) +
      moment
        .utc(date)
        .tz(venueTimezone || DEFAULT_TIMEZONE)
        .format('hh:mm A')
    );
  }
}
