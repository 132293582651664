import { Currency } from '../_shared/_models/currency.model';

export const getRandomSign = (decimal: number): number =>
  parseInt(String(decimal)) % 4 ? 1 : -1;

export const getRandomDate = (): string =>
  String(new Date(+new Date() - Math.random() * +new Date()));

export const getBalance = (
  balance: number = Math.random() * 100 * 100
): Currency => ({
  type: 'currency',
  symbol: 'USD',
  decimal: parseInt(String(balance)),
});

export const getRandomNumber = (
  exponentPart: number = 100,
  fixedPart: number = 2
): number => parseInt((Math.random() * exponentPart).toFixed(fixedPart));

export const getRandomBoolean = (): boolean =>
  !!(parseInt(String(getRandomNumber(100, 0))) % 2);

export const getRandomText = (length: number): string => {
  if (!length) {
    return '';
  }

  let text: string = '';

  for (; text.length < length; text += Math.random().toString(36).substr(2));
  return text.substr(0, length);
};

export const getRandomTextParagraph = (maxWordsCount: number = 30): string => {
  const getWord = (): string => getRandomText(getRandomNumber(10, 0));
  const wordsNumber: number = getRandomNumber(maxWordsCount, 0);
  let review: string = '';

  for (let i = 0; i < wordsNumber; i++) {
    review += ' ';
    review += getWord();
  }

  return review;
};
