import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Store } from '@ngxs/store';

import {
  BundleInformation,
  BundleItem,
  BundleItemSizeAttributes,
  ComboAttributes,
  SelectedItemsInBundle,
} from 'src/app/_shared/_interfaces/item.model';
import {
  GetBundleItemModifiers,
  RemoveBundleItemModifier,
} from 'src/app/_shared/_ngxs/bundle-item.actions';

@Component({
  selector: 'rs-combo-item',
  templateUrl: './combo-item.component.html',
  styleUrls: ['../_styles/item-details.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComboItemComponent {
  @Input() items!: BundleItem[];
  @Input() bundleItemSizeAttributes!: BundleItemSizeAttributes[];
  @Input() comboAttributes!: ComboAttributes;
  @Input() addedBundleItems: BundleInformation[] = [];
  @Input() disableOrdering = false;

  @Output() bundleItemsSelected: EventEmitter<SelectedItemsInBundle> =
    new EventEmitter();

  constructor(private readonly store: Store) {}

  public reactOnItemSelected(item: BundleItem): void {
    this.getModifiersForItem(item);

    this.addedBundleItems.push({
      bundleItemId: item.id,
      bundleSectionId: '',
    });

    this.bundleItemsSelected.next({
      bundleSectionId: '',
      items: this.addedBundleItems,
    });
  }

  public increaseQuantity(item: BundleItem): void {
    this.addedBundleItems.push({
      bundleItemId: item.id,
      bundleSectionId: '',
    });

    this.bundleItemsSelected.next({
      bundleSectionId: '',
      items: this.addedBundleItems,
    });

    this.getModifiersForItem(item);
  }

  public getModifiersForItem(item: BundleItem): void {
    this.store.dispatch(new GetBundleItemModifiers(item, ''));
  }

  public decreaseModifiersForItem(item: BundleItem): void {
    this.store.dispatch(new RemoveBundleItemModifier(item, ''));
  }

  public decreaseQuantity(item: BundleItem): void {
    const index: number = this.addedBundleItems.findIndex(
      ({ bundleItemId }) => bundleItemId === item.id
    );

    if (index > -1) {
      this.addedBundleItems.splice(index, 1);
    }

    this.bundleItemsSelected.next({
      bundleSectionId: '',
      items: this.addedBundleItems,
    });

    this.decreaseModifiersForItem(item);
  }
}
