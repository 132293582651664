import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';

import { RsPipesModule } from '../../_modules/pipes.module';
import { DirectivesModule } from '../../_directives/directives.module';
import { SharedModule } from '../../_modules/shared.module';
import { EXPIRATION_DATE_MASK_CONFIG } from '../../_constants/card.constants';
import { PaymentValidator } from '../../_validators/payment.validation';

@Component({
  selector: 'rs-payment-card-form',
  standalone: true,
  imports: [SharedModule, RsPipesModule, DirectivesModule],
  templateUrl: 'payment-card-form.component.html',
  styleUrls: ['payment-card-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardFormComponent {
  @Input() cardForm: FormGroup | undefined = undefined;

  public readonly expirationDateMaskConfig = EXPIRATION_DATE_MASK_CONFIG;
  public readonly expirationDateValidator: ValidatorFn[] = [
    PaymentValidator.expirationDateValidator,
  ];
  public readonly cardExpirationDateErrors = {
    expired: 'VALIDATION.expiration',
    minLength: 'VALIDATION.length',
  };
}
