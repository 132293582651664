<div
  class="rs-dialog-header rs-dialog-header--default"
  [ngClass]="{
    'rs-dialog-header--success': type === DialogTypeOptions.Success,
    'rs-dialog-header--error': type === DialogTypeOptions.Error,
    'rs-dialog-header--with-bottom-border': dialogHeaderHasBorder
  }"
>
  <div
    class="rs-dialog-header__title d-flex justify-content-start align-items-center"
    #rsDialogHeader
    [ngClass]="{ 'd-none': !rsDialogHeader?.children?.length }"
  >
    <ng-content></ng-content>
  </div>
</div>
