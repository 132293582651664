import { AbstractControl } from '@angular/forms';

import { PaymentCardPattern } from 'src/app/profile/_interfaces/payment.model';
import {
  getMaxCardNumberLength,
  getMaxCVVLength,
  getMinCardNumberLength,
  getMinCVVLength,
  getPossiblePaymentCardPatterns,
  isSatisfiedLuhnAlgorithm,
  isSecurityCodeRequired,
  removeSpecialCharactersFromCardNumber,
} from '../_utils/payment-card';

export class PaymentValidator {
  static clearCardNumber: string;
  static possibleCardPatterns: PaymentCardPattern[];
  static valid: boolean;

  static cardNumberValidator(
    control?: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control!.value) {
      const clearCardNumber: string = removeSpecialCharactersFromCardNumber(
        control!.value
      );
      const possibleCardPatterns =
        getPossiblePaymentCardPatterns(clearCardNumber);

      PaymentValidator.clearCardNumber = clearCardNumber;
      PaymentValidator.possibleCardPatterns = possibleCardPatterns;

      if (!possibleCardPatterns.length) {
        return { wrongType: true };
      }

      const isCardSatisfiedLuhnAlgorithm =
        isSatisfiedLuhnAlgorithm(clearCardNumber);
      PaymentValidator.valid = isCardSatisfiedLuhnAlgorithm;

      if (!isCardSatisfiedLuhnAlgorithm) {
        return { invalid: true };
      }

      const cardNumberLength = clearCardNumber.length;

      if (cardNumberLength < getMinCardNumberLength(possibleCardPatterns)) {
        return { minLength: true };
      }

      if (cardNumberLength > getMaxCardNumberLength(possibleCardPatterns)) {
        return { maxLength: true };
      }
    }

    return null;
  }

  static expirationDateValidator(
    control?: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control!.value && control?.value.length < 5) {
      return { minLength: true };
    }

    if (control!.value && PaymentValidator.isCardExpired(control!.value)) {
      return { expired: true };
    }

    return null;
  }

  static isCardExpired(date: string): boolean {
    const [inputMonth, inputYear]: string[] = date.split('/');
    let month: number;
    let year: number;

    if (inputYear?.length === 2 && inputMonth?.length === 2) {
      const now = new Date();
      const nowMonth = now.getMonth();
      const nowYear = now.getFullYear();

      month = Number(inputMonth);
      year = Number('20' + inputYear);

      return year < nowYear || (year === nowYear && month <= nowMonth);
    }

    return false;
  }

  static securityCodeRequired(
    control?: AbstractControl
  ): { [key: string]: boolean } | null {
    if (isSecurityCodeRequired(PaymentValidator.possibleCardPatterns)) {
      return control!.value ? null : { required: true };
    }

    return null;
  }

  static cvvLengthValidator(
    control?: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control!.value) {
      const cvvLength: number = control!.value.length;
      const minLength: number = getMinCVVLength(
        PaymentValidator.possibleCardPatterns
      );
      const maxLength: number = getMaxCVVLength(
        PaymentValidator.possibleCardPatterns
      );

      if (cvvLength < minLength) {
        return { minLength: true };
      }

      if (cvvLength > maxLength) {
        return { maxLength: true };
      }
    }

    return null;
  }
}
