import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable, first, map } from 'rxjs';
import * as moment from 'moment';

import { ScheduleRange } from 'src/app/_shared/_interfaces/date-settings';
import { LocalizedDatePipe } from 'src/app/_shared/_pipes/locolized-date.pipe';
import { EasternArabicNumeralsPipe } from 'src/app/_shared/_pipes/eastern-arabic-numerals.pipe';

@Pipe({
  name: 'rsScheduleOrderDayTitle',
  pure: false,
  standalone: true,
})
export class RsScheduleOrderDayTitlePipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly easternArabicNumeralsPipe: EasternArabicNumeralsPipe
  ) {}

  public transform(day: ScheduleRange): Observable<string> {
    const pattern = 'EEE';
    const localizedDatePipe = new LocalizedDatePipe(
      this.translateService,
      this.easternArabicNumeralsPipe
    );
    const today = moment();

    return this.translateService.get('TIME.today').pipe(
      first(),
      map(translation => {
        return today.get('date') === day.day.get('date')
          ? translation
          : localizedDatePipe.transform(day.day, pattern);
      })
    );
  }
}

@Pipe({ name: 'rsIsScheduleDaySelected', standalone: true })
export class RsIsScheduleDaySelectedPipe implements PipeTransform {
  public transform(day: ScheduleRange, selectedDay: ScheduleRange): boolean {
    return moment(selectedDay.day).get('date') === day.day.get('date');
  }
}
