import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isPlatformServer } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  first,
  firstValueFrom,
} from 'rxjs';

import { SharedModule } from '../shared.module';
import { OrderTypeToggleComponent } from './order-type-toggle/order-type-toggle.component';
import { MapComponent } from 'src/app/_shared/_components/map/map.component';
import { OrderTypeInfoComponent } from './order-type-info/order-type-info.component';
import { CarItemComponent } from './checkout-order-information-popup/select-car/car-item/car-item.component';
import { CarFormComponent } from 'src/app/_shared/_components/car-form/car-form.component';
import { CompleteAddressPopupComponent } from '../../_components/complete-address-popup/complete-address-popup.component';
import { InitialAddressPopupComponent } from '../../_components/initial-address-popup/initial-address-popup.component';

import { IsCarValidPipe } from '../../_pipes/car.pipe';

import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';
import { VenueOrderSettingsState } from 'src/app/_shared/_ngxs/venue-order-settings.state';
import {
  UpdateOrderCar,
  UpdateOrderData,
  UpdateOrderType,
} from 'src/app/_shared/_ngxs/order-data.actions';
import { SetIsSearchingDriver } from 'src/app/_shared/_ngxs/cart.actions';
import {
  OpenCompleteAddressDialog,
  OpenEditCheckoutInfoDialog,
} from 'src/app/_shared/_ngxs/dialog.actions';

import { DeliveryMethod } from 'src/app/_shared/_enums/order.enum';
import { MatDialogId } from '../../_enums/mat-dialog-id.enum';
import { Car } from 'src/app/profile/_interfaces/car.model';
import { CompleteAddressPopupData } from './complete-address-popup.models';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { Phone } from 'src/app/_shared/_models/common.interface';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { isCarValid } from 'src/app/_shared/_utils/car.utils';
import { isAddressFilled } from '../../_utils/address.helper';

@Component({
  selector: 'rs-order-information',
  standalone: true,
  imports: [
    SharedModule,
    OrderTypeToggleComponent,
    MapComponent,
    OrderTypeInfoComponent,
    CarItemComponent,
    CarFormComponent,
    IsCarValidPipe,
    CompleteAddressPopupComponent,
    InitialAddressPopupComponent,
  ],
  templateUrl: './order-information.component.html',
  styleUrls: ['./order-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderInformationComponent {
  @Input() isCheckout!: boolean;
  @Input() orderMethod!: DeliveryMethod;

  public readonly orderAddress$: Observable<UserProfileAddress | undefined> =
    this.store.select(OrderDataState.orderAddress);
  public readonly orderPhone$: Observable<Phone | undefined> =
    this.store.select(OrderDataState.orderPhone);
  public readonly orderCar$: Observable<Car> = this.store.select(
    OrderDataState.orderCar
  );
  public readonly isThereAnyAvailableOrderMethods$ = this.store.select(
    VenueOrderSettingsState.isThereAnyAvailableOrderMethod
  );

  public car!: Car;
  public carForm!: FormGroup;

  public readonly DeliveryMethod = DeliveryMethod;
  private readonly destroyed$ = untilDestroyed();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly matDialog: MatDialog,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.initializeSubscriptions();
    this.initializeCarForm();
  }

  public ngOnChanges(): void {
    this.checkIfInformationIsFilledForDelivery();
  }

  private checkIfInformationIsFilledForDelivery(): void {
    if (this.orderMethod !== DeliveryMethod.Delivery) {
      return;
    }

    const address: UserProfileAddress | undefined = this.store.selectSnapshot(
      OrderDataState.orderAddress
    );
    const phone: Phone | undefined = this.store.selectSnapshot(
      OrderDataState.orderPhone
    );
    const isAddressCompleted: boolean = address
      ? isAddressFilled(address)
      : false;
    const isPhoneCompleted: boolean = !!phone?.phoneNumber;

    (!isAddressCompleted || !isPhoneCompleted) &&
      this.openFillInformationDialog(address);
  }

  private async openFillInformationDialog(
    address?: UserProfileAddress
  ): Promise<void> {
    this.matDialog.getDialogById(MatDialogId.complete_address)?.close();

    await firstValueFrom(
      this.store.dispatch(new OpenCompleteAddressDialog(address))
    );

    this.matDialog
      .getDialogById(MatDialogId.complete_address)
      ?.afterClosed()
      .pipe(first())
      .subscribe((data: CompleteAddressPopupData) => this.setDialogData(data));
  }

  private setDialogData(data: CompleteAddressPopupData): void {
    if (!data) {
      return;
    }

    this.store.dispatch([
      new UpdateOrderData({
        address: data.address,
        phone: data.phone,
      }),
    ]);
  }

  private initializeCarForm(): void {
    this.carForm = new FormGroup({
      make: new FormControl(this.car?.make || '', Validators.required),
      model: new FormControl(this.car?.model || '', Validators.required),
      color: new FormControl(this.car?.color || '', Validators.required),
      plate: new FormControl(this.car?.plate || '', Validators.required),
      isDefault: new FormControl(!!this.car?.isDefault),
    });

    this.carForm.valueChanges
      .pipe(debounceTime(300), this.destroyed$())
      .subscribe(value => this.updateCar(value));
  }

  private initializeSubscriptions(): void {
    this.orderCar$.pipe(this.destroyed$()).subscribe(car => (this.car = car));
  }

  private updateCar(car: Car): void {
    if (!isCarValid(car)) {
      return;
    }

    this.car = car;
    this.store.dispatch(new UpdateOrderCar(car));
  }

  public changeOrderMethod(orderMethod: DeliveryMethod): void {
    const actions: any[] = [new UpdateOrderType(orderMethod)];

    orderMethod !== DeliveryMethod.Delivery &&
      actions.push(new SetIsSearchingDriver(false, false));

    this.store
      .select(OrderDataState.orderMethod)
      .pipe(distinctUntilChanged(), first())
      .subscribe(() => this.openEditDialog());
    this.store.dispatch(actions);
  }

  public openEditDialog(): void {
    this.store.dispatch(new OpenEditCheckoutInfoDialog(this.isCheckout));
  }
}
