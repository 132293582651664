import { NgModule } from '@angular/core';

import { SharedModule } from '../../_modules/shared.module';
import { RsPipesModule } from '../../_modules/pipes.module';
import { ItemAllergensComponent } from '../allergens-dietary-restrictions/item-allergens/item-allergens/item-allergens.component';

import { NutritionalValuesComponent } from './nutritional-values/nutritional-values.component';
import { ItemModifiersComponent } from './item-modifiers/item-modifiers.component';
import { ItemSizesComponent } from './item-sizes/item-sizes.component';
import { BundleSectionComponent } from './bundle-item/bundle-section.component';
import { BundleSectionsComponent } from './bundle-sections/bundle-sections.component';
import { BundleItemModifiersComponent } from './bundle-item/bundle-item-modifiers.component';
import { BundleItemComponent } from './bundle-item/bundle-item.component';
import { ComboComponent } from './combo/combo/combo.component';
import { ComboItemComponent } from './combo/combo-item.component';
import { RsCounterComponent } from '../rs-counter/rs-counter.component';
import { RsPanelWrapperComponent } from 'src/app/_shared/_components/rs-panel-wrapper/rs-panel-wrapper.component';
import { RsFoodItemComponent } from '../food-item/rs-food-item.component';

const exportsComponent = [
  NutritionalValuesComponent,
  ItemModifiersComponent,
  ItemSizesComponent,
  BundleSectionComponent,
  BundleSectionsComponent,
  BundleItemModifiersComponent,
  BundleItemComponent,
  ComboComponent,
  ComboItemComponent,
];

@NgModule({
  declarations: [...exportsComponent],
  imports: [
    SharedModule,
    RsPipesModule,
    ItemAllergensComponent,
    RsCounterComponent,
    RsPanelWrapperComponent,
    RsFoodItemComponent,
  ],
  exports: [...exportsComponent],
})
export class ItemDetailsDialogModule {}
