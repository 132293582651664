import { ComponentType } from '@angular/cdk/portal';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatBottomSheetConfig } from '@angular/material/bottom-sheet';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { Card } from 'src/app/profile/_interfaces/payment.model';
import { AddressForm } from '../_interfaces/address.model';
import { Item } from '../_interfaces/item.model';
import { QRSettingsType } from '../_interfaces/qrSettings.model';

export class OpenDialog {
  static readonly type = '[Dialog] Open';

  constructor(
    public component: ComponentType<any>,
    public config: MatDialogConfig<any> = {}
  ) {}
}

export class OpenBottomSheet {
  static readonly type = '[Everywhere] OpenBottomSheet';

  constructor(
    public component: ComponentType<any>,
    public config: MatBottomSheetConfig<any> = {}
  ) {}
}

export class OpenCartDialog {
  static readonly type = '[Dialog] Open Cart Dialog';
}

export class OpenForgetPasswordDialog {
  static readonly type = '[ChangePasswordComponent] OpenForgetPasswordDialog';
}

export class OpenLoginDialog {
  static readonly type =
    '[AppComponent, SessionState, DialogState, LoginButton] Open Login Dialog';

  constructor(public skipRedirect?: boolean) {}
}

export class OpenLoginDialogWithoutGuest {
  static readonly type = '[ContactInfoNotificationComponent] OpenLoginDialog';

  constructor(public skipRedirect?: boolean) {}
}

export class OpenAddressDeliveryDialog {
  static readonly type = '[Address Delivery Dialog] Open';
}

export class OpenSignUpDialog {
  static readonly type = '[SuccessPages, DialogState] Open SignUp Dialog';

  constructor(
    public signUpData: {
      skipRedirect?: boolean;
      email?: string;
      phone?: { countryCode: string; phoneNumber: string } | null;
    }
  ) {}
}

export class OpenPayNowDialog {
  static readonly type = '[Dialog] Open Pay Now Dialog';

  constructor(public isGiftCard: boolean = false) {}
}

export class OpenNewAddressDialog {
  static readonly type = '[Dialog] Open New Address Dialog';

  constructor(
    public data: {
      address: UserProfileAddress | undefined | null;
      isLastDefault?: boolean;
      isShowCloseButton?: boolean;
    }
  ) {}
}

export class OpenSelectCardForPaymentDialog {
  static readonly type = '[Select Card For Payment Dialog] Open';
}

export class OpenChangeAddressDialog {
  static readonly type = '[Dialog] Open Change Address Dialog';

  constructor(public data?: any) {}
}

export class OpenDeleteDefaultAddressDialog {
  static readonly type = '[Dialog] Open Delete Default Address Dialog';
}

export class OpenAddNewCardDialog {
  static readonly type = '[Dialog] Open Add New Card Dialog';

  constructor(
    public data: AddressForm | null,
    public selectedCard: Card | null
  ) {}
}

export class OpenDeleteDefaultCardDialog {
  static readonly type = '[Dialog] Open Delete Default Card Dialog';

  constructor(
    public data: {
      card?: Card;
      shouldSkipActions?: boolean;
      title: string;
      description: string;
    }
  ) {}
}

export class OpenItemDetailsMobileDialog {
  static readonly type = '[Dialog] Open Item Details Dialog Screen';

  constructor(
    public menuId: string,
    public itemId: string,
    public sectionId: string | undefined,
    public isMobile: boolean,
    public menuName: string,
    public menu?: QRSettingsType
  ) {}
}

export class OpenConfirmationDialog {
  static readonly type = '[Dialog] Open Confirmation Dialog';

  constructor(public data: string) {}
}

export class OpenLogoutConfirmationDialog {
  static readonly type = '[Dialog] Logout Confirmation Dialog Component';
}

export class RemoveDialogFromHistory {
  static readonly type = '[Dialog] Remove Dialog From History';

  constructor(public id: string) {}
}

export class ClearHistory {
  static readonly type = '[Dialog] Clear History';
}

export class OpenCompleteAddressDialog {
  static readonly type = '[Complete Address Dialog] Open';

  constructor(public address?: UserProfileAddress) {}
}

export class OpenInitialAddressDialog {
  static readonly type =
    '[OrderAddressButtonComponent, OrderOnlineHeaderComponent] OpenInitialAddressDialog';

  constructor(public address?: UserProfileAddress) {}
}

export class OpenSavedAddressesDialog {
  static readonly type = '[Saved Addresses Dialog] Open';
}

export class OpenLanguagesDialog {
  static readonly type = '[OpenVersionDialog, LanguageComponent] Open';

  constructor(public center: boolean = false) {}
}

export class OpenEditCheckoutInfoDialog {
  static readonly type =
    '[OrderOnlineHeaderComponent] OpenEditCheckoutInfoDialog';

  constructor(public isCheckout: boolean = false) {}
}

export class OpenDineInChangePaymentmethodDialog {
  static readonly type = '[Dialog] Open Dine In Change Payment Method Dialog';
}

export class OpenDineInAddPaymentmethodDialog {
  static readonly type = '[Dialog] Open Dine In Add Payment Method Dialog';
}

export class OpenVersionDialog {
  static readonly type = '[VenueSidenavComponent] OpenVersionDialog';
}
