<div class="rs-contact-info-notification">
  <div class="rs-contact-info-notification__icon">
    <mat-icon [svgIcon]="personIcon"></mat-icon>
  </div>
  <div class="rs-contact-info-notification__text">
    <a [id]="id.Cart.userLogInButton" (click)="login()">
      <p class="rs-contact-info-notification__text">
        {{ 'PAY_NOW.ALREADY_HAVE_ACCOUNT' | translate }}
      </p>
      <p
        class="rs-contact-info-notification__text"
        [innerHTML]="'PAY_NOW.LOGIN_FASTER' | translate"
      ></p>
    </a>
  </div>
</div>
