import { Pipe, PipeTransform } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';

@Pipe({ name: 'rsGetRouterSegments' })
export class GetRouterSegments implements PipeTransform {
  constructor(public readonly router: Router) {}

  public transform(url: string): UrlSegment[] {
    return this.router.parseUrl(url).root.children['primary'].segments;
  }
}
