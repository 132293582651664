<div class="rs-wine-review d-flex flex-column">
  <div class="rs-wine-review__short d-flex flex-wrap align-items-center">
    <rs-badge-status rs-color="#E73535" rs-style="stadium">
      {{ rating.rating }}/100
    </rs-badge-status>

    <div class="rs-wine-review__name">{{ rating.source | uppercase }}</div>
  </div>
  <div class="rs-wine-review__review">
    {{ rating.review }}
  </div>
</div>
