import { ENDPOINTS } from './endpoints';

export const ENDPOINTS_WITH_ACCESS_TOKEN = [
  'order/v1/guest/dine-in',
  'user-profile/v1/profile/me/address',
  'payment/card-token',
  'payment/v2/card-token',
  'payment/consumer/gift-card',
  'payment/v2/consumer/gift-card',
  'order/v1/guest',
  'order/v1/guest/dine-in/',
  'user-profile/v1/profile/me',
  'catalog/v1/user/settings',
  'user-profile/v1/profile',
  'order/v1/ca',
  'payment/',
  'payment/v2/',
  'search/v2/favorite',
  ENDPOINTS.wallet.createGiftCard,
  ENDPOINTS.wallet.mergeGiftCards,
  'review/v1/check/',
  'review/v1/order/',
  'wallet/v1/gift-card',
  'wallet/v1/guest/gift-card',
  'delivery/v1',
];

export const ENDPOINTS_WITHOUT_ACCESS_TOKEN = [
  ENDPOINTS.authentication.signUp,
  'catalog/v1/ca/tag',
  'order/v1/guest/calculate',
  'delivery/v1/cw/quote',
  'order/v1/guest/table-info',
  'payment/apple-pay/validate',
  'payment/v2/apple-pay/validate',
];
