import { Injectable } from '@angular/core';

import moment from 'moment';
import { getCurrentMomentTime } from '../profile/_helpers/default-menu.helpers';

import { weekDaysWeekStartsFromSunday } from '../venue/dine-in/_constants/menus-availability.constants';
import {
  GroupedAvailabilityItems,
  NonWorkingPeriodData,
  VisibleMenuAvailability,
  WorkingPeriodData,
} from '../venue/dine-in/_models/menus.models';
import { Format, Unit } from '../_shared/_enums/date-time.enum';
import { Period } from '../_shared/_interfaces/item.model';
import { Menu, MenuAvailability } from '../_shared/_interfaces/menu.model';

@Injectable({ providedIn: 'root' })
export class MenusService {
  public getMenuAvailability(
    menu: Menu,
    selectedMomentTime: moment.Moment = getCurrentMomentTime()
  ): VisibleMenuAvailability | null {
    if (!menu?.availability?.length) {
      return null;
    }

    const checkedMenuAvailability = this.checkMenuAvailability(
      menu,
      selectedMomentTime
    );

    if (checkedMenuAvailability) {
      return {
        ...checkedMenuAvailability,
        menuId: menu.menuId,
      };
    }

    return null;
  }

  public getMenuAvailabilityItem(menuId: string, menuPage: any): any {
    return menuPage?.menusAvailability?.find(
      (item: any) => item.menuId === menuId
    );
  }

  public isMenuClosedSoon(menuId: string, menuPage: any): boolean {
    const workingPeriodData = this.getMenuAvailabilityItem(menuId, menuPage);

    return (
      workingPeriodData?.isWorkingDay &&
      workingPeriodData?.timeWithinClosed <= 15 &&
      workingPeriodData?.timeWithinClosed > 0
    );
  }

  private checkMenuAvailability(
    menu: Menu,
    selectedMomentTime: moment.Moment
  ): Omit<VisibleMenuAvailability, 'menuId'> | null {
    const groupedAvailabilityItems = this.groupAvailabilityItems(
      menu.availability,
      selectedMomentTime
    );

    const currentWeekDay = selectedMomentTime
      .clone()
      .format(Format.WeekDay)
      .toLowerCase();

    const checkedWorkingPeriod = this.checkWorkingPeriod(
      groupedAvailabilityItems,
      currentWeekDay,
      selectedMomentTime
    );

    if (checkedWorkingPeriod) {
      return checkedWorkingPeriod;
    }

    const checkedNotWorkingPeriod = this.checkNonWorkingPeriod(
      groupedAvailabilityItems,
      currentWeekDay,
      selectedMomentTime
    );

    if (checkedNotWorkingPeriod) {
      return checkedNotWorkingPeriod;
    }

    return null;
  }

  private checkWorkingPeriod(
    groupedAvailabilityItems: GroupedAvailabilityItems,
    currentWeekDay: string,
    selectedMomentTime: moment.Moment
  ): WorkingPeriodData | null {
    for (const [weekDay, periods] of groupedAvailabilityItems) {
      if (!periods || !weekDay) {
        return null;
      }
      if (weekDay !== currentWeekDay) {
        continue;
      }

      const currentTimeRelatedPeriod = this.getCurrentTimeRelatedPeriod(
        periods,
        selectedMomentTime
      );

      if (currentTimeRelatedPeriod) {
        return {
          isWorkingDay: true,
          timeWithinClosed: this.getTimeWithinClosed(
            currentTimeRelatedPeriod.to as moment.Moment,
            selectedMomentTime
          ),
        };
      }
    }

    return null;
  }

  private checkNonWorkingPeriod(
    groupedAvailabilityItems: GroupedAvailabilityItems,
    currentWeekDay: string,
    selectedMomentTime: moment.Moment
  ): Partial<NonWorkingPeriodData> | null {
    const willBeOpenedTodayData = this.getWillBeOpenedTodayData(
      groupedAvailabilityItems,
      currentWeekDay,
      selectedMomentTime
    );

    if (willBeOpenedTodayData) {
      return willBeOpenedTodayData;
    }

    const willBeOpenedOtherData = this.getWillBeOpenedOtherDayData(
      groupedAvailabilityItems,
      selectedMomentTime
    );

    if (willBeOpenedOtherData) {
      return willBeOpenedOtherData;
    }

    return null;
  }

  private getWillBeOpenedTodayData(
    groupedAvailabilityItems: GroupedAvailabilityItems,
    currentWeekDay: string,
    selectedMomentTime: moment.Moment
  ): Partial<
    Pick<
      NonWorkingPeriodData,
      'willBeOpenedToday' | 'nextPeriodOpenningTime' | 'willBeOpenedWeekDay'
    >
  > | null {
    const currentWeekDayAvailabilityItemPeriods =
      groupedAvailabilityItems.get(currentWeekDay);

    const nextAvailabilityItemPeriod = (
      currentWeekDayAvailabilityItemPeriods || []
    ).find(period =>
      (period.from as moment.Moment).isAfter(
        moment(
          selectedMomentTime.clone().format(Format.TwelveHours),
          Format.TwelveHours
        )
      )
    );

    const isSelectedDateBetweenAvailabilityItemPeriod = (
      currentWeekDayAvailabilityItemPeriods || []
    ).find(period =>
      moment(
        selectedMomentTime.clone().format(Format.TwelveHours),
        Format.TwelveHours
      ).isBetween(period.from, period.to)
    );

    if (
      !nextAvailabilityItemPeriod &&
      currentWeekDay !== this.getCurrentWeekDay() &&
      isSelectedDateBetweenAvailabilityItemPeriod
    ) {
      return {};
    }

    if (nextAvailabilityItemPeriod) {
      if (currentWeekDay !== this.getCurrentWeekDay()) {
        if (!isSelectedDateBetweenAvailabilityItemPeriod) {
          return {
            willBeOpenedToday: true,
            willBeOpenedWeekDay:
              currentWeekDay !== this.getCurrentWeekDay()
                ? `${currentWeekDay[0].toUpperCase()}${currentWeekDay.substring(
                    1
                  )}`
                : '',
            nextPeriodOpenningTime:
              nextAvailabilityItemPeriod.from as moment.Moment,
          };
        }

        return null;
      }

      return {
        willBeOpenedToday: true,
        nextPeriodOpenningTime:
          nextAvailabilityItemPeriod.from as moment.Moment,
      };
    }

    return null;
  }

  private isTodayWorkingDay(
    groupedAvailabilityItems: GroupedAvailabilityItems,
    selectedMomentTime: moment.Moment
  ): boolean {
    const currentMoment = selectedMomentTime.clone();
    const currentWeekDayNumber = currentMoment.weekday();

    const [workingDayPeriod] = (
      groupedAvailabilityItems.get(
        weekDaysWeekStartsFromSunday[currentWeekDayNumber]
      ) || []
    ).sort(
      (a, b) =>
        (a.from as moment.Moment).valueOf() -
        (b.from as moment.Moment).valueOf()
    );

    const currentFormattedTime = moment(
      currentMoment.clone().format(Format.TwelveHours),
      Format.TwelveHours
    );

    return (
      (workingDayPeriod &&
        (workingDayPeriod.from as moment.Moment).isBefore(
          currentFormattedTime
        ) &&
        (workingDayPeriod.to as moment.Moment).isAfter(currentFormattedTime)) ||
      false
    );
  }

  private getWillBeOpenedOtherDayData(
    groupedAvailabilityItems: GroupedAvailabilityItems,
    selectedMomentTime: moment.Moment
  ): Pick<
    NonWorkingPeriodData,
    | 'daysDiffBeforeOpen'
    | 'isNonWorkingDay'
    | 'nextPeriodOpenningTime'
    | 'willBeOpenedWeekDay'
  > | null {
    const currentDay = selectedMomentTime.clone();
    const currentWeekDayNumber = currentDay.weekday();
    const tomorrowDayNumber = currentDay.clone().add(1, Unit.Day).weekday();
    const isTodayNonWorkingDay: boolean = !this.isTodayWorkingDay(
      groupedAvailabilityItems,
      selectedMomentTime
    );

    for (
      let i = tomorrowDayNumber;
      i < weekDaysWeekStartsFromSunday.length;
      i++
    ) {
      const nextWorkingDay = weekDaysWeekStartsFromSunday[i];

      if (!groupedAvailabilityItems.has(nextWorkingDay)) {
        if (i === weekDaysWeekStartsFromSunday.length - 1) {
          i = -1;
        }

        continue;
      }

      const [nextWorkingDayPeriod] = (
        groupedAvailabilityItems.get(nextWorkingDay) || []
      ).sort(
        (a, b) =>
          (a.from as moment.Moment).valueOf() -
          (b.from as moment.Moment).valueOf()
      );

      return {
        daysDiffBeforeOpen: i + 1 - currentWeekDayNumber,
        willBeOpenedWeekDay: `${nextWorkingDay[0].toUpperCase()}${nextWorkingDay.substring(
          1
        )}`,
        nextPeriodOpenningTime: (nextWorkingDayPeriod?.from ??
          selectedMomentTime.clone()) as moment.Moment,
        isNonWorkingDay: isTodayNonWorkingDay,
      };
    }

    return null;
  }

  private getCurrentTimeRelatedPeriod(
    periods: Period[],
    selectedMomentTime: moment.Moment
  ): Period | undefined {
    return periods.find(
      period =>
        selectedMomentTime.isSameOrAfter(period.from) &&
        selectedMomentTime.isBefore(period.to)
    );
  }

  private getTimeWithinClosed(
    periodClosedTime: moment.Moment,
    selectedMomentTime: moment.Moment
  ): number {
    return periodClosedTime.diff(selectedMomentTime, Unit.Minutes);
  }

  private groupAvailabilityItems(
    menuAvailiabilityItems: MenuAvailability[],
    selectedMomentTime: moment.Moment
  ): GroupedAvailabilityItems {
    const map = new Map<string, Period[]>();

    for (const { daysOfWeek, periods } of menuAvailiabilityItems) {
      if (!!daysOfWeek) {
        for (const weekDay of daysOfWeek) {
          const lowerCasedWeekDay = weekDay.toLowerCase();
          const momentConvertedPeriods = (periods ?? []).map<Period>(period => {
            const fromMoment = selectedMomentTime.clone().set(period.from);
            const fromMomentTime = moment(
              fromMoment.format(Format.TwelveHours),
              Format.TwelveHours
            );

            const toMoment = selectedMomentTime.clone().set(period.to);
            const toMomentTime = moment(
              toMoment.format(Format.TwelveHours),
              Format.TwelveHours
            );

            return {
              from: fromMomentTime,
              to: toMomentTime,
            };
          });

          if (map.has(lowerCasedWeekDay)) {
            map.get(lowerCasedWeekDay)?.push(...momentConvertedPeriods);
          } else {
            map.set(lowerCasedWeekDay, momentConvertedPeriods);
          }
        }
      }
    }

    return map;
  }

  private getCurrentWeekDay(): string {
    return getCurrentMomentTime().clone().format(Format.WeekDay).toLowerCase();
  }
}
