import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { flattenDeep, uniqBy } from 'lodash';

import {
  BundleInformation,
  ItemDetails,
  BundleItem,
} from 'src/app/_shared/_interfaces/item.model';
import { BundleItemState } from 'src/app/_shared/_ngxs/bundle-item.state';
import {
  ClearItemModifiers,
  GetBundleItemModifiers,
} from 'src/app/_shared/_ngxs/bundle-item.actions';
import { ItemState } from 'src/app/_shared/_ngxs/item.state';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';

@Component({
  selector: 'rs-bundle-sections',
  templateUrl: './bundle-sections.component.html',
  styleUrls: ['./bundle-sections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundleSectionsComponent implements AfterContentInit {
  @Input() bundleItemsControl: FormControl = new FormControl([]);
  @Input() metadata: any = {
    modifierSelections: [],
  };
  @Input() disableOrdering = false;

  @Output() allItemsSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() allModifiersValid: EventEmitter<boolean> = new EventEmitter();

  public itemDetails$: Observable<ItemDetails | null> = this.store.select(
    ItemState.item
  );

  public bundleItemModifiers$ = this.store.select(
    BundleItemState.bundleItemModifiers
  );
  private sectionsNumber: number = 0;
  private selectedAllItems: boolean = false;
  private modifiersValid: { [index: string]: boolean } = {};

  private destroyed$ = untilDestroyed();

  constructor(private store: Store) {}

  ngOnInit() {
    if (this.store.selectSnapshot(CartState.isEditing)) {
      this.setEditingData();
    }
  }

  ngAfterContentInit(): void {
    this.store.dispatch(new ClearItemModifiers());

    this.itemDetails$.pipe(this.destroyed$()).subscribe(itemDetails => {
      this.sectionsNumber =
        itemDetails?.bundleAttributes?.sections?.length || 0;

      this.checkIfSelectedAllBundleItems();
    });
  }

  public setEditingData(): void {
    if (this.bundleItemsControl.value) {
      const itemDetails = this.store.selectSnapshot(ItemState.item);

      if (itemDetails) {
        for (const bundleItem of this.bundleItemsControl.value) {
          const foundSection = itemDetails.bundleAttributes?.sections.find(
            section => bundleItem.bundleSectionId === section.id
          );

          if (foundSection) {
            const foundItem = foundSection.items.find(
              item => item.id === bundleItem.bundleItemId
            );

            this.store.dispatch(
              new GetBundleItemModifiers(
                foundItem as BundleItem,
                foundSection.id
              )
            );

            this.metadata.modifierSelections.push(
              (bundleItem.modifiers || []).map((modifier: any) => {
                return {
                  modifierId: modifier.modifierId,
                  modifierOptionIds: (bundleItem.modifiers || [])
                    .filter(
                      (modifierItem: any) =>
                        modifierItem.modifierId === modifier.modifierId
                    )
                    .map((modifierItem: any) => modifierItem.selectedOptionId),
                };
              })
            );
          }
        }

        this.metadata.modifierSelections = flattenDeep(
          this.metadata.modifierSelections
        );

        this.selectedAllItems = true;

        this.allItemsSelected.next(this.selectedAllItems);
        this.allModifiersValid.next(true);
      }
    }
  }

  public updateBundleItemsSelected({
    items,
  }: {
    bundleSectionId: string;
    items: BundleInformation[];
  }): void {
    this.bundleItemsControl.reset([...items]);

    this.checkIfAllModifiersValid();
    this.checkIfSelectedAllBundleItems();
  }

  public checkIfSelectedAllBundleItems(): void {
    this.selectedAllItems =
      uniqBy(this.bundleItemsControl.value, 'bundleSectionId').length ===
      this.sectionsNumber;

    this.allItemsSelected.next(this.selectedAllItems);
  }

  public updateBundleItem(
    bundleItemId: string,
    bundleItemData: {
      bundleItemId: string;
      bundleSectionId: string;
      modifiers: {
        modifierId: string;
        modifierOptionIds: string[];
      }[];
    }
  ): void {
    const existingBundlesItems: {
      bundleItemId: string;
      bundleSectionId: string;
      modifiers: {
        modifierId: string;
        modifierOptionIds: string[];
      }[];
    }[] = [...this.bundleItemsControl.value];
    const currentItemIndex: number = existingBundlesItems.findIndex(
      ({ bundleItemId: itemId }) => bundleItemId === itemId
    );

    if (currentItemIndex < 0) {
      existingBundlesItems.push(bundleItemData);
    } else {
      existingBundlesItems[currentItemIndex] = bundleItemData;
    }

    this.bundleItemsControl.reset([...existingBundlesItems]);
    this.metadata.bundleItems = [...existingBundlesItems];
  }

  public reactOnModifiersValidity(
    id: string,
    isModifiersForItemValid: boolean
  ): void {
    this.modifiersValid[id] = isModifiersForItemValid;

    this.checkIfAllModifiersValid();
  }

  public checkIfAllModifiersValid(): void {
    const keys = Object.keys(this.modifiersValid);
    const existingBundlesItems: {
      bundleItemId: string;
      bundleSectionId: string;
    }[] = this.bundleItemsControl.value;

    let isValid: boolean = true;

    for (let i = 0; i < keys.length; i++) {
      if (
        existingBundlesItems.find(
          ({ bundleItemId }) => bundleItemId === keys[i]
        )
      ) {
        isValid = isValid && this.modifiersValid[keys[i]];
      }
    }
  }
}
