import { Component, Input } from '@angular/core';
import { SharedModule } from '../../_modules/shared.module';
import { RsBannerAlertComponent } from '../../_rs-design/banner/alert/banner-alert.component';
import { DeliveryType } from '../../_enums/order.enum';

@Component({
  selector: 'rs-item-unavailable-message',
  standalone: true,
  imports: [SharedModule, RsBannerAlertComponent],
  templateUrl: './item-unavailable-message.component.html',
})
export class ItemsUnavailableMessageComponent {
  @Input() disableOrdering!: boolean;
  @Input() noAvailableOrderTypes!: boolean | null;
  @Input() itemUnavailable!: boolean;
  @Input() itemUnavailableForCurrentTime!: boolean;
  @Input() itemUnavailbleForCurrentOrderType!: boolean;
  @Input() orderType!: DeliveryType;
}
