<div class="d-flex justify-content-between rs-gift-cards-list__events">
  <p class="rs-items-amount">
    <mat-checkbox
      *ngIf="showCheckboxes"
      [id]="id.Cart.totalItemsCheckbox"
      [checked]="everyItemInCartIsSelected"
      (change)="selectAllItems($event.checked)"
    ></mat-checkbox>
    {{ String(items.length) | rsCartItemsAmount | rsEasternArabicNumerals }}
  </p>

  <a (click)="goToGiftCards()" [id]="id.Cart.orderMore">
    <div class="rs-gift-cards-list__order-more">
      <mat-icon [svgIcon]="addOutlineIcon"></mat-icon>
      {{ 'PAY_NOW.ORDER_MORE' | translate | uppercase }}
    </div>
  </a>
</div>

<div class="rs-list-of-items" [dir]="'languageDirection' | translate">
  <ng-template ngFor [ngForOf]="items" let-card let-index="index">
    <div
      *ngIf="id.Cart.giftCardListItems(index + 1); let giftCardId"
      class="rs-item"
    >
      <mat-checkbox
        *ngIf="showCheckboxes"
        [class.rs-checkbox-padding]="!isMobileView"
        [class.rs-checkbox-mobile]="isMobileView"
        [id]="giftCardId + '-checkbox'"
        [checked]="card.selectedInCart"
        (click)="$event.stopPropagation()"
        (change)="selectItem(card.cartItemId || '', $event.checked)"
      ></mat-checkbox>
      <rs-gift-card-item-view
        [elementId]="giftCardId"
        [isDeleteAvailable]="true"
        [card]="card"
        (decreaseItemQuantity)="decreaseItemQuantity.emit($event)"
      ></rs-gift-card-item-view>
    </div>
  </ng-template>
</div>
