import { Pipe, PipeTransform } from '@angular/core';
import { KeyValue } from '@angular/common';

import { AvailableLanguage } from '../_ngxs/language.state';

@Pipe({ name: 'rsLanguageShort', standalone: true })
export class LanguageShortNamePipe implements PipeTransform {
  public transform({
    key,
    value,
  }: KeyValue<string, AvailableLanguage>): string {
    switch (value) {
      case 'es':
      case 'pt':
        return value;
      case 'en':
      case 'ru':
        return key.substring(0, 3);
      default:
        return key;
    }
  }
}
