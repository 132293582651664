import { AvailableLanguage } from './language.state';

export class SetLanguage {
  public static readonly type = '[Language] Set Language';

  constructor(public language: AvailableLanguage) {}
}

export class InitializeLanguage {
  public static readonly type = '[Language] Initialize Language';
}
