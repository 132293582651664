<ng-container *ngIf="isDialog; else partialContent">
  <rs-dialog rs-dialog-style="default">
    <ng-container rs-dialog-header>
      <span>{{ 'DELIVERY_METHOD.Delivery_address' | translate }}</span>
    </ng-container>

    <ng-container *ngTemplateOutlet="partialContent"></ng-container>

    <ng-container rs-dialog-actions-direction="row">
      <button
        rs-style="bold"
        [disabled]="selectedAddress == undefined"
        (click)="close()"
      >
        <span>{{ 'DELIVERY_METHOD.continue' | translate }}</span>
      </button>
    </ng-container>
  </rs-dialog>
</ng-container>

<ng-template #partialContent>
  <div
    class="rs-select-address d-flex flex-column"
    [dir]="'languageDirection' | translate"
    *ngIf="addresses$ | async as addresses"
  >
    <ng-container
      *ngFor="
        let isLoggedInAndHasAddresses of [
          (isLoggedIn$ | async) && addresses.length
        ]
      "
    >
      <div class="w-100 mt-3">
        <div *ngIf="!isDialog" class="rs-select-address__title">
          {{ 'DELIVERY_METHOD.Delivery_address' | translate }}
        </div>

        <div
          *ngIf="isLoggedInAndHasAddresses"
          class="rs-select-address__description mb-1"
        >
          {{ 'PROFILE.payment.select-address' | translate }}
        </div>
      </div>

      <ng-container *ngIf="isLoggedInAndHasAddresses">
        <div
          class="rs-select-address__item"
          *ngFor="let address of addresses"
          [class.rs-select-address__item--selected]="
            address.address1 === selectedAddress?.address1 &&
            address.address2 === selectedAddress?.address2 &&
            address.zipcode === selectedAddress?.zipcode &&
            address.city === selectedAddress?.city
          "
          (click)="getSelectedAddress(address)"
        >
          <div class="rs-select-address__item-icon">
            <mat-icon>place</mat-icon>
          </div>
          <div class="rs-select-address__item-info">
            <div class="rs-select-address__item-name">{{ address.name }}</div>
            <div class="rs-select-address__item-text">
              {{ address.address1 }}, {{ address.city }}, {{ address.state }}
              {{ address.zipcode }}
            </div>
          </div>
        </div>
      </ng-container>

      <div class="rs-select-address__description mb-3">
        {{
          (isLoggedInAndHasAddresses
            ? 'DELIVERY_METHOD.Or enter new address'
            : 'DELIVERY_METHOD.Enter new address'
          ) | translate
        }}
      </div>
      <div class="rs-select-address__new p-1">
        <rs-address-form
          [hideInstructions]="false"
          [hidePhoneNumber]="!!(isLoggedIn$ | async)"
          [instructionsPlaceholder]="
            'PROFILE.payment.more_instructions_delivery'
          "
          [rsAddressForm]="addressForm"
          [phoneFormGroup]="phoneFormGroup"
          [isAddressDetailsVisible]="selectedAddress != undefined"
          (clearAutoComplete)="clearAutoCompelete()"
        ></rs-address-form>
      </div>
    </ng-container>
  </div>
</ng-template>
