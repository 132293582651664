<div
  class="rs-counter d-flex justify-content-between align-items-center"
  [ngClass]="{
    'rs-sm-counter': isSmall,
    'rs-square-border': isSquareBorder
  }"
>
  <button
    *ngIf="(!withDisabling && quantity > 0) || withDisabling"
    [id]="deleteButtonId"
    mat-button
    mat-icon-button
    matPrefix
    aria-label="Remove"
    [disabled]="quantity === 0 && withDisabling"
    (click)="quantity === 1 ? deleteItem($event) : setQuantity($event, -1)"
  >
    <mat-icon
      [svgIcon]="
        quantity === 1 && withDelete ? CommonIcon.Trash : CommonIcon.Minus
      "
    ></mat-icon>
  </button>
  <ng-container *ngIf="showIncreaseButton">
    <span *ngIf="(!withDisabling && quantity > 0) || withDisabling">
      {{ quantity | rsEasternArabicNumerals }}
    </span>
    <button
      mat-button
      mat-icon-button
      matSuffix
      aria-label="Add"
      (click)="setQuantity($event, 1)"
      [disabled]="isButtonDisabled()"
    >
      <mat-icon [svgIcon]="CommonIcon.Plus"></mat-icon>
    </button>
  </ng-container>
</div>
