import moment from 'moment';

import { Order } from 'src/app/profile/_interfaces/order.model';
import { Allergen, Item, ItemData, StateItem } from '../_interfaces/item.model';
import { Menu, Section } from '../_interfaces/menu.model';
import { DeliveryMethod, DeliveryType, OrderType } from '../_enums/order.enum';
import { DeliveryDataDateTime } from '../_interfaces/order.model';
import { Format } from '../_enums/date-time.enum';
import { DayOfWeek } from '../_enums/day-of-week.enum';
import {
  areItemsAllergensPassFilter,
  getValidatedItemAllergens,
} from './allergens';
import {
  convertDeliveryTimeToPeriod,
  isNowIsInPeriod,
  isPeriodIsInAnotherPeriod,
} from './dates';
import { DEFAULT_DELIVERY_METHOD } from '../_constants/delivery.constant';

export const getMenuAvailableFor = (menu: Menu): DeliveryType[] =>
  menu.availableFor;

export const getMenusPrepared = (menus: Menu[]): Menu[] => {
  return (menus || []).map((menu: Menu) => {
    const { sections } = menu;
    const index = sections.findIndex(({ sectionName }) => !sectionName);

    if (index < 0) {
      return menu;
    }

    const accumulator = sections[index].items?.length ? [sections[index]] : [];

    return {
      ...menu,
      sections: sections.reduce((accum, section, i) => {
        if (i !== index && section.items?.length) {
          accum.push(section);
        }

        return accum;
      }, accumulator),
    };
  });
};

export const filterMenuByAllergens = (
  menu: Menu,
  selectedAllergens: Allergen[]
): Menu => {
  const filteredSections = [...menu.sections].map((section: Section) => {
    const filteredItems = [...section.items].filter(({ allergens }: Item) => {
      if (!allergens?.length) {
        return true;
      }

      return areItemsAllergensPassFilter(
        getValidatedItemAllergens(allergens),
        selectedAllergens
      );
    });

    return {
      ...section,
      items: filteredItems,
    };
  });

  const filteredMenu: Menu = {
    ...menu,
    sections: filteredSections,
  };

  return filteredMenu;
};

export const filterMenusByAllergens = (
  menus: Menu[],
  selectedAllergens: Allergen[]
): Menu[] => {
  return [...menus].map(menu => filterMenuByAllergens(menu, selectedAllergens));
};

export const isItemConsistOfSearchText = (
  { customerFacingName, name }: Item,
  searchText: string
): boolean => {
  return customerFacingName
    ? customerFacingName.toLowerCase().indexOf(searchText) > -1
    : name?.toLowerCase().indexOf(searchText) > -1;
};

export const filterOrdersByText = (orders: Order[], searchText: string) => {
  return [
    ...orders.filter(order => {
      const { items, shortId } = order;
      const text = searchText.toLowerCase().trim();

      if (String(shortId || '').indexOf(text) > -1) {
        return true;
      }

      return !!items.find((item: Item) =>
        isItemConsistOfSearchText(item, text)
      );
    }),
  ];
};

export const filterMenuByText = (menu: Menu, searchText: string) => {
  const trimmedText: string = searchText.trim().toLowerCase();

  if (!trimmedText) {
    return menu;
  }

  const filteredSections = [...menu.sections].map((section: Section) => {
    const filteredItems = [...section.items].filter((item: Item) =>
      isItemConsistOfSearchText(item, trimmedText)
    );

    return {
      ...section,
      items: filteredItems,
    };
  });

  const filteredMenu: Menu = {
    ...menu,
    sections: filteredSections,
  };

  return filteredMenu;
};

export const filterMenusBySearchText = (
  menus: Menu[],
  searchText: string
): Menu[] => {
  if (!searchText) {
    return menus;
  }

  return [...menus].map(menu => filterMenuByText(menu, searchText));
};

export const getFilteredMenuBySearchParameters = (
  menu: Menu,
  selectedAllergens: Allergen[],
  searchText: string
): Menu => {
  if (!selectedAllergens?.length && !searchText) {
    return menu;
  }

  return searchText && selectedAllergens?.length
    ? filterMenuByAllergens(
        filterMenuByText(menu, searchText),
        selectedAllergens
      )
    : searchText
    ? filterMenuByText(menu, searchText)
    : filterMenuByAllergens(menu, selectedAllergens);
};

export const getFilteredMenusByOrderType = (
  menus: Menu[],
  orderType?: DeliveryType
): Menu[] => {
  if (!orderType) {
    return [...menus];
  }

  return [...menus].filter(
    menu => getMenuAvailableFor(menu).indexOf(orderType) > -1
  );
};

export const getFilteredMenusForToGo = (menus: Menu[]): Menu[] => {
  return [...menus].filter(menu => {
    const availableFor: DeliveryType[] = getMenuAvailableFor(menu);

    return (
      availableFor.indexOf(DeliveryMethod.Delivery) > -1 ||
      availableFor.indexOf(DeliveryMethod.Takeout) > -1 ||
      availableFor.indexOf(DeliveryMethod.Curbside) > -1
    );
  });
};

export const getFilteredMenusBySearchParameters = (
  menus: Menu[],
  selectedAllergens: Allergen[],
  searchText: string
): Menu[] => {
  if (!selectedAllergens?.length && !searchText) {
    return menus;
  }

  return [...menus].map(menu =>
    getFilteredMenuBySearchParameters(menu, selectedAllergens, searchText)
  );
};

export const getAvailableItemsList = (menus: Menu[]): Item[] => {
  const items: Item[] = [];
  menus.forEach(
    ({ sections }: Menu) =>
      sections.length &&
      sections.forEach(
        ({ items: sectionItems }) => sectionItems && items.push(...sectionItems)
      )
  );

  return items;
};

export const getVisibleMenus = (menus: Menu[], ids: string[]): Menu[] =>
  (menus || []).filter((menu: Menu) => ids.indexOf(menu.menuId) > -1);

export const getSectionsList = (menus: Menu[]): Section[] => {
  if (!menus?.length) {
    return [];
  }

  const sections: Section[] = [];

  menus.forEach(
    ({ sections: visibleSections }) =>
      visibleSections?.length && sections.push(...visibleSections)
  );

  return sections;
};

export const getItemsList = (menus: Menu[]): Item[] => {
  return (menus || [])
    .flatMap(menu => menu.sections || [])
    .flatMap(section => section.items || []);
};

export const isAvailableMenuItemForSelectedTime = (
  menuId: string,
  orderType: DeliveryType,
  selectedOrderPeriod: DeliveryDataDateTime,
  menus: Menu[],
  isAvailable: boolean
): boolean => {
  const current = menus.find(menu => menu.menuId === menuId);

  if (!current) {
    return false;
  }

  if (!current.availability?.length || orderType === OrderType.DineIn) {
    return isAvailable;
  }

  const currentDay = (
    selectedOrderPeriod?.day?.day
      ? moment(selectedOrderPeriod?.day.day)
      : moment()
  )
    .format(Format.WeekDay)
    .toUpperCase() as DayOfWeek;

  if (
    selectedOrderPeriod?.time &&
    !selectedOrderPeriod?.time?.includes('ASAP')
  ) {
    const currentPeriod = convertDeliveryTimeToPeriod(
      selectedOrderPeriod?.time
    );

    const availabilityForCurrentDay = current.availability.filter(
      ({ daysOfWeek }) => daysOfWeek?.indexOf(currentDay) > -1
    );

    return !!availabilityForCurrentDay.find(
      ({ periods }) =>
        !!periods.find(period =>
          isPeriodIsInAnotherPeriod(currentPeriod, period)
        )
    );
  } else {
    const availabilityForCurrentDay = current.availability.filter(
      ({ daysOfWeek }) => daysOfWeek?.indexOf(currentDay) > -1
    );

    return !!availabilityForCurrentDay.find(
      ({ periods }) => !!periods.find(period => isNowIsInPeriod(period))
    );
  }
};

export const isAvailableMenuForSelectedTime = (
  menuId: string,
  orderType: DeliveryType,
  selectedOrderPeriod: DeliveryDataDateTime,
  menus: Menu[],
  item?: StateItem | ItemData
): boolean => {
  const current = menus.find(menu => menu.menuId === menuId);

  if (!current || !selectedOrderPeriod) {
    return false;
  }

  if (!current.availability?.length || orderType === OrderType.DineIn) {
    return item?.item.availability.isAvailable || true;
  }

  const currentDay = (
    selectedOrderPeriod?.day?.day
      ? moment(selectedOrderPeriod?.day.day)
      : moment()
  )
    .format(Format.WeekDay)
    .toUpperCase() as DayOfWeek;

  if (
    selectedOrderPeriod?.time &&
    !selectedOrderPeriod?.time?.includes('ASAP')
  ) {
    const currentPeriod = convertDeliveryTimeToPeriod(
      selectedOrderPeriod?.time
    );

    const availabilityForCurrentDay = current.availability.filter(
      ({ daysOfWeek }) => daysOfWeek?.indexOf(currentDay) > -1
    );

    return !!availabilityForCurrentDay.find(
      ({ periods }) =>
        !!periods.find(period =>
          isPeriodIsInAnotherPeriod(currentPeriod, period)
        )
    );
  } else {
    const availabilityForCurrentDay = current.availability.filter(
      ({ daysOfWeek }) => daysOfWeek?.indexOf(currentDay) > -1
    );

    return !!availabilityForCurrentDay.find(
      ({ periods }) => !!periods.find(period => isNowIsInPeriod(period))
    );
  }
};

export const isAvailableMenuForCurrentOrderType = (
  menuId: string,
  orderType: DeliveryType,
  menus: Menu[]
): boolean => {
  const current = menus.find(menu => menu.menuId === menuId);

  if (!current) {
    return false;
  }

  !orderType && (orderType = DEFAULT_DELIVERY_METHOD);

  return current.availableFor.indexOf(orderType) > -1;
};

export const getFilteredCartItems = (
  items: StateItem[],
  orderType: DeliveryType,
  selectedOrderPeriod: DeliveryDataDateTime,
  menus: Menu[],
  getAll: boolean = false
): any => {
  if (items.length && (items[0] as any).currentBalance) {
    return items;
  }

  return items
    .filter(item => getAll || item.selectedInCart)
    .filter(
      (item: StateItem) =>
        isAvailableMenuForCurrentOrderType(item.menuId, orderType, menus) &&
        isAvailableMenuForSelectedTime(
          item.menuId,
          orderType,
          selectedOrderPeriod,
          menus,
          item
        )
    );
};
