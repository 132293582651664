import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsAllergenPipesModule } from 'src/app/_shared/_modules/allergen-pipes.module';
import { TooltipComponent } from '../../../_rs-design/tooltip/tooltip/tooltip.component';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

@Component({
  selector: 'rs-dietary-restrictions-list-wrapper',
  standalone: true,
  imports: [SharedModule, RsAllergenPipesModule, TooltipComponent],
  styleUrls: ['./dietary-restrictions-list-wrapper.component.scss'],
  templateUrl: './dietary-restrictions-list-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DietaryRestrictionListWrapperComponent {
  public readonly infoIcon = CommonIcons.Information;
  constructor(private readonly svgIconService: SvgIconService) {
    this.registerIcon();
  }

  private registerIcon(): void {
    this.svgIconService.registerSvgIcons([this.infoIcon], NEW_ICONS_DIRECTORY);
  }
}
