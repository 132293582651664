export enum OrderItemStatus {
  Received = 'received',
  InProgress = 'in_progress',
  ReadyForDelivery = 'ready_for_delivery',
  ReadyForPickUp = 'ready_for_pick_up',
  ReadyForPickup = 'ready_for_pickup',
  OutForDelivery = 'out_for_delivery',
  Delivered = 'delivered',
  Refunded = 'refunded',
  Deleted = 'deleted',
  Canceled = 'canceled',
  Finished = 'finished',
}
