// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env: 'stg',
  baseUrl: 'https://stg-sites-qwjcr9im4fs7ubuk9lrt.rockspoon.io',
  cdnUrl: 'https://cdn.rockspoon.io/website/stg',
  apiUrl: 'https://api.stg.rockspoon.io',
  paymentUrl: 'https://payment.stg.pay.rockspoon.io',
  domain: 'stg-sites-qwjcr9im4fs7ubuk9lrt.rockspoon.io',
  prefix: 'stg-',
  mapbox: {
    apiUrl: 'https://api.mapbox.com',
    accessToken:
      'pk.eyJ1Ijoicm9ja3Nwb29uLW1hcCIsImEiOiJjbDhhZnBnbmgwZ3Z3M3FvNW1lcjVyYXBtIn0.kEog2uybAQ-u8I7n2gCP2Q',
  },
};
