import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Store } from '@ngxs/store';
import _ from 'lodash';

import { DirectivesModule } from '../../_directives/directives.module';
import { FormInputModule } from '../form-input/form-input.module';
import { RsPipesModule } from '../../_modules/pipes.module';
import { SharedModule } from '../../_modules/shared.module';
import { RsAutocompleteFieldComponent } from '../rs-autocomplete-field/rs-autocomplete-field.component';

import {
  VehicleBrand,
  VehicleModel,
} from 'src/app/profile/_interfaces/car.model';
import { HideSpinner, ShowSpinner } from '../spinner/spinner.state';
import { CarService } from './car.service';
import { TEST_ID } from '../../_constants/e2e-ids.constants';
import { FormFieldErrors } from '../../_interfaces/form-errors.interface';

@Component({
  selector: 'rs-car-form',
  standalone: true,
  templateUrl: 'car-form.component.html',
  styleUrls: ['car-form.component.scss'],
  imports: [
    SharedModule,
    RsPipesModule,
    DirectivesModule,
    FormInputModule,
    RsAutocompleteFieldComponent,
  ],
  providers: [CarService],
})
export class CarFormComponent {
  @Input() carForm = new FormGroup({
    make: new FormControl(''),
    model: new FormControl(''),
    color: new FormControl(''),
    plate: new FormControl(''),
    isDefault: new FormControl(false),
  });

  public allCarBrands: VehicleBrand[] = [];
  public displayedCarBrands: VehicleBrand[] = [];
  public displayedCarBrandsLabels: string[] = [];

  public allModels: VehicleModel[] = [];
  public displayedModels: VehicleModel[] = [];
  public displayedModelsLabels: string[] = [];

  public readonly carBrandId = TEST_ID.checkout.carBrandId;
  public readonly carModelId = TEST_ID.checkout.carModelId;
  public readonly controlErrors: FormFieldErrors[] = [
    {
      hasError: 'required',
      errorMessage: 'VALIDATION.REQUIRED',
    },
  ];

  constructor(
    private carService: CarService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getCarBrands();
  }

  private async getCarBrands(): Promise<void> {
    const carBrands: VehicleBrand[] = await this.carService.getCarBrands();

    this.allCarBrands = carBrands.map((make: any) => ({
      ...make,
      MakeName: _.capitalize(make.MakeName),
    }));
    this.displayedCarBrands = [...this.allCarBrands];
    this.initializeDisplayedCarBrandsLabels();

    this.changeDetectorRef.markForCheck();
  }

  public findBrandByLabel(label: string): VehicleBrand | undefined {
    return this.displayedCarBrands.find(
      ({ MakeName }: VehicleBrand) => MakeName === label
    );
  }

  public findModelByLabel(label: string): VehicleModel | undefined {
    return this.displayedModels.find(
      ({ Model_Name }: VehicleModel) => Model_Name === label
    );
  }

  private initializeDisplayedCarBrandsLabels(): void {
    this.displayedCarBrandsLabels = this.displayedCarBrands.map(
      ({ MakeName }) => MakeName
    );
  }

  private initializeCarModelsLabels(): void {
    this.displayedModelsLabels = this.displayedModels.map(
      ({ Model_Name }: VehicleModel) => Model_Name
    );
  }

  public selectCarBrand(label: string): void {
    const carBrand: VehicleBrand | undefined = this.findBrandByLabel(label);

    if (carBrand) {
      this.carForm.controls.make.reset(carBrand.MakeName);

      this.getCarModels(String(carBrand.MakeId));
    }
  }

  private async getCarModels(id: string): Promise<void> {
    try {
      this.store.dispatch(new ShowSpinner());
      const carModels: VehicleModel[] =
        await this.carService.getModelsByBrandId(id);

      this.allModels = carModels;
      this.displayedModels = [...carModels];
      this.initializeCarModelsLabels();

      this.changeDetectorRef.markForCheck();
    } catch (e) {
      console.error(e);
    } finally {
      this.store.dispatch(new HideSpinner());
    }
  }

  public filterCarBrands(event: Event): void {
    this.displayedCarBrands = this.allCarBrands.filter(make =>
      make.MakeName.toLowerCase().includes(
        (event.target as any).value.toLowerCase()
      )
    );
    this.initializeDisplayedCarBrandsLabels();
  }

  public selectFirstCarBrand(): void {
    const label: string = this.displayedCarBrandsLabels[0];

    this.selectCarBrand(label);
  }

  public selectCarModel(label: string): void {
    const carModel: VehicleModel | undefined = this.findModelByLabel(label);

    carModel && this.carForm.controls.model.reset(carModel.Model_Name);
  }

  public selectFirstCarModel(): void {
    const label: string = this.displayedModelsLabels[0];

    this.selectCarModel(label);
  }

  public filterCarModels(event: Event): void {
    this.displayedModels = this.allModels.filter(model =>
      model.Model_Name.toLowerCase().includes(
        (event.target as any).value.toLowerCase()
      )
    );

    this.initializeCarModelsLabels();
  }
}
