import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SharedModule } from '../../_modules/shared.module';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

@Component({
  selector: 'rs-address-item',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './address-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressItemComponent {
  @Input() address: UserProfileAddress | undefined;
  @Input() isAddressSelected: boolean = false;
  @Input() activeCard: boolean = false;

  @Output() addressSelected: EventEmitter<UserProfileAddress> =
    new EventEmitter();

  public selectAddress(address: UserProfileAddress): void {
    this.addressSelected.emit(address);
  }
}
