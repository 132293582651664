import {
  ChangeDetectionStrategy,
  Component,
  NgZone,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsFullHeightDialogComponent } from 'src/app/_shared/_rs-design/dialog/full-height-dialog.component';
import { DietaryRestrictionListComponent } from '../dietary-restrictions-list/dietary-restrictions-list.component';
import { RsBannerAlertComponent } from 'src/app/_shared/_rs-design/banner/alert/banner-alert.component';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import {
  ResetSelectedAllergens,
  SaveSelectedAllergens,
} from 'src/app/_shared/_ngxs/allergens.actions';
import { FilteredMenuState } from 'src/app/_shared/_ngxs/filtered-menu.state';
import { Allergen } from 'src/app/_shared/_interfaces/item.model';
import { AllergensState } from 'src/app/_shared/_ngxs/allergens.state';

@Component({
  selector: 'rs-dietary-restrictions-popup',
  standalone: true,
  imports: [
    SharedModule,
    DietaryRestrictionListComponent,
    RsFullHeightDialogComponent,
    RsBannerAlertComponent,
  ],
  templateUrl: './dietary-restrictions-popup.component.html',
  styleUrls: ['./dietary-restrictions-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DietaryRestrictionPopupComponent {
  @ViewChild('restrictionsList')
  restrictionsList!: DietaryRestrictionListComponent;

  public noItems!: boolean;
  public allergens: string[] = [];
  public isNeedToSaveChanges!: boolean;
  public readonly closePopupIcon = CommonIcons.Close;

  constructor(
    private readonly ngZone: NgZone,
    private readonly dialogRef: MatDialogRef<DietaryRestrictionPopupComponent>,
    private readonly svgIconService: SvgIconService,
    private readonly store: Store
  ) {
    this.registerCloseButton();
  }

  public closePopup(): void {
    this.ngZone.run(() => this.dialogRef.close(this.isNeedToSaveChanges));
  }

  public setIsNeedToSaveAllergens(isNeedToSaveChanges: boolean): void {
    this.isNeedToSaveChanges = isNeedToSaveChanges;
  }

  public update(allergens: string[]): void {
    this.allergens = allergens;

    this.checkIfItemsAvailable(allergens);
  }

  public checkIfItemsAvailable(allergensValues: string[]): void {
    if (allergensValues.length) {
      const allergens: Allergen[] = this.store.selectSnapshot(
        AllergensState.allergensOfSelectedValues(allergensValues)
      );

      this.noItems = this.store.selectSnapshot(
        FilteredMenuState.areThereItemsIfAllergensApplied(allergens)
      );
    } else {
      this.noItems = false;
    }
  }

  public clearChanges(): void {
    this.setIsNeedToSaveAllergens(false);
    this.restrictionsList.clearFilters();
    this.store.dispatch(new ResetSelectedAllergens());
  }

  public async save(): Promise<void> {
    this.isNeedToSaveChanges &&
      (await firstValueFrom(
        this.store.dispatch(new SaveSelectedAllergens(this.allergens))
      ));

    this.closePopup();
  }

  private registerCloseButton(): void {
    this.svgIconService.registerSvgIcons(
      [this.closePopupIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
