import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import {
  GeocodeByTextResult,
  GeocodeByCoordinatesResult,
} from '../_shared/_interfaces/map.model';

const apiUrl = environment.mapbox.apiUrl;
const accessToken = environment.mapbox.accessToken;

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  constructor(private http: HttpClient) {}

  public getGeocodeByText(
    searchString: string,
    types: string = 'address',
    limit: number = 1
  ): Observable<GeocodeByTextResult> {
    return this.http.get<GeocodeByTextResult>(
      `${apiUrl}/geocoding/v5/mapbox.places/${searchString}.json?types=${types}&limit=${limit}&access_token=${accessToken}`
    );
  }

  public getGeocodeByCoordinates(
    longitude: number,
    latitude: number,
    types: string = 'address',
    limit: number = 1
  ): Observable<GeocodeByCoordinatesResult> {
    return this.http.get<GeocodeByCoordinatesResult>(
      `${apiUrl}/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=${types}&limit=${limit}&access_token=${accessToken}`
    );
  }
}
