import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsDialogComponent } from '../../dialog/dialog.component';

@Component({
  selector: 'rs-dialog-instead-of-tooltip.component',
  standalone: true,
  imports: [SharedModule, RsDialogComponent],
  templateUrl: './dialog-instead-of-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogMobileForTooltipComponent {
  @ViewChild('rsDialogHeader', { static: false }) dialogHeader:
    | ElementRef<any>
    | undefined;

  @ViewChild('rsDialogContent', { static: false }) dialogContent:
    | ElementRef<any>
    | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      tooltip: ElementRef<any>;
      tooltipHeader: ElementRef<any> | undefined;
    },
    private readonly renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.initializeDialog();
  }

  private initializeDialog(): void {
    this.dialogHeader?.nativeElement &&
      this.data.tooltipHeader?.nativeElement &&
      this.renderer.appendChild(
        this.dialogHeader?.nativeElement,
        this.data.tooltipHeader.nativeElement
      );

    this.dialogContent?.nativeElement &&
      this.renderer.appendChild(
        this.dialogContent?.nativeElement,
        this.data.tooltip.nativeElement
      );
  }
}
