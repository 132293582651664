import { uniqBy } from 'lodash';

import { Allergen } from '../_interfaces/item.model';

import {
  ALLERGEN_FREE_POSTFIX,
  ALLERGEN_TRACE_POSTFIX,
  DIGITAL_STOREFRONT_ALLERGEN_ICON_PREFIX,
  SPICY_LEVEL,
} from '../_constants/allergens.constants';
import { DEFAULT_LANGUAGE } from '../_constants/common';

const getDefaultAllergensLabels = (
  defaultAllergensList: Allergen[]
): string[] => defaultAllergensList.map(({ value }: Allergen) => value);

export const getDigitalStoreFrontAllergenIconRealName = (name: string) =>
  name.split(`_${ALLERGEN_TRACE_POSTFIX}`)[0].toLowerCase();

export const getDigitalStoreFrontAllergenIconRegistratedName = (name: string) =>
  `${DIGITAL_STOREFRONT_ALLERGEN_ICON_PREFIX}-${
    name.split(`_${ALLERGEN_TRACE_POSTFIX}`)[0]
  }`.toLowerCase();

export const isAllergenFree = (allergen: string): boolean =>
  allergen.includes(ALLERGEN_FREE_POSTFIX);
export const isAllergenTrace = (allergen: string): boolean =>
  allergen.includes(ALLERGEN_TRACE_POSTFIX);

export const isSpicyLevel = (allergen: string): boolean =>
  SPICY_LEVEL.includes(allergen);

export const isAllergenIncludes = (
  allergens: Allergen[],
  allergenLabel: string
): boolean => !!allergens.find(({ value }) => value === allergenLabel);

export const filterAllergensArray = (
  allergens: Allergen[],
  getAllergensFree: boolean,
  getAllergensContains: boolean = false
): Allergen[] => {
  if (!allergens?.length) {
    return [];
  }

  return allergens.filter(({ value }: Allergen) => {
    const isSpicyLevel: boolean = SPICY_LEVEL.includes(value);
    const isFree: boolean = isAllergenFree(value);
    const isTrace: boolean = isAllergenTrace(value);

    return (
      !isSpicyLevel &&
      ((getAllergensFree && isFree) ||
        (!getAllergensFree && !getAllergensContains && isTrace && !isFree) ||
        (!getAllergensFree && getAllergensContains && !isTrace && !isFree))
    );
  });
};

export const filterSpicyLevels = (allergens: Allergen[]): Allergen[] => {
  return allergens.filter(({ value }) => isSpicyLevel(value));
};

export const getAllergenWithoutModifier = (
  allergenWithModifier: string
): string => {
  return allergenWithModifier
    .split(`_${ALLERGEN_FREE_POSTFIX}`)[0]
    .split(`_${ALLERGEN_TRACE_POSTFIX}`)[0];
};

export const getAllergenLabelWithDifferentModifier = (
  allergen: string
): string => {
  // free vs trace
  const allergenWithoutModifier: string = getAllergenWithoutModifier(allergen);
  const allergenWithDifferentModifier: string = isAllergenFree(allergen)
    ? `${allergenWithoutModifier}_${ALLERGEN_TRACE_POSTFIX}`
    : `${allergenWithoutModifier}_${ALLERGEN_FREE_POSTFIX}`;

  return allergen && allergenWithDifferentModifier;
};

export const getAllergensLabelsWithDifferentModifier = (
  allergens: string[]
): string[] => {
  return allergens.map(allergen =>
    getAllergenLabelWithDifferentModifier(allergen)
  );
};

export const getCustomAllergens = (
  allergens: Allergen[],
  defaultAllergensList: Allergen[]
): Allergen[] => {
  return allergens.filter(
    ({ value }: Allergen) =>
      !getDefaultAllergensLabels(defaultAllergensList).includes(value)
  );
};

export const getAllergensBasedOnLabelsArray = (
  labels: string[],
  allergensList: Allergen[]
): Allergen[] => {
  return [...allergensList].filter(({ value }: Allergen) =>
    labels.includes(value)
  );
};

export const getDefaultAllergens = (
  allergens: Allergen[],
  defaultAllergensList: Allergen[]
): Allergen[] => {
  const defaultAllergensLabels: string[] =
    getDefaultAllergensLabels(defaultAllergensList);

  return allergens.filter(({ value }: Allergen) =>
    defaultAllergensLabels.includes(value)
  );
};

export const getAllergenLabel = (
  { label, value }: Allergen,
  currentLanguage: string | null = DEFAULT_LANGUAGE
): string => {
  const labelFromValue: string = value.split('_').join(' ');

  if (!label?.length) {
    return labelFromValue;
  }

  const defaultLabel: string | undefined = label.find(({ language }) =>
    language.includes(DEFAULT_LANGUAGE)
  )?.value;
  const firstExistingLabel: string = label[0].value;
  const showedDefaultLabel: string = defaultLabel || firstExistingLabel;

  // TODO remove it when all allergens' labels consist of 'free' part
  // when PROD is ready
  const isFree: boolean = isAllergenFree(value);
  const labelFreeTail = isFree
    ? showedDefaultLabel.toLowerCase().indexOf('free') > 0
      ? ''
      : ' Free'
    : '';
  // TODO end

  if (currentLanguage && currentLanguage !== DEFAULT_LANGUAGE) {
    const currentLabel: string | undefined = label.find(({ language }) =>
      language.includes(currentLanguage)
    )?.value;

    return currentLabel || showedDefaultLabel;
  }

  return `${showedDefaultLabel}${labelFreeTail}`;
};

export const areItemsAllergensPassFilter = (
  itemAllergens: Allergen[],
  selectedAllergensByUser: Allergen[]
): boolean => {
  // selected allergens never(!) consists of contains allergens
  const allergenNotPassTheFilter = selectedAllergensByUser.find(
    ({ allergenOption, value: selectedAllergenValue }: Allergen) => {
      // TODO fix it when PROD is ready
      const value = selectedAllergenValue.split(' free_free').join('_free');
      // TODO end

      let allergenPassFilter: boolean = !!itemAllergens.find(
        itemAllergen => itemAllergen.value === value
      );

      if (allergenPassFilter) {
        // item has dietary restriction as selected
        // so f.e. if item has 'Egg Free' and selected 'Egg Free'
        // or item has 'Egg Trace' and selected 'Egg Trace'
        // no need check more, it is safe

        return false;
      }

      const allergenContainsValue: string = getAllergenWithoutModifier(value);
      let allergenNotPassTheFilter: boolean = !!itemAllergens.find(
        itemAllergen => itemAllergen.value === allergenContainsValue
      );

      if (allergenNotPassTheFilter) {
        // f.e. item has 'Egg Contains'
        // but selected dietary restriction is 'Egg Trace'/'Egg Free'
        // we need to filter this item it is not safe
        return allergenNotPassTheFilter;
      }

      // here is probably an issue

      if (allergenOption === 'free') {
        // f.e. selected 'Egg Free'
        // already checked item has no 'Egg Contains', no 'Egg Free'
        // need to check
        // if item consists of 'Egg Trace'
        // if yes, it is not safe
        const allergenTraceValue: string =
          getAllergenLabelWithDifferentModifier(value);

        allergenNotPassTheFilter = !!itemAllergens.find(
          itemAllergen => itemAllergen.value === allergenTraceValue
        );

        if (allergenNotPassTheFilter) {
          return allergenNotPassTheFilter;
        }
        // f.e. selected 'Egg Free'
        // item doesn't have 'Egg Contains', 'Egg Trace', 'Egg Free'
        // item is safe
        // https://rockspoon.slack.com/archives/C06C94JA1RC/p1709654857355889
        return false;
      }

      // allergenOption === 'trace' here
      // f.e. selected 'Egg Trace'
      // we already checked there is no 'Egg Contains', 'Egg Trace'
      // so item has 'Egg Free' or don't have any from 'Egg Contains', 'Egg Trace', 'Egg Free'
      // item is safe
      // https://rockspoon.slack.com/archives/C06C94JA1RC/p1709654857355889
      return false;
    }
  );

  return !allergenNotPassTheFilter;
};

export const convertOldAllergensValuesToNew = (value: string) => {
  let valueInLowerCase = value.toLowerCase();
  switch (true) {
    case valueInLowerCase.indexOf('tree nuts') > -1:
      valueInLowerCase = valueInLowerCase.split('tree nuts').join('nuts');
      break;
    case valueInLowerCase.indexOf('eggs') > -1:
      valueInLowerCase = valueInLowerCase.split('eggs').join('egg');
      break;
    case valueInLowerCase.indexOf('sugary products') > -1:
      valueInLowerCase = valueInLowerCase
        .split('sugary products')
        .join('sugar');
      break;
    case valueInLowerCase.indexOf('sulphites') > -1:
      valueInLowerCase = valueInLowerCase.split('sulphites').join('sulfites');
      break;
    default:
      break;
  }

  return valueInLowerCase;
};

export const getValidatedItemAllergens = (
  itemAllergens: Allergen[]
): Allergen[] =>
  uniqBy(
    itemAllergens.map(allergen => ({
      ...allergen,
      value: convertOldAllergensValuesToNew(allergen.value),
    })),
    'value'
  );
