import { Pipe, PipeTransform } from '@angular/core';
import { WineRating } from '../../_interfaces/item.model';

@Pipe({ name: 'rsWineReviewsSummary', standalone: true })
export class WineReviewsSummaryPipe implements PipeTransform {
  transform(wineRating: WineRating[]): number {
    if (!wineRating?.length) {
      return 100;
    }
    const reviewsCount = wineRating.length;

    const reviewsSum = wineRating.reduce(
      (accumulator, { rating }) => accumulator + rating,
      0
    );
    return Math.round(reviewsSum / reviewsCount);
  }
}
