import { Pipe, PipeTransform } from '@angular/core';

import { PaymentMethods } from 'src/app/_shared/_enums/payment-methods.enum';
import { PaymentViewDescription } from 'src/app/_shared/_interfaces/payment.model';

@Pipe({ name: 'rsIsAvailablePaymentType', standalone: true })
export class IsAvailablePaymentTypePipe implements PipeTransform {
  public transform(
    paymentType: PaymentMethods,
    availablePaymentMethods: PaymentViewDescription[]
  ): boolean {
    return (
      !!paymentType &&
      !!availablePaymentMethods?.length &&
      !!availablePaymentMethods.find(
        ({ placeholder }) => placeholder === paymentType
      )
    );
  }
}
