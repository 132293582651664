<div class="rs-country-select w-100">
  <mat-form-field class="rs-v3-input--small w-100" appearance="outline">
    <mat-select [formControl]="rsCountryControl" [compareWith]="compareWithFn">
      <mat-option *ngFor="let country of countries" [value]="country.code2">
        {{ country.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="rsCountryControl.hasError('required')">
      {{ 'ERRORS.required' | translate }}
    </mat-error>
  </mat-form-field>
</div>
