<div
  *ngIf="!(isLoggedIn$ | async) && !(isShadowUser$ | async)"
  class="rs-contact-info"
  [dir]="'languageDirection' | translate"
>
  <p class="rs-contact-info__title">
    {{ 'PAY_NOW.CONTACT_INFO' | translate | uppercase }}
  </p>

  <rs-contact-info-notification></rs-contact-info-notification>

  <div
    [id]="id.checkout.loginForm"
    class="rs-contact-info__form"
    [formGroup]="guestForm"
  >
    <rs-banner-alert
      *ngIf="guestForm.invalid || someContactInformationMissed"
      rs-status="error"
      rs-rounded="true"
      class="mt-3"
    >
      {{ 'CONTACTS.Please fill in contact information' | translate }}
    </rs-banner-alert>

    <div class="w-50 mt-3" [class.w-100]="isMobileView">
      <rs-input
        [control]="nameControl"
        [placeholder]="'CONTACTS.Name' | translate"
        (onInput)="reactOnNameInput()"
        [required]="true"
      ></rs-input>
    </div>

    <div class="w-50" [class.w-100]="isMobileView">
      <rs-input
        rs-input-style="email"
        [control]="emailControl"
        [placeholder]="'CONTACTS.Email' | translate"
        (onInput)="reactOnNameInput()"
        [required]="true"
      ></rs-input>
    </div>

    <div class="w-50" [class.w-100]="isMobileView">
      <rs-phone-input
        [phoneGroup]="phoneFormGroup"
        (phoneNumber)="handlePhoneControlChange($event)"
        [required]="true"
        [placeholder]="'CONTACTS.Phone_Number' | translate"
      >
      </rs-phone-input>
    </div>

    <div
      *ngIf="!emailControl?.value && !phoneFormGroup.value.phoneNumber"
      class="rs-contact-info__info d-flex"
    >
      <mat-icon
        class="rs-contact-info__info-icon"
        [svgIcon]="informationInCircleIcon"
      ></mat-icon>
      <span>{{ 'PAY_NOW.ENTER_EMAIL_PHONE' | translate }}</span>
    </div>
  </div>
</div>
