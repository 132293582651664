import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';

import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, debounceTime, first, map, Observable, of } from 'rxjs';

import { VenueState } from './_shared/_ngxs/venue.state';
import { SessionState } from './_shared/_ngxs/authentication.state';
import { RefreshToken } from './_shared/_ngxs/authentication.actions';
import { OpenLoginDialog } from './_shared/_ngxs/dialog.actions';
import { untilDestroyed } from './_shared/_utils/until-destroyed';
import { getVenueName } from './_shared/_utils/common';
import { ClearFavorites, GetFavorites } from './_shared/_ngxs/profile.actions';
import { LanguageState } from './_shared/_ngxs/language.state';
import { SessionStorageEngine } from './_services/session-storage.service';
import { LocalStorageService } from './_services/local-storage.service';
import { SessionStorageKeys } from './_shared/_enums/session-storage-keys.enum';
import { LocaleStorageKeys } from './_shared/_enums/local-storage-keys.enum';

export function setTimeout$(cb: () => void, timer: number) {
  of(true).pipe(debounceTime(timer), first()).subscribe(cb);
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title = 'rs.web.custom.renderer';
  public isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );
  public isTokenActive$: Observable<boolean> = this.store.select(
    SessionState.isTokenActive
  );
  public isShadowUser$: Observable<boolean> = this.store.select(
    SessionState.isShadowUser
  );

  public primaryColor$: Observable<string> = this.store.select(
    VenueState.primaryColor
  );
  public language$: Observable<string> = this.store
    .select(LanguageState.language)
    .pipe(
      map(lang => {
        return (
          this.sessionStorage.getItem(SessionStorageKeys.language) ||
          this.localStorage.getItem(LocaleStorageKeys.language) ||
          lang
        );
      })
    );
  public venueName: string = '';
  public path: string = '';

  private destroyed$ = untilDestroyed();

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private platformId: string,
    private readonly translate: TranslateService,
    private readonly store: Store,
    private readonly sessionStorage: SessionStorageEngine,
    private readonly localStorage: LocalStorageService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    translate.addLangs(['en', 'es', 'ru', 'pt', 'zh', 'ar']);
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => this.parseUrl());
    }
    this.addSubscriptions();
  }

  private parseUrl(): void {
    this.venueName = getVenueName(this.document.location.href);
    this.path = this.document.location.href;
  }

  private addSubscriptions(): void {
    combineLatest([
      this.isShadowUser$,
      this.isLoggedIn$,
      this.isTokenActive$,
      this.primaryColor$,
    ])
      .pipe(this.destroyed$())
      .subscribe(([isShadowUser, isLoggedIn, isTokenActive]) => {
        this.controlSession(isShadowUser, isLoggedIn, isTokenActive);
        this.checkFavorites(isLoggedIn);
        this.setColors();
      });

    this.language$.subscribe((language: string) =>
      this.translate.use(language)
    );

    this.activatedRoute.queryParams
      .pipe(this.destroyed$())
      .subscribe((params: Params) => {
        if (params['login']) {
          this.store.dispatch(new OpenLoginDialog(true));
        }
      });
  }

  private controlSession(
    isShadowUser: boolean,
    isLoggedIn: boolean,
    isTokenActive: boolean
  ): void {
    if (!isShadowUser && !isLoggedIn) {
      return;
    }

    if (isLoggedIn && !isTokenActive) {
      this.store.dispatch(new RefreshToken());
    }
  }

  private checkFavorites(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      this.store.dispatch(new GetFavorites());
    } else {
      this.store.dispatch(new ClearFavorites());
    }
  }

  private setColors(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout$(() => {
        const config = this.store.selectSnapshot(VenueState.config);

        document.body.style.setProperty('--primary', config?.primaryColor);
        document.body.style.setProperty(
          '--secondary',
          config?.secondaryColor || config?.primaryColor
        );
        document.body.style.setProperty('--common-primary', '#333');
        document.body.style.setProperty('--common-secondary', '#9b9b9b');
      }, 10);
    }
  }
}
