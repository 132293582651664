import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import {
  GetCountryCodeByNamePipe,
  GetCountryCodeByCode3Pipe,
  GetCountryNameByCode3Pipe,
  GetCountryNameByCodePipe,
} from './get-country-code.pipe';

/**
 * TO use flags from this component make sure that you add
 * <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css"
    />
    in your index.html file
 */

@Component({
  selector: 'rs-country-flag',
  templateUrl: './country-flag.component.html',
  standalone: true,
  imports: [
    CommonModule,
    GetCountryCodeByNamePipe,
    GetCountryCodeByCode3Pipe,
    GetCountryNameByCode3Pipe,
    GetCountryNameByCodePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryFlagComponent {
  @Input() code3!: string;
  @Input() name!: string;
  @Input() code!: string;
}
