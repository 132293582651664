import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

// ApplePaySession link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession
const domainUrl = environment.domain;

const config = {
  payments: {
    acceptedCardSchemes: ['amex', 'masterCard', 'maestro', 'visa', 'mada'],
  },
  requiredBillingContactFields: ['name', 'postalAddress', 'phone', 'email'],
  requiredShippingContactFields: ['email', 'phone'],
  shop: {
    product_price: '0.00',
    shop_name: '',
    shop_localisation: {
      currencyCode: 'USD',
      countryCode: 'US',
    },
  },
  countryCode: 'US',
  currencyCode: 'USD',
  merchantCapabilities: [
    'supports3DS',
    // 'supportsEMV',
    'supportsCredit',
    'supportsDebit',
  ],
  supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
  total: {
    label: '',
    type: 'final',
    amount: '0.00',
  },
};
const paymentUrl = environment.paymentUrl;

@Injectable({ providedIn: 'root' })
export class ApplePayService {
  constructor(private readonly http: HttpClient) {}

  public request = config;

  // public pay(request: ApplePayJS.ApplePayPaymentRequest): void {
  //   const session = new ApplePaySession(3, config);
  //
  //   session.begin();
  // }
  //
  // public async isApplePayAvailable(): Promise<boolean> {
  //   if ((window as any).ApplePaySession) {
  //     const canMakePayments = await ApplePaySession.canMakePayments();
  //
  //     return !!canMakePayments;
  //   } else {
  //     return false;
  //   }
  // }

  public validateMerchant(appleUrl: string) {
    console.log('_validateApplePaySession!');
    return this.http
      .post<any>(`${paymentUrl}/payment/v2/apple-pay/validate`, {
        validationURL: appleUrl,
        domain: domainUrl,
      })
      .pipe();
  }

  public _performTransaction(details: any) {
    console.log('_performTransaction!');

    //GET Email and Phone number
    console.log(details.shippingContact);

    console.log(details.billingContact);

    let url = `${paymentUrl}/apple-pay`;
    return this.http
      .post<any>(url, {
        amount: 1,
        checkId: '6036a178d96e8114f6b5858b',
        applePay: {
          token: details.token,
          billingContact: details.billingContact,
        },
      })
      .pipe();
  }

  private _calculateTotal(subtotal: string, shipping: string) {
    console.log('_calculateTotal');
    return (parseFloat(subtotal) + parseFloat(shipping)).toFixed(2);
  }

  public onLoadPaymentData(event: any) {
    console.log(`onLoadPaymentData`);
    console.log(event);
  }
}
