export enum OrderType {
  DineIn = 'dine-in',
  Qsr = 'qsr',
  Delivery = 'delivery',
  Takeout = 'takeout',
  Curbside = 'curbside',
  ThirdParty = 'thirdParty',
  Catering = 'catering',
  GiftCard = 'gift-card',
}

export enum OrderDomain {
  Restaurant = 'restaurant',
  Store = 'store',
  ECommerce = 'e-commerce',
}

export enum OrderStatus {
  Open = 'open',
  Closed = 'closed',
  Deleted = 'deleted',
  Canceled = 'canceled',
}

export enum RestaurantOrderItemStatus {
  Canceled = 'canceled',
  Pending = 'pending',
  Fired = 'fired',
  FulfilledWithoutFiring = 'fulfilled_without_firing',
  Voided = 'voided',
}

export enum DeliveryMethod {
  Delivery = 'delivery',
  Takeout = 'takeout',
  Curbside = 'curbside',
}

export enum EntryType {
  Discount = 'discount',
  DeliveryFee = 'delivery_fee',
  DeliveryFeeExternal = 'delivery_fee_external',
  ExtraFeeBefore = 'extra_fee_before',
  Tax = 'tax',
  ExtraFeeAfter = 'extra_fee_after',
  ServiceCharges = 'service_charges',
  OrderItem = 'order_item',
  Modifier = 'modifier',
  ModifierOption = 'modifier_option',
}

export enum OrderReviewType {
  MenuItem = 'menuItem',
  Venue = 'venue',
  Server = 'waiter',
}

export enum ReorderingPoints {
  SuccessScreen = 'successScreen',
  OrdersScreen = 'ordersScreen',
}

export type DeliveryType = DeliveryMethod | OrderType.DineIn;
