import { isPlatformServer } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'rs-wine-details-item',
  templateUrl: 'wine-details-item.component.html',
  styleUrls: ['wine-details-item.component.scss'],
})
export class WineDetailsItemComponent implements OnInit, OnDestroy {
  @ViewChild('valueContainer', { static: true }) valueContainer!: ElementRef;

  @Input() from: string = '';
  @Input() to: string = '';
  @Input() items: string[] = [];
  @Input() value: string = '';

  public width: number = 0;
  public left: number = 0;

  private onResize = () => this.setValuePosition();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.setValuePosition();

    window.addEventListener('resize', this.onResize);
  }

  private setValuePosition(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    setTimeout(() => {
      const { width } =
        this.valueContainer?.nativeElement?.parentElement?.getBoundingClientRect();
      const currentIndex = this.items.findIndex(item => item === this.value);

      this.width = width / this.items.length;
      this.left = this.width * currentIndex;

      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }
}
