import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

import { Store } from '@ngxs/store';

import { SharedModule } from '../../shared.module';
import { FullOrderInformationInnerComponent } from '../full-order-information-inner/full-order-information-inner.component';

import { SetGuestUserPhoneData } from 'src/app/_shared/_ngxs/cart.actions';

import { OrderData } from 'src/app/_shared/_interfaces/delivery.model';

import { isObjectEmpty } from 'src/app/_shared/_utils/common';
import { UpdateOrderData } from 'src/app/_shared/_ngxs/order-data.actions';

@Component({
  selector: 'rs-schedule-order-bottom-sheet',
  standalone: true,
  imports: [SharedModule, FullOrderInformationInnerComponent],
  templateUrl: './schedule-order-bottom-sheet.component.html',
  styleUrls: ['./schedule-order-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleOrderBottomSheetComponent {
  public isCheckout: boolean = false;
  public partialOrderData!: Partial<OrderData>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    readonly bottomSheetData: { isCheckout: boolean },
    private readonly bottomSheetRef: MatBottomSheetRef<ScheduleOrderBottomSheetComponent>,
    private readonly store: Store
  ) {
    this.isCheckout = bottomSheetData.isCheckout;
  }

  public finishUpdate(): void {
    if (!this.partialOrderData) {
      return;
    }

    const actions: any[] = [];

    !!this.partialOrderData.phone?.phoneNumber &&
      actions.push(new SetGuestUserPhoneData(this.partialOrderData.phone));
    !isObjectEmpty(this.partialOrderData) &&
      this.store.dispatch([
        ...actions,
        new UpdateOrderData(this.partialOrderData),
      ]);

    this.close();
  }

  public close(): void {
    this.bottomSheetRef.dismiss();
  }
}
