import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Store } from '@ngxs/store';
import { filter, first, map, Observable, Subject, takeUntil } from 'rxjs';

import { SharedModule } from '../../_modules/shared.module';
import { ContactInfoNotificationComponent } from '../contact-info-notification/contact-info-notification.component';
import { RsPhoneInputModule } from '../../_rs-design/phone-input/phone-input.module';
import { RsBannerAlertComponent } from '../../_rs-design/banner/alert/banner-alert.component';

import { OrderDataState } from '../../_ngxs/order-data.state';
import { SessionState } from '../../_ngxs/authentication.state';
import { CartState } from '../../_ngxs/cart.state';
import {
  SetGuestUserData,
  SetGuestUserPhoneData,
} from '../../_ngxs/cart.actions';
import { SaveUserName } from '../../_ngxs/authentication.actions';
import { UpdateOrderPhone } from '../../_ngxs/order-data.actions';

import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';
import {
  GuestUserData,
  GuestUserFormData,
} from '../../_interfaces/session.model';
import { PhoneNumber } from 'src/app/_shared/_models/phone-number.model';
import { GiftCard } from '../../_interfaces/gift-card.model';

import { Phone } from '../../_models/common.interface';

import { REGEX } from '../../_constants/regular-expressions';
import {
  DEFAULT_COUNTRY,
  PHONE_DEFAULT_TYPE,
} from 'src/app/_shared/_rs-design/phone-input/phone-input.constants';
import { TEST_ID } from '../../_constants/e2e-ids.constants';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';

import { minimumOneValueIsValid } from '../../_utils/email-phone';
import { untilDestroyed } from '../../_utils/until-destroyed';
import { DeviceService } from 'src/app/_services/device.service';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-contact-info',
  standalone: true,
  imports: [
    SharedModule,
    ContactInfoNotificationComponent,
    RsPhoneInputModule,
    RsBannerAlertComponent,
  ],
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent {
  public get nameControl(): AbstractControl | null {
    return this.guestForm.controls['name'];
  }

  public get emailControl(): AbstractControl | null {
    return this.guestForm.controls['email'];
  }

  public readonly isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );

  public readonly isShadowUser$: Observable<boolean> = this.store.select(
    SessionState.isShadowUser
  );

  public readonly guestUserData$: Observable<GuestUserData> = this.store.select(
    CartState.guestUserData
  );

  public readonly orderPhone$: Observable<Phone | undefined> =
    this.store.select(OrderDataState.orderPhone);

  public readonly phoneFormGroup: FormGroup = new FormGroup({
    phoneNumber: new FormControl(''),
    countryCode: new FormControl(DEFAULT_COUNTRY),
    type: new FormControl(PHONE_DEFAULT_TYPE, [Validators.required]),
  });
  public guestForm: FormGroup = new FormGroup(
    {
      name: new FormControl('', Validators.required),
      email: new FormControl(null, Validators.pattern(REGEX.EMAIL)),
      phone: this.phoneFormGroup,
    },
    { validators: minimumOneValueIsValid() }
  );

  protected isMobileView: boolean = false;
  public someContactInformationMissed: boolean = true;

  public readonly informationInCircleIcon = CommonIcons.InformationInCircle;
  public readonly id = TEST_ID;
  private readonly destroyed$ = untilDestroyed();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly formBuilder: FormBuilder,
    protected readonly deviceService: DeviceService,
    private readonly svgIconService: SvgIconService
  ) {}

  public ngOnInit(): void {
    this.registerIcons();
    this.setView();
    this.initializeForm();
    this.subscribeOnPhoneChanges();
    this.checkIfContactInformationMissed();

    this.guestForm.valueChanges
      .pipe(this.destroyed$())
      .subscribe((value: GuestUserFormData) => {
        this.checkIfContactInformationMissed();
        this.store.dispatch(new SetGuestUserData(value, this.guestForm.valid));
      });
  }

  public checkIfContactInformationMissed(): void {
    this.someContactInformationMissed =
      !this.phoneFormGroup.value.phoneNumber ||
      !this.nameControl?.value ||
      !this.emailControl?.value;
  }

  public reactOnNameInput(): void {
    this.store.dispatch(new SaveUserName(this.guestForm.value.name));
  }

  public subscribeOnPhoneChanges(): void {
    this.isLoggedIn$
      .pipe(first(isLoggedIn => !isLoggedIn))
      .subscribe(() => this.subscribeOnPhoneNumberChanges());
  }

  public destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkIfPhoneExists(): void {
    this.destroy();

    if (!this.phoneFormGroup.value.phoneNumber) {
      this.destroy$ = new Subject<void>();
      this.subscribeOnPhoneChangesInState();
    }
  }

  private subscribeOnPhoneNumberChanges(): void {
    this.checkIfPhoneExists();

    this.phoneFormGroup.valueChanges
      .pipe(this.destroyed$())
      .subscribe(() => this.checkIfPhoneExists());
  }

  private subscribeOnPhoneChangesInState(): void {
    this.orderPhone$
      .pipe(takeUntil(this.destroy$), this.destroyed$(), filter(Boolean))
      .subscribe(phoneNumber =>
        this.phoneFormGroup.patchValue({
          phoneNumber: phoneNumber.phoneNumber,
          countryCode: phoneNumber.countryCode,
        })
      );

    this.guestUserData$
      .pipe(
        takeUntil(this.destroy$),
        map((guestUserData: GuestUserData | undefined) => guestUserData?.phone),
        filter((phone: Phone | undefined) => !!phone?.phoneNumber),
        this.destroyed$()
      )
      .subscribe((phone: Phone | undefined) =>
        this.guestForm.patchValue({ phone: phone })
      );
  }

  public handlePhoneControlChange(phoneValue: PhoneNumber): void {
    const phone: Phone = {
      countryCode: phoneValue.countryCode,
      phoneNumber: phoneValue.phoneNumber,
      type: phoneValue.type,
    };

    this.destroy();
    this.phoneFormGroup.patchValue(phone);
    this.store.dispatch([
      new SetGuestUserPhoneData(phoneValue),
      new UpdateOrderPhone(phone),
    ]);
  }

  private initializeForm(): void {
    let name: string = '';
    let email: string = '';
    const giftCards: GiftCard[] = this.store.selectSnapshot(
      CartState.giftCards
    );

    if (giftCards.length) {
      const { senderEmail, senderName } = giftCards[0];

      name = senderName || '';
      email = senderEmail || '';
    }

    this.guestForm = this.formBuilder.group(
      {
        name: new FormControl(name || '', Validators.required),
        email: new FormControl(email || '', Validators.pattern(REGEX.EMAIL)),
        phone: this.phoneFormGroup,
      },
      { validators: minimumOneValueIsValid() }
    );

    this.store.dispatch(
      new SetGuestUserData(this.guestForm.value, this.guestForm.valid)
    );
  }

  private setView(): void {
    this.deviceService
      .isMobile()
      .pipe(this.destroyed$())
      .subscribe(isMobile => (this.isMobileView = isMobile));
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.informationInCircleIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
