import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'rsDinersString' })
export class RsDinersStringPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  public transform(diners: { name: string }[]): string {
    if (diners.length === 1) {
      return diners[0].name;
    } else {
      let dinersString: string = '';
      const and = this.translate.instant('and') || '';

      diners.forEach(
        ({ name }, index) =>
          (dinersString +=
            name +
            (index === diners.length - 2
              ? ' ' + and
              : index === diners.length - 1
              ? ''
              : ',') +
            ' ')
      );

      return dinersString;
    }
  }
}
