<rs-chip-suggestion
  *ngIf="allergen"
  [selected]="selected && !disabled"
  [disabledLook]="disabled"
  [showActionIcon]="true"
  [attr.aria-label]="
    selected
      ? ('DIETARY_RESTRICTIONS.Unselect {allergen}'
        | translate
          : { allergen: (allergen | rsGetAllergenLabel: (language$ | async)) })
      : ('DIETARY_RESTRICTIONS.Select {allergen}'
        | translate
          : { allergen: (allergen | rsGetAllergenLabel: (language$ | async)) })
  "
  (change)="toggle()"
>
  <mat-icon
    *ngIf="allergen.value | rsDietaryRestrictionIcon as icon"
    class="rs-suggestion-chip__icon"
    [ngClass]="{
      'rs-suggestion-chip__icon--save-color': !isAllergenFree
    }"
    [svgIcon]="icon"
  ></mat-icon>
  {{ allergen | rsGetAllergenLabel: (language$ | async) }}
</rs-chip-suggestion>
