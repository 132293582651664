import { Pipe, PipeTransform } from '@angular/core';

import { find } from 'lodash';

@Pipe({ name: 'isObjectInArray' })
export class IsObjectInArrayPipe implements PipeTransform {
  transform(items: Object[], property: any, propertyName: string): boolean {
    return !!find(items, [propertyName, property]);
  }
}
