import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Store } from '@ngxs/store';

import { SharedModule } from '../../_modules/shared.module';
import { SessionState } from '../../_ngxs/authentication.state';

import { PoweredByRockspoonComponent } from 'src/app/_shared/_modules/powered-by-rockspoon/powered-by-rockspoon.component';
import { LanguageComponent } from '../language/language.component';
import { PasswordStrengthComponent } from '../create-password-form/password-strength/password-strength.component';
import { AuthorisationLoginComponent } from './authorisation-login/authorisation-login.component';
import { AuthorisationSignUpComponent } from './authorisation-sign-up/authorisation-sign-up.component';
import { AuthorisationGuestComponent } from './authorisation-guest/authorisation-guest.component';
import { RsPanelWrapperComponent } from 'src/app/_shared/_components/rs-panel-wrapper/rs-panel-wrapper.component';

import { AutorisationType } from './autorisation';

import { CookieStorageKeys } from 'src/app/_shared/_enums/cookie-storage-keys.enum';
import { CookieEngine } from 'src/app/_services/cookie.service';

@Component({
  selector: 'rs-authorisation',
  standalone: true,
  imports: [
    SharedModule,
    PoweredByRockspoonComponent,
    LanguageComponent,
    PasswordStrengthComponent,
    AuthorisationLoginComponent,
    AuthorisationSignUpComponent,
    AuthorisationGuestComponent,
    RsPanelWrapperComponent,
  ],
  templateUrl: 'authorisation.component.html',
  styleUrls: ['authorisation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorisationComponent {
  @Input() set autorisation(autorisation: AutorisationType) {
    this.detectPageType(autorisation);
  }
  @Input() hiddenAutorisationType!: AutorisationType | undefined;
  public isShadowUser = this.store.selectSnapshot(SessionState.isShadowUser);
  public previouslySavedName!: string;

  public showCreatePasswordPart!: boolean;
  public showForgotPassword!: boolean;

  public isResetPassword!: boolean;
  public isLogin!: boolean;
  public isSignUp!: boolean;
  public isGuest!: boolean;

  public readonly AutorisationType = AutorisationType;

  constructor(
    private readonly cookieEngine: CookieEngine,
    private readonly store: Store,
    @Inject(DOCUMENT) private readonly document: any
  ) {
    this.checkIfResetPasswordPage();
    this.clearPreviousSavedData();
  }

  ngOnInit(): void {
    this.getPrevioslySavedData();
  }

  public detectPageType(autorisation: AutorisationType): void {
    this.isResetPassword =
      this.isResetPassword || autorisation === AutorisationType.RestorePassword;
    this.isLogin = autorisation === AutorisationType.Login;
    this.isGuest = autorisation === AutorisationType.Guest;
    this.isSignUp = autorisation === AutorisationType.SignUp;

    this.isResetPassword && (this.showCreatePasswordPart = true);
  }

  private checkIfResetPasswordPage(): void {
    this.isResetPassword =
      this.document?.location?.href.indexOf('resetPassword') > -1;
    this.showCreatePasswordPart = this.isResetPassword;
  }

  private clearPreviousSavedData(): void {
    this.showCreatePasswordPart = false;
    this.showForgotPassword = false;
  }

  private getPrevioslySavedData(): void {
    this.previouslySavedName =
      this.cookieEngine.getItem(CookieStorageKeys.previouslySavedName) || '';
  }

  public reactOnCloseFirstTab(): void {
    !this.isResetPassword && (this.showCreatePasswordPart = false);
  }
}
