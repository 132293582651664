import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import {
  Card,
  NewPaymentAddressToAdd,
} from 'src/app/profile/_interfaces/payment.model';
import { Profile } from 'src/app/profile/_interfaces/profile.model';
import { ChangePasswordRequest } from 'src/app/_shared/_models/authentication.model';
import { UpdateOrderReviewPayload } from '../_interfaces/order.model';

export class UpdateAndSetDefaultAddress {
  static readonly type = '[Address] Update and Set Default';

  constructor(
    public address: Partial<UserProfileAddress>,
    public addressId: string,
    public newDefault: string
  ) {}
}

export class UpdateAddress {
  static readonly type = '[Address] Update';

  constructor(
    public address: Partial<UserProfileAddress>,
    public addressId: string
  ) {}
}

export class GetLoyaltyInformation {
  static readonly type = '[Profile Loyalty] Get history';
}
export class ClaimPoints {
  static readonly type = '[Profile Loyalty] Claim points';
  constructor(public code: string) {}
}
export class UsePoints {
  static readonly type = '[Profile Loyalty] Use points';
  constructor(public points: number) {}
}
export class ClearPointsMessage {
  static readonly type = '[Profile Loyalty] Clear Point Message';
}
export class DeleteDefaultAddress {
  static readonly type = '[Default Address] Delete';

  constructor(
    public newDefaultAddressId: string,
    public deletedAddressId: string
  ) {}
}

export class InitializePaymentMethods {
  static readonly type = '[Profile] Initialize Payment Methods';
}

export class GetAddressBook {
  static readonly type = '[Address Book] Get';
}

export class AddNewAddress {
  static readonly type = '[Address] Add';

  constructor(public address: UserProfileAddress) {}
}

export class AddNewAndSetAsDefaultAddress {
  static readonly type = '[Address] Add And Set As Default';

  constructor(public address: UserProfileAddress) {}
}

export class DeleteAddress {
  static readonly type = '[Address] Delete';

  constructor(public addressId: string) {}
}

export class SetAddressAsDefault {
  static readonly type = '[Address] Set as Default';

  constructor(public addressId: string) {}
}

export class DeleteCard {
  static readonly type = '[Profile] Delete Card';

  constructor(public readonly cardId: string) {}
}

export class SetDefaultCard {
  static readonly type = '[Profile] Set Default Card';

  constructor(public readonly cardId: string) {}
}
export class SaveNewCard {
  static readonly type = '[Profile] Save New Card';

  constructor(
    public readonly card: Card,
    public readonly address: NewPaymentAddressToAdd
  ) {}
}

export class GetProfileInfo {
  static readonly type = '[Profile] Get Profile Info';
}

export class DeleteProfileInfo {
  static readonly type = '[Profile] Delete Profile Info';
}

export class SaveProfileInfo {
  static readonly type = '[Profile] Save Profile Info';

  constructor(public info: Partial<Profile>, public image: Blob | null) {}
}

export class SaveAllergensPreferences {
  static readonly type = '[AllergensState] SaveAllergensPreferences';

  constructor(public allergens: string[]) {}
}

export class SavePassword {
  static readonly type = '[Profile] Save Password';

  constructor(public password: ChangePasswordRequest) {}
}

export class GetProfilePreferences {
  static readonly type = '[Profile] Get Profile Preferences';
}

export class GetProfileOrders {
  public static readonly type = '[Profile] Get Profile Orders';
}

export class SetProfileOrderFavorite {
  public static readonly type = '[Profile] Set Profile Order Favorite';

  constructor(
    public readonly orderId: string,
    public readonly isFavorited: boolean
  ) {}
}

export class GetProfileOrderPaymentDetails {
  public static readonly type = '[Profile] Get Order Payment Details';

  constructor(public paymentIds: string[], public orderId: string) {}
}

export class GetProfileOrderReviewDetails {
  public static readonly type = '[Profile] Get Order Review Details';

  constructor(public checkId: string, public orderId: string) {}
}

export class UpdateProfileOrderReviewDetails {
  public static readonly type = '[Profile] Update Order Review Details';

  constructor(
    public orderId: string,
    public reviews: UpdateOrderReviewPayload[]
  ) {}
}

export class DownloadOrderSummary {
  public static readonly type = '[Profile] Download Order Summary';

  constructor(public paymentIds: string[], public orderShortId: string) {}
}

export class GetAllAllergens {
  public static readonly type = '[Profile] Get All Allergens';
}

export class GetProfileGiftCards {
  public static readonly type = '[Profile] Get Profile Gift Cards';
}

export class GetFavorites {
  public static readonly type = '[Profile] Get Favorites';
}

export class ClearFavorites {
  public static readonly type = '[Profile] Clear Favorites';
}

export class AddToFavorites {
  public static readonly type = '[Profile] Add To Favorites';

  constructor(
    public itemId: string,
    public menuId: string,
    public menuSectionId: string
  ) {}
}

export class RemoveFromFavorites {
  public static readonly type = '[Profile] Remove From Favorites';

  constructor(
    public itemId: string,
    public menuId?: string,
    public menuSectionId?: string
  ) {}
}

export class GetProfileOrderDetails {
  public static readonly type = '[Profile] Get Profile Order Details';

  constructor(public orderId: string) {}
}

export class ClearProfileData {
  public static readonly type = '[Profile] Get Profile Data';
}
