<rs-schedule-order
  (selectedDayChange)="updateData('day', $event)"
  (selectedTimeChange)="updateData('time', $event)"
  (selectedOrderMethod)="updateData('method', $event)"
>
  <rs-select-address
    *ngIf="orderMethod === DeliveryMethod.Delivery"
    [isDialog]="false"
    [selectedAddress]="orderAddress$ | async"
    [selectedPhone]="orderPhone$ | async"
    (selectedAddressChange)="updateData('address', $event)"
    (selectedPhoneChange)="updateData('phone', $event)"
  ></rs-select-address>
  <div class="mt-3">
    <rs-select-car
      *ngIf="isCheckout && orderMethod === DeliveryMethod.Curbside"
      [selectedCar]="orderCar$ | async"
      (selectedCarChange)="updateData('car', $event)"
    ></rs-select-car>
  </div>
</rs-schedule-order>
