<rs-full-height-dialog rs-dialog-style="default">
  <ng-container rs-dialog-header>
    <mat-icon
      [svgIcon]="arrowBackIcon"
      (click)="handleBackArrowButtonClick()"
    ></mat-icon>

    <span class="rs-one-line-text-ellipsis">
      {{
        cameFromCart
          ? ('DELIVERY_METHOD.Back to cart' | translate)
          : (currentItem?.customerFacingName ||
              currentItem?.name ||
              dialogData.menuName | uppercase)
      }}
    </span>

    <button rs-shape="circle" rs-style="standart" (click)="share()">
      <mat-icon [svgIcon]="exportIcon"></mat-icon>
    </button>
  </ng-container>
  <rs-item-details
    [cameFromCart]="cameFromCart"
    (goBackOutSide)="handleChildEmitter($event)"
  ></rs-item-details>
</rs-full-height-dialog>
