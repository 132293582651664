import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Params, Router } from '@angular/router';
import { ObserversModule } from '@angular/cdk/observers';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { TooltipComponent } from 'src/app/_shared/_rs-design/tooltip/tooltip/tooltip.component';
import { FoodItemAllergensComponent } from '../allergens-dietary-restrictions/item-allergens/item-allergens/food-item-allergens.component';
import { CountryFlagComponent } from '../country/country-flag.component';
import { WineReviewsSummaryPipe } from 'src/app/_shared/_components/wine-reviews/wine-reviews-summary.pipe';
import { RsDefaultFoodIconComponent } from '../rs-default-food-icon/rs-default-food-icon.component';
import {
  GetCountryCodeByCode3Pipe,
  GetCountryCodeByNamePipe,
  GetCountryNameByCode3Pipe,
  GetCountryNameByCodePipe,
} from '../country/get-country-code.pipe';

import { LanguageState } from 'src/app/_shared/_ngxs/language.state';

import { ItemState } from '../../_ngxs/item.state';
import { SetItemData } from '../../_ngxs/item.actions';
import { VenueState } from '../../_ngxs/venue.state';
import { FilteredMenuState } from '../../_ngxs/filtered-menu.state';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { Allergen, Item } from 'src/app/_shared/_interfaces/item.model';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { DefaultFoodIconSize } from 'src/app/_shared/_enums/default-food-icon-size.enums';

import { Menu } from '../../_interfaces/menu.model';

import {
  getImagePathToNoRes,
  getImageToCDN,
} from 'src/app/_shared/_utils/image';
import { formItemDetailsUrlAndParams } from '../../_utils/items.helper';
import { getPathWithoutBaseUrl } from '../../_utils/common';

import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-food-suggested-item',
  standalone: true,
  templateUrl: 'rs-food-item-suggested.component.html',
  imports: [
    ObserversModule,
    SharedModule,
    TooltipComponent,
    CountryFlagComponent,
    GetCountryCodeByNamePipe,
    GetCountryCodeByCode3Pipe,
    GetCountryNameByCode3Pipe,
    GetCountryNameByCodePipe,
    FoodItemAllergensComponent,
    WineReviewsSummaryPipe,
    RsDefaultFoodIconComponent,
  ],
  styleUrls: ['./rs-food-item-suggested.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsFoodItemSuggestedComponent {
  @ViewChild('rsImage', { read: ElementRef, static: false })
  public image!: ElementRef<HTMLElement>;

  @Input() set item(item: Item) {
    this._item = item;

    item?.image &&
      (this.itemImage = getImagePathToNoRes(getImageToCDN(item.image)));

    item &&
      (this.itemName =
        item.alcoholicAttributes?.string === 'wine'
          ? item.alcoholicAttributes?.style +
            ', ' +
            item.alcoholicAttributes?.vintage
          : item.customerFacingName || item.name);
  }
  @Input() showAllergens: boolean = false;

  public readonly language$ = this.store.select(LanguageState.language);

  public itemAllergens$!: Observable<Allergen[] | undefined | null>;
  public _item!: Item;
  public itemName!: string;
  public itemImage!: string;
  public showFavoriteButton!: boolean;
  public titleMargin!: number;
  public menuRoute!: string;
  // TODO change it when calories and rating are available
  public showRating!: boolean;
  public showCalories!: boolean;
  // TODO end
  private menuId: string = '';
  private sectionId: string = '';

  public readonly DefaultFoodIconSize = DefaultFoodIconSize;
  public readonly addIcon = CommonIcons.Add;
  public readonly starIcon = CommonIcons.Star;

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store,
    private readonly router: Router,
    private readonly matDialog: MatDialog,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcons();
  }

  public ngOnInit(): void {
    this.itemAllergens$ = this.showAllergens
      ? this.store.select(ItemState.allergens)
      : of(null);
  }

  public ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.calculatePosition();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.addIcon, this.starIcon],
      NEW_ICONS_DIRECTORY
    );
  }

  private calculatePosition(): void {
    const isDesktopCard: boolean = window.innerWidth >= 480;
    const favoriteButtonDistance: number = this.showFavoriteButton ? 48 : 0;
    const defaultPaddingFromCorner: number = isDesktopCard ? 12 : 14;

    this.titleMargin = isDesktopCard
      ? defaultPaddingFromCorner + favoriteButtonDistance
      : favoriteButtonDistance;

    this.changeDetectorRef.detectChanges();
  }

  private getMenuId(): string {
    return this.getMenuItem()?.menuId ?? '';
  }

  private getMenuItem(): Menu | undefined {
    const menus: Menu[] =
      this.store.selectSnapshot(FilteredMenuState.menus) || [];
    const currentMenu: Menu | undefined = menus.find(
      ({ menuId, sections }: Menu) =>
        !!sections.find(({ sectionId, items }) =>
          items.find(({ itemId }: Item) => {
            if (itemId === this._item?.itemId && !!menuId) {
              this.menuId = menuId;
              this.sectionId = sectionId;
            }

            return itemId === this._item?.itemId;
          })
        )
    );

    return currentMenu;
  }

  private getItemDetailsUrlAndParams(): {
    url: string;
    queryParams: Params;
  } {
    const formattedVenueName: string = this.store.selectSnapshot(
      VenueState.formattedVenueName
    );

    const isDineIn = getPathWithoutBaseUrl(
      this.document.location.href
    ).includes('dine-in');

    return formItemDetailsUrlAndParams(
      isDineIn ? 'dine-in' : 'togo',
      this._item.itemId,
      this.menuId,
      this.sectionId,
      formattedVenueName,
      true
    );
  }

  public goToItem(): void {
    !this.menuId && (this.menuId = this.getMenuId());

    if (!this._item || !this.menuId) {
      throw new Error('There is no menu id');
    }

    this.store.dispatch(
      new SetItemData({
        item: this._item,
        menuId: this.menuId,
        menuSectionId: this.sectionId,
        metadata: this._item.metadata,
      })
    );

    const { url, queryParams } = this.getItemDetailsUrlAndParams();

    this.matDialog.closeAll();
    this.router.navigate([url], {
      queryParams: {
        ...queryParams,
        suggestions: true,
      },
    });
  }
}
