import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { Observable, filter, first } from 'rxjs';

import { SharedModule } from '../../../_modules/shared.module';
import { AuthorisationWrapperComponent } from '../ui/authorisation-wrapper/authorisation-wrapper.component';
import { RsFullHeightDialogComponent } from 'src/app/_shared/_rs-design/dialog/full-height-dialog.component';

import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

import {
  getHomePageUrlForRedirection,
  getVenueName,
} from 'src/app/_shared/_utils/common';

import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import {
  GetShadowUserToken,
  Login,
  SignUp,
} from 'src/app/_shared/_ngxs/authentication.actions';
import { RemoveDialogFromHistory } from 'src/app/_shared/_ngxs/dialog.actions';
import { GuestRedirect } from 'src/app/_shared/_ngxs/digital-storefront.actions';
import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';
import { AutorisationType } from '../autorisation';

@Component({
  selector: 'rs-authorisation-dialog',
  standalone: true,
  imports: [
    SharedModule,
    AuthorisationWrapperComponent,
    RsFullHeightDialogComponent,
  ],
  templateUrl: 'authorisation-dialog.component.html',
  styles: [
    ':host ::ng-deep .rs-dialog__body { padding-left:0; padding-right: 0;}',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorisationDialogComponent {
  private isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );

  public venueName!: string;
  public openedPanelType!: AutorisationType;
  public hiddenAutorisationType!: AutorisationType;

  public readonly closePopupIcon: CommonIcons = CommonIcons.Close;
  private destroyed$ = untilDestroyed();

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      skipRedirect?: boolean;
      email?: string;
      phone?: { countryCode: string; phoneNumber: string } | null;
      openedPanelType?: AutorisationType;
      hiddenAutorisationType?: AutorisationType;
    },
    private readonly svgIconService: SvgIconService,
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<AuthorisationDialogComponent>,
    private readonly actions$: Actions
  ) {
    this.registerCloseIcon();
    this.initialize();
    this.addSubscriptions();

    this.data.openedPanelType &&
      (this.openedPanelType = this.data.openedPanelType);
    this.data.hiddenAutorisationType &&
      (this.hiddenAutorisationType = this.data.hiddenAutorisationType);
  }

  private registerCloseIcon(): void {
    this.svgIconService.registerSvgIcons(
      [this.closePopupIcon],
      NEW_ICONS_DIRECTORY
    );
  }

  private initialize(): void {
    this.venueName = getVenueName(this.document.location.href);
  }

  private addSubscriptions(): void {
    !this.data?.skipRedirect && this.redirectAfterUserLogin();

    this.actions$
      .pipe(
        ofActionSuccessful(Login, SignUp, GetShadowUserToken),
        this.destroyed$()
      )
      .subscribe(() => {
        this.store.dispatch(new RemoveDialogFromHistory(MatDialogId.login));
        this.dialogRef.close(true);

        setTimeout(() => {
          this.store.dispatch(new GuestRedirect());
        }, 500);
      });
  }

  private redirectAfterUserLogin(): void {
    this.isLoggedIn$
      .pipe(
        filter(isLoggedIn => !!isLoggedIn),
        first()
      )
      .subscribe(() => {
        const currentUrl = this.document?.location?.href || '';
        const isMenu =
          currentUrl.includes('/menu/') ||
          currentUrl.includes('/order/') ||
          currentUrl.includes('/dine-in/');

        !isMenu &&
          this.router.navigate(
            [getHomePageUrlForRedirection(this.venueName, this.router.url)],
            {
              relativeTo: this.activatedRoute,
            }
          );
      });
  }
}
