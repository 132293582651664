import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';

import { SharedModule } from '../../_modules/shared.module';
import { AddressAutocompleteComponent } from '../address-autocomplete/address-autocomplete.component';
import { CountrySelectModule } from '../country-select/country-select.module';
import { FormInputModule } from '../form-input/form-input.module';
import { StateSelectModule } from '../state-select/state-select.module';
import { RsPhoneInputModule } from '../../_rs-design/phone-input/phone-input.module';
import { FormInputComponent } from '../form-input/form-input.component';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { untilDestroyed } from '../../_utils/until-destroyed';
import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';
import { TEST_ID } from '../../_constants/e2e-ids.constants';
import {
  DEFAULT_COUNTRY,
  PHONE_DEFAULT_TYPE,
} from 'src/app/_shared/_rs-design/phone-input/phone-input.constants';
import { PhoneNumber } from 'src/app/_shared/_models/phone-number.model';

@Component({
  selector: 'rs-address-form',
  standalone: true,
  imports: [
    SharedModule,
    FormInputModule,
    AddressAutocompleteComponent,
    StateSelectModule,
    CountrySelectModule,
    RsPhoneInputModule,
  ],
  templateUrl: 'address-form.component.html',
  styleUrls: ['address-form.component.scss'],
})
export class AddressFormComponent {
  @Input() rsAddressForm: FormGroup = new FormGroup({
    country: new FormControl('US', Validators.required),
    address1: new FormControl('', Validators.required),
    address2: new FormControl(''),
    city: new FormControl('', Validators.required),
    zipcode: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    name: new FormControl(''),
    instructions: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
  });

  @Input() phoneFormGroup: FormGroup = new FormGroup({
    phoneNumber: new FormControl('', Validators.required),
    countryCode: new FormControl(DEFAULT_COUNTRY),
    type: new FormControl(PHONE_DEFAULT_TYPE, Validators.required),
  });

  @Input() hideNickName: boolean = true;
  @Input() hideInstructions: boolean = true;
  @Input() phoneRequired: boolean = false;
  @Input() hideCountry: boolean = true;
  @Input() hidePhoneNumber: boolean = true;
  @Input() public showLocationIcon: boolean = false;

  @Input() resetFormSubject: Subject<void> = new Subject<void>();
  @Input() instructionsPlaceholder: string =
    'PROFILE.payment.more_instructions';
  @Input() address1Placeholder: string = 'PROFILE.payment.address_line_1';
  @Input() address2Placeholder: string = 'PROFILE.payment.address_line_2';
  @Input() isAddress2Required?: boolean = false;
  @Input() focusOnInput: boolean = false;
  @Input() markStateAsTouched: boolean = true;

  @ViewChild('address2', { static: false }) address2!: FormInputComponent;

  public readonly locationIcon = CommonIcons.Location;
  public readonly bookmarkIcon = CommonIcons.Bookmark;
  public readonly id = TEST_ID;
  private destroyed$ = untilDestroyed();

  @Input() isAddressDetailsVisible: boolean = false;

  @Output() clearAutoComplete = new EventEmitter<any>();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.resetFormSubject.pipe(this.destroyed$()).subscribe(() => {
      this.isAddressDetailsVisible = false;
      this.changeDetectorRef.markForCheck();
    });

    if ((this.rsAddressForm.controls as any)['address1'].value) {
      this.isAddressDetailsVisible = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if ((this.rsAddressForm.controls as any).address1.value) {
        this.address2?.input.nativeElement.focus();
      }
    }, 300);
  }

  public reactOnAddressDetailsFound(address: UserProfileAddress): void {
    const {
      name,
      city,
      state,
      zipcode,
      address1,
      latitude,
      longitude,
      country,
    } = this.rsAddressForm.controls;

    if (!this.hideNickName) {
      name.reset(address.name);
    }

    if (!address.state) {
      state.setErrors({
        required: true,
      });
    }

    city.reset(address.city);
    state.reset(address.state);
    zipcode.reset(address.zipcode);
    address1.reset(address.address1);
    latitude.reset(address.latitude);
    longitude.reset(address.longitude);
    country.reset(address.countryCode);
    this.isAddressDetailsVisible = true;

    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public handlePhoneControlChange(phoneValue: PhoneNumber): void {
    this.phoneFormGroup.patchValue({
      phoneNumber: phoneValue.phoneNumber,
      countryCode: phoneValue.countryCode,
      type: phoneValue.type,
    });
  }

  public clearAutoCompleteField(): void {
    const { city, state, zipcode } = this.rsAddressForm.controls;

    city.reset();
    state.reset();
    zipcode.reset();

    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    });
    this.clearAutoComplete.emit();
  }
}
