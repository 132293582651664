import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { filter, first, firstValueFrom, map, Observable } from 'rxjs';

import { SharedModule } from '../../_modules/shared.module';
import { GiftCardsListInOrderModule } from '../gift-cards-list-in-order/gift-cards-list-in-order.module';
import { CartDialogMainViewComponent } from './cart-dialog-main-view.component';
import { SuggestionsItemsListComponent } from 'src/app/venue/_components/suggestions-items-list/suggestions-items-list.component';
import { ListOfItemsInOrderComponent } from '../list-of-items-in-order/list-of-items-in-order.component';
import {
  IsAvailableMenuForCurrentOrderTypePipe,
  IsAvailableMenuForSelectedTimePipe,
} from '../../_pipes/menu.pipe';

import { SessionState } from '../../_ngxs/authentication.state';
import { OrderState } from '../../_ngxs/order.state';
import { OrderDataState } from '../../_ngxs/order-data.state';
import { VenueState } from '../../_ngxs/venue.state';
import { CartEntity, CartState } from '../../_ngxs/cart.state';
import { VenueOrderSettingsState } from '../../_ngxs/venue-order-settings.state';
import { ProfileState } from '../../_ngxs/profile.state';
import { FilteredMenuState } from '../../_ngxs/filtered-menu.state';
import {
  AddToCart,
  AssignSenderToGiftCard,
  CalculatePrices,
  RemoveItemFromCart,
  SetCartEditing,
  SetGiftCards,
  SetItems,
} from '../../_ngxs/cart.actions';
import { RemoveDialogFromHistory } from '../../_ngxs/dialog.actions';
import { GetItemsSuggestions } from '../../_ngxs/order.actions';

import { OrderType } from '../../_enums/order.enum';
import { OrderItemType } from '../../_enums/order-item-type.enum';
import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';

import { Item, ItemDetails, StateItem } from '../../_interfaces/item.model';
import { Menu } from '../../_interfaces/menu.model';
import { GiftCard } from '../../_interfaces/gift-card.model';
import { ItemInListWithAvailability } from '../../_models/items.model';
import { CartItemSelection } from '../list-of-items-in-order/models';
import { QRSettingsType } from '../../_interfaces/qrSettings.model';

import { environment } from 'src/environments/environment';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';

import {
  formItemDetailsUrlAndParams,
  updateItemsForCart,
} from '../../_utils/items.helper';
import { untilDestroyed } from '../../_utils/until-destroyed';
import {
  copy,
  getCurrentSettingsTypeFromQueryParams,
  getVenueName,
  joinStringsWithSpaceBetween,
} from '../../_utils/common';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-cart-dialog',
  standalone: true,
  imports: [
    SharedModule,
    CartDialogMainViewComponent,
    SuggestionsItemsListComponent,
    GiftCardsListInOrderModule,
    ListOfItemsInOrderComponent,
  ],
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    IsAvailableMenuForSelectedTimePipe,
    IsAvailableMenuForCurrentOrderTypePipe,
  ],
})
export class CartDialogComponent {
  public readonly isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );
  public readonly isShadowUser$: Observable<boolean> = this.store.select(
    SessionState.isShadowUser
  );
  public readonly suggestions$: Observable<ItemDetails[]> = this.store.select(
    OrderState.rebuyItemsSuggestions
  );
  public readonly giftCards$: Observable<GiftCard[]> = this.store
    .select(CartState.giftCards)
    .pipe(map(giftCards => [...(giftCards || [])]));
  public readonly items$: Observable<StateItem[]> = this.store
    .select(CartState.items)
    .pipe(map(items => updateItemsForCart([...(items || [])])));
  public readonly badgeNumber$: Observable<string> = this.store.select(
    CartState.badgeNumber
  );
  public readonly isGiftCardInCart: boolean = this.store.selectSnapshot(
    CartState.isGiftCardInCart
  );
  public readonly orderType = this.store.selectSnapshot(
    OrderDataState.orderType
  );
  public readonly selectedOrderPeriod = this.store.selectSnapshot(
    OrderDataState.selectedOrderPeriod
  );
  public readonly menus: Menu[] = this.store.selectSnapshot(
    FilteredMenuState.menus
  );

  public readonly isProduction: boolean = environment.env === 'prod';
  public readonly noItemsFoundIcon = CommonIcons.BagNoItemsFound;
  private readonly destroyed$ = untilDestroyed();

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly platform: Platform,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly matDialog: MatDialog,
    private readonly dialogRef: MatDialogRef<CartDialogComponent>,
    private readonly store: Store,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcons();
  }
  ngOnInit(): void {
    this.subscribeOnUserSession();
  }

  private subscribeOnUserSession(): void {
    this.isLoggedIn$
      .pipe(filter(Boolean), this.destroyed$())
      .subscribe(async _ => {
        this.getItemsSuggestions();

        if (!this.isGiftCardInCart) {
          return;
        }

        const profileInfo = await firstValueFrom(
          this.store
            .select(ProfileState.profileInfo)
            .pipe(
              first(
                profile => !!profile && Object.values(profile).some(Boolean)
              )
            )
        );

        if (!profileInfo) {
          return;
        }

        const senderInfo = {
          senderName: `${profileInfo?.firstName || ''} ${
            profileInfo?.lastName
          }`,
          senderEmail: profileInfo?.email || '',
          senderProfileId: profileInfo?.id || '',
        };

        this.store.dispatch(new AssignSenderToGiftCard(senderInfo));

        this.updateSenderInformationForGiftCards(
          joinStringsWithSpaceBetween([
            profileInfo.firstName,
            profileInfo?.lastName,
          ]),
          profileInfo.email,
          profileInfo.id
        );
      });

    this.isShadowUser$.pipe(filter(Boolean), this.destroyed$()).subscribe(_ => {
      if (!this.isGiftCardInCart) {
        return;
      }

      const shadowUserInfo = this.store.selectSnapshot(
        SessionState.shadowUserInfo
      );

      this.updateSenderInformationForGiftCards(
        shadowUserInfo.name,
        shadowUserInfo.email
      );
    });
  }

  private getItemsSuggestions(): void {
    this.store.dispatch(new GetItemsSuggestions());
  }

  public selectItem({ cartItemId, value }: CartItemSelection): void {
    const items: CartEntity[] = this.getCartEnities();
    const index = items.findIndex(item => item.cartItemId === cartItemId);
    items[index]!.selectedInCart = value;

    if (this.isGiftCardInCart) {
      this.store.dispatch(new SetGiftCards(items as any));
    } else {
      this.store.dispatch([new SetItems(items), new CalculatePrices()]);
    }
  }

  public selectAllItems(selected: boolean): void {
    const items: CartEntity[] = this.getCartEnities();

    items.forEach((_, index) => (items[index].selectedInCart = selected));

    this.store.dispatch([new SetItems(items), new CalculatePrices()]);
  }

  public increaseItemQuantityInCart(
    currentItem: ItemInListWithAvailability
  ): void {
    if (this.isGiftCardInCart) {
      return;
    }
    const item: StateItem | undefined = this.getCartEnities().find(
      (i: CartEntity) => (i as StateItem).cartItemId === currentItem?.cartItemId
    ) as StateItem;

    !!item && this.store.dispatch(new AddToCart(item as StateItem));
  }

  public decreaseItemQuantityInCart(
    currentValue: ItemInListWithAvailability | string
  ): void {
    const item: CartEntity | undefined = this.getCartEnities().find(
      (i: CartEntity) =>
        (i as StateItem).cartItemId ===
          (currentValue as ItemInListWithAvailability).cartItemId ||
        (i as StateItem).cartItemId === currentValue
    );

    !!item &&
      this.store.dispatch(
        new RemoveItemFromCart(
          item as StateItem,
          this.isGiftCardInCart ? OrderItemType.gift_card : OrderItemType.item
        )
      );
  }

  private getCartEnities(): CartEntity[] {
    return copy(this.store.selectSnapshot(CartState.cartFilling).items || []);
  }

  public handleClickByItem(item: ItemInListWithAvailability): void {
    const isThereAnyAvailableOrderMethod: boolean = this.store.selectSnapshot(
      VenueOrderSettingsState.isThereAnyAvailableOrderMethod
    );
    const currentMenuType: QRSettingsType = isThereAnyAvailableOrderMethod
      ? getCurrentSettingsTypeFromQueryParams(
          this.document?.location?.href || ''
        ) || 'menu'
      : 'menu';
    const formattedVenueName: string = this.store.selectSnapshot(
      VenueState.formattedVenueName
    );
    const itemId = item.itemId || '';
    const cartItemId = item.cartItemId || '';
    const menuId = item.menuId || this.getItemMenuId(itemId);
    const sectionId: string | undefined = undefined;

    const { url, queryParams } = formItemDetailsUrlAndParams(
      currentMenuType,
      itemId,
      menuId,
      sectionId,
      formattedVenueName
    );

    this.router.navigate([url], {
      queryParams: { cartItemId, ...queryParams },
    });

    // This timeout fixes issue with overwriting isEditing parameter in store
    setTimeout(() => {
      this.store.dispatch([
        new RemoveDialogFromHistory(this.dialogRef.id),
        new SetCartEditing(true),
      ]);
    });

    this.dialogRef.close();
  }

  public goToOrdering(): void {
    this.matDialog.closeAll();

    if (this.platform.isBrowser) {
      const venueName = getVenueName(this.document.location.href);
      let url = `/${venueName}/`;
      const isDineIn: boolean = this.orderType === OrderType.DineIn;
      const firstMenuId = this.store.selectSnapshot(
        VenueState.defaultMenuId(isDineIn ? 'dine-in' : 'togo')
      );
      url += this.isGiftCardInCart
        ? `gift-card`
        : isDineIn
        ? `dine-in/menus/${firstMenuId}`
        : `order/${firstMenuId}`;

      this.router.navigate([url], {
        relativeTo: this.route,
      });
    }
  }

  private getItemMenuId(itemId: string): string {
    const menus: Menu[] = this.store.selectSnapshot(FilteredMenuState.menus);

    return (
      (menus || []).find(({ sections }: Menu) =>
        sections.find(({ items }) =>
          items.find((item: Item) => item.itemId === itemId)
        )
      )?.menuId || ''
    );
  }

  private updateSenderInformationForGiftCards(
    name: string,
    email: string,
    id: string = ''
  ): void {
    const senderInfo = {
      senderName: name || '',
      senderEmail: email || '',
      senderProfileId: id || '',
    };

    this.store.dispatch(new AssignSenderToGiftCard(senderInfo));
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.noItemsFoundIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
