import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { Store } from '@ngxs/store';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsFoodItemSuggestedComponent } from 'src/app/_shared/_components/food-item-suggested/rs-food-item-suggested.component';
import { RsDefaultFoodIconComponent } from 'src/app/_shared/_components/rs-default-food-icon/rs-default-food-icon.component';

import { VenueState } from 'src/app/_shared/_ngxs/venue.state';

import { Item, ItemDetails } from 'src/app/_shared/_interfaces/item.model';

@Component({
  selector: 'rs-suggestions-items-list',
  imports: [
    SharedModule,
    RsFoodItemSuggestedComponent,
    RsDefaultFoodIconComponent,
  ],
  standalone: true,
  templateUrl: './suggestions-items-list.component.html',
  styleUrls: ['./suggestions-items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionsItemsListComponent {
  @Input() set suggestedItems(suggestedItems: ItemDetails[] | Item[]) {
    this.initialize(suggestedItems);
  }
  @Input() title: string = '';

  public items: Item[] = [];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store
  ) {}

  private initialize(suggestedItems: ItemDetails[] | Item[]): void {
    this.items = [];

    if (!suggestedItems?.length) {
      return;
    }

    if (Object(suggestedItems[0]).hasOwnProperty('itemId')) {
      this.items = suggestedItems as Item[];

      return;
    }

    const items: Item[] = this.store.selectSnapshot(
      VenueState.availableVisibleMenusItems
    );

    (suggestedItems as ItemDetails[]).forEach(({ id }) => {
      const item = items.find(({ itemId }) => itemId === id);
      if (!item) {
        return;
      }
      this.items.push(item);
    });
    this.items = this.items.filter(item => !!item.prices[0]?.price?.amount);

    this.changeDetectorRef.markForCheck();
  }
}
