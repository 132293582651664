import { KeyValue } from '@angular/common';

import { AvailableLanguage } from '../_ngxs/language.state';

export const LANGUAGES: KeyValue<string, AvailableLanguage>[] = [
  {
    key: 'English',
    value: 'en',
  },
  {
    key: 'Spanish',
    value: 'es',
  },
  {
    key: 'Portuguese',
    value: 'pt',
  },
  {
    key: 'Русский',
    value: 'ru',
  },
  {
    key: '中文',
    value: 'zh',
  },
  {
    key: 'عربي',
    value: 'ar',
  },
];
