<section class="rs-authorisation-wrapper d-flex flex-column align-items-center">
  <rs-powered-by-rockspoon [showPowered]="false"></rs-powered-by-rockspoon>

  <rs-authorisation
    class="w-100"
    [autorisation]="autorisation"
    [hiddenAutorisationType]="hiddenAutorisationType"
  ></rs-authorisation>

  <ng-content></ng-content>

  <rs-language class="rs-authorisation-wrapper__language"></rs-language>

  <rs-powered-by-rockspoon
    class="rs-authorisation-wrapper__footer"
    [showLogo]="false"
  ></rs-powered-by-rockspoon>
</section>
