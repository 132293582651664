import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RsPipesModule } from '../../_modules/pipes.module';
import { DirectivesModule } from '../../_directives/directives.module';
import { SharedModule } from '../../_modules/shared.module';
import { StateSelectModule } from '../state-select/state-select.module';
import { AddressAutocompleteComponent } from '../address-autocomplete/address-autocomplete.component';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

import { TEST_ID } from '../../_constants/e2e-ids.constants';

@Component({
  selector: 'rs-payment-address-form',
  standalone: true,
  imports: [
    SharedModule,
    RsPipesModule,
    DirectivesModule,
    AddressAutocompleteComponent,
    StateSelectModule,
  ],
  templateUrl: 'payment-address-form.component.html',
  styleUrls: ['payment-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentAddressFormComponent {
  @Input() addressForm: FormGroup | undefined;

  public readonly id = TEST_ID;

  public reactOnAddressDetailsFound(address: UserProfileAddress): void {
    const { address1, city, state, zipcode, countryCode } = address;

    this.addressForm &&
      this.addressForm.patchValue({
        address: address1,
        address1,
        city,
        state,
        country: countryCode,
        zipCode: zipcode,
        zipcode,
      });
  }
}
