import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { StateSelectComponent } from './state-select.component';

@NgModule({
  imports: [SharedModule],
  declarations: [StateSelectComponent],
  exports: [StateSelectComponent],
})
export class StateSelectModule {}
