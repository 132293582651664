import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { filter, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { OpenConfirmationDialog } from '../../_ngxs/dialog.actions';
import { MatDialogId } from '../../_enums/mat-dialog-id.enum';

@Directive({ selector: '[rsOpenConfirmation]' })
export class OpenConfirmationPopupDirective {
  @Output() confirmed = new EventEmitter<void>();

  @Input() question: string = this.translateService.instant(
    'ADDRESS_BOOK.default_question'
  );

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService
  ) {}

  @HostListener('click', ['$event'])
  openConfirmationPopup(event: PointerEvent): void {
    event.stopPropagation();

    this.store
      .dispatch(new OpenConfirmationDialog(this.question))
      .pipe(take(1))
      .subscribe(() => {
        this.dialog
          .getDialogById(MatDialogId.confirmation)
          ?.afterClosed()
          .pipe(
            take(1),
            filter(confirmed => confirmed)
          )
          .subscribe(() => this.confirmed.emit());
      });
  }
}
