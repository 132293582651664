export const ALLERGEN_FREE_POSTFIX: string = 'free';
export const ALLERGEN_TRACE_POSTFIX: string = 'trace';
export const ALLERGEN_CONTAIN_POSTFIX: string = 'contains';
export const DIGITAL_STOREFRONT_ALLERGEN_ICON_PREFIX = 'allergen-icon';
export const DIGITAL_STOREFRONT_ALLERGEN_ICON_DIRECTORY: string = 'allergens';

export const SPICY_LEVEL: string[] = ['mild', 'medium', 'hot'];

// Note: if you change value here you need to change icon name
// src/assets/icons/allergens
export const ALLERGENS_CONTAINS: string[] = [
  'dairy',
  'sulfites',
  'sugar',
  'seasame',
  'oats',
  'mustard',
  'lupin',
  'celery',
  'nuts',
  'soy',
  'peanuts',
  'lactose',
  'gluten',
  'shellfish',
  'fish',
  'egg',
];

export const ALLERGENS_TRACES: string[] = [...ALLERGENS_CONTAINS].map(
  allergen => `${allergen}_${ALLERGEN_TRACE_POSTFIX}`
);

export const ALLERGENS_FREE: string[] = [...ALLERGENS_CONTAINS].map(
  allergen => `${allergen}_${ALLERGEN_FREE_POSTFIX}`
);

export const ALLERGENS: string[] = [
  ...ALLERGENS_CONTAINS,
  ...ALLERGENS_FREE,
  ...ALLERGENS_TRACES,
];
