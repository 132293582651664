import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { OrdersResponse } from '../profile/_interfaces/order.model';
import {
  FavoriteItem,
  Favorites,
  Profile,
  ProfilePreferences,
} from '../profile/_interfaces/profile.model';
import { ENDPOINTS } from '../_shared/_constants/endpoints';
import { GiftCard } from '../_shared/_interfaces/gift-card.model';
import {
  OrderReview,
  UpdateOrderReviewPayload,
} from '../_shared/_interfaces/order.model';
import { OrderPaymentDetails } from '../_shared/_interfaces/payment.model';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private readonly apiUrl = environment.apiUrl;
  private readonly paymentApiUrl = environment.paymentUrl;

  constructor(private readonly http: HttpClient) {}

  public getProfileInfo(): Observable<Profile> {
    return this.http.get<Profile>(`${this.apiUrl}/user-profile/v1/profile/me`);
  }

  public updateProfileInfo(info: Partial<Profile>) {
    const email = info.email;
    let phone = info.phone;
    if (phone) phone.isDefault = true;
    const toBackend = {
      ...info,
      email: {
        email,
        isDefault: true,
      },
      phone,
    };

    return this.http.put<void>(
      `${this.apiUrl}/user-profile/v1/profile/me`,
      toBackend
    );
  }

  public getProfilePreferences(): Observable<ProfilePreferences> {
    return this.http.get<ProfilePreferences>(
      `${this.apiUrl}/catalog/v1/user/settings`
    );
  }

  public updateProfileTags(tags: string[]): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/catalog/v1/user/settings`, {
      tags,
    });
  }

  public updateProfilePicture(
    profileId: string,
    image: Blob
  ): Observable<void> {
    const imageData = new FormData();

    imageData.append('image', image, 'Profile Picture');
    imageData.append('type', 'customer');

    return this.http.put<void>(
      `${this.apiUrl}/${ENDPOINTS.profile.pictire(profileId)}`,
      imageData
    );
  }

  public getProfileOrders(): Observable<OrdersResponse> {
    // if you need more orders use ?pageSize=200 parameter
    return this.http.get<OrdersResponse>(`${this.apiUrl}/order/v1/ca`);
  }

  public getProfileOrderPaymentDetails(
    paymentId: string
  ): Observable<OrderPaymentDetails> {
    return this.http.get<OrderPaymentDetails>(
      `${this.paymentApiUrl}/${ENDPOINTS.payment.details(paymentId)}`
    );
  }

  public getProfileOrderReviewDetails(
    orderId: string
  ): Observable<OrderReview> {
    return this.http.get<OrderReview>(
      `${this.apiUrl}/${ENDPOINTS.order.review(orderId)}`
    );
  }

  public updateProfileOrderReview(
    orderId: string,
    reviews: UpdateOrderReviewPayload[]
  ): Observable<void> {
    return this.http.put<void>(
      `${this.apiUrl}/${ENDPOINTS.order.updateReview(orderId)}`,
      reviews
    );
  }

  public downloadOrderSummary(paymentId: string): Observable<ArrayBuffer> {
    return this.http.post(
      `${this.apiUrl}/${ENDPOINTS.order.getReceipt(paymentId)}`,
      {},
      { responseType: 'arraybuffer' }
    );
  }

  public getProfileGiftCards(): Observable<GiftCard[]> {
    return this.http.get<GiftCard[]>(
      `${this.paymentApiUrl}/wallet/v1/consumer/gift-card`
    );
  }

  public getFavorites(): Observable<Favorites> {
    return this.http.get<Favorites>(
      `${this.apiUrl}/${ENDPOINTS.profile.favorite}`
    );
  }

  public setFavorites(
    venues: string[],
    items: FavoriteItem[]
  ): Observable<Favorites> {
    return this.http.put<Favorites>(
      `${this.apiUrl}/${ENDPOINTS.profile.favorite}`,
      { venues, items }
    );
  }
}
