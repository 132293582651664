import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({ name: 'rsEtaTime' })
export class ETATimePipe implements PipeTransform {
  transform(dateString: Date | string): string {
    return moment(dateString).format('h:mm A');
  }
}
