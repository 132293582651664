export function downloadPdfFileFromArrayBuffer(
  buffer: ArrayBuffer,
  fileName: string
): void {
  const fullName = fileName + '.pdf';
  const file = new File([buffer], fullName, { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  const fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.download = fullName;
  fileLink.click();
}
