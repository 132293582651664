import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ENDPOINTS } from '../_shared/_constants/endpoints';
import { CardBrand } from '../_shared/_enums/card.enum';
import { GiftCard } from '../_shared/_interfaces/gift-card.model';
import {
  GiftCardPaymentPayload,
  CartNotPresentPayload,
  CartNotPresentPayment,
  CreateGiftCardPayload,
  MergeGiftcards,
} from '../_shared/_interfaces/payment.model';
import {
  CardResponse,
  CardToEdit,
  NewCardToAdd,
} from '../_shared/_models/common.interface';
import { PaymentMethods } from '../_shared/_enums/payment-methods.enum';
import { VenueState } from '../_shared/_ngxs/venue.state';

const paymentUrl = environment.paymentUrl;

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(private http: HttpClient, private store: Store) {}

  public getCards(): Observable<CardResponse[]> {
    return this.http.get<CardResponse[]>(
      `${paymentUrl}/${ENDPOINTS.payment.cardToken}`
    );
  }

  public getGiftCardDetails(giftCardId: string): Observable<GiftCard> {
    return this.http.get<GiftCard>(
      `${paymentUrl}/${ENDPOINTS.wallet.createGiftCard}/${giftCardId}`
    );
  }

  public createCard(
    newCardToAdd: NewCardToAdd
  ): Observable<{ id: string; cardBrand: CardBrand }> {
    newCardToAdd.skipValidation = true;
    newCardToAdd.venueId = this.store.selectSnapshot(VenueState.venueId);
    return this.http.post<{ id: string; cardBrand: CardBrand }>(
      `${paymentUrl}/${ENDPOINTS.payment.cardToken}`,
      newCardToAdd
    );
  }

  public editCard(cardToEdit: CardToEdit, cardId: string): Observable<void> {
    return this.http.put<void>(
      `${paymentUrl}/${ENDPOINTS.payment.cardToken}/${cardId}`,
      cardToEdit
    );
  }

  public deleteCard(cardId: string): Observable<void> {
    return this.http.delete<void>(
      `${paymentUrl}/${ENDPOINTS.payment.cardToken}/${cardId}`
    );
  }

  public createCardNotPresentPayment(
    data: CartNotPresentPayload
  ): Observable<CartNotPresentPayment> {
    return this.http.post<CartNotPresentPayment>(
      `${paymentUrl}/${ENDPOINTS.payment.createCardNotPresentPayment}`,
      data
    );
  }

  public createGiftCardPayment(
    type: PaymentMethods,
    order: GiftCardPaymentPayload | null
  ): Observable<CartNotPresentPayment> {
    return this.http.post<CartNotPresentPayment>(
      type === PaymentMethods.card
        ? `${paymentUrl}/${ENDPOINTS.payment.createCardNotPresentPayment}`
        : type === PaymentMethods.gift_card
        ? `${paymentUrl}/${ENDPOINTS.payment.giftCardPayment}`
        : type === PaymentMethods.apple_pay
        ? `${paymentUrl}/${ENDPOINTS.payment.applePayPayment}`
        : type === PaymentMethods.google_pay
        ? `${paymentUrl}/${ENDPOINTS.payment.googlePayPayment}`
        : '',
      order
    );
  }

  public createGiftCard(
    data: CreateGiftCardPayload
  ): Observable<CartNotPresentPayment> {
    return this.http.post<CartNotPresentPayment>(
      `${paymentUrl}/${ENDPOINTS.wallet.createGiftCard}`,
      data
    );
  }

  public mergeGiftCards(giftCardsToMerge: MergeGiftcards) {
    return this.http.post<null>(
      `${paymentUrl}/${ENDPOINTS.wallet.mergeGiftCards}`,
      giftCardsToMerge
    );
  }
}
