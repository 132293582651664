import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SvgIconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  private registeredIcons = new Set<string>();

  public registerSvgIcons(
    icons: string[],
    directory: string = '',
    prefix: string = ''
  ): void {
    // for SSR app url must be absolute
    const domain = isPlatformServer(this.platformId)
      ? `${environment.baseUrl}/`
      : '';

    icons.forEach(icon => {
      if (this.registeredIcons.has(icon)) {
        return;
      }

      this.matIconRegistry.addSvgIcon(
        `${prefix ? prefix + '-' : ''}${icon}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${domain}assets/icons/${
            directory ? '/' + directory + '/' : ''
          }${icon}.svg`
        )
      );
      this.registeredIcons.add(icon);
    });
  }
}
