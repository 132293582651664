import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CardHorizontalActionComponent,
  CardHorizontalComponent,
  CardHorizontalLineComponent,
  CardHorizontalTitleComponent,
} from './card-horizontal.component';

const components = [
  CardHorizontalActionComponent,
  CardHorizontalComponent,
  CardHorizontalLineComponent,
  CardHorizontalTitleComponent,
];

@NgModule({
  imports: [CommonModule, ...components],
  exports: [...components],
})
export class CardHorizontalModule {}
