<div
  class="rs-wine-details-item d-flex justify-content-between align-items-center"
>
  <div class="rs-wine-details-item__from">{{ 'WINE.' + from | translate }}</div>
  <div class="rs-wine-details-item__content">
    <div
      #valueContainer
      class="rs-wine-details-item__value"
      [ngStyle]="{
        width: width + 'px',
        left: left + 'px'
      }"
    ></div>
  </div>
  <div class="rs-wine-details-item__to">{{ 'WINE.' + to | translate }}</div>
</div>
