import { BundleItem } from '../_interfaces/item.model';

export class GetBundleItemModifiers {
  static readonly type = '[Bundle Item] Get Bundle Item Modifiers';
  constructor(public item: BundleItem, public bundleSectionId: string) {}
}

export class RemoveBundleItemModifier {
  static readonly type = '[Bundle Item] Remove Bundle Item Modifier';
  constructor(public item: BundleItem, public bundleSectionId: string) {}
}

export class ClearItemModifiers {
  static readonly type = '[Bundle Item] Clear Bundle Item Modifiers';
}
