<div
  class="rs-dialog-actions__actions d-flex flex-row rs-dialog-actions__actions--row justify-content-end"
  #rsActionsInRow
  [ngClass]="{
    'd-none': !rsActionsInRow?.children?.length
  }"
>
  <ng-content select="[rs-dialog-actions-direction='row']"></ng-content>
</div>

<div
  class="rs-dialog-actions__actions d-flex flex-column rs-dialog-actions__actions--column align-items-end"
  #rsActionsInColumn
  [ngClass]="{ 'd-none': !rsActionsInColumn?.children?.length }"
>
  <ng-content select="[rs-dialog-actions-direction='column']"></ng-content>
</div>
