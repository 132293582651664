import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getImagePathToNoRes, getImageToCDN } from '../_utils/image';

const env = environment.env;

@Pipe({ name: 'toCDN' })
export class ToCDNPipe implements PipeTransform {
  transform(src: string | undefined): string {
    return getImageToCDN(src);
  }
}

@Pipe({ name: 'toNoRes' })
export class ToNoResPipe implements PipeTransform {
  transform(src: string | undefined): string {
    return getImagePathToNoRes(src);
  }
}
