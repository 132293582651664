import { Injectable } from '@angular/core';

import { combineLatest, tap } from 'rxjs';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import * as moment from 'moment';

import {
  CreateDelivery,
  InitiateDelivery,
  GetDeliveryById,
  CancelDelivery,
  HandleBurqWebhook,
} from './delivery.actions';
import { DeliveryService } from 'src/app/_services/delivery.service';
import { Delivery, DeliveryStatus } from '../_interfaces/delivery.model';
import { Step } from '../_interfaces/step.interface';
import { DELIVERY_STEP_LABEL } from '../_constants/delivery.constant';
import { DeliveryStatuses } from '../_enums/delivery-status.enum';
import { groupBy, map, orderBy } from 'lodash';
import { OrderService } from 'src/app/_services/order.service';
import { OrderState } from './order.state';
import { SaveUpdatedOrderDetails } from './order.actions';
import { VenueState } from './venue.state';
import { TranslateService } from '@ngx-translate/core';
import { OrderItemStatus } from '../_enums/order-item-status.enum';

interface DeliveryStateModel {
  delivery: Delivery | null;
  deliverySteps: Step[];
}

@State<DeliveryStateModel>({
  name: 'delivery',
  defaults: {
    delivery: null,
    deliverySteps: [],
  },
})
@Injectable()
export class DeliveryState {
  constructor(
    private readonly deliveryService: DeliveryService,
    private readonly orderService: OrderService,
    private readonly store: Store,
    private readonly translateService: TranslateService
  ) {}

  @Selector()
  static deliveryId({ delivery }: DeliveryStateModel): string | null {
    return delivery?.id || null;
  }

  @Selector()
  static delivery({ delivery }: DeliveryStateModel): Delivery | null {
    return delivery || null;
  }

  @Selector()
  static deliverySteps({ deliverySteps }: DeliveryStateModel): Step[] {
    return deliverySteps;
  }

  @Action(CreateDelivery)
  createDelivery(
    {}: StateContext<DeliveryStateModel>,
    { requestBody }: CreateDelivery
  ) {
    return this.deliveryService
      .createDelivery(requestBody)
      .pipe(
        tap((response: Delivery) => console.log('CreateDelivery', response))
      );
  }

  @Action(InitiateDelivery)
  initiateDelivery(
    {}: StateContext<DeliveryStateModel>,
    { deliveryId }: InitiateDelivery
  ) {
    return this.deliveryService
      .initiateDelivery(deliveryId)
      .pipe(tap(() => console.log('InitiateDelivery')));
  }

  @Action(GetDeliveryById)
  getDeliveryById(
    { patchState }: StateContext<DeliveryStateModel>,
    { deliveryId, orderId }: GetDeliveryById
  ) {
    return combineLatest([
      this.orderService.getOrderDetails(
        orderId
          ? orderId
          : this.store.selectSnapshot(OrderState.orderDetails)?.orderId!
      ),
      this.deliveryService.getDeliveryById(deliveryId),
    ]).pipe(
      tap(([orderResponse, deliveryResponse]) => {
        this.store.dispatch(new SaveUpdatedOrderDetails(orderResponse));

        const groupedStatuses: DeliveryStatus[] = [
          ...(orderResponse.statusHistory || []),
          ...(deliveryResponse.statusHistory || []),
        ];

        const statuses = Object.keys(DELIVERY_STEP_LABEL)
          .map(deliveryStatusKey =>
            groupedStatuses.find(
              groupedStatus => groupedStatus.status === deliveryStatusKey
            )
          )
          .filter(Boolean) as DeliveryStatus[];

        patchState({
          delivery: deliveryResponse || null,
          deliverySteps: this.getDeliverySteps(statuses),
        });
      })
    );
  }

  @Action(CancelDelivery)
  cancelDelivery(
    {}: StateContext<DeliveryStateModel>,
    { deliveryId }: CancelDelivery
  ) {
    return this.deliveryService
      .cancelDelivery(deliveryId)
      .pipe(tap(() => console.log('CancelDelivery')));
  }

  @Action(HandleBurqWebhook)
  handleBurqWebhook(
    {}: StateContext<DeliveryStateModel>,
    { requestBody }: HandleBurqWebhook
  ) {
    return this.deliveryService
      .handleBurqWebhook(requestBody)
      .pipe(tap(() => console.log('HandleBurqWebhook')));
  }

  private getDeliverySteps(statuses: DeliveryStatus[]): Step[] {
    const activeStatusIndex = statuses.length - 1;
    const isStepCompleted = (status: string, index: number) => {
      return (
        index !== activeStatusIndex ||
        status == String(OrderItemStatus.Delivered) ||
        status == 'Delivered' ||
        status == String(OrderItemStatus.Finished) ||
        status == 'voided'
      );
    };
    return map(
      groupBy(statuses, 'status'),
      value => orderBy(value, ['date'], ['desc'])[0]
    ).map((status, index) => ({
      index,
      label:
        status.status === DeliveryStatuses.DriverOnTheWay
          ? this.translateService.instant(DELIVERY_STEP_LABEL[status.status], {
              restaurantName: this.store.selectSnapshot(VenueState.venue).name,
            })
          : this.translateService.instant(DELIVERY_STEP_LABEL[status.status]),
      completed: index ? isStepCompleted(status.status, index) : true,
      canceled: status.status === String(DeliveryStatuses.Canceled),
      description: moment(status.time).format('h:mm A'),
    }));
  }
}
