<div class="rs-password-strength">
  <div class="rs-password-strength__row rs-progress">
    <span [ngClass]="classes">
      {{ strengthLabel | translate | uppercase }}
    </span>
    <mat-progress-bar
      mode="determinate"
      [ngClass]="classes"
      [value]="strengthValue"
    >
    </mat-progress-bar>
  </div>
  <div class="rs-password-strength__row rs-checks">
    <div *ngFor="let passwordCheck of passwordChecks" class="rs-check">
      <mat-icon
        *ngIf="passwordCheck.isChecked"
        [class.rs-checked]="passwordCheck.isChecked"
      >
        check_circle
      </mat-icon>
      <mat-icon *ngIf="!passwordCheck.isChecked">check_circle_outline</mat-icon>
      <span>{{ passwordCheck.label | translate }}</span>
    </div>
  </div>
</div>
