<button
  rs-chip="suggestion"
  [ngClass]="{
    'rs-suggestion-chip--selected': selected && !disabled,
    'rs-suggestion-chip--disabled': disabled || disabledLook
  }"
  [disabled]="disabled"
  (click)="toggle()"
>
  <ng-content></ng-content>

  <ng-template [ngIf]="showActionIcon">
    <mat-icon
      *ngIf="selected; else rsSelect"
      class="rs-suggestion-chip__action"
      [svgIcon]="checkmarkIcon"
    ></mat-icon>
  </ng-template>
</button>

<ng-template #rsSelect>
  <mat-icon class="rs-suggestion-chip__action" [svgIcon]="addIcon"></mat-icon>
</ng-template>
