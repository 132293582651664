import { Pipe, PipeTransform } from '@angular/core';
import { ImageDetailsPhoto, Resolutions } from '../_interfaces/image.model';

@Pipe({ name: 'backgroundImage' })
export class BackgroundImagePipe implements PipeTransform {
  transform(value: string): string {
    return `url(${value})`;
  }
}
@Pipe({ name: 'rsGetDefaultImageUrlFromPhotoData' })
export class GetDefaultImageUrlFromResolutionsPipe implements PipeTransform {
  transform({ resolutions }: ImageDetailsPhoto): string {
    if (!resolutions) {
      return '';
    }

    return (
      resolutions.default?.url ??
      resolutions.high?.url ??
      resolutions.low?.url ??
      ''
    );
  }
}
