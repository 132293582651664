import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { cloneDeep } from 'lodash';

import countries from '../../../../assets/files/countries.json';
import { DEFAULT_COUNTRY } from 'src/app/_shared/_rs-design/phone-input/phone-input.constants';

@Component({
  selector: 'rs-state-select',
  templateUrl: 'state-select.component.html',
  styleUrls: ['state-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateSelectComponent {
  @Input() rsFormControl: FormControl = new FormControl('');
  @Input() countryCode: string = DEFAULT_COUNTRY;
  @Input() placeholder: string = '';
  @Input() shouldDisable: boolean = false;
  @Input() markAsTouched: boolean = true;

  public states: any[] = [];

  public isPreFilled: boolean = false;

  ngOnChanges(): void {
    this.states = cloneDeep(
      countries.find(country => country.code2 === this.countryCode)!
    ).states;

    if (this.markAsTouched) {
      this.rsFormControl.markAsTouched();
    }
  }

  ngOnInit() {
    if (this.rsFormControl?.value?.length > 0) {
      this.isPreFilled = true;
    }
  }

  public compareWithFn(state1: string, state2: string): boolean {
    return state1.toLowerCase() === state2.toLowerCase();
  }
}
