import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { CommonOrderItemComponent } from './common-order-item.component';

import { ItemInListWithAvailability } from '../../_models/items.model';
import { CommonOrderItemBundleItemsForView } from './common-order-item.interface';
import { getBundleItemsForViewFromBundleItemsElements } from './common-order-item.utils';

@Component({
  selector: 'rs-cart-order-item',
  standalone: true,
  imports: [SharedModule, CommonOrderItemComponent],
  templateUrl: './cart-order-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartOrderItemComponent {
  @Input() set item(item: ItemInListWithAvailability) {
    this.orderItem = item;
  }
  @Input() showCheckboxes: boolean = true;
  @Input() availableToChange: boolean = true;
  @Input() availableToOrder: boolean = true;
  @Input() bundleItemsNames!: { [id: string]: string } | null | undefined;
  @Input() unavailableMessage: string = '';

  public bundleItems: CommonOrderItemBundleItemsForView[] = [];
  public orderItem!: ItemInListWithAvailability;

  @Output() clickedOnItem: EventEmitter<void> = new EventEmitter();
  @Output() selected: EventEmitter<boolean> = new EventEmitter();
  @Output() amountChanged: EventEmitter<number> = new EventEmitter();

  public ngOnChanges(changes: SimpleChanges): void {
    changes['item'].firstChange &&
      (this.bundleItems = getBundleItemsForViewFromBundleItemsElements(
        this.orderItem?.bundleItems || [],
        this.bundleItemsNames
      ));
  }

  public selectItem(selected: boolean): void {
    this.selected.next(selected);
  }

  public onClick(): void {
    this.availableToChange && this.clickedOnItem.next();
  }

  public changeAmount(change: 1 | -1): void {
    this.availableToChange && this.amountChanged.next(change);
  }
}
