import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'rs-qr-scanner',
  templateUrl: './rs-qr-scanner.component.html',
  styleUrls: ['./rs-qr-scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsQrScannerComponent {
  @Input() activateCameraScanner: boolean = false;
  @Output() qrScanResult = new EventEmitter<string>();
  @ViewChild('scanner', { static: false })
  public enableScanner: boolean = false;
  public allowedFormats: BarcodeFormat = BarcodeFormat.QR_CODE;
  public desiredDevice: MediaDeviceInfo = {} as MediaDeviceInfo;

  public camerasFoundHandler(camerasFound: MediaDeviceInfo[]): void {
    if (camerasFound.length) {
      this.enableScanner = true;
      this.desiredDevice = camerasFound[0];
    }
  }

  public scanSuccessHandler(scanResult: string): void {
    this.emitScanResult(scanResult);
    this.disableScan();
    this.deactivateScanner();
  }

  public disableScan = (): void => {
    this.enableScanner = false;
  };

  private deactivateScanner = (): void => {
    this.activateCameraScanner = false;
  };

  private emitScanResult(scanResult: string): void {
    this.qrScanResult.emit(scanResult);
  }
}
