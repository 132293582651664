<div class="rs-dine-in-check position-relative">
  <div class="rs-dine-in-check__header position-sticky mb-5">
    <span class="rs-dine-in-check__table-name flex-1">
      {{ 'ADD-GUESTS.TABLE' | translate }} {{ tableName$ | async }}
    </span>
    <div *ngIf="venue$ | async as venue" class="rs-dine-in-check__venue-logo">
      <img
        *ngIf="venue?.logo"
        rsImageLoader
        height="34px"
        width="auto"
        loading="lazy"
        [src]="venue?.logo | toNoRes"
        [alt]="venue?.name"
      />
    </div>

    <span class="flex-1">
      <!-- in the future, the button to call a server will go here -->
    </span>
  </div>

  <div class="rs-dine-in-check__content m-auto">
    <ng-container *ngIf="!wrongGroup; else wrongGroupMessage">
      <div class="rs-dine-in-check__question-mark">
        <mat-icon
          class="rs-dine-in-check__question-mark-icon mb-5 fs-6"
          [svgIcon]="Icon.QuestionMark"
        ></mat-icon>
        <span class="rs-dine-in-check__title mt-1">
          {{ 'DINERS.Are you dining with' | translate }}
        </span>
      </div>

      <div
        *ngIf="diners$ | async as diners"
        class="rs-dine-in-check__diners flex-wrap col-7"
      >
        {{ diners | rsDinersString }}
      </div>

      <div class="rs-dine-in-check__actions fw-bold">
        <button rs-style="bold" (click)="close()">
          <span>{{ 'DINERS.YES, I AM' | translate }}</span>
        </button>
        <button rs-style="text" (click)="wrongGroup = true">
          <span>
            {{ 'DINERS.NO, THIS IS NOT MY GROUP' | translate }}
          </span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #wrongGroupMessage>
  <div class="rs-dine-in-check__wrong-group col-8">
    <mat-icon class="mb-5"> sentiment_very_dissatisfied_alt </mat-icon>
    <span class="rs-dine-in-check__title mb-3">
      {{ 'DINERS.not_your_table' | translate }}
    </span>
    <span class="rs-dine-in-check__subtitle mt-1">
      {{ 'DINERS.call_server_for_assistance' | translate }}
    </span>
  </div>
</ng-template>
