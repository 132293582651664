import { Injectable } from '@angular/core';

import { State, Action, StateContext, Selector } from '@ngxs/store';

import { SetLanguage, InitializeLanguage } from './language.actions';
import { SessionStorageKeys } from '../_enums/session-storage-keys.enum';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { SessionStorageEngine } from 'src/app/_services/session-storage.service';
import { LocaleStorageKeys } from '../_enums/local-storage-keys.enum';

export type AvailableLanguage = 'en' | 'es' | 'ru' | 'pt' | 'zh' | 'ar';
export interface LanguageStateModel {
  language: AvailableLanguage | null;
}

const DEFAULT_LANGUAGE: AvailableLanguage = 'en';

@State<LanguageStateModel>({
  name: 'language',
  defaults: {
    language: null,
  },
})
@Injectable()
export class LanguageState {
  constructor(
    private readonly sessionStorage: SessionStorageEngine,
    private localStorage: LocalStorageService
  ) {}

  @Selector()
  static language({ language }: LanguageStateModel): string {
    // Everywhere where you use this selector (in components, services),
    // add map for value with the next order assigning a value:
    //   1. language in SessionStorage;
    //   2. language in LocalStorage;
    //   3. value from this selector.
    // This is need to avoid remapping language on page reload.
    return language || DEFAULT_LANGUAGE;
  }

  @Action(SetLanguage)
  setLanguage(
    { patchState }: StateContext<LanguageStateModel>,
    { language }: SetLanguage
  ) {
    this.sessionStorage.setItem(SessionStorageKeys.language, language);
    this.localStorage.setItem(LocaleStorageKeys.language, language);

    patchState({ language });
  }

  @Action(InitializeLanguage)
  initializeLanguage({ patchState }: StateContext<LanguageStateModel>) {
    patchState({
      language: (this.sessionStorage.getItem(SessionStorageKeys.language) ||
        this.localStorage.getItem(LocaleStorageKeys.language) ||
        DEFAULT_LANGUAGE) as AvailableLanguage,
    });
  }
}
