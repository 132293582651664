import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { SharedModule } from '../../_modules/shared.module';
import { SpinnerComponent } from './spinner.component';
import { SpinnerState } from './spinner.state';

@NgModule({
  imports: [SharedModule, NgxsModule.forFeature([SpinnerState])],
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
})
export class SpinnerModule {}
