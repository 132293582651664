import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

import {
  BundleItem,
  Modifier,
  SelectedModifier,
} from 'src/app/_shared/_interfaces/item.model';

@Component({
  selector: 'rs-bundle-item',
  templateUrl: './bundle-item.component.html',
  styleUrls: ['../_styles/item-details.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundleItemComponent {
  @Input() modifiersControl: FormControl = new FormControl([]);
  @Input() item!: BundleItem;
  @Input() bundleSectionId!: string;
  @Input() itemIndex!: number;
  @Input() metadata: any = { modifierSelections: [] };
  @Input() set modifiers(modifiers: Modifier[]) {
    this.availableModifiers = [...modifiers];

    this.setValidatorsIfRequiredModifiers(modifiers);
  }
  @Input() disableOrdering = false;

  public availableModifiers: Modifier[] = [];

  @Output() bundleItemUpdated: EventEmitter<{
    bundleItemId: string;
    bundleSectionId: string;
    modifiers: {
      modifierId: string;
      modifierOptionIds: string[];
    }[];
  }> = new EventEmitter();

  @Output() modifiersControlsValid: EventEmitter<boolean> = new EventEmitter();

  public selectModifiers(modifiers: any[]): void {
    this.bundleItemUpdated.next({
      bundleItemId: this.item.id,
      bundleSectionId: this.bundleSectionId,
      modifiers,
    });

    this.modifiersControlsValid.next(this.modifiersControl.valid);
  }

  public setValidatorsIfRequiredModifiers(modifiers: Modifier[]): void {
    const requiredModifierIds = this.getRequiredModifierIds(modifiers);
    const modifiersControl = this.modifiersControl;

    if (requiredModifierIds.length) {
      this.setModifierValidator(requiredModifierIds);
    } else {
      modifiersControl.clearValidators();
    }

    modifiersControl.updateValueAndValidity();

    this.modifiersControlsValid.next(modifiersControl.valid);
  }

  private getRequiredModifierIds(modifiers: Modifier[]): string[] {
    return modifiers.reduce((accum, modifier) => {
      if (modifier.itemModifier.mandatory) {
        accum.push(modifier.id);
      }

      return accum;
    }, [] as string[]);
  }

  private setModifierValidator(requiredModifierIds: string[]): void {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as SelectedModifier[];
      const selectedModifiers = value.map(({ modifierId }) => modifierId);
      const selectedModifiersSet = new Set(selectedModifiers);

      return requiredModifierIds.every(id => selectedModifiersSet.has(id))
        ? null
        : { missedMandatoryItem: true };
    };

    this.modifiersControl.setValidators(validatorFn);
  }
}
