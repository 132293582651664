export enum Breakpoints {
  small = 576,
  medium = 768,
  large = 992,
  extraLarge = 1200,
}

export enum MenuBreakpoints {
  MobileOneCard = 480,
  TabletOneCard = 860,
  TwoCards = 1304,
}
