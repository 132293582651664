import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Store } from '@ngxs/store';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import {
  GetShadowUserToken,
  SaveUserName,
} from 'src/app/_shared/_ngxs/authentication.actions';

@Component({
  selector: 'rs-authorisation-guest',
  standalone: true,
  imports: [SharedModule],
  templateUrl: 'authorisation-guest.component.html',
  styleUrls: ['../authorisation.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorisationGuestComponent {
  public guestForm: FormGroup = this.formBuilder.group({
    name: new FormControl('', Validators.required),
  });

  constructor(
    private store: Store,
    private readonly formBuilder: FormBuilder
  ) {}

  public get name(): AbstractControl | null {
    return this.guestForm.controls['name'];
  }

  public submitGuestForm(): void {
    const { name } = this.guestForm.value;

    this.store.dispatch([new SaveUserName(name), new GetShadowUserToken()]);
  }
}
