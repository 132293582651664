<div
  class="rs-status-dialog-header w-100 d-flex"
  [ngClass]="{
    'rs-status-dialog-header--success': isSuccessMessage,
    'rs-status-dialog-header--error': !isSuccessMessage
  }"
>
  <mat-icon
    *ngIf="isSuccessMessage; else rsErrorIcon"
    [svgIcon]="successIcon"
  ></mat-icon>

  <ng-content select="[rs-status-dialog-header]"></ng-content>
</div>
<div class="rs-status-dialog-header__details w-100 d-flex">
  <ng-content select="[rs-status-dialog-header-details]"></ng-content>
</div>

<ng-template #rsErrorIcon>
  <mat-icon [svgIcon]="clearIcon"></mat-icon>
</ng-template>
