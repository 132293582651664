import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { CountrySelectComponent } from './country-select.component';

@NgModule({
  imports: [SharedModule],
  declarations: [CountrySelectComponent],
  exports: [CountrySelectComponent],
})
export class CountrySelectModule {}
