import { VenueOrderSettings } from '../_models/venue-order-settings';

export class UpdateVenueOrderSettings {
  static readonly type = '[VenueState] UpdateVenueOrderSettings';

  constructor(public venueOrderSettings: VenueOrderSettings) {}
}

export class CalculateAvailableScheduleRangeForOrder {
  static readonly type =
    '[VenueOrderSettingsState] CalculateAvailableScheduleRangeForOrder';
}
