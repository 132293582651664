import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';

@Directive({
  selector: '[rsImageLoader]',
})
export class ImageLoaderDirective implements AfterViewInit {
  private intersectionObserver!: IntersectionObserver;
  private defaultImageWrapper: HTMLElement = this.document.createElement('div');

  constructor(
    private imageRef: ElementRef,
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private readonly platformId: string
  ) {}

  public ngAfterViewInit(): void {
    this.defaultImageWrapper.className = 'rs-lazy-loading-default-image';

    if (isPlatformBrowser(this.platformId)) {
      this.intersectionObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          this.checkForIntersection(entries);
        },
        {}
      );

      this.intersectionObserver.observe(<Element>this.imageRef.nativeElement);
    }
  }

  private checkForIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      const { clientHeight, clientWidth } = entry.target;
      const { offsetTop, offsetLeft } = this.imageRef.nativeElement;

      this.defaultImageWrapper.style.width = `${clientWidth}px`;
      this.defaultImageWrapper.style.height = `${clientHeight}px`;
      this.defaultImageWrapper.style.top = `${offsetTop}px`;
      this.defaultImageWrapper.style.left = `${offsetLeft}px`;
      entry.target.insertAdjacentElement('afterend', this.defaultImageWrapper);

      if (this.checkIfIntersecting(entry)) {
        this.defaultImageWrapper.remove();
        this.intersectionObserver.unobserve(
          <Element>this.imageRef.nativeElement
        );
        this.intersectionObserver.disconnect();
      }
    });
  };

  private checkIfIntersecting(entry: IntersectionObserverEntry): boolean {
    return (<any>entry).isIntersecting && !!entry.time;
  }
}
