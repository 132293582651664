import { Pipe, PipeTransform } from '@angular/core';

import { DeliveryType } from '../_enums/order.enum';
import { OrderItemType } from '../_enums/order-item-type.enum';
import { OrderCheckEntry } from '../_interfaces/check-calculation.model';
import { Menu } from '../_interfaces/menu.model';
import {
  DeliveryDataDateTime,
  GenericOrderResponse,
} from '../_interfaces/order.model';

import {
  isAvailableMenuForCurrentOrderType,
  isAvailableMenuItemForSelectedTime,
} from '../_utils/menus';

@Pipe({ name: 'orderEntries' })
export class OrderEntriesPipe implements PipeTransform {
  transform(order: GenericOrderResponse): OrderCheckEntry[] {
    let allEntries: OrderCheckEntry[] = [];

    order?.checks?.forEach(check => {
      allEntries.push(...(check.entries || []));
    }, []);

    return allEntries;
  }
}

@Pipe({ name: 'orderItemsToItemsInList' })
export class OrderItemsToItemsInListPipe implements PipeTransform {
  transform(
    items: any,
    entries: OrderCheckEntry[],
    type = OrderItemType.item
  ): any[] {
    return (
      items.map((item: any) => {
        return {
          type,
          itemId: item.itemId || '',
          description: (item.description || '')
            .split('; ')
            .sort((a: string, b: string) => a.localeCompare(b)),
          image:
            entries.find(entry => entry.itemId === item.itemId)?.photo || '',
          name: item?.name || '',
          customerFacingName: item?.customerFacingName || '',
          recipientName: item?.recipientName,
          size: item?.size,
          bundleItems: item?.bundleItems || [],
          quantity: item?.quantity || 1,
          modifiers: item?.modifiers,
          currentBalance: item?.currentBalance,
          price: item?.price?.value,
          specialRequest: item?.specialRequest || '',
        };
      }) || []
    );
  }
}

@Pipe({ name: 'isSelectedItem' })
export class IsSelectedItem implements PipeTransform {
  transform(items: any[] | undefined): boolean {
    if (!items?.length) {
      return false;
    }

    return !!items.find(({ selectedInCart }: any) => selectedInCart);
  }
}

@Pipe({ name: 'rsAddItemsAvailability' })
export class AddItemsAvailabilityPipe implements PipeTransform {
  transform(
    items: any,
    orderType: DeliveryType,
    selectedOrderPeriod: DeliveryDataDateTime,
    menus?: Menu[]
  ): any[] {
    return (
      items?.map((item: any, index: number) => {
        const menuAvailabilityAtSelectedTime = menus?.length
          ? isAvailableMenuItemForSelectedTime(
              item.menuId,
              orderType,
              selectedOrderPeriod,
              menus,
              item.isAvailable
            )
          : true;
        const menuAvailabilityForSelectedDeliveryType = menus?.length
          ? isAvailableMenuForCurrentOrderType(item.menuId, orderType, menus)
          : true;
        items[index] = {
          ...item,
          menuAvailabilityAtSelectedTime,
          menuAvailabilityForSelectedDeliveryType,
        };

        return items[index];
      }) || []
    );
  }
}
