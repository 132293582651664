import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { SelectedModifier } from '../_interfaces/item.model';
import { getModifierTitleWithAmount } from '../_utils/items.helper';

@Pipe({ name: 'rsCartItemsAmount' })
export class CartItemsAmountPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  transform(amount: string | null): string {
    return this.translate.instant(
      `CART.{amount} item${amount === '1' ? '' : 's'}`,
      { amount: amount || 0 }
    );
  }
}

@Pipe({ name: 'rsModifierTitleWithAmount' })
export class ModifierTitleWithAmount implements PipeTransform {
  transform(modifierTitle: string, modifiers: SelectedModifier[] = []): string {
    return getModifierTitleWithAmount(modifierTitle, modifiers);
  }
}
