import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { emptyOrNoCharactersValidator } from './_validations/validators';

import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';

import { getRandomNumber } from '../../_utils/common';
import {
  AUTOCOMPLETE_FORM_FIELD_ID,
  AUTOCOMPLETE_PANEL_CLASS,
} from '../../_components/rs-autocomplete-field/autocomplete.constants';
import { untilDestroyed } from '../../_utils/until-destroyed';

@Component({
  selector: 'rs-auto-complete-input',
  styleUrls: ['./auto-complete-input.component.scss'],
  templateUrl: './auto-complete-input.component.html',
})
export class RsAutoCompleteInputComponent {
  @Input() control: FormControl = new FormControl();
  @Input() injectedSuccess!: string;
  @Input() injectedWarning!: string;
  @Input() placeholder: string = '';
  @Input() label!: string;
  @Input() hint!: string;
  @Input() successMessage!: string;
  @Input() hasClearButton: boolean = true;
  @Input() hasSuccessStatus: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() public options: any[] = [];
  @Input() public matFormFieldClass: string = '';
  @Input() public iconType!: CommonIcons;

  @HostBinding('class.w-100') w100Class = true;

  @ViewChild('autoCompleteInput') activeInput!: ElementRef<HTMLInputElement>;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  public autoComplete: MatAutocompleteTrigger | undefined;

  public isActive: boolean = false;
  public form: FormGroup = new FormGroup({});
  public currentErrorType: any = { invalid: true };
  public formControl: FormControl = new FormControl();
  public isInvalid: boolean = false;
  public isValid: boolean = false;
  public ifVisibilityOff: boolean = true;
  private clearingValue: boolean = false;

  public idVariablePart!: string;
  public autocompleteClass!: string;
  public matFormFieldId!: string;

  public readonly success = CommonIcons.Success;
  public readonly error = CommonIcons.Error;
  public readonly angleDown = CommonIcons.AngleDown;

  public get applyPadding(): boolean {
    return (
      (this.hasClearButton &&
        this.formControlValue !== null &&
        this.isActive) ||
      this.isValid ||
      this.isInvalid
    );
  }

  public get showClearButton(): boolean {
    return (
      this.hasClearButton &&
      this.isControlValueNotEmpty() &&
      this.isActive &&
      !this.isValid &&
      !this.isInvalid
    );
  }

  public get controlValue(): string | number | null {
    return this.control?.value;
  }

  public get formControlValue(): string | null {
    return this.formControl?.value;
  }

  private readonly destroyed$ = untilDestroyed();

  @Output() onFocus: EventEmitter<Event> = new EventEmitter(); // 'onFocus' will emit after we perform a validity reset upon the focus event.
  @Output() onKeyDownEnter: EventEmitter<Event> = new EventEmitter(); // 'onInput' will emit after we perform a validity reset and if type is Card, it will check the card type upon the input event.
  @Output() onDebouncedInput: EventEmitter<Event> = new EventEmitter();
  @Output() formControlReset: EventEmitter<Event> = new EventEmitter();
  @Output() onOptionSelected: EventEmitter<Event> = new EventEmitter();
  @Output() controlChange = new EventEmitter<FormControl<any>>();
  @Output() injectedWarningChange = new EventEmitter<string>();
  @Output() injectedSuccessChange = new EventEmitter<string>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly svgIconService: SvgIconService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.idVariablePart = String(getRandomNumber());
      this.matFormFieldId = `${AUTOCOMPLETE_FORM_FIELD_ID}-${this.idVariablePart}`;
      this.autocompleteClass = `${AUTOCOMPLETE_PANEL_CLASS}-${this.idVariablePart}`;
    } else {
      const randomNumber = parseInt(String(Math.random() * 1000));
      this.idVariablePart = String(randomNumber);
      this.matFormFieldId = `${AUTOCOMPLETE_FORM_FIELD_ID}-${this.idVariablePart}`;
      this.autocompleteClass = `${AUTOCOMPLETE_PANEL_CLASS}-${this.idVariablePart}`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['injectedWarning'] && !changes['injectedWarning'].firstChange) {
      this.isInvalid = true;
      this.isValid = false;
    } else if (
      changes['injectedSuccess'] &&
      !changes['injectedSuccess'].firstChange
    ) {
      this.isValid = true;
      this.isInvalid = false;
    }
  }

  ngOnInit(): void {
    this.registerIcons();

    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.updateAutoCompletePanelPosition();

    this.setupDefaultInput();
    this.subscribeToFormControl();
  }

  ngAfterViewInit(): void {
    this.setupDefaultInput();
    this.subscribeOnInputControl();
  }

  private subscribeOnInputControl(): void {
    this.control.valueChanges
      .pipe(this.destroyed$())
      .subscribe((value: string) => this.formControl.setValue(value));
  }

  private updateAutoCompletePanelPosition(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.scrollHandler, true);
    }
  }

  private scrollHandler = (): void => {
    if (this.autoComplete && this.autoComplete?.panelOpen) {
      this.autoComplete.updatePosition();
    }
  };

  private setupDefaultInput(): void {
    this.formControl = new FormControl(
      this.controlValue,
      this.defaultValidators()
    );
  }

  private subscribeToFormControl(): void {
    this.formControl.valueChanges.pipe(this.destroyed$()).subscribe(() => {
      this.clearWarningSuccess();

      this.control.setValue(this.formControl.value);
    });

    this.changeDetectorRef.detectChanges();
  }

  private defaultValidators(): ValidatorFn[] | null {
    return this.required
      ? [Validators.required, emptyOrNoCharactersValidator()]
      : [Validators.nullValidator];
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.iconType, this.success, this.error, this.angleDown],
      NEW_ICONS_DIRECTORY
    );
  }

  public clearValue(): void {
    this.clearingValue = true;
    this.formControl.reset();
    this.formControlReset.emit();

    setTimeout(() => {
      this.activeInput?.nativeElement.focus();
    }, 50);

    setTimeout(() => {
      this.clearingValue = false;
    }, 250);
  }

  public reactOnFocus(): void {
    this.resetValidity();

    setTimeout(() => {
      this.activeInput?.nativeElement.focus();
    }, 50);

    this.onFocus.emit();
  }

  public reactOnOptionSelected(event: Event): void {
    setTimeout(() => {
      this.isActive = true;
      this.changeDetectorRef.detectChanges();
    }, 150);

    this.onOptionSelected.emit(event);
  }

  private clearWarningSuccess(): void {
    this.isInvalid = false;
    this.isValid = false;

    this.injectedWarning = '';
    this.injectedSuccess = '';

    this.injectedWarningChange.emit(this.injectedWarning);
    this.injectedSuccessChange.emit(this.injectedSuccess);
  }

  public reactOnKeyDownEnter(event: Event): void {
    event.preventDefault();
    this.onKeyDownEnter.emit();
  }

  public reactOnKeyDownTab(): void {
    this.checkFormErrorStates();
  }

  private checkFormErrorStates(): void {
    this.isValid = false;
    if (this.injectedSuccess || this.injectedWarning) {
      this.isValid = !!this.injectedSuccess;
      this.isInvalid = !this.injectedSuccess && !!this.injectedWarning;
    } else if (this.formControl?.invalid) {
      this.isInvalid = this.formControl?.invalid;
      !this.isInvalid &&
        this.formControlValue &&
        this.hasSuccessStatus &&
        (this.isValid = true);
    } else {
      this.formControlValue && this.hasSuccessStatus && (this.isValid = true);
    }
  }

  public onOutsideClick(): void {
    if (!this.clearingValue) {
      this.checkFormErrorStates();
      this.isActive = false;
    }
  }

  private isControlValueNotEmpty(): boolean {
    const value = this.formControlValue;

    return !(
      value === null ||
      value === '' ||
      (typeof value === 'string' && value.trim() === '')
    );
  }

  private resetValidity(): void {
    this.isActive = true;
    this.isValid = false;
    this.isInvalid = false;
  }

  public toggleAutocompletePanel(): void {
    if (this.autoComplete?.panelOpen) {
      this.autoComplete?.closePanel();
    } else {
      this.autoComplete?.openPanel();
      this.isInvalid = false;
      setTimeout(() => {
        this.activeInput?.nativeElement.focus();
      }, 50);
    }
  }
}
