import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

export enum BannerAlertStatusOption {
  Information = 'information',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}

export type BannerAlertStatus = BannerAlertStatusOption;

export const getBannerAlertIcon = (status: BannerAlertStatusOption) => {
  switch (status) {
    case BannerAlertStatusOption.Warning:
      return CommonIcons.Warning;
    case BannerAlertStatusOption.Error:
      return CommonIcons.Error;
    case BannerAlertStatusOption.Success:
      return CommonIcons.Success;
    default:
      return CommonIcons.Information;
  }
};

export const BANNER_ALERT_STATUSES: BannerAlertStatus[] = [
  BannerAlertStatusOption.Information,
  BannerAlertStatusOption.Warning,
  BannerAlertStatusOption.Error,
  BannerAlertStatusOption.Success,
];

export const BANNER_ALERT_ICONS: CommonIcons[] = [...BANNER_ALERT_STATUSES].map(
  status => getBannerAlertIcon(status)
);
