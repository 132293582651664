import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { WineRating } from '../../_interfaces/item.model';
import { MaterialModule } from '../../_modules/material.module';
import { WineReviewComponent } from './wine-review/wine-review.component';

@Component({
  selector: 'rs-wine-reviews',
  templateUrl: './wine-reviews.component.html',
  styleUrls: ['./wine-reviews.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule, WineReviewComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WineReviewsComponent {
  @Input() ratings!: WineRating[];

  public showAll!: boolean;
}
