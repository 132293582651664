<div class="rs-order-type-toggle__title" *ngIf="title">
  {{ 'DELIVERY_METHOD.' + title | translate }}
</div>

<rs-button-segmented-wrapper *ngFor="let isDesktop of [isDesktop$ | async]">
  <ng-container *ngFor="let deliveryMethod of activeDeliveryMethods$ | async">
    <button
      *ngIf="deliveryMethod.method !== DeliveryMethod.Curbside"
      rs-style="segmented"
      [ngClass]="{
        'rs-button--active': deliveryMethod.method === selectedOrderMethod
      }"
      [disabled]="!deliveryMethod.active"
      (click)="changeSelectedOrderType(deliveryMethod.method)"
    >
      <ng-container [ngSwitch]="deliveryMethod.method">
        <ng-container *ngSwitchCase="DeliveryMethod.Curbside">
          <mat-icon *ngIf="isDesktop && !hideIcons" [svgIcon]="curbsideIcon"></mat-icon>
          {{ 'SCHEDULE.' + deliveryMethod.method | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="DeliveryMethod.Delivery">
          <mat-icon *ngIf="isDesktop && !hideIcons" [svgIcon]="deliveryIcon"></mat-icon>
          {{ 'SCHEDULE.' + deliveryMethod.method | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="DeliveryMethod.Takeout">
          <mat-icon *ngIf="isDesktop && !hideIcons" [svgIcon]="takeoutIcon"></mat-icon>
          {{ 'SCHEDULE.' + deliveryMethod.method | translate }}
        </ng-container>
      </ng-container>
    </button>
  </ng-container>
</rs-button-segmented-wrapper>
