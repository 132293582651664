<rs-create-password-form
  [formGroup]="formGroup"
  [showWarningIcon]="true"
  (formUpdated)="updateValues($event)"
  (formIsValid)="formIsValid = $event"
></rs-create-password-form>
<button
  rs-style="bold"
  class="w-100 m-1"
  [disabled]="!formIsValid"
  (click)="submitPassword()"
>
  <span>
    {{
      (isResetPassword
        ? 'CREATE_PASSWORD.reset_password'
        : 'CREATE_PASSWORD.create_password'
      )
        | translate
        | uppercase
    }}
  </span>
</button>
