import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { Store } from '@ngxs/store';
import { Observable, map, distinctUntilChanged, filter } from 'rxjs';

import { cloneDeep } from 'lodash';

import {
  ItemDetails,
  SizesAndPrice,
} from 'src/app/_shared/_interfaces/item.model';
import { ItemState } from 'src/app/_shared/_ngxs/item.state';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

@Component({
  selector: 'rs-item-sizes',
  templateUrl: './item-sizes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ItemSizesComponent),
    },
  ],
})
export class ItemSizesComponent implements ControlValueAccessor {
  @Input() formControl: FormControl<SizesAndPrice | null> = new FormControl();
  @Input() currentValueName: string | undefined = '';

  public formGroup: FormGroup = new FormGroup({
    size: this.formControl,
  });

  public readonly item$: Observable<ItemDetails | null> = this.store
    .select(ItemState.item)
    .pipe(
      filter(item => !!item),
      map(item => {
        const menuId =
          this.store.selectSnapshot(ItemState.itemData)?.menuId || '';
        let updatedItem = cloneDeep(item);

        !!menuId &&
          updatedItem?.sizesAndPrices?.map(sizeAndPrices => {
            sizeAndPrices.menuAttributes =
              sizeAndPrices?.menuAttributes?.filter(
                menuAttribute => menuAttribute.menuId === menuId
              ) || [];

            return sizeAndPrices;
          });

        return updatedItem;
      })
    );
  // note: filter only sizes for current menu, otherwise size not belonds here
  public readonly sizesAndPrices$: Observable<SizesAndPrice[]> =
    this.item$.pipe(
      map(item =>
        (item?.sizesAndPrices || []).filter(size => !!size?.menuAttributes?.[0])
      )
    );
  // note: if some size has price we need to show price, otherwise don't need to show zeros
  public readonly showPricesForSizes$: Observable<boolean> =
    this.sizesAndPrices$.pipe(
      map(
        sizesAndPrices =>
          !!sizesAndPrices.find(size => !!size?.menuAttributes?.[0].price)
      )
    );

  private readonly destroyed$ = untilDestroyed();
  private onChange!: (value: any) => void;

  @Output() sizeSelection: EventEmitter<{
    size: SizesAndPrice;
    index: number;
  }> = new EventEmitter<{
    size: SizesAndPrice;
    index: number;
  }>();

  constructor(
    private readonly store: Store,
    private readonly changeDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initializeCurrentValue();

    // this trick for case when
    // opened item and then edit similar item from cart
    // previously sizes showed incorrectly
    this.formControl.valueChanges
      .pipe(
        distinctUntilChanged(
          (previous, current) => previous?.name === current?.name
        ),
        this.destroyed$()
      )
      .subscribe(() => {
        setTimeout(() => {
          this.initializeCurrentValue();
        }, 100);
      });
  }

  ngOnChanges(): void {
    this.formGroup = new FormGroup({
      size: this.formControl,
    });
  }

  private initializeCurrentValue(): void {
    this.currentValueName = this.formControl?.value?.name || '';
    this.changeDetectionRef.detectChanges();
  }

  public reactOnSizeSelection(size: SizesAndPrice, index: number): void {
    this.sizeSelection.emit({ size, index });
  }

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public writeValue(_: any): void {}
  public registerOnTouched(_: () => void): void {}
}
