import { Injectable } from '@angular/core';

import {
  Action,
  createSelector,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import {
  catchError,
  combineLatest,
  forkJoin,
  of,
  switchMap,
  tap,
  throwError,
  zip,
  filter,
  firstValueFrom,
} from 'rxjs';

import { cloneDeep, map, uniq } from 'lodash';

import {
  AddNewAddress,
  ClaimPoints,
  ClearPointsMessage,
  DeleteAddress,
  DeleteCard,
  DeleteDefaultAddress,
  GetAddressBook,
  GetLoyaltyInformation,
  GetProfilePreferences,
  GetProfileInfo,
  InitializePaymentMethods,
  SaveNewCard,
  SaveProfileInfo,
  SetAddressAsDefault,
  SetDefaultCard,
  UpdateAddress,
  UsePoints,
  UpdateAndSetDefaultAddress,
  DeleteProfileInfo,
  GetProfileOrders,
  SetProfileOrderFavorite,
  GetProfileGiftCards,
  GetProfileOrderPaymentDetails,
  DownloadOrderSummary,
  GetFavorites,
  ClearFavorites,
  AddToFavorites,
  RemoveFromFavorites,
  GetProfileOrderReviewDetails,
  UpdateProfileOrderReviewDetails,
  SavePassword,
  AddNewAndSetAsDefaultAddress,
  GetProfileOrderDetails,
  ClearProfileData,
  SaveAllergensPreferences,
} from './profile.actions';
import {
  HideSpinner,
  ShowSpinner,
} from 'src/app/_shared/_components/spinner/spinner.state';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';
import { ClearHistory } from './dialog.actions';

import {
  getLoyaltyRecordForUsedPoints,
  getRandomLoyaltyRecord,
  LOYALTY_RECORDS,
} from 'src/app/_mock/loyalty.mock';
import { AddressBookService } from '../_modules/address-book/address-book.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PaymentService } from 'src/app/_services/payment.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { LoyaltyError } from 'src/app/_shared/_enums/loyalty.enums';
import { LoyaltyRecord } from 'src/app/_shared/_interfaces/loyalty.models';
import { copy, removeCommas, sortByDate } from 'src/app/_shared/_utils/common';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { Card } from 'src/app/profile/_interfaces/payment.model';
import {
  FavoriteItem,
  Profile,
  ProfilePreferences,
} from 'src/app/profile/_interfaces/profile.model';
import {
  Favorites,
  FavoriteVenues,
} from './../../profile/_interfaces/profile.model';
import {
  CardResponse,
  CardToEdit,
  NewCardToAdd,
} from '../_models/common.interface';
import { getDateForRequestFromString } from '../_utils/dates';
import { Order } from 'src/app/profile/_interfaces/order.model';
import { OrderService } from 'src/app/_services/order.service';
import { CatalogService } from 'src/app/_services/catalog.service';
import { Allergen, Item } from '../_interfaces/item.model';
import {
  ALLERGEN_CATEGORY,
  getNameFromTagName,
  getTagsForSaveWithCategory,
} from '../_constants/tag.constants';
import { GiftCard } from '../_interfaces/gift-card.model';
import { NotificationService } from '../_services/notification.service';
import { OrderPaymentDetails } from '../_interfaces/payment.model';
import { downloadPdfFileFromArrayBuffer } from '../_utils/download-file';
import {
  GenericOrderResponse,
  OrderReview,
  OrderReviewItem,
} from '../_interfaces/order.model';
import { Car } from 'src/app/profile/_interfaces/car.model';
import { SaveUserName } from './authentication.actions';
import { CookieStorageKeys } from '../_enums/cookie-storage-keys.enum';
import { CookieEngine } from 'src/app/_services/cookie.service';
import { SessionState } from './authentication.state';

export interface ProfileStateModel {
  cards: Card[];
  addresses: UserProfileAddress[];
  availablePoints: number;
  addedRecord: LoyaltyRecord | null;
  loyaltyError: LoyaltyError | null;
  loyaltyRecords: LoyaltyRecord[];
  profileInfo: Profile | null;
  preferences: ProfilePreferences;
  orders: Order[];
  currentOrder: GenericOrderResponse | null;
  paymentDetailsByOrderId: Record<string, OrderPaymentDetails[]>;
  reviewDetailsByOrderId: Record<string, OrderReview>;
  availableAllergens: Allergen[];
  giftCards: GiftCard[];
  favoriteItems: FavoriteItem[];
  favoriteVenues: FavoriteVenues[];
  lastAddedAddressID: string;
}

const defaults: ProfileStateModel = {
  cards: [],
  addresses: [],
  availablePoints: 0,
  addedRecord: null,
  loyaltyError: null,
  loyaltyRecords: [],
  profileInfo: null,
  preferences: {
    tags: [],
    items: [],
    nutritionalPreferences: [],
  },
  orders: [],
  currentOrder: null,
  paymentDetailsByOrderId: {},
  reviewDetailsByOrderId: {},
  availableAllergens: [],
  giftCards: [],
  favoriteItems: [],
  favoriteVenues: [],
  lastAddedAddressID: '',
};
const defaultAddressCountry = 'Unites States';

@State<ProfileStateModel>({
  name: 'profile',
  defaults,
})
@Injectable()
export class ProfileState implements NgxsOnInit {
  constructor(
    private readonly paymentService: PaymentService,
    private readonly addressBookService: AddressBookService,
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store,
    private readonly profileService: ProfileService,
    private readonly orderService: OrderService,
    private readonly catalogService: CatalogService,
    private readonly notificationService: NotificationService,
    private readonly cookieEngine: CookieEngine
  ) {}
  private showSuccessMessage(): void {
    this.notificationService.showSuccess('Saved successfully');
  }
  private showDeleteSuccessMessage(): void {
    this.notificationService.showSuccess('Deleted successfully');
  }

  @Selector()
  static cars({ profileInfo }: ProfileStateModel): Car[] | undefined {
    return profileInfo?.cars;
  }

  @Selector()
  static profileId({ profileInfo }: ProfileStateModel): string {
    return profileInfo?.id || '';
  }

  @Selector()
  static cards({ cards }: ProfileStateModel): Card[] {
    return cards;
  }

  @Selector()
  static defaultCard({ cards }: ProfileStateModel): Card | undefined {
    return cards.find(({ default: isDefault }: Card) => isDefault);
  }

  @Selector()
  static addresses({ addresses }: ProfileStateModel): UserProfileAddress[] {
    return addresses;
  }

  @Selector()
  static defaultAddress({
    addresses,
  }: ProfileStateModel): UserProfileAddress | undefined {
    return addresses.find(address => address.isDefault);
  }

  @Selector()
  static lastAddedAddressID({
    lastAddedAddressID,
  }: ProfileStateModel): string | null {
    return lastAddedAddressID;
  }

  @Selector()
  static loyaltyRecords({
    loyaltyRecords,
  }: ProfileStateModel): LoyaltyRecord[] {
    return loyaltyRecords;
  }

  @Selector()
  static availablePoints({ availablePoints }: ProfileStateModel): number {
    return availablePoints;
  }

  @Selector()
  static addedRecord({ addedRecord }: ProfileStateModel): LoyaltyRecord | null {
    return addedRecord;
  }

  @Selector()
  static loyaltyError({
    loyaltyError,
  }: ProfileStateModel): LoyaltyError | null {
    return loyaltyError;
  }

  @Selector()
  static allergens({ preferences }: ProfileStateModel): string[] {
    return (preferences.tags || []).reduce((accumulator, tag) => {
      if (tag.startsWith(ALLERGEN_CATEGORY)) {
        accumulator.push(getNameFromTagName(tag));
      }

      return accumulator;
    }, [] as string[]);
  }

  @Selector()
  static profileInfo({ profileInfo }: ProfileStateModel): Profile | null {
    return profileInfo;
  }

  @Selector()
  static availableAllergens({
    availableAllergens,
  }: ProfileStateModel): Allergen[] {
    return availableAllergens || [];
  }

  @Selector([VenueState.venueId])
  static getOrders({ orders }: ProfileStateModel, venueId: string): Order[] {
    return orders.filter(order => order.venueId === venueId) || [];
  }

  @Selector([ProfileState.getOrders])
  static getFavoritedOrdersNumber(
    _: ProfileStateModel,
    orders: Order[]
  ): number {
    return orders.filter(order => order.isFavorited).length;
  }

  static getOrderById(orderId: string) {
    return createSelector(
      [ProfileState],
      ({ orders }: ProfileStateModel): Order | undefined => {
        return orders.find(order => order.orderId === orderId);
      }
    );
  }

  @Selector()
  static currentOrder({
    currentOrder,
  }: ProfileStateModel): GenericOrderResponse | null {
    return currentOrder;
  }

  static getCardById(cardId: string) {
    return createSelector(
      [ProfileState],
      ({ cards }: ProfileStateModel): Card | undefined => {
        return cards.find(card => card.id === cardId);
      }
    );
  }

  static getPaymentDetailsByOrderById(orderId: string) {
    return createSelector(
      [ProfileState],
      ({
        paymentDetailsByOrderId,
      }: ProfileStateModel): OrderPaymentDetails[] => {
        return paymentDetailsByOrderId[orderId] || [];
      }
    );
  }

  static getReviewsByOrderById(orderId: string) {
    return createSelector(
      [ProfileState],
      ({ reviewDetailsByOrderId }: ProfileStateModel): OrderReviewItem[] => {
        return reviewDetailsByOrderId[orderId]?.reviews || [];
      }
    );
  }

  @Selector([VenueState.venueId, ProfileState.profileId])
  static giftCards(
    { giftCards }: ProfileStateModel,
    venueId: string,
    profileId: string
  ): GiftCard[] {
    return giftCards.filter(
      giftCard =>
        giftCard.venueId === venueId &&
        giftCard.recipientProfileId === profileId &&
        giftCard.currentBalance
    );
  }

  static isFavoriteItem(
    itemId: string,
    menuId: string,
    sectionId: string | undefined
  ) {
    return createSelector(
      [ProfileState],
      ({ favoriteItems }: ProfileStateModel) => {
        // because of BE issue
        const isMenuSectionIdsSavedInFavorites = !!favoriteItems.find(
          ({ menuSectionId }) => !!menuSectionId
        );

        if (isMenuSectionIdsSavedInFavorites && !!sectionId) {
          return !!favoriteItems.find(
            (item: FavoriteItem) =>
              item.itemId === itemId &&
              item.menuId === menuId &&
              item.menuSectionId === sectionId
          );
        } else {
          return !!favoriteItems.find(
            (item: FavoriteItem) =>
              item.itemId === itemId && item.menuId === menuId
          );
        }
      }
    );
  }

  @Selector([VenueState.availableVisibleMenusItems])
  static favoriteItems(
    { favoriteItems }: ProfileStateModel,
    availableVisibleMenusItems: Item[]
  ): Item[] {
    return availableVisibleMenusItems
      .filter(item =>
        ProfileState.isFavoriteItem(item.itemId, item.menuId || '', undefined)
      )
      .map(item => {
        const menuId = favoriteItems.find(
          favoriteItem => favoriteItem.itemId === item.itemId
        )?.menuId;
        const updatedItem = cloneDeep(item);

        updatedItem.menuId = menuId || '';

        return updatedItem;
      })
      .filter(item => !!item.menuId);
  }

  ngxsOnInit({ dispatch }: StateContext<ProfileStateModel>) {
    this.store
      .select(SessionState.isLoggedIn)
      .pipe(filter(isLoggedIn => isLoggedIn))
      .subscribe(() => dispatch(new GetProfilePreferences()));
  }

  @Action(ClearProfileData)
  clearProfileData({ patchState }: StateContext<ProfileStateModel>) {
    patchState({
      preferences: {
        items: [],
        nutritionalPreferences: [],
        tags: [],
      },
    });
  }

  @Action(UpdateAndSetDefaultAddress)
  updateAndSetDefaultAddress(
    _: StateContext<ProfileStateModel>,
    { address, addressId, newDefault }: UpdateAndSetDefaultAddress
  ) {
    return this.store
      .dispatch(new SetAddressAsDefault(newDefault))
      .pipe(
        switchMap(() =>
          this.store.dispatch(new UpdateAddress(address, addressId))
        )
      );
  }

  @Action(DeleteDefaultAddress)
  deleteDefaultAddress(
    _: StateContext<ProfileStateModel>,
    { deletedAddressId, newDefaultAddressId }: DeleteDefaultAddress
  ) {
    return forkJoin([
      this.store.dispatch(new DeleteAddress(deletedAddressId)),
      this.store.dispatch(new SetAddressAsDefault(newDefaultAddressId)),
    ]);
  }

  @Action(SetAddressAsDefault)
  setAddressAsDefault(
    _: StateContext<ProfileStateModel>,
    { addressId }: SetAddressAsDefault
  ) {
    this.store.dispatch(new UpdateAddress({ isDefault: true }, addressId));
  }

  @Action(UpdateAddress)
  updateAddress(
    { getState, patchState, dispatch }: StateContext<ProfileStateModel>,
    { addressId, address }: UpdateAddress
  ) {
    this.store.dispatch(new ShowSpinner());

    const addressInState = getState().addresses.find(
      address => address.id === addressId
    );
    const newAddress = { ...addressInState, ...address };

    return this.addressBookService.updateAddress(newAddress, addressId).pipe(
      tap({
        next: () => {
          const addresses = cloneDeep(getState().addresses);
          const editedAddressIndex = addresses.findIndex(
            (address: UserProfileAddress) => address.id === addressId
          );

          if (newAddress.isDefault) {
            addresses.forEach(
              (address: UserProfileAddress) => (address.isDefault = false)
            );
          }

          (addresses[editedAddressIndex] as any) = newAddress;
          patchState({
            addresses,
          });
          this.showSuccessMessage();
        },
        complete: () => {
          dispatch([new ClearHistory(), new HideSpinner()]);
        },
      })
    );
  }

  @Action(DeleteAddress)
  deleteAddress(
    { patchState, getState }: StateContext<ProfileStateModel>,
    { addressId }: DeleteAddress
  ) {
    this.store.dispatch(new ShowSpinner());

    return this.addressBookService.deleteAddress(addressId).pipe(
      tap({
        next: () => {
          patchState({
            addresses: getState().addresses.filter(
              address => address.id !== addressId
            ),
          });
          this.showDeleteSuccessMessage();
        },
        complete: () => this.store.dispatch(new HideSpinner()),
      })
    );
  }

  @Action(AddNewAndSetAsDefaultAddress)
  addNewAndSetAsDefaultAddress(
    { getState, dispatch }: StateContext<ProfileStateModel>,
    { address }: AddNewAndSetAsDefaultAddress
  ) {
    return dispatch(new AddNewAddress(address)).pipe(
      switchMap(() => {
        const { lastAddedAddressID } = getState();
        return dispatch(new SetAddressAsDefault(lastAddedAddressID));
      })
    );
  }

  @Action(AddNewAddress)
  addNewAddress(
    { patchState, getState }: StateContext<ProfileStateModel>,
    { address }: AddNewAddress
  ) {
    const { address1, address2 } = address;
    const addressForRequest: UserProfileAddress = {
      ...address,
      address1: removeCommas(address1),
      address2: removeCommas(address2),
    };

    address.country = defaultAddressCountry;
    this.store.dispatch(new ShowSpinner());

    return this.addressBookService.createAddress(addressForRequest).pipe(
      tap({
        next: ({ id }) => {
          this.showSuccessMessage();
          patchState({
            addresses: [
              ...getState().addresses,
              {
                ...address,
                id: id,
              },
            ],
            lastAddedAddressID: id,
          });
        },
        complete: () => this.store.dispatch(new HideSpinner()),
      })
    );
  }

  @Action(GetAddressBook)
  getAddressBook({ patchState }: StateContext<ProfileStateModel>) {
    this.store.dispatch(new ShowSpinner());

    return this.addressBookService.getAddressBookNotMock().pipe(
      tap({
        next: addresses => patchState({ addresses }),
        complete: () => this.store.dispatch(new HideSpinner()),
      })
    );
  }

  @Action(InitializePaymentMethods)
  initializePaymentMethods({ patchState }: StateContext<ProfileStateModel>) {
    this.store.dispatch(new ShowSpinner());

    return this.paymentService.getCards().pipe(
      tap({
        next: (response: CardResponse[]) => {
          const responseCards: Card[] = copy(response) || [];

          this.store.dispatch(new HideSpinner());
          patchState({
            cards: responseCards.sort((card: Card) => (card.default ? -1 : 1)),
          });
        },
        error: () => this.store.dispatch(new HideSpinner()),
      })
    );
  }

  @Action(DeleteCard)
  deleteCard(
    { getState, patchState }: StateContext<ProfileStateModel>,
    { cardId }: DeleteCard
  ) {
    this.store.dispatch(new ShowSpinner());

    return this.paymentService.deleteCard(cardId).pipe(
      tap({
        next: () => {
          const existingCards: Card[] = copy(getState().cards);
          const cards = existingCards.filter(({ id }: Card) => id !== cardId);

          this.store.dispatch(new HideSpinner());

          patchState({ cards });
          this.showDeleteSuccessMessage();
        },
      })
    );
  }

  @Action(SetDefaultCard)
  setDefaultCard(
    { getState }: StateContext<ProfileStateModel>,
    { cardId }: SetDefaultCard
  ) {
    const existingCards: Card[] = copy(getState().cards);
    const cardToEdit: CardResponse = copy(
      existingCards.find(({ id }: Card) => id === cardId)
    )!;

    const defaultCard: CardToEdit = {
      ...cardToEdit,
      name: cardToEdit.cardholderName,
      expirationDate: cardToEdit.cardExpirationDate,
      default: true,
    };

    this.store.dispatch(new ShowSpinner());

    return this.paymentService.editCard(defaultCard, cardId).pipe(
      tap(() => this.showSuccessMessage()),
      switchMap(() => this.store.dispatch(new InitializePaymentMethods()))
    );
  }

  @Action(SaveNewCard)
  saveNewCard(
    _: StateContext<ProfileStateModel>,
    { card, address }: SaveNewCard
  ) {
    this.store.dispatch(new ShowSpinner());
    const { cardNumber, cardExpirationDate: expiration } = card;
    /*
      IMPORTANT !!!
      Please, be very careful with data below if you try to add new real card.
      If you try to set some invalid data for your real card, it could be blocked by ELAVON processor.
    */
    const newCard: NewCardToAdd = {
      ...card,
      ...address,
      tokenType: 'rockspoon',
      cardToken: 'rockspoon',
      countryCode: 'United States',
      redactedCardNumber: cardNumber,
      default: false,
      cardExpirationDate: getDateForRequestFromString(expiration),
    };

    this.store.dispatch(new ShowSpinner());

    return this.paymentService.createCard(newCard).pipe(
      tap(() => this.showSuccessMessage()),
      switchMap(() => this.store.dispatch(new InitializePaymentMethods()))
    );
  }

  @Action(GetLoyaltyInformation)
  getLoyaltyInformation({ patchState }: StateContext<ProfileStateModel>) {
    // TODO implement BE integration when it is available
    const availablePoints = parseFloat(
      LOYALTY_RECORDS.reduce((sum, { bonus }) => sum + bonus, 0).toFixed(2)
    );
    patchState({
      loyaltyRecords: LOYALTY_RECORDS,
      availablePoints,
    });
  }

  @Action(ClaimPoints)
  claimPoints(
    { getState, patchState }: StateContext<ProfileStateModel>,
    { code }: ClaimPoints
  ) {
    const existingLoyaltyRecords = getState().loyaltyRecords;

    if (code === '123456789') {
      // TODO if BE enpoint return error or empty result
      patchState({ loyaltyError: LoyaltyError.NoCode });
    } else if (
      existingLoyaltyRecords.findIndex(
        ({ code: currentCode }) => code === currentCode
      ) > -1
    ) {
      patchState({ loyaltyError: LoyaltyError.CodeClaimed });
    } else {
      // TODO implement BE integration when it is available
      const addedRecord: LoyaltyRecord = getRandomLoyaltyRecord(code, true);
      const loyaltyRecords: LoyaltyRecord[] = [
        ...existingLoyaltyRecords,
        addedRecord,
      ].sort(sortByDate);
      const availablePoints = parseFloat(
        loyaltyRecords.reduce((sum, { bonus }) => sum + bonus, 0).toFixed(2)
      );
      patchState({
        loyaltyRecords,
        addedRecord,
        availablePoints,
      });
      this.showSuccessMessage();
    }
  }

  @Action(ClearPointsMessage)
  clearPointsMessage({ patchState }: StateContext<ProfileStateModel>) {
    patchState({ addedRecord: null, loyaltyError: null });
  }

  @Action(UsePoints)
  usePoints(
    { getState, patchState }: StateContext<ProfileStateModel>,
    { points }: UsePoints
  ) {
    // TODO implement BE integration when it available
    const existingLoyaltyRecords = getState().loyaltyRecords;
    const usedPointsRecord: LoyaltyRecord =
      getLoyaltyRecordForUsedPoints(points);
    const loyaltyRecords: LoyaltyRecord[] = [
      ...existingLoyaltyRecords,
      usedPointsRecord,
    ].sort(sortByDate);
    const availablePoints = parseFloat(
      loyaltyRecords.reduce((sum, { bonus }) => sum + bonus, 0).toFixed(2)
    );
    patchState({
      loyaltyRecords,
      addedRecord: usedPointsRecord,
      availablePoints,
    });
  }

  @Action(GetProfileInfo)
  getProfileInfo({ patchState }: StateContext<ProfileStateModel>) {
    this.store.dispatch(new ShowSpinner());

    return this.profileService.getProfileInfo().pipe(
      tap(profileInfo => {
        patchState({
          profileInfo,
        });

        this.cookieEngine.setItem(
          CookieStorageKeys.previouslySavedName,
          profileInfo.firstName || ''
        );

        this.store.dispatch([
          new SaveUserName(`${profileInfo.firstName} ${profileInfo.lastName}`),
          new HideSpinner(),
        ]);
      })
    );
  }

  @Action(SaveProfileInfo)
  saveProfileInfo(
    { getState }: StateContext<ProfileStateModel>,
    { info, image }: SaveProfileInfo
  ) {
    const { id } = getState().profileInfo as Profile;
    const requests = [this.profileService.updateProfileInfo(info)];

    if (image) {
      requests.push(this.profileService.updateProfilePicture(id, image));
    }

    this.store.dispatch(new ShowSpinner());

    return zip(...requests).pipe(
      tap(() => {
        this.store.dispatch([
          new GetProfileInfo(),
          new GetProfilePreferences(),
        ]);

        this.showSuccessMessage();
      })
    );
  }

  @Action(SaveAllergensPreferences)
  async saveAllergensPreferences(
    { dispatch, getState }: StateContext<ProfileStateModel>,
    { allergens }: SaveAllergensPreferences
  ) {
    await firstValueFrom(
      this.profileService.updateProfileTags(
        this.getTagsForSave(getState().preferences.tags, allergens)
      )
    );

    return firstValueFrom(dispatch(new GetProfilePreferences()));
  }

  @Action(SavePassword)
  saveProfilePasword(
    _: StateContext<ProfileStateModel>,
    { password }: SavePassword
  ) {
    this.store.dispatch(new ShowSpinner());

    return this.authenticationService.changePassword(password).pipe(
      tap(() => {
        this.store.dispatch([
          new GetProfileInfo(),
          new GetProfilePreferences(),
        ]);

        this.showSuccessMessage();
      })
    );
  }

  private getTagsForSave(allTags: string[], allergens: string[]) {
    const notAllergensTags = (allTags || []).filter(
      tag => !tag.startsWith(ALLERGEN_CATEGORY)
    );
    const allergensForSave = getTagsForSaveWithCategory(
      allergens,
      ALLERGEN_CATEGORY
    );

    return [...notAllergensTags, ...allergensForSave];
  }

  @Action(DeleteProfileInfo)
  deleteProfileInfo({ patchState }: StateContext<ProfileStateModel>) {
    patchState({
      profileInfo: defaults.profileInfo,
    });
  }

  @Action(GetProfilePreferences)
  getProfilePreferences({ patchState }: StateContext<ProfileStateModel>) {
    return this.profileService.getProfilePreferences().pipe(
      tap(preferences => {
        patchState({
          preferences,
        });
      })
    );
  }

  @Action(GetProfileOrders)
  getProfileOrders({ patchState }: StateContext<ProfileStateModel>) {
    this.store.dispatch(new ShowSpinner());

    return this.profileService.getProfileOrders().pipe(
      tap(ordersResponse => {
        this.store.dispatch(new HideSpinner());

        patchState({ orders: ordersResponse.results });
      })
    );
  }

  // TODO need to fix
  @Action(GetProfileOrderPaymentDetails)
  getProfileOrderPaymentDetails(
    { patchState }: StateContext<ProfileStateModel>,
    { paymentIds, orderId }: GetProfileOrderPaymentDetails
  ) {
    this.store.dispatch(new ShowSpinner());

    const getProfileOrderPaymentDetails: any[] = [];

    for (let i = 0; i < paymentIds.length; i++) {
      getProfileOrderPaymentDetails.push(
        this.profileService.getProfileOrderPaymentDetails(paymentIds[i])
      );
    }

    return combineLatest(getProfileOrderPaymentDetails).pipe(
      tap(paymentDetails => {
        patchState({
          paymentDetailsByOrderId: { [orderId]: paymentDetails },
        });

        this.store.dispatch(new HideSpinner());
      })
    );
  }

  @Action(GetProfileOrderReviewDetails)
  GetProfileOrderReviewDetails(
    { patchState }: StateContext<ProfileStateModel>,
    { checkId, orderId }: GetProfileOrderReviewDetails
  ) {
    this.store.dispatch(new ShowSpinner());

    return this.profileService.getProfileOrderReviewDetails(checkId).pipe(
      tap(reviewDetails => {
        patchState({
          reviewDetailsByOrderId: { [orderId]: reviewDetails },
        });

        this.store.dispatch(new HideSpinner());
      }),
      catchError(error => throwError(error))
    );
  }

  @Action(UpdateProfileOrderReviewDetails)
  updateProfileOrderReviewDetails(
    { patchState, getState }: StateContext<ProfileStateModel>,
    { orderId, reviews }: UpdateProfileOrderReviewDetails
  ) {
    this.store.dispatch(new ShowSpinner());

    return this.profileService.updateProfileOrderReview(orderId, reviews).pipe(
      tap(() => {
        const reviewDetails = getState().reviewDetailsByOrderId[orderId];
        const savedReviews = reviewDetails.reviews;
        const updatedReviews = savedReviews.map(savedReview => {
          const review = reviews.find(({ id }) => id === savedReview.id);

          return review
            ? {
                ...savedReview,
                rating: review.rating,
                review: review.review,
              }
            : savedReview;
        });

        patchState({
          reviewDetailsByOrderId: {
            [orderId]: { ...reviewDetails, reviews: updatedReviews },
          },
        });

        this.store.dispatch(new HideSpinner());
      })
    );
  }

  @Action(DownloadOrderSummary)
  downloadOrderSummary(
    { dispatch }: StateContext<ProfileStateModel>,
    { paymentIds, orderShortId }: DownloadOrderSummary
  ) {
    dispatch(new ShowSpinner());

    const downloadOrderPaymentReceipt: any[] = [];

    const venueName = this.store.selectSnapshot(VenueState.venue)?.name;
    const name = `${venueName} Receipt: #${orderShortId}`;

    for (let i = 0; i < paymentIds.length; i++) {
      downloadOrderPaymentReceipt.push(
        this.orderService.downloadOrderPaymentReceipt(paymentIds[i]).pipe(
          tap(buffer => {
            setTimeout(() => downloadPdfFileFromArrayBuffer(buffer, name));
          }),
          catchError(() => of(null))
        )
      );
    }

    return zip(downloadOrderPaymentReceipt).pipe(
      tap(() => dispatch(new HideSpinner()))
    );
  }

  @Action(SetProfileOrderFavorite)
  setProfileOrderFavorite(
    { patchState, getState }: StateContext<ProfileStateModel>,
    { orderId, isFavorited }: SetProfileOrderFavorite
  ) {
    this.store.dispatch(new ShowSpinner());

    return this.orderService.setOrderFavorite(orderId, isFavorited).pipe(
      tap(() => {
        this.store.dispatch(new HideSpinner());

        const orders = getState().orders.map(order => {
          if (order.orderId === orderId) {
            return {
              ...order,
              isFavorited,
            };
          }

          return { ...order };
        });

        patchState({ orders });

        this.showSuccessMessage();
      })
    );
  }

  @Action(GetProfileGiftCards)
  getProfileGiftCards({ patchState }: StateContext<ProfileStateModel>) {
    this.store.dispatch(new ShowSpinner());

    return this.profileService.getProfileGiftCards().pipe(
      tap(giftCards => {
        this.store.dispatch(new HideSpinner());

        patchState({ giftCards });
      })
    );
  }

  @Action(GetFavorites)
  getFavorites({ patchState }: StateContext<ProfileStateModel>) {
    return this.profileService.getFavorites().pipe(
      tap(({ items: favoriteItems, venues: favoriteVenues }: Favorites) => {
        patchState({
          favoriteItems,
          favoriteVenues,
        });
      })
    );
  }

  @Action(ClearFavorites)
  clearFavorites({ patchState }: StateContext<ProfileStateModel>) {
    patchState({ favoriteItems: [], favoriteVenues: [] });
  }

  @Action(AddToFavorites)
  addToFavorites(
    { patchState, getState }: StateContext<ProfileStateModel>,
    { itemId, menuId, menuSectionId }: AddToFavorites
  ) {
    this.store.dispatch(new ShowSpinner());

    const { favoriteVenues, favoriteItems } = getState();
    const venueId: string = this.store.selectSnapshot(VenueState.venueId);
    const updatedFavoriteItems = [
      ...favoriteItems,
      { itemId, menuId, menuSectionId },
    ];
    let favoriteVenuesId = favoriteVenues.map(
      (favoriteVenue: FavoriteVenues) => favoriteVenue.id
    );

    !favoriteVenuesId.includes(venueId) &&
      (favoriteVenuesId = [...favoriteVenuesId, venueId]);

    return this.profileService
      .setFavorites(favoriteVenuesId, updatedFavoriteItems)
      .pipe(
        tap({
          next: ({
            items: favoriteItems,
            venues: favoriteVenues,
          }: Favorites) => {
            patchState({
              favoriteItems,
              favoriteVenues,
            });
            this.showSuccessMessage();
          },
          complete: () => this.store.dispatch(new HideSpinner()),
        })
      );
  }

  @Action(RemoveFromFavorites)
  removeFromFavorites(
    { patchState, getState }: StateContext<ProfileStateModel>,
    { itemId, menuId, menuSectionId }: RemoveFromFavorites
  ) {
    this.store.dispatch(new ShowSpinner());

    const { favoriteItems, favoriteVenues } = getState();
    const favoriteVenuesId = favoriteVenues.map(
      (favoriteVenue: FavoriteVenues) => favoriteVenue.id
    );
    const ifSectionIdMustBeIgnored: boolean = !favoriteItems.find(
      ({ menuSectionId }) => menuSectionId
    );
    // trick: if we have old favorites, we need to remove them too because
    // previously they don't have menuId, sectionId
    // and for now BE issue: we send menuSectionId but it doesn't returned for us back
    const isFavoriteInDifferentMenus =
      favoriteItems.filter(item => item.itemId === itemId).length > 1;
    const isFavoriteInDifferentSections =
      favoriteItems.filter(
        item => item.itemId === itemId && item.menuId === menuId
      ).length > 1;
    const filterByItemId = (item: { itemId: string }): boolean =>
      item.itemId !== itemId;
    const filterationRule =
      isFavoriteInDifferentMenus &&
      isFavoriteInDifferentSections &&
      !ifSectionIdMustBeIgnored
        ? (item: { itemId: string; menuId: string; menuSectionId: string }) =>
            !(
              item.itemId === itemId &&
              item.menuId === menuId &&
              item.menuSectionId === menuSectionId
            )
        : isFavoriteInDifferentMenus || ifSectionIdMustBeIgnored
        ? (item: { itemId: string; menuId: string }): boolean =>
            !(item.itemId === itemId && item.menuId === menuId)
        : filterByItemId;
    const updatedFavoriteItems = !!menuId
      ? favoriteItems.filter(item => filterationRule(item))
      : favoriteItems.filter(item => filterByItemId(item));

    return this.profileService
      .setFavorites(favoriteVenuesId, updatedFavoriteItems)
      .pipe(
        tap({
          next: ({
            items: favoriteItems,
            venues: favoriteVenues,
          }: Favorites) => {
            patchState({
              favoriteItems,
              favoriteVenues,
            });
            this.showSuccessMessage();
          },
          complete: () => this.store.dispatch(new HideSpinner()),
        })
      );
  }

  @Action(GetProfileOrderDetails)
  getProfileOrderDetails(
    { patchState }: StateContext<ProfileStateModel>,
    { orderId }: GetProfileOrderDetails
  ) {
    this.store.dispatch(new ShowSpinner());

    return this.orderService.getOrderDetailsWithoutDate(orderId).pipe(
      tap({
        next: (order: GenericOrderResponse) => {
          if (!!order) {
            let updatedOrder = cloneDeep(order);
            const itemIds =
              updatedOrder.items?.map(orderItem => orderItem.itemId) || [];
            const allVenueItems = this.store.selectSnapshot(
              VenueState.allItems
            );
            const venueItemsForOrderBeingViewed = allVenueItems.filter(
              venueItem => itemIds.includes(venueItem.itemId)
            );

            updatedOrder.items = updatedOrder.items?.map(orderItem => {
              if (!orderItem.customerFacingName) {
                const suitableVenueItem = venueItemsForOrderBeingViewed.find(
                  venueItem => venueItem.itemId === orderItem.itemId
                );
                const customerFacingName =
                  suitableVenueItem?.customerFacingName ||
                  suitableVenueItem?.name ||
                  '';

                orderItem.customerFacingName = customerFacingName;
              }

              return orderItem;
            });

            patchState({
              currentOrder: updatedOrder,
            });
          }
        },
        error: error => {
          console.error('ERROR from GetProfileOrderDetails action:', error);

          this.store.dispatch(new HideSpinner());
        },
        complete: () => this.store.dispatch(new HideSpinner()),
      })
    );
  }
}
