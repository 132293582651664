import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import moment from 'moment';
import { Periods } from '../_models/common.interface';
import { DeliveryDataDateTime } from '../_interfaces/order.model';
import { ScheduleRange } from 'src/app/_shared/_interfaces/date-settings';

export const MMM_D_YYYY_DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM D, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_AND_TIME_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS',
  },
  display: {
    dateInput: 'ddd, MMM DD YYYY hh:mm A',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MMM_D_YYYY_DATE_FORMAT_PROVIDERS = [
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
  { provide: MAT_DATE_FORMATS, useValue: MMM_D_YYYY_DATE_FORMAT },
];

export const BIRTHDATE_FORMAT = 'YYYY-MM-DD';

export const getNow = (): string => {
  return new Date().toISOString();
};

export const getNPeriodFromNow = (
  nPeriod: number = 0,
  period: 'months' | 'years' | 'days' = 'months'
): string => {
  if (!nPeriod) {
    return getNow();
  }

  return moment().add(nPeriod, period).toISOString();
};
export const getDateStartOfPeriod = (
  startOf: 'month' | 'year' | 'day' = 'month'
): string => {
  return moment().startOf(startOf).toISOString();
};
export const getNDaysFromNow = (nDaysFromNow: number = 0): string => {
  if (!nDaysFromNow) {
    return getNow();
  }

  return getNPeriodFromNow(nDaysFromNow, 'days');
};

// FYI: This trick with '_' is used because of incorrect days numeration from API
export const WEEK_DAYS: string[] = [
  '_',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const getHoursAndMinutesFromInterval = (
  time: string,
  partOfInterval: number
): number[] => {
  const selectedNonFormedTime: moment.Moment = moment(
    time?.split(' - ')[partOfInterval],
    'h:mm A'
  );
  const selectedFormedTime: string[] = selectedNonFormedTime
    .format('HH:mm')
    .split(':');

  const hours: number = +selectedFormedTime[0];
  const minutes: number = +selectedFormedTime[1];

  return [hours, minutes];
};

export const createArrayOfTimes = (
  { from, to }: Periods,
  rangeStep: number,
  day: moment.Moment
): string[] => {
  const timesArray: string[] = [];
  const startHours: number = +from.split(':')[0];
  const startMinutes: number = +from.split(':')[1];
  const endHours: number = +to.split(':')[0];
  const endMinutes: number = +to.split(':')[1];
  const startTime: moment.Moment = moment().set({
    hours: startHours,
    minutes: startMinutes,
  });
  let timeTemp: moment.Moment = startTime.clone();
  const endTime: moment.Moment = moment().set({
    hours: endHours,
    minutes: endMinutes,
  });

  while (timeTemp.isBefore(endTime)) {
    const tempInterval: moment.Moment = moment(timeTemp).add(
      rangeStep,
      'minutes'
    );

    timesArray.push(
      `${timeTemp.format('h:mm A')} - ${tempInterval.format('h:mm A')}`
    );
    timeTemp = tempInterval;
  }

  const selectedDay = moment(day);

  return selectedDay.isValid() && moment(day)!.date() === moment().date()
    ? getAvailableTimeItems(timesArray)
    : timesArray;
};

const getAvailableTimeItems = (timesArray: string[]): string[] =>
  timesArray.filter((time: string) => {
    const [hours, minutes] = getHoursAndMinutesFromInterval(time, 0);
    const formattedTimeItem = moment().set({ hours, minutes });

    return formattedTimeItem.isAfter(moment());
  });

export const ASAPTimeShouldBeAdded = (
  timesArray: string[],
  asapMinTime: number,
  asapMaxTime: number
): boolean => {
  if (!timesArray.length) {
    return false;
  }
  const [hours, minutes] = getHoursAndMinutesFromInterval(timesArray[0], 0);
  const formattedTimeItem = moment().set({ hours, minutes });
  const currentTimePlusMaxPreparationTime = moment().add(
    asapMinTime,
    'minutes'
  );
  const currentTimeMinusMaxPreparationTime = moment().add(
    -asapMaxTime,
    'minutes'
  );

  return (
    formattedTimeItem.isAfter(currentTimeMinusMaxPreparationTime) &&
    formattedTimeItem.isBefore(currentTimePlusMaxPreparationTime)
  );
};

export const changePeriodInAvailablePeriodsToASAPTime = (
  timesArray: string[],
  asapMinTime: number,
  asapMaxTime: number
): string[] => {
  timesArray.shift();
  timesArray.unshift(`ASAP ${asapMinTime} - ${asapMaxTime} MIN`);

  return timesArray;
};

export const EMPTY_DAY_SCHEDULE_RANGE: ScheduleRange = {
  day: moment(),
  periods: [],
};
export const EMPTY_TIME: string = '';
export const EMPTY_DELIVERY_DATE_TIME: DeliveryDataDateTime = {
  day: { ...EMPTY_DAY_SCHEDULE_RANGE },
  time: EMPTY_TIME,
};
