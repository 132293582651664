import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TextMaskModule } from 'angular2-text-mask';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';

import { RsInputAngleDownIconComponent } from './_components/angle-down-icon/angle-down-icon.component';
import { RsAutoCompleteInputComponent } from './auto-complete-input.component';
import { RsInputActionIconComponent } from './_components/action-icon/action-icon.component';
import { RsClickOutsideDirective } from 'src/app/_shared/_directives/click-outside.directive';
import { RsDebounceInputDirective } from 'src/app/_shared/_directives/debounce.directive';
import { MatAutocompletePositionDirective } from 'src/app/_shared/_components/rs-autocomplete-field/autocomplete-position.directive';
import { MaterialModule } from 'src/app/_shared/_modules/material.module';
import { RsPipesModule } from 'src/app/_shared/_modules/pipes.module';

@NgModule({
  declarations: [
    RsAutoCompleteInputComponent,
    RsInputAngleDownIconComponent,
    RsInputActionIconComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RsDebounceInputDirective,
    RsClickOutsideDirective,
    MatAutocompletePositionDirective,
    CurrencyMaskModule,
    MaterialModule,
    TextMaskModule,
    TranslateModule,
    RsPipesModule,
    NgxMatIntlTelInputComponent,
    NgxMaskModule.forChild(),
  ],
  exports: [
    RsAutoCompleteInputComponent,
    RsInputAngleDownIconComponent,
    RsInputActionIconComponent,
  ],
})
export class RsAutoCompleteInputModule {}
