<rs-full-height-dialog>
  <ng-container rs-dialog-header>
    <mat-icon
      tabindex="-1"
      [ngClass]="{
        'd-none': !isPaymentSelectionCompleted
      }"
      mat-dialog-close
      [svgIcon]="backIcon"
      (click)="closePopup()"
    ></mat-icon>

    <p class="m-0">{{ 'PAYING_WITH.Edit Payment' | translate | titlecase }}</p>
  </ng-container>

  <rs-payment-selection
    (isPaymentSelectionCompleted)="setIsPaymentSelectionCompleted($event)"
  ></rs-payment-selection>

  <button rs-style="text" class="mt-2 mb-3" (click)="openAddPaymentDialog()">
    <mat-icon [svgIcon]="addIcon"></mat-icon>

    <span>{{ 'PAYING_WITH.Add Payment Method' | translate | uppercase }}</span>
  </button>

  <rs-tips-selector *ngIf="isShowTips"></rs-tips-selector>
</rs-full-height-dialog>
