<rs-dialog rs-dialog-style="default">
  <ng-container rs-dialog-header>
    <span> {{ 'PROFILE.Log_out_confirmation_question' | translate }}</span>
  </ng-container>
  <ng-container rs-dialog-actions-direction="row">
    <button rs-style="bold" mat-dialog-close (click)="reactOnAccept()">
      <span>{{ 'CROPPER.ACCEPT' | translate }}</span>
    </button>
    <button rs-style="outline" mat-dialog-close>
      <span>{{ 'CROPPER.CANCEL' | translate }}</span>
    </button>
  </ng-container>
</rs-dialog>
