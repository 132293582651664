import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { WineDetailsItemComponent } from './wine-details-item/wine-details-item.component';
import { WineDetailsComponent } from './wine-details.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [WineDetailsComponent, WineDetailsItemComponent],
  exports: [WineDetailsComponent],
})
export class WineDetailsModule {}
