import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

@Component({
  selector: 'rs-panel-wrapper',
  standalone: true,
  imports: [SharedModule],
  templateUrl: 'rs-panel-wrapper.component.html',
  styleUrls: ['rs-panel-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsPanelWrapperComponent {
  @Input() expanded!: boolean;
  @Output() closed: EventEmitter<void> = new EventEmitter();
}
