<mat-radio-button
  class="rs-radio-button-box w-100"
  [ngClass]="{
    'rs-radio-button-box--selected': checked,
    'rs-radio-button-box--disabled': disabled
  }"
  [value]="value"
  [checked]="checked"
  [disabled]="disabled"
  (change)="updateValue($event)"
  labelPosition="before"
  [matTooltip]="tooltip"
  [matTooltipDisabled]="!tooltip"
>
  <p class="rs-radio-button-box__label rs-one-line-text-ellipsis">
    <ng-content></ng-content>
  </p>
  <p
    class="rs-radio-button-box__supporting rs-one-line-text-ellipsis"
    #rsRadioButtonBoxSupporting
    [ngClass]="{
      'd-none': !rsRadioButtonBoxSupporting?.children?.length
    }"
  >
    <ng-content select="[rs-radio-button-box-supporting]"></ng-content>
  </p>
</mat-radio-button>
