<div class="rs-car-form pt-2" [dir]="'languageDirection' | translate">
  <div class="row">
    <rs-autocomplete-field
      class="d-block mb-4 col-4 rs-auto-compelete-car"
      [autocompleteFieldPlaceholder]="'DELIVERY_METHOD.Car Brand' | translate"
      [control]="carForm.controls.make"
      [id]="carBrandId"
      [options]="displayedCarBrandsLabels"
      [formErrors]="controlErrors"
      (optionSelected)="selectCarBrand($event)"
      (filterAutocompleteOptions)="filterCarBrands($event)"
      (enterPressedInInput)="selectFirstCarBrand()"
    ></rs-autocomplete-field>
  </div>

  <div class="row" *ngIf="allModels.length || carForm.controls.model.value">
    <rs-autocomplete-field
      class="d-block mb-4 col-4 rs-auto-compelete-car"
      [autocompleteFieldPlaceholder]="'DELIVERY_METHOD.Car Model' | translate"
      [control]="carForm.controls.model"
      [id]="carModelId"
      [options]="displayedModelsLabels"
      [formErrors]="controlErrors"
      (optionSelected)="selectCarModel($event)"
      (filterAutocompleteOptions)="filterCarModels($event)"
      (enterPressedInInput)="selectFirstCarModel()"
    ></rs-autocomplete-field>

    <div class="col-4">
      <rs-input
        [control]="$any(carForm.controls).color"
        [placeholder]="'DELIVERY_METHOD.Color' | translate"
      >
      </rs-input>
    </div>
    <div class="col-4">
      <rs-input
        [control]="$any(carForm.controls).plate"
        [placeholder]="'DELIVERY_METHOD.Plate #' | translate"
      >
      </rs-input>
    </div>
  </div>
</div>
