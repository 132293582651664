import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { debounceTime, Subject } from 'rxjs';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

@Directive({ selector: '[rsDebounceInput]', standalone: true })
export class RsDebounceInputDirective {
  @Input() duration: number = 500;

  private events: Subject<Event> = new Subject<Event>();
  private readonly destroyed$ = untilDestroyed();

  @HostListener('input', ['$event'])
  reactOnInput(event: Event): void {
    this.events.next(event);
  }

  @Output() debouncedInput: EventEmitter<Event> = new EventEmitter<Event>();

  ngOnInit(): void {
    this.events
      .pipe(this.destroyed$(), debounceTime(this.duration))
      .subscribe(event => this.debouncedInput.emit(event));
  }
}
