import { Component, Input } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

@Component({
  selector: 'rs-menu-title',
  standalone: true,
  imports: [SharedModule],
  template: `<div
    class="w-100 rs-menu-title"
    (click)="ignoreClickIfTitle($event)"
  >
    <ng-content></ng-content>
  </div>`,
})
export class RsMenuTitleComponent {
  public ignoreClickIfTitle(event: Event): void {
    event.stopPropagation();
  }
}

@Component({
  selector: 'rs-menu-item',
  standalone: true,
  imports: [SharedModule],
  template: `<div
    class="rs-menu-item"
    [ngClass]="{ 'rs-menu-item--selected': selected }"
    mat-menu-item
    #rsMenuItem
  >
    <ng-content></ng-content>
  </div>`,
})
export class RsMenuItemComponent {
  @Input() selected!: boolean;
}

@Component({
  selector: 'rs-menu',
  standalone: true,
  imports: [SharedModule, RsMenuItemComponent, RsMenuTitleComponent],
  template: `
    <div [matMenuTriggerFor]="menu">
      <ng-content select="[rs-trigger]"></ng-content>
    </div>
    <mat-menu
      class="rs-menu"
      #menu="matMenu"
      [backdropClass]="isOverlap ? backdropClassForOverlaping : ''"
    >
      <div
        class="d-flex flex-column"
        [ngClass]="{ 'rs-bordered': isBordered, 'rs-default': !isBordered }"
      >
        <ng-content></ng-content>
      </div>
    </mat-menu>
  `,
})
export class RsMenuComponent {
  @Input('rs-bordered') set bordered(_: any) {
    this.isBordered = true;
  }

  @Input('rs-overlap-class') set overlapClass(className: string) {
    if (className) {
      this.isOverlap = true;
      this.backdropClassForOverlaping = className;
    }
  }

  @Input('rs-overlap') set overlap(_: string) {
    this.isOverlap = true;
  }

  public isBordered!: boolean;
  public isOverlap!: boolean;
  public backdropClassForOverlaping: string =
    'rs-menu__backdrop-for-overlapping';
}
