import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ENDPOINTS } from '../_shared/_constants/endpoints';
import {
  CWCreateOrderRequest,
  DineInOrderIDResponse,
  GenericOrderResponse,
  OrderItemRequest,
} from '../_shared/_interfaces/order.model';
import { OrderPaymentDetails } from '../_shared/_interfaces/payment.model';
import { ItemsSuggestionsResponse } from '../_shared/_models/items-suggestions-response.model';

const apiUrl = environment.apiUrl;
const paymentUrl = environment.paymentUrl;

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  // TODO: we need to create Interceptor for using and generate tokens
  private headers = new HttpHeaders().set(
    'access_token',
    'eyJhbGciOiJSUzI1NiIsImtpZCI6InN0ZyIsInR5cCI6IkpXVCIsInZlciI6InYxIn0.eyJ1cmFjIjp7ImVtYWlsIjoiaXJvbi1nb2dhQHlhbmRleC5ydSIsImZpcnN0TmFtZSI6IiIsInByb2ZpbGUiOnsiaWQiOiI2MzE5YzBkYWMzMTczOGRlY2QzMmZlNGYifX0sImdyb3Vwc0NvbmZpZyI6eyJST0NLIjoiY3VzdG9tX3dlYnNpdGUifSwidGVuYW50Ijp7ImlkIjoiNWNjMmY3ZGIwZDE2YjMwMDlmNWMwZDJlIiwiY29kZSI6IlJPQ0siLCJtYWluVGVuYW50SWQiOiI1Y2MyZjdkYjBkMTZiMzAwOWY1YzBkMmUiLCJtYWluVGVuYW50Q29kZSI6IlJPQ0siLCJwcm9maWxlIjp7InZlbnVlSWQiOiIifX0sIm9yZGVyIjp7ImNoZWNrSWQiOiIifSwiaXNTaGFkb3dVc2VyIjp0cnVlLCJleHAiOjE2NjI3MTg1NTQsImlhdCI6MTY2MjYzMjE1NH0.aDYuIFMu0g-2qvFlG0vv4FJ57aWzFOEImo1CGsnfCZKq_jCFz6WbrvsVOaEbwF5OKjb21lVv39TM5aOdVpyKtRIxGXdGf6pCiyNN7CoYE0IwXlyHov9x_v_YTKtlXiDb1kLvzsPR4G_qjEvB5CB9MFvhtTlXnV0SMYDyOJsRCdBMwAiD5NQgz1Ylkcd49akZWSc4ulveN8yD3gCqG4Q8_XnkqzXKm5KJJtd2NdgILtbM-gTR32kuf4ISSZQP3vBoumLDc348uWolm5SH1_MtQrej_G__USMuCWLe2QJTnpd-T8ud7IHaEXUSSYnMnzmiZJNtoT2Io26Ia_tzjM2bYQ'
  );

  constructor(private http: HttpClient) {}

  public createOrder(
    order: CWCreateOrderRequest
  ): Observable<GenericOrderResponse> {
    return this.http.post<GenericOrderResponse>(
      `${apiUrl}/${ENDPOINTS.order.createOrder}`,
      order,
      { headers: this.headers }
    );
  }

  public createProfileOrder(
    order: CWCreateOrderRequest
  ): Observable<GenericOrderResponse> {
    return this.http.post<GenericOrderResponse>(
      `${apiUrl}/${ENDPOINTS.order.createProfileOrder}`,
      order,
      { headers: this.headers }
    );
  }

  public getOrderDetails(orderId: string): Observable<GenericOrderResponse> {
    const url = `${apiUrl}/${ENDPOINTS.order.getOrderDetails(orderId)}`;
    const date = +new Date();

    return this.http.get<GenericOrderResponse>(`${url}?${date}`);
  }

  public getOrderDetailsWithoutDate(
    orderId: string
  ): Observable<GenericOrderResponse> {
    return this.http.get<GenericOrderResponse>(
      `${apiUrl}/${ENDPOINTS.order.getOrderDetails(orderId)}`
    );
  }

  public addDiner(
    orderId: string,
    order: CWCreateOrderRequest
  ): Observable<DineInOrderIDResponse> {
    return this.http.post<DineInOrderIDResponse>(
      `${apiUrl}/${ENDPOINTS.order.addDiner(orderId)}`,
      order,
      { headers: this.headers }
    );
  }

  public createLoggedGuestOrder(
    orderId: string,
    order: CWCreateOrderRequest
  ): Observable<DineInOrderIDResponse> {
    return this.http.post<DineInOrderIDResponse>(
      `${apiUrl}/${ENDPOINTS.order.createLoggedGuestOrder(orderId)}`,
      order,
      { headers: this.headers }
    );
  }

  public updateDineInOrder(
    orderId: string,
    order: OrderItemRequest
  ): Observable<DineInOrderIDResponse> {
    return this.http.post<DineInOrderIDResponse>(
      `${apiUrl}/${ENDPOINTS.order.updateDineInOrder(orderId)}`,
      order,
      { headers: this.headers }
    );
  }

  public setOrderFavorite(
    orderId: string,
    isFavorited: boolean
  ): Observable<void> {
    return this.http.put<void>(
      `${apiUrl}/${ENDPOINTS.order.favorite(orderId)}`,
      {
        favorite: isFavorited,
      }
    );
  }

  public cancelOrder(orderId: string): Observable<void> {
    return this.http.put<void>(
      `${apiUrl}/${ENDPOINTS.order.cancelOrder(orderId)}`,
      {}
    );
  }

  public getOrderPaymentDetails(
    paymentId: string
  ): Observable<OrderPaymentDetails> {
    return this.http.get<OrderPaymentDetails>(
      `${paymentUrl}/${ENDPOINTS.payment.details(paymentId)}`
    );
  }

  public downloadOrderPaymentReceipt(
    paymentId: string
  ): Observable<ArrayBuffer> {
    return this.http.post(
      `${apiUrl}/${ENDPOINTS.order.getReceipt(paymentId)}`,
      {},
      { responseType: 'arraybuffer' }
    );
  }

  public getItemsSuggestion(
    venueId: string,
    startDate: string,
    endDate: string,
    pageSize: number
  ): Observable<ItemsSuggestionsResponse> {
    return this.http.get<ItemsSuggestionsResponse>(
      `${apiUrl}/${ENDPOINTS.order.getItemsSuggestions(
        venueId,
        startDate,
        endDate,
        pageSize
      )}`
    );
  }

  public closeLogInTab(orderId: string, venueId: string): Observable<void> {
    return this.http.post<void>(
      `${apiUrl}/${ENDPOINTS.order.closeLogInTab(orderId)}`,
      {},
      { headers: { ...this.headers, key: venueId } }
    );
  }

  public closeGuestTab(orderId: string, venueId: string): Observable<void> {
    return this.http.post<void>(
      `${apiUrl}/${ENDPOINTS.order.closeGuestTab(orderId)}`,
      {},
      { headers: { ...this.headers, key: venueId } }
    );
  }

  public addToTabForLoggedInUser(
    orderId: string,
    order: OrderItemRequest
  ): Observable<DineInOrderIDResponse> {
    return this.http.post<DineInOrderIDResponse>(
      `${apiUrl}/${ENDPOINTS.order.addToTabForLoggedInUser(orderId)}`,
      order,
      { headers: this.headers }
    );
  }
}
