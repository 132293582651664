import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Store } from '@ngxs/store';

import { Logout } from 'src/app/_shared/_ngxs/authentication.actions';
import { DeleteProfileInfo } from 'src/app/_shared/_ngxs/profile.actions';

@Component({
  selector: 'rs-logout-confirmation-dialog',
  templateUrl: './logout-confirmation-dialog.component.html',
  styleUrls: ['./logout-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutConfirmationDialogComponent {
  constructor(private readonly store: Store) {}

  reactOnAccept(): void {
    this.store.dispatch([new Logout(), new DeleteProfileInfo()]);
  }
}
