import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { isEqual } from 'lodash';

import { SharedModule } from '../../_modules/shared.module';
import { RsCounterComponent } from '../rs-counter/rs-counter.component';
import { RsBannerAlertComponent } from '../../_rs-design/banner/alert/banner-alert.component';
import { RsDefaultFoodIconComponent } from 'src/app/_shared/_components/rs-default-food-icon/rs-default-food-icon.component';
import { CartOrderItemComponent } from '../order-item/cart-order-item.component';
import {
  IsAvailableMenuForCurrentOrderTypePipe,
  IsAvailableMenuItemForSelectedTimePipe,
} from '../../_pipes/menu.pipe';

import { BundleItemState } from 'src/app/_shared/_ngxs/bundle-item.state';
import { OrderDataState } from '../../_ngxs/order-data.state';
import { VenueOrderSettingsState } from '../../_ngxs/venue-order-settings.state';

import { OrderItemType } from 'src/app/_shared/_enums/order-item-type.enum';
import { DeliveryMethod, OrderType } from '../../_enums/order.enum';
import { DefaultFoodIconSize } from 'src/app/_shared/_enums/default-food-icon-size.enums';
import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';

import { ItemInListWithAvailability } from '../../_models/items.model';
import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';
// TODO 4th or 10th epic get rid of device service, use CSS instead
import { DeviceService } from 'src/app/_services/device.service';
// TODO end
import { SvgIconService } from 'src/app/_services/svg-icon.service';

import { untilDestroyed } from '../../_utils/until-destroyed';

@Component({
  selector: 'rs-list-of-items-in-order',
  standalone: true,
  imports: [
    SharedModule,
    RsCounterComponent,
    RsBannerAlertComponent,
    RsDefaultFoodIconComponent,
    CartOrderItemComponent,
  ],
  templateUrl: './list-of-items-in-order.component.html',
  styleUrls: ['./list-of-items-in-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    IsAvailableMenuItemForSelectedTimePipe,
    IsAvailableMenuForCurrentOrderTypePipe,
  ],
})
export class ListOfItemsInOrderComponent {
  @Input() items: ItemInListWithAvailability[] = [];
  @Input() amount = '1';
  @Input() itemCanBeChanged = true;
  @Input() showCheckboxes!: boolean;

  public isMobileView: boolean = false;

  public readonly bundleItemsNames$: Observable<{ [id: string]: string }> =
    this.store.select(BundleItemState.bundleItemsNames);
  public readonly orderType$: Observable<OrderType | DeliveryMethod> =
    this.store.select(OrderDataState.orderType);

  public readonly OrderItemType = OrderItemType;
  protected readonly id = TEST_ID;
  protected readonly DefaultFoodIconSize = DefaultFoodIconSize;

  String = String;
  public everyItemInCartIsSelected: boolean = false;

  public readonly addOutlineIcon = CommonIcons.AddOutline;
  public readonly isThereAnyAvailableOrderMethods$ = this.store.select(
    VenueOrderSettingsState.isThereAnyAvailableOrderMethod
  );

  private readonly destroyed$ = untilDestroyed();

  @Output() increaseItemQuantity: EventEmitter<ItemInListWithAvailability> =
    new EventEmitter<ItemInListWithAvailability>();
  @Output() decreaseItemQuantity: EventEmitter<
    ItemInListWithAvailability | string
  > = new EventEmitter<ItemInListWithAvailability | string>();
  @Output() changedItemSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output() changedItemsSelection: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() clickByItem: EventEmitter<ItemInListWithAvailability> =
    new EventEmitter<ItemInListWithAvailability>();
  @Output() toOrdering: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly store: Store,
    private readonly deviceService: DeviceService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcon();
  }

  ngOnChanges(): void {
    this.checkIsEveryItemInCartSelected();
    this.groupItems();
    this.setScreenWidth();
  }

  private setScreenWidth(): void {
    this.deviceService
      .isMenuMobile()
      .pipe(this.destroyed$())
      .subscribe(isMobile => {
        this.isMobileView = isMobile;
        this.changeDetectorRef.markForCheck();
      });
  }

  public selectItem(cartItemId: string, selected: boolean): void {
    const currentItemIndex = this.items.findIndex(
      item => item.cartItemId === cartItemId
    );
    this.items[currentItemIndex].selectedInCart = selected;

    this.changedItemSelection.emit({
      cartItemId: cartItemId,
      value: selected,
    });

    this.checkIsEveryItemInCartSelected();
  }

  public selectAllItems(selected: boolean): void {
    this.items.forEach((_, index) => {
      this.items[index].selectedInCart = selected;
    });

    this.checkIsEveryItemInCartSelected();
    this.changedItemsSelection.emit(selected);
  }

  public goToOrdering(): void {
    this.toOrdering.emit();
  }

  private checkIsEveryItemInCartSelected(): void {
    this.everyItemInCartIsSelected = !this.items.find(
      ({ selectedInCart }) => !selectedInCart
    );
  }

  private groupItems(): void {
    this.items = this.items.reduce((acc, item) => {
      const index = acc.findIndex(
        searchItem =>
          searchItem.menuId === item.menuId &&
          searchItem.itemId === item.itemId &&
          searchItem.size?.id === item.size?.id &&
          searchItem.specialRequest === item.specialRequest &&
          isEqual(searchItem.bundleItems, item.bundleItems) &&
          isEqual(searchItem.modifiers, item.modifiers)
      );

      if (~index) {
        acc[index].quantity! += 1;
      } else {
        acc.push(item);
      }

      return acc;
    }, [] as ItemInListWithAvailability[]);
  }

  public handleClickByItem(item: ItemInListWithAvailability | null): void {
    if (!item) {
      return;
    }

    this.clickByItem.emit(item);
  }

  private registerIcon(): void {
    this.svgIconService.registerSvgIcons(
      [this.addOutlineIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
