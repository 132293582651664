import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  RsGridItemComponent,
  RsGridItemIconsComponent,
  RsGridItemLabelComponent,
  RsGridItemTextComponent,
} from './grid-item.component';

const components = [
  RsGridItemComponent,
  RsGridItemIconsComponent,
  RsGridItemLabelComponent,
  RsGridItemTextComponent,
];

@NgModule({
  imports: [CommonModule, ...components],
  exports: [...components],
})
export class GridItemModule {}
