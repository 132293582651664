import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { Store } from '@ngxs/store';
import {
  combineLatest,
  filter,
  map,
  Observable,
  tap,
  withLatestFrom,
} from 'rxjs';

import { SharedModule } from '../../_modules/shared.module';
import { RsBannerAlertComponent } from '../../_rs-design/banner/alert/banner-alert.component';
import { CalculateRemainingTotalAfterGiftCardsPipe } from 'src/app/_shared/_pipes/calc-tips.pipe';
import { IsAvailablePaymentTypePipe } from '../dine-in-paying-with/_pipes/dine-in-paying-with.pipes';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import {
  ClearSelectedGiftCardError,
  SelectCardForOrder,
  SelectGiftCardForOrder,
  SetCompletePaymentMethod,
  SetIsGiftCardCoverTotal,
  SetPaymentMethod,
} from 'src/app/_shared/_ngxs/cart.actions';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';
import { GetProfileGiftCards } from 'src/app/_shared/_ngxs/profile.actions';
import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';
import { VenueOrderSettingsState } from 'src/app/_shared/_ngxs/venue-order-settings.state';

import { OrderItemType } from 'src/app/_shared/_enums/order-item-type.enum';
import { PaymentMethods } from 'src/app/_shared/_enums/payment-methods.enum';
import { PaymentIcons } from '../../_enums/digital-storefront-icons.enum';

import { Card } from 'src/app/profile/_interfaces/payment.model';
import { GiftCard } from 'src/app/_shared/_interfaces/gift-card.model';
import { Tips } from 'src/app/_shared/_interfaces/item.model';
import { PaymentViewDescription } from 'src/app/_shared/_interfaces/payment.model';

import { PAYMENT_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { getAvailablePaymentOptions } from 'src/app/_shared/_utils/payment.utils';

// Documentation:
// https://rockspoon.atlassian.net/wiki/spaces/MFT/pages/2305490978/Cart+Checkout+Payment+Validation+on+Frontend+Side
@Component({
  selector: 'rs-payment-selection',
  templateUrl: './payment-selection.component.html',
  providers: [CalculateRemainingTotalAfterGiftCardsPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, RsBannerAlertComponent, IsAvailablePaymentTypePipe],
})
export class PaymentSelectionComponent {
  public readonly isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );
  public readonly giftCards$: Observable<GiftCard[]> = this.store.select(
    ProfileState.giftCards
  );
  public readonly cards$: Observable<Card[]> = this.store.select(
    ProfileState.cards
  );
  public readonly selectedPaymentCard$: Observable<Card | null> =
    this.store.select(CartState.selectedPaymentCard);
  public readonly selectedPaymentMethod$: Observable<PaymentMethods> =
    this.store.select(CartState.paymentMethod);
  public readonly completePaymentMethod$: Observable<
    PaymentMethods | undefined
  > = this.store.select(CartState.completePaymentMethod);
  private readonly tips$: Observable<Tips> = this.store.select(CartState.tips);
  private readonly cartFilling$: Observable<{
    cartItemsType: OrderItemType | null;
  }> = this.store.select(CartState.cartFilling);
  public readonly selectedGiftCard$: Observable<GiftCard | null> =
    this.store.select(CartState.selectedGiftCard);
  public readonly selectedGiftCardError$: Observable<boolean> =
    this.store.select(CartState.selectedGiftCardError);

  public cards: Card[] = [];
  public selectedCard: Card | null = null;
  public selectedCardId: string = '';
  public selectedPaymentMethod: PaymentMethods | undefined =
    PaymentMethods.card;
  public completePaymentMethod!: PaymentMethods | undefined;
  public paymentMethods: typeof PaymentMethods = PaymentMethods;
  public availablePaymentMethods: PaymentViewDescription[] = [];
  public supportApplePay: boolean = false;
  public isShowAdditionalPayment: boolean = false;
  public giftCards: GiftCard[] = [];
  public selectedGiftCard!: GiftCard | undefined | null;

  private readonly destroyed$ = untilDestroyed();

  @Output() isPaymentSelectionCompleted: EventEmitter<boolean> =
    new EventEmitter();

  constructor(
    @Inject(PLATFORM_ID)
    private readonly platformId: string,
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly calculateRemainingTotalAfterGiftCardPipe: CalculateRemainingTotalAfterGiftCardsPipe,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcons();
  }

  public ngOnInit(): void {
    this.getGiftCards();
    this.subscribeOnTipsChanges();
    this.initializeSubscriptions();
    this.getAvailablePaymentMethods();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      Object.values(PaymentIcons),
      PAYMENT_ICONS_DIRECTORY
    );
  }

  public getActivePaymentState(card: Card): boolean {
    return (
      (this.selectedPaymentMethod === this.paymentMethods.card ||
        (this.selectedPaymentMethod === this.paymentMethods.gift_card &&
          this.completePaymentMethod === this.paymentMethods.card)) &&
      this.selectedCardId === card.id
    );
  }

  private getAvailablePaymentMethods(): void {
    const availablePaymentMethods: PaymentMethods[] = this.store.selectSnapshot(
      VenueOrderSettingsState.availablePaymentMethods
    );
    const selectedPaymentMethod: PaymentMethods = this.store.selectSnapshot(
      CartState.paymentMethod
    );

    this.availablePaymentMethods = getAvailablePaymentOptions(
      availablePaymentMethods
    );
    !!this.availablePaymentMethods.length &&
      !availablePaymentMethods.includes(selectedPaymentMethod) &&
      this.store.dispatch(
        new SetPaymentMethod(this.availablePaymentMethods[0].placeholder)
      );

    this.changeDetectorRef.detectChanges();
  }

  private initializeSubscriptions(): void {
    this.selectedPaymentMethod$
      .pipe(this.destroyed$())
      .subscribe(
        (paymentMethod: PaymentMethods) =>
          (this.selectedPaymentMethod = paymentMethod)
      );

    this.completePaymentMethod$
      .pipe(this.destroyed$())
      .subscribe(
        (completePaymentMethod: PaymentMethods | undefined) =>
          (this.completePaymentMethod = completePaymentMethod)
      );

    combineLatest([this.isLoggedIn$, this.cards$, this.selectedPaymentCard$])
      .pipe(
        filter(([isLoggedIn, _, __]) => !!isLoggedIn),
        this.destroyed$()
      )
      .subscribe(
        ([_, cards, selectedPaymentCard]: [boolean, Card[], Card | null]) => {
          this.cards = cards || [];

          const defaultCard = this.cards.find(
            creditCard => creditCard.default
          )!;

          if (selectedPaymentCard) {
            this.selectedCard = selectedPaymentCard;
            this.selectedCardId = selectedPaymentCard?.id || '';
          } else {
            this.selectedCard = defaultCard;
            this.selectedCardId = this.selectedCard?.id || '';
            this.store.dispatch(new SelectCardForOrder(defaultCard));
          }

          this.changeDetectorRef.detectChanges();
        }
      );

    combineLatest([this.isLoggedIn$, this.giftCards$])
      .pipe(
        filter(([isLoggedIn, _]: [boolean, GiftCard[]]) => !!isLoggedIn),
        map(([_, giftCards]: [boolean, GiftCard[]]) => giftCards),
        this.destroyed$()
      )
      .subscribe(
        (giftCards: GiftCard[]) =>
          (this.giftCards = giftCards.filter(giftCard => !!giftCard) || [])
      );

    this.selectedGiftCard$
      .pipe(
        this.destroyed$(),
        tap(selectedGiftCard => (this.selectedGiftCard = selectedGiftCard)),
        withLatestFrom(this.cartFilling$),
        filter(giftCard => !!giftCard)
      )
      .subscribe(
        ([giftCard, cartFilling]) =>
          giftCard &&
          (this.isShowAdditionalPayment =
            cartFilling.cartItemsType !== OrderItemType.gift_card &&
            this.checkRemainingTotalAfterGiftCard(giftCard))
      );
  }

  private checkRemainingTotalAfterGiftCard(
    selectedGiftCard: GiftCard
  ): boolean {
    const isRemainingTotalAfterGiftCard =
      this.calculateRemainingTotalAfterGiftCardPipe.transform(
        selectedGiftCard.currentBalance!
      ) > 0;

    this.store.dispatch(
      new SetIsGiftCardCoverTotal(!isRemainingTotalAfterGiftCard)
    );

    isRemainingTotalAfterGiftCard &&
      this.completePaymentMethod &&
      this.store.dispatch(
        new SetCompletePaymentMethod(this.completePaymentMethod)
      );

    return isRemainingTotalAfterGiftCard;
  }

  private subscribeOnTipsChanges(): void {
    this.tips$
      .pipe(this.destroyed$())
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }

  private getGiftCards(): void {
    this.isLoggedIn$
      .pipe(
        filter(isLoggedIn => isLoggedIn),
        this.destroyed$()
      )
      .subscribe(() => this.store.dispatch(new GetProfileGiftCards()));
  }

  public selectPayment(
    { checked }: MatCheckboxChange,
    paymentMethod: PaymentMethods,
    id?: string
  ): void {
    if (checked) {
      if (paymentMethod === PaymentMethods.gift_card) {
        const completePaymentMethod: PaymentMethods | undefined =
          this.checkRemainingTotalAfterGiftCard(this.giftCards[0]) &&
          this.selectedPaymentMethod === PaymentMethods.card
            ? PaymentMethods.card
            : undefined;

        this.completePaymentMethod = completePaymentMethod;

        const defaultGiftCard: GiftCard = this.giftCards[0];
        const currentCard: GiftCard = id
          ? this.giftCards.find(({ id: giftCardId }) => id === giftCardId) ||
            defaultGiftCard
          : defaultGiftCard;

        this.store.dispatch([
          new SetPaymentMethod(PaymentMethods.gift_card),
          new SelectGiftCardForOrder(currentCard),
          new SetCompletePaymentMethod(this.completePaymentMethod),
        ]);
      } else {
        this.selectNotGiftCardPayment(paymentMethod);
      }
    } else {
      if (paymentMethod === PaymentMethods.gift_card) {
        this.removeGiftCard();

        if (this.completePaymentMethod) {
          this.selectedPaymentMethod = this.completePaymentMethod;
          this.completePaymentMethod = undefined;

          this.store.dispatch([
            new SetPaymentMethod(this.selectedPaymentMethod),
          ]);
        }
      } else {
        if (this.selectedPaymentMethod === PaymentMethods.gift_card) {
          this.completePaymentMethod = undefined;
        } else {
          this.selectedPaymentMethod = undefined;
          this.completePaymentMethod = undefined;
        }
      }
    }

    this.checkIfPaymentSelectionCompleted();
  }

  public selectCardForOrder({ checked }: MatCheckboxChange, card: Card): void {
    if (checked) {
      this.selectNotGiftCardPayment(PaymentMethods.card, card);
    } else {
      this.selectedCard = null;
      this.selectedCardId = '';

      if (this.selectedPaymentMethod === PaymentMethods.gift_card) {
        this.completePaymentMethod = undefined;
      } else {
        this.selectedPaymentMethod = undefined;
        this.completePaymentMethod = undefined;
      }
    }

    this.checkIfPaymentSelectionCompleted();
  }

  public selectNotGiftCardPayment(
    paymentMethod: PaymentMethods,
    card?: Card
  ): void {
    // if gift card selected, it has not enought balance
    // then we can add a card as complete payment
    // otherwise we replace selected payment method
    const ifSelectedPaymentMustBeReplaced: boolean = !(
      this.selectedPaymentMethod === PaymentMethods.gift_card &&
      this.isShowAdditionalPayment &&
      paymentMethod === PaymentMethods.card &&
      !!card
    );
    const actions: any[] = [];
    const selectedCard =
      paymentMethod === PaymentMethods.card && !!card ? card : null;

    this.selectedCard = selectedCard;
    this.selectedCardId = selectedCard?.id || '';

    if (ifSelectedPaymentMustBeReplaced) {
      this.selectedPaymentMethod = paymentMethod;
      this.completePaymentMethod = undefined;

      actions.push(
        new SelectCardForOrder(selectedCard),
        new SetPaymentMethod(paymentMethod),
        new SetCompletePaymentMethod(undefined)
      );
    } else {
      this.completePaymentMethod = paymentMethod;
      this.removeGiftCard();

      actions.push(
        new SelectCardForOrder(selectedCard),
        new SetCompletePaymentMethod(paymentMethod)
      );
    }

    this.store.dispatch(actions);
  }

  private removeGiftCard(): void {
    if (this.selectedPaymentMethod === PaymentMethods.gift_card) {
      this.clearGiftCardSelection();
      this.clearGiftCardError();
    }
  }

  public clearGiftCardSelection(): void {
    this.store.dispatch(new SelectGiftCardForOrder(null));
  }

  public clearGiftCardError(): void {
    this.store.dispatch(new ClearSelectedGiftCardError());
  }

  private checkIfPaymentSelectionCompleted(): void {
    const isPaymentSelectionCompleted: boolean = this.selectedPaymentMethod
      ? this.selectedPaymentMethod === PaymentMethods.gift_card
        ? !this.isShowAdditionalPayment ||
          (this.isShowAdditionalPayment && !!this.completePaymentMethod)
        : true
      : false;

    this.isPaymentSelectionCompleted.emit(isPaymentSelectionCompleted);
  }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId) && (window as any).ApplePaySession) {
      this.supportApplePay = (window as any).ApplePaySession.canMakePayments();
      this.changeDetectorRef.markForCheck();
    }
  }
}
