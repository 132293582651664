import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../_constants/endpoints';
import { VenueInformation } from '../_interfaces/merchant.model';

const apiUrl = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class MerchantService {
  constructor(private http: HttpClient) {}

  public getMerchantInformation(venueId: string): Observable<VenueInformation> {
    return this.http.get<VenueInformation>(
      `${apiUrl}/${ENDPOINTS.merchant.merchantInformation(venueId)}`
    );
  }
}
