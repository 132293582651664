<rs-full-height-dialog rs-dialog-style="default">
  <ng-container rs-dialog-header>
    <mat-icon
      [svgIcon]="closePopupIcon"
      mat-dialog-close
      tabindex="-1"
    ></mat-icon>
  </ng-container>

  <rs-authorisation-wrapper
    [dir]="'languageDirection' | translate"
    [autorisation]="openedPanelType"
    [hiddenAutorisationType]="hiddenAutorisationType"
  ></rs-authorisation-wrapper>
</rs-full-height-dialog>
