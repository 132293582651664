import { Pipe, PipeTransform } from '@angular/core';

import {
  filterAllergensArray,
  filterSpicyLevels,
  getAllergenLabel,
  getAllergenLabelWithDifferentModifier,
  getDigitalStoreFrontAllergenIconRegistratedName,
  isAllergenFree,
  isAllergenIncludes,
  isAllergenTrace,
  isSpicyLevel,
} from '../_utils/allergens';
import { difference } from 'lodash';
import { Allergen } from '../_interfaces/item.model';
import { DIETARY_RESTRICTIONS_ICONS } from '../_constants/allergen-icons.constant';
import { AllergenType } from '../_enums/allergens.enum';
import { DEFAULT_LANGUAGE } from '../_constants/common';

const getAllergenIcon = (allergen: string): string =>
  allergen && DIETARY_RESTRICTIONS_ICONS.includes(allergen)
    ? getDigitalStoreFrontAllergenIconRegistratedName(allergen)
    : '';

@Pipe({ name: 'rsDietaryRestrictionIcon', standalone: true })
export class DietaryRestrictionIconPipe implements PipeTransform {
  public transform(allergen: string): string {
    return getAllergenIcon(allergen);
  }
}

@Pipe({ name: 'rsIsAllergenFree', standalone: true })
export class IsAllergenFreePipe implements PipeTransform {
  public transform(allergen: string): boolean {
    return !!allergen && isAllergenFree(allergen);
  }
}

@Pipe({ name: 'rsSpicyLevelIcon', standalone: true })
export class SpicyLevelIconPipe implements PipeTransform {
  public transform(level: string): string {
    return getDigitalStoreFrontAllergenIconRegistratedName(level);
  }
}

@Pipe({ name: 'rsGetAllergensTrace', standalone: true })
export class GetAllergensTracePipe implements PipeTransform {
  public transform(allergens: Allergen[]): Allergen[] {
    return filterAllergensArray(allergens, false);
  }
}

@Pipe({ name: 'rsGetAllergensContains', standalone: true })
export class GetAllergensContainsPipe implements PipeTransform {
  public transform(allergens: Allergen[]): Allergen[] {
    return filterAllergensArray(allergens, false, true);
  }
}

@Pipe({ name: 'rsGetAllergensFree', standalone: true })
export class GetAllergensFreePipe implements PipeTransform {
  public transform(allergens: Allergen[]): Allergen[] {
    return filterAllergensArray(allergens, true);
  }
}

@Pipe({ name: 'rsGetSpicyLevels', standalone: true })
export class GetSpicyLevelsPipe implements PipeTransform {
  public transform(allergens: Allergen[]): Allergen[] {
    return filterSpicyLevels(allergens);
  }
}

@Pipe({ name: 'rsGetNotSelectedAllergens', standalone: true })
export class GetNotSelectedAllergensPipe implements PipeTransform {
  public transform(allergens: Allergen[], selected: Allergen[]): Allergen[] {
    return difference(allergens, selected);
  }
}

@Pipe({ name: 'rsIncludesAllergen', standalone: true })
export class IncludesAllergenPipe implements PipeTransform {
  public transform(allergens: Allergen[], allergenLabel: string): boolean {
    return !!allergenLabel && isAllergenIncludes(allergens, allergenLabel);
  }
}

@Pipe({ name: 'rsIncludesOppositeByMeaningAllergen', standalone: true })
export class IncludesOppositeByMeaningAllergenPipe implements PipeTransform {
  public transform(allergens: Allergen[], allergen: string): boolean {
    const allergenLabelOppositeByMeaning: string =
      getAllergenLabelWithDifferentModifier(allergen);

    return (
      !!allergen &&
      isAllergenIncludes(allergens, allergenLabelOppositeByMeaning)
    );
  }
}

@Pipe({ name: 'rsExistsAllergenType', standalone: true })
export class ExistsAllergenType implements PipeTransform {
  public transform(allergens: Allergen[], type: AllergenType): boolean {
    if (!allergens?.length) {
      return false;
    }

    switch (type) {
      case AllergenType.AllergenFree:
        return !!allergens.find(({ value }) => isAllergenFree(value));
      case AllergenType.AllergenTrace:
        return !!allergens.find(
          ({ value }) => isAllergenTrace(value) && !isSpicyLevel(value)
        );
      case AllergenType.SpicyLevel:
        return !!allergens.find(({ value }) => isSpicyLevel(value));
      default:
        return false;
    }
  }
}

@Pipe({ name: 'rsGetAllergenLabel', standalone: true })
export class GetAllergenLabelPipe implements PipeTransform {
  public transform(
    allergen: Allergen,
    currentLanguage: string | null = DEFAULT_LANGUAGE
  ): string {
    return getAllergenLabel(allergen, currentLanguage);
  }
}

@Pipe({ name: 'rsGetAllergenWithoutIcons', standalone: true })
export class GetAllergenWithoutIcons implements PipeTransform {
  public transform(allergens: Allergen[]): Allergen[] {
    return allergens.filter(({ value }) => !getAllergenIcon(value));
  }
}

@Pipe({ name: 'rsGetAllergenWithIcons', standalone: true })
export class GetAllergenWithIcons implements PipeTransform {
  public transform(allergens: Allergen[]): Allergen[] {
    return allergens.filter(({ value }) => !!getAllergenIcon(value));
  }
}
