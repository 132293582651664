import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { combineLatest, filter, first, map, Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { SharedModule } from '../../../shared.module';
import { RsDialogComponent } from 'src/app/_shared/_rs-design/dialog/dialog.component';
import { AddressFormComponent } from 'src/app/_shared/_components/address-form/address-form.component';

import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';
import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { DeliveryMethod } from 'src/app/_shared/_enums/order.enum';
import { Phone } from 'src/app/_shared/_models/common.interface';
import {
  getAddressesListWithDefaultFirst,
  getUSAddresses,
} from 'src/app/_shared/_utils/address.helper';
import { getObjectValueByKey } from 'src/app/_shared/_utils/common';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import {
  DEFAULT_COUNTRY,
  PHONE_DEFAULT_TYPE,
} from 'src/app/_shared/_rs-design/phone-input/phone-input.constants';

@Component({
  selector: 'rs-select-address',
  standalone: true,
  imports: [SharedModule, RsDialogComponent, AddressFormComponent],
  templateUrl: 'select-address.component.html',
  styleUrls: ['select-address.component.scss'],
})
export class SelectAddressComponent {
  @Input() selectedAddress: UserProfileAddress | undefined | null;
  @Input() isDialog: boolean = true;
  @Input() selectedPhone: Phone | undefined | null;

  public readonly orderAddress$: Observable<UserProfileAddress | undefined> =
    this.store.select(OrderDataState.orderAddress);
  public readonly orderMethod$: Observable<DeliveryMethod> = this.store.select(
    OrderDataState.orderMethod
  );
  public readonly orderPhone$: Observable<Phone | undefined> =
    this.store.select(OrderDataState.orderPhone);
  public readonly addresses$: Observable<UserProfileAddress[]> = this.store
    .select(ProfileState.addresses)
    .pipe(
      map(addresses =>
        getUSAddresses(getAddressesListWithDefaultFirst(addresses))
      )
    );
  public readonly isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );

  public addressForm!: FormGroup;
  public phoneFormGroup!: FormGroup;

  private addresses: UserProfileAddress[] = [];

  protected readonly destroy$ = untilDestroyed();

  @Output() selectedAddressChange: EventEmitter<UserProfileAddress> =
    new EventEmitter<UserProfileAddress>();
  @Output() selectedPhoneChange: EventEmitter<Phone | undefined> =
    new EventEmitter<Phone | undefined>();

  constructor(
    public readonly store: Store,
    private readonly formBuilder: FormBuilder,
    @Optional()
    protected readonly dialogRef: MatDialogRef<SelectAddressComponent>
  ) {}

  ngOnInit(): void {
    this.addresses$
      .pipe(first())
      .subscribe(
        (addresses: UserProfileAddress[]) => (this.addresses = addresses)
      );

    combineLatest([this.orderAddress$, this.orderMethod$])
      .pipe(
        filter(([_, orderMethod]) => orderMethod === DeliveryMethod.Delivery),
        this.destroy$(),
        map(([address, _]) => address)
      )
      .subscribe((address: UserProfileAddress | undefined) => {
        const selectedAddress: UserProfileAddress = address
          ? address
          : this.addresses.find(address => address.isDefault) ||
            this.addresses[0];

        !!selectedAddress && this.getSelectedAddress(selectedAddress);
      });

    this.initializeFrom();
  }

  public getSelectedAddress(address: UserProfileAddress): void {
    this.selectedAddress = address;
    this.initializeFrom();
    this.selectedAddressChange.emit(address);
  }

  private initializeFrom(): void {
    this.addressForm = this.formBuilder.group({
      country: 'US',
      address1: [this.selectedAddress?.address1 || '', Validators.required],
      address2: [this.selectedAddress?.address2 || ''],
      city: [this.selectedAddress?.city || '', Validators.required],
      state: [this.selectedAddress?.state || '', Validators.required],
      zipcode: [this.selectedAddress?.zipcode || '', Validators.required],
      instructions: [
        getObjectValueByKey(this.selectedAddress, 'instructions') || '',
      ],
      latitude: [getObjectValueByKey(this.selectedAddress, 'latitude') || 0],
      longitude: [getObjectValueByKey(this.selectedAddress, 'longitude') || 0],
    });

    this.phoneFormGroup = this.formBuilder.group({
      phoneNumber: [this.selectedPhone?.phoneNumber || '', Validators.required],
      countryCode: [this.selectedPhone?.countryCode || DEFAULT_COUNTRY],
      type: [
        this.selectedPhone?.type || PHONE_DEFAULT_TYPE,
        [Validators.required],
      ],
    });

    this.addressForm.valueChanges.pipe(this.destroy$()).subscribe(value => {
      this.selectedAddress = value;
      this.selectedAddressChange.emit(value);
    });

    this.phoneFormGroup.statusChanges.subscribe(
      value => value === 'INVALID' && this.selectedPhoneChange.emit(undefined)
    );

    this.phoneFormGroup.valueChanges.pipe(this.destroy$()).subscribe(value => {
      this.selectedPhone = value;
      this.selectedPhoneChange.emit(value);
    });
  }

  public close(): void {
    this.dialogRef.close(this.selectedAddress);
  }

  public clearAutoCompelete(): void {
    this.selectedAddress = undefined;
  }
}
