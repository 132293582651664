import {
  LOGGED_IN_USER_ROUTES,
  MENU_ROUTES,
  VENUE_ROUTES,
} from '../_constants/routes';
import {
  BaseRoutes,
  ExistingRoutes,
  MenuRoute,
} from '../_interfaces/venue-route.model';

export const getSearchRoute = (
  routes: BaseRoutes[],
  name: ExistingRoutes
): BaseRoutes | undefined =>
  routes.find((route: BaseRoutes) => route.name === name);

export const getRoutePath = (
  name: ExistingRoutes,
  routes: BaseRoutes[]
): string => getSearchRoute(routes, name)?.path ?? '';

export const getRouteTitle = (
  name: ExistingRoutes,
  routes: BaseRoutes[] = VENUE_ROUTES
): string => getSearchRoute(routes, name)?.title ?? '';

export const getMenuRouteTitle = (name: ExistingRoutes): string =>
  getRouteTitle(name, MENU_ROUTES);

export const getLoggedInUserTitle = (name: ExistingRoutes): string =>
  getRouteTitle(name, LOGGED_IN_USER_ROUTES);

export const getDineInTitle = (name: ExistingRoutes): string =>
  getRouteTitle(name);

export const getPageVisibilityFromConfig = (
  config: any,
  visibilityKey: string = ''
): boolean => {
  if (!visibilityKey) {
    return false;
  }

  if (visibilityKey === 'showGiftCards') {
    const currentPage = config?.pages?.find(
      (page: any) => page.name === 'home'
    );
    return currentPage?.pageData?.showGiftCards || false;
  }

  const currentPage = config?.pages?.find(
    (page: any) => page.name === visibilityKey
  );

  return currentPage?.display || false;
};

export const getAvailableRoutes = (venueConfig: any): MenuRoute[] => {
  const availableRoutes: MenuRoute[] = [];

  for (let route of MENU_ROUTES) {
    getPageVisibilityFromConfig(venueConfig, route.visibilityKey) &&
      availableRoutes.push(route);
  }

  return availableRoutes;
};
