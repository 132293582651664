export enum DialogTypeOptions {
  Default = 'default',
  Success = 'success',
  Error = 'error',
}

export type DialogType =
  | DialogTypeOptions.Default
  | DialogTypeOptions.Success
  | DialogTypeOptions.Error;

export type StatusDialogType =
  | DialogTypeOptions.Success
  | DialogTypeOptions.Error;
