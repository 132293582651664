<div
  class="rs-dialog-header rs-dialog-header--default"
  [ngClass]="{
    'rs-dialog-header--only-text-and-close-button': isCloseButton && isOnlyText,
    'rs-dialog-header--with-bottom-border': dialogHeaderHasBorder
  }"
>
  <div
    *ngIf="isCloseButton"
    class="rs-dialog-header__close-icon-wrapper d-flex justify-content-end align-items-center"
  >
    <mat-icon [svgIcon]="clearIcon" mat-dialog-close class="d-flex"></mat-icon>
  </div>
  <div
    class="rs-dialog-header__title d-flex"
    #rsDialogHeader
    [ngClass]="{
      'd-none': !rsDialogHeader?.children?.length,
      'rs-dialog-header__title--after-close-icon': isCloseButton,
      'rs-dialog-header__title--has-no-icon': isOnlyText,
      'justify-content-start': !isOnlyIcon,
      'justify-content-center': isOnlyIcon
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
