import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Params, Router } from '@angular/router';
import { ContentObserver, ObserversModule } from '@angular/cdk/observers';

import { Store } from '@ngxs/store';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  Observable,
  of,
  skip,
} from 'rxjs';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { TooltipComponent } from 'src/app/_shared/_rs-design/tooltip/tooltip/tooltip.component';
import { FoodItemAllergensComponent } from '../allergens-dietary-restrictions/item-allergens/item-allergens/food-item-allergens.component';
import { CountryFlagComponent } from '../country/country-flag.component';
import { OrderMethodIconComponent } from '../order-method-icon/order-method-icon.component';
import { WineReviewsSummaryPipe } from 'src/app/_shared/_components/wine-reviews/wine-reviews-summary.pipe';
import {
  GetCountryCodeByCode3Pipe,
  GetCountryCodeByNamePipe,
  GetCountryNameByCode3Pipe,
  GetCountryNameByCodePipe,
} from '../country/get-country-code.pipe';

import { LanguageState } from 'src/app/_shared/_ngxs/language.state';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';
import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';
import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { SetItemData } from 'src/app/_shared/_ngxs/item.actions';
import {
  AddToFavorites,
  RemoveFromFavorites,
} from 'src/app/_shared/_ngxs/profile.actions';
import { ItemState } from '../../_ngxs/item.state';
import { VenueOrderSettingsState } from '../../_ngxs/venue-order-settings.state';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { DeliveryType } from '../../_enums/order.enum';

import { Allergen, Item } from 'src/app/_shared/_interfaces/item.model';
import { Menu } from 'src/app/_shared/_interfaces/menu.model';
import { QRSettingsType } from 'src/app/_shared/_interfaces/qrSettings.model';

import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { getCurrentSettingsTypeFromQueryParams } from 'src/app/_shared/_utils/common';
import {
  getImagePathToNoRes,
  getImageToCDN,
} from 'src/app/_shared/_utils/image';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { formItemDetailsUrlAndParams } from '../../_utils/items.helper';
import { FilteredMenuState } from '../../_ngxs/filtered-menu.state';
import { CartState } from '../../_ngxs/cart.state';

@Component({
  selector: 'rs-food-item',
  standalone: true,
  templateUrl: 'rs-food-item.component.html',
  imports: [
    ObserversModule,
    SharedModule,
    TooltipComponent,
    CountryFlagComponent,
    GetCountryCodeByNamePipe,
    GetCountryCodeByCode3Pipe,
    GetCountryNameByCode3Pipe,
    GetCountryNameByCodePipe,
    FoodItemAllergensComponent,
    WineReviewsSummaryPipe,
    OrderMethodIconComponent,
  ],
  styleUrls: ['./rs-food-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsFoodItemComponent {
  @ViewChild('rsUnavailableMessage', { read: ElementRef, static: false })
  public unavailableMessage!: ElementRef<HTMLElement>;
  @ViewChild('rsImage', { read: ElementRef, static: false })
  public image!: ElementRef<HTMLElement>;

  @Input() set item(item: Item) {
    this._item = item;

    item?.image &&
      (this.itemImage = getImagePathToNoRes(getImageToCDN(item.image)));

    item &&
      (this.itemName =
        item.alcoholicAttributes?.string === 'wine'
          ? item.alcoholicAttributes?.style +
            ', ' +
            item.alcoholicAttributes?.vintage
          : item.customerFacingName || item.name);
    this.itemNumberInCart$ = this.store.select(
      CartState.itemNumberInCart(item.itemId)
    );
  }
  @Input() menuId: string = '';
  @Input() sectionId: string = '';
  @Input() disableOrdering!: boolean;
  @Input() disableRedirection: boolean = false;
  @Input() unavailable!: boolean;
  @Input() unavailableForOrderType!: boolean;
  @Input() unavailableForSelectedTime!: boolean;
  @Input() orderType!: DeliveryType;
  @Input() showAllergens: boolean = false;
  @Input() showFullAlcoholDetails: boolean = false;
  @Input() showItemNumberInCart: boolean = true;

  public itemNumberInCart$!: Observable<number>;
  public readonly language$ = this.store.select(LanguageState.language);

  public itemAllergens$!: Observable<Allergen[] | undefined | null>;
  public _item!: Item;
  public itemName!: string;
  public itemImage!: string;
  public isFavoriteItem!: boolean;
  public showFavoriteButton!: boolean;
  public titleMargin!: number;
  public unavailableMessageRight!: number;
  public menuRoute!: string;
  // TODO change it when calories and rating are available
  public showRating!: boolean;
  public showCalories!: boolean;
  // TODO end

  private readonly destroyed$ = untilDestroyed();

  public readonly addIcon = CommonIcons.Add;
  public readonly heartIcon = CommonIcons.Heart;
  public readonly heartOutlineIcon = CommonIcons.HeartOutline;
  public readonly starIcon = CommonIcons.Star;
  public readonly clockIcon = CommonIcons.Clock;

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store,
    private readonly router: Router,
    private readonly svgIconService: SvgIconService,
    private readonly contentObserver: ContentObserver
  ) {
    this.registerIcons();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes['item']?.firstChange) {
      return;
    }

    const itemId = changes['item'].currentValue?.itemId;
    const menuId = changes['menuId'].currentValue;

    itemId && this.detectIfFavoriteItem(itemId, menuId);
  }

  public ngOnInit(): void {
    this.itemAllergens$ = this.showAllergens
      ? this.store.select(ItemState.allergens)
      : of(null);
  }

  public ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.calculatePosition();
    this.subscribeOnResizeEvent();
  }

  private getMenuId(): string {
    return this.getMenuItem()?.menuId ?? '';
  }

  private getMenuItem(): any {
    const menus: Menu[] = this.store.selectSnapshot(FilteredMenuState.menus);
    const currentMenu: Menu | undefined = menus.find(
      ({ menuId, sections }: Menu) =>
        !!sections.find(({ sectionId, items }) =>
          items.find(({ itemId }: Item) => {
            if (itemId === this._item?.itemId && !!menuId) {
              this.menuId = menuId;
              this.sectionId = sectionId;
            }

            return itemId === this._item?.itemId;
          })
        )
    );

    return currentMenu;
  }

  private getItemDetailsUrlAndParams(): {
    url: string;
    queryParams: Params;
  } {
    const isThereAnyAvailableOrderMethod: boolean = this.store.selectSnapshot(
      VenueOrderSettingsState.isThereAnyAvailableOrderMethod
    );
    const currentMenuType: QRSettingsType = isThereAnyAvailableOrderMethod
      ? getCurrentSettingsTypeFromQueryParams(
          this.document?.location?.href || ''
        ) || 'menu'
      : 'menu';
    const formattedVenueName: string = this.store.selectSnapshot(
      VenueState.formattedVenueName
    );

    return formItemDetailsUrlAndParams(
      currentMenuType,
      this._item.itemId,
      this.menuId,
      this.sectionId,
      formattedVenueName
    );
  }

  public goToItem(): void {
    if (this.disableRedirection) {
      return;
    }

    !this.menuId && (this.menuId = this.getMenuId());

    if (!this._item || !this.menuId) {
      throw new Error('There is no menu id');
    }

    this.store.dispatch(
      new SetItemData({
        item: this._item,
        menuId: this.menuId,
        menuSectionId: this.sectionId,
        metadata: this._item.metadata,
        isCurrentMenuAvailable: !this.unavailable,
        disableOrdering: this.disableOrdering,
      })
    );

    const { url, queryParams } = this.getItemDetailsUrlAndParams();

    this.router.navigate([url], {
      queryParams,
    });
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [
        this.addIcon,
        this.clockIcon,
        this.heartIcon,
        this.starIcon,
        this.heartOutlineIcon,
      ],
      NEW_ICONS_DIRECTORY
    );
  }

  public setFavorite(event: Event): void {
    event.stopPropagation();

    this._item?.itemId &&
      this.store.dispatch(
        this.store.selectSnapshot(
          ProfileState.isFavoriteItem(
            this._item.itemId,
            this.menuId,
            this.sectionId
          )
        )
          ? new RemoveFromFavorites(
              this._item.itemId,
              this.menuId,
              this.sectionId
            )
          : new AddToFavorites(this._item.itemId, this.menuId, this.sectionId)
      );
  }

  private detectIfFavoriteItem(itemId: string, menuId: string): void {
    combineLatest(
      this.store.select(
        ProfileState.isFavoriteItem(
          itemId,
          menuId || this.menuId,
          this.sectionId
        )
      ),
      this.store.select(SessionState.isLoggedIn)
    )
      .pipe(this.destroyed$())
      .subscribe(([isFavorite, isLoggedIn]) => {
        this.showFavoriteButton = isLoggedIn;
        this.isFavoriteItem = isLoggedIn && isFavorite;

        this.changeDetectorRef.detectChanges();
      });
  }

  private subscribeOnResizeEvent(): void {
    combineLatest([this.store.select(SessionState.isLoggedIn)])
      .pipe(skip(1), this.destroyed$(), distinctUntilChanged())
      .subscribe(_ => this.calculatePosition());

    fromEvent(this.document.defaultView, 'resize')
      .pipe(this.destroyed$(), debounceTime(100))
      .subscribe(_ => this.calculatePosition());

    // this observer is used bc after language changes
    // it takes some time to propagate changes
    // and this onbserver detects changes correctly
    this.unavailableMessage &&
      this.contentObserver
        .observe(this.unavailableMessage)
        .pipe(
          this.destroyed$(),
          filter(
            (mutationsList: MutationRecord[]) =>
              !!mutationsList.find(
                mutation => mutation.type === 'characterData'
              )
          ),
          debounceTime(100)
        )
        .subscribe(_ => this.calculatePosition());
  }

  private calculatePosition(): void {
    const isDesktopCard: boolean = window.innerWidth >= 480;
    const imageWidth: number = this.image?.nativeElement?.offsetWidth || 0;
    const messageWidth: number =
      this.unavailableMessage?.nativeElement?.offsetWidth || 0;
    const favoriteButtonDistance: number = this.showFavoriteButton ? 48 : 0;
    const defaultPaddingFromCorner: number = isDesktopCard ? 12 : 14;
    const showedImage: boolean = !!imageWidth && !!this.itemImage;

    if (isDesktopCard) {
      // title margin from corner
      // if image no margin
      // if no image
      // message width + default padding + like button ? (like button width + 16) : 0
      this.titleMargin = showedImage
        ? 0
        : defaultPaddingFromCorner + favoriteButtonDistance + messageWidth;

      // unavailable message position from corner
      // if image
      // image width - (message width + default padding)
      // if no image
      // default padding + like button ? (like button width + 16) : 0
      this.unavailableMessageRight =
        showedImage && messageWidth
          ? imageWidth - (defaultPaddingFromCorner + messageWidth)
          : favoriteButtonDistance + defaultPaddingFromCorner;
    } else {
      // title margin from corner
      // for mobile
      // if image = no margin
      // if no image = message width + like button ? (like button width + 16) : 0
      this.titleMargin = showedImage
        ? 0
        : favoriteButtonDistance + messageWidth;

      // unavailable message position from corner
      // for mobile
      // if image = -1 * default padding from corner
      // if no image = (like button width + 16)
      this.unavailableMessageRight =
        showedImage && messageWidth
          ? -defaultPaddingFromCorner
          : favoriteButtonDistance;
    }

    this.changeDetectorRef.detectChanges();
  }
}
