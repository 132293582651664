<section class="rs-payment-address-selector">
  <div class="rs-payment-address-selector__items">
    <div
      *ngFor="let address of addresses; let index = index"
      (click)="selectAddressEvent(address, index)"
      class="rs-payment-address-selector__item"
      [ngClass]="{
        'rs-payment-address-selector__item--selected': index === selectedIndex
      }"
    >
      <span class="rs-payment-address-selector__title">{{ address.name }}</span>
      <span
        class="rs-payment-address-selector__title rs-payment-address-selector__title--small"
      >
        {{ address | rsFullAddress }}
      </span>
    </div>
  </div>
</section>
