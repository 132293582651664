const PLACEHOLDERS = [
  '937055521494',
  '35544541814',
  '213982402367',
  '16845555345',
  '3763555735575',
  '244226434190',
  '12645556440',
  '6721555385086',
  '12685559687',
  '543515685724',
  '374555551',
  '2975555243823',
  '6145557841',
  '436605551905',
  '9944055586864',
  '12425555517',
  '97335555574',
  '88009610991071',
  '12466220207',
  '375335554159',
  '32456555258',
  '501655563',
  '22961012483',
  '14415559593',
  '9751755564678',
  '5916555607546',
  '38733902917',
  '2677555601944',
  '5531234212611',
  '2463875556',
  '12845559759',
  '6738555923461',
  '35924373561',
  '22679555284',
  '2577555503691',
  '855775554220',
  '2379555238064',
  '12262715017',
  '2389555135866',
  '13457691931',
  '2365551917428',
  '23563555547',
  '56442050711',
  '8613555685942',
  '618555347477',
  '618555381632',
  '5723872233',
  '2693555709159',
  '68255551',
  '50685553756',
  '385925559064',
  '5355554973',
  '59997889932',
  '35723030036',
  '420516770431',
  '2432255543759',
  '4578744522',
  '253855531847',
  '17675557876',
  '18295557529',
  '67078555400',
  '593955569058',
  '201155526794',
  '50321133577',
  '2407555223182',
  '2917555496751',
  '3726683067',
  '2519555649407',
  '50055552',
  '298225550',
  '6793555536430',
  '358753252438',
  '33970019061',
  '689895559',
  '2413555733',
  '2207555601332',
  '995570555827',
  '491575557875',
  '233242426213',
  '3505855578463',
  '302111980652',
  '299555885',
  '14732300183',
  '16715559804',
  '50224581101',
  '445554937494',
  '2246555706667',
  '2456555142403',
  '5926555567501',
  '5094555135936',
  '50422358280',
  '85291255559',
  '36212111484',
  '3544971209',
  '917555753601',
  '622150995475',
  '989035553797',
  '9647855551927',
  '353855523717',
  '445556552789',
  '972772200237',
  '39373555769',
  '2256555140239',
  '18766139182',
  '818055519389',
  '445553858177',
  '9628055590757',
  '77775557326',
  '254203892208',
  '68665551',
  '38338403009',
  '9655555302963',
  '9965555603595',
  '8562055538126',
  '37163288836',
  '96171555941',
  '2665855558260',
  '23155557082',
  '2187555170886',
  '4237555443949',
  '37052078259',
  '352691555339',
  '85368258517',
  '38925517468',
  '2613555878272',
  '2658555133918',
  '601155550',
  '9602225261',
  '22365557028',
  '3567955591799',
  '6925553656',
  '2226555767681',
  '23058690032',
  '2625554274829',
  '527442501015',
  '6919255536',
  '37379555852',
  '3774555358968',
  '97698555458',
  '38260555516',
  '16645551547',
  '2126555761273',
  '2588555185746',
  '9597855551',
  '264833800101',
  '674555555126',
  '9779855521270',
  '31202254487',
  '5995551385478',
  '687855526',
  '6498860025',
  '50522442340',
  '22795557703',
  '23412279154',
  '68325550',
  '8505553807442',
  '16705558348',
  '4745551778',
  '96892555439',
  '923055587937',
  '6805553612',
  '970565557081',
  '5078337032',
  '6756855566892',
  '595217289034',
  '5117050854',
  '63822269451',
  '645551702293',
  '48523047977',
  '351304500582',
  '17875207514',
  '97455555458',
  '2424555255877',
  '262700555890',
  '40377880188',
  '79255598651',
  '2508555991934',
  '5906555401319',
  '2905551',
  '18695551762',
  '17585552322',
  '5906555810402',
  '5085554503597',
  '1784555575',
  '6857755596503',
  '3786655595701',
  '2399055536918',
  '966505554853',
  '2217755565837',
  '381665559483',
  '2487555539265',
  '2323355582223',
  '6585552426',
  '17215554231',
  '421233322956',
  '38682880182',
  '6777455539',
  '25255568621',
  '27555678837',
  '821055564910',
  '2119555261770',
  '34518899226',
  '947855534',
  '249156559836',
  '5978555575128',
  '477955512858',
  '26879555653',
  '46107502378',
  '41755558916',
  '963935552214',
  '886277344400',
  '9929055517921',
  '2557455565743',
  '6621055704',
  '22816955538',
  '690555807428',
  '6761755525385',
  '18682241633',
  '21631365962',
  '9050555341128',
  '9936655578467',
  '16495557776',
  '6885557367204',
  '13405551474',
  '2567555736975',
  '380947106959',
  '971525551394',
  '441254929801',
  '16595995742',
  '5989655524850',
  '9989055544625',
  '6784555601994',
  '396555426181',
  '582123357412',
  '84355591180',
  '6815556397349',
  '2125289555958',
  '967735554574',
  '2609555859896',
  '2637355534419',
  '3584575553592',
  '590590698042',
  '596596108040',
];

export { PLACEHOLDERS };
