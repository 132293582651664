import { NgModule } from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';
import localeAr from '@angular/common/locales/ar';
import localeZh from '@angular/common/locales/zh';

import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';

import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NotifierModule } from 'angular-notifier';
import { NgxMaskModule } from 'ngx-mask';

import { environment } from '../environments/environment';

import { TemplatePageTitleStrategy } from './_shared/_services/title.services';
import { SessionStorageEngine } from './_services/session-storage.service';

import { MessagePopupComponent } from './_shared/_components/message-popup/message-popup.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './_shared/_modules/shared.module';
import { SpinnerModule } from './_shared/_components/spinner/spinner.module';
import { SplashScreenModule } from './splash-screen/splash-screen.module';
import { CompleteAddressPopupComponent } from './_shared/_components/complete-address-popup/complete-address-popup.component';
import { InitialAddressPopupComponent } from './_shared/_components/initial-address-popup/initial-address-popup.component';
import { SelectAddressComponent } from './_shared/_modules/full-order-information/checkout-order-information-popup/select-address/select-address.component';

import { AppComponent } from './app.component';

import { SessionState } from './_shared/_ngxs/authentication.state';
import { VenueState } from './_shared/_ngxs/venue.state';
import { ItemState } from './_shared/_ngxs/item.state';
import { DineInState } from './venue/dine-in/_ngxs/dine-in.state';
import { ProfileState } from './_shared/_ngxs/profile.state';
import { CartState } from './_shared/_ngxs/cart.state';
import { OrderState } from './_shared/_ngxs/order.state';
import { MerchantState } from './_shared/_ngxs/merchant.state';
import { DialogState } from './_shared/_ngxs/dialog.state';
import { DeliveryState } from './_shared/_ngxs/delivery.state';
import { MapState } from './_shared/_ngxs/mapbox.state';
import { BundleItemState } from './_shared/_ngxs/bundle-item.state';
import { LanguageState } from './_shared/_ngxs/language.state';

import { LoggedInUserGuard } from './_shared/_guards/logged-in-user.guard';
import { translateBrowserLoaderFactory } from './_shared/_loaders/translate-browser.loader';
import { AccessTokenInterceptor } from './_shared/_interceptors/access-token.interceptor';
import { ErrorHandlerInterceptor } from './_shared/_interceptors/error-handler.interceptor';
import { DigitalStorefrontState } from './_shared/_ngxs/digital-storefront.state';
import { AllergensState } from './_shared/_ngxs/allergens.state';
import { FilteredMenuState } from './_shared/_ngxs/filtered-menu.state';
import { VenueOrderSettingsState } from './_shared/_ngxs/venue-order-settings.state';
import { OrderDataState } from './_shared/_ngxs/order-data.state';
import { VersionComponent } from './_shared/_components/version/version.component';

// translations settings
registerLocaleData(localeRu, 'ru');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeZh, 'zh');
registerLocaleData(localeAr, 'ar');

@NgModule({
  declarations: [AppComponent],
  imports: [
    SplashScreenModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
        },
      },
      behaviour: {
        autoHide: 5000,
      },
    }),
    SpinnerModule,
    SharedModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    NgxMaskModule.forRoot(),
    NgxsModule.forRoot(
      [
        VenueState,
        ItemState,
        DigitalStorefrontState,
        DineInState,
        SessionState,
        ProfileState,
        CartState,
        OrderState,
        MerchantState,
        DialogState,
        MapState,
        DeliveryState,
        BundleItemState,
        LanguageState,
        AllergensState,
        FilteredMenuState,
        VenueOrderSettingsState,
        OrderDataState,
      ],
      { developmentMode: !environment.production }
    ),
    NgxsStoragePluginModule.forRoot({
      key: [
        VenueState,
        ItemState,
        BundleItemState,
        DigitalStorefrontState,
        DineInState,
        SessionState,
        ProfileState,
        CartState,
        OrderState,
        AllergensState,
        VenueOrderSettingsState,
        OrderDataState,
      ],
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    MessagePopupComponent,
    CompleteAddressPopupComponent,
    InitialAddressPopupComponent,
    SelectAddressComponent,
    VersionComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: SessionStorageEngine,
    },
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
    LoggedInUserGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
