<rs-dialog rs-dialog-style="default" rs-show-close-button="true">
  <ng-container rs-dialog-header>
    <span>{{ title }}</span>
  </ng-container>
  <div>
    <p>
      {{ 'ADDRESS_BOOK.select_another' | translate }}
    </p>
    <div class="rs-delete-default-address">
      <rs-address-item
        *ngFor="let address of notDefaultAddresses"
        [address]="address"
        [activeCard]="address.id === selectedAddressId"
        (addressSelected)="selectAddress($event)"
      ></rs-address-item>
    </div>
  </div>
  <ng-container rs-dialog-actions-direction="row">
    <button
      [disabled]="!selectedAddressId"
      rs-style="text"
      [mat-dialog-close]="selectedAddressId"
    >
      <span>
        {{ 'save' | translate | uppercase }}
      </span>
    </button>
  </ng-container>
</rs-dialog>
