import {
  DEFAULT_PAYMENT_VIEW_DESCRIPTION,
  PAYMENT_VIEW_DESCRIPTION,
} from '../_constants/payment-methods.constant';
import { PaymentMethods } from '../_enums/payment-methods.enum';
import { PaymentViewDescription } from '../_interfaces/payment.model';

const getFilteredPaymentViewDescriptions = (
  paymentViewDescription: PaymentViewDescription[],
  availablePaymentTypes: PaymentMethods[]
): PaymentViewDescription[] => {
  if (!availablePaymentTypes?.length) {
    return [];
  }

  return [...paymentViewDescription].filter(
    (paymentViewDescription: PaymentViewDescription) =>
      availablePaymentTypes.includes(paymentViewDescription.placeholder)
  );
};

export const getAvailablePaymentOptions = (
  availablePaymentTypes: PaymentMethods[]
): PaymentViewDescription[] =>
  getFilteredPaymentViewDescriptions(
    PAYMENT_VIEW_DESCRIPTION,
    availablePaymentTypes
  );

export const getAdditionalAvailablePaymentOptions = (
  availablePaymentTypes: PaymentMethods[]
): PaymentViewDescription[] =>
  getFilteredPaymentViewDescriptions(
    DEFAULT_PAYMENT_VIEW_DESCRIPTION,
    availablePaymentTypes
  );
