import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable, first } from 'rxjs';

import { SharedModule } from '../../shared.module';
import { ScheduleOrderComponent } from 'src/app/_shared/_components/schedule/schedule-order/schedule-order.component';
import { SelectCarComponent } from '../checkout-order-information-popup/select-car/select-car.component';
import { SelectAddressComponent } from '../checkout-order-information-popup/select-address/select-address.component';

import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';

import { ScheduleRange } from 'src/app/_shared/_interfaces/date-settings';
import { Car } from 'src/app/profile/_interfaces/car.model';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

import { DeliveryMethod } from 'src/app/_shared/_enums/order.enum';
import { Phone } from 'src/app/_shared/_models/common.interface';
import { OrderData } from 'src/app/_shared/_interfaces/delivery.model';
import { isCarValid } from 'src/app/_shared/_utils/car.utils';

@Component({
  selector: 'rs-full-order-information',
  standalone: true,
  imports: [
    SharedModule,
    ScheduleOrderComponent,
    SelectCarComponent,
    SelectAddressComponent,
  ],
  templateUrl: './full-order-information-inner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullOrderInformationInnerComponent {
  @Input() isCheckout!: boolean;

  public readonly orderAddress$: Observable<UserProfileAddress | undefined> =
    this.store.select(OrderDataState.orderAddress);
  public readonly orderMethod$: Observable<DeliveryMethod> = this.store.select(
    OrderDataState.orderMethod
  );
  public readonly orderPhone$: Observable<Phone | undefined> =
    this.store.select(OrderDataState.orderPhone);
  public readonly orderCar$: Observable<Car> = this.store.select(
    OrderDataState.orderCar
  );

  public selectedTime!: string;
  public selectedDay!: ScheduleRange;
  public orderMethod!: DeliveryMethod;
  public selectedAddress!: UserProfileAddress;
  public selectedCar!: Car;
  public selectedPhone!: Phone | undefined;

  public readonly DeliveryMethod = DeliveryMethod;

  @Output() update: EventEmitter<Partial<OrderData>> = new EventEmitter();

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.subscribeOnOrderDataUpdates();
  }

  private subscribeOnOrderDataUpdates(): void {
    this.orderMethod$
      .pipe(first())
      .subscribe(orderMethod => (this.orderMethod = orderMethod));
  }
  //   actions.push(
  //     new SetIsSearchingDriver(false, false),
  //     new CalculateCheckAndCreateDeliveryQuote(this.data, false, true)
  //   );

  public updateData(field: string, data: any): void {
    switch (field) {
      case 'car':
        this.selectedCar = data as Car;
        break;
      case 'address':
        this.selectedAddress = data as UserProfileAddress;
        break;
      case 'phone':
        this.selectedPhone = data as Phone | undefined;
        break;
      case 'method':
        this.orderMethod = data as DeliveryMethod;
        break;
      case 'time':
        this.selectedTime = data as string;
        break;
      case 'day':
        this.selectedDay = data as ScheduleRange;
        break;
      default:
        break;
    }

    this.collectUpdateData();
  }

  public collectUpdateData(): void {
    const partialOrderData: Partial<OrderData> = {};

    if (!!this.orderMethod) {
      partialOrderData.orderType = this.orderMethod;
      partialOrderData.orderMethod = this.orderMethod;
    }

    !!this.selectedPhone?.phoneNumber &&
      (partialOrderData.phone = this.selectedPhone);
    !!this.selectedTime &&
      (partialOrderData.selectedOrderPeriod = {
        day: this.selectedDay,
        time: this.selectedTime,
      });
    isCarValid(this.selectedCar) && (partialOrderData.car = this.selectedCar);
    !!this.selectedAddress && (partialOrderData.address = this.selectedAddress);

    this.update.emit(partialOrderData);
  }
}
