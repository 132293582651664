<section>
  <mat-form-field
    [formGroup]="form"
    appearance="outline"
    [class.ng-invalid]="isInvalid"
    [class.rs-maximum-height]="label"
    [id]="matFormFieldId"
    class="{{ matFormFieldClass }} rs-auto-complete-input__form w-100"
  >
    <span *ngIf="label" class="rs-auto-complete-input__label">
      {{ label }}
    </span>
    <button
      class="rs-auto-complete-input"
      [class.rs-auto-complete-input-rtl]="false"
      [class.ltr]="true"
      tabindex="-1"
      [isActive]="isActive"
      (rsClickOutside)="onOutsideClick()"
      [class.rs-border-radius-auto-complete]="autoComplete?.panelOpen"
      [ngClass]="[
        disabled ? 'rs-auto-complete-input-disabled' : '',
        isInvalid && !disabled ? 'rs-auto-complete-input-invalid' : '',
        isValid && !disabled ? 'rs-auto-complete-input-valid' : ''
      ]"
    >
      <div class="rs-auto-complete-input__suffix" *ngIf="!showClearButton">
        <button
          *ngIf="!control.value && options?.length"
          mat-button
          mat-icon-button
          matSuffix
          aria-label="togglePanel"
          (click)="toggleAutocompletePanel(); $event.stopPropagation()"
        >
          <mat-icon
            class="rs-auto-complete-input__chevron"
            [class.rs-auto-complete-input__chevron--flip]="
              autoComplete?.panelOpen
            "
            [svgIcon]="angleDown"
          ></mat-icon>
        </button>
      </div>

      <div class="rs-auto-complete-input__prefix" *ngIf="iconType">
        <mat-icon
          class="rs-auto-complete-input__icon"
          [svgIcon]="iconType"
        ></mat-icon>
      </div>

      <input
        #autoCompleteInput
        class="rs-auto-complete-input__text-field m-0"
        [class.rs-auto-complete-input__text-field-padding]="applyPadding"
        matInput
        rsDebounceInput
        [matAutocomplete]="auto"
        [required]="required"
        [formControl]="formControl"
        [placeholder]="placeholder"
        (focus)="reactOnFocus()"
        (keydown.enter)="reactOnKeyDownEnter($event)"
        (keydown.tab)="reactOnKeyDownTab()"
        (debouncedInput)="onDebouncedInput.emit($event)"
      />

      <span class="rs-auto-complete-input__suffix" *ngIf="showClearButton">
        <rs-input-action-icon
          [isClearIcon]="true"
          tabindex="-1"
          (click)="clearValue()"
        ></rs-input-action-icon>
      </span>
    </button>

    <span
      *ngIf="hint && !isInvalid && !isValid"
      class="rs-auto-complete-input__hint"
    >
      {{ hint }}
    </span>

    <ng-container *ngIf="isValid || isInvalid">
      <ng-container *ngIf="!injectedWarning && !injectedSuccess">
        <span
          *ngIf="hasSuccessStatus && successMessage && isValid && !disabled"
          class="rs-auto-complete-input__success"
        >
          {{ successMessage }}
        </span>

        <mat-error *ngIf="formControl?.errors?.['required'] && isInvalid">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </mat-error>

        <ng-container *ngIf="isInvalid && !formControl?.errors?.['required']">
        </ng-container>
      </ng-container>

      <span
        *ngIf="injectedSuccess && isValid"
        class="rs-auto-complete-input__success"
      >
        {{ injectedSuccess }}
      </span>

      <mat-error *ngIf="injectedWarning && isInvalid">
        {{ injectedWarning }}
      </mat-error>
    </ng-container>
  </mat-form-field>

  <mat-autocomplete
    #auto="matAutocomplete"
    rsMatAutocompletePosition
    [class]="autocompleteClass"
    [idVariablePart]="idVariablePart"
    (rsCloseAutocomplete)="autoComplete?.closePanel()"
    (optionSelected)="reactOnOptionSelected($event.option.value)"
  >
    <mat-option *ngFor="let option of options" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</section>
