import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { EASTERN_ARABIC_NUMERALS } from '../_constants/eastern-arabic-numerals.constant';

@Pipe({ name: 'rsEasternArabicNumerals', pure: false })
export class EasternArabicNumeralsPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  public transform(input: string | number | null | undefined): string {
    if (input == null) {
      return '';
    }

    const langugaeDirection =
      this.translateService.instant('languageDirection');
    const inputString = input.toString();

    if (langugaeDirection !== 'rtl') {
      return inputString;
    }

    const isPercentage = inputString.endsWith('%');
    const isPrice = inputString.startsWith('$');
    const isOrderNUmber = /#(\d+)/.test(inputString);

    switch (true) {
      case isPercentage:
        return this.transformNumbers(inputString.slice(0, -1)) + '\u066A';
      case isPrice:
        return this.transformNumbers(inputString.slice(1)) + '$';
      case isOrderNUmber:
        const matches = inputString.match(/#(\d+)/);
        return `${inputString.replace(/#(\d+)/, '')} #${this.transformNumbers(
          matches![1]
        )}`;
      default:
        return this.transformNumbers(inputString);
    }
  }

  private transformNumbers = (input: string): string =>
    input
      .replace(/[0-9.]/g, match =>
        match === '.' ? '\u060C' : EASTERN_ARABIC_NUMERALS[match]
      )
      .replace(/\bAM\b/g, 'ص')
      .replace(/\bPM\b/g, 'م')
      .replace(/\bMIN\b/g, 'دقيقة');
}
