import { PasswordCheck } from '../_enum/password-strength.enum';
import { PasswordOption } from '../_models/password-strength.models';

export const REGEX = {
  LINK: /^[a-z\d\-_+]+\.[a-z\d. \-_+]+$/i,
  EMAIL: /^[a-z\d.\-_+]+@[a-z\d\-_+]+\.[a-z\d. \-_+]+$/i,
  UPPER_CASE: /[A-Z]/,
  LOWER_CASE: /[a-z]/,
  NUMBER: /\d/,
  SPECIAL_SYMBOL: /[`!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?~]/,
  URL: /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/i,
};

export const LETTERS = /[a-z]+/gi;
export const LOWER_CASE_LETTERS = /[a-z]+/;
export const UPPER_CASE_LETTERS = /[A-Z]+/;
export const NUMBERS = /\d+/gi;
export const SPECIAL_SYMBOLS = /[`!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?~]/;

export const MIN_PASSWORD_LENGTH = 9;

export const PASSWORD_CHECKS: PasswordOption<PasswordCheck>[] = [
  {
    key: PasswordCheck.MinCharacters,
    label: 'CREATE_PASSWORD.password_checks.min_characters',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinCharacter,
    label: 'CREATE_PASSWORD.password_checks.min_character',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinLowerCaseCharacter,
    label: 'CREATE_PASSWORD.password_checks.min_lower_case_character',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinUpperCaseCharacter,
    label: 'CREATE_PASSWORD.password_checks.min_upper_case_character',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinNumber,
    label: 'CREATE_PASSWORD.password_checks.min_number',
    isChecked: false,
  },
  {
    key: PasswordCheck.MinSymbol,
    label: 'CREATE_PASSWORD.password_checks.min_symbol',
    isChecked: false,
  },
];
