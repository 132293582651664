import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom, map, Observable } from 'rxjs';
import { uniqBy } from 'lodash';

import { environment } from '../../environments/environment';

import {
  ItemDetails,
  CalculateItemsPriceRequest,
  ModifiersResponse,
  CalculatedItem,
  NutritionalAttribute,
  ItemUnavailabilityReason,
  ItemIds,
  Allergen,
  Modifier,
} from '../_shared/_interfaces/item.model';
import { CheckCalculation } from '../_shared/_interfaces/check-calculation.model';
import { ENDPOINTS } from '../_shared/_constants/endpoints';

const apiUrl = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class CatalogService {
  constructor(private http: HttpClient) {}

  public getItemDetails(id: string): Observable<ItemDetails> {
    return this.http.get<ItemDetails>(
      `${apiUrl}/catalog/v1/consumer/item/${id}`
    );
  }

  public getItemNutritionalValues(
    id: string
  ): Observable<NutritionalAttribute[]> {
    return this.http.get<NutritionalAttribute[]>(
      `${apiUrl}/catalog/v1/consumer/item/${id}/nutritional-value`
    );
  }

  public getItemModifiers(id: string): Observable<Modifier[]> {
    return this.http
      .get<ModifiersResponse>(
        `${apiUrl}/catalog/v1/consumer/item/${id}/modifier`
      )
      .pipe(
        map((modifiers: ModifiersResponse) => modifiers?.modifiers || []),
        map(modifiers =>
          modifiers.map(modifier => ({
            ...modifier,
            options: uniqBy(modifier.options, 'id'),
          }))
        )
      );
  }

  public calculatePrices(
    items: CalculateItemsPriceRequest
  ): Observable<CalculatedItem[]> {
    return this.http.post<CalculatedItem[]>(
      `${apiUrl}/${ENDPOINTS.catalog.calculate}`,
      items
    );
  }

  public getItemAvailability(
    itemIds: ItemIds
  ): Observable<ItemUnavailabilityReason[]> {
    return this.http.post<ItemUnavailabilityReason[]>(
      `${apiUrl}/catalog/v1/public/item/availability`,
      itemIds
    );
  }

  public calculateCheck(
    subtotal: number,
    totalExemptSubtotal: number,
    venueId: string,
    orderType = 'delivery'
  ): Observable<CheckCalculation> {
    return this.http.post<CheckCalculation>(
      `${apiUrl}/order/v1/guest/calculate`,
      {
        subtotal,
        totalExemptSubtotal,
        venueId,
        orderType,
      }
    );
  }

  private getAllergens(
    venueId: string,
    inUseOnly: boolean = true,
    defaultAllergens: boolean = false
  ): Promise<Allergen[]> {
    const queryParametersString: string = `venueId=${venueId}&category=allergen&inUseOnly=${inUseOnly}&defaultAllergens=${defaultAllergens}`;
    return firstValueFrom(
      this.http.get<Allergen[]>(
        `${apiUrl}/${ENDPOINTS.catalog.tag}?${queryParametersString}`
      )
    );
  }

  public getDefaultAllergens(venueId: string): Promise<Allergen[]> {
    return this.getAllergens(venueId, false, true);
  }

  public getUsedAllergens(venueId: string): Promise<Allergen[]> {
    return this.getAllergens(venueId, true, false);
  }
}
