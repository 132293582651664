import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

import moment from 'moment';
import { Store } from '@ngxs/store';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import {
  RsScheduleOrderDayTitlePipe,
  RsIsScheduleDaySelectedPipe,
} from './schedule-order-period.pipe';

import { ScheduleRange } from 'src/app/_shared/_interfaces/date-settings';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { LanguageState } from 'src/app/_shared/_ngxs/language.state';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-schedule-order-date',
  standalone: true,
  imports: [
    SharedModule,
    RsIsScheduleDaySelectedPipe,
    RsScheduleOrderDayTitlePipe,
  ],
  templateUrl: './schedule-order-date.component.html',
  styleUrls: ['./schedule-order-date.component.scss'],
})
export class ScheduleOrderDateComponent {
  @ViewChild('rsDaysRef', { static: true }) daysRef!: ElementRef<HTMLElement>;
  @Input() title!: string;
  @Input() selectedDay!: ScheduleRange;
  @Input() scheduleRange!: ScheduleRange[];
  @Input() noAvailableTime!: boolean | null;

  public showScrollToStartButton: boolean = false;
  public showScrollToEndButton: boolean = true;

  private language: string = '';

  private readonly destroyed$ = untilDestroyed();
  private readonly language$ = this.store.select(LanguageState.language);

  private onScroll = () => {
    const { scrollWidth, scrollLeft, clientWidth } = this.daysRef.nativeElement;

    if (scrollLeft == 0) {
      this.showScrollToEndButton = true;
      this.showScrollToStartButton = false;

      return;
    }

    this.showScrollToStartButton = true;

    const scrollPosition = parseInt(
      String(
        this.language === 'ar'
          ? scrollWidth + scrollLeft
          : scrollWidth - scrollLeft
      )
    );
    // scroll position calculated approximately and it can be a little
    // difference between scroll position and client width
    const isScrollAtTheEnd: boolean =
      scrollPosition === clientWidth ||
      (scrollPosition > clientWidth - 5 && scrollPosition < clientWidth - 5);

    this.showScrollToEndButton = !isScrollAtTheEnd;
  };

  public readonly arrowIcon = CommonIcons.AngleDown;

  @Output() selectedDayChange: EventEmitter<ScheduleRange> =
    new EventEmitter<ScheduleRange>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcons();
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.subscribeOnDaysScroll();
    this.subscribeOnLanguage();
  }

  ngOnChanges(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    // need time to rerender days
    setTimeout(() => {
      this.onScroll();
      this.changeDetectorRef.markForCheck();
    });
  }

  public selectDay(day: ScheduleRange): void {
    this.selectedDayChange.emit(day);
  }

  public getDayTitle(day: ScheduleRange): string {
    const today = moment();

    return today.get('date') === day.day.get('date')
      ? 'today'
      : day.day.format('ddd');
  }

  public getDayDescription(day: ScheduleRange): string {
    return day.day.format('MMM D');
  }

  public scrollToEnd(): void {
    this.daysRef.nativeElement.scrollLeft =
      this.language !== 'ar'
        ? this.daysRef?.nativeElement.scrollWidth
        : -1 * this.daysRef?.nativeElement.scrollWidth;
  }

  public scrollToStart(): void {
    this.daysRef.nativeElement.scrollLeft = 0;
  }

  private subscribeOnDaysScroll(): void {
    this.daysRef.nativeElement.addEventListener('scroll', this.onScroll);
  }

  private subscribeOnLanguage(): void {
    this.language$
      .pipe(this.destroyed$())
      .subscribe(language => (this.language = language));
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons([this.arrowIcon], NEW_ICONS_DIRECTORY);
  }

  ngOnDestroy(): void {
    this.daysRef.nativeElement.removeEventListener('scroll', this.onScroll);
  }
}
