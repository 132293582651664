import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

@Component({
  selector: 'rs-billing-address',
  templateUrl: 'billing-address.component.html',
  styleUrls: ['billing-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingAddressComponent {
  @Input() addressForm!: FormGroup;
  @Input() isHalfSeparatedInputs: boolean = false;
  @Input() isCountrySelector: boolean = false;

  public reactOnAddressDetailsFound(address: UserProfileAddress): void {
    const { address1, city, state, zipcode, countryCode } = address;

    this.addressForm.patchValue({
      address: address1,
      address1,
      city,
      state,
      country: countryCode,
      zipCode: zipcode,
      zipcode,
    });
  }
}
