<rs-auto-complete-input
  [label]="autocompleteFieldLabel | titlecase"
  [placeholder]="autocompleteFieldPlaceholder | titlecase"
  [control]="control"
  [matFormFieldClass]="matFormFieldClass"
  [required]="true"
  (onFocus)="clearValidators.emit()"
  (onBlur)="inputBlur.emit()"
  (onKeyDownEnter)="enterPressedInInput.emit()"
  (onDebouncedInput)="filterAutocompleteOptions.emit($event)"
  (onOptionSelected)="reactOnOptionSelect($event)"
  [options]="options"
  [iconType]="iconType"
  (formControlReset)="reactOnReset()"
></rs-auto-complete-input>
