import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';
import { OpenDineInAddPaymentmethodDialog } from 'src/app/_shared/_ngxs/dialog.actions';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

@Component({
  selector: 'rs-dine-in-edit-payment-dialog',
  templateUrl: './dine-in-edit-payment-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DineInEditPaymentDialogComponent {
  @Input() isShowTips: boolean = false;

  public isPaymentSelectionCompleted: boolean = true;
  public readonly backIcon = CommonIcons.Back;
  public readonly addIcon = CommonIcons.Add;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly store: Store,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcons();
  }

  public closePopup(): void {
    this.matDialog.getDialogById(MatDialogId.dine_in_change_payment)?.close();
  }

  public openAddPaymentDialog(): void {
    this.store.dispatch(new OpenDineInAddPaymentmethodDialog());
  }

  public setIsPaymentSelectionCompleted(
    isPaymentSelectionCompleted: boolean
  ): void {
    this.isPaymentSelectionCompleted = isPaymentSelectionCompleted;
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.addIcon, this.backIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
