import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TextMaskModule } from 'angular2-text-mask';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';

import { RsInputAngleDownIconComponent } from './_components/angle-down-icon/angle-down-icon.component';
import { RsInputComponent } from './input.component';
import { RsInputActionIconComponent } from './_components/action-icon/action-icon.component';
import { CreditCardMaskDirective } from './_directives/input-card.directive';
import { RsClickOutsideDirective } from 'src/app/_shared/_directives/click-outside.directive';
import { MaskErrorPipe } from './_pipes/mask-error.pipe';

import { MaterialModule } from 'src/app/_shared/_modules/material.module';
import { RsPipesModule } from 'src/app/_shared/_modules/pipes.module';

@NgModule({
  declarations: [
    RsInputComponent,
    RsInputAngleDownIconComponent,
    RsInputActionIconComponent,
    MaskErrorPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CreditCardMaskDirective,
    RsClickOutsideDirective,
    CurrencyMaskModule,
    MaterialModule,
    TextMaskModule,
    TranslateModule,
    RsPipesModule,
    NgxMatIntlTelInputComponent,
    NgxMaskModule.forChild(),
  ],
  exports: [
    RsInputComponent,
    RsInputAngleDownIconComponent,
    RsInputActionIconComponent,
    MaskErrorPipe,
  ],
})
export class RsInputModule {}
