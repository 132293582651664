export enum CardBrand {
  Mastercard = 'Mastercard',
  Visa = 'Visa',
  Amex = 'Amex',
  JCB = 'JCB',
  DinersClub = 'Diners Club',
  Discover = 'Discover',
  Unknown = 'Unknown',
  Default = 'Default',
}
