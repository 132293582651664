import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { Observable, filter, firstValueFrom, merge, of } from 'rxjs';

import {
  GuestRedirect,
  StartDigitalStorefrontExpirience,
} from './digital-storefront.actions';
import { RemoveShadowAndLoggedUserBoth } from './authentication.actions';
import {
  ClearDineInState,
  FetchDineIn,
} from 'src/app/venue/dine-in/_ngxs/dine-in.actions';
import { OpenDialog } from './dialog.actions';
import { UpdateOrderType } from './order-data.actions';

import { SessionState } from './authentication.state';
import { DineInState } from 'src/app/venue/dine-in/_ngxs/dine-in.state';
import { VenueState } from './venue.state';
import { CartState } from './cart.state';
import { AllergensState } from './allergens.state';

import { DinersComponent } from 'src/app/venue/dine-in/_components/diners/diners.component';
import { DietaryRestrictionPopupComponent } from '../_components/allergens-dietary-restrictions/dietary-restrictions-popup/dietary-restrictions-popup.component';

import { OrderType } from '../_enums/order.enum';
import { MatDialogId } from '../_enums/mat-dialog-id.enum';

import {
  DIETARY_RESTRICTIONS_POPUP_CONFIG,
  DINE_IN_CART_FULL_SCREEN_DIALOG_CONFIG,
} from '../_constants/mat-dialog-config.constant';

interface DigitalStorefrontStateModel {}
const DEFAULT_STATE = {};

// This state consist of flow realization for digital store front,
// not to connect with services
@State<DigitalStorefrontStateModel>({
  name: 'digitalStorefront',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class DigitalStorefrontState {
  private readonly isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );
  private readonly isShadowUser$: Observable<boolean> = this.store.select(
    SessionState.isShadowUser
  );

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly ngZone: NgZone
  ) {}

  @Action(StartDigitalStorefrontExpirience, { cancelUncompleted: true })
  async start({ dispatch }: StateContext<DigitalStorefrontStateModel>) {
    // clear previously added shadow user or/and login data
    dispatch([new ClearDineInState(), new UpdateOrderType(OrderType.DineIn)]);
    await firstValueFrom(dispatch(new RemoveShadowAndLoggedUserBoth()));

    // subscribe on receive token
    await firstValueFrom(
      merge(
        this.isLoggedIn$.pipe(filter(e => !!e)),
        this.isShadowUser$.pipe(filter(e => !!e))
      )
    );

    // get diners
    await firstValueFrom(dispatch(new FetchDineIn()));
    const shouldOpenDinersPopup = this.store.selectSnapshot(
      DineInState.diners
    )?.length;

    // if diners
    shouldOpenDinersPopup && (await this.openDinersPopup());

    const isShadowUser = this.store.selectSnapshot(SessionState.isShadowUser);

    // go to the menus
    await this.navigateToMenus();

    // open allergens popup for shadow user
    isShadowUser && (await this.openAllergensPopup());
  }

  @Action(GuestRedirect, { cancelUncompleted: true })
  async guestRedirect() {
    const isShadowUser = this.store.selectSnapshot(SessionState.isShadowUser);

    // open allergens popup for shadow user
    isShadowUser && (await this.openAllergensPopup());
  }

  async openDinersPopup(): Promise<void> {
    await firstValueFrom(
      this.store.dispatch(
        new OpenDialog(DinersComponent, {
          id: MatDialogId.diners,
          ...DINE_IN_CART_FULL_SCREEN_DIALOG_CONFIG,
        } as MatDialogConfig)
      )
    );

    await firstValueFrom(
      this.dialog.getDialogById(MatDialogId.diners)?.afterClosed() || of()
    );
  }

  async openAllergensPopup(): Promise<void> {
    const hasAllergens: boolean =
      this.store.selectSnapshot(AllergensState.defaultAllergens).length > 0 ||
      this.store.selectSnapshot(AllergensState.customAllergens).length > 0;

    if (hasAllergens) {
      await firstValueFrom(
        this.store.dispatch(
          new OpenDialog(DietaryRestrictionPopupComponent, {
            id: MatDialogId.allergens_popup,
            ...DIETARY_RESTRICTIONS_POPUP_CONFIG,
          })
        )
      );

      await firstValueFrom(
        this.dialog.getDialogById(MatDialogId.allergens_popup)?.afterClosed() ||
          of()
      );
    }
  }

  private navigateToMenus(): Promise<boolean> {
    const venueName = this.store.selectSnapshot(VenueState.formattedVenueName);
    const menuId = this.store.selectSnapshot(
      VenueState.defaultMenuId(OrderType.DineIn)
    );
    const menuIdFromQRCode = this.store.selectSnapshot(CartState.menuId);

    return this.ngZone.run(() => {
      return this.router.navigate(
        [
          `/${venueName}/dine-in/menus/${
            menuIdFromQRCode ? menuIdFromQRCode : menuId
          }`,
        ],
        {
          relativeTo: this.activatedRoute,
        }
      );
    });
  }
}
