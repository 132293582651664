<rs-dialog rs-dialog-style="default" rs-show-close-button="true">
  <ng-container rs-dialog-header>
    <span>{{ data.title }}</span>
  </ng-container>

  <span>{{ data.message }}</span>

  <ng-container rs-dialog-actions-direction="column">
    <button rs-style="bold" [mat-dialog-close]="true">
      <span> {{ 'CROPPER.ACCEPT' | translate }}</span>
    </button>
  </ng-container>
</rs-dialog>
