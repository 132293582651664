import { Pipe, PipeTransform } from '@angular/core';

import { Car } from 'src/app/profile/_interfaces/car.model';
import { isCarValid } from 'src/app/_shared/_utils/car.utils';

@Pipe({ name: 'rsIsCarValid', standalone: true })
export class IsCarValidPipe implements PipeTransform {
  public transform(car?: Car | undefined | null): boolean {
    return isCarValid(car);
  }
}
