<section *ngIf="cardForm" class="rs-payment-card-form" [formGroup]="cardForm">
  <p class="rs-payment-card-form__title">
    {{ 'PAYMENT.Add Payment Details' | translate }}
  </p>
  <p class="rs-payment-card-form__title rs-payment-card-form__title--small">
    {{ 'PAYMENT.New Card' | translate }}
  </p>
  <rs-input
    rs-input-style="default"
    [hasClearButton]="false"
    placeholder="{{ 'PAYMENT.Cardholder Name' | translate }}"
    [control]="cardForm.get('cardHolderName')"
    [required]="true"
  ></rs-input>
  <rs-input
    rs-input-style="default"
    [hasClearButton]="false"
    placeholder="{{ 'PAYMENT.Nickname' | translate }}"
    [required]="true"
    [control]="cardForm.get('name')"
  ></rs-input>
  <rs-input
    rs-input-style="card"
    [hasClearButton]="false"
    placeholder="{{ 'PAYMENT.Card Number' | translate }}"
    [control]="cardForm.get('cardNumber')"
  ></rs-input>
  <div class="rs-half-part">
    <rs-input
      rs-input-style="masked"
      [hasClearButton]="false"
      [required]="true"
      placeholder="MM/YY"
      [control]="cardForm.get('expirationDate')"
      [textMask]="expirationDateMaskConfig"
      [maskErrors]="cardExpirationDateErrors"
      [maskValidator]="expirationDateValidator"
    >
    </rs-input>
    <rs-input
      rs-input-style="cvv"
      [hasClearButton]="false"
      placeholder="CVV"
      [required]="true"
      [control]="cardForm.get('cvv')"
    ></rs-input>
  </div>
</section>
