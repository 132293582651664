import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { IN_PROGRESS_ORDER_STATUSES } from 'src/app/_shared/_constants/order-item-status.constant';
import { Order } from '../../profile/_interfaces/order.model';
import { LocalizedDatePipe } from 'src/app/_shared/_pipes/locolized-date.pipe';

@Pipe({ name: 'rsComposeOrderDate' })
export class RsComposeOrderDatePipe implements PipeTransform {
  constructor(
    private readonly localizedDatePipe: LocalizedDatePipe,
    private readonly translateService: TranslateService
  ) {}

  public transform(order: Order): string {
    if (!order.firedAt) {
      return '';
    }

    if (IN_PROGRESS_ORDER_STATUSES.has(order.fullStatus)) {
      return this.transformOrderDate(order.firedAt || order.fullStatus);
    }

    return this.transformOrderDate(order.firedAt);
  }

  private transformOrderDate(date: string): string {
    return this.localizedDatePipe.transform(
      date,
      this.translateService.instant('languageDirection') !== 'rtl'
        ? 'EEE, LLL yyyy'
        : 'EEE, LLL y'
    );
  }
}
