<ng-template [ngIf]="orderMethod">
  <ng-container [ngSwitch]="orderMethod">
    <ng-container *ngSwitchCase="DeliveryMethod.Curbside">
      <mat-icon [svgIcon]="curbsideIcon"></mat-icon>
    </ng-container>
    <ng-container *ngSwitchCase="DeliveryMethod.Delivery">
      <mat-icon [svgIcon]="deliveryIcon"></mat-icon>
    </ng-container>
    <ng-container *ngSwitchCase="DeliveryMethod.Takeout">
      <mat-icon [svgIcon]="takeoutIcon"></mat-icon>
    </ng-container>
  </ng-container>
</ng-template>
