import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import { debounceTime } from 'rxjs';

import { untilDestroyed } from '../../_utils/until-destroyed';

import { emptyOrNoCharactersValidator } from '../input/_validations/validators';

@Component({
  selector: 'rs-text-area',
  styleUrls: ['./text-area.component.scss'],
  templateUrl: './text-area.component.html',
  animations: [
    trigger('dropdown', [
      state(
        'closed',
        style({
          height: '0',
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      state(
        'open',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
})
export class RsTextAreaComponent {
  @Input() control: any = new FormControl();
  @Input() placeholder: string = '';
  @Input() label!: string;
  @Input() hint!: string;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;

  @HostBinding('class.w-100') w100Class = true;

  @ViewChild('defaultInput') defaultInput!: ElementRef<HTMLInputElement>;

  public isActive: boolean = false;
  public canBeRightToLeft: boolean = true;
  public form: FormGroup = new FormGroup({});
  public currentErrorType: any = { invalid: true };
  public formControl: any = new FormControl();
  public isInvalid: boolean = false;
  public isValid: boolean = false;

  private triggeredChange: boolean = false;
  private activeInput!: ElementRef<HTMLInputElement>;
  private readonly destroyed$ = untilDestroyed();

  @Output() onBlur: EventEmitter<Event> = new EventEmitter();
  @Output() onFocus: EventEmitter<Event> = new EventEmitter(); // 'onFocus' will emit after we perform a validity reset upon the focus event.
  @Output() onInput: EventEmitter<Event> = new EventEmitter(); // 'onInput' will emit after we perform a validity reset and if type is Card, it will check the card type upon the input event.
  @Output() onKeyDownEnter: EventEmitter<Event> = new EventEmitter(); // 'onKeyDownEnter' will emit after we call event.preventDefault() so that other elements do not get triggered on the keydown.enter event.
  @Output() controlChange: EventEmitter<FormControl<any>> = new EventEmitter<
    FormControl<any>
  >();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    !!changes['control'] &&
      !changes['control'].firstChange &&
      this.formControl.setValue(this.controlValue, { emitEvent: false });
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.control.valueChanges
      .pipe(this.destroyed$(), debounceTime(150))
      .subscribe(() => {
        if (!this.triggeredChange) {
          this.triggeredChange = true;
          this.formControl.setValue(this.controlValue, { emitEvent: false });

          setTimeout(() => {
            this.triggeredChange = false;
          }, 150);
        }
      });

    this.setupDefaultInput();
    this.subscribeToFormControl();
  }

  ngAfterViewInit(): void {
    this.activeInput = this.defaultInput;
  }

  private setupDefaultInput(): void {
    this.formControl = new FormControl(
      this.controlValue,
      this.defaultValidators()
    );
  }

  private subscribeToFormControl(): void {
    this.formControl.valueChanges.pipe(this.destroyed$()).subscribe(() => {
      this.clearWarningSuccess();

      this.control.setValue(this.formControl.value);
      !this.isActive && this.onOutsideClick();
    });

    this.changeDetectorRef.detectChanges();
  }

  private get controlValue(): string | number | null {
    return this.control?.value;
  }

  private defaultValidators(): ValidatorFn[] | null {
    return this.required
      ? [Validators.required, emptyOrNoCharactersValidator()]
      : [Validators.nullValidator];
  }

  public clearValue(): void {
    this.formControl.reset();
    this.onInput.emit();

    setTimeout(() => {
      this.activeInput?.nativeElement.focus();
    }, 50);
  }

  public reactOnFocus(): void {
    this.resetValidity();

    setTimeout(() => {
      this.activeInput?.nativeElement.focus();
    }, 50);

    this.onFocus.emit();
  }

  public reactOnInput(): void {
    this.resetValidity();
    this.onInput.emit();
  }

  private clearWarningSuccess(): void {
    this.isInvalid = false;
    this.isValid = false;
  }

  public reactOnKeyDownEnter(event: Event): void {
    event.preventDefault();
    this.onKeyDownEnter.emit();
  }

  public reactOnKeyDownTab(): void {
    this.checkFormErrorStates();
  }

  private checkFormErrorStates(): void {
    this.isValid = false;
    this.isInvalid = false;

    this.formControl?.invalid && (this.isInvalid = true);
  }

  public onOutsideClick(): void {
    this.checkFormErrorStates();
    this.isActive = false;
    this.onBlur.emit();
  }

  public get applyPadding(): boolean {
    return this.controlValue !== null || this.isValid || this.isInvalid;
  }

  public get showClearButton(): boolean {
    return (
      this.isControlValueNotEmpty() &&
      this.isActive &&
      !this.isValid &&
      !this.isInvalid
    );
  }

  private isControlValueNotEmpty(): boolean {
    const value = this.controlValue;

    return !(
      value == null ||
      value === '' ||
      (typeof value === 'string' && value.trim() === '')
    );
  }

  private resetValidity(): void {
    this.isActive = true;
    this.isValid = false;
    this.isInvalid = false;
  }
}
