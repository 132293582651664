<div class="rs-default-food">
  <mat-icon
    [svgIcon]="icon"
    class="rs-default-food__icon"
    [ngClass]="{
      'rs-default-food__icon--large': size === DefaultFoodIconSize.Large,
      'rs-default-food__icon--small': size === DefaultFoodIconSize.Small,
      'rs-default-food__icon--medium': size === DefaultFoodIconSize.Medium
    }"
  ></mat-icon>
</div>
