import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Store } from '@ngxs/store';
import { Observable, delay, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';

import {
  Login,
  SendRecoveryLink,
} from 'src/app/_shared/_ngxs/authentication.actions';
import { CookieEngine } from 'src/app/_services/cookie.service';
import { CookieStorageKeys } from 'src/app/_shared/_enums/cookie-storage-keys.enum';
import { EMAIL_REGEXP } from 'src/app/_shared/_validators/email-or-phone.validator';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';

@Component({
  selector: 'rs-authorisation-login',
  standalone: true,
  imports: [SharedModule],
  templateUrl: 'authorisation-login.component.html',
  styleUrls: [
    'authorisation-login.component.scss',
    '../authorisation.common.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorisationLoginComponent {
  @Input() public showForgotPassword!: boolean;

  public ifVisibilityOff: boolean = true;
  public previouslySavedEmail!: string;
  public loginForm: FormGroup = this.formBuilder.group({
    emailOrPhone: new FormControl('', Validators.required),
    password: new FormControl(null, Validators.required),
  });

  public get emailOrPhoneControl(): AbstractControl | null {
    return this.loginForm.controls['emailOrPhone'];
  }

  public get passwordControl(): AbstractControl | null {
    return this.loginForm.controls['password'];
  }

  public get emailControl(): AbstractControl | null {
    return this.recoveryPasswordGroup.controls['email'];
  }

  public readonly loginPasswordId = TEST_ID.login.password;
  public readonly emailOrPhoneId = TEST_ID.login.emailOrPhone;
  public readonly passwordResetEmailId = TEST_ID.passwordReset.email;
  public readonly passwordResetRecoveryId = TEST_ID.passwordReset.recovery;

  public recoveryPasswordGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(EMAIL_REGEXP),
    ]),
  });

  public isRecoveryLinkSent!: boolean;

  public successObserver: EventEmitter<string> = new EventEmitter();
  public injectedSuccess: Observable<string> = this.successObserver.pipe(
    delay(100)
  );

  @Output() showedForgotPassword: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private readonly store: Store,
    private readonly formBuilder: FormBuilder,
    private readonly cookieEngine: CookieEngine,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService
  ) {
    this.getPrevioslySavedData();
  }

  public login(): void {
    if (!this.loginForm.valid) {
      return;
    }

    const { emailOrPhone, password } = this.loginForm.value;

    this.store.dispatch(new Login(emailOrPhone, password));
  }

  public getControlErrors(control: AbstractControl | null): string[] {
    return Object.keys(control?.errors || {});
  }

  private getPrevioslySavedData(): void {
    this.previouslySavedEmail =
      this.cookieEngine.getItem(CookieStorageKeys.previouslySavedEmail) || '';

    if (this.previouslySavedEmail) {
      this.loginForm.patchValue({ emailOrPhone: this.previouslySavedEmail });
      this.recoveryPasswordGroup.patchValue({
        email: this.previouslySavedEmail,
      });
    }
  }

  public changeVisibility($event: MouseEvent): void {
    this.ifVisibilityOff = !this.ifVisibilityOff;
    $event.stopPropagation();
  }

  public changeToForgotPassword(): void {
    this.showForgotPassword = true;
    this.showedForgotPassword.emit(this.showForgotPassword);
  }

  public async sendRecoveryLink(): Promise<void> {
    if (!this.recoveryPasswordGroup.valid) {
      return;
    }

    const { email } = this.recoveryPasswordGroup.value;

    if (!email) {
      return;
    }

    const venueId = this.store.selectSnapshot(VenueState.venueId);

    await firstValueFrom(
      this.store.dispatch(new SendRecoveryLink(email, venueId))
    );

    this.previouslySavedEmail = email;
    this.isRecoveryLinkSent = true;
    this.successObserver.next(
      `${this.translateService.instant('RESET_PASSWORD.WE_SENT')} <strong>${
        this.previouslySavedEmail
      }</strong>`
    );
    this.changeDetectorRef.markForCheck();
  }

  public resetRecoveryLinkSent(): void {
    this.isRecoveryLinkSent = false;
  }
}
