import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Store } from '@ngxs/store';

import {
  BundleInformation,
  BundleItem,
  BundleItemSizeAttributes,
  BundleSectionSizeAttributes,
  SelectedItemsInBundle,
} from 'src/app/_shared/_interfaces/item.model';
import {
  GetBundleItemModifiers,
  RemoveBundleItemModifier,
} from 'src/app/_shared/_ngxs/bundle-item.actions';

@Component({
  selector: 'rs-bundle-section',
  templateUrl: './bundle-section.component.html',
  styleUrls: ['../_styles/item-details.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundleSectionComponent {
  @Input() bundleSectionId!: string;
  @Input() items!: BundleItem[];
  @Input() bundleItemSizeAttributes!: BundleItemSizeAttributes[];
  @Input() bundleSectionSizeAttributes: BundleSectionSizeAttributes | undefined;
  @Input() addedBundleItems: BundleInformation[] = [];
  @Input() disableOrdering = false;

  @Output() bundleItemsSelected: EventEmitter<SelectedItemsInBundle> =
    new EventEmitter();

  constructor(private store: Store) {}

  public isBundleItemChecked(bundleItem: BundleItem): boolean {
    return !!this.addedBundleItems.find(
      item => bundleItem.id === item.bundleItemId
    );
  }

  public reactOnItemSelected(item: BundleItem): void {
    const index: number = this.addedBundleItems.findIndex(
      ({ bundleSectionId }) => bundleSectionId === this.bundleSectionId
    );

    this.getModifiersForItem(item);

    if (index > -1) {
      this.addedBundleItems.splice(index, 1);
    }

    this.addedBundleItems.push({
      bundleItemId: item.id,
      bundleSectionId: this.bundleSectionId,
    });

    this.bundleItemsSelected.next({
      bundleSectionId: this.bundleSectionId,
      items: this.addedBundleItems,
    });
  }

  public increaseQuantity(item: BundleItem): void {
    this.addedBundleItems.push({
      bundleItemId: item.id,
      bundleSectionId: this.bundleSectionId,
    });

    this.bundleItemsSelected.next({
      bundleSectionId: this.bundleSectionId,
      items: this.addedBundleItems,
    });

    this.getModifiersForItem(item);
  }

  public getModifiersForItem(item: BundleItem): void {
    this.store.dispatch(new GetBundleItemModifiers(item, this.bundleSectionId));
  }

  public decreaseModifiersForItem(item: BundleItem): void {
    this.store.dispatch(
      new RemoveBundleItemModifier(item, this.bundleSectionId)
    );
  }

  public decreaseQuantity(item: BundleItem): void {
    const index: number = this.addedBundleItems.findIndex(
      ({ bundleItemId }) => bundleItemId === item.id
    );

    if (index > -1) {
      this.addedBundleItems.splice(index, 1);
    }

    this.bundleItemsSelected.next({
      bundleSectionId: this.bundleSectionId,
      items: this.addedBundleItems,
    });

    this.decreaseModifiersForItem(item);
  }
}
