<div class="rs-select-address">
  <div class="rs-select-address__title">
    {{ 'DELIVERY_METHOD.curbside_details' | translate }}
  </div>
  <ng-container *ngIf="(isLoggedIn$ | async) && (cars$ | async)?.length">
    <div class="rs-select-address__description">
      {{ 'DELIVERY_METHOD.Select Car' | translate }}
    </div>
    <div
      class="rs-select-address__item"
      *ngFor="let car of cars$ | async"
      [class.rs-select-address__item--selected]="car === selectedCar"
      (click)="reactOnCarClick(car)"
    >
      <rs-car-item [car]="car"></rs-car-item>
    </div>
  </ng-container>
  <div class="rs-select-address__description">
    {{
      ((isLoggedIn$ | async) && (cars$ | async)?.length
        ? 'DELIVERY_METHOD.Or enter new car details'
        : 'DELIVERY_METHOD.Enter new car details'
      ) | translate
    }}
  </div>
  <div class="rs-select-address__new">
    <rs-car-form [carForm]="carForm!"></rs-car-form>
  </div>
</div>
