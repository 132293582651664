import { isEqual } from 'lodash';

import {
  ModifierSelection,
  SelectedModifier,
  StateItem,
} from '../_interfaces/item.model';
import {
  getLinkBasedOnFormattedVenueName,
  joinStringsWithSpaceBetween,
} from './common';
import { Params } from '@angular/router';
import { QRSettingsType } from '../_interfaces/qrSettings.model';
import { OrderItemType } from '../_enums/order-item-type.enum';

export const compareModifierSelections = (
  modifierSelections1: ModifierSelection[],
  modifierSelections2: ModifierSelection[]
): boolean => {
  let modifiersEquality: boolean = true;

  if (!modifierSelections1 && !modifierSelections2) {
    return true;
  }

  if (modifierSelections1?.length === modifierSelections2?.length) {
    modifierSelections1.forEach(
      (selection1: ModifierSelection, idx: number) => {
        if (
          !isEqual(
            selection1.modifierOptionIds!,
            modifierSelections2[idx].modifierOptionIds!
          )
        ) {
          modifiersEquality = false;
        }
      }
    );
  }

  return modifiersEquality;
};

export const formItemDetailsUrlAndParams = (
  currentMenuType: QRSettingsType | undefined,
  itemId: string,
  menuId: string,
  sectionId: string | undefined,
  formattedVenueName: string,
  isMobileLink: boolean = false
): {
  url: string;
  queryParams: Params;
} => {
  const menuType = currentMenuType || 'menu';
  const isMenu: boolean = menuType === 'menu';
  const isDineIn: boolean = menuType === 'dine-in';
  let queryParams: Params = { itemId: itemId };
  let menuRoutePart: string = '';

  sectionId !== undefined && (queryParams['section'] = sectionId);
  currentMenuType && (queryParams['menu'] = currentMenuType);

  switch (true) {
    case isMenu:
      menuRoutePart = 'item';
      break;
    case isDineIn:
      menuRoutePart = isMobileLink ? 'dine-in/menus' : 'dine-in/item';
      break;
    default:
      menuRoutePart = 'order';
      break;
  }

  const menuRoute = getLinkBasedOnFormattedVenueName(formattedVenueName, [
    menuRoutePart,
    menuId,
  ]);
  let url: string = menuRoute;

  if (isMobileLink) {
    queryParams['isMobile'] = true;
  } else {
    url = `/${url}/${itemId}`;
  }

  return {
    url,
    queryParams,
  };
};

export const getModifierTitleWithAmount = (
  modifierTitle: string,
  modifiers: SelectedModifier[] = []
): any => {
  modifierTitle ??= '';

  const amount = (modifiers || []).filter(
    modifier => modifier.selectedOptionTitle === modifierTitle
  ).length;

  return modifierTitle + (amount > 1 ? ` x${amount}` : '');
};

export const getUniqueItemModifiersNames = (
  modifiers: SelectedModifier[]
): string[] => {
  const names = modifiers?.map(
    ({ selectedOptionTitle }) => selectedOptionTitle
  );

  return Array.from(new Set(names));
};

export const getBundleItemsAmount = <T>(item: T, items: T[] = []): string => {
  if (!items?.length) {
    return '';
  }

  const itemsAmount: number = items.filter((bundleItem: T) =>
    isEqual(item, bundleItem)
  ).length;

  return itemsAmount > 1 ? `x${itemsAmount}` : '';
};

export const getBundleName = <T extends { bundleItemId: string }>(
  bundleItem: T,
  bundleItems: T[],
  bundleItemsNames: { [id: string]: string }
): string => {
  const name = bundleItemsNames
    ? bundleItemsNames?.[bundleItem.bundleItemId]
    : '';
  const amount = getBundleItemsAmount(bundleItem, bundleItems);

  return joinStringsWithSpaceBetween([name, amount]);
};

export const getBundleModifiersDescriptions = (
  modifiers: SelectedModifier[] = []
): string[] => {
  if (!modifiers) {
    return [];
  }

  const uniqueModifiers: string[] = getUniqueItemModifiersNames(modifiers);

  return uniqueModifiers.map((modifier: string) =>
    getModifierTitleWithAmount(modifier, modifiers)
  );
};

export const updateItemsForCart = (
  items: StateItem[],
  type: OrderItemType | null = null
): any[] => {
  return (items || []).map((item: any) => {
    return {
      type: type || OrderItemType.item,
      itemId: item.item?.itemId || '',
      image: item.item?.image || '',
      name: item.item?.name || '',
      customerFacingName: item.item?.customerFacingName || '',
      size: item.size,
      bundleItems: item.bundleItems || [],
      quantity: item.quantity || 1,
      modifiers: item.modifiers,
      price: item.price || 0,
      specialRequest: item.specialRequest || '',
      selectedInCart: item.selectedInCart,
      menuId: item.menuId,
      isAvailable: item.item.availability.isAvailable,
      cartItemId: item.cartItemId,
      category: item.category,
      allergens: item.allergens,
    };
  });
};
