import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { first, Observable } from 'rxjs';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';

import { RsDialogComponent } from 'src/app/_shared/_rs-design/dialog/dialog.component';
import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { PaymentItemComponent } from 'src/app/_shared/_components/payment-item/payment-item.component';

import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';
import {
  DeleteCard,
  SetDefaultCard,
} from 'src/app/_shared/_ngxs/profile.actions';

import { Card } from 'src/app/profile/_interfaces/payment.model';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

interface DialogData {
  card: Card;
  shouldSkipActions?: boolean;
  title: string;
  description: string;
}

@Component({
  selector: 'rs-delete-default-card',
  templateUrl: 'delete-default-card.component.html',
  styleUrls: ['./delete-default-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RsDialogComponent, SharedModule, PaymentItemComponent],
})
export class DeleteDefaultCardComponent implements OnInit {
  private readonly cards$: Observable<Card[]> = this.store.select(
    ProfileState.cards
  );
  private readonly destroyed$ = untilDestroyed();
  public notDefaultCards: Card[] = [];
  public selectedPaymentId: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly dialogRef: MatDialogRef<DeleteDefaultCardComponent>
  ) {}

  public ngOnInit(): void {
    this.setNotDefaultCards();
  }

  private setNotDefaultCards(): void {
    this.cards$
      .pipe(this.destroyed$())
      .subscribe(
        (cards: Card[]) =>
          (this.notDefaultCards = cards.filter((card: Card) => !card.default))
      );
  }

  public selectDefaultCard(): void {
    if (!this.data.shouldSkipActions) {
      if (this.selectedPaymentId) {
        this.store.dispatch(new SetDefaultCard(this.selectedPaymentId));
        this.actions$
          .pipe(ofActionSuccessful(SetDefaultCard), first())
          .subscribe(() => {
            this.store.dispatch(new DeleteCard(this.data.card.id!));
          });
      } else {
        this.store.dispatch(new DeleteCard(this.data.card.id!));
      }
    }

    this.actions$
      .pipe(ofActionSuccessful(DeleteCard), first())
      .subscribe(() => this.dialogRef.close());
  }

  public setSelectedCard({ id }: Card): void {
    !!id && (this.selectedPaymentId = id);
  }
}
