<rs-common-order-item
  *ngIf="orderItem"
  [image]="orderItem.image"
  [size]="orderItem.size?.name || ''"
  [name]="orderItem.customerFacingName || orderItem.name"
  [price]="orderItem.price || 0"
  [amount]="orderItem.quantity || 0"
  [category]="orderItem.category || ''"
  [descriptionModifiers]="orderItem.description"
  [modifiers]="orderItem.modifiers"
  [bundleItems]="bundleItems || []"
  [selectedInCart]="!!orderItem.selectedInCart"
  [specialRequest]="orderItem.specialRequest || ''"
  [showCheckboxes]="showCheckboxes"
  [availableToChange]="availableToChange"
  [showFullDescription]="true"
  [availableToOrder]="availableToOrder"
  [blurUnavailableItem]="false"
  [unavailableMessage]="unavailableMessage"
  (clickedOnItem)="clickedOnItem.emit()"
  (selected)="selected.emit($event)"
  (amountChanged)="amountChanged.emit($event)"
>
</rs-common-order-item>
