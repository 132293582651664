import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SharedModule } from '../shared.module';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';

@Component({
  selector: 'rs-powered-by-rockspoon',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './powered-by-rockspoon.component.html',
  styleUrls: ['./powered-by-rockspoon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoweredByRockspoonComponent {
  @Input() showLogo: boolean = true;
  @Input() showPowered: boolean = true;
  @Input() darkTheme: boolean = false;
  venue$: Observable<any> = this.store.select(VenueState.data);

  constructor(private readonly store: Store) {}
}
