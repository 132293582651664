import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { SharedModule } from '../../_modules/shared.module';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';

import { untilDestroyed } from '../../_utils/until-destroyed';

@Component({
  selector: 'rs-create-password-form',
  standalone: true,
  imports: [SharedModule, PasswordStrengthComponent],
  templateUrl: './create-password-form.component.html',
  styleUrls: ['./create-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePasswordFormComponent {
  public get passwordControl(): AbstractControl | null {
    return this.formGroup.controls['password'];
  }

  public get confirmPasswordControl(): AbstractControl | null {
    return this.formGroup.controls['confirmPassword'];
  }

  @Input() formGroup!: FormGroup;
  @Input() showWarningIcon!: boolean;

  @Output() formUpdated: EventEmitter<{
    password: string;
    confirmPassword: string;
  }> = new EventEmitter();

  @Output() formIsValid: EventEmitter<boolean> = new EventEmitter();

  private readonly destroy$ = untilDestroyed();

  ngOnInit(): void {
    this.addSubscriptions();
  }

  public getControlErrors(control: AbstractControl | null): string[] {
    return Object.keys(control?.errors || {});
  }

  private addSubscriptions(): void {
    this.formGroup.valueChanges.pipe(this.destroy$()).subscribe(() => {
      const { password, confirmPassword } = this.formGroup.getRawValue();

      this.formUpdated.next({ password, confirmPassword });
      this.formIsValid.next(this.formGroup.valid);
    });
  }
}
