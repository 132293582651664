<div
  *ngIf="amount"
  class="rs-order-item d-flex"
  [ngClass]="{
    'cursor-pointer': availableToChange && availableToOrder,
    'rs-order-item--unavailable': !availableToOrder && blurUnavailableItem
  }"
  (click)="onClick()"
>
  <div *ngIf="showCheckboxes" class="rs-order-item__checkbox">
    <mat-checkbox
      (change)="selectItem($event.checked)"
      (click)="$event.stopPropagation()"
      [checked]="selectedInCart"
    ></mat-checkbox>
  </div>

  <div class="rs-order-item__item-image">
    <img
      *ngIf="image; else rsDefaultImage"
      [src]="image | toCDN"
      loading="lazy"
      rsImageLoader
      alt=""
      aria-hidden="true"
    />
  </div>

  <div class="rs-order-item__main-wrapper d-flex flex-column">
    <rs-banner-alert
      *ngIf="unavailableMessage"
      class="rs-w-fit-content rs-order-item__unavailable-message"
      rs-status="error"
      rs-rounded="true"
    >
      {{ unavailableMessage }}
    </rs-banner-alert>

    <div
      class="rs-order-item__main d-flex"
      [ngClass]="{
        'rs-order-item__main--no-actions': !availableToChange
      }"
    >
      <div
        class="rs-order-item__actions-and-price d-flex"
        [ngClass]="{
          'rs-order-item__actions-and-price--with-actions': availableToChange
        }"
      >
        <div
          *ngIf="availableToChange"
          class="rs-order-item__actions"
          (click)="$event.preventDefault(); $event.stopImmediatePropagation()"
        >
          <rs-counter
            (quantityDecreased)="changeAmount(-1)"
            (quantityIncreased)="changeAmount(1)"
            [isSmall]="true"
            [maxQuantity]="undefined"
            [quantity]="amount || 1"
            [showIncreaseButton]="true"
          ></rs-counter>
        </div>

        <div class="rs-order-item__price">
          {{
            (price || 0) * (amount || 1)
              | cents2DollarsView
              | rsEasternArabicNumerals
          }}
        </div>
      </div>

      <div
        class="rs-order-item__information d-flex flex-column"
        [ngClass]="{
          'rs-order-item__information--short': !showFullDescription,
          'rs-order-item__information--short-one-line':
            !showFullDescription && !!unavailableMessage
        }"
      >
        <div class="rs-order-item__title rs-one-line-text-ellipsis">
          <span
            *ngIf="!availableToChange && amount && amount > 1"
            class="rs-order-item__amount"
          >
            {{ amount }}x
          </span>

          <h1 class="rs-order-item__name">
            {{ name || '' }}
          </h1>
        </div>

        <div
          class="rs-order-item__description d-flex flex-column"
          [matTooltip]="shortModifiersDescription"
          [matTooltipDisabled]="
            !shortModifiersDescription || showFullDescription
          "
        >
          <p
            *ngIf="size !== defaultItemSize"
            class="rs-order-item__supporting-text rs-one-line-text-ellipsis"
          >
            {{ size }}
          </p>

          <p
            *ngFor="let modifier of descriptionModifiers"
            class="rs-order-item__supporting-text rs-one-line-text-ellipsis"
          >
            {{ modifier }}
          </p>

          <ng-container *ngFor="let bundleItem of bundleItems || []">
            <p class="rs-order-item__supporting-text rs-one-line-text-ellipsis">
              {{ bundleItem.name }}
            </p>
            <p
              *ngFor="let modifier of bundleItem.modifiers || []"
              class="ms-2 rs-order-item__supporting-text rs-one-line-text-ellipsis"
            >
              {{ modifier }}
            </p>
          </ng-container>

          <p
            *ngFor="let modifier of modifiers | rsGetUniqueItemModifiersNames"
            class="rs-order-item__supporting-text rs-one-line-text-ellipsis"
          >
            {{ modifier | rsModifierTitleWithAmount: modifiers || [] }}
          </p>

          <p
            *ngIf="specialRequest"
            class="rs-order-item__supporting-text rs-one-line-text-ellipsis"
          >
            {{ specialRequest }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #rsDefaultImage>
  <rs-default-food-icon
    [category]="category"
    [size]="defaultImageSize"
  ></rs-default-food-icon>
</ng-template>
