import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { tap } from 'rxjs';

import { DineInService } from 'src/app/_services/dine-in.service';
import {
  DineInStateModel,
  Diner,
} from 'src/app/_shared/_interfaces/dine-in.model';
import {
  ClearDineInState,
  FetchDineIn,
  InviteGuests,
  SetDineInOrderId,
} from 'src/app/venue/dine-in/_ngxs/dine-in.actions';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';
import { OrderState } from 'src/app/_shared/_ngxs/order.state';
import {
  HideSpinner,
  ShowSpinner,
} from 'src/app/_shared/_components/spinner/spinner.state';
import { OpenDialog } from 'src/app/_shared/_ngxs/dialog.actions';
import { InviteSuccessPopupPopupComponent } from '../_components/invite-success-popup/invite-success-popup.component';
import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';
import { DIALOG_PANEL_CLASS } from 'src/app/_shared/_constants/mat-dialog-panel-classes.constant';
import { NotificationService } from 'src/app/_shared/_services/notification.service';
import { CustomErrors } from 'src/app/_shared/_interceptors/error.interfaces';

const defaults: DineInStateModel = {
  orderId: null,
  maxOccupancy: null,
  createdAt: null,
  diners: null,
};

@State<DineInStateModel>({
  name: 'dineIn',
  defaults,
})
@Injectable()
export class DineInState {
  constructor(
    private readonly dineInService: DineInService,
    private readonly store: Store,
    private readonly notificationService: NotificationService
  ) {}

  @Selector()
  static orderId({ orderId }: DineInStateModel): string | null {
    return orderId;
  }

  @Selector()
  static diners({ diners }: DineInStateModel): Diner[] | null {
    return diners;
  }

  @Action(FetchDineIn)
  fetchDineIn({ patchState }: StateContext<DineInStateModel>) {
    return this.dineInService
      .fetchDineIn(
        this.store.selectSnapshot(VenueState.venueId),
        this.store.selectSnapshot(CartState.sectionId),
        this.store.selectSnapshot(CartState.tableId)
      )
      .pipe(tap(dineIn => patchState(dineIn)));
  }

  @Action(InviteGuests)
  inviteGuests({ dispatch }: StateContext<DineInStateModel>, phones: string[]) {
    dispatch(new ShowSpinner());

    return this.dineInService
      .inviteGuests(
        this.store.selectSnapshot(
          DineInState.orderId || OrderState.dineInOrderID
        ),
        phones
      )
      .pipe(
        tap({
          next: () => {
            dispatch([
              new HideSpinner(),
              new OpenDialog(InviteSuccessPopupPopupComponent, {
                id: MatDialogId.successful_invite,
                panelClass: DIALOG_PANEL_CLASS.successfulInvite,
                data: phones,
              }),
            ]);
          },
          error: (error: CustomErrors) => {
            this.notificationService.showError(error.errors.details[0].message);
          },
        })
      );
  }

  @Action(SetDineInOrderId)
  setDineInOrderID(
    { patchState }: StateContext<DineInStateModel>,
    { orderId }: SetDineInOrderId
  ) {
    patchState({ orderId });
  }

  @Action(ClearDineInState)
  clearDineInState({ patchState }: StateContext<DineInStateModel>) {
    patchState(defaults);
  }
}
