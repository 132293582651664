import { MenuItemKey } from 'src/app/profile/_enum/menu-items.enum';
import { camelCaseFormat } from '../_utils/common';

const prefix = 'rs-e2e';
const testCase = {
  login: 'login',
  onlineOrdering: 'order-online',
  orderType: 'order-type-button',
  addressAutocomplete: 'address-autocomplete',
  giftCard: 'gift-card',
  signUp: 'signup',
  passwordReset: 'password-reset',
  cart: 'cart',
  checkout: 'checkout',
  home: 'home',
  topbar: 'topbar',
  news: 'news',
  profile: 'profile',
  createPassword: 'create-password',
  giftCardSuccessPage: 'gift-card-success-page',
  orderSuccessPage: 'order-success-page',
  editInfoPopup: 'edit-info-popup',
  orderDetails: 'order-details',
  payNowPopup: 'pay-now-popup',
  payNowDesktopPopup: 'pay-now-desktop-popup',
  guest: 'guest',
  autocomplete: 'autocomplete',
};

export const TEST_ID = {
  autocompleteField: `${prefix}-${testCase.autocomplete}-field`,
  login: {
    emailOrPhone: `${prefix}-${testCase.login}-email-or-phone`,
    password: `${prefix}-${testCase.login}-password`,
    loginButton: `${prefix}-${testCase.login}-login-button`,
    forgotPassword: `${prefix}-${testCase.login}-forgot-password`,
    signup: `${prefix}-${testCase.login}-signup`,
    guestContinue: `${prefix}-${testCase.login}-guest-continue`,
    backButton: `${prefix}-${testCase.login}-back-button`,
  },
  onlineOrdering: {
    closeEnterAddressDialog: `${prefix}-${testCase.onlineOrdering}-close-enter-address-dialog`,
    addressForm: `${prefix}-${testCase.onlineOrdering}-address-form`,
    searchComponent: `${prefix}-${testCase.onlineOrdering}-search-component`,
    searchMenu: `${prefix}-${testCase.onlineOrdering}-search-menu`,
    header: `${prefix}-${testCase.onlineOrdering}-header`,
  },
  addressAutocomplete: {
    addressAutocomplete: `${prefix}-${testCase.addressAutocomplete}`,
    addressAutocompleteField: `${prefix}-${testCase.addressAutocomplete}-field`,
  },
  menuId: (menuId: string, isMobile: boolean = false) =>
    `${prefix}-${isMobile ? 'mobile-' : ''}menu-${menuId}`,
  sectionId: (sectionId: string, isMobile: boolean = false) =>
    `${prefix}-${isMobile ? 'mobile-' : ''}section-${sectionId}`,
  orderType: (orderType: string) =>
    `${prefix}-${testCase.orderType}-${orderType}`,
  giftCard: {
    customAmount: `${prefix}-${testCase.giftCard}-custom-amount`,
    customAmountButton: `${prefix}-${testCase.giftCard}-custom-amount-button`,
    preview: `${prefix}-${testCase.giftCard}-preview`,
    amount: `${prefix}-${testCase.giftCard}-preview-amount`,
    cardHolder: `${prefix}-${testCase.giftCard}-preview-holder-name`,
    sendMyselfToggle: `${prefix}-${testCase.giftCard}-send-myself-toggle`,
    form: `${prefix}-${testCase.giftCard}-form`,
    message: `${prefix}-${testCase.giftCard}-form-message`,
    add: `${prefix}-${testCase.giftCard}-add-button`,
    presetAmount: (amount: number) =>
      `${prefix}-${testCase.giftCard}-preset-amount-${amount}`,
    formInput: (control: string) =>
      `${prefix}-${testCase.giftCard}-form-control-${control}`,
    deliverByMethod: (method: string) =>
      `${prefix}-${testCase.giftCard}-deliver-by-${method}-button`,
  },
  signUp: {
    backButton: `${prefix}-${testCase.signUp}-back-button`,
    acceptTerms: `${prefix}-${testCase.signUp}-accept-checkbox`,
    signUpButton: `${prefix}-${testCase.signUp}-sign-up-button`,
    form: `${prefix}-${testCase.signUp}-form`,
    singupFormInput: (control: string) =>
      `${prefix}-${testCase.signUp}-${control}-field`,
  },
  passwordReset: {
    backButton: `${prefix}-${testCase.passwordReset}-back-button`,
    email: `${prefix}-${testCase.passwordReset}-email-field`,
    recovery: `${prefix}-${testCase.passwordReset}-send-recovery-link-button`,
    login: `${prefix}-${testCase.passwordReset}-login-button`,
  },
  Cart: {
    backButton: `${prefix}-${testCase.cart}-back-button`,
    orderMore: `${prefix}-${testCase.cart}-order-more-button`,
    allergensDialog: `${prefix}-${testCase.cart}-allergens-dialog-button`,
    totalItemsCheckbox: `${prefix}-${testCase.cart}-total-items-checkbox`,
    userLogInButton: `${prefix}-${testCase.cart}-user-log-in-button`,
    subtotalAmount: `${prefix}-${testCase.cart}-subtotal-amount`,
    cartCheckoutButton: `${prefix}-${testCase.cart}-cart-checkout-button`,
    giftCardListItems: (index: number) =>
      `${prefix}-${testCase.cart}-item-list-${index}`,
  },
  checkout: {
    loginForm: `${prefix}-${testCase.checkout}-login-form`,
    orderMore: `${prefix}-${testCase.checkout}-order-more-button`,
    subtotalAmount: `${prefix}-${testCase.checkout}-subtotal-amount`,
    totalAmount: `${prefix}-${testCase.checkout}-total-amount`,
    giftCardCodeField: `${prefix}-${testCase.checkout}-gift-card-code-field`,
    giftCardActionButton: `${prefix}-${testCase.checkout}-gift-card-action-button`,
    payButton: `${prefix}-${testCase.checkout}-checkout-place-order-button`,
    loginFormInput: (control: string) =>
      `${prefix}-${testCase.checkout}-login-form-${control}-field`,
    paymentMethodButtons: (paymentMethod: string) =>
      `${prefix}-${testCase.checkout}-payment-method-${paymentMethod}`,
    carBrandId: `${prefix}-${testCase.checkout}-car-brand`,
    carModelId: `${prefix}-${testCase.checkout}-car-model`,
  },
  home: {
    banner: `${prefix}-${testCase.home}-banner`,
  },
  topbar: {
    venueName: `${prefix}-${testCase.topbar}-venue-name`,
    languageDialogToggle: `${prefix}-${testCase.topbar}-language-dialog-toggle`,
    languageDialogMenuButton: `${prefix}-${testCase.topbar}-language-menu-button`,
    logInButton: `${prefix}-${testCase.topbar}-login-button`,
    cartButton: `${prefix}-${testCase.topbar}-cart-button`,
    topbarRoutes: (routeTitle: string) =>
      `${prefix}-${testCase.topbar}-${camelCaseFormat(routeTitle)}-route`,
    selectLanguageButton: (language: string) =>
      `${prefix}-${
        testCase.topbar
      }-language-select-${language.toLowerCase()}-button`,
  },
  news: {
    banner: `${prefix}-${testCase.news}-back-button`,
    tabs: `${prefix}-${testCase.news}-tabs`,
    newsContent: (index: number) =>
      `${prefix}-${testCase.news}-list-content-${index}`,
  },
  profile: {
    profileMenuButtonId: `${prefix}-${testCase.profile}-menu-button`,
    profileMenuRoutes: (routeTitle: string) =>
      `${prefix}-${testCase.profile}-menu-${camelCaseFormat(routeTitle)}-${
        routeTitle === MenuItemKey.LogOut ? 'button' : 'route'
      }`,
  },
  createPassword: {
    backButton: `${prefix}-${testCase.createPassword}-back-button`,
  },
  giftCardSuccessPage: {
    backButton: `${prefix}-${testCase.giftCardSuccessPage}-back-button`,
  },
  orderSuccessPage: {
    backButton: `${prefix}-${testCase.orderSuccessPage}-back-button`,
  },
  editInfoPopup: {
    backButton: `${prefix}-${testCase.editInfoPopup}-back-button`,
  },
  orderDetails: {
    backButton: `${prefix}-${testCase.orderDetails}-back-button`,
  },
  payNowPopup: {
    backButton: `${prefix}-${testCase.payNowPopup}-back-button`,
  },
  payNowDesktopPopup: {
    backButton: `${prefix}-${testCase.payNowDesktopPopup}-back-button`,
  },
  guest: {
    backButton: `${prefix}-${testCase.guest}-back-button`,
  },
  version: `${prefix}-get-version-button`,
};
