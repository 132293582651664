export const cleanObject = (obj: any) => {
  if (typeof obj === 'object') {
    for (let propName in obj) {
      if (!obj[propName]) {
        delete obj[propName];
      }
    }

    return obj;
  }
  return {};
};
