import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { CommonIcon } from '../../_enums/custom-icons.enum';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

import { setTimeout$ } from '../../../app.component';
import { SharedModule } from '../../_modules/shared.module';

@Component({
  selector: 'rs-counter',
  templateUrl: './rs-counter.component.html',
  styleUrls: ['./rs-counter.component.scss'],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsCounterComponent {
  @Input('quantity') public quantity = 0;
  @Input('maxQuantity') public maxQuantity: number | undefined;
  @Input('isSmall') public isSmall: boolean = false;
  @Input('isSquareBorder') public isSquareBorder: boolean = false;
  @Input('withDelete') public withDelete: boolean = true;
  @Input('withDisabling') public withDisabling = false;
  @Input('showIncreaseButton') public showIncreaseButton = true;
  @Input('deleteButtonId') public deleteButtonId?: string = '';

  @Output() quantityChanged: EventEmitter<number> = new EventEmitter();
  @Output() deleted: EventEmitter<any> = new EventEmitter();
  @Output() quantityIncreased: EventEmitter<void> = new EventEmitter();
  @Output() quantityDecreased: EventEmitter<void> = new EventEmitter();

  public readonly CommonIcon = CommonIcon;

  constructor(private readonly svgIconService: SvgIconService) {
    this.svgIconService.registerSvgIcons([
      CommonIcon.Trash,
      CommonIcon.Minus,
      CommonIcon.Plus,
    ]);
  }

  public setQuantity(event: MouseEvent, step: number = 1): void {
    event.preventDefault();
    this.quantity = this.quantity + step;
    setTimeout$(() => {
      this.quantityChanged.next(this.quantity);

      step > 0 ? this.quantityIncreased.emit() : this.quantityDecreased.emit();
    }, 0);
  }

  public deleteItem(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.quantity = 0;
    setTimeout$(() => {
      this.deleted.next(true);
      this.quantityDecreased.emit();
    }, 10);
  }

  public isButtonDisabled(): boolean {
    return this.maxQuantity !== undefined && this.quantity >= this.maxQuantity;
  }
}
