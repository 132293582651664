import { Component } from '@angular/core';

import { RsDialogComponent } from './dialog.component';
import { RsDialogActionsComponent } from './actions/dialog-actions.component';
import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsStatusDialogHeaderComponent } from './status-dialog-header/status-dialog-header.component';
import { RsDefaultFullHeightDialogHeaderComponent } from './header/default-full-height-dialog-header.component';

@Component({
  selector: 'rs-full-height-dialog',
  standalone: true,
  imports: [
    SharedModule,
    RsDialogActionsComponent,
    RsStatusDialogHeaderComponent,
    RsDefaultFullHeightDialogHeaderComponent,
  ],
  templateUrl: './full-height-dialog.component.html',
})
export class RsFullHeightDialogComponent extends RsDialogComponent {}
