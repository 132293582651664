import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { filter, first, map, Observable, Subject, takeUntil } from 'rxjs';

import { SharedModule } from '../../_modules/shared.module';
import { AddressFormComponent } from 'src/app/_shared/_components/address-form/address-form.component';
import { RsDialogComponent } from 'src/app/_shared/_rs-design/dialog/dialog.component';

import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';
import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { GuestUserData } from 'src/app/_shared/_interfaces/session.model';
import { Phone } from 'src/app/_shared/_models/common.interface';
import {
  DEFAULT_COUNTRY,
  PHONE_DEFAULT_TYPE,
} from 'src/app/_shared/_rs-design/phone-input/phone-input.constants';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

@Component({
  selector: 'rs-complete-address-popup',
  standalone: true,
  imports: [SharedModule, RsDialogComponent, AddressFormComponent],
  templateUrl: './complete-address-popup.component.html',
  styleUrls: ['./complete-address-popup.component.scss'],
})
export class CompleteAddressPopupComponent {
  public readonly isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );
  public readonly guestUserData$: Observable<GuestUserData> = this.store.select(
    CartState.guestUserData
  );

  public readonly orderPhone$: Observable<Phone | undefined> =
    this.store.select(OrderDataState.orderPhone);

  public formGroup!: FormGroup;
  public addressForm!: FormGroup;
  public phoneFormGroup!: FormGroup;

  private readonly destroyed$ = untilDestroyed();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private address: UserProfileAddress,
    private formBuilder: FormBuilder,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.subscribeOnPhoneChanges();
  }

  private initForm(): void {
    this.addressForm = this.formBuilder.group({
      country: 'US',
      address1: [this.address?.address1 || '', Validators.required],
      address2: [this.address?.address2 || ''],
      city: [this.address?.city || '', Validators.required],
      state: [this.address?.state || '', Validators.required],
      zipcode: [this.address?.zipcode || '', Validators.required],
      instructions: [this.address?.instructions || ''],
      name: [this.address?.name || ''],
      latitude: [this.address?.latitude || 0],
      longitude: [this.address?.longitude || 0],
    });

    this.phoneFormGroup = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      countryCode: [DEFAULT_COUNTRY],
      type: [PHONE_DEFAULT_TYPE, [Validators.required]],
    });

    this.formGroup = this.formBuilder.group({
      address: this.addressForm,
      phone: this.phoneFormGroup,
    });
  }

  public subscribeOnPhoneChanges(): void {
    this.isLoggedIn$
      .pipe(first(isLoggedIn => !isLoggedIn))
      .subscribe(() => this.subscribeOnPhoneNumberChanges());
  }

  public destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkIfPhoneExists(): void {
    this.destroy();

    if (!this.formGroup.value.phone?.phoneNumber) {
      this.destroy$ = new Subject<void>();
      this.subscribeOnPhoneChangesInState();
    }
  }

  private subscribeOnPhoneNumberChanges(): void {
    this.checkIfPhoneExists();

    this.formGroup.valueChanges.pipe(this.destroyed$()).subscribe(() => {
      this.checkIfPhoneExists();
    });
  }

  private subscribeOnPhoneChangesInState(): void {
    this.orderPhone$
      .pipe(takeUntil(this.destroy$), this.destroyed$(), filter(Boolean))
      .subscribe(phoneNumber => {
        this.formGroup.patchValue({ phone: phoneNumber });
      });

    this.guestUserData$
      .pipe(
        takeUntil(this.destroy$),
        map((guestUserData: GuestUserData | undefined) => guestUserData?.phone),
        filter((phone: Phone | undefined) => !!phone?.phoneNumber),
        this.destroyed$()
      )
      .subscribe((phone: Phone | undefined) => {
        this.formGroup.patchValue({ phone: phone });
      });
  }
}
