import { Pipe, PipeTransform } from '@angular/core';
import { GIFT_CARD_SHOW_SUFFIX_DIGITS } from '../_constants/card.constants';

@Pipe({ name: 'rsGiftCarNumberMask' })
export class GiftCarNumberMaskPipe implements PipeTransform {
  public transform(giftCardNumber: string | undefined): string {
    if (!giftCardNumber) {
      return '';
    }

    const giftCardNumberLength: number = giftCardNumber.length;
    const asterisksPrefixLength: number =
      giftCardNumberLength - GIFT_CARD_SHOW_SUFFIX_DIGITS;

    const showedGiftCardDigits: string =
      giftCardNumberLength > GIFT_CARD_SHOW_SUFFIX_DIGITS
        ? giftCardNumber.substring(
            giftCardNumberLength - GIFT_CARD_SHOW_SUFFIX_DIGITS,
            giftCardNumberLength
          )
        : giftCardNumber;

    return `${'*'.repeat(asterisksPrefixLength)}${showedGiftCardDigits}`;
  }
}
