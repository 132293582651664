import { Component, Input } from '@angular/core';

import { SharedModule } from '../../../../shared.module';

import { Car } from 'src/app/profile/_interfaces/car.model';

@Component({
  selector: 'rs-car-item',
  standalone: true,
  imports: [SharedModule],
  templateUrl: 'car-item.component.html',
  styleUrls: ['car-item.component.scss'],
})
export class CarItemComponent {
  @Input() car: Car | undefined;
}
