import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsDefaultFoodIconComponent } from '../rs-default-food-icon/rs-default-food-icon.component';
import { RsCounterComponent } from '../rs-counter/rs-counter.component';
import { RsBannerAlertComponent } from '../../_rs-design/banner/alert/banner-alert.component';

import { DefaultFoodIconSize } from '../../_enums/default-food-icon-size.enums';
import { SelectedModifier } from '../../_interfaces/item.model';
import { CommonOrderItemBundleItemsForView } from './common-order-item.interface';
import { DEFAULT_SEPARATOR, DEFAULT_SIZE } from '../../_constants/common';
import {
  getModifierTitleWithAmount,
  getUniqueItemModifiersNames,
} from '../../_utils/items.helper';
import { joinStringsWithSpaceBetween } from '../../_utils/common';

// Should be used in cart, checkout, success, order history screen
// - cart: CartOrderItemComponent ✅
// - checkout: CheckoutOrderItemComponent ✅
// - success: SuccessOrderItemComponent ✅
// - order-history: used ListOfItemsInOrderComponent which used CartOrderItemComponent ✅
@Component({
  selector: 'rs-common-order-item',
  standalone: true,
  imports: [
    SharedModule,
    RsDefaultFoodIconComponent,
    RsCounterComponent,
    RsBannerAlertComponent,
  ],
  templateUrl: './common-order-item.component.html',
  styleUrls: ['./common-order-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonOrderItemComponent {
  @Input() image!: string;
  @Input() name!: string;
  @Input() price: number = 0;
  @Input() amount: number = 1;
  @Input() category!: string;
  @Input() descriptionModifiers: string[] = [];
  @Input() modifiers: SelectedModifier[] = [];
  @Input() bundleItems: CommonOrderItemBundleItemsForView[] = [];
  @Input() specialRequest!: string;
  @Input() size: string = ''; // for bundles
  @Input() selectedInCart: boolean = false;
  @Input() showCheckboxes: boolean = true;
  @Input() availableToChange: boolean = true;
  @Input() showFullDescription: boolean = false;
  @Input() availableToOrder: boolean = true;
  @Input() unavailableMessage: string = '';
  @Input() blurUnavailableItem: boolean = true;

  public shortModifiersDescription: string = '';

  public readonly defaultItemSize: string = DEFAULT_SIZE;
  public readonly defaultImageSize: DefaultFoodIconSize =
    DefaultFoodIconSize.Medium;

  @Output() clickedOnItem: EventEmitter<void> = new EventEmitter();
  @Output() selected: EventEmitter<boolean> = new EventEmitter();
  @Output() amountChanged: EventEmitter<number> = new EventEmitter();

  ngOnChanges(): void {
    !this.showFullDescription && this.calculateShortModifiersDescription();
  }

  private calculateShortModifiersDescription(): void {
    const modifiersDescription: string = (this.descriptionModifiers || []).join(
      DEFAULT_SEPARATOR
    );
    const modifiers: string = getUniqueItemModifiersNames(this.modifiers || [])
      .map(modifier =>
        getModifierTitleWithAmount(modifier, this.modifiers || [])
      )
      .join(DEFAULT_SEPARATOR);
    const bundleItems: string = (this.bundleItems || [])
      .map(
        ({ name, modifiers }) =>
          `${name}: ${joinStringsWithSpaceBetween(modifiers)}`
      )
      .join(DEFAULT_SEPARATOR);
    this.shortModifiersDescription = [
      this.size && this.size !== modifiersDescription ? this.size : '',
      modifiers,
      bundleItems,
      this.specialRequest || '',
    ]
      .filter(Boolean)
      .join(DEFAULT_SEPARATOR);
  }

  public selectItem(selected: boolean): void {
    this.selected.next(selected);
  }

  public onClick(): void {
    this.availableToChange && this.clickedOnItem.next();
  }

  public changeAmount(change: 1 | -1): void {
    this.availableToChange && this.amountChanged.next(change);
  }
}
