import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import countries from '../../../assets/files/countries.json';
import { STATE_CODE_SPLITTER } from '../_constants/address.constant';
import { AddressForm, Country } from '../_interfaces/address.model';

export const getCountryAndStateCode = (
  countryInfo: string,
  stateInfo: string
): { countryCode: string | undefined; stateCode: string } => {
  const countryData = (countries as Country[])?.find(
    ({ name, code2 }) => name === countryInfo || code2 === countryInfo
  );
  const countryCode = countryData?.code2;

  if (stateInfo) {
    const stateData = (
      countryData?.states as {
        code: string;
        name: string;
        subdivision: string;
      }[]
    )?.find(({ code, name }) => {
      return (
        code === stateInfo ||
        name === stateInfo ||
        getStateCode(countryCode, code) === stateInfo
      );
    });
    stateInfo = stateData ? getStateCode(countryCode, stateData.code) : '';
  }

  return {
    countryCode,
    stateCode: stateInfo,
  };
};

export const getStateCode = (countryCode = '', stateCode = ''): string => {
  return `${countryCode}${STATE_CODE_SPLITTER}${stateCode}`;
};

export const getAddressesListWithDefaultFirst = (
  addresses: UserProfileAddress[] | null
): UserProfileAddress[] => {
  if (!addresses?.length) {
    return [];
  }

  const orderedAddresses: UserProfileAddress[] = [];

  for (const address of addresses) {
    address.isDefault
      ? orderedAddresses.unshift(address)
      : orderedAddresses.push(address);
  }

  return orderedAddresses;
};

export const getUSAddresses = (
  addresses: UserProfileAddress[] | null | undefined
): UserProfileAddress[] => {
  if (!addresses?.length) {
    return [];
  }

  const USCountry = countries.find(({ code2 }) => code2 === 'US');

  return USCountry
    ? addresses.filter(
        ({ country }) =>
          country === USCountry.code2 ||
          country === USCountry.code3 ||
          country === USCountry.name
      )
    : addresses;
};

export const isAddressFilled = (address: UserProfileAddress): boolean =>
  !!(
    address.address1 &&
    address.address2 &&
    address.city &&
    address.zipcode &&
    address.state
  );

export const getFullAddressDescription = (
  address: AddressForm | UserProfileAddress | undefined | null,
  withAddress2: boolean = false
): string => {
  if (!address) {
    return '';
  }

  return [
    address.address1 || '',
    withAddress2 ? address.address2 || '' : '',
    address.city || '',
    address.state || '',
    address.zipcode || '',
  ]
    .filter(part => !!part)
    .reduce((part, result) => (part ? result + ', ' + part : result), '');
};
