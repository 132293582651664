import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  RsRadioButtonBoxComponent,
  RsRadioButtonBoxGroupComponent,
} from './radio-button-box.component';

const components = [RsRadioButtonBoxComponent, RsRadioButtonBoxGroupComponent];

@NgModule({
  imports: [CommonModule, ...components],
  exports: [...components],
})
export class RadioButtonBoxModule {}
