<rs-dialog
  rs-dialog-style="default"
  rs-show-close-button="true"
  [dir]="'languageDirection' | translate"
>
  <ng-container [ngTemplateOutlet]="rsDialogBody"></ng-container>

  <ng-container rs-dialog-actions-direction="row">
    <button rs-style="text" (click)="copy()"><span>Copy</span></button>
  </ng-container>
</rs-dialog>

<ng-template #rsDialogBody>
  <ul class="d-flex flex-column rs-version__column">
    <li class="d-flex rs-version__line">
      <mat-icon [svgIcon]="clockIcon"></mat-icon>

      <p class="d-flex rs-version__desciption m-0">
        <span class="fw-bold rs-version__label">Date:</span>
        <span
          class="d-flex align-items-center rs-version__text rs-version__desciption"
        >
          <span class="fw-bold">{{ version.iso }}</span>
          <span class="rs-version__hint">({{ version.relative }})</span>
        </span>
      </p>
    </li>

    <li class="d-flex rs-version__line">
      <mat-icon [svgIcon]="starIcon"></mat-icon>

      <div class="d-flex rs-version__desciption m-0">
        <span class="fw-bold rs-version__label">Branch:</span>
        <div class="d-flex flex-column rs-version__text rs-version__desciption">
          <span class="fw-bold">{{ version.branch }}</span>
          <span>{{ version.hash }}</span>
        </div>
      </div>
    </li>

    <li class="d-flex rs-version__line">
      <mat-icon [svgIcon]="bookIcon"></mat-icon>

      <div class="d-flex rs-version__desciption m-0">
        <span class="fw-bold rs-version__label">Message:</span>
        <div class="d-flex flex-column rs-version__text">
          <span>{{ version.message }}</span>
        </div>
      </div>
    </li>
  </ul>
</ng-template>
