import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import {
  combineLatest,
  filter,
  firstValueFrom,
  map,
  Observable,
  tap,
} from 'rxjs';

import { SharedModule } from '../../_modules/shared.module';
import { CalculateRemainingTotalAfterGiftCardsPipe } from '../../_pipes/calc-tips.pipe';

import { CartEntity, CartState } from '../../_ngxs/cart.state';
import { SessionState } from '../../_ngxs/authentication.state';
import { ProfileState } from '../../_ngxs/profile.state';
import { VenueOrderSettingsState } from '../../_ngxs/venue-order-settings.state';
import { OpenDineInChangePaymentmethodDialog } from '../../_ngxs/dialog.actions';
import {
  CalculateCheck,
  SelectCardForOrder,
  SelectGiftCardForOrder,
  SetCompletePaymentMethod,
  SetIsGiftCardCoverTotal,
  SetPaymentMethod,
} from '../../_ngxs/cart.actions';
import { GetProfileGiftCards } from '../../_ngxs/profile.actions';

import { PaymentMethods } from '../../_enums/payment-methods.enum';
import { OrderItemType } from '../../_enums/order-item-type.enum';
import { PaymentIcons } from '../../_enums/digital-storefront-icons.enum';

import { Card } from 'src/app/profile/_interfaces/payment.model';
import { Tips } from '../../_interfaces/item.model';
import { PaymentViewDescription } from '../../_interfaces/payment.model';
import { GiftCard } from '../../_interfaces/gift-card.model';

import { PAYMENT_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';

import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { getAvailablePaymentOptions } from '../../_utils/payment.utils';
import { untilDestroyed } from '../../_utils/until-destroyed';

@Component({
  selector: 'rs-paying-with',
  templateUrl: './paying-with.component.html',
  styleUrls: ['./paying-with.component.scss'],
  standalone: true,
  imports: [SharedModule],
  providers: [CalculateRemainingTotalAfterGiftCardsPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayingWithComponent {
  @Input() hideTips: boolean = false;

  public readonly selectedPaymentMethod$ = this.store.select(
    CartState.paymentMethod
  );
  public readonly completePaymentMethod$ = this.store.select(
    CartState.completePaymentMethod
  );
  public readonly isGiftCardCoverTotal$ = this.store.select(
    CartState.isGiftCardCoverTotal
  );
  public readonly selectedGiftCard$ = this.store.select(
    CartState.selectedGiftCard
  );
  public readonly selectedPaymentCard$ = this.store.select(
    CartState.selectedPaymentCard
  );
  public readonly isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );
  public readonly items$: Observable<CartEntity[]> = this.store
    .select(CartState.cartFilling)
    .pipe(
      tap(
        (data: { items: CartEntity[]; cartItemsType: OrderItemType | null }) =>
          (this.itemType = data.cartItemsType)
      ),
      map(({ items }: { items: CartEntity[] }) => items)
    );

  public tips!: Tips;
  public itemType: OrderItemType | null = null;
  public selectedPaymentMethod: PaymentMethods | undefined =
    PaymentMethods.card;
  public completePaymentMethod!: PaymentMethods | undefined;
  public paymentMethods = PaymentMethods;

  private paymentSelected!: boolean;
  private availableMethodsBySettings: PaymentViewDescription[] = [];

  private readonly destroy$ = untilDestroyed();

  @Output() paymentHandled: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(PLATFORM_ID)
    private readonly platformId: string,
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly calculateRemainingTotalAfterGiftCardPipe: CalculateRemainingTotalAfterGiftCardsPipe,
    private readonly actions$: Actions,
    private readonly svgIconService: SvgIconService
  ) {}

  public ngOnInit(): void {
    this.registerIcons();
    this.getGiftCards();
    this.getDefaultTips();
    this.getPaymentsMethods();

    // TODO move in suitable part
    this.store.dispatch([
      new CalculateCheck(
        this.itemType === OrderItemType.gift_card,
        'dine-in',
        false
      ),
    ]);
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      Object.values(PaymentIcons),
      PAYMENT_ICONS_DIRECTORY
    );
  }

  public openEditPaymentDialog(): void {
    this.store.dispatch(new OpenDineInChangePaymentmethodDialog());
  }

  private getDefaultTips(): void {
    this.store
      .select(CartState.tips)
      .pipe(this.destroy$())
      .subscribe((tips: Tips) => {
        this.tips = tips;

        this.changeDetectorRef.markForCheck();
      });
  }

  private getPaymentsMethods(): void {
    combineLatest([this.selectedPaymentMethod$, this.selectedPaymentCard$])
      .pipe(this.destroy$())
      .subscribe(([selectedPaymentMethod, selectedPaymentCard]) => {
        const isLoggedIn: boolean = this.store.selectSnapshot(
          SessionState.isLoggedIn
        );

        this.selectedPaymentMethod = selectedPaymentMethod;

        isLoggedIn &&
          this.handlePaymentSelection(
            (selectedPaymentMethod === PaymentMethods.card &&
              !!selectedPaymentCard) ||
              selectedPaymentMethod !== PaymentMethods.card
          );

        // first open: selected payment method card but there is no information for complete payment
        !this.paymentSelected && this.selectPaymentMethodOnFirstOpen();

        this.changeDetectorRef.detectChanges();
      });

    this.completePaymentMethod$
      .pipe(this.destroy$())
      .subscribe((completePaymentMethod: PaymentMethods | undefined) => {
        this.completePaymentMethod = completePaymentMethod;
        this.changeDetectorRef.detectChanges();
      });
  }

  public selectPaymentMethodOnFirstOpen(): void {
    this.availableMethodsBySettings = this.getAvailableMethodsBySettings();

    const isLoggedIn: boolean = this.store.selectSnapshot(
      SessionState.isLoggedIn
    );
    const isAvailablePayByGiftCard: boolean =
      !!this.availableMethodsBySettings.find(
        ({ placeholder }) => placeholder === PaymentMethods.gift_card
      );

    if (isLoggedIn && isAvailablePayByGiftCard) {
      // if signed in
      // https://rockspoon.atlassian.net/wiki/spaces/RO/pages/2043346945/Digital+Storefront#UI-%E2%80%93-Screen-Functions-and-Requirements-(PM%2F-Mandatory)
      // if user has gift card we need to select it (with existing card if not enought balance)
      this.handleFirstOpenLoggedInCase();
    } else {
      // if guest or no gift card or there is no ability to pay by gift card/card
      // we choose Apple Pay or Google Pay
      this.handleNoGiftCardCase();
    }
  }

  private async handleFirstOpenLoggedInCase(): Promise<void> {
    // Note: we need wait until we get gift cards
    // selectsnapshhot can be executed before gift cards are in store
    await firstValueFrom(
      this.actions$.pipe(ofActionSuccessful(GetProfileGiftCards))
    );

    const giftCard: GiftCard | undefined = this.store.selectSnapshot(
      ProfileState.giftCards
    )[0];
    const isGiftCardCoverTotal: boolean = giftCard
      ? this.isGiftCardCoverTotal(giftCard)
      : false;

    // if user has gift card and pay by gift card is available
    // set gift card as payment
    !!giftCard && isGiftCardCoverTotal && this.selectGiftCard(giftCard);

    if (!this.paymentSelected && giftCard && !isGiftCardCoverTotal) {
      const isAvailablePayByCard: boolean =
        !!this.availableMethodsBySettings.find(
          ({ placeholder }) => placeholder === PaymentMethods.card
        );

      const defaultCard: Card | undefined = this.store.selectSnapshot(
        ProfileState.defaultCard
      );

      // if user has gift card, card and pay by gift card and card is available
      isAvailablePayByCard &&
        defaultCard &&
        this.selectGiftCardAndDefaultCard(giftCard, defaultCard);
    }

    // if no gift card or there is no ability to pay by gift card/card
    // we choose Apple Pay or Google Pay
    this.handleNoGiftCardCase();
  }

  private handleNoGiftCardCase(): void {
    // we need have them separate because after first method call
    // if payment selected, we set paymentSelected as true
    // if payment is not selected, we try to select the next one
    !this.paymentSelected && this.selectApplePay();
    !this.paymentSelected && this.selectGooglePay();
  }

  private selectGiftCard(giftCard: GiftCard): void {
    this.handlePaymentSelection(true);

    this.store.dispatch([
      new SetPaymentMethod(PaymentMethods.gift_card),
      new SelectGiftCardForOrder(giftCard),
    ]);
  }

  private selectGiftCardAndDefaultCard(
    giftCard: GiftCard,
    defaultCard: Card
  ): void {
    this.handlePaymentSelection(true);

    this.store.dispatch([
      new SetCompletePaymentMethod(PaymentMethods.card),
      new SelectCardForOrder(defaultCard),
      new SetPaymentMethod(PaymentMethods.gift_card),
      new SelectGiftCardForOrder(giftCard),
    ]);
  }

  private selectApplePay(): void {
    const isAvailablePayByApple: boolean =
      !!this.availableMethodsBySettings.find(
        ({ placeholder }) => placeholder === PaymentMethods.apple_pay
      );
    const supportApplePay =
      isPlatformBrowser(this.platformId) &&
      (window as any).ApplePaySession &&
      (window as any).ApplePaySession?.canMakePayments();

    if (isAvailablePayByApple && supportApplePay) {
      this.handlePaymentSelection(true);

      this.store.dispatch(new SetPaymentMethod(PaymentMethods.apple_pay));
    }
  }

  private selectGooglePay(): void {
    const isAvailablePayByGoogle: boolean =
      !!this.availableMethodsBySettings.find(
        ({ placeholder }) => placeholder === PaymentMethods.google_pay
      );

    if (isAvailablePayByGoogle) {
      this.handlePaymentSelection(true);

      this.store.dispatch([new SetPaymentMethod(PaymentMethods.google_pay)]);
    }
  }

  private getAvailableMethodsBySettings(): PaymentViewDescription[] {
    const availablePaymentTypes: PaymentMethods[] = this.store.selectSnapshot(
      VenueOrderSettingsState.availablePaymentMethods
    );

    return getAvailablePaymentOptions(availablePaymentTypes);
  }

  private getGiftCards(): void {
    this.isLoggedIn$
      .pipe(
        filter((isLoggedIn: boolean) => isLoggedIn),
        this.destroy$()
      )
      .subscribe(() => {
        this.store.dispatch(new GetProfileGiftCards());
      });
  }

  private isGiftCardCoverTotal(selectedGiftCard: GiftCard): boolean {
    const isGiftCardCoverTotal =
      this.calculateRemainingTotalAfterGiftCardPipe.transform(
        selectedGiftCard.currentBalance!
      ) <= 0;

    this.store.dispatch(new SetIsGiftCardCoverTotal(isGiftCardCoverTotal));

    return isGiftCardCoverTotal;
  }

  private handlePaymentSelection(paymentSelected: boolean): void {
    this.paymentSelected = paymentSelected;
    this.paymentHandled.next(paymentSelected);
  }
}
