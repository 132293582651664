import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { RsTextAreaComponent } from './text-area.component';
import { RsClickOutsideDirective } from 'src/app/_shared/_directives/click-outside.directive';

import { MaterialModule } from 'src/app/_shared/_modules/material.module';
import { RsPipesModule } from 'src/app/_shared/_modules/pipes.module';

@NgModule({
  declarations: [RsTextAreaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RsClickOutsideDirective,
    MaterialModule,
    TranslateModule,
    RsPipesModule,
  ],
  exports: [RsTextAreaComponent],
})
export class RsTextAreaModule {}
