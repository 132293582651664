<rs-card-horizontal
  *ngIf="address"
  [active]="activeCard"
  (click)="selectAddress(address)"
>
  <rs-card-horizontal-title>
    {{ address.name }}
  </rs-card-horizontal-title>

  <rs-card-horizontal-line>
    <span
      >{{ address.address1 }}
      <span *ngIf="address.address2">, {{ address.address2 }}</span>
      <span *ngIf="address.city">, {{ address.city }}</span>
      <span *ngIf="address.state">, {{ address.state }}</span>
      <span *ngIf="address.zipcode">, {{ address.zipcode }}</span>
    </span>
  </rs-card-horizontal-line>
</rs-card-horizontal>
