import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';

import { Store } from '@ngxs/store';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Observable,
  Subscription,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';

import { RsInputModule } from 'src/app/_shared/_rs-design/input/input.module';
import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsFoodItemComponent } from 'src/app/_shared/_components/food-item/rs-food-item.component';
import { WineDetailsModule } from 'src/app/_shared/_components/wine-details/wine-details.module';
import { ItemAllergensComponent } from 'src/app/_shared/_components/allergens-dietary-restrictions/item-allergens/item-allergens/item-allergens.component';
import { RsCounterComponent } from 'src/app/_shared/_components/rs-counter/rs-counter.component';
import { WineReviewsComponent } from 'src/app/_shared/_components/wine-reviews/wine-reviews.component';
import { CountryFlagComponent } from 'src/app/_shared/_components/country/country-flag.component';
import { RsBannerAlertComponent } from 'src/app/_shared/_rs-design/banner/alert/banner-alert.component';
import { RsItemDescriptionComponent } from 'src/app/_shared/_components/rs-description/rs-item-description.component';
import { RsDefaultFoodIconComponent } from 'src/app/_shared/_components/rs-default-food-icon/rs-default-food-icon.component';
import { ItemsUnavailableMessageComponent } from 'src/app/_shared/_components/item-unavailable-message/item-unavailable-message.component';

import {
  GetCountryCodeByCode3Pipe,
  GetCountryCodeByNamePipe,
  GetCountryNameByCode3Pipe,
  GetCountryNameByCodePipe,
} from 'src/app/_shared/_components/country/get-country-code.pipe';
import { WineReviewsSummaryPipe } from 'src/app/_shared/_components/wine-reviews/wine-reviews-summary.pipe';
import { GetRouterSegments } from 'src/app/_shared/_pipes/router-segments.pipe';
import { RsIsOrderWarning } from 'src/app/_shared/_pipes/menu.pipe';

import { ItemDetailsDialogModule } from 'src/app/_shared/_components/item-details-dialog/item-details-dialog.module';

import {
  ClearItem,
  GetItemDetails,
  GetItemModifiers,
  GetItemNutritionalValues,
  SetItemData,
} from 'src/app/_shared/_ngxs/item.actions';
import {
  HideSpinner,
  ShowSpinner,
} from 'src/app/_shared/_components/spinner/spinner.state';
import {
  AddToFavorites,
  RemoveFromFavorites,
} from 'src/app/_shared/_ngxs/profile.actions';
import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';
import {
  AddToCart,
  ClearGiftCards,
  SetCartEditing,
} from 'src/app/_shared/_ngxs/cart.actions';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';
import { VenueOrderSettingsState } from 'src/app/_shared/_ngxs/venue-order-settings.state';
import { ItemState } from 'src/app/_shared/_ngxs/item.state';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';
import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';
import {
  OpenCartDialog,
  OpenConfirmationDialog,
} from 'src/app/_shared/_ngxs/dialog.actions';
import { GetVenueMenusAvailability } from 'src/app/_shared/_ngxs/venue.actions';
import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { FilteredMenuState } from 'src/app/_shared/_ngxs/filtered-menu.state';

import { BundleType } from 'src/app/_shared/_enums/item.enum';
import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';
import { ItemCategory } from 'src/app/_shared/_enums/item-category.enum';
import { SessionStorageKeys } from 'src/app/_shared/_enums/session-storage-keys.enum';
import { DeliveryType, OrderType } from 'src/app/_shared/_enums/order.enum';
import { LocaleStorageKeys } from 'src/app/_shared/_enums/local-storage-keys.enum';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import {
  ItemData,
  ItemDetails,
  Modifier,
  SelectedModifier,
  SizesAndPrice,
  StateItem,
} from 'src/app/_shared/_interfaces/item.model';
import {
  Menu,
  Section,
  VisibleMenus,
} from 'src/app/_shared/_interfaces/menu.model';
import { Item } from '../../_shared/_interfaces/item.model';
import { QRSettingsType } from 'src/app/_shared/_interfaces/qrSettings.model';
import { GiftCard } from 'src/app/_shared/_interfaces/gift-card.model';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { copy, isDineIn } from 'src/app/_shared/_utils/common';
import { MenusService } from 'src/app/_services/menus.service';
import { NotificationService } from 'src/app/_shared/_services/notification.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { SessionStorageEngine } from 'src/app/_services/session-storage.service';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-item-details',
  templateUrl: 'item-details.component.html',
  styleUrls: ['item-details.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    ItemDetailsDialogModule,
    RsInputModule,
    CountryFlagComponent,
    GetCountryCodeByNamePipe,
    GetCountryCodeByCode3Pipe,
    GetCountryNameByCode3Pipe,
    GetCountryNameByCodePipe,
    RsFoodItemComponent,
    WineDetailsModule,
    ItemAllergensComponent,
    RsCounterComponent,
    WineReviewsSummaryPipe,
    WineReviewsComponent,
    RsItemDescriptionComponent,
    RsBannerAlertComponent,
    RsIsOrderWarning,
    RsDefaultFoodIconComponent,
    ItemsUnavailableMessageComponent,
  ],
  providers: [GetRouterSegments],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemDetailsComponent {
  @ViewChild('itemWrapper') itemWrapper!: ElementRef;

  @Input() cameFromCart: boolean = false;

  @Output() goBackOutSide: EventEmitter<() => void> = new EventEmitter<
    () => void
  >();

  public readonly item$: Observable<ItemDetails | null> = this.store.select(
    ItemState.item
  );
  public readonly itemHasAllergens$: Observable<boolean | null> = this.store
    .select(ItemState.allergens)
    .pipe(map(allergens => !!allergens?.length));
  public readonly calories$: Observable<number | undefined> = this.store.select(
    ItemState.calories
  );
  public readonly itemData$: Observable<ItemData | null> = this.store.select(
    ItemState.itemData
  );
  public readonly isCartEditing$: Observable<boolean> = this.store.select(
    CartState.isEditing
  );
  public readonly isThereAnyAvailableOrderMethods$ = this.store.select(
    VenueOrderSettingsState.isThereAnyAvailableOrderMethod
  );
  public readonly menus$: Observable<Menu[]> = this.store.select(
    FilteredMenuState.menus
  );
  public readonly selectedOrderPeriod$ = this.store.select(
    OrderDataState.selectedOrderPeriod
  );
  public readonly orderType$ = this.store.select(OrderDataState.orderType);

  public readonly isEarlyAdopter =
    this.store.selectSnapshot(VenueState.formattedVenueName) ===
    'zaya-thai-pantry-llc';

  public isVisibleFavoriteButton$ = new BehaviorSubject<boolean>(false);
  public isFavorite$ = new BehaviorSubject<boolean>(false);
  public deliveryType!: DeliveryType;
  public quantity: number = 1;
  public selectedSizePrice = 0;
  public maxItemAmount = 1;
  public selectedSizeId = '';
  public selectedSizeIndex = 0;
  public specialRequest: FormControl = new FormControl(null);
  public menuId!: string;
  public menuSectionId!: string;
  public metadata!: any;
  public disableOrdering = false;
  public selectedAllBundleItems: boolean = true;
  public allBundleItemsModifiersValid: boolean = true;
  public isMobile: boolean = false;
  public isDineInPage: boolean = false;
  public isOnlyDineItem: boolean = false;
  public isOnlyDineInMenu: boolean = false;
  public isDineItem: boolean = false;
  public readonly BundleType = BundleType;
  public menus: Menu[] = [];
  public currentMenu: Menu | undefined;
  public currentSection: Section | undefined;
  public currentItem: Item | undefined;
  public data: ItemData | null = null;
  public cameFromFavorites: boolean = false;
  public showRating: boolean = false;

  public itemForm = new FormGroup({
    size: new FormControl<SizesAndPrice | null>(null, Validators.required),
    modifiers: new FormControl<SelectedModifier[]>([]),
    bundleItems: new FormControl<
      { bundleItemId: string; bundleSectionId: string }[]
    >([]),
  });

  private currentCartItemId: string = '';
  private menuIdFromPath: string | undefined;
  private menuSectionIdFromPath: string | undefined;
  private itemIdFromPath: string | undefined;
  private sectionIdFromPath: string | undefined;
  private currentMenuType!: QRSettingsType | undefined;
  private subscription!: Subscription;
  private itemSubscription!: Subscription;

  public readonly backIcon = CommonIcons.Back;
  public readonly exportIcon = CommonIcons.Export;
  public readonly heartIcon = CommonIcons.Heart;
  public readonly heartOutlineIcon = CommonIcons.HeartOutline;
  public readonly starIcon = CommonIcons.Star;
  private readonly destroyed$ = untilDestroyed();

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      menuId: string;
      itemId: string;
      isMobile: boolean;
      menu?: QRSettingsType;
    },
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly notifierService: NotificationService,
    private readonly translationService: TranslateService,
    private readonly router: Router,
    private readonly matDialog: MatDialog,
    private readonly dialogRef: MatDialogRef<ItemDetailsComponent>,
    private readonly sessionStorage: SessionStorageEngine,
    private readonly localStorage: LocalStorageService,
    private readonly menuService: MenusService,
    private readonly rsGetRouterSegments: GetRouterSegments,
    private readonly svgIconService: SvgIconService
  ) {}

  public ngOnInit(): void {
    const { suggestions } = this.activatedRoute.snapshot.queryParams;

    this.cameFromCart = !!suggestions;

    this.registerIcons();
    this.store.dispatch(new ClearItem());
    this.getMenu();
    this.subscribeOnRouteParamsChanges();
    this.setLocalStorageIfCameFromProfile();
    this.goBackOutSide.emit(this.goBack.bind(this));
    this.data?.item && this.subscribeOnFavoriteSettings();
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new SetCartEditing(false));

    if (this.itemSubscription) {
      this.itemSubscription.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public goBack(): void {
    let route: string = '';
    const url = this.router.url;
    const urlSegments: UrlSegment[] = this.rsGetRouterSegments.transform(url);
    const venueName: string = urlSegments[0].path;
    const cameFrom: string | null = this.localStorage.getItem(
      LocaleStorageKeys.cameFrom
    );

    const { isMobile, menuId: menuIdDialog } = this.dialogData;

    const menuId: string =
      isMobile && menuIdDialog
        ? menuIdDialog
        : !cameFrom || !cameFrom.includes('/profile/favorites')
        ? urlSegments[urlSegments.length - 2].path
        : '';

    if (url.includes('/item')) {
      switch (true) {
        case cameFrom?.includes('/profile/favorites'):
          this.localStorage.removeItem(LocaleStorageKeys.cameFrom);
          route = 'profile/favorites';
          break;
        case this.isDineInPage:
          route = 'dine-in/menus/';
          break;
        case url.includes('cartItemId'):
          route = 'order/';
          break;
        default:
          route = 'menu/';
      }
    } else {
      const { isMobile, menu } = this.dialogData;

      isMobile && menu === 'dine-in'
        ? (route = 'dine-in/menus/')
        : (route = 'order/');

      this.cameFromFavorites && (route = 'profile/favorites/');
    }

    this.router.navigate([`/${venueName}/${route}${menuId}`], {
      relativeTo: this.activatedRoute,
    });

    this.closeItemDetails();

    this.cameFromCart && this.store.dispatch(new OpenCartDialog());
  }

  public share(): void {
    if (navigator.share) {
      navigator
        .share({
          title: this.currentItem?.customerFacingName || this.currentItem?.name,
          text: `Check out ${
            this.currentItem?.customerFacingName || this.currentItem?.name
          } - Seems Yummy!!!`,
          url: location.href,
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error));
    } else {
      navigator.clipboard.writeText(location.href);
      this.notifierService.showSuccess(
        this.translationService.instant('DELIVERY_METHOD.Link copied')
      );
    }
  }

  public setFavorite(event: Event): void {
    event.stopPropagation();
    console.log(this.data?.item);

    this.data?.item?.itemId &&
      this.store.dispatch(
        this.isFavorite$.value
          ? new RemoveFromFavorites(
              this.data.item?.itemId,
              this.menuId,
              this.data?.menuSectionId
            )
          : new AddToFavorites(
              this.data.item?.itemId,
              this.menuId,
              this.data!.menuSectionId!
            )
      );
  }

  public checkIfDineIn(): void {
    if (this.document?.location?.href) {
      const url: string = this.document.location.href;

      this.isDineInPage = isDineIn(url);
    }

    this.item$.pipe(this.destroyed$()).subscribe(item => {
      const itemDetailsAvailableFor: string[] = item?.availableFor || [];

      this.isDineItem = isDineIn(itemDetailsAvailableFor);

      this.isOnlyDineItem =
        itemDetailsAvailableFor.length === 1 && this.isDineItem;
    });
  }

  public reactOnDeleteItem(): void {
    this.quantity = 0;
  }

  public reactOnSizeSelection({
    size,
    index,
  }: {
    size: SizesAndPrice;
    index: number;
  }): void {
    const singleMenuPrices = size.menuAttributes.length;

    this.maxItemAmount = size.comboAttributes?.itemsQuantity || 1;

    if (!singleMenuPrices || !this.menuId) {
      this.selectedSizePrice = size.menuAttributes[0].price;
    } else {
      const currentMenuAttribute = size.menuAttributes.find(
        ({ menuId }) => this.menuId === menuId
      );

      if (currentMenuAttribute) {
        this.selectedSizePrice = currentMenuAttribute.price;
        this.menuSectionId = currentMenuAttribute.menuSectionId;
        this.data!.menuSectionId = this.menuSectionId;
      } else {
        this.selectedSizePrice = size.menuAttributes[0].price;
        this.menuSectionId = size.menuAttributes[0].menuSectionId;
        this.data!.menuSectionId = this.menuSectionId;
      }
    }

    this.selectedSizeId = size.id;
    this.selectedSizeIndex = index;
    this.itemForm.controls.bundleItems.reset([]);

    this.changeDetectorRef.markForCheck();
  }

  public reactOnAddToCart(): void {
    const existingGiftCardsInCart: GiftCard[] = this.store.selectSnapshot(
      CartState.giftCards
    );

    if (existingGiftCardsInCart.length) {
      this.store
        .dispatch(
          new OpenConfirmationDialog(
            this.translationService.instant('CART.confirm_question')
          )
        )
        .pipe(first(), this.destroyed$())
        .subscribe(() => {
          this.matDialog
            .getDialogById(MatDialogId.confirmation)
            ?.afterClosed()
            .pipe(first(confirmed => confirmed))
            .subscribe(() => {
              this.store.dispatch(new ClearGiftCards());
              this.addItemsToCart();
            });
        });
    } else {
      this.addItemsToCart();
    }
  }

  public reactOnQuantityChanged(quantity: number): void {
    this.quantity = quantity;
  }

  public closeItemDetails(): void {
    this.store.dispatch(new ClearItem());
    this.dialogData.isMobile && this.dialogRef.close();
  }

  public reactOnBundleItemsModifiersValidation(
    allBundleItemsModifiersValid: boolean
  ): void {
    this.allBundleItemsModifiersValid = allBundleItemsModifiersValid;
  }

  public reactOnAllBundleItemsSelected(allBundleItemsSelected: boolean): void {
    this.selectedAllBundleItems = allBundleItemsSelected;
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [
        this.backIcon,
        this.exportIcon,
        this.heartIcon,
        this.starIcon,
        this.heartOutlineIcon,
      ],
      NEW_ICONS_DIRECTORY
    );
  }

  private subscribeOnRouteParamsChanges(): void {
    this.activatedRoute.params.pipe(this.destroyed$()).subscribe(() => {
      const { menu, section } = this.activatedRoute.snapshot.queryParams;
      const {
        isMobile,
        menuId: menuIdDialog,
        itemId: itemIdDialog,
      } = this.dialogData;
      const { itemId: itemIdQuery, menuId: menuIdQuery } =
        this.activatedRoute.snapshot.params;
      let itemId: string = '';
      let menuId: string = '';

      if (isMobile) {
        itemId = itemIdDialog;
        menuId = menuIdDialog;
      } else {
        itemId = itemIdQuery;
        menuId = menuIdQuery;
      }

      this.currentMenuType = menu;

      this.store.dispatch([
        new ShowSpinner(),
        new GetItemDetails(itemId),
        new GetItemNutritionalValues(itemId),
        new GetItemModifiers(itemId),
      ]);
      this.menuIdFromPath = menuId;
      this.itemIdFromPath = itemId;
      this.sectionIdFromPath = section;
      this.getCurrentMenu();

      this.item$.pipe(filter(item => !!item)).subscribe(item => {
        this.store.dispatch(new HideSpinner());

        if (item) {
          this.menuSectionIdFromPath =
            item.sizesAndPrices[0].menuAttributes.find(
              (attributes: any) => attributes.menuId === menuId
            )?.menuSectionId ||
            item.sizesAndPrices[0].menuAttributes[0].menuSectionId;
        }
      });
    });
  }

  private subscribeOnFavoriteSettings(): void {
    const itemId = this.data?.item.itemId;

    itemId &&
      combineLatest(
        this.store.select(
          ProfileState.isFavoriteItem(
            itemId,
            this.menuId,
            this.sectionIdFromPath
          )
        ),
        this.store.select(SessionState.isLoggedIn)
      )
        .pipe(this.destroyed$())
        .subscribe(([isFavorite, isLoggedIn]) => {
          this.isVisibleFavoriteButton$.next(isLoggedIn);
          this.isFavorite$.next(isLoggedIn && isFavorite);
        });
  }

  private getCurrentMenu(): void {
    const menu: Menu | undefined = this.menus?.find(
      ({ menuId }: Menu) => this.menuIdFromPath === menuId
    );

    this.currentMenu = menu;

    if (!menu) {
      return;
    }

    let sections = this.sectionIdFromPath
      ? menu.sections.filter(
          ({ sectionId }) => sectionId === this.sectionIdFromPath
        )
      : menu.sections;

    !sections.length && (sections = menu.sections);

    this.currentSection = sections.find(
      ({ items }) =>
        !!items.find(({ itemId }) => itemId === this.itemIdFromPath)
    );

    const currentItem: Item | undefined = this.currentSection?.items.find(
      ({ itemId }) => itemId === this.itemIdFromPath
    );

    !!currentItem && (this.currentItem = currentItem);

    this.isOnlyDineInMenu =
      this.currentMenu?.availableFor.length === 1 &&
      this.currentMenu?.availableFor[0] === OrderType.DineIn;

    this.getData();
    this.changeDetectorRef.markForCheck();
  }

  private getMenu(): void {
    this.menus$.pipe(this.destroyed$()).subscribe(menus => {
      this.menus = menus;
    });
  }

  private getData(): void {
    this.store.dispatch(
      new SetItemData({
        item: this.currentItem!,
        menuId: this.menuIdFromPath!,
        // please don't change it, otherwise it broke open edit item
        menuSectionId: this.menuSectionIdFromPath,
      })
    );

    !!this.itemSubscription && this.itemSubscription.unsubscribe();

    this.itemSubscription = this.itemData$
      .pipe(map(itemData => copy<ItemData>(itemData)))
      .subscribe(itemData => {
        if (itemData) {
          if (this.menuIdFromPath && this.menuSectionId) {
            itemData.menuId = this.menuIdFromPath;
            // please don't change it, otherwise it broke open edit item
            itemData.menuSectionId = this.menuSectionIdFromPath;
          }

          this.data = copy<ItemData>(itemData);
          this.menuId = this.data.menuId;
          this.metadata = this.data.metadata || null;

          if (!('isCurrentMenuAvailable' in this.data)) {
            this.store.dispatch(new GetVenueMenusAvailability());
            this.isCurrentMenuAvailable();
          }

          !('disableOrdering' in this.data) && this.isOrderingDisabled();

          this.disableOrdering =
            this.currentMenuType === 'menu'
              ? true
              : this.data?.disableOrdering!;

          if (this.disableOrdering === undefined) {
            this.disableOrdering = true;
          }

          if (this.metadata?.sizeId) {
            this.selectedSizeId = this.metadata?.sizeId;
          }

          this.initializeSubscriptions();
          this.checkIfDineIn();
        }
      });
  }

  /*
   this method used for case when impossible to get menu id for items list and send here as a parameter
   however, it is important to know for which menu the item is to show correct price in the button to add it
   to cart.
  */
  private getMenuIdIfNotDefined(defaultSizesAndPrices: SizesAndPrice): void {
    const menuAttributes = defaultSizesAndPrices.menuAttributes;
    const minimumPriceData =
      this.data!.item.prices?.[0].price?.amount ||
      this.data!.item?.price?.amount ||
      0;

    if (minimumPriceData) {
      const currentMenuAttributes = menuAttributes.find(
        ({ price }) => price === minimumPriceData
      );

      if (currentMenuAttributes) {
        this.menuId = currentMenuAttributes.menuId;
        this.menuSectionId = currentMenuAttributes.menuSectionId;
        this.data!.menuSectionId = this.menuSectionId;
      }
    }
  }

  private addItemsToCart(): void {
    const cartItems = this.store.selectSnapshot(CartState.items);
    const selectedInCart = cartItems.find(
      cartItem => cartItem.cartItemId === this.currentCartItemId
    )?.selectedInCart;
    const itemCategory = this.store.selectSnapshot(ItemState.item)
      ?.category as ItemCategory;

    this.store.dispatch(
      new AddToCart(
        copy<StateItem>({
          ...this.itemForm.value,
          ...this.data,
          specialRequest: this.specialRequest.value,
          taxRule: this.store.selectSnapshot(ItemState.item)!?.taxRule,
          quantity: this.quantity,
          cartItemId: this.currentCartItemId || uuidv4(),
          selectedInCart: selectedInCart,
          // menuSectionId: this.menuSectionIdFromPath,
          menuSectionId: this.menuSectionId || this.menuSectionIdFromPath,
          category: itemCategory,
        }),
        itemCategory
      )
    );
    this.goBack();
  }

  private initializeSubscriptions(): void {
    !!this.subscription && this.subscription.unsubscribe();

    this.subscription = combineLatest([
      this.item$,
      this.store.select(CartState.isEditing),
      this.activatedRoute.queryParams,
    ])
      .pipe(this.destroyed$())
      .subscribe(([item, isCartEditing, queryParams]) => {
        const cartItemId = queryParams['cartItemId'];
        const cartItem = this.store.selectSnapshot(
          CartState.item(item?.id || '', cartItemId || '')
        );

        if (isCartEditing && cartItem) {
          this.quantity = cartItem.quantity || this.quantity;
          this.currentCartItemId = cartItem?.cartItemId || '';
          this.itemForm.patchValue({
            size: cartItem?.size,
            modifiers: cartItem?.modifiers,
            bundleItems: cartItem?.bundleItems,
          });
          this.specialRequest.patchValue(cartItem.specialRequest);
          this.metadata = {
            modifierSelections: (cartItem.modifiers || []).map(modifier => {
              return {
                modifierId: modifier.modifierId,
                modifierOptionIds: (cartItem.modifiers || [])
                  .filter(
                    modifierItem =>
                      modifierItem.modifierId === modifier.modifierId
                  )
                  .map(modifierItem => modifierItem.selectedOptionId),
              };
            }),
          };

          // cartItem price is size price + modifiers prices so we don't use it here
          this.selectedSizePrice =
            cartItem?.size.menuAttributes.find(
              (attributes: any) => attributes.menuId === this.menuIdFromPath
            )?.price || this.selectedSizePrice;

          if (cartItem?.size) {
            this.selectedSizeId = cartItem?.size?.id;
          }
        } else {
          const defaultSelectedSizeIndex = 0;
          const defaultSelectedSizeItem =
            item?.sizesAndPrices[defaultSelectedSizeIndex];

          if (defaultSelectedSizeItem) {
            if (!this.data!.menuId) {
              this.getMenuIdIfNotDefined(defaultSelectedSizeItem);
            }

            this.itemForm.controls.size.setValue(defaultSelectedSizeItem);
            this.reactOnSizeSelection({
              size: defaultSelectedSizeItem,
              index: defaultSelectedSizeIndex,
            });
          }
        }

        this.changeDetectorRef.detectChanges();
      });

    this.store
      .select(ItemState.modifiers)
      .pipe(this.destroyed$())
      .subscribe(modifiers => {
        const requiredModifierIds = this.getRequiredModifierIds(modifiers);
        const modifiersControl = this.itemForm.controls.modifiers;

        if (requiredModifierIds.length) {
          this.setModifierValidator(requiredModifierIds);
        } else {
          modifiersControl.clearValidators();
        }

        modifiersControl.updateValueAndValidity();
      });
  }

  private getRequiredModifierIds(modifiers: Modifier[]): string[] {
    return modifiers.reduce((accum, modifier) => {
      !!modifier.itemModifier.mandatory && accum.push(modifier.id);

      return accum;
    }, [] as string[]);
  }

  private setModifierValidator(requiredModifierIds: string[]): void {
    const validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as SelectedModifier[];
      const selectedModifiers = value.map(({ modifierId }) => modifierId);
      const selectedModifiersSet = new Set(selectedModifiers);

      return requiredModifierIds.every(id => selectedModifiersSet.has(id))
        ? null
        : { missedMandatoryItem: true };
    };

    this.itemForm.controls.modifiers.setValidators(validatorFn);
  }

  private setLocalStorageIfCameFromProfile(): void {
    const cameFrom: string | null = this.sessionStorage.getItem(
      SessionStorageKeys.cameFrom
    );

    if (cameFrom?.includes('/profile/favorites')) {
      // setting cameFrom in localStorage in case the user accesses
      // item details from profile/favorites and then reloads screen
      this.localStorage.setItem(LocaleStorageKeys.cameFrom, cameFrom);
      this.cameFromFavorites = true;
    }
  }

  private isOrderingDisabled(): void {
    const urlSegments: UrlSegment[] = this.rsGetRouterSegments.transform(
      this.router.url
    );

    this.disableOrdering = urlSegments[1].path === 'item';
    this.data!.disableOrdering = this.disableOrdering;
  }

  private isCurrentMenuAvailable(): void {
    this.store
      .select<VisibleMenus>(VenueState.menuPage)
      .pipe(this.destroyed$())
      .subscribe((visibleMenus: VisibleMenus) => {
        const menuAvailabilityItem = this.menuService.getMenuAvailabilityItem(
          this.menuId,
          visibleMenus
        );

        this.data!.isCurrentMenuAvailable =
          !menuAvailabilityItem?.isNonWorkingDay &&
          !menuAvailabilityItem?.willBeOpenedToday;
      });
  }
}
