export const TIME_AGO_TRANSLATIONS_PATHS = [
  'TIME.just_now',
  'TIME.minutes',
  'TIME.minute',
  'TIME.min',
  'TIME.hours',
  'TIME.hour',
  'TIME.h',
  'TIME.days',
  'TIME.day',
  'TIME.months',
  'TIME.month',
  'TIME.years',
  'TIME.year',
  'TIME.ago',
];

export const AT_TRANSLATION_PATH = ['TIME.at'];

export const TIME_WITH_ASAP_TRANSLATIONS_PATHS = [
  'TIME.ASAP',
  'TIME.MIN',
  'TIME.Min',
  'TIME.min',
];

export const AVAILABLE_IN_TRANSLATIONS_PATHS = [
  'MENUS.today',
  'MENUS.tomorrow',
  'MENUS.menu_unavailable_until',
  'MENUS.please_select_a_different_menu',
  'WEEKDAY.(until) monday',
  'WEEKDAY.(until) Monday',
  'WEEKDAY.(until) MONDAY',
  'WEEKDAY.(until) tuesday',
  'WEEKDAY.(until) Tuesday',
  'WEEKDAY.(until) TUESDAY',
  'WEEKDAY.(until) wednesday',
  'WEEKDAY.(until) Wednesday',
  'WEEKDAY.(until) WEDNESDAY',
  'WEEKDAY.(until) thursday',
  'WEEKDAY.(until) Thursday',
  'WEEKDAY.(until) THURSDAY',
  'WEEKDAY.(until) friday',
  'WEEKDAY.(until) Friday',
  'WEEKDAY.(until) FRIDAY',
  'WEEKDAY.(until) saturday',
  'WEEKDAY.(until) Saturday',
  'WEEKDAY.(until) SATURDAY',
  'WEEKDAY.(until) sunday',
  'WEEKDAY.(until) Sunday',
  'WEEKDAY.(until) SUNDAY',
];

export const FORMATED_DATE_TIME_TRANSLATIONS_PATHS = ['TIME.at'];
