import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
} from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable, distinctUntilChanged, map } from 'rxjs';

import { CartState } from '../../_ngxs/cart.state';
import { OrderDataState } from '../../_ngxs/order-data.state';
import { ResetTips } from '../../_ngxs/cart.actions';

import { AmountType } from '../../_enums/payment.enum';
import { DeliveryMethod, DeliveryType } from '../../_enums/order.enum';
import { CheckCalculation } from '../../_interfaces/check-calculation.model';
import { Tips } from '../../_interfaces/item.model';
import { TEST_ID } from '../../_constants/e2e-ids.constants';
import { untilDestroyed } from '../../_utils/until-destroyed';

@Component({
  selector: 'rs-marketing-checkout-check',
  templateUrl: './marketing-checkout-check.component.html',
  styleUrls: [
    './marketing-checkout-check.component.scss',
    '../../_styles/marketing/checkout.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketingCheckoutCheckComponent {
  @HostListener('window:beforeunload', ['$event']) unloadHandler(_: Event) {
    this.store.dispatch(new ResetTips());
    return undefined;
  }
  @Input() isGiftCard: boolean = false;
  @Input() isSearchingDriver: boolean = false;

  public readonly checkCalculations$: Observable<CheckCalculation> =
    this.store.select(CartState.checkCalculations);
  public readonly price$: Observable<number> = this.store.select(
    CartState.overallPrice
  );

  public readonly AmountType = AmountType;

  public readonly isDelivery$: Observable<boolean> = this.store
    .select(OrderDataState.orderType)
    .pipe(
      map((orderType: DeliveryType) => orderType === DeliveryMethod.Delivery),
      distinctUntilChanged()
    );

  public tips!: Tips;

  public readonly deliveryMethod = DeliveryMethod.Delivery;
  public readonly id = TEST_ID;
  private readonly destroy = untilDestroyed();

  constructor(
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscribeOnTipsChange();
  }

  private subscribeOnTipsChange(): void {
    this.store
      .select(CartState.tips)
      .pipe(this.destroy())
      .subscribe(value => {
        this.tips = value;
        this.changeDetectorRef.detectChanges();
      });
  }
}
