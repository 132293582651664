<div class="rs-item-description">
  <div class="rs-item-description-header">
    <div
      [matTooltip]="itemData!.item.customerFacingName || itemData!.item.name"
      class="rs-item-description-header__title"
      matTooltipPosition="left"
    >
      {{ itemData!.item.customerFacingName || itemData!.item.name }}
    </div>

    <div *ngIf="calories" class="rs-item-description-header__calories">
      ({{ calories }} cal)
    </div>
  </div>

  <div
    *ngIf="
      itemData?.item?.alcoholicAttributes?.wineAttributes?.alcoholicBeverage
        ?.ratings as wineRatings
    "
    class="rs-item-description-row__title"
  >
    <rs-badge-status
      class="rs-item-description-row__title-badge-status"
      rs-color="#E73535"
      rs-style="stadium"
    >
      {{ wineRatings | rsWineReviewsSummary }}/100
    </rs-badge-status>

    <div class="rs-item-description-row__title-critic-review">
      {{
        wineRatings.length === 1
          ? ('WINE.1 critic review' | translate)
          : ('WINE.{count} critic reviews'
            | translate: { count: wineRatings.length })
      }}
    </div>

    <rs-country-flag
      *ngIf="itemData?.item?.origin?.country as originCountry"
      [code3]="originCountry"
      class="rs-item-description-row__title-flag"
    ></rs-country-flag>

    <div class="rs-item-description-row__title-location">
      <span class="rs-item-description-row__title-winery">
        {{
          itemData?.item?.alcoholicAttributes?.wineAttributes?.origin?.winery ||
            ''
        }}
      </span>

      <span>{{
        itemData?.item?.alcoholicAttributes?.wineAttributes?.origin?.winery &&
        itemData?.item?.origin?.country
          ? ', '
          : ''
      }}</span>

      <span
        *ngIf="itemData?.item?.origin?.country as originCountry"
        class="rs-item-description-row__title-country"
        >{{ originCountry | getCountryNameByCode3 }}</span
      >
    </div>
  </div>

  <div *ngIf="itemData!.item.description" class="rs-item-description-info">
    {{ itemData!.item.description }}
  </div>

  <div
    *ngIf="!!price || itemHasAllergens"
    class="rs-item-description-row rs-item-description-row--price-part"
  >
    <div *ngIf="price" class="rs-item-description-row__price">{{ price }}</div>
    <rs-item-allergens></rs-item-allergens>
  </div>
</div>
