<div class="rs-invite-success-popup">
  <img
    src="../../../../../assets/img/check.png"
    width="50px"
    height="40px"
    alt=""
  />
  <p>{{ 'ADD-GUESTS.LINKS_SUCCESSFULLY' | translate }}</p>
  <div class="rs-invite-success-popup__phones">
    <span *ngFor="let phone of data.phones">{{ phone }}</span>
  </div>
  <p mat-dialog-close>{{ 'ADD-GUESTS.CLOSE' | translate | uppercase }}</p>
</div>
