<div
  class="rs-pay-now rs-base-container d-flex flex-column"
  [dir]="'languageDirection' | translate"
>
  <app-dialog-header
    *ngIf="isMobileView"
    [backButtonId]="id.payNowPopup.backButton"
  ></app-dialog-header>
  <div *ngIf="isMobileView; else desktopView">
    <rs-pay-now-mobile-view
      [isGiftCard]="isGiftCardPopup"
      [isSearchingDriver]="isSearchingDriver"
      [showDelivery]="showDelivery"
      (payClicked)="payForOrder()"
    ></rs-pay-now-mobile-view>
  </div>
</div>
<ng-template #desktopView>
  <rs-pay-now-desktop-view
    [isGiftCard]="isGiftCardPopup"
    [isSearchingDriver]="isSearchingDriver"
    [showDelivery]="showDelivery"
    (payClicked)="payForOrder()"
    class="h-100"
  ></rs-pay-now-desktop-view>
</ng-template>
