import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';
import { flatMap } from 'lodash';

import { ScheduleRange } from 'src/app/_shared/_interfaces/date-settings';
import {
  createArrayOfTimes,
  ASAPTimeShouldBeAdded,
  changePeriodInAvailablePeriodsToASAPTime,
} from '../_constants/date';

@Pipe({ name: 'rsIsAsapTime', standalone: true })
export class IsAsapTimePipe implements PipeTransform {
  transform = (timeString: string): boolean => timeString.includes('ASAP');
}

@Pipe({ name: 'rsFormulateOrderTime' })
export class RsFormulateOrderTimePipe implements PipeTransform {
  transform(
    selectedDayValue: ScheduleRange | undefined,
    asapMinTime: number,
    asapMaxTime: number,
    rangeStep: number
  ): string[] {
    let times =
      flatMap(
        selectedDayValue?.periods.map(period =>
          createArrayOfTimes(
            {
              from: period.from,
              to: period.to,
            },
            rangeStep,
            selectedDayValue.day
          )
        )
      ) || [];

    const isSelectedDayToday: boolean =
      moment(selectedDayValue?.day).date() === moment().date();

    if (
      isSelectedDayToday &&
      ASAPTimeShouldBeAdded(times, asapMinTime, asapMaxTime)
    ) {
      return changePeriodInAvailablePeriodsToASAPTime(
        times,
        asapMinTime,
        asapMaxTime
      );
    }

    return times;
  }
}

@Pipe({ name: 'rsCheckIfTodayHasDelivery' })
export class RsCheckIfTodayHasDeliveryPipe implements PipeTransform {
  constructor(private rsFormulateOrderTime: RsFormulateOrderTimePipe) {}

  public transform(
    days: ScheduleRange[],
    asapMinTime: number,
    asapMaxTime: number,
    rangeStep: number
  ): {
    filteredDays: ScheduleRange[];
    isAvailableDeliveryToday: boolean;
  } {
    const isAvailableDeliveryToday = !!this.rsFormulateOrderTime.transform(
      days[0],
      asapMinTime,
      asapMaxTime,
      rangeStep
    ).length;

    return {
      filteredDays: isAvailableDeliveryToday ? days : days.slice(1),
      isAvailableDeliveryToday: isAvailableDeliveryToday,
    };
  }
}
