export enum MatDialogId {
  reset_password = 'reset_password',
  login = 'login',
  create_password = 'create_password',
  logout_confirmation = 'logout_confirmation',
  add_guest = 'add_guest',
  confirmation = 'confirmation',
  cart = 'cart',
  allergens = 'allergens',
  allergens_popup = 'allergens_popup',
  search_popup = 'search_popup',
  message = 'message',
  item_details = 'item_details',
  item_details_dialog_screen = 'item_details_dialog_screen',
  order_details = 'order_details',
  profile_order_details = 'profile_order_details',
  add_new_card = 'add_new_card',
  delete_default_card = 'delete_default_card',
  image_cropper = 'image_cropper',
  new_address = 'new_address',
  initial_address = 'initial_address',
  delete_default_address = 'delete_default_address',
  change_address = 'change_address',
  pay_now = 'pay_now',
  redeem_points = 'redeem_points',
  choose_payment_address = 'choose_payment_address',
  select_payment_card = 'select_payment_card',
  select_gift_card_payment = 'select_gift_card_payment',
  photo = 'photo',
  add_gift_card = 'add_gift_card',
  new_delivery_address = 'new_delivery_address',
  schedule_delivery = 'schedule_delivery',
  schedule_dialog = 'schdeule_dialog',
  complete_address = 'complete_address',
  edit_delivery_info = 'edit_delivery_info',
  saved_addresses = 'saved_addresses',
  successful_invite = 'successful_invite',
  languages = 'languages',
  dine_in_change_payment = 'dine_in_change_payment',
  dine_in_add_payment = 'dine_in_add_payment',
  diners = 'diners',
  popup_instead_of_tooltip = 'popup_instead_of_tooltip',
  version = 'version',
}

export enum BootomSheetId {
  edit_delivery_info = 'edit_delivery_info',
}
