<div class="rs-bundle-item">
  <ng-template [ngIf]="!!item">
    <p class="rs-title flex-grow-1 rs-bundle-section__item-with-modifiers">
      {{ item.title }} {{ itemIndex ? itemIndex : '' }}
    </p>
    <rs-bundle-item-modifiers
      [modifiers]="availableModifiers"
      [metadata]="metadata"
      [modifiersControl]="modifiersControl"
      (modifiersSelected)="selectModifiers($event)"
      [disableOrdering]="disableOrdering"
    >
    </rs-bundle-item-modifiers>
  </ng-template>
</div>
