<rs-order-type-toggle
  title="Order Type"
  [(selectedOrderMethod)]="orderMethod"
  (change)="selectDefaultSchedule()"
></rs-order-type-toggle>

<ng-content></ng-content>

<ng-container *ngIf="scheduleSettings">
  <rs-schedule-order-date
    [title]="
      'SCHEDULE.select_date' + (orderMethod ? '_' + orderMethod : '')
        | translate
    "
    [scheduleRange]="scheduleRange"
    [noAvailableTime]="noAvailableTime$ | async"
    [(selectedDay)]="selectedDay"
    (selectedDayChange)="reactOnDaySelected($event)"
  ></rs-schedule-order-date>
  <rs-schedule-order-time
    [title]="'SCHEDULE.select_time_' + orderMethod | translate"
    [timeList]="timeList"
    [noAvailableTime]="noAvailableTime$ | async"
    [(selectedTime)]="selectedTime"
    (selectedTimeChange)="reactOnTimeSelect($event)"
  ></rs-schedule-order-time>
</ng-container>
