import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import {
  LETTERS,
  LOWER_CASE_LETTERS,
  MIN_PASSWORD_LENGTH,
  NUMBERS,
  PASSWORD_CHECKS,
  SPECIAL_SYMBOLS,
  UPPER_CASE_LETTERS,
} from '../_constants/password-strength.constants';
import {
  PasswordCheck,
  PasswordCheckCount,
  PasswordStrengthColor,
  PasswordStrengthLabel,
  PasswordStrengthValue,
} from '../_enum/password-strength.enum';
import { PasswordOption } from '../_models/password-strength.models';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-password-strength',
  standalone: true,
  imports: [SharedModule, FormsModule],
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordStrengthComponent {
  @Input()
  password!: string;

  public passwordChecks = PASSWORD_CHECKS;
  public strengthValue!: number;
  public strengthLabel!: string;

  public classes!: { [key: string]: boolean };

  ngOnChanges(): void {
    this.passwordChecks = PASSWORD_CHECKS.map(check => ({
      ...check,
      isChecked: this.isPasswordCheckPassed(
        this.password,
        check.key as PasswordCheck
      ),
    }));
    this.strengthValue = this.getPasswordStrength(this.passwordChecks);
    this.strengthLabel = this.getPasswordStrengthLabel(this.strengthValue);
    this.classes = {
      [this.getPasswordStrengthLabelColor(this.strengthValue)]: true,
    };
  }

  private getPasswordStrengthLabelColor(
    strengthValue: PasswordStrengthValue
  ): string {
    switch (strengthValue) {
      case PasswordStrengthValue.Weak: {
        return PasswordStrengthColor.Weak;
      }
      case PasswordStrengthValue.Moderate: {
        return PasswordStrengthColor.Moderate;
      }
      case PasswordStrengthValue.Strong: {
        return PasswordStrengthColor.Strong;
      }
      case PasswordStrengthValue.VeryStrong: {
        return PasswordStrengthColor.VeryStrong;
      }
      default: {
        return PasswordStrengthColor.Weak;
      }
    }
  }

  private isPasswordCheckPassed(
    password: string,
    check: PasswordCheck
  ): boolean {
    switch (check) {
      case PasswordCheck.MinCharacters: {
        return !!password && password.length >= MIN_PASSWORD_LENGTH;
      }
      case PasswordCheck.MinCharacter: {
        return !!password && !!password.match(LETTERS);
      }
      case PasswordCheck.MinLowerCaseCharacter: {
        return !!password && !!password.match(LOWER_CASE_LETTERS);
      }
      case PasswordCheck.MinUpperCaseCharacter: {
        return !!password && !!password.match(UPPER_CASE_LETTERS);
      }
      case PasswordCheck.MinNumber: {
        return !!password && !!password.match(NUMBERS);
      }
      case PasswordCheck.MinSymbol: {
        return !!password && !!password.match(SPECIAL_SYMBOLS);
      }
    }
  }

  private getPasswordStrength(passwordChecks: PasswordOption[]): number {
    const passedCheckCount = passwordChecks.reduce(
      (count, check) => count + (check.isChecked ? 1 : 0),
      0
    );

    switch (passedCheckCount) {
      case PasswordCheckCount.One:
      case PasswordCheckCount.Two: {
        return PasswordStrengthValue.Weak;
      }
      case PasswordCheckCount.Three:
      case PasswordCheckCount.Four: {
        return PasswordStrengthValue.Moderate;
      }
      case PasswordCheckCount.Five: {
        return PasswordStrengthValue.Strong;
      }
      case PasswordCheckCount.Six: {
        return PasswordStrengthValue.VeryStrong;
      }
      default: {
        return PasswordStrengthValue.None;
      }
    }
  }

  private getPasswordStrengthLabel(
    strengthValue: number
  ): PasswordStrengthLabel {
    switch (strengthValue) {
      case PasswordStrengthValue.Weak: {
        return PasswordStrengthLabel.Weak;
      }
      case PasswordStrengthValue.Moderate: {
        return PasswordStrengthLabel.Moderate;
      }
      case PasswordStrengthValue.Strong: {
        return PasswordStrengthLabel.Strong;
      }
      case PasswordStrengthValue.VeryStrong: {
        return PasswordStrengthLabel.VeryStrong;
      }
      default: {
        return PasswordStrengthLabel.Weak;
      }
    }
  }
}
