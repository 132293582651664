import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsAllergenPipesModule } from 'src/app/_shared/_modules/allergen-pipes.module';
import { TooltipComponent } from '../../../../_rs-design/tooltip/tooltip/tooltip.component';
import { ItemAllergensComponent } from './item-allergens.component';

import { Allergen } from 'src/app/_shared/_interfaces/item.model';
import { getValidatedItemAllergens } from 'src/app/_shared/_utils/allergens';

@Component({
  selector: 'rs-item-allergens',
  standalone: true,
  imports: [SharedModule, RsAllergenPipesModule, TooltipComponent],
  styleUrls: ['./item-allergens.component.scss'],
  templateUrl: './item-allergens.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodItemAllergensComponent extends ItemAllergensComponent {
  @Input() override itemName!: string;
  @Input() set itemAllergens(allergens: Allergen[] | undefined | null) {
    if (!allergens) {
      return;
    }

    const showAllergens = getValidatedItemAllergens(allergens);
    showAllergens?.length && this.formAllergens(showAllergens);
  }

  // this is required bc we don't need ngOnInit functionality from base class
  override ngOnInit(): void {}
}
