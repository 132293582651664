import { Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable, combineLatest, map, tap } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { LanguageState } from '../_ngxs/language.state';
import {
  FORMATED_DATE_TIME_TRANSLATIONS_PATHS,
  TIME_AGO_TRANSLATIONS_PATHS,
} from '../_constants/paths-to-translations.constant';
import { TimeAgoPipe } from './time-ago.pipe';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { SessionStorageEngine } from 'src/app/_services/session-storage.service';
import { SessionStorageKeys } from '../_enums/session-storage-keys.enum';
import { FormateDateTimeStringPipe } from './formate-date-time-string.pipe';
import { LocaleStorageKeys } from '../_enums/local-storage-keys.enum';

class CurrentLanguage {
  constructor(
    protected readonly store: Store,
    protected readonly sessionStorage: SessionStorageEngine,
    protected readonly localStorage: LocalStorageService
  ) {}

  public getLang(): string {
    return (
      this.sessionStorage.getItem(SessionStorageKeys.language) ||
      this.localStorage.getItem(LocaleStorageKeys.language) ||
      this.store.selectSnapshot(LanguageState.language)
    );
  }
}

@Pipe({
  name: 'translateByPathsInFile',
  pure: false,
})
export class TranslateByPathsInFile
  extends CurrentLanguage
  implements PipeTransform
{
  constructor(
    private readonly translateService: TranslateService,
    store: Store,
    sessionStorage: SessionStorageEngine,
    localStorage: LocalStorageService
  ) {
    super(store, sessionStorage, localStorage);
  }

  transform(value: string, paths: string[] = []): any {
    return combineLatest([
      this.store.select(LanguageState.language),
      ...paths.map(path => this.translateService.get(path)),
    ]).pipe(
      tap(result => this.translateService.use(result[0])),
      map(result => {
        const lang = this.getLang();

        this.translateService.use(lang);

        value = value || '';

        return paths.reduce(
          (translated, path, index) =>
            translated.replace(path, result[index + 1]),
          value
        );
      })
    );
  }
}

@Pipe({
  name: 'translateDaysOfWeek',
  pure: false,
})
export class TranslateDaysOfWeekPipe implements PipeTransform {
  constructor(
    private readonly store: Store,
    private readonly translateService: TranslateService
  ) {}

  transform(weekDays: string): Observable<string> {
    const tanslationObservables = weekDays
      .split(' — ')
      .map(weekDay => this.translateService.get('WEEKDAY.' + weekDay));

    return combineLatest([
      this.store.select(LanguageState.language),
      ...tanslationObservables,
    ]).pipe(
      map(result => {
        const lang = result[0];
        this.translateService.use(lang);
        result.splice(0, 1);

        return (result as string[]).join(' - ');
      })
    );
  }
}

@Pipe({
  name: 'translatedTimeAgo',
  pure: false,
})
export class TranslatedTimeAgoPipe implements PipeTransform {
  constructor(
    private readonly translateByPathsInFile: TranslateByPathsInFile,
    private readonly timeAgoPipe: TimeAgoPipe
  ) {}

  transform(value: string) {
    return this.translateByPathsInFile.transform(
      this.timeAgoPipe.transform(value),
      TIME_AGO_TRANSLATIONS_PATHS
    );
  }
}

@Pipe({ name: 'translateFormatedDateTimeString' })
export class TranslateFormatedDateTimeStringPipe
  extends CurrentLanguage
  implements PipeTransform
{
  private defaultDateFormat = 'ddd, MMM D YYYY';
  private defaultTimeFormat = 'hh:mm A';

  constructor(
    private readonly translateService: TranslateService,
    store: Store,
    sessionStorage: SessionStorageEngine,
    localStorage: LocalStorageService
  ) {
    super(store, sessionStorage, localStorage);
  }

  transform(
    value: string,
    dateFormat = this.defaultDateFormat,
    timeFormat = this.defaultTimeFormat
  ): Observable<string> {
    return combineLatest([
      this.store.select(LanguageState.language),
      ...FORMATED_DATE_TIME_TRANSLATIONS_PATHS.map(path =>
        this.translateService.get(path)
      ),
    ]).pipe(
      map(result => {
        const lang = this.getLang();
        this.translateService.use(lang);

        const formated = new FormateDateTimeStringPipe(
          this.translateService
        ).transform(value, dateFormat, timeFormat);

        return formated.replace(this.translateService.instant('at'), result[1]);
      })
    );
  }
}

@Pipe({ name: 'translateIfExists', pure: false })
export class TranslateIfExistsPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string, group: string): string {
    const translationKey = `${group}.${value}`;
    const translation = this.translateService.instant(translationKey);

    return translation !== translationKey ? translation : value;
  }
}
