import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const PHONE_REGEXP =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const EMAIL_REGEXP = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

export function emailOrPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (PHONE_REGEXP.test(value) || EMAIL_REGEXP.test(value)) {
      return null;
    }

    return { invalid: true };
  };
}
