import { LngLatLike } from 'mapbox-gl';

export const MAP_STYLE_SRC = 'mapbox://styles/mapbox/streets-v11';
export const DEFAULT_MAP_CENTER: LngLatLike | undefined = [
  -101.299591, 47.116386,
]; // center of US
export const DEFAULT_MAP_ZOOM = 2.5;

export const DEFAULT_MAP_CONFIG = {
  container: 'map',
  style: MAP_STYLE_SRC,
  zoom: DEFAULT_MAP_ZOOM,
  center: DEFAULT_MAP_CENTER,
  attributionControl: false,
  renderWorldCopies: false,
};
