import { CardBrand } from '../_enums/card.enum';

export const getCardImageSrcByBrand = (brand: CardBrand | undefined) => {
  let imageName = 'UNKNOWN';

  switch (brand) {
    case CardBrand.Mastercard:
    case CardBrand.Visa:
    case CardBrand.JCB:
    case CardBrand.Discover:
    case CardBrand.Amex:
      imageName = brand.toUpperCase();
      break;

    case CardBrand.DinersClub:
      imageName = 'DINERS_CLUB';
      break;
  }

  return `assets/img/cards-logos/cc-${imageName}.svg`;
};
