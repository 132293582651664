import { NgModule } from '@angular/core';

import { SharedModule } from '../../_modules/shared.module';
import {
  RsMenuComponent,
  RsMenuItemComponent,
  RsMenuTitleComponent,
} from './menu.component';

@NgModule({
  imports: [
    SharedModule,
    RsMenuComponent,
    RsMenuTitleComponent,
    RsMenuItemComponent,
  ],
  exports: [RsMenuComponent, RsMenuTitleComponent, RsMenuItemComponent],
})
export class RsMenuModule {}
