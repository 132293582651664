import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

import { Store } from '@ngxs/store';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';

import { CartEntity, CartState } from 'src/app/_shared/_ngxs/cart.state';
import { BundleItemState } from 'src/app/_shared/_ngxs/bundle-item.state';
import { OrderState } from 'src/app/_shared/_ngxs/order.state';
import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';
import { VenueOrderSettingsState } from 'src/app/_shared/_ngxs/venue-order-settings.state';
import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';
import { FilteredMenuState } from 'src/app/_shared/_ngxs/filtered-menu.state';
import { CalculateCheckAndCreateDeliveryQuote } from 'src/app/_shared/_ngxs/cart.actions';

import { PaymentMethods } from 'src/app/_shared/_enums/payment-methods.enum';
import {
  DeliveryMethod,
  DeliveryType,
  OrderType,
} from 'src/app/_shared/_enums/order.enum';
import { PaymentIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { StateItem } from 'src/app/_shared/_interfaces/item.model';
import { Menu } from 'src/app/_shared/_interfaces/menu.model';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';
import { PAYMENT_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { getVenueName } from 'src/app/_shared/_utils/common';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-pay-now-desktop-view',
  templateUrl: './pay-now-desktop-view.component.html',
  styleUrls: [
    './pay-now-desktop-view.component.scss',
    '../../../../../_shared/_styles/marketing/checkout.scss',
    '../apple-google-pay.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayNowDesktopViewComponent {
  @Input() isGiftCard: boolean = false;
  @Input() isSearchingDriver: boolean = false;
  @Input() showDelivery = false;

  public readonly items$: Observable<CartEntity[]> = this.store
    .select(CartState.cartFilling)
    .pipe(
      map(data => data.items.filter(item => (item as StateItem).selectedInCart))
    );

  public readonly bundleItemsNames$: Observable<{ [id: string]: string }> =
    this.store.select(BundleItemState.bundleItemsNames);
  public readonly isOrderReadyForPayment$ = this.store.select(
    CartState.isOrderReadyForPayment
  );
  public readonly selectedPaymentCard$ = this.store.select(
    CartState.selectedPaymentCard
  );
  public readonly badgeNumber$: Observable<string> = this.store.select(
    CartState.badgeNumber
  );
  public readonly isAddressCoveredByDelivery$ = this.store.select(
    CartState.isAddressCoveredByDelivery
  );
  public readonly checkCalculations$ = this.store.select(
    CartState.checkCalculations
  );
  public readonly venue$ = this.store.select(VenueState.venue);
  public readonly tips$ = this.store.select(CartState.tips);
  public readonly isOpenTab$ = this.store.select(OrderState.isOpenTab);
  public readonly paymentMethod$ = this.store.select(CartState.paymentMethod);
  public readonly guestUserData$ = this.store.select(CartState.guestUserData);
  public readonly isLoggedIn$ = this.store.select(SessionState.isLoggedIn);
  public readonly isShadowUser$ = this.store.select(SessionState.isShadowUser);
  public readonly price$: Observable<number> = this.store.select(
    CartState.overallPrice
  );
  public readonly isThereAnyAvailableOrderMethods$ = this.store.select(
    VenueOrderSettingsState.isThereAnyAvailableOrderMethod
  );
  public readonly orderType$ = this.store.select(OrderDataState.orderType);
  public readonly orderPhone$ = this.store.select(OrderDataState.orderPhone);
  public readonly orderAddress$ = this.store.select(
    OrderDataState.orderAddress
  );
  public readonly selectedOrderPeriod$ = this.store.select(
    OrderDataState.selectedOrderPeriod
  );

  public currentPaymentMethod: PaymentMethods | undefined = PaymentMethods.card;
  public venueName = '';
  public orderType!: DeliveryMethod | OrderType.DineIn;
  public menus!: Menu[];
  public phoneIsEntered!: boolean;
  public isCardExpired = false;
  public orderAddress!: UserProfileAddress | undefined;

  public readonly paymentMethods = PaymentMethods;
  public readonly OrderType = OrderType;
  public readonly deliveryMethod: DeliveryMethod = DeliveryMethod.Delivery;
  public readonly id = TEST_ID;
  public readonly appleIcon = PaymentIcons.ApplePay;
  public readonly googleIcon = PaymentIcons.GooglePay;

  private readonly destroyed$ = untilDestroyed();

  @Output() payClicked = new EventEmitter<void>();

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly store: Store,
    private readonly matDialog: MatDialog,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly platform: Platform,
    private readonly svgIconService: SvgIconService
  ) {
    this.menus = this.store.selectSnapshot(FilteredMenuState.menus);
    this.venueName = this.store.selectSnapshot(VenueState.venue)?.name;
  }

  ngOnInit(): void {
    this.registerIcons();
    this.addSubscriptions();
  }

  private addSubscriptions(): void {
    combineLatest([this.orderType$, this.orderAddress$])
      .pipe(this.destroyed$())
      .subscribe(([orderType, orderAddress]) => {
        !this.isGiftCard &&
          (orderType !== this.orderType ||
            orderAddress !== this.orderAddress) &&
          this.store.dispatch(
            new CalculateCheckAndCreateDeliveryQuote(true, false)
          );

        this.orderType = orderType;
        this.orderAddress = orderAddress;
      });

    combineLatest(this.orderType$, this.orderPhone$)
      .pipe(this.destroyed$())
      .subscribe(
        ([orderType, orderPhone]) =>
          (this.phoneIsEntered =
            orderType === DeliveryMethod.Delivery && !!orderPhone?.phoneNumber)
      );

    this.guestUserData$
      .pipe(
        map(({ phone }) => phone),
        filter(phone => !!phone?.phoneNumber),
        this.destroyed$()
      )
      .subscribe(phoneNumber => (this.phoneIsEntered = !!phoneNumber));

    this.selectedPaymentCard$.subscribe(card => {
      this.cardExpired(card?.cardExpirationDate);
    });
  }

  public goToOrdering(): void {
    this.matDialog.closeAll();

    if (this.platform.isBrowser) {
      const venueName = getVenueName(this.document.location.href);

      const firstMenuId = this.store.selectSnapshot(
        VenueState.defaultMenuId(
          this.orderType === OrderType.DineIn ? 'dine-in' : 'togo'
        )
      );

      let url = `/${venueName}/`;

      if (this.isGiftCard) {
        url += `gift-card`;
      } else {
        if (this.orderType === OrderType.DineIn) {
          url += `dine-in/menus/${firstMenuId}`;
        } else {
          url += `order/${firstMenuId}`;
        }
      }

      this.router.navigate([url], {
        relativeTo: this.route,
      });
    }
  }

  public reactOnPaymentMethodChanged(paymentMethod: PaymentMethods): void {
    this.currentPaymentMethod = paymentMethod;
  }

  public cardExpired(date?: string) {
    const today = moment().format('YYYY-MM-DD');
    const expiry = moment(date).format('YYYY-MM-DD');
    if (date) {
      setTimeout(() => {
        this.isCardExpired = moment(expiry).isBefore(today, 'day');
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.appleIcon, this.googleIcon],
      PAYMENT_ICONS_DIRECTORY
    );
  }
}
