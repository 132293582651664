<div
  class="rs-gift-card"
  [ngClass]="{ 'rs-gift-card-small': isMobileView && resizeOnMobile }"
  [dir]="'languageDirection' | translate"
>
  <div class="rs-gift-card__card-wrapper row no-gutters">
    <mat-card
      class="rs-gift-card__gradient rs-gift-card__card"
      [class.rs-disabled]="disabled"
    >
      <div
        class="rs-gift-card__header"
        *ngIf="displayAllInformation || (!isMobileView && resizeOnMobile)"
      >
        <div class="rs-gift-card__title">
          {{ title }}
        </div>
        <div [id]="id.giftCard.amount" class="rs-gift-card__amount">
          {{
            card
              | composeGiftCardAmount
              | cents2DollarsView
              | rsEasternArabicNumerals
          }}
        </div>
      </div>
      <mat-card-content class="w-100">
        <div>
          <div class="rs-gift-card__qr-code-generated">
            <img
              *ngIf="isQrPreview"
              class="rs-gift-card__qr-code d-flex"
              src="../../../../../assets/images/gift-card-QR-code.png"
              alt="QR code"
            />

            <qrcode
              *ngIf="!isQrPreview && card.giftCardNumber"
              [qrdata]="card?.giftCardNumber || ''"
              [title]="card?.giftCardNumber || ''"
              [errorCorrectionLevel]="'M'"
              [elementType]="'svg'"
              [width]="isMobileView && resizeOnMobile ? 35 : 116"
            ></qrcode>
          </div>
        </div>
        <div class="rs-gift-card__serial-code">
          {{ giftCardNumberDefined ? card?.giftCardNumber : 'XXX' }}
        </div>
        <div
          class="rs-gift-card__card-holder"
          *ngIf="displayAllInformation || (!isMobileView && resizeOnMobile)"
        >
          <span
            [id]="id.giftCard.cardHolder"
            class="rs-gift-card__card-holder-child"
          >
            {{ recipientName }}
          </span>
        </div>
      </mat-card-content>
      <mat-card-footer>
        <div class="rs-gift-card__powered-by">
          <span>{{ 'GIFT_CARDS.powered_by' | translate }}</span>
          <mat-icon [svgIcon]="CommonIcon.WhiteRockspoonLogo"></mat-icon>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>
</div>
