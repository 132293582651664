<rs-dialog rs-dialog-style="default" rs-show-close-button="true">
  <ng-container rs-dialog-header>
    <span>{{ data.title }}</span>
  </ng-container>
  <div>
    <p>
      {{ data.description }}
    </p>
    <div class="rs-delete-default-card">
      <rs-payment-item
        *ngFor="let card of notDefaultCards"
        [card]="card"
        [activeCard]="card.id === selectedPaymentId"
        (selectCard)="setSelectedCard($event)"
      ></rs-payment-item>
    </div>
  </div>
  <ng-container rs-dialog-actions-direction="row">
    <button
      [disabled]="!selectedPaymentId"
      rs-style="text"
      (click)="selectDefaultCard()"
    >
      <span>{{ 'save' | translate | uppercase }}</span>
    </button>
  </ng-container>
</rs-dialog>
