import { Component, Input } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import { DialogType, DialogTypeOptions } from '../dialog-type';

@Component({
  selector: 'rs-default-full-height-dialog-header',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './default-full-height-dialog-header.component.html',
})
export class RsDefaultFullHeightDialogHeaderComponent {
  @Input('rs-type') type!: DialogType;
  @Input() dialogHeaderHasBorder!: boolean;

  public readonly DialogTypeOptions = DialogTypeOptions;
}
