import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { filter, take } from 'rxjs';

import {
  SaveGuestPaymentCard,
  SavePaymentCard,
} from 'src/app/_shared/_ngxs/cart.actions';
import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { OpenChangeAddressDialog } from 'src/app/_shared/_ngxs/dialog.actions';

import { removeSpecialCharactersFromCardNumber } from 'src/app/_shared/_utils/payment-card';
import { NewCardToAdd } from 'src/app/_shared/_models/common.interface';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { AddressForm } from 'src/app/_shared/_interfaces/address.model';
import {
  CARD_NUMBER_MASK_CONFIG,
  CVV_NUMBER_MASK_CONFIG,
  EXPIRATION_DATE_MASK_CONFIG,
} from 'src/app/_shared/_constants/card.constants';
import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';
import { PaymentValidator } from 'src/app/_shared/_validators/payment.validation';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

@Component({
  selector: 'rs-add-new-card',
  templateUrl: './add-new-card.component.html',
  styleUrls: ['./add-new-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewCardComponent {
  public isLoggedIn$ = this.store.select(SessionState.isLoggedIn);
  public newCardForm = this.getNewCardForm();
  public isFormValid: boolean = false;
  public cardNumberMaskConfig = CARD_NUMBER_MASK_CONFIG;
  public cvvNumberMaskConfig = CVV_NUMBER_MASK_CONFIG;
  public expirationDateMaskConfig = EXPIRATION_DATE_MASK_CONFIG;
  public useDeliveryAddressControl = new FormControl<boolean>(false, {
    nonNullable: true,
  });

  private destroyed$ = untilDestroyed();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly addCardDialogRef: MatDialogRef<AddNewCardComponent>,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      deliveryAddress: AddressForm | null;
      selectedCard: NewCardToAdd | null;
    }
  ) {
    if (data?.deliveryAddress) {
      this.subscribeOnUseDeliveryAddressControlChange();
    }
    if (data?.selectedCard) {
      this.initiateNewCardValue(data?.selectedCard);
    }
  }

  public saveCard(): void {
    const isLoggedIn: boolean = this.store.selectSnapshot(
      SessionState.isLoggedIn
    );

    this.store
      .dispatch(
        isLoggedIn
          ? new SavePaymentCard(this.getCardInformation())
          : new SaveGuestPaymentCard(this.getCardInformation())
      )
      .pipe(this.destroyed$())
      .subscribe(() => this.addCardDialogRef.close());
  }

  public openChangeAddressPopup(): void {
    this.store
      .dispatch(new OpenChangeAddressDialog())
      .pipe(take(1))
      .subscribe(() => {
        this.dialog
          .getDialogById(MatDialogId.change_address)
          ?.afterClosed()
          .pipe(
            take(1),
            filter(address => !!address)
          )
          .subscribe((address: UserProfileAddress) => {
            //TODO: Needs to clarify what exactly we should send to API as state
            //const state = address?.state
            //? getCountryAndStateCode('US', address.state).stateCode
            //: '';
            this.newCardForm.patchValue({
              address1: address?.address1 || '',
              address2: address?.address2 || '',
              city: address?.city || '',
              state: address?.state || '',
              zipcode: address?.zipcode || '',
            });
          });
      });
  }

  private getNewCardForm() {
    return this.formBuilder.group({
      cardholderName: this.formBuilder.control<string | null>(null, [
        Validators.required,
      ]),
      cardNumber: this.formBuilder.control<string | null>(null, [
        Validators.required,
        PaymentValidator.cardNumberValidator,
      ]),
      cvv: this.formBuilder.control<string | null>(null, [
        PaymentValidator.securityCodeRequired,
        PaymentValidator.cvvLengthValidator,
        Validators.pattern(/^[0-9\s]*$/),
      ]),
      cardExpirationDate: this.formBuilder.control<string | null>(null, [
        Validators.required,
        PaymentValidator.expirationDateValidator,
      ]),
      address1: this.formBuilder.control<string | null>(null, [
        Validators.required,
      ]),
      address2: this.formBuilder.control<string | null>(null),
      city: this.formBuilder.control<string | null>(null, [
        Validators.required,
      ]),
      state: this.formBuilder.control<string | null>(null, [
        Validators.required,
      ]),
      zipcode: this.formBuilder.control<string | null>(null, [
        Validators.required,
      ]),
      country: this.formBuilder.control<string | null>(null, [
        Validators.required,
      ]),
    });
  }

  private getCardInformation(): NewCardToAdd {
    const formValue = this.newCardForm.value;
    const cardNumber = this.getCardNumber();

    return {
      tokenType: 'rockspoon',
      redactedCardNumber: cardNumber,
      cardNumber: cardNumber,
      cvv: formValue.cvv,
      cardholderName: formValue.cardholderName,
      cardExpirationDate: this.getCardExpirationDate(),
      zipCode: formValue.zipcode,
      address: formValue.address1,
      address2: formValue.address2,
      countryCode: formValue.country,
      city: formValue.city,
      state: formValue.state,
    } as NewCardToAdd;
  }

  private getCardNumber(): string {
    return removeSpecialCharactersFromCardNumber(
      this.newCardForm.controls.cardNumber.value!
    );
  }

  private getCardExpirationDate(): string {
    const strDate = this.newCardForm.controls.cardExpirationDate.value || '';
    const strArray = strDate.split('/');

    return '20' + strArray[1] + '-' + strArray[0] + '-01T00:00:00Z';
  }

  private subscribeOnUseDeliveryAddressControlChange(): void {
    this.useDeliveryAddressControl.valueChanges
      .pipe(
        filter(value => !!value),
        this.destroyed$()
      )
      .subscribe(() => this.newCardForm.patchValue(this.data.deliveryAddress!));
  }

  private initiateNewCardValue(card: NewCardToAdd): void {
    const {
      cardholderName,
      cardNumber,
      cvv,
      cardExpirationDate,
      address,
      address2,
      city,
      state,
      zipCode,
      countryCode,
    } = card;

    this.newCardForm.patchValue({
      cardholderName,
      cardNumber,
      cvv,
      cardExpirationDate: this.setCardExpirationDate(cardExpirationDate),
      address1: address,
      address2,
      city,
      state,
      zipcode: zipCode,
      country: countryCode,
    });
  }

  private setCardExpirationDate(expirationDate: string): string {
    let cardExpirationDate = expirationDate.split('-');
    cardExpirationDate.pop();
    cardExpirationDate.reverse();
    return `${cardExpirationDate[0]}/${cardExpirationDate[1].slice(2)}`;
  }
}
