import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DineInStateModel } from 'src/app/_shared/_interfaces/dine-in.model';
import { ENDPOINTS } from '../_shared/_constants/endpoints';

const apiUrl = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class DineInService {
  constructor(private readonly http: HttpClient) {}

  public fetchDineIn(
    venueId: string,
    floorSectionId: string,
    floorPlanElementId: string
  ): Observable<DineInStateModel> {
    const params = new HttpParams()
      .set('venueId', venueId)
      .set('floorSectionId', floorSectionId)
      .set('floorPlanElementId', floorPlanElementId);

    return this.http.get<DineInStateModel>(
      `${apiUrl}/order/v1/guest/table-info`,
      {
        params,
      }
    );
  }

  public inviteGuests(
    orderId: string | null,
    phones: string[]
  ): Observable<void> {
    return this.http.post<void>(
      `${apiUrl}/${ENDPOINTS.order.inviteGuests(orderId)}`,
      phones
    );
  }
}
