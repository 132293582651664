import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';

import { CatalogService } from 'src/app/_services/catalog.service';
import {
  ClearItemModifiers,
  GetBundleItemModifiers,
  RemoveBundleItemModifier,
} from './bundle-item.actions';
import { BundleItem, Modifier } from 'src/app/_shared/_interfaces/item.model';
import { copy } from '../_utils/common';

interface BundleItemStateModel {
  bundleItemModifiers: {
    bundleSectionId: string;
    item: BundleItem;
    modifiers: Modifier[];
  }[];
  bundleItemsNames: { [id: string]: string };
}

const DEFAULT_ITEM: BundleItemStateModel = {
  bundleItemModifiers: [],
  bundleItemsNames: {},
};

@State<BundleItemStateModel>({
  name: 'bundleItem',
  defaults: DEFAULT_ITEM,
})
@Injectable()
export class BundleItemState {
  @Selector()
  static bundleItemsNames({ bundleItemsNames }: BundleItemStateModel): {
    [id: string]: string;
  } {
    return bundleItemsNames;
  }

  @Selector()
  static bundleItemModifiers({ bundleItemModifiers }: BundleItemStateModel): {
    bundleSectionId: string;
    item: BundleItem;
    modifiers: Modifier[];
  }[] {
    return bundleItemModifiers || [];
  }

  constructor(private readonly service: CatalogService) {}

  @Action(GetBundleItemModifiers)
  getBundleItemModifiers(
    { getState, patchState }: StateContext<BundleItemStateModel>,
    { item, bundleSectionId }: GetBundleItemModifiers
  ) {
    const bundleItemsNames: { [id: string]: string } = copy(
      getState().bundleItemsNames || {}
    );
    bundleItemsNames[item.id] = item.title;

    return this.service.getItemModifiers(item.itemId).pipe(
      tap(modifiers => {
        patchState({
          bundleItemsNames,
          bundleItemModifiers: [
            ...(copy(getState().bundleItemModifiers) as {
              bundleSectionId: string;
              item: BundleItem;
              modifiers: Modifier[];
            }[]),
            {
              bundleSectionId,
              item: copy(item),
              modifiers: copy(modifiers),
            },
          ],
        });
      })
    );
  }

  @Action(RemoveBundleItemModifier)
  removeBundleItemModifier(
    { getState, patchState }: StateContext<BundleItemStateModel>,
    { item }: RemoveBundleItemModifier
  ) {
    let bundleItemModifiers: {
      bundleSectionId: string;
      item: BundleItem;
      modifiers: Modifier[];
    }[] = (
      copy(getState().bundleItemModifiers ?? []) as {
        item: BundleItem;
        modifiers: Modifier[];

        bundleSectionId: string;
      }[]
    ).reverse();
    const existingItemModifiersIndex: number = bundleItemModifiers.findIndex(
      ({ item: bundleItemModifier }) => bundleItemModifier.id === item.id
    );
    if (existingItemModifiersIndex > -1) {
      bundleItemModifiers.splice(existingItemModifiersIndex, 1);
    }
    patchState({
      bundleItemModifiers: [...bundleItemModifiers.reverse()],
    });
  }

  @Action(ClearItemModifiers)
  clearItemModifiers({ patchState }: StateContext<BundleItemStateModel>) {
    patchState({ bundleItemModifiers: [] });
  }
}
