export const DIALOG_PANEL_CLASS = {
  popup: 'rs-popup',
  fullScreenModal: 'rs-full-screen-modal',
  fullScreenModalWith2ScrollableParts:
    'rs-full-screen-modal-with-two-scrollable-part',
  dineInCartFullScreenModal: 'rs-dine-in-cart-full-screen-modal',
  addGuestPopup: 'rs-add-guests-popup',
  successfulInvite: 'rs-invite-success-popup',
  chooseAddress: 'rs-choose-address-popup',
  photosDialog: 'rs-photos-dialog',
  newAddress: 'rs-new-address-popup',
  editDeliveryInfo: 'rs-edit-delivery-info-popup',
  paymentCards: 'rs-payment-cards-popup',
  profileOrderDetails: 'rs-profile-order-details',
  languages: 'rs-languages-panel-class',
  dialogWith100dvhHeight: 'rs-full-height--dvh',
  dialogWithMax100dvhHeight: 'rs-max-full-height--dvh',
};

export const BOTTOM_SHEET_CLASS = {
  showHeaderUnderBackdrop: 'rs-bottom-sheet--show-header',
  scheduleBottomScheet: 'rs-schedule-bottom-sheet',
};
