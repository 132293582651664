import { environment } from 'src/environments/environment';

const isProd = environment.env === 'prod';

export const getImageToCDN = (src: string | undefined): string => {
  if (!src) {
    return '';
  }

  return isProd
    ? src.replace(/storage.googleapis.com\/image-ms\//, 'cdn.rockspoon.io/')
    : src;
};

export const getImagePathToNoRes = (src: string | undefined): string => {
  return src ? src.replace(/\/hi_/, '/no_') : '';
};
