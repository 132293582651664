import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  catchError,
  map,
  Observable,
  throwError as observableThrowError,
} from 'rxjs';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../_shared/_constants/endpoints';

import {
  JoinUserId,
  ShadowUserUsername,
  TokenData,
} from '../_shared/_interfaces/session.model';
import {
  ChangePasswordRequest,
  ForgetPasswordRequest,
} from '../_shared/_models/authentication.model';
import { Phone } from '../_shared/_models/common.interface';

const apiUrl = environment.apiUrl;
// TODO change client to correct one;
const client = 'BCKOF';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(private readonly http: HttpClient) {}

  private static handleError(error: any) {
    return observableThrowError(error);
  }

  public login(username: string, password: string): Observable<TokenData> {
    return this.http
      .post<TokenData>(`${apiUrl}/${ENDPOINTS.authentication.login}`, {
        username,
        password,
      })
      .pipe(catchError(AuthenticationService.handleError));
  }

  public signUp(
    firstName: string,
    lastName: string,
    email: string,
    phone: Phone,
    password: string,
    allowUnconfirmedLogin?: boolean
  ): Observable<JoinUserId> {
    const payload: any = {
      firstName: firstName,
      lastName: lastName,
      password: password,
      allowUnconfirmedLogin: allowUnconfirmedLogin,
    };

    if (email && email !== '') {
      payload.email = email;
    }

    if (phone?.phoneNumber !== '') {
      payload.phone = phone;
    }

    return this.http
      .post<JoinUserId>(`${apiUrl}/${ENDPOINTS.authentication.signUp}`, payload)
      .pipe(catchError(AuthenticationService.handleError));
  }

  public fetchShadowUserTokenData(
    body?: ShadowUserUsername
  ): Observable<TokenData> {
    return this.http.post<TokenData>(
      `${apiUrl}/${ENDPOINTS.authentication.shadowUserToken}`,
      body
    );
  }

  public refreshToken(refreshToken: string): Observable<TokenData> {
    return this.http
      .post<TokenData>(`${apiUrl}/${ENDPOINTS.authentication.refreshToken}`, {
        refreshToken,
      })
      .pipe(catchError(AuthenticationService.handleError));
  }

  // TODO: am not sure if we really need it.
  // if yes, we need check sent email
  public validateEmail(token: string): Observable<any> {
    return this.http
      .get<any>(
        `${apiUrl}/${ENDPOINTS.authentication.validateEmail}?token=${token}`
      )
      .pipe(catchError(AuthenticationService.handleError));
  }

  public recoveryPassword(
    username: string,
    venueId: string
  ): Observable<boolean> {
    return this.http
      .post<{ result: boolean }>(
        `${apiUrl}/${ENDPOINTS.authentication.recoveryPassword}`,
        {
          username,
          venueId,
          client: 'RSCW',
        }
      )
      .pipe(
        map(({ result }) => result),
        catchError(AuthenticationService.handleError)
      );
  }

  // TODO fix any;
  public confirmResetPassword(
    model: ForgetPasswordRequest
  ): Observable<TokenData> {
    return this.http
      .post<TokenData>(
        `${apiUrl}/${ENDPOINTS.authentication.resetPassword}`,
        model
      )
      .pipe(catchError(AuthenticationService.handleError));
  }

  public changePassword(model: ChangePasswordRequest): Observable<void> {
    return this.http
      .post<void>(`${apiUrl}/user-profile/v1/profile/me/password`, model)
      .pipe(catchError(AuthenticationService.handleError));
  }

  // TODO fix any;
  public validateJoin(token: string): Observable<any> {
    return this.http
      .post<any>(`${apiUrl}/${ENDPOINTS.authentication.validateJoin}`, {
        token,
        client,
      })
      .pipe(catchError(AuthenticationService.handleError));
  }
}
