import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'rs-radio-button-box',
  standalone: true,
  imports: [CommonModule, MatRadioModule, MatTooltipModule],
  templateUrl: './radio-button-box.component.html',
  styleUrls: ['./radio-button-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RsRadioButtonBoxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsRadioButtonBoxComponent implements ControlValueAccessor {
  @Input() value!: any;
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() tooltip!: string;

  private onChange!: (value: any) => void;

  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public updateValue($event: MatRadioChange): void {
    !!this.onChange && this.onChange($event.value);
    this.change.emit($event);
    this.changeDetectorRef.detectChanges();
  }

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public writeValue(_: any): void {}
  public registerOnTouched(_: () => void): void {}
}

@Component({
  selector: 'rs-radio-button-box-group',
  standalone: true,
  imports: [CommonModule, MatRadioModule, FormsModule, ReactiveFormsModule],
  template: `
    <mat-radio-group
      class="rs-radio-button-box-group d-flex flex-wrap w-100"
      [formControl]="formControl"
      labelPosition="before"
    >
      <ng-content></ng-content>
    </mat-radio-group>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RsRadioButtonBoxGroupComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsRadioButtonBoxGroupComponent implements ControlValueAccessor {
  @Input() formControl!: FormControl;

  public registerOnChange(_: (value: any) => void): void {}
  public writeValue(_: any): void {}
  public registerOnTouched(_: () => void): void {}
}
