<rs-menu *ngIf="isDesktop; else rsMobile" rs-overlap>
  <button rs-trigger rs-style="dropdown-trigger" [id]="selectLanguageButtonId" class="rs-header-language-button">
    <ng-container *ngTemplateOutlet="rsButtonInner"></ng-container>
  </button>

  <rs-menu-title>
    {{ 'LANGUAGE.Choose your language' | translate }}
  </rs-menu-title>
  <rs-menu-item
    *ngFor="let language of languages"
    (click)="selectLanguage(language)"
  >
    <span class="rs-menu-item__text">
      {{ language.key | uppercase }}
    </span>
    <mat-icon
      *ngIf="selectedLanguage.key === language.key"
      [svgIcon]="checkIcon"
    ></mat-icon>
  </rs-menu-item>
</rs-menu>

<ng-template #rsMobile>
  <button
    *ngIf="!isDesktop"
    [id]="selectLanguageButtonId"
    rs-style="dropdown-trigger"
    (click)="openDialog()"
  >
    <ng-container *ngTemplateOutlet="rsButtonInner"></ng-container>
  </button>
</ng-template>

<ng-template #rsButtonInner>
  <mat-icon [svgIcon]="languageIcon"></mat-icon>
  <span class="rs-language__value">
    {{
      shortText
        ? (selectedLanguage | rsLanguageShort | uppercase)
        : (selectedLanguage.key | titlecase)
    }}
  </span>
</ng-template>
