import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { getParsedVenueName } from '../_utils/common';

const DEFAULT_TITLE = 'Restaurant';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  protected venueName: string = '';

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly title: Title,
    private readonly translateService: TranslateService
  ) {
    super();
    try {
      this.venueName = getParsedVenueName(this.document.location.href);
    } catch (error) {
      console.error('No available restaurant venue');
    }
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    let title = this.buildTitle(routerState);

    if (!!title) {
      title = this.translateService.instant(title);

      if (!!this.venueName) {
        title = `${this.venueName} - ${title}`;
      }
    } else if (!!this.venueName) {
      title = `${this.venueName}`;
    }

    this.title.setTitle(title ?? this.translateService.instant(DEFAULT_TITLE));
  }
}
