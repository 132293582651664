import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[rsCreditCardMask]',
  standalone: true,
})
export class CreditCardMaskDirective implements OnInit {
  constructor(private ngControl: NgControl) {}

  ngOnInit(): void {
    this.applyMask(this.ngControl.value);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.applyMask(value);
  }

  private applyMask(value: string): void {
    const trimmedValue: string = value?.replace(/\D/g, '')?.substring(0, 16);
    const formattedValue: string = trimmedValue
      ?.replace(/(\d{4})/g, '$1 ')
      ?.trim();

    this.ngControl.control?.setValue(formattedValue, { emitEvent: false });
  }
}
