import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SharedModule } from '../../_modules/shared.module';
import { RsDialogComponent } from '../../_rs-design/dialog/dialog.component';

export interface MessagePopupData {
  title: string;
  message: string;
}

@Component({
  selector: 'rs-message-popup',
  standalone: true,
  imports: [SharedModule, RsDialogComponent],
  templateUrl: './message-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagePopupComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: MessagePopupData) {}
}
