import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { firstValueFrom, map } from 'rxjs';

import {
  VehicleBrand,
  VehicleModel,
  VehicleAPIResponse,
} from 'src/app/profile/_interfaces/car.model';

@Injectable({ providedIn: 'root' })
export class CarService {
  constructor(private http: HttpClient) {}

  public getCarBrands(): Promise<VehicleBrand[]> {
    return firstValueFrom(
      this.http
        .get<VehicleAPIResponse<VehicleBrand>>(
          'https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForVehicleType/car?format=json'
        )
        .pipe(map(({ Results }) => Results))
    );
  }

  public getModelsByBrandId(makeId: string): Promise<VehicleModel[]> {
    return firstValueFrom(
      this.http
        .get<VehicleAPIResponse<VehicleModel>>(
          `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeId/${makeId}?format=json`
        )
        .pipe(map(({ Results }) => Results))
    );
  }
}
