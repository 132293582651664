<rs-card-horizontal
  *ngIf="card"
  [active]="activeCard"
  (click)="selectCard.emit(card)"
>
  <rs-card-horizontal-title>
    {{ card.cardholderName }}
  </rs-card-horizontal-title>
  <rs-card-horizontal-line>
    <div class="rs-payment-item">
      <img
        height="20px"
        width="30px"
        alt="card"
        loading="lazy"
        class="rs-payment__card-image"
        [src]="card.cardBrand | rsCardImageByBrand"
      />
      <p>{{ card.redactedCardNumber }}</p>
    </div>
  </rs-card-horizontal-line>
</rs-card-horizontal>
