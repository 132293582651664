<div class="rs-billing-address w-100" [formGroup]="addressForm">
  <mat-form-field
    *ngIf="$any(addressForm.controls).nickname"
    class="rs-v3-input--small w-100"
    appearance="outline"
  >
    <input
      matInput
      formControlName="nickname"
      [placeholder]="'PROFILE.payment.address_nickname' | translate"
    />
    <mat-error *ngIf="addressForm.get('nickname')!.hasError('required')">
      {{ 'VALIDATION.REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <rs-address-autocomplete
    *ngIf="$any(addressForm.controls).address"
    placeholder="{{ 'PROFILE.payment.address_line_1' | translate }}"
    [rsFormControl]="$any(addressForm.controls).address"
    (addressDetails)="reactOnAddressDetailsFound($event)"
  ></rs-address-autocomplete>
  <rs-address-autocomplete
    *ngIf="$any(addressForm.controls).address1"
    placeholder="{{ 'PROFILE.payment.address_line_1' | translate }}"
    [rsFormControl]="$any(addressForm.controls).address1"
    (addressDetails)="reactOnAddressDetailsFound($event)"
  ></rs-address-autocomplete>
  <mat-form-field class="rs-v3-input--small w-100" appearance="outline">
    <input
      matInput
      formControlName="address2"
      [value]="
        $any(addressForm.controls).address2.value | rsAddressWithoutCommas
      "
      [placeholder]="'PROFILE.payment.address_line_2' | translate"
    />
    <mat-error *ngIf="addressForm.get('address2')!.hasError('required')">
      {{ 'VALIDATION.REQUIRED' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field
    *ngIf="$any(addressForm.controls).businessName"
    class="rs-v3-input--small w-100"
    appearance="outline"
  >
    <input
      matInput
      formControlName="businessName"
      [placeholder]="'PROFILE.payment.business_name' | translate"
    />
  </mat-form-field>
  <div [ngClass]="{ 'rs-billing-address--half': isHalfSeparatedInputs }">
    <mat-form-field class="rs-v3-input--small w-100" appearance="outline">
      <input
        matInput
        formControlName="city"
        [placeholder]="'PROFILE.payment.city' | translate"
      />
      <mat-error *ngIf="addressForm.get('city')!.hasError('required')">
        {{ 'VALIDATION.REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="rs-v3-input--small w-100" appearance="outline">
      <input
        matInput
        formControlName="state"
        [placeholder]="'PROFILE.payment.state' | translate"
      />
      <mat-error
        *ngIf="addressForm.get('state')!.hasError('required')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div [ngClass]="{ 'rs-billing-address--half': isHalfSeparatedInputs }">
    <mat-form-field
      *ngIf="$any(addressForm.controls).zipCode"
      class="rs-v3-input--small w-100"
      appearance="outline"
    >
      <input
        matInput
        mask="00000"
        formControlName="zipCode"
        [placeholder]="'PROFILE.payment.zip_code' | translate"
      />
      <mat-error *ngIf="addressForm.get('zipCode')!.hasError('required')">
        {{ 'VALIDATION.REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field
      *ngIf="$any(addressForm.controls).zipcode"
      class="rs-v3-input--small w-100"
      appearance="outline"
    >
      <input
        matInput
        mask="00000"
        formControlName="zipcode"
        [placeholder]="'PROFILE.payment.zip_code' | translate"
      />
      <mat-error *ngIf="addressForm.get('zipcode')!.hasError('required')">
        {{ 'VALIDATION.REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>

    <ng-container
      *ngIf="$any(addressForm.controls).country; let countryControl"
    >
      <mat-form-field
        *ngIf="!isCountrySelector"
        class="rs-v3-input--small w-100"
        appearance="outline"
      >
        <input
          matInput
          formControlName="country"
          [placeholder]="'PROFILE.payment.country' | translate"
        />
        <mat-error *ngIf="addressForm.get('country')!.hasError('required')">
          {{ 'VALIDATION.REQUIRED' | translate }}
        </mat-error>
      </mat-form-field>

      <rs-country-select
        *ngIf="isCountrySelector"
        class="rs-billing-address__country-selector"
        [rsCountryControl]="countryControl"
      ></rs-country-select>
    </ng-container>
  </div>

  <mat-form-field
    *ngIf="$any(addressForm.controls).moreInstruction"
    class="rs-v3-input--small w-100"
    appearance="outline"
  >
    <input
      matInput
      formControlName="moreInstruction"
      [placeholder]="'PROFILE.payment.more_instructions' | translate"
    />
  </mat-form-field>
</div>
