<ng-container *ngIf="itemDetails$ | async as itemDetails">
  <ng-template [ngIf]="itemDetails.bundleAttributes.items">
    <rs-combo-item
      *ngIf="sizeId"
      [addedBundleItems]="bundleItemsControl.value"
      [items]="itemDetails?.bundleAttributes?.items || []"
      [bundleItemSizeAttributes]="
        itemDetails | getComboItemSizeAttributes: sizeId
      "
      [comboAttributes]="itemDetails | getComboAttributes: sizeId"
      [disableOrdering]="disableOrdering"
      (bundleItemsSelected)="updateBundleItemsSelected($event)"
    ></rs-combo-item>
  </ng-template>
</ng-container>

<ng-container *ngIf="bundleItemModifiers$ | async; let bundleItemModifiers">
  <ng-template
    ngFor
    let-itemModifiers
    [ngForOf]="bundleItemModifiers"
    let-index="index"
  >
    <rs-bundle-item
      *ngIf="itemModifiers.modifiers?.length"
      [item]="itemModifiers.item"
      [bundleSectionId]="itemModifiers.bundleSectionId"
      [modifiers]="itemModifiers.modifiers"
      [itemIndex]="
        (itemModifiers.item | isBundleItemRepeated: bundleItemModifiers)
          ? (itemModifiers.item | bundleItemNumber: bundleItemModifiers:index)
          : 0
      "
      [disableOrdering]="disableOrdering"
      (bundleItemUpdated)="updateBundleItem(itemModifiers.item.id, $event)"
      (modifiersControlsValid)="
        reactOnModifiersValidity(itemModifiers.item.id, $event)
      "
    >
    </rs-bundle-item>
  </ng-template>
</ng-container>
