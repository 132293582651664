import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AddressBookService {
  constructor(private http: HttpClient) {}

  public getAddressBookNotMock(): Observable<UserProfileAddress[]> {
    return this.http.get<UserProfileAddress[]>(
      `${apiUrl}/user-profile/v1/profile/me/address`
    );
  }

  public createAddress(address: any): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      `${apiUrl}/user-profile/v1/profile/me/address`,
      address
    );
  }

  public updateAddress(address: any, addressId: string): Observable<void> {
    return this.http.put<void>(
      `${apiUrl}/user-profile/v1/profile/me/address/${addressId}`,
      address
    );
  }

  public deleteAddress(addressId: string): Observable<void> {
    return this.http.delete<void>(
      `${apiUrl}/user-profile/v1/profile/me/address/${addressId}`
    );
  }
}
