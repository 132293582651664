import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { Currency } from '../_models/currency.model';

export const defaultCurrencyCode = 'USD';
export const defaultCurrencyDisplayType = 'symbol';
export const defaultCurrencyRounding = '1.2-2';

export const CUSTOM_AMOUNT_MASK_CONFIG: CurrencyMaskConfig = {
  align: 'right',
  precision: 2,
  suffix: '',
  prefix: '$',
  thousands: ',',
  decimal: '.',
  allowNegative: false,
};

export const DEFAULT_CURRENCY: Currency = {
  type: 'USD',
  symbol: '$',
  decimal: 2,
};
