import { Pipe, PipeTransform } from '@angular/core';
import { Address, CardResponse } from '../_models/common.interface';

import { removeCommas } from './../_utils/common';
import { Card } from 'src/app/profile/_interfaces/payment.model';
import { AddressForm } from '../_interfaces/address.model';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import {
  getAddressesListWithDefaultFirst,
  getFullAddressDescription,
  getUSAddresses,
} from '../_utils/address.helper';

@Pipe({ name: 'rsAddressWithoutCommas' })
export class AddressWithoutCommas implements PipeTransform {
  public transform(address: string | undefined): string {
    return !!address ? removeCommas(address) : '';
  }
}

@Pipe({ name: 'rsFullAddress' })
export class FullAddressPipe implements PipeTransform {
  public transform(address: Address | Card | CardResponse | undefined): string {
    if (!address) {
      return '';
    }

    const convertedAddress = address as any;

    return [
      convertedAddress.address1 || convertedAddress.address || '',
      convertedAddress.city || '',
      convertedAddress.state || '',
      convertedAddress.zipcode || '',
    ]
      .filter(part => !!part)
      .reduce((part, result) => (part ? result + ', ' + part : result), '');
  }
}

@Pipe({ name: 'rsRearrangeAddressesList' })
export class RearrangeAddressesListPipe implements PipeTransform {
  public transform(
    addresses: UserProfileAddress[] | null
  ): UserProfileAddress[] {
    return getAddressesListWithDefaultFirst(addresses);
  }
}

@Pipe({ name: 'rsGetUSAddresses' })
export class GetUSAddressesPipe implements PipeTransform {
  public transform(
    addresses: UserProfileAddress[] | null
  ): UserProfileAddress[] {
    return getUSAddresses(addresses);
  }
}

@Pipe({ name: 'rsOrderFullAddress', standalone: true })
export class GetOrderFullAddressPipe implements PipeTransform {
  public transform(
    address: AddressForm | UserProfileAddress | undefined | null
  ): string {
    return getFullAddressDescription(address);
  }
}

const ENTER_YOUR_ADDRESS_TRANSLATION: string =
  'DELIVERY_METHOD.Enter your address';

@Pipe({ name: 'rsAddress1', standalone: true })
export class RsGetAddress1Pipe implements PipeTransform {
  public transform(address?: Address): string {
    return address?.address1 || ENTER_YOUR_ADDRESS_TRANSLATION;
  }
}

@Pipe({ name: 'rsAddress1WithCity', standalone: true })
export class RsGetAddress1WithCityPipe implements PipeTransform {
  public transform(address?: Address | AddressForm): string {
    if (!address) {
      return ENTER_YOUR_ADDRESS_TRANSLATION;
    }

    const { address1, city } = address;
    const displayAddress = `${address1}${address1 && city ? ', ' : ''}${city}`;

    return displayAddress || ENTER_YOUR_ADDRESS_TRANSLATION;
  }
}

@Pipe({ name: 'rsIsAddressesTheSame', standalone: true })
export class RsIsAddressesTheSamePipe implements PipeTransform {
  public transform(
    address1: UserProfileAddress,
    address2: UserProfileAddress | undefined | null
  ): boolean {
    return (
      address1?.address1 === address2?.address1 &&
      address1?.address2 === address2?.address2 &&
      address1?.zipcode === address2?.zipcode &&
      address1?.city === address2?.city
    );
  }
}
