import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

interface SplashStateModel {
  isHidden: boolean;
}

export class ShowSplash {
  static readonly type = '[Splash] Show';
}

export class HideSplash {
  static readonly type = '[Splash] Hide';
}

@State<SplashStateModel>({
  name: 'splash',
  defaults: {
    isHidden: false,
  },
})
@Injectable()
export class SplashState {
  @Selector()
  static isHidden({ isHidden }: SplashStateModel): boolean {
    return isHidden;
  }

  @Action(ShowSplash)
  showSplash({ patchState }: StateContext<SplashStateModel>) {
    patchState({
      isHidden: false,
    });
  }

  @Action(HideSplash)
  hideSplash({ patchState }: StateContext<SplashStateModel>) {
    patchState({
      isHidden: true,
    });
  }
}
