<div class="rs-car-item" *ngIf="car">
  <div class="rs-car-item__icon">
    <img src="../../../../../../assets/images/car.png" alt="car" />
  </div>
  <div class="rs-car-item__info">
    <div class="rs-car-item__info-title">{{ car.make }} - {{ car.model }}</div>
    <div class="rs-car-item__info-description">
      {{ car.color }} - {{ car.plate }}
    </div>
  </div>
</div>
