import { environment } from '../../../environments/environment';

const env = environment.env;

export const ENDPOINTS = {
  catalog: {
    calculate: 'catalog/v1/public/item/calculate-prices',
    tag: 'catalog/v1/ca/tag',
  },
  order: {
    createOrder: 'order/v1/guest',
    createProfileOrder: 'order/v1/ca',
    createLoggedGuestOrder: (orderId: string) =>
      `order/v1/ca/dine-in/${orderId}/diner`,
    getOrderDetails: (orderId: string | null) => `order/v1/ca/${orderId}`,
    addDiner: (orderId: string) => `order/v1/guest/dine-in/${orderId}/diner`,
    updateDineInOrder: (orderId: string) =>
      `order/v1/guest/dine-in/${orderId}/item`,
    inviteGuests: (orderId: string | null) =>
      `order/v1/guest/dine-in/${orderId}/invite`,
    favorite: (orderId: string | null) => `order/v1/ca/${orderId}/favorite`,
    cancelOrder: (orderId: string | null) => `order/v1/ca/${orderId}/cancel`,
    getReceipt: (paymentId: string) =>
      `order/v1/ca/receipt/payment/${paymentId}/download`,
    getItemsSuggestions: (
      venueId: string,
      startDate: string,
      endDate: string,
      pageSize: number
    ) =>
      `/order/v1/ca/item?pageSize=${pageSize}&from=${startDate}&to=${endDate}&venueId=${venueId}`,
    review: (checkId: string) => `review/v1/check/${checkId}`,
    updateReview: (orderId: string) => `review/v1/order/${orderId}`,
    closeLogInTab: (orderId: string) => `order/v1/ca/${orderId}/close`,
    closeGuestTab: (orderId: string) =>
      `order/v1/guest/dine-in/${orderId}/close`,
    addToTabForLoggedInUser: (orderId: string) => `order/v1/ca/${orderId}/item`,
  },
  delivery: {
    createDelivery: 'delivery/v1/delivery',
    initiateDelivery: (deliveryId: string) =>
      `delivery/v1/delivery/${deliveryId}/initiate`,
    createDeliveryQuote: 'delivery/v1/cw/quote',
    getDelivery: (deliveryId: string) => `delivery/v1/delivery/${deliveryId}`,
    cancelDelivery: (deliveryId: string) =>
      `delivery/v1/delivery/${deliveryId}/cancel`,
    handleBurqWebhook: 'delivery/v1/burq/webhook',
  },
  authentication: {
    login: 'authentication/v1/user/login',
    authentication: 'user-profile/profile/me',
    changePassword: 'user-profile/profile/me/password',
    signUp: 'user-profile/v1/profile/join',
    registrationPart: 'authentication/v1/recovery',
    recoveryPassword: 'authentication/v1/recovery/password',
    resetPassword: 'authentication/v1/recovery/password/confirm',
    refreshToken: 'authentication/v1/user/login/refresh',
    validateEmail: 'user-profile/profile/email/validate',
    validateJoin: 'user-profile/profile/join/validate',
    shadowUserToken: 'order/v1/guest/user',
  },
  payment: {
    cardToken: env === 'prod' ? 'payment/card-token' : 'payment/v2/card-token',
    createCardNotPresentPayment:
      env === 'prod'
        ? 'payment/consumer/cardnotpresent'
        : 'payment/v2/consumer/cardnotpresent',
    details: (paymentId: string) =>
      env === 'prod'
        ? `payment/consumer/${paymentId}`
        : `payment/v2/consumer/${paymentId}`,
    giftCardPayment:
      env === 'prod'
        ? 'payment/consumer/gift-card'
        : 'payment/v2/consumer/gift-card',
    applePayPayment:
      env === 'prod' ? 'payment/apple-pay' : 'payment/v2/apple-pay',
    googlePayPayment:
      env === 'prod' ? 'payment/google-pay' : 'payment/v2/google-pay',
  },
  wallet: {
    createGiftCard: 'wallet/v1/consumer/gift-card',
    mergeGiftCards: 'wallet/v1/gift-card/merge',
    giftCardDetails: (id: string) => `wallet/v1/guest/gift-card/${id}`,
  },
  profile: {
    pictire: (profileId: string) =>
      `user-profile/v1/profile/${profileId}/picture`,
    favorite: 'search/v2/favorite',
  },
  merchant: {
    merchantInformation: (id: string) => `merchant-profile/v2/venue/${id}`,
  },
};
