<ng-container *ngIf="isDialog; else rsPartialContent">
  <rs-dialog rs-dialog-style="default">
    <ng-container rs-dialog-header>
      <span> {{ 'ADDRESS_BOOK.new_address' | translate }}</span>
    </ng-container>

    <ng-container *ngTemplateOutlet="rsPartialContent"></ng-container>

    <ng-container rs-dialog-actions-direction="row">
      <ng-container *ngTemplateOutlet="rsPartialButtons"></ng-container>
    </ng-container>
  </rs-dialog>
</ng-container>

<ng-template #rsPartialContent>
  <div class="rs-new-address">
    <div
      [class.rs-new-address__form-wrapper]="!isEdit"
      class="rs-new-address__header"
    >
      <div class="rs-new-address__title" *ngIf="!isDialog">
        {{ 'ADDRESS_BOOK.new_address' | translate }}
      </div>
      <form class="rs-new-address__form">
        <rs-address-form
          [hideCountry]="false"
          [hideNickName]="false"
          [hideInstructions]="false"
          [rsAddressForm]="newAddressForm"
          [resetFormSubject]="resetFormSubject"
        ></rs-address-form>
        <div class="rs-new-address__control rs-checkbox-wrapper">
          <mat-checkbox
            (change)="reactOnCheckboxChange($event)"
            [formControl]="$any(newAddressForm.controls).isDefault"
            >{{ 'ADDRESS_BOOK.make_as_default' | translate }}</mat-checkbox
          >
        </div>
      </form>
    </div>
    <div *ngIf="!isDialog">
      <ng-container *ngTemplateOutlet="rsPartialButtons"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #rsPartialButtons>
  <button
    *ngIf="!isEdit; else saveChangesButton"
    [disabled]="!newAddressForm.valid"
    (click)="submit()"
    rs-style="bold"
  >
    <span>{{ 'save' | translate | uppercase }}</span>
  </button>
  <ng-template #saveChangesButton>
    <button
      [disabled]="doesAddressHaveChanges()"
      (click)="saveChanges()"
      class="w-100"
      rs-style="bold"
    >
      <span>{{ 'save_changes' | translate | uppercase }}</span>
    </button>
  </ng-template>
</ng-template>
