import { CartEntity } from './cart.state';
import { StateItem, Tips } from '../_interfaces/item.model';
import { GiftCard } from './../_interfaces/gift-card.model';
import { QuoteSearchingDriver } from '../_interfaces/order.model';
import { Card } from 'src/app/profile/_interfaces/payment.model';
import { GuestUserFormData } from '../_interfaces/session.model';
import { CheckCalculation } from '../_interfaces/check-calculation.model';
import { GiftCardInForSelection } from '../_components/list-of-items-in-order/models';
import { NewCardToAdd, Phone } from '../_models/common.interface';
import { OrderItemType } from '../_enums/order-item-type.enum';
import { PaymentMethods } from '../_enums/payment-methods.enum';
import { ItemCategory } from '../_enums/item-category.enum';

export class AddToCart {
  static readonly type = '[Cart] Add Item to cart';

  constructor(public item: StateItem, public category?: ItemCategory) {}
}

export class AddGiftCard {
  static readonly type = '[Cart] Add Gift Card';

  constructor(public giftCard: GiftCard) {}
}

export class ClearGiftCards {
  static readonly type = '[Cart] Clear Gift Cards';
}

export class RemoveItemFromCart {
  static readonly type = '[Cart] Remove Item From Cart';

  constructor(
    public item: GiftCardInForSelection,
    public itemType: OrderItemType | null
  ) {}
}

export class DeleteItem {
  static readonly type = '[Cart] Delete Item';

  constructor(public item: CartEntity, public itemType: OrderItemType | null) {}
}

export class SetItems {
  static readonly type = '[Cart] Set Items';

  constructor(public items: (StateItem | GiftCard)[]) {}
}

export class ClearItems {
  static readonly type = '[Cart] Clear Items';
}

export class SetTips {
  static readonly type = '[Cart] Set Tips';

  constructor(public tips: Tips) {}
}

export class ResetTips {
  static readonly type = '[Cart] Reset Tips';
}

export class ClearCart {
  static readonly type = '[Cart] Clear Cart';
}

export class ClearAddressInDeliveryData {
  static readonly type = '[Cart] Clear Address In Delivery Data';
}

export class PlaceOrder {
  static readonly type = '[Cart] Place Order';
}

export class CalculatePrices {
  static readonly type = '[Cart] Calculate Prices';
}

export class SetOverallPrice {
  static readonly type = '[Cart] Set Overall Price';
}
export class CalculateCheck {
  static readonly type = '[Check] Calculate';

  constructor(
    public isGiftCard: boolean = true,
    public orderType: string = 'dine-in',
    public openDialog: boolean = true
  ) {}
}

export class SetTableId {
  static readonly type = '[Table] Set Table ID';

  constructor(public tableId: string) {}
}

export class SetMenuId {
  static readonly type = '[Table] Set Menu ID';

  constructor(public menuId: string) {}
}

export class SetTableName {
  static readonly type = '[Table] Set Table Name';

  constructor(public tableName: string) {}
}

export class SetGuestUserData {
  static readonly type = '[Guest] Set Guest User Data';

  constructor(
    public guestUserFormData: GuestUserFormData,
    public isFormDataValid: boolean
  ) {}
}

export class SetGuestUserPhoneData {
  static readonly type = '[Cart] Set Guest User Phone Data';

  constructor(public readonly phone: Phone) {}
}

export class ClearGuestUserData {
  static readonly type = '[Guest] Clear Guest User Data';
}

export class SetTableSectionId {
  static readonly type = '[Table] Set Table Section ID';

  constructor(public sectionId: string) {}
}

export class CalculateCheckAndCreateDeliveryQuote {
  static readonly type = '[Cart] Set Delivery Data';

  constructor(
    public ignoreLoader?: boolean,
    public readonly searchingForDriver?: boolean
  ) {}
}

export class SavePaymentCard {
  static readonly type = '[Cart] Save Payment Card';

  constructor(public card: NewCardToAdd) {}
}

export class SaveGuestPaymentCard {
  static readonly type = '[Cart] Save Guest Payment Card';

  constructor(public card: NewCardToAdd) {}
}

export class SelectCardForOrder {
  static readonly type = '[Cart] Select Card For Order';

  constructor(public card: Card | null) {}
}

export class SelectGiftCardForOrder {
  static readonly type = '[Cart] Select Gift Card For Order';

  constructor(public giftcard: GiftCard | null) {}
}

export class GiftCardDetails {
  static readonly type = '[Cart] Get Gift Card Details';

  constructor(public scannedGiftcardId: string) {}
}

export class ClearSelectedGiftCardError {
  static readonly type = '[Cart] Clear Selected Gift Card Error';
}

export class MergeGiftCard {
  static readonly type = '[Cart] Merge Gift Cards Balance';

  constructor(public giftCardFrom: string, public giftCardTo: string) {}
}

export class SetGiftCards {
  static readonly type = '[Cart] Set Gift Cards';

  constructor(public giftCards: GiftCard[]) {}
}

export class CreateDeliveryQuote {
  public static readonly type = '[Cart] Create Delivery Quote';

  constructor(
    public readonly ignoreLoader?: boolean,
    public readonly quoteSearchingDriver?: QuoteSearchingDriver
  ) {}
}

export class SetIsSearchingDriver {
  public static readonly type = '[Cart] Set Is Searching Driver';

  constructor(
    public readonly isSearchingDriver: boolean,
    public readonly isNoDriverAvailable: boolean
  ) {}
}

export class SetPaymentMethod {
  public static readonly type = '[Cart] Set Payment Method';

  constructor(public readonly paymentMethod: PaymentMethods) {}
}

export class SetCompletePaymentMethod {
  public static readonly type = '[Cart] Set Complete Payment Method';

  constructor(
    public readonly completePaymentMethod:
      | PaymentMethods
      | undefined = undefined
  ) {}
}

export class SetIsGiftCardCoverTotal {
  public static readonly type = '[Cart] Set Is Gift Card Cover Total';

  constructor(public readonly isGiftCardCoverTotal: boolean) {}
}

export class SetTranslations {
  public static readonly type = '[Cart] Set Translations';
}

export class SetCartEditing {
  public static readonly type = '[Cart] Set Editing';

  constructor(public readonly value: boolean) {}
}

export class ResetCheckCalculations {
  public static readonly type = '[Cart] Reset Check Calculations';
}

export class InitializeCheckout {
  public static readonly type = '[Cart] Initialize Checkout';

  constructor() {}
  // public readonly items: StateItem[],
  // public readonly isGifCard: boolean
}

export class SetCheckCalculations {
  public static readonly type = '[Cart] Set Check Calculations';

  constructor(public readonly checkCalculations: CheckCalculation) {}
}

export class ClearSenderDataOfGiftCards {
  public static readonly type = '[Cart] Clear Sender Data Of GiftCards';
}

export class AssignSenderToGiftCard {
  public static readonly type = '[Cart] Assign Sender To Gift Card';

  constructor(
    public senderInfo: {
      senderName: string;
      senderEmail: string;
      senderProfileId?: string;
    }
  ) {}
}
