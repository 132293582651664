<div class="rs-schedule-order-time">
  <div class="rs-schedule-order-time__title">
    {{ title }}
  </div>
  <div class="rs-schedule-order-time__time-list">
    <ng-container
      *ngIf="timeList?.length && !noAvailableTime; else rsNoAvailableTime"
    >
      <div
        class="rs-schedule-order-time__time cursor-pointer"
        *ngFor="let time of timeList"
        [ngClass]="{
          'rs-schedule-order-time__time--selected': selectedTime === time
        }"
        (click)="selectTime(time)"
      >
        {{
          time
            | uppercaseForFirstSymbol
            | translateByPathsInFile: translationsPaths
            | async
            | rsEasternArabicNumerals
        }}
      </div>
    </ng-container>
  </div>
</div>

<ng-template #rsNoAvailableTime>
  <p>
    {{ 'SCHEDULE.no_available_time' | translate }}
  </p>
</ng-template>
