import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rs-invite-success-popup',
  templateUrl: './invite-success-popup.component.html',
  styleUrls: ['./invite-success-popup.component.scss'],
})
export class InviteSuccessPopupPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      phones: string[];
    }
  ) {}
}
