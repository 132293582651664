import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  RsDrawerComponent,
  RsDrawerItemComponent,
  RsDrawerListComponent,
  RsDrawerSectionHeaderComponent,
  RsDrawerTitleComponent,
} from './drawer.component';

const components = [
  RsDrawerTitleComponent,
  RsDrawerSectionHeaderComponent,
  RsDrawerItemComponent,
  RsDrawerListComponent,
  RsDrawerComponent,
];

@NgModule({
  imports: [CommonModule, ...components],
  exports: [...components],
})
export class DrawerModule {}
