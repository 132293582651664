import { Pipe, PipeTransform } from '@angular/core';

import { MenuItem } from 'src/app/profile/_interfaces/menu-items.model';

@Pipe({ name: 'rsIsProfileMenuItemSelected' })
export class IsProfileMenuItemSelectedPipe implements PipeTransform {
  public transform(
    menuItem: MenuItem,
    selectedMenuItem: MenuItem | null
  ): boolean {
    return !!selectedMenuItem && menuItem.key === selectedMenuItem.key;
  }
}

@Pipe({ name: 'rsIsProfileRouteActive', standalone: true })
export class IsProfileRouteActivePipe implements PipeTransform {
  public transform(routePath: string, currentUrl: string | null): boolean {
    return !!currentUrl && !!routePath && currentUrl.endsWith(routePath);
  }
}
