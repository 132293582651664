import { Pipe, PipeTransform } from '@angular/core';
import {
  Modifier,
  ModifierOption,
  SelectedModifier,
} from '../_interfaces/item.model';

const getSelectedModifier = (modifierId: string, metadata: any): null | any => {
  if (!metadata?.modifierSelections || !modifierId) {
    return null;
  }

  return metadata.modifierSelections.find(
    (modifierSelection: any) => modifierSelection.modifierId === modifierId
  );
};

@Pipe({ name: 'rsIsSelectedItemOption' })
export class IsSelectedItemOptionPipe implements PipeTransform {
  public transform(
    option: ModifierOption,
    modifierId: string,
    metadata: any
  ): boolean {
    if (!option?.id || !modifierId || !metadata) {
      return false;
    }

    const currentModifier = getSelectedModifier(modifierId, metadata);

    if (!currentModifier) {
      return false;
    }

    return !!currentModifier.modifierOptionIds.find(
      (modifierOptionId: string) => modifierOptionId === option.id
    );
  }
}

@Pipe({ name: 'rsIsSelectedItemModifier' })
export class IsSelectedItemModifierPipe implements PipeTransform {
  public transform(modifierId: string, metadata: any): boolean {
    return !!getSelectedModifier(modifierId, metadata);
  }
}

@Pipe({ name: 'rsGetSelectedCountOfItemOption' })
export class GetSelectedCountOfItemOptionPipe implements PipeTransform {
  public transform(
    option: ModifierOption,
    modifier: Modifier,
    metadata: any,
    maxCount: number,
    selectedModifiers: SelectedModifier[] = []
  ): number {
    if (
      !option?.id ||
      !modifier?.id ||
      !(metadata || selectedModifiers?.length)
    ) {
      return 0;
    }

    const currentModifier = getSelectedModifier(modifier?.id, metadata);

    if (metadata && currentModifier) {
      const maxPossible =
        maxCount > modifier.maxModifiers ? modifier.maxModifiers : maxCount;
      const orderedCount: number = currentModifier.modifierOptionIds.filter(
        (modifierOptionId: string) => modifierOptionId === option.id
      ).length;

      return orderedCount > maxPossible ? maxPossible : orderedCount;
    }

    const filteredSelectedModifiers: SelectedModifier[] =
      selectedModifiers.filter(
        ({ modifierId, selectedOptionId }: SelectedModifier) =>
          modifierId === modifier.id && selectedOptionId === option.id
      );

    return filteredSelectedModifiers.length;
  }
}
