import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Store } from '@ngxs/store';

import { VenueState } from 'src/app/_shared/_ngxs/venue.state';
import { GiftCard } from 'src/app/_shared/_interfaces/gift-card.model';
import { CommonIcon } from 'src/app/_shared/_enums/custom-icons.enum';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { DeviceService } from 'src/app/_services/device.service';

@Component({
  selector: 'rs-gift-card-item-view',
  templateUrl: './gift-card-item-view.component.html',
  styleUrls: ['./gift-card-item-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftCardItemViewComponent {
  @Input() card!: GiftCard;
  @Input() isDeleteAvailable: boolean = false;
  @Input() showPrintButton: boolean = false;
  @Input() giftCardNumberDefined: boolean = true;
  @Input() elementId?: string = '';

  @Output() decreaseItemQuantity = new EventEmitter<string>();
  @Output() printPhysicalCard = new EventEmitter<GiftCard>();

  public venueName!: string;
  public readonly CommonIcon = CommonIcon;
  public isMobileView: boolean = false;

  private readonly destroyed$ = untilDestroyed();

  constructor(
    private readonly store: Store,
    private readonly svgService: SvgIconService,
    protected readonly deviceService: DeviceService
  ) {
    this.svgService.registerSvgIcons([CommonIcon.Print]);
  }

  public ngOnInit(): void {
    this.setVenueName();
    this.setView();
  }

  private setVenueName(): void {
    this.venueName = this.store.selectSnapshot(VenueState.venue)?.name;
  }

  private setView(): void {
    this.deviceService
      .isMobile()
      .pipe(this.destroyed$())
      .subscribe(isMobile => (this.isMobileView = isMobile));
  }
}
