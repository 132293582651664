import { MatDialogConfig } from '@angular/material/dialog';

import { MatDialogId } from '../_enums/mat-dialog-id.enum';
import { DIALOG_PANEL_CLASS } from './mat-dialog-panel-classes.constant';

export const DEFAULT_DIALOG_CONFIG = {} as MatDialogConfig;

export const FULL_SCREEN_DIALOG_CONFIG: MatDialogConfig = {
  autoFocus: 'dialog',
  maxWidth: '100vw',
  height: '100%',
  width: '100%',
  panelClass: [
    DIALOG_PANEL_CLASS.fullScreenModal,
    DIALOG_PANEL_CLASS.dialogWithMax100dvhHeight,
  ],
};

export const DESKTOP_DIALOG_CONFIG: MatDialogConfig = {
  autoFocus: false,
  width: '100%',
  maxWidth: '490px',
  height: '100%',
  maxHeight: '555px',
};

export const FULL_SCREEN_DIALOG_WITH_2_SCROLLABLE_PARTS_CONFIG: MatDialogConfig =
  {
    autoFocus: 'dialog',
    maxWidth: '100vw',
    width: '100%',
    panelClass: [
      DIALOG_PANEL_CLASS.fullScreenModalWith2ScrollableParts,
      DIALOG_PANEL_CLASS.dialogWith100dvhHeight,
      DIALOG_PANEL_CLASS.dialogWithMax100dvhHeight,
    ],
  };

export const DINE_IN_CART_FULL_SCREEN_DIALOG_CONFIG: MatDialogConfig = {
  autoFocus: 'dialog',
  maxWidth: '100vw',
  height: '100%',
  width: '100%',
  panelClass: [
    DIALOG_PANEL_CLASS.fullScreenModal,
    DIALOG_PANEL_CLASS.dineInCartFullScreenModal,
    DIALOG_PANEL_CLASS.dialogWithMax100dvhHeight,
  ],
};

export const ADDRESS_DIALOG_CONFIG: MatDialogConfig = {
  width: '100%',
  maxWidth: '490px',
  height: '100%',
  maxHeight: '555px',
  panelClass: DIALOG_PANEL_CLASS.chooseAddress,
  autoFocus: false,
};

export const CHANGE_ADDRESS_DIALOG_CONFIG: MatDialogConfig = {
  id: MatDialogId.change_address,
  ...ADDRESS_DIALOG_CONFIG,
  height: 'min-height',
};

export const NEW_ADDRESS_DIALOG_CONFIG: MatDialogConfig = {
  id: MatDialogId.new_delivery_address,
  ...ADDRESS_DIALOG_CONFIG,
};

export const LOGIN_DIALOG_CONFIG: MatDialogConfig = {
  id: MatDialogId.login,
  ...FULL_SCREEN_DIALOG_CONFIG,
};

export const CART_DIALOG_CONFIG: MatDialogConfig = {
  id: MatDialogId.cart,
  ...FULL_SCREEN_DIALOG_WITH_2_SCROLLABLE_PARTS_CONFIG,
};

export const DINE_IN_CHANGE_PAYMENT_DIALOG_CONFIG: MatDialogConfig = {
  id: MatDialogId.dine_in_change_payment,
  ...DINE_IN_CART_FULL_SCREEN_DIALOG_CONFIG,
};

export const DINE_IN_ADD_PAYMENT_DIALOG_CONFIG: MatDialogConfig = {
  id: MatDialogId.dine_in_add_payment,
  ...DINE_IN_CART_FULL_SCREEN_DIALOG_CONFIG,
};

export const SCHEDULE_DELIVERY_CONFIG: MatDialogConfig = {
  id: MatDialogId.schedule_delivery,
  ...ADDRESS_DIALOG_CONFIG,
  maxWidth: '730px',
  autoFocus: false,
};

export const PROFILE_ORDER_DETAILS: MatDialogConfig = {
  id: MatDialogId.profile_order_details,
  autoFocus: 'dialog',
  maxWidth: '100vw',
  height: '100%',
  width: '100%',
  panelClass: [
    DIALOG_PANEL_CLASS.fullScreenModal,
    DIALOG_PANEL_CLASS.profileOrderDetails,
    DIALOG_PANEL_CLASS.dialogWithMax100dvhHeight,
  ],
};

export const DIETARY_RESTRICTIONS_POPUP_CONFIG: MatDialogConfig = {
  id: MatDialogId.allergens_popup,
  ...FULL_SCREEN_DIALOG_CONFIG,
};
