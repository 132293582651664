import { Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngxs/store';

import moment from 'moment';

import { LanguageState } from '../_ngxs/language.state';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { SessionStorageEngine } from 'src/app/_services/session-storage.service';
import { SessionStorageKeys } from '../_enums/session-storage-keys.enum';
import { LocaleStorageKeys } from '../_enums/local-storage-keys.enum';

@Pipe({ name: 'timeAgo' })
export class TimeAgoPipe implements PipeTransform {
  constructor(
    private readonly sessionStorage: SessionStorageEngine,
    private readonly localStorage: LocalStorageService,
    private readonly store: Store
  ) {}

  transform(dateString: Date | string): string {
    let timeAgo: string = '';

    if (dateString) {
      const now = new Date().valueOf();
      const date = new Date(dateString).valueOf();
      const duration = moment.duration(now - date);
      const minutes = duration.minutes();
      const hours = duration.hours();
      const days = duration.days();
      const months = duration.months();
      const years = duration.years();

      switch (true) {
        case !minutes: {
          timeAgo = 'TIME.just_now';
          break;
        }

        case !days: {
          timeAgo = this.addHoursAndMinutes(timeAgo, hours, minutes);
          break;
        }

        case !months: {
          timeAgo = this.addDays(timeAgo, days);
          break;
        }

        case months >= 1: {
          timeAgo = this.addMonthsAndYears(timeAgo, months, years);
          break;
        }
      }

      timeAgo += minutes && timeAgo ? ' TIME.ago' : '';
    }

    return timeAgo;
  }

  private addHoursAndMinutes(
    timeAgo: string,
    hours: number,
    minutes: number
  ): string {
    timeAgo += hours ? hours + 'TIME.h' : '';
    timeAgo += minutes ? minutes + 'TIME.min' : '';

    return timeAgo;
  }

  private addDays(timeAgo: string, days: number): string {
    // for languages that change word endings, you need to add special conditions
    const isRussianLang = this.getLanguage() === 'ru';

    if (isRussianLang) {
      return (
        timeAgo +
        days +
        ' ' +
        'TIME.day' +
        (this.checkIsSingularInRussian(days) ? '' : 's')
      );
    }

    return timeAgo + days + ' ' + 'TIME.day' + (days > 1 ? 's' : '');
  }

  private addMonthsAndYears(
    timeAgo: string,
    months: number,
    years: number
  ): string {
    const isRussianLang = this.getLanguage() === 'ru';

    if (isRussianLang) {
      timeAgo = this.getMonthsAndYearsForRussianLang(timeAgo, months, years);
    } else {
      timeAgo = this.getCommonMonthsAndYears(timeAgo, months, years);
    }

    return timeAgo;
  }

  private getCommonMonthsAndYears(
    timeAgo: string,
    months: number,
    years: number
  ) {
    timeAgo += years ? years + ' ' + 'TIME.year' + (years > 1 ? 's' : '') : '';
    timeAgo += ' ';
    timeAgo +=
      (timeAgo ? ' ' : '') + months
        ? months + ' ' + 'TIME.month' + (months > 1 ? 's' : '')
        : '';

    return timeAgo;
  }

  private getMonthsAndYearsForRussianLang(
    timeAgo: string,
    months: number,
    years: number
  ): string {
    timeAgo += years
      ? years +
        ' ' +
        'TIME.year' +
        (this.checkIsSingularInRussian(years) ? '' : 's')
      : '';
    timeAgo += ' ';
    timeAgo +=
      (timeAgo ? ' ' : '') + months
        ? months +
          ' ' +
          'TIME.month' +
          (this.checkIsSingularInRussian(months) ? '' : 's')
        : '';

    return timeAgo;
  }

  private checkIsSingularInRussian(value: number): boolean {
    // special conditions for numerals in Russian
    const lastNumber = +value.toString().slice(-1);
    const lastTwoNumbers = +value.toString().slice(-2);

    if (lastTwoNumbers <= 20 && lastTwoNumbers >= 5) {
      return false;
    } else if (lastNumber === 0 || lastNumber > 5) {
      return false;
    } else {
      return true;
    }
  }

  private getLanguage(): string {
    return (
      this.sessionStorage.getItem(SessionStorageKeys.language) ||
      this.localStorage.getItem(LocaleStorageKeys.language) ||
      this.store.selectSnapshot(LanguageState.language)
    );
  }
}
