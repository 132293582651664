import { Pipe, PipeTransform } from '@angular/core';
import { BannerAlertStatusOption, getBannerAlertIcon } from './banner-alert';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

@Pipe({ name: 'rsGetBannerAlertIcon', standalone: true })
export class GetBannerAlertIconPipe implements PipeTransform {
  public transform(status: BannerAlertStatusOption): CommonIcons {
    return getBannerAlertIcon(status);
  }
}
