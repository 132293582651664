export enum PaymentCard {
  AMEX = 'AMEX',
  CHINA_T_UNION = 'CHINA_T_UNION',
  CHINA_UNION_PAY = 'CHINA_UNION_PAY',
  DINERS_CLUB_EN_ROUTE = 'DINERS_CLUB_EN_ROUTE',
  DINERS_CLUB_INTERNATIONALS = 'DINERS_CLUB_INTERNATIONALS',
  DINERS_CLUB_USA_CANADA = 'DINERS_CLUB_USA_CANADA',
  DINERS_CLUB_CARTE_BLANCHE = 'DINERS_CLUB_CARTE_BLANCHE',
  DISCOVER = 'DISCOVER',
  URK_CARD = 'URK_CARD',
  RU_PAY = 'RU_PAY',
  INTER_PAYMENT = 'INTER_PAYMENT',
  INSTA_PAYMENT = 'INSTA_PAYMENT',
  JCB = 'JCB',
  MAESTRO_UK = 'MAESTRO_UK',
  MAESTRO = 'MAESTRO',
  DANKORT = 'DANKORT',
  MIR = 'MIR',
  NPS_PRIDNESTROVIE = 'NPS_PRIDNESTROVIE',
  MASTERCARD = 'MASTERCARD',
  TROY = 'TROY',
  VISA = 'VISA',
  VISA_ELECTRON = 'VISA_ELECTRON',
  LANKA_PAY = 'LANKA_PAY',
  UNKNOWN = 'UNKNOWN',
}
