import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { Observable, of } from 'rxjs';

import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { getVenueName } from 'src/app/_shared/_utils/common';

@Injectable()
export class LoggedInUserGuard implements CanActivate {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly ngZone: NgZone
  ) {}

  public canActivate(): Observable<boolean> {
    return this.ngZone.run(() => {
      const isLoggedIn: boolean = this.store.selectSnapshot(
        SessionState.isLoggedIn
      );
      const venueName: string = getVenueName(this.document.location.href);

      if (!isLoggedIn) {
        const url = `/${venueName}/home/`;

        this.router.navigate([url]);
      }

      return of(isLoggedIn);
    });
  }
}
