import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

interface SpinnerStateModel {
  isVisible: boolean;
}

export class ShowSpinner {
  static readonly type = '[Spinner] Show';
}

export class HideSpinner {
  static readonly type = '[Spinner] Hide';
}

@State<SpinnerStateModel>({
  name: 'spinner',
  defaults: {
    isVisible: false,
  },
})
@Injectable()
export class SpinnerState {
  @Selector()
  static isVisible({ isVisible }: SpinnerStateModel): boolean {
    return isVisible;
  }

  @Action(ShowSpinner)
  showSpinner({ patchState }: StateContext<SpinnerStateModel>) {
    patchState({
      isVisible: true,
    });
  }

  @Action(HideSpinner)
  hideSpinner({ patchState }: StateContext<SpinnerStateModel>) {
    patchState({
      isVisible: false,
    });
  }
}
