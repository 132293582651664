import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { DineInState } from 'src/app/venue/dine-in/_ngxs/dine-in.state';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';

import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { Diner } from 'src/app/_shared/_interfaces/dine-in.model';
import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';
import { CommonIcon } from 'src/app/_shared/_enums/custom-icons.enum';

@Component({
  selector: 'app-diners',
  templateUrl: './diners.component.html',
  styleUrls: ['./diners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DinersComponent {
  public diners$: Observable<Diner[] | null> = this.store.select(
    DineInState.diners
  );
  public tableName$ = this.store.select(CartState.tableName);
  public venue$ = this.store.select(VenueState.venue);

  public Icon = CommonIcon;
  public wrongGroup = false;

  constructor(
    private readonly store: Store,
    private readonly matDialog: MatDialog,
    private readonly svgIconService: SvgIconService
  ) {
    this.svgIconService.registerSvgIcons([CommonIcon.QuestionMark]);
  }

  public close(): void {
    this.matDialog.getDialogById(MatDialogId.diners)?.close(true);
  }
}
