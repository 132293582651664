import { Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngxs/store';

import { ItemState } from '../_ngxs/item.state';

import {
  BundleInformation,
  Config,
  Item,
  ItemWithMenuAndSection,
  Modifier,
  ModifierOption,
  SelectedModifier,
  StateItem,
} from '../_interfaces/item.model';
import { getUniqueItemModifiersNames } from '../_utils/items.helper';

@Pipe({ name: 'itemCounter' })
export class ItemCounterPipe implements PipeTransform {
  transform(itemId: string, itemsInCart: StateItem[]): number {
    return itemsInCart
      .filter((itemInCart: StateItem) => itemInCart.item.itemId === itemId)
      .reduce((accum, stateItem) => {
        return accum + stateItem.quantity;
      }, 0);
  }
}

@Pipe({ name: 'rearrangeItems' })
export class RearrangeItemsPipe implements PipeTransform {
  private sortItemsByAvailability(
    items: Item[],
    unavailableItems: ItemWithMenuAndSection[] = []
  ): Item[] {
    const unavailableItemsIds = new Set([
      ...unavailableItems.map(item => item.itemId),
    ]);

    return items.sort((previous, current) => {
      const isPreviousInSet = unavailableItemsIds.has(previous.itemId);
      const isCurrentInSet = unavailableItemsIds.has(current.itemId);

      return isPreviousInSet > isCurrentInSet
        ? 1
        : isPreviousInSet < isCurrentInSet
        ? -1
        : 0;
    });
  }

  transform(
    items: Item[] | null,
    unavailableItems: ItemWithMenuAndSection[] | null,
    itemsInCart: StateItem[] = []
  ): Item[] {
    items = items || [];
    unavailableItems = unavailableItems || [];

    const rearrangedItems = [...items];

    itemsInCart.forEach((itemInCart: StateItem) => {
      const index = rearrangedItems.findIndex(
        item => item.itemId === itemInCart.item.itemId
      );

      if (index > -1) {
        const itemToTop = rearrangedItems.splice(index, 1)[0];
        rearrangedItems.splice(0, 0, itemToTop);
      }
    });

    return this.sortItemsByAvailability(rearrangedItems, unavailableItems);
  }
}

@Pipe({ name: 'itemFullPrice' })
export class ItemFullPrice implements PipeTransform {
  constructor(private store: Store) {}
  transform<T extends { modifierId: string; selectedOptionId: string }>(
    sizePrice: number,
    sizeId: string,
    quantity: number,
    selectedModifiers: T[]
  ): number {
    sizePrice ??= 0;
    quantity ??= 0;

    const modifiers = this.store.selectSnapshot(ItemState.modifiers);
    const selectedModifiersPrice = selectedModifiers.reduce(
      (fullPrice, modifier) => {
        const option = modifiers
          .find(mod => mod.id === modifier.modifierId)
          ?.options.find(option => option.id === modifier.selectedOptionId);
        const selectedConfig = option?.itemModifierOption.config?.[0];

        return fullPrice + (selectedConfig?.price || 0);
      },
      0
    );

    return (sizePrice + selectedModifiersPrice) * quantity;
  }
}

@Pipe({ name: 'itemPriceWithBundleItems' })
export class ItemPriceWithBundleItemsPipe implements PipeTransform {
  constructor(private store: Store) {}
  transform(
    currentPrice: number,
    addedBundleItems: BundleInformation[] | null,
    quantity: number
  ): number {
    if (!addedBundleItems?.length || !quantity) {
      return currentPrice;
    }

    const itemDetails = this.store.selectSnapshot(ItemState.item);
    const itemsWithExtraPrice = addedBundleItems.filter(
      ({ bundleItemId }) =>
        !!itemDetails?.sizesAndPrices[0].bundleItemSizeAttributes?.find(
          ({ bundleItemId: id }) => bundleItemId === id
        )?.extraPrice.value
    );
    if (!itemsWithExtraPrice?.length) {
      return currentPrice;
    }
    itemsWithExtraPrice.forEach(({ bundleItemId }) => {
      const itemPrice =
        itemDetails?.sizesAndPrices[0].bundleItemSizeAttributes?.find(
          ({ bundleItemId: id }) => bundleItemId === id
        )?.extraPrice.value ?? 0;
      currentPrice += Number(itemPrice) * quantity;
    });
    return currentPrice;
  }
}

@Pipe({ name: 'rsGetUniqueItemModifiersNames' })
export class UniqueItemModifiersNames implements PipeTransform {
  transform(modifiers: SelectedModifier[]): string[] {
    return getUniqueItemModifiersNames(modifiers);
  }
}

@Pipe({ name: 'rsGetModifierConfig' })
export class GetModifierConfigPipe implements PipeTransform {
  transform(modifierOption: ModifierOption): Config {
    return (
      modifierOption.itemModifierOption?.config?.[0] ||
      ({
        maxRepetition: 1,
        price: 0,
        quantity: 0,
      } as Config)
    );
  }
}

@Pipe({ name: 'rsGetMaxModifiersCount' })
export class GetMaxModifiersCount implements PipeTransform {
  transform(
    selectedModifiers: SelectedModifier[],
    modifier: Modifier,
    maxRepetitionOfSingleModifier: number,
    option: ModifierOption
  ): number {
    const filteredSelectedModifiers: SelectedModifier[] =
      selectedModifiers.filter(
        (selectedModifier: SelectedModifier) =>
          selectedModifier.modifierId === modifier.id
      );
    const numberOfThisModifierSelections: number = selectedModifiers.filter(
      selectedModifier => selectedModifier.selectedOptionId === option.id
    ).length;

    const totalModifiersLeftSelections: number =
      modifier.maxModifiers - filteredSelectedModifiers.length;
    const singleModifierLeftSelections: number =
      maxRepetitionOfSingleModifier - numberOfThisModifierSelections;

    const leftAmount =
      totalModifiersLeftSelections < singleModifierLeftSelections
        ? totalModifiersLeftSelections
        : singleModifierLeftSelections;

    return leftAmount + numberOfThisModifierSelections;
  }
}
