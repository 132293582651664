import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  MatMenuTrigger,
  MenuPositionX,
  MenuPositionY,
} from '@angular/material/menu';

import { Store } from '@ngxs/store';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { DialogMobileForTooltipComponent } from '../dialog/dialog-instead-of-tooltip.component';

import { OpenDialog } from 'src/app/_shared/_ngxs/dialog.actions';

import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';

@Component({
  selector: 'rs-tooltip',
  standalone: true,
  imports: [SharedModule, DialogMobileForTooltipComponent],
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @ViewChild('rsTooltip', { static: false }) tooltip:
    | ElementRef<any>
    | undefined;

  @ViewChild('rsTooltipHeader', { static: false }) tooltipHeader:
    | ElementRef<any>
    | undefined;
  @Input() xPosition: MenuPositionX = 'before';
  @Input() yPosition: MenuPositionY = 'below';

  public isHoverAvailable!: boolean;
  public isMenuOpened: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.detectIfHoverAvailable();
  }

  private detectIfHoverAvailable(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isHoverAvailable = window.matchMedia(
        '(any-hover: hover) and (pointer: fine)'
      )?.matches;
    }
  }

  public openPopupIfHoverNotAvailable(): void {
    if (!this.isHoverAvailable && this.tooltip) {
      this.store.dispatch(
        new OpenDialog(DialogMobileForTooltipComponent, {
          id: MatDialogId.popup_instead_of_tooltip,
          data: { tooltip: this.tooltip, tooltipHeader: this.tooltipHeader },
        } as MatDialogConfig)
      );
    }
  }

  public showMenu(trigger: MatMenuTrigger): void {
    if (!this.isHoverAvailable) {
      return;
    }

    trigger.openMenu();
  }

  public hideMenu(trigger: MatMenuTrigger): void {
    if (!this.isHoverAvailable) {
      return;
    }

    setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }
}
