import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullTextSearch',
})
export class FullTextSearchPipe implements PipeTransform {
  transform(
    items: any[],
    searchText: string,
    fields: string[],
    count: { count: number } = { count: 0 }
  ): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      count.count = items.length;

      return items;
    }

    searchText = searchText.toLowerCase();

    const result = items.filter(item => {
      if (!fields.length) {
        return item.toLowerCase().includes(searchText);
      } else {
        return fields.some(field =>
          item[field]?.toLowerCase()?.includes(searchText)
        );
      }
    });

    count.count = result.length;

    return result;
  }
}
