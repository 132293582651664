<div
  class="rs-order-information"
  *ngIf="isThereAnyAvailableOrderMethods$ | async"
>
  <rs-order-type-toggle
    title="Order Type"
    [(selectedOrderMethod)]="orderMethod"
    (change)="changeOrderMethod(orderMethod)"
  ></rs-order-type-toggle>
</div>
<ng-container *ngIf="orderMethod === DeliveryMethod.Curbside">
  <div class="rs-order-information__car">
    <rs-car-item
      *ngIf="car | rsIsCarValid; else rsNoSelectedCar"
      [car]="car!"
    ></rs-car-item>
  </div>
</ng-container>
<ng-container *ngIf="orderMethod !== DeliveryMethod.Delivery">
  <ng-container
    [ngTemplateOutlet]="rsOrderMethodInformation"
    [ngTemplateOutletContext]="{ $implicit: orderMethod }"
  ></ng-container>
</ng-container>

<rs-order-type-info [isCheckout]="isCheckout"></rs-order-type-info>

<ng-template #rsOrderMethodInformation let-orderMethod>
  <div class="rs-order-information__map">
    <rs-map
      [mapName]="
        orderMethod === DeliveryMethod.Takeout ? 'TakeoutInfo' : 'CurbsideInfo'
      "
    ></rs-map>
  </div>
</ng-template>

<ng-template #rsNoSelectedCar>
  <rs-car-form [carForm]="carForm"></rs-car-form>
</ng-template>
