<div class="rs-state-select w-100">
  <mat-form-field
    class="rs-v3-input--small w-100"
    appearance="outline"
    [ngClass]="shouldDisable ? 'rs-state-select__disabled' : ''"
  >
    <mat-select
      [formControl]="rsFormControl"
      [compareWith]="compareWithFn"
      [placeholder]="placeholder"
    >
      <mat-option *ngFor="let state of states" [value]="state.name">
        {{ state.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="rsFormControl.hasError('required')">
      {{ 'ERRORS.required' | translate }}
    </mat-error>
  </mat-form-field>
</div>
