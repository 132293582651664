<ng-container *ngIf="sizesAndPrices$ | async; let sizesAndPrices">
  <section *ngIf="sizesAndPrices.length > 1" [formGroup]="formGroup">
    <div class="rs-title mt-0">
      {{ 'Size' | translate }}
    </div>

    <ng-container
      *ngFor="let showPricesForSizes of [showPricesForSizes$ | async]"
    >
      <rs-radio-button-box-group
        class="w-100"
        *ngIf="formControl"
        [formControl]="formControl"
      >
        <ng-container *ngFor="let size of sizesAndPrices; let index = index">
          <rs-radio-button-box
            [checked]="size.name == currentValueName"
            [value]="size"
            [tooltip]="
              (size.name | translate | titlecase) +
              (showPricesForSizes
                ? ' ' +
                  (size.menuAttributes[0].price
                    | cents2DollarsView
                    | rsEasternArabicNumerals)
                : '')
            "
            formControlName="size"
            (change)="reactOnSizeSelection(size, index)"
          >
            {{ size.name | translate | titlecase }}

            <span *ngIf="showPricesForSizes" rs-radio-button-box-supporting>
              {{
                size.menuAttributes[0].price
                  | cents2DollarsView
                  | rsEasternArabicNumerals
              }}
            </span>
          </rs-radio-button-box>
        </ng-container>
      </rs-radio-button-box-group>
    </ng-container>
  </section>
</ng-container>
