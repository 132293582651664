import { PaymentCard } from 'src/app/profile/_enum/payment-card';

const ABSENT_CARD_NUMBER: string = 'xxxxxxxxxxxx';

const CVV_MASK = {
  3: [/\d/, /\d/, /\d/],
  4: [/\d/, /\d/, /\d/, /\d/],
};

const DEFAULT_CARD_MASK: (RegExp | string)[] = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
];

const MIN_CARD_LENGTH: number = 8;
const MAX_CARD_LENGTH: number = 19;
const MIN_SECURITY_CODE_LENGTH: number = 3;
const MAX_SECURITY_CODE_LENGTH: number = 4;

const PAYMENT_SYSTEM_TITLES = {
  [PaymentCard.VISA]: 'Visa',
  [PaymentCard.VISA_ELECTRON]: 'Visa Electron',
  [PaymentCard.MASTERCARD]: 'Mastercard',
  [PaymentCard.AMEX]: 'AMEX',
  [PaymentCard.DISCOVER]: 'Discover',
  [PaymentCard.DINERS_CLUB_INTERNATIONALS]: 'Diners Club - International',
  [PaymentCard.DINERS_CLUB_USA_CANADA]: 'Diners Club - USA & Canada',
  [PaymentCard.DINERS_CLUB_CARTE_BLANCHE]: 'Diners Club - Carte Blanche',
  [PaymentCard.JCB]: 'JCB',
  [PaymentCard.MAESTRO]: 'Maestro',
  [PaymentCard.CHINA_UNION_PAY]: 'China UnionPay',
  [PaymentCard.UNKNOWN]: 'Unknown card type',
};

export {
  ABSENT_CARD_NUMBER,
  CVV_MASK,
  DEFAULT_CARD_MASK,
  MIN_CARD_LENGTH,
  MIN_SECURITY_CODE_LENGTH,
  MAX_CARD_LENGTH,
  MAX_SECURITY_CODE_LENGTH,
  PAYMENT_SYSTEM_TITLES,
};
