import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

type Style = 'title-label' | 'title-label-icons' | 'icon-label';
type Status = 'overlay' | 'default';

@Component({
  selector: 'rs-grid-item',
  standalone: true,
  imports: [CommonModule],
  template: `
    <button
      rs-style="text"
      [disabled]="disabled"
      class="rs-button-grid-item"
      [ngClass]="{
        'rs-button-grid-item--title-label': rsStyle === 'title-label',
        'rs-button-grid-item--title-label-icons':
          rsStyle === 'title-label-icons',
        'rs-button-grid-item--icon-label': rsStyle === 'icon-label',
        'rs-button-grid-item--active': active,
        'rs-button-grid-item--overlay': rsStatus === 'overlay',
        'rs-button-grid-item--unavailable': unavailable
      }"
    >
      <ng-content></ng-content>
    </button>
  `,
})
export class RsGridItemComponent {
  @Input() disabled!: boolean;
  @Input() active!: boolean;
  @Input() unavailable!: boolean;
  @Input('rs-style') set style(style: Style) {
    this.rsStyle = style || 'title-label';
  }
  @Input('rs-status') set status(status: Status) {
    this.rsStatus = status;
  }
  public rsStyle: Style = 'title-label';
  public rsStatus: Status = 'default';
}

@Component({
  selector: 'rs-grid-item-label',
  standalone: true,
  imports: [CommonModule],
  template: '<p class="rs-grid-item-label m-0"><ng-content></ng-content></p>',
})
export class RsGridItemLabelComponent {}

@Component({
  selector: 'rs-grid-item-text',
  standalone: true,
  imports: [CommonModule],
  template: '<p class="rs-grid-item-text m-0"><ng-content></ng-content></p>',
})
export class RsGridItemTextComponent {}

@Component({
  selector: 'rs-grid-item-icons',
  standalone: true,
  imports: [CommonModule],
  template: '<div class="rs-grid-item-icons"><ng-content></ng-content></div>',
})
export class RsGridItemIconsComponent {}
