import { AbstractControl, FormGroup } from '@angular/forms';

import {
  MIN_PASSWORD_LENGTH,
  REGEX,
} from '../_constants/password-strength.constants';
import { PasswordErorrMap } from '../_models/password-strength.models';

export const confirmPasswordValidator = (form: FormGroup): void => {
  const { password, confirmPassword } = form.controls;

  if (password && confirmPassword && password.value !== confirmPassword.value) {
    confirmPassword.setErrors({ passwordsMatch: true });
  } else if (!confirmPassword?.hasError('required')) {
    confirmPassword.setErrors(null);
  }
};

export const passwordStrengthValidator = (
  control: AbstractControl
): PasswordErorrMap<boolean> | null => {
  const password = control.value as string;
  const isEmpty: boolean = !password;
  const notEnougthSymbols: boolean = password?.length < MIN_PASSWORD_LENGTH;

  const haveUpperCaseLetter = !!password?.match(REGEX.UPPER_CASE);
  const haveLowerCaseLetter = !!password?.match(REGEX.LOWER_CASE);
  const haveNumber = !!password?.match(REGEX.NUMBER);
  const haveSpecialSymbol = !!password?.match(REGEX.SPECIAL_SYMBOL);

  switch (true) {
    case isEmpty: {
      return { required: true };
    }
    case notEnougthSymbols: {
      return { minlength: true };
    }
    case !(haveUpperCaseLetter || haveLowerCaseLetter): {
      return { noLetters: true };
    }
    case !haveLowerCaseLetter: {
      return { noLowerCaseLetter: true };
    }
    case !haveUpperCaseLetter: {
      return { noUpperCaseLetter: true };
    }
    case !haveNumber: {
      return { noNumbers: true };
    }
    case !haveSpecialSymbol: {
      return { noSymbols: true };
    }
    default: {
      return null;
    }
  }
};
