import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import {
  DeliveryMethod,
  DeliveryType,
  OrderType,
} from 'src/app/_shared/_enums/order.enum';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-order-method-icon',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './order-method-icon.component.html',
  styleUrls: ['./order-method-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderMethodIconComponent {
  @Input() orderMethod!: DeliveryMethod;
  @Input() set orderType(orderType: DeliveryType) {
    if (orderType !== OrderType.DineIn) {
      this.orderMethod = orderType as DeliveryMethod;
    }
  }

  public readonly DeliveryMethod = DeliveryMethod;
  public readonly curbsideIcon = CommonIcons.Curbside;
  public readonly deliveryIcon = CommonIcons.Location;
  public readonly takeoutIcon = CommonIcons.Takeout;

  constructor(private readonly svgIconService: SvgIconService) {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.curbsideIcon, this.deliveryIcon, this.takeoutIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
