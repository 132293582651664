import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../../_modules/material.module';
import { RsPipesModule } from '../../_modules/pipes.module';
import { RsSelectComponent } from './select.component';

@NgModule({
  declarations: [RsSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    RsPipesModule,
  ],
  exports: [RsSelectComponent],
})
export class RsSelectModule {}
