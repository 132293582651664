import { Injectable } from '@angular/core';

import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { filter } from 'rxjs';

import { CatalogService } from 'src/app/_services/catalog.service';
import {
  GetAvailableAllergens,
  SaveSelectedDefaultAllergens,
  SaveGuestAllergens,
  SaveSelectedAllergens,
  SaveUserAllergens,
  SaveSelectedCustomAllergens,
  ResetSelectedAllergens,
} from './allergens.actions';
import { Allergen } from '../_interfaces/item.model';
import {
  getCustomAllergens,
  getAllergensBasedOnLabelsArray,
  getAllergenLabel,
} from '../_utils/allergens';
import { ProfileState } from './profile.state';
import { SaveAllergensPreferences } from './profile.actions';
import { VenueState } from './venue.state';
import { SessionState } from './authentication.state';
import { DEFAULT_LANGUAGE } from '../_constants/common';
import { ALLERGEN_CONTAIN_POSTFIX } from '../_constants/allergens.constants';

interface AllergensStateModel {
  defaultAllergens: Allergen[];
  customAllergens: Allergen[];
  selectedDefaultAllergens: Allergen[];
  selectedCustomAllergens: Allergen[];
  // not used in components
  selectedDefaultAllergensBE: Allergen[];
  availableAllergensBE: Allergen[];
}
export const DEFAULT_STATE: AllergensStateModel = {
  defaultAllergens: [],
  customAllergens: [],
  selectedDefaultAllergens: [],
  selectedCustomAllergens: [],
  selectedDefaultAllergensBE: [],
  availableAllergensBE: [],
};

@State<AllergensStateModel>({
  name: 'allergens',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class AllergensState implements NgxsOnInit {
  @Selector()
  static defaultAllergens({
    defaultAllergens,
  }: AllergensStateModel): Allergen[] {
    return defaultAllergens;
  }

  @Selector()
  static customAllergens({ customAllergens }: AllergensStateModel): Allergen[] {
    return customAllergens;
  }

  @Selector()
  static selectedDefaultAllergens({
    selectedDefaultAllergens,
  }: AllergensStateModel): Allergen[] {
    return selectedDefaultAllergens;
  }

  @Selector()
  static selectedCustomAllergens({
    selectedCustomAllergens,
  }: AllergensStateModel): Allergen[] {
    return selectedCustomAllergens;
  }

  @Selector()
  static selectedAllergens({
    selectedCustomAllergens,
    selectedDefaultAllergens,
  }: AllergensStateModel): Allergen[] {
    return [...selectedCustomAllergens, ...selectedDefaultAllergens];
  }

  static allergensOfSelectedValues(selected: string[]) {
    return createSelector(
      [AllergensState],
      ({ defaultAllergens, customAllergens }: AllergensStateModel) => {
        const selectedDefaultAllergens = getAllergensBasedOnLabelsArray(
          selected,
          defaultAllergens
        );
        const selectedCustomAllergens = getAllergensBasedOnLabelsArray(
          selected,
          customAllergens
        );

        return [...selectedDefaultAllergens, ...selectedCustomAllergens];
      }
    );
  }

  static getAllergensBySearchText(
    text: string,
    selected: string[],
    language: string | null = DEFAULT_LANGUAGE
  ) {
    return createSelector(
      [AllergensState],
      ({ defaultAllergens, customAllergens }: AllergensStateModel) => {
        if (!text || !text.trim()) {
          return [];
        }

        const searchText = text.trim().toLowerCase();

        return [
          ...defaultAllergens.filter(
            ({ value }) => selected.indexOf(value) < 0
          ),
          ...customAllergens.filter(({ value }) => selected.indexOf(value) < 0),
        ].filter(
          allergen =>
            getAllergenLabel(allergen, language)
              .toLowerCase()
              .indexOf(searchText) > -1 &&
            allergen.allergenOption !== ALLERGEN_CONTAIN_POSTFIX
        );
      }
    );
  }

  static isAllergenCustom(allergen: Allergen) {
    return createSelector(
      [AllergensState],
      ({ customAllergens }: AllergensStateModel) => {
        return !!customAllergens.find(({ value }) => allergen.value === value);
      }
    );
  }

  constructor(
    private readonly store: Store,
    private readonly catalogService: CatalogService
  ) {}

  ngxsOnInit({
    dispatch,
    patchState,
    getState,
  }: StateContext<AllergensStateModel>) {
    this.store
      .select(VenueState.venueId)
      .pipe(filter(venueId => !!venueId))
      .subscribe(venueId => dispatch(new GetAvailableAllergens(venueId)));

    this.store.select(ProfileState.allergens).subscribe(selected => {
      patchState({
        selectedDefaultAllergensBE: getAllergensBasedOnLabelsArray(
          selected,
          getState().defaultAllergens
        ),
      });

      const isLoggedIn: boolean = this.store.selectSnapshot(
        SessionState.isLoggedIn
      );

      isLoggedIn && dispatch(new SaveSelectedDefaultAllergens(selected));
    });
  }

  @Action(GetAvailableAllergens)
  async getAvailableAllergens(
    { patchState }: StateContext<AllergensStateModel>,
    { venueId }: GetAvailableAllergens
  ) {
    const defaultAllergens: Allergen[] =
      (await this.catalogService.getDefaultAllergens(venueId)) || [];
    const available: Allergen[] =
      (await this.catalogService.getUsedAllergens(venueId)) || [];
    const customAllergens = getCustomAllergens(available, defaultAllergens);

    patchState({
      availableAllergensBE: available,
      defaultAllergens,
      customAllergens,
    });
  }

  @Action(SaveSelectedDefaultAllergens)
  SaveSelectedDefaultAllergens(
    { patchState, getState }: StateContext<AllergensStateModel>,
    { selected }: SaveSelectedDefaultAllergens
  ) {
    const { defaultAllergens } = getState();
    const selectedDefaultAllergens = getAllergensBasedOnLabelsArray(
      selected,
      defaultAllergens
    );

    patchState({ selectedDefaultAllergens });
  }

  @Action(SaveSelectedCustomAllergens)
  saveSelectedCustomAllergens(
    { patchState, getState }: StateContext<AllergensStateModel>,
    { selected }: SaveSelectedCustomAllergens
  ) {
    const { customAllergens } = getState();
    const selectedCustomAllergens = getAllergensBasedOnLabelsArray(
      selected,
      customAllergens
    );

    patchState({ selectedCustomAllergens });
  }

  @Action(SaveUserAllergens)
  saveUserAllergens(
    { dispatch }: StateContext<AllergensStateModel>,
    { selected }: SaveUserAllergens
  ) {
    // we don't need here filtration because allergens come here already filtered.
    // this action fired ProfileState action
    // we have subsctiption on ProfileState.allergens in which we update
    // selected default allergens
    dispatch(new SaveAllergensPreferences(selected));
  }

  @Action(SaveSelectedAllergens)
  saveSelectedAllergens(
    { dispatch, getState }: StateContext<AllergensStateModel>,
    { selected }: SaveSelectedAllergens
  ) {
    const isLoggedIn: boolean = this.store.selectSnapshot(
      SessionState.isLoggedIn
    );

    if (isLoggedIn) {
      const { defaultAllergens } = getState();
      const selectedDefaultAllergensLabels: string[] = [...selected].filter(
        label => defaultAllergens.find(({ value }) => value === label)
      );

      dispatch([
        new SaveUserAllergens(selectedDefaultAllergensLabels),
        new SaveSelectedCustomAllergens(selected),
      ]);
    } else {
      dispatch(new SaveGuestAllergens(selected));
    }
  }

  @Action(SaveGuestAllergens)
  saveGuestAllergens(
    { dispatch }: StateContext<AllergensStateModel>,
    { selected }: SaveGuestAllergens
  ) {
    dispatch([
      new SaveSelectedDefaultAllergens(selected),
      new SaveSelectedCustomAllergens(selected),
    ]);
  }

  @Action(ResetSelectedAllergens)
  resetSelectedAllergens({ dispatch }: StateContext<AllergensStateModel>) {
    dispatch(new SaveSelectedAllergens([]));
  }
}
