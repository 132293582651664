import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { Subject, take } from 'rxjs';

import { isEqual, omit } from 'lodash';

import {
  AddNewAddress,
  UpdateAddress,
  UpdateAndSetDefaultAddress,
} from 'src/app/_shared/_ngxs/profile.actions';
import { OpenDeleteDefaultAddressDialog } from 'src/app/_shared/_ngxs/dialog.actions';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';

const unnecessaryAddressProperties = [
  'country',
  'id',
  'isValidated',
  'latitude',
  'longitude',
  'region',
  'status',
  'timezone',
  'state',
];

interface DialogData {
  address: UserProfileAddress;
  isLastDefault: boolean;
  isShowCloseButton?: boolean;
}

@Component({
  selector: 'rs-new-address',
  templateUrl: 'new-address.component.html',
  styleUrls: ['new-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewAddressComponent implements OnInit {
  @Input() isDialog: boolean = true;

  public isEdit: boolean = false;
  public isShowCloseButton: boolean | undefined = false;
  public newAddressForm: FormGroup = new FormGroup({});
  public resetFormSubject: Subject<void> = new Subject<void>();

  private destroyed$ = untilDestroyed();
  private newDefaultAddressId: string | undefined;

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData,
    private dialog: MatDialog,
    @Optional() private dialogRef: MatDialogRef<NewAddressComponent>
  ) {
    this.isEdit = !!dialogData.address?.id;
    this.isShowCloseButton = dialogData.isShowCloseButton;
  }

  ngOnInit(): void {
    this.initForm();
  }

  public reactOnCheckboxChange(event: MatCheckboxChange) {
    if (!event.checked) {
      this.store
        .dispatch(new OpenDeleteDefaultAddressDialog())
        .pipe(take(1))
        .subscribe(() => {
          this.dialog
            .getDialogById(MatDialogId.delete_default_address)
            ?.afterClosed()
            .pipe(take(1))
            .subscribe(newDefaultId => {
              if (!newDefaultId) {
                this.newAddressForm.controls['isDefault'].reset(true);
              } else {
                this.newDefaultAddressId = newDefaultId;
              }
            });
        });
    }
  }

  public doesAddressHaveChanges(): boolean {
    return isEqual(
      omit(this.dialogData.address, unnecessaryAddressProperties),
      this.newAddressForm.value
    );
  }

  public submit(): void {
    this.store
      .dispatch(
        new AddNewAddress(this.newAddressForm.value as UserProfileAddress)
      )
      .pipe(this.destroyed$())
      .subscribe(() => {
        this.newAddressForm.reset({ country: 'US' });
        this.resetFormSubject.next();
        this.dialogRef?.close?.();
      });
  }

  public saveChanges(): void {
    if (this.newDefaultAddressId) {
      this.store.dispatch(
        new UpdateAndSetDefaultAddress(
          this.newAddressForm.value as UserProfileAddress,
          this.dialogData.address.id,
          this.newDefaultAddressId
        )
      );
    } else {
      this.store.dispatch(
        new UpdateAddress(
          this.newAddressForm.value as UserProfileAddress,
          this.dialogData.address.id
        )
      );
    }

    this.dialog.getDialogById(MatDialogId.new_address)?.close();
  }

  private initForm(): void {
    this.newAddressForm = new FormGroup({
      country: new FormControl(
        this.isEdit ? this.dialogData.address.country : 'US',
        Validators.required
      ),
      name: new FormControl(
        this.isEdit ? this.dialogData.address.name : '',
        Validators.required
      ),
      address1: new FormControl(
        this.isEdit ? this.dialogData.address.address1 : '',
        Validators.required
      ),
      address2: new FormControl(
        this.isEdit ? this.dialogData.address.address2 : ''
      ),
      city: new FormControl(
        this.isEdit ? this.dialogData.address.city : '',
        Validators.required
      ),
      state: new FormControl(
        this.isEdit ? this.dialogData.address.state : '',
        Validators.required
      ),
      zipcode: new FormControl(
        this.isEdit ? this.dialogData.address.zipcode : '',
        Validators.required
      ),
      instructions: new FormControl(
        this.isEdit ? this.dialogData.address.instructions : ''
      ),
      isDefault: new FormControl({
        value: this.isEdit ? this.dialogData.address.isDefault : false,
        disabled: this.dialogData.isLastDefault,
      }),
      latitude: new FormControl(
        this.isEdit ? this.dialogData.address.latitude : 0
      ),
      longitude: new FormControl(
        this.isEdit ? this.dialogData.address.longitude : 0
      ),
    });
  }
}
