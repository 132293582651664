<rs-dialog rs-dialog-style="default" rs-show-close-button="true">
  <ng-container rs-dialog-header>
    <span>{{ 'PAYMENT.select-card' | translate }}</span>
  </ng-container>
  <section
    class="rs-select-payment-card"
    [dir]="'languageDirection' | translate"
  >
    <div class="rs-select-payment-card__main">
      <ul
        class="rs-select-payment-card__cards-list d-flex flex-column p-0 mb-0"
      >
        <li
          *ngFor="let card of cards"
          class="rs-select-payment-card__card-item"
        >
          <a class="rs-select-payment-card__card" (click)="selectCard(card)">
            <div class="rs-select-payment-card__card-info-wrapper">
              <div class="d-flex flex-row">
                <img
                  class="rs-select-payment-card__card-brand"
                  src="{{ card.cardBrand | rsCardImageByBrand }}"
                  alt=""
                  loading="lazy"
                />
                <div class="rs-select-payment-card__card-info ps-3">
                  <span class="rs-select-payment-card__card-number">
                    {{ card.redactedCardNumber | rsStarsCard }}
                  </span>

                  <span>
                    {{
                      'CARD.Exp {date}'
                        | translate
                          : {
                              date: card.cardExpirationDate | rsMoment: 'MM/YY'
                            }
                    }}
                  </span>
                </div>
              </div>

              <div class="rs-select-payment-card__default">
                <ng-container *ngIf="card.default; else isDefaultCheckbox">
                  <mat-icon class="rs-select-payment-card__info-icon">
                    info
                  </mat-icon>
                  <span class="rs-select-payment-card__default-text">
                    {{ 'PROFILE.payment.default_card_title' | translate }}
                  </span>
                </ng-container>

                <ng-template #isDefaultCheckbox>
                  <div class="rs-checkbox-wrapper">
                    <mat-checkbox
                      #checkbox
                      (click)="checkbox.checked = false"
                      rsOpenConfirmation
                      [question]="
                        'PROFILE.payment.default_question' | translate
                      "
                      (confirmed)="setDefaultCard(card.id!)"
                    >
                      <span class="rs-select-payment-card__make-default">
                        {{ 'PROFILE.payment.make_as_default' | translate }}
                      </span>
                    </mat-checkbox>
                  </div>
                </ng-template>
              </div>
            </div>

            <button
              *ngIf="selectedPaymentCard$ | async; let selectedCard"
              mat-icon-button
              class="rs-select-payment-card__card-button"
            >
              <mat-icon
                *ngIf="card == selectedCard"
                [svgIcon]="checkIcon"
              ></mat-icon>
            </button>
          </a>
        </li>
      </ul>
    </div>

    <rs-payment-card-form [cardForm]="newCardForm"></rs-payment-card-form>
    <p class="rs-select-payment-card__title">
      {{ 'PAYMENT.Billing' | translate }}
    </p>
    <p
      *ngIf="(userProfileAddresses$ | async)?.length"
      class="rs-select-payment-card__title rs-select-payment-card__title--small"
    >
      {{ 'PAYMENT.Select from existing addresses' | translate }}
    </p>
    <rs-payment-address-selector
      *ngIf="(userProfileAddresses$ | async)?.length"
      [addresses]="(userProfileAddresses$ | async)!"
      (selectAddress)="selectAddressForAddingPayment($event)"
      [resetListener]="addressFormResetListener"
    ></rs-payment-address-selector>
    <p
      *ngIf="(userProfileAddresses$ | async)?.length"
      class="rs-select-payment-card__title rs-select-payment-card__title--small rs-select-payment-card__title--centered"
    >
      {{ 'CART.OR ADD A NEW BILLING ADDRESS' | translate }}
    </p>
    <rs-payment-address-form
      [addressForm]="newAddressForm"
    ></rs-payment-address-form>

    <!--TODO: Uncomment during implementation-->
    <!-- <rs-tips-selector></rs-tips-selector> -->
    <!--TODO: End.-->
  </section>
  <ng-container rs-dialog-actions-direction="row">
    <button
      rs-style="text"
      [disabled]="
        newCardForm.invalid ||
        (newAddressForm.invalid && !selectedAddressFromSelector)
      "
      (click)="saveNewCard()"
    >
      <span>{{ 'save' | translate | uppercase }}</span>
    </button>
  </ng-container>
</rs-dialog>
