import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Store } from '@ngxs/store';
import { Observable, filter } from 'rxjs';
import { CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';

import { RsPipesModule } from '../../_modules/pipes.module';
import { SharedModule } from '../../_modules/shared.module';
import { DirectivesModule } from '../../_directives/directives.module';

import { VenueState } from '../../_ngxs/venue.state';
import { SetTips } from '../../_ngxs/cart.actions';
import { CartState } from '../../_ngxs/cart.state';

import { Tips } from '../../_interfaces/item.model';
import {
  CheckCalculation,
  CheckTax,
} from '../../_interfaces/check-calculation.model';

import { untilDestroyed } from '../../_utils/until-destroyed';

@Component({
  selector: 'rs-tips-selector',
  standalone: true,
  imports: [SharedModule, RsPipesModule, DirectivesModule, CurrencyMaskModule],
  templateUrl: 'tips-selector.component.html',
  styleUrls: ['tips-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipsSelectorComponent {
  public readonly tips$: Observable<number[]> = this.store.select(
    VenueState.venueTips
  );
  private readonly checkCalculations$: Observable<CheckCalculation> =
    this.store.select(CartState.checkCalculations);
  private readonly destroy$ = untilDestroyed();
  public subtotal = 0;
  public isCustom: boolean = false;
  public customTipsControl: FormControl = new FormControl(0);
  public selectedPercentage: number | undefined;
  public dollarsCurrencyOptions: Partial<CurrencyMaskConfig> = {
    prefix: '$',
    thousands: ',',
    decimal: '.',
    allowNegative: false,
    align: 'center',
  };

  constructor(
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngAfterContentInit(): void {
    this.subscribeOnCustomTips();
    this.subscribeOnTipsChange();
    this.subscribeOnCheckCalculations();
  }

  public dispatchSetTips(
    absolute: number,
    percentage: number,
    isCustom: boolean
  ): void {
    if (isCustom) {
      absolute = Math.round(absolute * 100);
      percentage = Math.round((absolute * 1000) / this.subtotal) / 10;
    } else {
      absolute = Math.round((this.subtotal * percentage) / 100);
    }

    this.store.dispatch(new SetTips({ absolute, percentage, isCustom }));
  }

  private subscribeOnCustomTips(): void {
    this.customTipsControl.valueChanges
      .pipe(
        this.destroy$(),
        filter((value: number | null) => value !== null)
      )
      .subscribe((value: number | null) => {
        const percentage: number =
          Math.round((value || 0 * 1000) / this.subtotal) / 10;

        this.dispatchSetTips(value || 0, percentage, true);
      });
  }

  private subscribeOnTipsChange(): void {
    this.store
      .select(CartState.tips)
      .pipe(this.destroy$())
      .subscribe((tips: Tips) => {
        this.selectedPercentage = tips.percentage || 0;

        this.selectedPercentage === 0 &&
          !tips.isCustom &&
          this.store.dispatch(
            new SetTips({
              absolute: 0,
              isCustom: false,
              percentage: this.store.selectSnapshot(VenueState.venueTips)[0],
            })
          );

        this.isCustom = tips.isCustom;

        this.isCustom &&
          this.customTipsControl.setValue((tips?.absolute || 0) / 100, {
            emitEvent: false,
          });

        this.changeDetectorRef.detectChanges();
      });
  }

  private subscribeOnCheckCalculations(): void {
    this.checkCalculations$
      .pipe(this.destroy$())
      .subscribe((checkCalculation: CheckCalculation) => {
        const deliveryFeesTranslation: string = this.store.selectSnapshot(
          CartState.deliveryFeesTranslation
        );
        const deliveryFee: number =
          checkCalculation.entries.find(
            (entry: CheckTax) => entry.name === deliveryFeesTranslation
          )?.grossPrice || 0;

        this.subtotal = checkCalculation.netTotal - deliveryFee;
      });
  }

  public reactOnClickByCustomControl(): void {
    const wasCustom: boolean = this.isCustom;
    const absolute: number = wasCustom ? this.customTipsControl.value || 0 : 0;
    const percentage: number =
      Math.round((absolute || 0 * 1000) / this.subtotal) / 10;

    this.isCustom = true;
    this.dispatchSetTips(absolute, percentage, this.isCustom);
  }
}
