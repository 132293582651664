import { tap } from 'rxjs';
import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { HideSpinner } from './../_components/spinner/spinner.state';
import { ShowSpinner } from 'src/app/_shared/_components/spinner/spinner.state';
import { VenueInformation } from '../_interfaces/merchant.model';
import { MerchantService } from '../_services/merchant.service';
import { GetMerchantInformation } from './merchant.actions';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';

export interface MerchantStateModel {
  venueInformation: VenueInformation | null;
}

const defaults: MerchantStateModel = {
  venueInformation: null,
};

@State<MerchantStateModel>({
  name: 'merchant',
  defaults,
})
@Injectable()
export class MerchantState {
  constructor(
    private readonly store: Store,
    private readonly merchantService: MerchantService
  ) {}

  @Selector()
  static venueInformation({
    venueInformation,
  }: MerchantStateModel): VenueInformation | null {
    return venueInformation;
  }

  @Action(GetMerchantInformation)
  getMerchantInformation({ patchState }: StateContext<MerchantStateModel>) {
    const venueId: string = this.store.selectSnapshot(VenueState.venueId);

    this.spinnerVisibility(true);

    return this.merchantService.getMerchantInformation(venueId).pipe(
      tap({
        next: (venueInformation: VenueInformation) => {
          this.spinnerVisibility(false);

          patchState({ venueInformation });
        },
        error: () => this.spinnerVisibility(false),
      })
    );
  }

  private spinnerVisibility(isShow: boolean): void {
    isShow
      ? this.store.dispatch(new ShowSpinner())
      : this.store.dispatch(new HideSpinner());
  }
}
