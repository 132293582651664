import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SharedModule } from '../../shared.module';

import { VenueOrderSettingsState } from 'src/app/_shared/_ngxs/venue-order-settings.state';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { DeliveryMethod } from 'src/app/_shared/_enums/order.enum';
import { ActiveDeliverySettings } from 'src/app/_shared/_models/venue-order-settings';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { DeviceService } from 'src/app/_services/device.service';

@Component({
  selector: 'rs-order-type-toggle',
  standalone: true,
  imports: [SharedModule],
  templateUrl: 'order-type-toggle.component.html',
  styleUrls: ['order-type-toggle.component.scss'],
})
export class OrderTypeToggleComponent {
  @Input() title!: string;
  @Input() selectedOrderMethod!: DeliveryMethod;
  @Input() hideIcons: boolean =false;

  public readonly activeDeliveryMethods$: Observable<ActiveDeliverySettings[]> =
    this.store.select(VenueOrderSettingsState.activeDeliveryMethods);
  public readonly isDesktop$: Observable<boolean> =
    this.deviceService.isDesktop();

  @Output() change = new EventEmitter<void>();
  @Output() selectedOrderMethodChange = new EventEmitter<DeliveryMethod>();

  public readonly DeliveryMethod = DeliveryMethod;

  public readonly curbsideIcon = CommonIcons.Curbside;
  public readonly deliveryIcon = CommonIcons.Delivery;
  public readonly takeoutIcon = CommonIcons.Takeout;

  constructor(
    private readonly store: Store,
    private readonly deviceService: DeviceService,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcons();
  }

  public changeSelectedOrderType(selected: DeliveryMethod): void {
    this.selectedOrderMethodChange.emit(selected);
    this.change.emit();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.curbsideIcon, this.deliveryIcon, this.takeoutIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
