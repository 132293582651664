// more information about each place type is here: https://docs.mapbox.com/api/search/geocoding/#data-types
export enum PlaceType {
  Country = 'country',
  Region = 'region',
  Postcode = 'postcode',
  District = 'district',
  Place = 'place',
  Locality = 'locality',
  Neighborhood = 'neighborhood',
  Address = 'address',
  Poi = 'poi',
}

export enum ContextId {
  Zipcode = 'postcode',
  City = 'place',
  State = 'region',
  Country = 'country',
  Poi = 'poi',
}
