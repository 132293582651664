<div class="rs-item-list">
  <div class="rs-item-list__items">
    <ng-container *ngFor="let item of items">
      <div class="rs-item-list__item w-100 d-flex flex-column">
        <ng-container
          *ngIf="itemTemplateRef"
          [ngTemplateOutlet]="itemTemplateRef"
          [ngTemplateOutletContext]="{ $implicit: item }"
        >
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="rs-item-list__total" *ngIf="isTotal">
    <ng-content select="[rs-total]"></ng-content>
  </div>
</div>
