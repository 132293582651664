import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import {
  ENDPOINTS_WITHOUT_ACCESS_TOKEN,
  ENDPOINTS_WITH_ACCESS_TOKEN,
} from '../_constants/secure-endpoints';
import { HEADERS } from '../_constants/common';
import { SessionState } from '../_ngxs/authentication.state';
import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../_constants/endpoints';

const apiUrl: string = environment.apiUrl;
const payUrl: string = environment.paymentUrl;

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken: string = this.store.selectSnapshot(
      SessionState.accessToken
    );
    const reqPayUrl: string = request.url.replace(`${payUrl}/`, '');

    if (
      request.url !== `${apiUrl}/order/v1/guest/user` &&
      ENDPOINTS_WITH_ACCESS_TOKEN.some(route => request.url.includes(route)) &&
      ENDPOINTS_WITHOUT_ACCESS_TOKEN.every(
        route => !request.url.includes(route)
      )
    ) {
      request = request.clone({
        headers: request.headers.set(HEADERS.ACCESS_TOKEN, accessToken),
      });
    }

    if (
      reqPayUrl.includes(ENDPOINTS.wallet.createGiftCard) &&
      reqPayUrl.replace(ENDPOINTS.wallet.createGiftCard, '').length
    ) {
      request = request.clone({
        headers: request.headers.delete(HEADERS.ACCESS_TOKEN),
      });
    }

    return next.handle(request);
  }
}
