<rs-dialog rs-dialog-style="default">
  <div rs-dialog-header #rsDialogHeader></div>

  <div #rsDialogContent></div>

  <ng-container rs-dialog-actions-direction="row">
    <button mat-dialog-close rs-style="text" class="p-2">
      <span>{{ 'got it' | translate | uppercase }}</span>
    </button>
  </ng-container>
</rs-dialog>
