import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, firstValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';

import { AutoCompleteAddress } from '../_shared/_interfaces/address.model';
import { UserProfileAddress } from '../profile/_interfaces/address.model';

const apiUrl = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class GeolocationService {
  constructor(private readonly http: HttpClient) {}

  public suggestAddress(
    text: string,
    country: string
  ): Promise<AutoCompleteAddress[]> {
    return firstValueFrom(
      this.http.get(`${apiUrl}/geo-location/v1/location/autocomplete-address`, {
        params: { text, country },
      }) as Observable<AutoCompleteAddress[]>
    );
  }

  public getAddress(suggestionId: string): Promise<UserProfileAddress> {
    return firstValueFrom(
      this.http.get<UserProfileAddress>(
        `${apiUrl}/geo-location/v1/location/address`,
        { params: { suggestionId } }
      )
    );
  }
}
