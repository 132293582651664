import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { NutritionalAttribute } from 'src/app/_shared/_interfaces/item.model';
import { ItemState } from 'src/app/_shared/_ngxs/item.state';

@Component({
  selector: 'rs-nutritional-values',
  templateUrl: './nutritional-values.component.html',
  styleUrls: ['./nutritional-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NutritionalValuesComponent {
  public nutritionalValues$: Observable<
    NutritionalAttribute[] | null | undefined
  > = this.store.select(ItemState.nutritionalValues);

  constructor(private readonly store: Store) {}
}
