<rs-full-height-dialog
  *ngIf="!isDesktop"
  [dir]="'languageDirection' | translate"
>
  <ng-container rs-dialog-header>
    <mat-icon [svgIcon]="backIcon" mat-dialog-close tabindex="-1"></mat-icon>

    <p class="m-0">
      {{ 'LANGUAGE.Languages' | translate | titlecase }}
    </p>
  </ng-container>

  <ng-container [ngTemplateOutlet]="rsLanguageContent"></ng-container>
</rs-full-height-dialog>

<rs-dialog
  *ngIf="isDesktop"
  rs-dialog-style="default"
  rs-show-close-button="true"
  [dir]="'languageDirection' | translate"
>
  <ng-container rs-dialog-header>
    <span>{{ 'LANGUAGE.Languages' | translate | titlecase }}</span>
  </ng-container>

  <ng-container [ngTemplateOutlet]="rsLanguageContent"></ng-container>
</rs-dialog>

<ng-template #rsLanguageContent>
  <div class="rs-languages-popup__title w-100">
    {{ 'LANGUAGE.Choose your language' | translate }}
  </div>
  <div
    *ngFor="let language of languages"
    [id]="id.topbar.selectLanguageButton(language.key)"
    (click)="selectLanguage(language)"
    mat-dialog-closes
    class="rs-languages-popup__item w-100 d-flex cursor-pointer"
  >
    <span class="flex-1">{{ language.key }}</span>
    <mat-icon
      *ngIf="selectedLanguage.key === language.key"
      [svgIcon]="checkIcon"
    ></mat-icon>
  </div>
</ng-template>
