import { Order } from 'src/app/profile/_interfaces/order.model';
import { GiftCard } from '../_interfaces/gift-card.model';
import {
  CWCreateOrderRequest,
  GenericOrderResponse,
} from '../_interfaces/order.model';
import { ReorderingPoints } from '../_enums/order.enum';

export class CreateDineInOrder {
  static readonly type = '[Order] Create DineIn Order';
}

export class CreateNotDineInOrder {
  static readonly type = '[Order] Create Order';
}

export class CancelOrder {
  static readonly type = '[Order] Cancel Order';

  constructor(public orderId: string) {}
}

export class CancelExistingOrder {
  static readonly type = '[Order] Cancel Existing Order';

  constructor(public orderId: string) {}
}

export class DownloadOrderSummary {
  static readonly type = '[Order] Download Order Summary';
}

export class GetOrderPaymentDetails {
  static readonly type = '[Order] Get Payment Details';
}

export class ChangeOrderIsFavorite {
  static readonly type = '[Order] Change Order Is Favorite';

  constructor(public isFavorited: boolean) {}
}

export class GetItemsSuggestions {
  static readonly type = '[Order] Get Items Suggestions';
}

export class GetOrderDetails {
  static readonly type = '[Order] Get Order Details';

  constructor(public orderId: string, public paymentIds: string[]) {}
}

export class GetUpdatedOrderDetails {
  static readonly type = '[Order] Get Updated Order Details';
}

export class UpdateDineInOrder {
  static readonly type = '[Order] Update DineIn Order';

  constructor(public orderId: string, public order: CWCreateOrderRequest) {}
}

export class CreateGiftCards {
  public static readonly type = '[Order] Create Gift Cards';
}

export class CreateOneGiftCard {
  public static readonly type = '[Order] Create One Gift Card';

  constructor(public giftCard: GiftCard) {}
}

export class ClearPurchasedGiftCards {
  public static readonly type = '[Order] Clear Purchased Gift Cards';
}

export class Reorder {
  public static readonly type = '[Order] Reorder';

  constructor(public order: Order, public reorderingPoint?: ReorderingPoints) {}
}

export class FinishReorderItems {
  public static readonly type = '[Order] Finish Reorder Items';

  constructor(public order: Order) {}
}

export class SetGooglePay {
  public static readonly type = '[Order] Set Google Pay';

  constructor(public readonly googlePay: any) {}
}

export class SetApplePay {
  public static readonly type = '[Order] Set Apple Pay';

  constructor(public readonly applePay: any) {}
}

export class SetIsOpenTab {
  static readonly type = '[Order] Set Order Is Open Tab';

  constructor(public isOpenTab: boolean) {}
}

export class CloseOpenTab {
  static readonly type = '[Open Tab] Close Open Tab';
}

export class UpdateOpenTab {
  static readonly type = '[Open Tab] Update Open Tab';
}

export class SaveUpdatedOrderDetails {
  public static readonly type = '[Order] Save Updated Order Details';

  constructor(public readonly orderDetails: GenericOrderResponse) {}
}
