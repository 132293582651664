<div class="rs-dietary-restrictions-list-wrapper">
  <h4 class="rs-dietary-restrictions-list-wrapper__title">
    <ng-content select="[rs-title-data]"></ng-content>

    <rs-tooltip>
      <mat-icon
        rs-element
        class="rs-dietary-restrictions-list-wrapper__icon"
        [svgIcon]="infoIcon"
      ></mat-icon>
      <ng-container rs-tooltip>
        <ng-content select="[rs-tooltip-data]"></ng-content>
      </ng-container>
    </rs-tooltip>
  </h4>
  <div class="rs-dietary-restrictions-list-wrapper__content">
    <ng-content></ng-content>
  </div>
</div>
