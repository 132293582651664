<rs-dialog rs-dialog-style="default" rs-show-close-button="true">
  <ng-container rs-dialog-header>
    <span>{{ 'DELIVERY_METHOD.Complete your address' | translate }}</span>
  </ng-container>
  <div class="rs-complete-address" [dir]="'languageDirection' | translate">
    <div class="rs-complete-address__form">
      <rs-address-form
        [focusOnInput]="true"
        [rsAddressForm]="addressForm"
        [phoneFormGroup]="phoneFormGroup"
        [hideInstructions]="false"
        [phoneRequired]="true"
        [hidePhoneNumber]="!!(isLoggedIn$ | async)"
        [instructionsPlaceholder]="'PROFILE.payment.more_instructions_delivery'"
        [address1Placeholder]="'PROFILE.payment.Address'"
        [address2Placeholder]="'PROFILE.payment.Address2'"
      ></rs-address-form>
    </div>
  </div>

  <ng-container rs-dialog-actions-direction="row">
    <button
      rs-style="bold"
      [mat-dialog-close]="formGroup.value"
      [disabled]="
        addressForm.invalid ||
        (!(isLoggedIn$ | async) && phoneFormGroup.invalid)
      "
    >
      <span>{{ 'DELIVERY_METHOD.use_this_address' | translate }}</span>
    </button>
  </ng-container>
</rs-dialog>
