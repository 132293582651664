import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rs-list-item-segment',
  standalone: true,
  imports: [CommonModule],
  template: `<li
    class="rs-list-item-segment"
    [ngClass]="{
      'rs-list-item-segment--active': active,
      'rs-list-item-segment--disabled': disabled
    }"
  >
    <ng-content></ng-content>
  </li>`,
})
export class RsListItemSegmentComponent {
  @Input() disabled!: boolean;
  @Input() active!: boolean;
}

@Component({
  selector: 'rs-list-item-segment-action',
  standalone: true,
  imports: [CommonModule],
  template: `<ng-content></ng-content>`,
})
export class RsListItemSegmentActionComponent {}

@Component({
  selector: 'rs-list-item-segment-hint',
  standalone: true,
  imports: [CommonModule],
  template: `<ng-content></ng-content>`,
})
export class RsListItemSegmentHintComponent {}

@Component({
  selector: 'rs-list-item-segment-label',
  standalone: true,
  imports: [CommonModule],
  template: `<ng-content></ng-content>`,
})
export class RsListItemSegmentLabelComponent {}
