<ng-container *ngIf="modifiers?.length">
  <ng-container
    *ngFor="
      let modifier of modifiers | filterBy: ['type']:'cooking';
      let isFirst = first
    "
  >
    <ng-container
      *ngTemplateOutlet="
        rsModifierTitle;
        context: { modifier: modifier, isFirst: isFirst }
      "
    ></ng-container>
    <mat-radio-group class="rs-modifier-radio-group" labelPosition="before">
      <mat-radio-button
        *ngFor="let option of modifier.options"
        (change)="reactOnModifierSelected(option, modifier)"
        [value]="option.id"
        [checked]="
          (option.default &&
            !(modifier.id | rsIsSelectedItemModifier: metadata)) ||
          (option | rsIsSelectedItemOption: modifier.id:metadata)
        "
        class="rs-modifier-option"
      >
        {{ option.title }}
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>

  <ng-container
    *ngTemplateOutlet="rsModifierSelectors; context: { type: 'ingredient' }"
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="rsModifierSelectors; context: { type: 'side' }"
  ></ng-container>
  <ng-template #rsModifierSelectors let-type="type">
    <ng-container
      *ngFor="
        let modifier of modifiers | filterBy: ['type']:type;
        let isFirst = first
      "
    >
      <ng-container
        *ngTemplateOutlet="
          rsModifierTitle;
          context: { modifier: modifier, isFirst: isFirst }
        "
      ></ng-container>
      <mat-radio-group
        class="rs-modifier-radio-group"
        *ngIf="modifier.maxModifiers === 1"
        labelPosition="before"
      >
        <div
          class="w-100 d-flex align-items-center justify-content-between rs-modifier-option"
          *ngFor="let option of modifier.options"
        >
          <mat-radio-button
            [ngClass]="{ 'hiddent-mat-radio-container': disableOrdering }"
            [value]="option.id"
            [checked]="
              (option.default &&
                !(modifier.id | rsIsSelectedItemModifier: metadata)) ||
              (option | rsIsSelectedItemOption: modifier.id:metadata)
            "
            (change)="reactOnModifierSelected(option, modifier)"
          >
            {{ option.title }}
          </mat-radio-button>
          <div class="d-flex align-items-center">
            <div
              class="rs-items"
              *ngIf="(option | rsGetModifierConfig).price > 0"
            >
              +
              {{ (option | rsGetModifierConfig).price | cents2DollarsView }}
            </div>
          </div>
        </div>
      </mat-radio-group>
      <ng-container *ngIf="modifier.maxModifiers > 1">
        <div *ngFor="let option of modifier.options">
          <div
            *ngIf="option.itemModifierOption.available"
            class="d-flex align-items-center justify-content-between rs-modifier-option"
          >
            <div class="rs-items">
              {{ option.title }}
            </div>
            <div
              class="d-flex align-items-center"
              *ngIf="option | rsGetModifierConfig as config"
            >
              <div class="rs-items" *ngIf="config.price && config.price > 0">
                + {{ config.price | cents2DollarsView }}
              </div>

              <ng-container
                *ngFor="
                  let maxQuantity of [
                    modifiersControl.value
                      | rsGetMaxModifiersCount
                        : modifier
                        : config.maxRepetition
                        : option
                  ]
                "
              >
                <rs-counter
                  class="ms-3"
                  [quantity]="
                    option
                      | rsGetSelectedCountOfItemOption
                        : modifier
                        : metadata
                        : maxQuantity
                        : modifiersControl.value
                  "
                  [maxQuantity]="maxQuantity"
                  [isSmall]="true"
                  (quantityIncreased)="
                    increaseModifierQuantity(option, modifier)
                  "
                  (quantityDecreased)="
                    decreaseModifierQuantity(option, modifier)
                  "
                ></rs-counter>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- TODO: KEEP THIS COMMENTED CODE FOR THE TIME BEING TO CHECK IF THIS WILL BE NEEDED FOR SOME TYPES OF MODIFIERS -->
      <!--    <span class="rs-checklist" *ngIf="modifier.maxModifiers > 1 && !modifier.subtype.includes('extra_ingredient')">-->
      <!--      <ul>-->
      <!--        <li *ngFor="let option of modifier.options">-->
      <!--          <mat-checkbox [value]="option.id">-->
      <!--              {{option.title}}-->
      <!--          </mat-checkbox>-->
      <!--        </li>-->
      <!--      </ul>-->
      <!--    </span>-->
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #rsModifierTitle let-modifier="modifier" let-first="isFirst">
  <div class="d-flex">
    <div
      class="rs-title flex-grow-1"
      [ngClass]="{ 'rs-title--not-first': !first }"
    >
      {{ modifier.title }}
    </div>
    <div
      class="rs-title-info"
      *ngIf="modifier.maxModifiers > 1 && !disableOrdering"
    >
      {{ 'Up to' | translate: { amount: modifier.maxModifiers } }}
    </div>
  </div>
</ng-template>
