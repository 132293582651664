<rs-full-height-dialog [dir]="'languageDirection' | translate">
  <ng-container rs-dialog-header>
    <mat-icon
      [svgIcon]="backIcon"
      (click)="close()"
      tabindex="-1"
      [id]="backButtonId"
      class="cursor-pointer"
    ></mat-icon>

    <app-dialog-header
      [showOnlyLogoLeft]="true"
      [showBackButton]="false"
    ></app-dialog-header>
  </ng-container>

  <div class="rs-edit-info-popup__title">
    {{ (isCheckout ? 'PAY_NOW.CHECKOUT' : 'SCHEDULE.schedule') | translate }}
  </div>

  <rs-full-order-information
    [isCheckout]="isCheckout"
    (update)="partialOrderData = $event"
  ></rs-full-order-information>

  <ng-container rs-dialog-actions-direction="row">
    <button rs-style="bold" (click)="finishUpdate()">
      <span>{{ 'DELIVERY_METHOD.continue' | translate }}</span>
    </button>
  </ng-container>
</rs-full-height-dialog>
