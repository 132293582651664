import { Pipe, PipeTransform } from '@angular/core';
import { CardBrand } from '../_enums/card.enum';

import { getCardImageSrcByBrand } from '../_utils/card.utils';

@Pipe({ name: 'rsCardImageByBrand' })
export class CardImageByBrandPipe implements PipeTransform {
  public transform(cardBrand: CardBrand | undefined): string {
    return getCardImageSrcByBrand(cardBrand);
  }
}
