import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { SharedModule } from '../../../../_modules/shared.module';
import { PoweredByRockspoonComponent } from 'src/app/_shared/_modules/powered-by-rockspoon/powered-by-rockspoon.component';
import { LanguageComponent } from '../../../language/language.component';
import { AuthorisationComponent } from '../../authorisation.component';

import { AutorisationType } from '../../autorisation';
import { DeviceService } from 'src/app/_services/device.service';

@Component({
  selector: 'rs-authorisation-wrapper',
  standalone: true,
  imports: [
    SharedModule,
    PoweredByRockspoonComponent,
    LanguageComponent,
    AuthorisationComponent,
  ],
  templateUrl: 'authorisation-wrapper.component.html',
  styleUrls: ['authorisation-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorisationWrapperComponent {
  @Input() autorisation!: AutorisationType;
  @Input() hiddenAutorisationType!: AutorisationType | undefined;

  public isDesktop$: Observable<boolean> = this.deviceService.isDesktop();

  constructor(private readonly deviceService: DeviceService) {}
}
