import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function emailOrPhoneValidator(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const emailControl = form.get('email');
    const phoneControl = form.get('phone') as FormGroup;
    const isOneOfControlsValid: boolean | undefined =
      (emailControl?.valid && !!emailControl?.value) || phoneControl?.valid;

    return isOneOfControlsValid ? null : { incorrect: true };
  };
}

export function minimumOneValueIsValid(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const nameControl = form.get('name');
    const emailControl = form.get('email');
    const phoneControl = form.get('phone') as FormGroup;
    const isFormValid: boolean | undefined =
      nameControl?.valid &&
      ((emailControl?.valid && emailControl?.value) ||
        (phoneControl?.valid && phoneControl?.value.phoneNumber));
    return isFormValid ? null : { incorrect: true };
  };
}
