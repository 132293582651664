import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { AddPaymentCardFormComponent } from '../add-payment-card-form/add-payment-card-form.component';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';
import { CartState } from 'src/app/_shared/_ngxs/cart.state';
import { SavePaymentCard } from 'src/app/_shared/_ngxs/cart.actions';
import { SetDefaultCard } from 'src/app/_shared/_ngxs/profile.actions';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { Card } from 'src/app/profile/_interfaces/payment.model';
import { NewCardToAdd } from 'src/app/_shared/_models/common.interface';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { PaymentValidator } from 'src/app/_shared/_validators/payment.validation';
import {
  getCardExpirationDate,
  removeSpecialCharactersFromCardNumber,
} from 'src/app/_shared/_utils/payment-card';

@Component({
  selector: 'rs-select-payment-card-dialog',
  templateUrl: './select-payment-card-dialog.component.html',
  styleUrls: ['./select-payment-card-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPaymentCardDialogComponent {
  @ViewChildren(AddPaymentCardFormComponent)
  private cardForm!: QueryList<AddPaymentCardFormComponent>;

  public readonly cards$ = this.store.select(ProfileState.cards);
  public readonly selectedPaymentCard$ = this.store.select(
    CartState.selectedPaymentCard
  );
  public readonly userProfileAddresses$: Observable<UserProfileAddress[]> =
    this.store.select(ProfileState.addresses);
  public cards: Card[] = [];
  private initialCards: Card[] = [
    ...this.store.selectSnapshot(ProfileState.cards),
  ];
  public readonly checkIcon = CommonIcons.Checkmark;
  public newAddressForm!: FormGroup;
  public newCardForm!: FormGroup;
  public selectedAddressFromSelector: UserProfileAddress | null | undefined;
  public addressFormResetListener: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly destroyed$ = untilDestroyed();

  constructor(
    private readonly store: Store,
    private readonly dialogRef: MatDialogRef<SelectPaymentCardDialogComponent>,
    private readonly svgService: SvgIconService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly actions$: Actions
  ) {
    this.svgService.registerSvgIcons([this.checkIcon], NEW_ICONS_DIRECTORY);
  }

  ngOnInit(): void {
    this.initializeForms();
    this.initiateCardsSubscription();
  }

  private initializeForms(): void {
    this.newAddressForm = this.formBuilder.group({
      address1: [null, Validators.required],
      address2: [null],
      city: [null, Validators.required],
      state: [null, Validators.required],
      zipcode: [null, Validators.required],
      country: ['US', Validators.required],
    });

    this.newCardForm = this.formBuilder.group({
      cardHolderName: [null, [Validators.required]],
      name: [null, [Validators.required]],
      cardNumber: [
        null,
        [Validators.required, PaymentValidator.cardNumberValidator],
      ],
      expirationDate: [
        null,
        [Validators.required, PaymentValidator.expirationDateValidator],
      ],
      cvv: [
        null,
        [
          PaymentValidator.securityCodeRequired,
          PaymentValidator.cvvLengthValidator,
          Validators.pattern(/^[0-9\s]*$/),
        ],
      ],
    });
  }

  private initiateCardsSubscription(): void {
    this.cards$.pipe(this.destroyed$()).subscribe(cards => {
      this.cards = cards;
      this.changeDetectorRef.detectChanges();

      let newCard = cards.find(
        newCards => !this.initialCards.some(card => card.id === newCards.id)
      );

      !!newCard && this.selectCard(newCard);
    });

    this.newAddressForm.valueChanges
      .pipe(this.destroyed$())
      .subscribe(formValue => {
        Object.values(formValue).some(Boolean) &&
          this.addressFormResetListener.next(true);
      });
  }

  public selectCard(card: Card): void {
    this.dialogRef.close(card);
  }

  public setDefaultCard(cardId: string): void {
    this.store.dispatch(new SetDefaultCard(cardId));
  }

  public saveNewCard(): void {
    const { address1, zipcode, country, zipCode } =
      this.selectedAddressFromSelector ?? this.newAddressForm.value;
    const { expirationDate, cardNumber } = this.newCardForm.value;
    const addressValues = this.selectedAddressFromSelector
      ? { ...this.selectedAddressFromSelector }
      : { ...this.newAddressForm.value };
    const cardExpirationDate: string = getCardExpirationDate(expirationDate);
    const redactedCardNumber =
      removeSpecialCharactersFromCardNumber(cardNumber);
    const newCard: NewCardToAdd = {
      ...this.newCardForm.value,
      ...addressValues,
      tokenType: 'rockspoon',
      redactedCardNumber,
      cardNumber: redactedCardNumber,
      cardExpirationDate,
      address: address1,
      zipCode: zipCode || zipcode,
      countryCode: country,
    };

    this.store.dispatch(new SavePaymentCard(newCard));
    this.actions$
      .pipe(ofActionSuccessful(SavePaymentCard), this.destroyed$())
      .subscribe(() => {
        this.newAddressForm.reset();
        this.newCardForm.reset();
        this.addressFormResetListener.next(true);
      });
  }

  public selectAddressForAddingPayment(
    address: UserProfileAddress | null
  ): void {
    this.selectedAddressFromSelector = address;

    if (address) {
      this.newAddressForm.reset();
      this.newAddressForm.markAsUntouched();
    }
  }
}
