import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SharedModule } from '../../_modules/shared.module';

import { Card } from 'src/app/profile/_interfaces/payment.model';

@Component({
  selector: 'rs-payment-item',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './payment-item.component.html',
  styleUrls: ['./payment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentItemComponent {
  @Input() card!: Card;
  @Input() activeCard: boolean = false;
  @Output() selectCard: EventEmitter<Card> = new EventEmitter<Card>();
}
