<rs-tooltip *ngIf="allergens?.length" class="rs-item-allergens">
  <div
    class="rs-item-allergens__content"
    rs-element
    [ngClass]="{
      'rs-item-allergens__content--one-line': showOnlyOneLine,
      'rs-item-allergens__content--one-line-extra-small': small20pxIcons
    }"
  >
    <ng-container
      *ngIf="allergens | rsGetAllergenWithIcons as allergenWithIcons"
    >
      <ng-container
        *ngFor="let allergen of allergenWithIcons | rsGetAllergensContains"
      >
        <ng-container
          *ngTemplateOutlet="rsAllergenIcon; context: { value: allergen.value }"
        ></ng-container>
      </ng-container>
      <ng-container
        *ngFor="let allergen of allergenWithIcons | rsGetAllergensTrace"
      >
        <ng-container
          *ngTemplateOutlet="rsAllergenIcon; context: { value: allergen.value }"
        ></ng-container>
      </ng-container>
      <ng-container
        *ngFor="let allergen of allergenWithIcons | rsGetAllergensFree"
      >
        <ng-container
          *ngTemplateOutlet="rsAllergenIcon; context: { value: allergen.value }"
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-container
      *ngIf="allergens | rsGetAllergenWithoutIcons as allergenWithoutIcons"
    >
      <ng-container
        *ngIf="
          (allergenWithoutIcons | rsGetAllergensTrace)?.length ||
          (allergenWithoutIcons | rsGetAllergensContains)?.length
        "
      >
        <ng-container *ngTemplateOutlet="rsAllergenNoIcon"></ng-container>
      </ng-container>
    </ng-container>
  </div>

  <p rs-tooltip-header class="m-0">{{ itemName }}</p>
  <div class="d-flex flex-wrap rs-item-allergens__tooltip" rs-tooltip>
    <ng-container *ngFor="let allergen of allergens | rsGetAllergensContains">
      <ng-container
        *ngTemplateOutlet="rsAllergenInTooltip; context: { allergen: allergen }"
      ></ng-container>
    </ng-container>
    <ng-container *ngFor="let allergen of allergens | rsGetAllergensTrace">
      <ng-container
        *ngTemplateOutlet="rsAllergenInTooltip; context: { allergen: allergen }"
      ></ng-container>
    </ng-container>
    <ng-container *ngFor="let allergen of allergens | rsGetAllergensFree">
      <ng-container
        *ngTemplateOutlet="rsAllergenInTooltip; context: { allergen: allergen }"
      ></ng-container>
    </ng-container>
  </div>
</rs-tooltip>

<ng-template #rsAllergenIcon let-value="value" let-isTooltip="isTooltip">
  <mat-icon
    *ngIf="value | rsDietaryRestrictionIcon as icon"
    class="rs-item-allergens__icon"
    [ngClass]="{
      'rs-item-allergens__icon--extra-small': small20pxIcons && !isTooltip,
      'rs-item-allergens__icon--big': isTooltip,
      'rs-item-allergens__icon--allergen-free': value | rsIsAllergenFree
    }"
    [svgIcon]="icon"
  ></mat-icon>
</ng-template>

<ng-template #rsAllergenNoIcon let-isTooltip="isTooltip">
  <span
    class="rs-item-allergens__custom-allergen"
    [ngClass]="{
      'rs-item-allergens__custom-allergen--extra-small':
        small20pxIcons && !isTooltip,
      'rs-item-allergens__custom-allergen--big': isTooltip
    }"
  >
    A
  </span>
</ng-template>

<ng-template #rsEmptyIcon>
  <div class="rs-item-allergens__icon--empty"></div>
</ng-template>

<ng-template #rsAllergenInTooltip let-allergen="allergen">
  <div class="rs-item-allergens__allergen d-flex">
    <ng-container
      *ngIf="
        !!(allergen.value | rsDietaryRestrictionIcon);
        else rsNoDefaultIcon
      "
    >
      <ng-container
        *ngTemplateOutlet="
          rsAllergenIcon;
          context: { value: allergen.value, isTooltip: true }
        "
      ></ng-container>
    </ng-container>

    <ng-template #rsNoDefaultIcon>
      <ng-container
        *ngIf="!(allergen.value | rsIsAllergenFree); else rsEmptyIcon"
      >
        <ng-container
          *ngTemplateOutlet="rsAllergenNoIcon; context: { isTooltip: true }"
        ></ng-container>
      </ng-container>
    </ng-template>

    <ng-container
      *ngTemplateOutlet="rsAllergenLabel; context: { allergen: allergen }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #rsAllergenLabel let-allergen="allergen">
  <span class="rs-item-allergens__label">
    {{ allergen | rsGetAllergenLabel: (language$ | async) | titlecase }}
  </span>
</ng-template>
