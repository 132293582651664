export type InputType = 'email' | 'sales' | 'website' | 'card' | 'default';

export enum InputOptions {
  Email = 'email',
  Sales = 'sales',
  Website = 'website',
  Card = 'card',
  Default = 'default',
  Password = 'password',
  Masked = 'masked',
  CVV = 'cvv',
}

export enum DefaultOptions {
  Text = 'text',
  Date = 'date',
  Time = 'time',
  Number = 'number',
}

export enum InputPlaceholder {
  Email = 'olivia@rockspoon.com',
  Sales = '1,000.00',
  Website = 'www.website.com',
  Card = '1234 5678 9012 3456',
  Default = '',
  Password = '',
}
