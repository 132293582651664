import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { GiftCard } from '../../_interfaces/gift-card.model';

import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';

import { TEST_ID } from '../../_constants/e2e-ids.constants';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { DeviceService } from 'src/app/_services/device.service';
import { untilDestroyed } from '../../_utils/until-destroyed';

// TODO MFT-3514 make this component standalone, refactor it
@Component({
  selector: 'rs-gift-cards-list-in-order',
  templateUrl: './gift-cards-list-in-order.component.html',
  styleUrls: ['./gift-cards-list-in-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftCardsListInOrderComponent {
  @Input() items: GiftCard[] = [];
  @Input() isDesktop = true;
  @Input() showCheckboxes!: boolean;

  @Output() changedItemSelection = new EventEmitter<any>();
  @Output() changedItemsSelection = new EventEmitter<boolean>();
  @Output() decreaseItemQuantity = new EventEmitter<string>();
  @Output() toOrdering: EventEmitter<void> = new EventEmitter<void>();

  String = String;
  public everyItemInCartIsSelected: boolean = false;
  public deliveryType = '';
  public isMobileView: boolean = false;

  public readonly id = TEST_ID;
  private readonly destroyed$ = untilDestroyed();
  public readonly addOutlineIcon = CommonIcons.AddOutline;

  constructor(
    private readonly svgIconService: SvgIconService,
    protected readonly deviceService: DeviceService
  ) {
    this.registerIcon();
  }

  public ngOnInit(): void {
    this.setView();
  }

  ngOnChanges(): void {
    this.checkIsEveryItemInCartSelected();
  }

  public selectItem(cartItemId: string, selected: boolean): void {
    const currentItemIndex = this.items.findIndex(
      item => item.cartItemId === cartItemId
    );
    this.items[currentItemIndex] = {
      ...this.items[currentItemIndex],
      selectedInCart: selected,
    };
    this.changedItemSelection.emit({
      cartItemId: cartItemId,
      value: selected,
    });
    this.checkIsEveryItemInCartSelected();
  }

  public selectAllItems(selected: boolean): void {
    this.items.forEach((_, index) => {
      this.items[index].selectedInCart = selected;
    });

    this.checkIsEveryItemInCartSelected();
    this.changedItemsSelection.emit(selected);
  }

  public goToGiftCards(): void {
    this.toOrdering.emit();
  }

  private checkIsEveryItemInCartSelected(): void {
    this.everyItemInCartIsSelected = !this.items.find(
      ({ selectedInCart }) => !selectedInCart
    );
  }

  private registerIcon(): void {
    this.svgIconService.registerSvgIcons(
      [this.addOutlineIcon],
      NEW_ICONS_DIRECTORY
    );
  }

  private setView(): void {
    this.deviceService
      .isMobile()
      .pipe(this.destroyed$())
      .subscribe(isMobile => (this.isMobileView = isMobile));
  }
}
