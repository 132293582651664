<form [formGroup]="guestForm" class="d-flex flex-column align-items-center">
  <rs-input
    rs-input-style="default"
    [required]="true"
    inputType="text"
    [placeholder]="'WELCOME_SCREEN.Name' | translate"
    [control]="name"
    (onKeyDownEnter)="submitGuestForm()"
  ></rs-input>
  <button
    [disabled]="guestForm.controls['name'].errors"
    rs-style="bold"
    class="w-100 m-1"
    (click)="submitGuestForm()"
  >
    <span>
      {{ 'WELCOME_SCREEN.CONTINUE' | translate }}
    </span>
  </button>
</form>
