const NANP_COUNTRIES_ISO2_CODES = [
  'AI',
  'AG',
  'BS',
  'BB',
  'BM',
  'CA',
  'KY',
  'DM',
  'DO',
  'GD',
  'JM',
  'MS',
  'KN',
  'LC',
  'VC',
  'SX',
  'TT',
  'TC',
  'VI',
  'US',
  'VG',
  'AS',
  'GU',
  'MP',
  'PR',
];

const NPA_CODES: { [key: string]: { NPA: string[] } } = {
  // 8 Anguilla
  AI: {
    NPA: ['264'],
  },
  // 10 Antigua and Barbuda
  AG: {
    NPA: ['268'],
  },
  // 17 Bahamas
  BS: {
    NPA: ['242'],
  },
  // 20 Barbados
  BB: {
    NPA: ['246'],
  },
  // 25 Bermuda
  BM: {
    NPA: ['441'],
  },
  // 39 Canada
  CA: {
    NPA: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '367',
      '368',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '778',
      '780',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  },
  // 41 Cayman Islands
  KY: {
    NPA: ['345'],
  },
  // 60 Dominica
  DM: {
    NPA: ['767'],
  },
  // 61 Dominican Republic
  DO: {
    NPA: ['809, 829, 849'],
  },
  // 84 Grenada
  GD: {
    NPA: ['473'],
  },
  // 105 Jamaica
  JM: {
    NPA: ['876, 658'],
  },
  // 142 Montserrat
  MS: {
    NPA: ['664'],
  },
  // 181 Saint Kitts and Nevis
  KN: {
    NPA: ['869'],
  },
  // 182 Saint Lucia
  LC: {
    NPA: ['758'],
  },
  // 185 Saint Vincent and the Grenadines
  VC: {
    NPA: ['784'],
  },
  // 195 Sint Maarten
  SX: {
    NPA: ['721'],
  },
  // 219 Trinidad and Tobago
  TT: {
    NPA: ['868'],
  },
  // 223 Turks and Caicos Islands
  TC: {
    NPA: ['649'],
  },
  // 225 U.S. Virgin Islands
  VI: {
    NPA: ['340'],
  },
  // 230 United States
  US: {
    NPA: [
      '201',
      '202',
      '203',
      '205',
      '206',
      '207',
      '208',
      '209',
      '210',
      '212',
      '213',
      '214',
      '215',
      '216',
      '217',
      '218',
      '219',
      '220',
      '223',
      '224',
      '225',
      '228',
      '229',
      '231',
      '234',
      '239',
      '240',
      '248',
      '251',
      '252',
      '253',
      '254',
      '256',
      '260',
      '262',
      '267',
      '269',
      '270',
      '272',
      '276',
      '279',
      '281',
      '301',
      '302',
      '303',
      '304',
      '305',
      '307',
      '308',
      '309',
      '310',
      '312',
      '313',
      '314',
      '315',
      '316',
      '317',
      '318',
      '319',
      '320',
      '321',
      '323',
      '325',
      '326',
      '330',
      '331',
      '332',
      '334',
      '336',
      '337',
      '339',
      '341',
      '346',
      '347',
      '351',
      '352',
      '360',
      '361',
      '364',
      '380',
      '385',
      '386',
      '401',
      '402',
      '404',
      '405',
      '406',
      '407',
      '408',
      '409',
      '410',
      '412',
      '413',
      '414',
      '415',
      '417',
      '419',
      '423',
      '424',
      '425',
      '430',
      '432',
      '434',
      '435',
      '440',
      '442',
      '443',
      '445',
      '447',
      '458',
      '463',
      '469',
      '470',
      '475',
      '478',
      '479',
      '480',
      '484',
      '501',
      '502',
      '503',
      '504',
      '505',
      '507',
      '508',
      '509',
      '510',
      '512',
      '513',
      '515',
      '516',
      '517',
      '518',
      '520',
      '530',
      '531',
      '534',
      '539',
      '540',
      '541',
      '551',
      '559',
      '561',
      '562',
      '563',
      '564',
      '567',
      '570',
      '571',
      '572',
      '573',
      '574',
      '575',
      '580',
      '582',
      '585',
      '586',
      '601',
      '602',
      '603',
      '605',
      '606',
      '607',
      '608',
      '609',
      '610',
      '612',
      '614',
      '615',
      '616',
      '617',
      '618',
      '619',
      '620',
      '623',
      '626',
      '628',
      '629',
      '630',
      '631',
      '636',
      '640',
      '641',
      '646',
      '650',
      '651',
      '657',
      '659',
      '660',
      '661',
      '662',
      '667',
      '669',
      '678',
      '680',
      '681',
      '682',
      '689',
      '701',
      '702',
      '703',
      '704',
      '706',
      '707',
      '708',
      '712',
      '713',
      '714',
      '715',
      '716',
      '717',
      '718',
      '719',
      '720',
      '724',
      '725',
      '726',
      '727',
      '731',
      '732',
      '734',
      '737',
      '740',
      '743',
      '747',
      '754',
      '757',
      '760',
      '762',
      '763',
      '765',
      '769',
      '770',
      '772',
      '773',
      '774',
      '775',
      '779',
      '781',
      '785',
      '786',
      '801',
      '802',
      '803',
      '804',
      '805',
      '806',
      '808',
      '810',
      '812',
      '813',
      '814',
      '815',
      '816',
      '817',
      '818',
      '820',
      '828',
      '830',
      '831',
      '832',
      '838',
      '839',
      '840',
      '843',
      '845',
      '847',
      '848',
      '850',
      '854',
      '856',
      '857',
      '858',
      '859',
      '860',
      '862',
      '863',
      '864',
      '865',
      '870',
      '872',
      '878',
      '901',
      '903',
      '904',
      '906',
      '907',
      '908',
      '909',
      '910',
      '912',
      '913',
      '914',
      '915',
      '916',
      '917',
      '918',
      '919',
      '920',
      '925',
      '928',
      '929',
      '930',
      '931',
      '934',
      '936',
      '937',
      '938',
      '940',
      '941',
      '945',
      '947',
      '949',
      '951',
      '952',
      '954',
      '956',
      '959',
      '970',
      '971',
      '972',
      '973',
      '978',
      '979',
      '980',
      '984',
      '985',
      '986',
      '989',
    ],
  },
  // 32 British Virgin Islands
  VG: {
    NPA: ['284'],
  },
  // 5 American Samoa
  AS: {
    NPA: ['684'],
  },
  // 85 Guam
  GU: {
    NPA: ['671'],
  },
  // 158 Northern Mariana Islands
  MP: {
    NPA: ['670'],
  },
  // 172 Puerto Rico
  PR: {
    NPA: ['787', '939'],
  },
};

export { NANP_COUNTRIES_ISO2_CODES, NPA_CODES };
