import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'rs-button-segmented-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button-segmented-wrapper.component.html',
})
export class RsButtonSegmentedWrapperComponent {}
