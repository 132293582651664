import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  RsBadgeComponent,
  RsBadgeLabelComponent,
  RsBadgeLegendComponent,
  RsBadgeStatusComponent,
} from './badge.component';

const components = [
  RsBadgeComponent,
  RsBadgeLabelComponent,
  RsBadgeLegendComponent,
  RsBadgeStatusComponent,
];

@NgModule({
  imports: [CommonModule, ...components],
  exports: [...components],
})
export class BadgeModule {}
