<div
  class="rs-banner-alert"
  [ngClass]="{
    'rs-banner-alert--rounded': areCornersRounded,
    'rs-banner-alert--default': !areCornersRounded && !isBig,
    'rs-banner-alert--big flex-column': isBig,
    'rs-banner-alert--information':
      status === BannerAlertStatusOption.Information,
    'rs-banner-alert--warning': status === BannerAlertStatusOption.Warning,
    'rs-banner-alert--error': status === BannerAlertStatusOption.Error,
    'rs-banner-alert--success': status === BannerAlertStatusOption.Success
  }"
>
  <div
    class="rs-banner-alert__content align-items-center"
    [ngClass]="{
      'rs-banner-alert__content--rounded': areCornersRounded,
      'rs-banner-alert__content--default': !areCornersRounded && !isBig,
      'rs-banner-alert__content--big': isBig
    }"
  >
    <mat-icon
      *ngIf="status | rsGetBannerAlertIcon as icon"
      class="rs-banner-alert__status-icon"
      [svgIcon]="icon"
    ></mat-icon>

    <div class="d-flex flex-1 flex-column">
      <ng-content></ng-content>
    </div>
  </div>

  <div
    class="rs-banner-alert__content rs-banner-alert__content--big-supporting-text"
    #rsBannerAlertSupportingText
    [ngClass]="{
      'd-none': !isBig
    }"
  >
    <ng-content select="[rs-banner-alert-supporting-text]"></ng-content>
  </div>

  <mat-icon
    *ngIf="!areCornersRounded && !isBig"
    class="rs-banner-alert__close-icon"
    [ngClass]="{
      'rs-banner-alert__close-icon--information':
        status === BannerAlertStatusOption.Information,
      'rs-banner-alert__close-icon--warning':
        status === BannerAlertStatusOption.Warning,
      'rs-banner-alert__close-icon--error':
        status === BannerAlertStatusOption.Error,
      'rs-banner-alert__close-icon--success':
        status === BannerAlertStatusOption.Success
    }"
    [svgIcon]="closeIcon"
    (click)="close()"
  ></mat-icon>
</div>
