import { Pipe, PipeTransform } from '@angular/core';
import {
  clearString,
  getFormat,
  getParsedPhone,
} from '../_components/phone/phone-number/utils/phone.helper';

@Pipe({ name: 'phoneFormat' })
export class PhonePipe implements PipeTransform {
  transform(phoneNumber: string): string {
    const parsedNumber = getParsedPhone('+' + clearString(phoneNumber));
    return parsedNumber.format(getFormat(parsedNumber.country));
  }
}
