<ng-container *ngIf="!disableOrdering">
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="noAvailableOrderTypes">
      <ng-template
        [ngTemplateOutlet]="rsBannerAlert"
        [ngTemplateOutletContext]="{
          $implicit: ('MENUS.unavailable' | translate)
        }"
      ></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="!noAvailableOrderTypes && itemUnavailable">
      <ng-template
        [ngTemplateOutlet]="rsBannerAlert"
        [ngTemplateOutletContext]="{
          $implicit: ('MENUS.unavailable' | translate)
        }"
      ></ng-template>
    </ng-container>

    <ng-container
      *ngSwitchCase="
        !noAvailableOrderTypes &&
        !itemUnavailable &&
        !itemUnavailableForCurrentTime &&
        itemUnavailbleForCurrentOrderType
      "
    >
      <ng-template
        [ngTemplateOutlet]="rsBannerAlert"
        [ngTemplateOutletContext]="{
          $implicit:
            ('MENUS.unavailable_for_orderType'
            | translate: { orderType: orderType | titlecase })
        }"
      ></ng-template>
    </ng-container>

    <ng-container
      *ngSwitchCase="
        !noAvailableOrderTypes &&
        !itemUnavailable &&
        itemUnavailableForCurrentTime
      "
    >
      <ng-template
        [ngTemplateOutlet]="rsBannerAlert"
        [ngTemplateOutletContext]="{
          $implicit:
            ('MENUS.this_item_is_unavailable_at_the_chosen_time' | translate)
        }"
      ></ng-template>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #rsBannerAlert let-message>
  <rs-banner-alert
    class="d-block w-fit-content mb-2"
    rs-rounded="true"
    rs-status="error"
  >
    {{ message }}
  </rs-banner-alert>
</ng-template>
