import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'rs-card-horizontal-action',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  template: `<div class="rs-card-horizontal-action">
    <ng-content></ng-content>
  </div>`,
})
export class CardHorizontalActionComponent {}

@Component({
  selector: 'rs-card-horizontal-line',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div
    class="rs-card-horizontal-line w-100"
    [ngClass]="{
      'rs-card-horizontal-line--bold': isBold,
      'rs-card-horizontal-line--checkbox': isCheckbox
    }"
  >
    <ng-content></ng-content>
  </div>`,
  imports: [CommonModule],
})
export class CardHorizontalLineComponent {
  @Input() isBold: boolean = false;
  @Input() isCheckbox: boolean = false;
}

@Component({
  selector: 'rs-card-horizontal-title',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  template: `<div
    class="rs-card-horizontal-title rs-card-horizontal-title--icon"
    [ngClass]="{
      'rs-card-horizontal-title--with-actions': withActions
    }"
  >
    <ng-content></ng-content>
  </div>`,
})
export class CardHorizontalTitleComponent {
  @Input() withActions: boolean = false;
}

@Component({
  selector: 'rs-card-horizontal',
  styleUrls: ['./card-horizontal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  template: `<button
    class="rs-card-horizontal rs-card-container w-100"
    [ngClass]="{
      'rs-card-horizontal--disabled': disabled,
      'rs-card-horizontal--active rs-card-container--active':
        active && !disabled
    }"
  >
    <ng-content></ng-content>
  </button>`,
})
export class CardHorizontalComponent {
  @Input() disabled: boolean = false;
  @Input() active: boolean = false;
}
