import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Store } from '@ngxs/store';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import { VenueState } from '../../../_shared/_ngxs/venue.state';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { CommonIcon } from 'src/app/_shared/_enums/custom-icons.enum';

@Component({
  selector: 'app-dialog-header',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @Input() showLogo: boolean = true;
  @Input() showBackButton!: boolean;
  @Input() showOnlyLogoLeft!: boolean;
  @Input() backButtonId!: string;
  public venue$ = this.store.select(VenueState.venue);

  constructor(
    private readonly svgIconService: SvgIconService,
    private readonly store: Store
  ) {
    this.svgIconService.registerSvgIcons([CommonIcon.AngleLeft]);
  }
}
