import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { KeyValue } from '@angular/common';

import { Store } from '@ngxs/store';
import { Observable, firstValueFrom, map } from 'rxjs';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsMenuModule } from 'src/app/_shared/_rs-design/menu/menu.module';
import { LanguageShortNamePipe } from '../../_pipes/language.pipe';

import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { SessionStorageEngine } from 'src/app/_services/session-storage.service';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { DeviceService } from 'src/app/_services/device.service';

import { OpenLanguagesDialog } from 'src/app/_shared/_ngxs/dialog.actions';
import {
  AvailableLanguage,
  LanguageState,
} from 'src/app/_shared/_ngxs/language.state';

import { LANGUAGES } from 'src/app/_shared/_constants/languages.constant';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { SessionStorageKeys } from 'src/app/_shared/_enums/session-storage-keys.enum';
import { LocaleStorageKeys } from 'src/app/_shared/_enums/local-storage-keys.enum';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

import { copy } from 'src/app/_shared/_utils/common';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { SetLanguage } from '../../_ngxs/language.actions';

@Component({
  selector: 'rs-language',
  standalone: true,
  imports: [SharedModule, RsMenuModule, LanguageShortNamePipe],
  templateUrl: './language.component.html',
  styles: ['.rs-language__value { font-weight: 500; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageComponent {
  @Input('selectLanguageButtonId') selectLanguageButtonId!: string;
  @Input() shortText!: boolean;

  public readonly language$: Observable<AvailableLanguage> = this.store
    .select(LanguageState.language)
    .pipe(
      map(lang => {
        return (this.sessionStorage.getItem(SessionStorageKeys.language) ||
          this.localStorage.getItem(LocaleStorageKeys.language) ||
          lang) as AvailableLanguage;
      })
    );

  public readonly languages: KeyValue<string, AvailableLanguage>[] =
    copy(LANGUAGES);
  public selectedLanguage: KeyValue<string, AvailableLanguage> =
    this.languages[0];

  public isDesktop!: boolean;

  public readonly languageIcon = CommonIcons.Language;
  public readonly checkIcon = CommonIcons.Checkmark;

  private readonly destroy$ = untilDestroyed();

  constructor(
    private readonly sessionStorage: SessionStorageEngine,
    private readonly localStorage: LocalStorageService,
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly svgIconService: SvgIconService,
    private readonly deviceService: DeviceService
  ) {
    this.registerIcons();
    this.subscribeOnDevice();
  }

  ngOnInit(): void {
    this.subscribeOnLanguageChange();
  }

  public openDialog(): void {
    this.store.dispatch(new OpenLanguagesDialog());
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.languageIcon, this.checkIcon],
      NEW_ICONS_DIRECTORY
    );
  }

  public async selectLanguage(
    language: KeyValue<string, AvailableLanguage>
  ): Promise<void> {
    this.selectedLanguage = language;
    await firstValueFrom(
      this.store.dispatch(new SetLanguage(this.selectedLanguage.value))
    );
  }

  private async subscribeOnLanguageChange(): Promise<void> {
    this.language$
      .pipe(this.destroy$())
      .subscribe((currentLanguage: AvailableLanguage) => {
        this.selectedLanguage =
          this.languages.find(({ value }) => value === currentLanguage) ||
          this.languages[0];

        this.changeDetectorRef.markForCheck();
      });
  }

  private subscribeOnDevice(): void {
    this.deviceService
      .isDesktop()
      .pipe(this.destroy$())
      .subscribe(isDesktop => {
        this.isDesktop = isDesktop;
        this.changeDetectorRef.markForCheck();
      });
  }
}
