<rs-default-full-height-dialog-header
  [rs-type]="type"
  [dialogHeaderHasBorder]="dialogHeaderHasBorder"
>
  <ng-content select="[rs-dialog-header]"></ng-content>
</rs-default-full-height-dialog-header>

<rs-status-dialog-header [rs-status]="status" *ngIf="isStatusDialog">
  <ng-container rs-status-dialog-header>
    <ng-content select="[rs-status-dialog-header]"></ng-content>
  </ng-container>
  <ng-container rs-status-dialog-header-details>
    <ng-content select="[rs-status-dialog-header-details]"></ng-content>
  </ng-container>
</rs-status-dialog-header>

<div
  #rsDialogBody
  class="w-100 rs-dialog__body"
  [ngClass]="{
    'd-none': !rsDialogBody?.children?.length,
    'rs-dialog__body--actions': rsActions?.children?.length
  }"
>
  <ng-content></ng-content>
</div>

<div #rsActions>
  <rs-dialog-actions>
    <ng-container rs-dialog-actions-direction="column">
      <ng-content select="[rs-dialog-actions-direction='column']"></ng-content>
    </ng-container>
    <ng-container rs-dialog-actions-direction="row">
      <ng-content select="[rs-dialog-actions-direction='row']"></ng-content>
    </ng-container>
  </rs-dialog-actions>
</div>
