import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { SharedModule } from '../_shared/_modules/shared.module';
import { SplashScreenComponent } from './splash-screen.component';
import { SplashState } from './splash-screen.state';

@NgModule({
  imports: [SharedModule, NgxsModule.forFeature([SplashState])],
  declarations: [SplashScreenComponent],
  exports: [SplashScreenComponent],
})
export class SplashScreenModule {}
