import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { Subject } from 'rxjs';

import { PaymentValidator } from 'src/app/_shared/_validators/payment.validation';
import {
  CARD_NUMBER_MASK_CONFIG,
  EXPIRATION_DATE_MASK_CONFIG,
} from 'src/app/_shared/_constants/card.constants';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { NewPaymentCardForCheckout } from 'src/app/_shared/_interfaces/payment.model';
import { AddressForm } from 'src/app/_shared/_interfaces/address.model';
import {
  getCardExpirationDate,
  removeSpecialCharactersFromCardNumber,
} from 'src/app/_shared/_utils/payment-card';
import { NewCardToAdd } from 'src/app/_shared/_models/common.interface';

@Component({
  selector: 'rs-add-payment-card-form',
  templateUrl: './add-payment-card-form.component.html',
  styleUrls: ['./add-payment-card-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPaymentCardFormComponent {
  @Input() public isDialog: boolean = false;
  @Input() public address!: UserProfileAddress | AddressForm;

  public addressForm = this.formBuilder.group({
    country: ['US'],
    name: [null, [Validators.required]],
    address1: ['', [Validators.required]],
    address2: [''],
    city: [null, [Validators.required]],
    state: [null, [Validators.required]],
    zipcode: [null, [Validators.required]],
    instructions: [null],
    latitude: [null],
    longitude: [null],
  });

  public cardExpirationDateErrors = {
    expired: 'VALIDATION.expiration',
    minLength: 'VALIDATION.length',
  };

  public expirationDateValidator: ValidatorFn[] = [
    PaymentValidator.expirationDateValidator,
  ];

  public newCardGroup = this.formBuilder.group({
    cardHolderName: [null, [Validators.required]],
    cardNumber: [
      null,
      [Validators.required, PaymentValidator.cardNumberValidator],
    ],
    expirationDate: [
      null,
      [Validators.required, PaymentValidator.expirationDateValidator],
    ],
    cvv: [
      null,
      [
        PaymentValidator.securityCodeRequired,
        PaymentValidator.cvvLengthValidator,
        Validators.required,
        Validators.pattern(/^[0-9\s]*$/),
      ],
    ],
  });

  public get cardHolderNameControl(): AbstractControl | null {
    return this.newCardGroup.controls['cardHolderName'];
  }

  public get cardNumberControl(): AbstractControl | null {
    return this.newCardGroup.controls['cardNumber'];
  }

  public get expirationDateControl(): AbstractControl | null {
    return this.newCardGroup.controls['expirationDate'];
  }

  public get cvvControl(): AbstractControl | null {
    return this.newCardGroup.controls['cvv'];
  }

  private newCardInfo: NewPaymentCardForCheckout =
    {} as NewPaymentCardForCheckout;

  public isAddressSameAsBilling: boolean = false;
  public expirationDateMaskConfig = EXPIRATION_DATE_MASK_CONFIG;
  public cardNumberMaskConfig = CARD_NUMBER_MASK_CONFIG;
  public resetFormSubject: Subject<void> = new Subject<void>();

  @Output()
  public newCardDetails = new EventEmitter<NewCardToAdd>();

  constructor(private readonly formBuilder: FormBuilder) {}

  public onBillingAddressChange(checkBoxChange: MatCheckboxChange): void {
    this.isAddressSameAsBilling = checkBoxChange.checked;
  }

  public emitNewCardDetails(): void {
    this.newCardInfo = {
      ...this.newCardGroup.value,
      ...(this.isAddressSameAsBilling ? this.address : this.addressForm.value),
    };

    const redactedCardNumber = removeSpecialCharactersFromCardNumber(
      this.newCardInfo.cardNumber!
    );

    let newPaymentCard: NewCardToAdd = {
      tokenType: 'rockspoon',
      redactedCardNumber: redactedCardNumber,
      cardNumber: redactedCardNumber,
      cvv: this.newCardInfo.cvv,
      cardExpirationDate: getCardExpirationDate(
        this.newCardInfo.expirationDate!
      ),
      cardholderName: this.newCardInfo.cardHolderName,
      address: this.newCardInfo.address1,
      address2: this.newCardInfo.address2,
      city: this.newCardInfo.city,
      state: this.newCardInfo.state,
      zipCode: this.newCardInfo.zipcode,
      countryCode: this.newCardInfo.country,
    } as NewCardToAdd;

    this.newCardDetails.emit(newPaymentCard);
  }
}
