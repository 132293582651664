import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[rsClickOutside]',
  standalone: true,
})
export class RsClickOutsideDirective {
  @Input() isActive: boolean = true;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.isActive) {
      return;
    }

    const targetElement = event.target as HTMLElement;

    if (
      targetElement &&
      !this.elementRef.nativeElement.contains(targetElement)
    ) {
      this.rsClickOutside.emit(event);
    }
  }

  @Output() rsClickOutside = new EventEmitter<Event>();

  constructor(private readonly elementRef: ElementRef) {}
}
