import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { Card } from 'src/app/profile/_interfaces/payment.model';
import { AddressForm } from 'src/app/_shared/_interfaces/address.model';
import { NewPaymentCardForCheckout } from 'src/app/_shared/_interfaces/payment.model';
import { NewCardToAdd } from 'src/app/_shared/_models/common.interface';

import {
  CARD_NUMBER_MASK_CONFIG,
  EXPIRATION_DATE_MASK_CONFIG,
} from 'src/app/_shared/_constants/card.constants';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { MatDialogId } from 'src/app/_shared/_enums/mat-dialog-id.enum';
import { PaymentMethods } from 'src/app/_shared/_enums/payment-methods.enum';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

import {
  SavePaymentCard,
  SelectCardForOrder,
  SetPaymentMethod,
} from 'src/app/_shared/_ngxs/cart.actions';
import { InitializePaymentMethods } from 'src/app/_shared/_ngxs/profile.actions';
import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';

import {
  getCardExpirationDate,
  removeSpecialCharactersFromCardNumber,
} from 'src/app/_shared/_utils/payment-card';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { PaymentValidator } from 'src/app/_shared/_validators/payment.validation';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-dine-in-add-payment-dialog',
  templateUrl: './dine-in-add-payment-dialog.component.html',
  styleUrls: ['./dine-in-add-payment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DineInAddPaymentDialogComponent {
  public address!: UserProfileAddress | AddressForm | undefined;
  public selectedCardIndex: number | null = null;

  public addressForm = this.formBuilder.group({
    country: ['US'],
    name: [null],
    address1: ['', [Validators.required]],
    address2: [''],
    city: [null, [Validators.required]],
    state: [null, [Validators.required]],
    zipcode: [null, [Validators.required]],
    instructions: [null],
    latitude: [null],
    longitude: [null],
  });

  public newCardGroup = this.formBuilder.group({
    cardHolderName: [null, [Validators.required]],
    cardNumber: [
      null,
      [Validators.required, PaymentValidator.cardNumberValidator],
    ],
    expirationDate: [
      null,
      [Validators.required, PaymentValidator.expirationDateValidator],
    ],
    cvv: [
      null,
      [
        PaymentValidator.securityCodeRequired,
        PaymentValidator.cvvLengthValidator,
        Validators.required,
        Validators.pattern(/^[0-9\s]*$/),
      ],
    ],
  });

  private newCardInfo: NewPaymentCardForCheckout =
    {} as NewPaymentCardForCheckout;

  public isAddressSameAsBilling: boolean = false;
  public expirationDateMaskConfig = EXPIRATION_DATE_MASK_CONFIG;
  public cardNumberMaskConfig = CARD_NUMBER_MASK_CONFIG;

  public addresses$: Observable<UserProfileAddress[]> = this.store.select(
    ProfileState.addresses
  );
  private destroyed$ = untilDestroyed();

  public readonly backIcon = CommonIcons.ArrowBack;

  constructor(
    private readonly matDialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcons();
  }

  ngOnInit(): void {
    this.subscribeOnCreateCardSuccess();
  }

  public createCard(): void {
    this.newCardInfo = {
      ...this.newCardGroup.value,
      ...(!!this.address ? this.address : this.addressForm.value),
    };

    const redactedCardNumber = removeSpecialCharactersFromCardNumber(
      this.newCardInfo.cardNumber!
    );

    const newPaymentCard: NewCardToAdd = {
      tokenType: 'rockspoon',
      redactedCardNumber: redactedCardNumber,
      cardNumber: redactedCardNumber,
      cvv: this.newCardInfo.cvv,
      cardExpirationDate: getCardExpirationDate(
        this.newCardInfo.expirationDate!
      ),
      cardholderName: this.newCardInfo.cardHolderName,
      address: this.newCardInfo.address1,
      address2: this.newCardInfo.address2,
      city: this.newCardInfo.city,
      state: this.newCardInfo.state,
      zipCode: this.newCardInfo.zipcode,
      countryCode: this.newCardInfo.country,
      default: true,
    } as NewCardToAdd;

    this.store.dispatch(new SavePaymentCard(newPaymentCard));
  }

  public subscribeOnCreateCardSuccess(): void {
    this.actions$
      .pipe(ofActionSuccessful(InitializePaymentMethods), this.destroyed$())
      .subscribe(() => {
        const cards = this.store.selectSnapshot(ProfileState.cards);
        const currentCard = cards.find((card: Card) => card.default) || null;

        this.store.dispatch([
          new SetPaymentMethod(PaymentMethods.card),
          new SelectCardForOrder(currentCard),
        ]);
      });

    this.actions$
      .pipe(ofActionSuccessful(SelectCardForOrder), this.destroyed$())
      .subscribe(() => this.closePopup());
  }

  public clearSelectedAddress(): void {
    this.address = undefined;
  }

  public closePopup(): void {
    this.matDialog.getDialogById(MatDialogId.dine_in_add_payment)?.close();
  }

  public onSelectCard(index: number): void {
    this.selectedCardIndex = this.selectedCardIndex === index ? null : index;
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons([this.backIcon], NEW_ICONS_DIRECTORY);
  }
}
