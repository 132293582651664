import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryMethod, DeliveryType, OrderType } from '../_enums/order.enum';

@Pipe({ name: 'rsDeliveryTypeToDeliveryMethod', standalone: true })
export class ConvertDeliveryTypeToDeliveryMethodPipe implements PipeTransform {
  public transform(deliveryType: DeliveryType): DeliveryMethod {
    return deliveryType === OrderType.DineIn
      ? DeliveryMethod.Delivery
      : deliveryType;
  }
}

@Pipe({ name: 'rsOrderTypeToDeliveryType', standalone: true })
export class ConvertOrderTypeToDeliveryTypePipe implements PipeTransform {
  public transform(orderType: OrderType): DeliveryType {
    switch (orderType) {
      case OrderType.Delivery:
        return DeliveryMethod.Delivery;
      case OrderType.Takeout:
        return DeliveryMethod.Takeout;
      case OrderType.Curbside:
        return DeliveryMethod.Curbside;
      default:
        return OrderType.DineIn;
    }
  }
}
@Pipe({ name: 'rsIsDineInOrderType', standalone: true })
export class IsDineInOrderTypesPipe implements PipeTransform {
  public transform(deliveryType: DeliveryType): boolean {
    return deliveryType === OrderType.DineIn;
  }
}
