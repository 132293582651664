import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { GetBannerAlertIconPipe } from './banner-alert-icon.pipe';

import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import {
  BANNER_ALERT_ICONS,
  BANNER_ALERT_STATUSES,
  BannerAlertStatus,
  BannerAlertStatusOption,
} from './banner-alert';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { isStringTrue } from 'src/app/_shared/_utils/common';

@Component({
  selector: 'rs-banner-alert',
  standalone: true,
  imports: [SharedModule, GetBannerAlertIconPipe],
  templateUrl: './banner-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsBannerAlertComponent {
  @Input('rs-status') set bannerStatus(status: string) {
    this.status =
      BANNER_ALERT_STATUSES.indexOf(status as BannerAlertStatus) > -1
        ? (status as BannerAlertStatus)
        : BannerAlertStatusOption.Information;
  }

  @Input('rs-close') set isCloseAvailable(isCloseAvailable: string) {
    this.showCloseButton = isStringTrue(isCloseAvailable);
  }

  @Input('rs-rounded') set showWithRoundedCorners(
    showWithRoundedCorners: string
  ) {
    this.areCornersRounded = isStringTrue(showWithRoundedCorners);
  }

  @Input('rs-big') set showBig(showBig: string) {
    this.isBig = isStringTrue(showBig);
  }

  public status: BannerAlertStatus = BannerAlertStatusOption.Information;
  public showCloseButton!: boolean;
  public areCornersRounded!: boolean;
  public isBig!: boolean;

  public readonly BannerAlertStatusOption = BannerAlertStatusOption;
  public readonly closeIcon: CommonIcons = CommonIcons.Close;

  @Output() closed: EventEmitter<void> = new EventEmitter();

  constructor(
    private readonly svgIconService: SvgIconService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.registerActionButtons();
  }

  ngOnChanges(): void {
    this.isBig && this.changeDetectorRef.detectChanges();
  }

  private registerActionButtons(): void {
    this.svgIconService.registerSvgIcons(
      [...BANNER_ALERT_ICONS, this.closeIcon],
      NEW_ICONS_DIRECTORY
    );
  }

  public close(): void {
    this.closed.emit();
  }
}
