<rs-full-height-dialog rs-dialog-style="default">
  <ng-container rs-dialog-header>
    <mat-icon
      [svgIcon]="closePopupIcon"
      (click)="closePopup()"
      tabindex="-1"
    ></mat-icon>

    <p class="m-0">
      {{ 'DIETARY_RESTRICTIONS.dietary restrictions' | translate | titlecase }}
    </p>

    <button
      *ngIf="!!allergens?.length"
      rs-style="text"
      (click)="clearChanges()"
    >
      <span>{{ 'DIETARY_RESTRICTIONS.clear' | translate | uppercase }}</span>
    </button>
  </ng-container>

  <div *ngIf="noItems" class="w-100 mb-3">
    <rs-banner-alert rs-status="warning" rs-rounded="true">
      {{ 'DIETARY_RESTRICTIONS.no items found' | translate }}
    </rs-banner-alert>
  </div>

  <rs-dietary-restrictions-list
    #restrictionsList
    (allergensSelected)="update($event)"
    (allergensUpdated)="setIsNeedToSaveAllergens($event)"
  ></rs-dietary-restrictions-list>

  <ng-container rs-dialog-actions-direction="row">
    <button
      *ngIf="allergens?.length"
      rs-style="bold"
      class="w-100"
      (click)="save()"
    >
      <span>{{ 'DIETARY_RESTRICTIONS.save' | translate | uppercase }}</span>
    </button>

    <button
      *ngIf="!allergens?.length"
      rs-style="outline"
      class="w-100"
      (click)="closePopup()"
    >
      <span>{{ 'DIETARY_RESTRICTIONS.skip' | translate | uppercase }}</span>
    </button>
  </ng-container>
</rs-full-height-dialog>
