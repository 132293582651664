import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { ItemDetailsComponent } from '../item/item-details.component';
import { RsFullHeightDialogComponent } from 'src/app/_shared/_rs-design/dialog/full-height-dialog.component';

import { FilteredMenuState } from 'src/app/_shared/_ngxs/filtered-menu.state';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

import { QRSettingsType } from 'src/app/_shared/_interfaces/qrSettings.model';
import { Item } from 'src/app/_shared/_interfaces/item.model';
import { Menu, Section } from 'src/app/_shared/_interfaces/menu.model';

import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { SvgIconService } from 'src/app/_services/svg-icon.service';

import { NotificationService } from 'src/app/_shared/_services/notification.service';

@Component({
  selector: 'rs-item-details-mobile',
  templateUrl: 'item-details-mobile.component.html',
  standalone: true,
  imports: [SharedModule, ItemDetailsComponent, RsFullHeightDialogComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemDetailsMobileComponent {
  public readonly arrowBackIcon: CommonIcons = CommonIcons.ArrowBack;
  public readonly exportIcon: CommonIcons = CommonIcons.Export;
  public currentItem!: Item;
  public cameFromCart: boolean = false;

  private localCallback: (() => void) | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      menuId: string;
      itemId: string;
      sectionId: string | undefined;
      isMobile: boolean;
      menuName: string;
      menu?: QRSettingsType;
    },
    private readonly store: Store,
    private readonly svgService: SvgIconService,
    private readonly notifierService: NotificationService,
    private readonly translationService: TranslateService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    const { suggestions } = this.activatedRoute.snapshot.queryParams;

    this.cameFromCart = !!suggestions;

    this.findCurrentItem();
    this.svgService.registerSvgIcons(
      [this.arrowBackIcon, this.exportIcon],
      NEW_ICONS_DIRECTORY
    );
  }

  private findCurrentItem(): void {
    const currentMenu: Menu | undefined = this.store
      .selectSnapshot(FilteredMenuState.menus)
      .find(({ menuId }: Menu) => menuId === this.dialogData.menuId);

    if (!currentMenu) {
      return;
    }

    let currentSections: Section[] =
      this.dialogData.sectionId === undefined
        ? currentMenu.sections
        : currentMenu.sections.filter(
            ({ sectionId }: Section) => this.dialogData.sectionId === sectionId
          );

    !currentSections.length && (currentSections = currentMenu.sections);

    const currentItem: Item | undefined = currentSections
      .find(
        ({ items }) =>
          !!items.find(({ itemId }) => itemId === this.dialogData.itemId)
      )
      ?.items.find(({ itemId }) => itemId === this.dialogData.itemId);

    !!currentItem && (this.currentItem = currentItem);
  }

  public share(): void {
    if (!!this.currentItem && navigator.share) {
      const itemName: string =
        this.currentItem.customerFacingName || this.currentItem.name;

      navigator
        .share({
          title: itemName,
          text: `Check out ${itemName} - Seems Yummy!!!`,
          url: location.href,
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error));
    } else {
      navigator.clipboard.writeText(location.href);

      this.notifierService.showSuccess(
        this.translationService.instant('DELIVERY_METHOD.Link copied')
      );
    }
  }

  public handleBackArrowButtonClick(): void {
    this.localCallback && this.localCallback();
  }

  public handleChildEmitter(method: () => void): void {
    this.localCallback = method;
  }
}
