import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { VenueState } from 'src/app/_shared/_ngxs/venue.state';

import { DeviceService } from 'src/app/_services/device.service';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { CommonIcon } from '../../_enums/custom-icons.enum';
import { GiftCard } from '../../_interfaces/gift-card.model';
import { Venue } from '../../_models/common.interface';
import { TEST_ID } from '../../_constants/e2e-ids.constants';
import { untilDestroyed } from '../../_utils/until-destroyed';

@Component({
  selector: 'rs-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GiftCardComponent {
  @Input()
  card!: GiftCard;

  @Input()
  title!: string;

  @Input()
  disabled!: boolean;

  @Input()
  isQrPreview: boolean = true;

  @Input()
  amount!: number;

  @Input()
  resizeOnMobile: boolean = false;

  @Input()
  displayAllInformation: boolean = false;

  @Input()
  recipientName!: string;

  @Input()
  giftCardNumberDefined: boolean = true;

  public readonly CommonIcon = CommonIcon;
  public readonly id = TEST_ID;
  public venue$: Observable<Venue> = this.store.select(VenueState.venue);
  public isMobileView: boolean = false;

  private readonly destroyed$ = untilDestroyed();

  constructor(
    private readonly svgIconService: SvgIconService,
    private readonly store: Store,
    protected readonly deviceService: DeviceService
  ) {
    this.svgIconService.registerSvgIcons([CommonIcon.WhiteRockspoonLogo]);
  }

  public ngOnInit(): void {
    this.setView();
  }

  private setView(): void {
    this.deviceService
      .isMobile()
      .pipe(this.destroyed$())
      .subscribe(isMobile => (this.isMobileView = isMobile));
  }
}
