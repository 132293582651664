import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SharedModule } from '../../shared.module';
import { RsDialogComponent } from 'src/app/_shared/_rs-design/dialog/dialog.component';
import { AddressItemComponent } from 'src/app/_shared/_components/address-item/address-item.component';

import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

@Component({
  selector: 'rs-delete-default-address',
  templateUrl: 'delete-default-address.component.html',
  styleUrls: ['./delete-default-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RsDialogComponent, SharedModule, AddressItemComponent],
})
export class DeleteDefaultAddressComponent implements OnInit {
  private readonly addresses$: Observable<UserProfileAddress[]> =
    this.store.select(ProfileState.addresses);
  public notDefaultAddresses: UserProfileAddress[] = [];
  private readonly destroyed$ = untilDestroyed();
  public selectedAddressId: string = '';

  constructor(
    private readonly store: Store,
    @Inject(MAT_DIALOG_DATA) public title: string
  ) {}

  public ngOnInit(): void {
    this.setNotDefaultAddresses();
  }

  private setNotDefaultAddresses(): void {
    this.addresses$
      .pipe(this.destroyed$())
      .subscribe(
        (addresses: UserProfileAddress[]) =>
          (this.notDefaultAddresses = addresses.filter(
            (address: UserProfileAddress) => !address.isDefault
          ))
      );
  }

  public selectAddress({ id }: UserProfileAddress): void {
    this.selectedAddressId = id;
  }
}
