import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { ItemCategory } from 'src/app/_shared/_enums/item-category.enum';
import { DefaultFoodIconSize } from 'src/app/_shared/_enums/default-food-icon-size.enums';

import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-default-food-icon',
  templateUrl: './rs-default-food-icon.component.html',
  styleUrls: ['./rs-default-food-icon.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, CommonModule],
})
export class RsDefaultFoodIconComponent {
  @Input() size: DefaultFoodIconSize = DefaultFoodIconSize.Large;
  protected readonly DefaultFoodIconSize = DefaultFoodIconSize;
  private readonly meal = CommonIcons.Meal;
  public icon = this.meal;
  private readonly cocktail = CommonIcons.Cocktail;
  private readonly drink = CommonIcons.Drink;
  private readonly pizza = CommonIcons.Pizza;

  constructor(private readonly svgIconService: SvgIconService) {}

  @Input() set category(category: string | undefined) {
    category && (this.icon = this.getIcon(category as ItemCategory));
  }

  ngOnInit(): void {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.meal, this.cocktail, this.drink, this.pizza],
      NEW_ICONS_DIRECTORY
    );
  }

  private getIcon(category: ItemCategory): CommonIcons {
    switch (category) {
      case ItemCategory.alcohol:
        return this.cocktail;
      case ItemCategory.drink:
        return this.drink;
      case ItemCategory.food:
        return this.meal;
      case ItemCategory.pizza:
        return this.pizza;
      default:
        return this.meal;
    }
  }
}
