import { NgModule } from '@angular/core';

import { QRCodeModule } from 'angularx-qrcode';

import { SharedModule } from '../../_modules/shared.module';

import { GiftCardComponent } from './gift-card.component';
import { GiftCardAdditionalInfoComponent } from './gift-card-additional-info/gift-card-additional-info.component';
import { ComposeGiftCardAmountPipe } from './_pipes/compose-gift-card-amount.pipe';

@NgModule({
  declarations: [
    GiftCardComponent,
    ComposeGiftCardAmountPipe,
    GiftCardAdditionalInfoComponent,
  ],
  imports: [SharedModule, QRCodeModule],
  exports: [GiftCardComponent, GiftCardAdditionalInfoComponent],
})
export class GiftCardModule {}
