<ng-template [ngIf]="items?.length && bundleSectionSizeAttributes">
  <mat-button-toggle-group
    class="rs-modifier-radio-group w-100 d-flex flex-row align-items-center"
    *ngIf="bundleSectionSizeAttributes?.maximumQuantity === 1"
  >
    <div class="rs-modifier-option mx-2" *ngFor="let item of items">
      <mat-button-toggle
        [value]="item.id"
        [checked]="isBundleItemChecked(item)"
        (change)="reactOnItemSelected(item)"
        class="rs-modifier-option__button"
      >
        {{ item.title }}
        <span
          *ngIf="
            bundleItemSizeAttributes | getBundleItemPrice: item.id as extraPrice
          "
        >
          +
          {{ extraPrice | cents2DollarsView }}
        </span>
      </mat-button-toggle>
    </div>
  </mat-button-toggle-group>

  <div
    *ngIf="
      !bundleSectionSizeAttributes?.maximumQuantity ||
      bundleSectionSizeAttributes.maximumQuantity > 1
    "
  >
    <div
      *ngFor="let item of items"
      class="d-flex align-items-center justify-content-between rs-modifier-option"
    >
      <!-- //  *ngIf="option.itemModifierOption.available" -->
      <div class="rs-items">
        {{ item.title }}
      </div>
      <div class="d-flex align-items-center">
        <ng-template
          [ngIf]="bundleItemSizeAttributes | getBundleItemPrice: item.id"
          let-extraPrice
        >
          <div class="rs-items" *ngIf="extraPrice > 0">
            +
            {{ extraPrice | cents2DollarsView }}
          </div>
        </ng-template>

        <rs-counter
          *ngIf="!disableOrdering"
          class="ms-3"
          [quantity]="
            addedBundleItems | getBundleItemsCount: item.id:bundleSectionId
          "
          [maxQuantity]="
            addedBundleItems
              | getBundleItemMaximum
                : item.id
                : bundleSectionId
                : bundleSectionSizeAttributes
                : bundleItemSizeAttributes
          "
          [isSmall]="true"
          (quantityIncreased)="increaseQuantity(item)"
          (quantityDecreased)="decreaseQuantity(item)"
          (delete)="decreaseQuantity(item)"
        ></rs-counter>
      </div>
    </div>
  </div>
</ng-template>
