import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { catchError, Observable, switchMap, tap } from 'rxjs';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';

import { SessionState } from '../_ngxs/authentication.state';
import { CartState } from '../_ngxs/cart.state';
import { HideSpinner, ShowSpinner } from '../_components/spinner/spinner.state';
import { Logout, RefreshToken } from '../_ngxs/authentication.actions';

import { HEADERS } from '../_constants/common';
import { NotificationService } from '../_services/notification.service';
import { CustomError, CustomErrors } from './error.interfaces';

import { ENDPOINTS } from '../_constants/endpoints';
import { getVenueName } from '../_utils/common';

const DEFAULT_ERROR_MESSAGE: string = 'ERRORS.something-is-broken';
const excludedErrorCodes: number[] = [];
const paymentTransactionErrorCode: number = 4150025;
const excludedEndpoints: string[] = [ENDPOINTS.order.createProfileOrder];
const translatedErrors = new Map<number, string>([
  [4701009, 'ERRORS.wrong-credentials'],
  [4150180, 'MESSAGES_FOR_SOA_CODES.Items_cannot_be_added_to_the_cart'],
  [4702037, 'MESSAGES_FOR_SOA_CODES.Phonenumber_is_incorrect_for_US'],
  [4702008, 'MESSAGES_FOR_SOA_CODES.Your_email_account_has_not_been_validated'],
  [4701011, 'MESSAGES_FOR_SOA_CODES.Your_email_account_has_not_been_validated'],
]);

enum ErrorStatusCode {
  backEndInternalError = 500,
  unauthorizedError = 401,
  badRequest = 400,
  unknown = 0,
  tooManyAttempts = 429,
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private canRepeatAttempt: boolean = true;
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly store: Store,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
    private readonly ngZone: NgZone,
    private readonly router: Router
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpError: HttpErrorResponse) => {
        let showMessage = true;
        this.store.dispatch(new HideSpinner());

        const error: CustomError | CustomErrors = httpError.error;

        switch (true) {
          case this.isRegistrationError(
            error as CustomError,
            httpError.url ?? ''
          ):
          case this.isTooManyAttemptsError(httpError):
          case this.isUnknowError(httpError):
          case this.isCreatingDeliveryQuoteError(httpError.url ?? ''):
          case this.getIsIgnoreError(request, error):
            showMessage = false;
            break;
          case httpError.status === ErrorStatusCode.unauthorizedError:
            if (this.isTokenExpirationError(error)) {
              showMessage = false;
              const isShadowUser: boolean = this.store.selectSnapshot(
                SessionState.isShadowUser
              );
              const wasLoggedIn: boolean = this.store.selectSnapshot(
                SessionState.isLoggedIn
              );
              if (isShadowUser) {
                this.ngZone.run(() => {
                  const venueName = getVenueName(this.document.location.href);
                  const tid = this.store.selectSnapshot(CartState.tableId);
                  const tn = this.store.selectSnapshot(CartState.tableName);
                  const sid = this.store.selectSnapshot(CartState.sectionId);

                  this.router.navigate([
                    `${venueName}/dine-in/welcome`,
                    { queryParams: { tn, tid, sid } },
                  ]);
                });
              } else if (wasLoggedIn && this.canRepeatAttempt) {
                return this.refreshTokenAndResendRequest(request, next);
              } else if (this.isRefreshTokenError(httpError.url ?? '')) {
                showMessage = true;
              } else {
                this.store.dispatch(new Logout());
              }
            }
            break;

          default:
            break;
        }

        if (showMessage) {
          const message: string = this.getErrorMessage(error);
          this.showErrorMessage(message);
        }

        throw error;
      })
    );
  }

  private getIsIgnoreError(
    request: HttpRequest<any>,
    error: CustomError | CustomErrors
  ): boolean {
    return (
      this.getIsIgnoredRequest(request.url) && this.getIsIgnoredCode(error)
    );
  }

  private getIsIgnoredRequest(url: string): boolean {
    return !!excludedEndpoints.find((endpoint: string) =>
      url.includes(endpoint)
    );
  }

  private getIsIgnoredCode(error: CustomError | CustomErrors): boolean {
    return (
      excludedErrorCodes.length !== 0 &&
      excludedErrorCodes.includes(
        (error as CustomError).code ||
          (error as CustomErrors).errors.codes[0] ||
          (error as CustomErrors).errors.details[0].code
      )
    );
  }

  private translateMessage(error: CustomError): string {
    const translationKey = translatedErrors.get(error.code);

    if (translationKey) {
      return this.translateService.instant(translationKey);
    } else {
      return error.message;
    }
  }

  private refreshTokenAndResendRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.store.dispatch(new ShowSpinner());
    this.canRepeatAttempt = false;

    return this.store.dispatch(new RefreshToken()).pipe(
      switchMap(({ session: { accessToken } }: any) => {
        return this.resendRequest(accessToken, request, next).pipe(
          tap(() => (this.canRepeatAttempt = true))
        );
      })
    );
  }

  private resendRequest(
    accessToken: string,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    request = request.clone({
      headers: request.headers.set(HEADERS.ACCESS_TOKEN, accessToken),
    });

    return next.handle(request).pipe(
      tap({
        complete: () => this.store.dispatch(new HideSpinner()),
      })
    );
  }

  private getErrorMessage(error: CustomError | CustomErrors): string {
    const errors = (error as CustomErrors).errors;

    if (errors) {
      return this.translateMessage(errors.details[0]);
    }

    const { code, message } = error as CustomError;

    if (code === paymentTransactionErrorCode) {
      return this.translateService.instant(
        message.includes('code: 4251045')
          ? 'MESSAGES_FOR_SOA_CODES.Transaction_not_approved'
          : 'MESSAGES_FOR_SOA_CODES.Generic_error_message'
      );
    }

    return this.translateMessage(error as CustomError);
  }

  private isTokenExpirationError(error: CustomError | CustomErrors): boolean {
    return (
      (error as CustomError).code === ErrorStatusCode.unauthorizedError ||
      (error as CustomErrors).errors?.details[0].code ===
        ErrorStatusCode.unauthorizedError
    );
  }

  private showErrorMessage(message: string = DEFAULT_ERROR_MESSAGE): void {
    this.notificationService.showError(message);
  }

  private isRegistrationError(
    error: CustomError | CustomErrors,
    url: string
  ): boolean {
    return (
      url.includes(ENDPOINTS.authentication.registrationPart) &&
      !!(error as CustomError)?.code
    );
  }

  private isTooManyAttemptsError(httpError: HttpErrorResponse): boolean {
    return httpError.status === ErrorStatusCode.tooManyAttempts;
  }

  private isUnknowError(httpError: HttpErrorResponse): boolean {
    return httpError.status === ErrorStatusCode.unknown;
  }

  private isRefreshTokenError(url: string): boolean {
    return !!(url && url.indexOf(ENDPOINTS.authentication.refreshToken) > -1);
  }

  private isCreatingDeliveryQuoteError(url: string): boolean {
    return !!(url && url.indexOf(ENDPOINTS.delivery.createDeliveryQuote) > -1);
  }
}
