import { KeyValue } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { Observable, firstValueFrom, map } from 'rxjs';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsFullHeightDialogComponent } from 'src/app/_shared/_rs-design/dialog/full-height-dialog.component';
import { RsDialogComponent } from 'src/app/_shared/_rs-design/dialog/dialog.component';

import {
  InitializeLanguage,
  SetLanguage,
} from 'src/app/_shared/_ngxs/language.actions';
import {
  AvailableLanguage,
  LanguageState,
} from 'src/app/_shared/_ngxs/language.state';

import { SessionStorageKeys } from 'src/app/_shared/_enums/session-storage-keys.enum';
import { LocaleStorageKeys } from 'src/app/_shared/_enums/local-storage-keys.enum';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';
import { LANGUAGES } from 'src/app/_shared/_constants/languages.constant';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { SessionStorageEngine } from 'src/app/_services/session-storage.service';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { DeviceService } from 'src/app/_services/device.service';
import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

@Component({
  selector: 'rs-languages-popup',
  standalone: true,
  imports: [SharedModule, RsFullHeightDialogComponent, RsDialogComponent],
  templateUrl: './languages-popup.component.html',
  styleUrls: ['./languages-popup.component.scss'],
})
export class LanguagesPopupComponent {
  public language$: Observable<string> = this.store
    .select(LanguageState.language)
    .pipe(
      map(
        lang =>
          this.sessionStorage.getItem(SessionStorageKeys.language) ||
          this.localStorage.getItem(LocaleStorageKeys.language) ||
          lang
      )
    );

  public readonly languages: KeyValue<string, AvailableLanguage>[] = [
    ...LANGUAGES,
  ];
  public readonly id = TEST_ID;
  public selectedLanguage: KeyValue<string, AvailableLanguage> =
    this.languages[0];
  public isDesktop!: boolean;

  public readonly backIcon = CommonIcons.Back;
  public readonly checkIcon = CommonIcons.Checkmark;

  private readonly destroyed$ = untilDestroyed();

  constructor(
    private readonly dialogRef: MatDialogRef<LanguagesPopupComponent>,
    private readonly sessionStorage: SessionStorageEngine,
    private readonly localStorage: LocalStorageService,
    private readonly store: Store,
    private readonly svgIconService: SvgIconService,
    private readonly deviceService: DeviceService
  ) {
    this.registerIcons();
    this.store.dispatch(new InitializeLanguage());
  }

  public ngOnInit(): void {
    this.getData();
  }

  private async getData(): Promise<void> {
    this.language$
      .pipe(this.destroyed$())
      .subscribe(
        (languageValue: string) =>
          (this.selectedLanguage =
            this.languages.find(
              ({ value }: KeyValue<string, string>) => value === languageValue
            ) || this.languages[0])
      );

    this.isDesktop = await firstValueFrom(this.deviceService.isDesktop());
  }

  public async selectLanguage(
    language: KeyValue<string, AvailableLanguage>
  ): Promise<void> {
    this.selectedLanguage = language;
    await firstValueFrom(
      this.store.dispatch(new SetLanguage(this.selectedLanguage.value))
    );

    this.dialogRef.close();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.backIcon, this.checkIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
