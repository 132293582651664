import { PaymentCard } from 'src/app/profile/_enum/payment-card';
import { PaymentCardPattern } from 'src/app/profile/_interfaces/payment.model';

export const PAYMENT_SYSTEM_MEASURES: PaymentCardPattern[] = [
  {
    type: PaymentCard.AMEX,
    cardNumberLength: [15],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 34, max: 34 },
      { min: 37, max: 37 },
    ],
    cardNumberMask: [
      {
        cardNumberLength: [15],
        mask: [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ],
      },
    ],
    securityCodeName: 'CID',
    securityCodeLength: [3, 4],
    isSecurityCode: true,
    isSecurityCodeOptional: false,
  },
  {
    type: PaymentCard.CHINA_UNION_PAY,
    cardNumberLength: [16, 17, 18, 19],
    isCardNumberValidation: true,
    iinRanges: [{ min: 62, max: 62 }],
    cardNumberMask: [
      {
        cardNumberLength: [19],
        mask: [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ],
      },
    ],
    securityCodeName: 'CVC',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: false,
  },
  {
    type: PaymentCard.DANKORT,
    cardNumberLength: [16],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 5019, max: 5019 },
      { min: 4571, max: 4571 },
    ],
    cardNumberMask: [],
    securityCodeName: 'CID',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.DINERS_CLUB_INTERNATIONALS,
    cardNumberLength: [14, 15, 16, 17, 18, 19],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 300, max: 305 },
      { min: 3095, max: 3095 },
      { min: 36, max: 36 },
      { min: 38, max: 38 },
      { min: 38, max: 39 },
    ],
    cardNumberMask: [
      {
        cardNumberLength: [14],
        mask: [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ],
      },
    ],
    securityCodeName: 'CVV',
    securityCodeLength: [3, 4],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.DINERS_CLUB_USA_CANADA,
    cardNumberLength: [16],
    validationCodeLength: 3,
    iinRanges: [{ min: 54, max: 55 }],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3, 4],
    isSecurityCode: true,
    isSecurityCodeOptional: false,
  },
  {
    type: PaymentCard.DISCOVER,
    cardNumberLength: [14, 15, 16, 17, 18, 19],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 30000000, max: 30599999 },
      { min: 30880000, max: 31029999 },
      { min: 31120000, max: 31209999 },
      { min: 31580000, max: 31599999 },
      { min: 33370000, max: 33499999 },
      { min: 35280000, max: 35899999 },
      { min: 38000000, max: 39999999 },
      { min: 36000000, max: 36999999 },
      { min: 309500, max: 309600 },
      { min: 352800, max: 359000 },
      { min: 60110000, max: 60110999 },
      { min: 60112000, max: 60114499 },
      { min: 60117400, max: 60117499 },
      { min: 60117700, max: 60117999 },
      { min: 60118600, max: 60119999 },
      { min: 62212600, max: 62292599 },
      { min: 62400000, max: 62699999 },
      { min: 62820000, max: 62889999 },
      { min: 622126, max: 622925 },
      { min: 628200, max: 628899 },
      { min: 64400000, max: 65999999 },
      { min: 81000000, max: 81719999 },
    ],
    cardNumberMask: [],
    securityCodeName: 'CID',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.INTER_PAYMENT,
    cardNumberLength: [16, 17, 18, 19],
    isCardNumberValidation: true,
    iinRanges: [{ min: 636, max: 636 }],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.INSTA_PAYMENT,
    cardNumberLength: [16],
    isCardNumberValidation: true,
    iinRanges: [{ min: 637, max: 639 }],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: false,
  },
  {
    type: PaymentCard.JCB,
    cardNumberLength: [15, 16, 17, 18, 19],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 1800, max: 1800 },
      { min: 2131, max: 2131 },
      { min: 3088, max: 3094 },
      { min: 3096, max: 3102 },
      { min: 3112, max: 3120 },
      { min: 3158, max: 3159 },
      { min: 3337, max: 3349 },
      { min: 3528, max: 3589 },
    ],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: false,
  },
  {
    type: PaymentCard.MAESTRO_UK,
    cardNumberLength: [12, 13, 14, 15, 16, 17, 18, 19],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 6759, max: 6759 },
      { min: 676770, max: 676770 },
      { min: 676774, max: 676774 },
    ],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.MAESTRO,
    cardNumberLength: [12, 13, 14, 15, 16, 17, 18, 19],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 500000, max: 509999 },
      { min: 560000, max: 589999 },
      { min: 600000, max: 699999 },
      { min: 5018, max: 5018 },
      { min: 5020, max: 5020 },
      { min: 5038, max: 5038 },
      { min: 5893, max: 5893 },
      { min: 6304, max: 6304 },
      { min: 6759, max: 6759 },
      { min: 6761, max: 6763 },
    ],
    cardNumberMask: [
      {
        cardNumberLength: [13],
        mask: [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ],
      },
      {
        cardNumberLength: [14],
        mask: [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ],
      },
      {
        cardNumberLength: [19],
        mask: [
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
        ],
      },
    ],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.MASTERCARD,
    cardNumberLength: [16],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 51, max: 55 },
      { min: 222100, max: 272099 },
    ],
    cardNumberMask: [],
    securityCodeName: 'CVC',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.MIR,
    cardNumberLength: [16, 17, 18, 19],
    isCardNumberValidation: true,
    iinRanges: [{ min: 2200, max: 2204 }],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.NPS_PRIDNESTROVIE,
    cardNumberLength: [16],
    isCardNumberValidation: true,
    iinRanges: [{ min: 6054740, max: 6054744 }],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.TROY,
    cardNumberLength: [16],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 65, max: 65 },
      { min: 9792, max: 9792 },
    ],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: true,
  },
  {
    type: PaymentCard.VISA,
    cardNumberLength: [13, 14, 15, 16, 17, 18, 19],
    isCardNumberValidation: true,
    iinRanges: [{ min: 4, max: 4 }],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: false,
  },
  {
    type: PaymentCard.VISA_ELECTRON,
    cardNumberLength: [16],
    isCardNumberValidation: true,
    iinRanges: [
      { min: 4026, max: 4026 },
      { min: 417500, max: 417500 },
      { min: 4508, max: 4508 },
      { min: 4844, max: 4844 },
      { min: 4913, max: 4913 },
      { min: 4917, max: 4917 },
    ],
    cardNumberMask: [],
    securityCodeName: 'CVV',
    securityCodeLength: [3],
    isSecurityCode: true,
    isSecurityCodeOptional: false,
  },
] as PaymentCardPattern[];
