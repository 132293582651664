import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { NgPipesModule } from 'ngx-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { MaterialModule } from './material.module';
import { RsPipesModule } from './pipes.module';
import { DirectivesModule } from '../_directives/directives.module';
import { RsInputModule } from '../_rs-design/input/input.module';
import { RsAutoCompleteInputModule } from '../_rs-design/auto-complete-input/auto-complete-input.module';
import { RsButtonSegmentedWrapperComponent } from '../_rs-design/button/button-segmented-wrapper.component';
import { RsSelectModule } from '../_rs-design/select/select.module';
import { DrawerModule } from '../_rs-design/drawer/drawer.module';
import { RsListItemSegmentComponent } from '../_rs-design/list-item/list-item.component';
import { ListItemSegmentModule } from '../_rs-design/list-item/list-item.module';
import { GridItemModule } from '../_rs-design/grid-item/grid-item.module';
import { BadgeModule } from '../_rs-design/badge/badge.module';
import { CardHorizontalModule } from '../_rs-design/card-horizontal/card-horizontal.module';
import { RadioButtonBoxModule } from '../_rs-design/radio-button-box/radio-button-box.module';
import { RsTextAreaModule } from '../_rs-design/text-area/text-area.module';

const standaloneComponents = [
  RsButtonSegmentedWrapperComponent,
  RsListItemSegmentComponent,
];

@NgModule({
  imports: [...standaloneComponents],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    HttpClientModule,
    MaterialModule,
    TranslateModule,
    SlickCarouselModule,
    RsPipesModule,
    DirectivesModule,
    NgxHideOnScrollModule,
    RsInputModule,
    RsAutoCompleteInputModule,
    RsSelectModule,
    ...standaloneComponents,
    ListItemSegmentModule,
    DrawerModule,
    BadgeModule,
    CardHorizontalModule,
    GridItemModule,
    RadioButtonBoxModule,
    RsTextAreaModule,
  ],
})
export class SharedModule {}
