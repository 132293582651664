import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Store } from '@ngxs/store';

import { RsPipesModule } from '../../_modules/pipes.module';
import { DirectivesModule } from '../../_directives/directives.module';
import { SharedModule } from '../../_modules/shared.module';
import { TEST_ID } from '../../_constants/e2e-ids.constants';
import { FormFieldErrors } from '../../_interfaces/form-errors.interface';
import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';

import { RsAutocompleteFieldComponent } from '../rs-autocomplete-field/rs-autocomplete-field.component';
import { HideSpinner, ShowSpinner } from '../spinner/spinner.state';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { GeolocationService } from 'src/app/_services/geolocation.service';

interface AddressAutocomplete {
  address: string;
  suggestionId: string;
}

@Component({
  selector: 'rs-address-autocomplete',
  standalone: true,
  imports: [
    SharedModule,
    RsPipesModule,
    DirectivesModule,
    RsAutocompleteFieldComponent,
  ],
  templateUrl: 'address-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressAutocompleteComponent {
  @Output() addressDetails = new EventEmitter<UserProfileAddress>();
  @Output() clearInput = new EventEmitter<UserProfileAddress>();

  @Input() rsFormControl = new FormControl('');
  @Input() selectedCountryCode: string = 'US';
  @Input() placeholder: string = '';
  @Input() matFormFieldClass: string = '';
  @Input() focusOnInput!: boolean;
  @Input() public showLocationIcon: boolean = false;
  @Input() public iconType!: CommonIcons;

  @ViewChild('address', { static: false })
  addressInput!: ElementRef<HTMLInputElement>;

  public addresses: AddressAutocomplete[] = [];
  public addressLabels: string[] = [];
  public readonly id = TEST_ID.addressAutocomplete.addressAutocompleteField;
  public controlErrors: FormFieldErrors[] = [
    {
      hasError: 'required',
      errorMessage: 'VALIDATION.REQUIRED',
    },
  ];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store,
    private readonly geolocationService: GeolocationService
  ) {}

  ngAfterViewInit(): void {
    this.makeAddressInputFocused();
  }

  private makeAddressInputFocused(): void {
    // this setTimeout needed to have UI rendered
    setTimeout(
      () => this.focusOnInput && this.addressInput?.nativeElement?.focus(),
      300
    );
  }

  public async reactOnInputText(event: Event): Promise<void> {
    const text = (event.target as HTMLInputElement).value;
    const country = this.selectedCountryCode;

    if (text) {
      this.store.dispatch(new ShowSpinner());

      try {
        const addresses: AddressAutocomplete[] =
          await this.geolocationService.suggestAddress(text, country);

        this.addresses = addresses;
        this.addressLabels = addresses.map(
          ({ address }: AddressAutocomplete) => address
        );
        this.store.dispatch(new HideSpinner());
        this.changeDetectorRef.markForCheck();
      } catch (e) {
        console.error(e);
      } finally {
        this.store.dispatch(new HideSpinner());
      }
    }
  }

  public findAddressByLabel(label: string): AddressAutocomplete | undefined {
    return this.addresses.find(
      ({ address }: AddressAutocomplete) => address === label
    );
  }

  public async reactOnSelectFromAutocomplete(label: string): Promise<void> {
    const addressAutocomplete: AddressAutocomplete | undefined =
      this.findAddressByLabel(label);

    if (addressAutocomplete) {
      const { address, suggestionId } = addressAutocomplete;

      this.store.dispatch(new ShowSpinner());
      this.rsFormControl.reset(address);

      try {
        const addressDetails: UserProfileAddress =
          await this.geolocationService.getAddress(suggestionId);

        this.addressDetails.emit(addressDetails);
      } catch (e) {
        console.error(e);
      } finally {
        this.store.dispatch(new HideSpinner());
      }
    }
  }

  public selectFirstSuggestion(): void {
    const label = this.addressLabels[0];

    this.reactOnSelectFromAutocomplete(label);
  }

  public reactOnClear(): void {
    this.rsFormControl.setValue('');
    this.clearInput.emit();
  }
}
