<rs-default-dialog-header
  mat-dialog-title
  *ngIf="!isStatusDialog"
  [rs-show-close-button]="showCloseButton"
  [dialogHeaderHasBorder]="dialogHeaderHasBorder"
>
  <ng-content select="[rs-dialog-header]"></ng-content>
</rs-default-dialog-header>

<rs-status-dialog-header
  [rs-status]="status"
  *ngIf="isStatusDialog"
  mat-dialog-title
>
  <ng-container rs-status-dialog-header>
    <ng-content select="[rs-status-dialog-header]"></ng-content>
  </ng-container>
  <ng-container rs-status-dialog-header-details>
    <ng-content select="[rs-status-dialog-header-details]"></ng-content>
  </ng-container>
</rs-status-dialog-header>

<div
  #rsDialogBody
  class="w-100 rs-dialog__body"
  [ngClass]="{
    'd-none': !rsDialogBody?.children?.length,
    'rs-dialog__body--without-buttons': noButtons
  }"
  mat-dialog-content
>
  <ng-content></ng-content>
</div>

<rs-dialog-actions mat-dialog-actions #rsDialogActions>
  <ng-container rs-dialog-actions-direction="column">
    <ng-content select="[rs-dialog-actions-direction='column']"></ng-content>
  </ng-container>
  <ng-container rs-dialog-actions-direction="row">
    <ng-content select="[rs-dialog-actions-direction='row']"></ng-content>
  </ng-container>
</rs-dialog-actions>
