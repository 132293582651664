import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
} from '@angular/material/bottom-sheet';

import { Action, State, StateContext, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, first, firstValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash';

import { ItemDetailsMobileComponent } from 'src/app/venue/item-mobile/item-details-mobile.component';
import { AuthorisationDialogComponent } from '../_components/authorisation/authorisation-dialog/authorisation-dialog.component';
import { PayNowPopupComponent } from 'src/app/venue/_components/pay-now-popup/pay-now-popup.component';
import { DeleteDefaultAddressComponent } from '../_modules/address-book/delete-default-address/delete-default-address.component';
import { NewAddressComponent } from '../_modules/address-book/new-address/new-address.component';
import { DeleteDefaultCardComponent } from 'src/app/profile/_components/payment-methods/delete-default-card/delete-default-card.component';
import { AddNewCardComponent } from 'src/app/venue/dine-in/_components/add-new-card/add-new-card.component';
import { ConfirmationPopupComponent } from '../_components/confirmation-popup/confirmation-popup.component';
import { LogoutConfirmationDialogComponent } from 'src/app/profile/_components/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { SelectPaymentCardDialogComponent } from 'src/app/venue/_components/pay-now-popup/select-payment-card-dialog/select-payment-card-dialog.component';
import { CheckoutOrderInformationPopupComponent } from '../_modules/full-order-information/checkout-order-information-popup/checkout-order-information-popup.component';
import { SelectAddressComponent } from '../_modules/full-order-information/checkout-order-information-popup/select-address/select-address.component';
import { CompleteAddressPopupComponent } from '../_components/complete-address-popup/complete-address-popup.component';
import { InitialAddressPopupComponent } from '../_components/initial-address-popup/initial-address-popup.component';
import { LanguagesPopupComponent } from '../_components/language/languages-popup/languages-popup.component';
import { DineInEditPaymentDialogComponent } from '../_components/dine-in-paying-with/dine-in-edit-payment-dialog/dine-in-edit-payment-dialog.component';
import { DineInAddPaymentDialogComponent } from '../_components/dine-in-paying-with/dine-in-add-payment-dialog/dine-in-add-payment-dialog.component';
import { AutorisationType } from '../_components/authorisation/autorisation';
import { DeviceService } from 'src/app/_services/device.service';
import { ScheduleOrderBottomSheetComponent } from '../_modules/full-order-information/schedule-order-bottom-sheet/schedule-order-bottom-sheet.component';
import { VersionComponent } from '../_components/version/version.component';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { SessionStorageEngine } from 'src/app/_services/session-storage.service';

import {
  OpenDialog,
  OpenLoginDialog,
  OpenSignUpDialog,
  OpenPayNowDialog,
  OpenDeleteDefaultAddressDialog,
  OpenAddNewCardDialog,
  OpenDeleteDefaultCardDialog,
  RemoveDialogFromHistory,
  ClearHistory,
  OpenNewAddressDialog,
  OpenCartDialog,
  OpenConfirmationDialog,
  OpenLogoutConfirmationDialog,
  OpenSelectCardForPaymentDialog,
  OpenCompleteAddressDialog,
  OpenEditCheckoutInfoDialog,
  OpenAddressDeliveryDialog,
  OpenInitialAddressDialog,
  OpenSavedAddressesDialog,
  OpenLanguagesDialog,
  OpenDineInChangePaymentmethodDialog,
  OpenDineInAddPaymentmethodDialog,
  OpenForgetPasswordDialog,
  OpenBottomSheet,
  OpenVersionDialog,
  OpenItemDetailsMobileDialog,
  OpenLoginDialogWithoutGuest,
} from './dialog.actions';
import { LanguageState } from './language.state';

import { SessionStorageKeys } from '../_enums/session-storage-keys.enum';
import { LocaleStorageKeys } from '../_enums/local-storage-keys.enum';
import { MatDialogId } from '../_enums/mat-dialog-id.enum';

import {
  CART_DIALOG_CONFIG,
  DEFAULT_DIALOG_CONFIG,
  DESKTOP_DIALOG_CONFIG,
  DINE_IN_ADD_PAYMENT_DIALOG_CONFIG,
  DINE_IN_CHANGE_PAYMENT_DIALOG_CONFIG,
  FULL_SCREEN_DIALOG_CONFIG,
  LOGIN_DIALOG_CONFIG,
  SCHEDULE_DELIVERY_CONFIG,
} from '../_constants/mat-dialog-config.constant';
import {
  BOTTOM_SHEET_CLASS,
  DIALOG_PANEL_CLASS,
} from '../_constants/mat-dialog-panel-classes.constant';

import { CartDialogComponent } from '../_components/cart-dialog/cart-dialog.component';

interface DialogStateModel {
  history: string[]; // array of dialog's ids
}

@State<DialogStateModel>({
  name: 'dialog',
  defaults: {
    history: [],
  },
})
@Injectable()
export class DialogState {
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly ngZone: NgZone,
    private readonly dialog: MatDialog,
    private readonly matBottomSheet: MatBottomSheet,
    private readonly store: Store,
    private readonly translateService: TranslateService,
    private readonly sessionStorage: SessionStorageEngine,
    private readonly localStorage: LocalStorageService,
    private readonly deviceService: DeviceService
  ) {}

  @Action(OpenDialog)
  openDialog(
    { getState, patchState, dispatch }: StateContext<DialogStateModel>,
    { component, config }: OpenDialog
  ): void {
    const dialogId = config?.id;

    !config && (config = DEFAULT_DIALOG_CONFIG);

    this.ngZone.run(() => {
      const dialogRef = this.dialog.open(component, config);
      const language: string =
        this.sessionStorage.getItem(SessionStorageKeys.language) ||
        this.localStorage.getItem(LocaleStorageKeys.language) ||
        this.store.selectSnapshot(LanguageState.language);

      this.translateService.use(language);

      const getUpdatedHistory = (dialogId: string): string[] => {
        let history: string[] = cloneDeep(getState().history);

        history.push(dialogId);

        return history;
      };

      if (!dialogId) {
        return;
      }

      dialogRef
        .afterOpened()
        .pipe(first())
        .subscribe(() => patchState({ history: getUpdatedHistory(dialogId) }));

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(() => dispatch(new RemoveDialogFromHistory(dialogId)));
    });
  }

  @Action(OpenBottomSheet)
  openBottomSheet(
    _: StateContext<DialogStateModel>,
    { component, config }: OpenBottomSheet
  ) {
    this.ngZone.run(() => {
      const language: string =
        this.sessionStorage.getItem(SessionStorageKeys.language) ||
        this.localStorage.getItem(LocaleStorageKeys.language) ||
        this.store.selectSnapshot(LanguageState.language);

      this.translateService.use(language);

      this.matBottomSheet.open(component, {
        ...config,
        autoFocus: false,
        disableClose: false,
        closeOnNavigation: true,
        hasBackdrop: true,
        direction: language === 'ar' ? 'rtl' : 'ltr',
      } as MatBottomSheetConfig);
    });
  }

  // TODO refactor both actions
  @Action(OpenCartDialog)
  openCartDialog({ dispatch }: StateContext<DialogStateModel>): void {
    dispatch(new OpenDialog(CartDialogComponent, CART_DIALOG_CONFIG));
  }

  @Action(OpenLanguagesDialog)
  async openLanguagesDialog({
    dispatch,
  }: StateContext<DialogStateModel>): Promise<void> {
    const isDesktop = await firstValueFrom(this.deviceService.isDesktop());
    const config = isDesktop
      ? DESKTOP_DIALOG_CONFIG
      : FULL_SCREEN_DIALOG_CONFIG;

    dispatch(
      new OpenDialog(LanguagesPopupComponent, {
        id: MatDialogId.languages,
        ...config,
        direction: this.translateService.instant('languageDirection'),
      })
    );
  }

  @Action(OpenVersionDialog)
  openVersionDialog({ dispatch }: StateContext<DialogStateModel>): void {
    dispatch(
      new OpenDialog(VersionComponent, {
        id: MatDialogId.version,
        ...DESKTOP_DIALOG_CONFIG,
        maxHeight: '400px',
        direction: this.translateService.instant('languageDirection'),
      })
    );
  }

  @Action(OpenAddressDeliveryDialog)
  openAddressDeliveryDialog({
    dispatch,
  }: StateContext<DialogStateModel>): void {
    dispatch(new OpenDialog(SelectAddressComponent, SCHEDULE_DELIVERY_CONFIG));
  }

  @Action(OpenForgetPasswordDialog)
  openForgetPasswordDialog({
    dispatch,
  }: StateContext<OpenForgetPasswordDialog>): Observable<void> {
    const config = {
      ...LOGIN_DIALOG_CONFIG,
      direction: this.translateService.instant('languageDirection'),
      autoFocus: false,
      data: {
        openedPanelType: AutorisationType.RestorePassword,
      },
    };
    return dispatch(new OpenDialog(AuthorisationDialogComponent, config));
  }

  @Action(OpenLoginDialogWithoutGuest)
  openLoginDialogWithoutGuest(
    { dispatch }: StateContext<DialogStateModel>,
    { skipRedirect }: OpenLoginDialog
  ): Observable<void> {
    const config = {
      ...LOGIN_DIALOG_CONFIG,
      direction: this.translateService.instant('languageDirection'),
      autoFocus: false,
      data: {
        skipRedirect,
        openedPanelType: AutorisationType.Login,
        hiddenAutorisationType: AutorisationType.Guest,
      },
    };

    return dispatch(new OpenDialog(AuthorisationDialogComponent, config));
  }

  @Action(OpenLoginDialog)
  openLoginDialog(
    { dispatch }: StateContext<DialogStateModel>,
    { skipRedirect }: OpenLoginDialog
  ): Observable<void> {
    const config = {
      ...LOGIN_DIALOG_CONFIG,
      direction: this.translateService.instant('languageDirection'),
      autoFocus: false,
      data: {
        skipRedirect,
        openedPanelType: AutorisationType.Login,
      },
    };

    return dispatch(new OpenDialog(AuthorisationDialogComponent, config));
  }

  @Action(OpenSignUpDialog)
  openSignUpDialog(
    { dispatch }: StateContext<DialogStateModel>,
    { signUpData }: OpenSignUpDialog
  ): void {
    dispatch(
      new OpenDialog(AuthorisationDialogComponent, {
        ...LOGIN_DIALOG_CONFIG,
        data: { ...signUpData, openedPanelType: AutorisationType.SignUp },
        autoFocus: false,
        direction: this.translateService.instant('languageDirection'),
      })
    );
  }

  @Action(OpenPayNowDialog)
  openPayNowDialog(
    { dispatch }: StateContext<DialogStateModel>,
    { isGiftCard }: OpenPayNowDialog
  ): void {
    dispatch(
      new OpenDialog(PayNowPopupComponent, {
        id: MatDialogId.pay_now,
        ...FULL_SCREEN_DIALOG_CONFIG,
        data: isGiftCard,
        autoFocus: false,
      })
    );
  }

  @Action(OpenNewAddressDialog)
  openNewAddressDialog(
    { dispatch }: StateContext<DialogStateModel>,
    { data }: OpenNewAddressDialog
  ): void {
    dispatch(
      new OpenDialog(NewAddressComponent, {
        id: MatDialogId.new_address,
        panelClass: [DIALOG_PANEL_CLASS.popup, DIALOG_PANEL_CLASS.newAddress],
        autoFocus: false,
        data,
      })
    );
  }

  @Action(OpenDeleteDefaultAddressDialog)
  openDeleteDefaultAddressDialog({
    dispatch,
  }: StateContext<DialogStateModel>): void {
    dispatch(
      new OpenDialog(DeleteDefaultAddressComponent, {
        id: MatDialogId.delete_default_address,
        panelClass: DIALOG_PANEL_CLASS.popup,
        data: this.translateService.instant('ADDRESS_BOOK.edit_default'),
        autoFocus: false,
      })
    );
  }

  @Action(OpenAddNewCardDialog)
  OpenAddNewCardDialog(
    { dispatch }: StateContext<DialogStateModel>,
    { data, selectedCard }: OpenAddNewCardDialog
  ): void {
    dispatch(
      new OpenDialog(AddNewCardComponent, {
        id: MatDialogId.add_new_card,
        data: { ...data, selectedCard },
        autoFocus: false,
      })
    );
  }

  @Action(OpenDeleteDefaultCardDialog)
  openDeleteDefaultCardDialog(
    { dispatch }: StateContext<DialogStateModel>,
    { data }: OpenDeleteDefaultCardDialog
  ): void {
    dispatch(
      new OpenDialog(DeleteDefaultCardComponent, {
        id: MatDialogId.delete_default_card,
        minWidth: '500px',
        panelClass: DIALOG_PANEL_CLASS.popup,
        data,
        autoFocus: false,
      })
    );
  }

  @Action(OpenConfirmationDialog)
  openConfirmationDialog(
    { dispatch }: StateContext<DialogStateModel>,
    { data }: OpenConfirmationDialog
  ) {
    return dispatch(
      new OpenDialog(ConfirmationPopupComponent, {
        id: MatDialogId.confirmation,
        panelClass: DIALOG_PANEL_CLASS.popup,
        data,
        autoFocus: false,
      })
    );
  }

  @Action(OpenCompleteAddressDialog)
  openCompleteAddressDialog(
    { dispatch }: StateContext<DialogStateModel>,
    { address }: OpenCompleteAddressDialog
  ) {
    dispatch(
      new OpenDialog(CompleteAddressPopupComponent, {
        id: MatDialogId.complete_address,
        panelClass: DIALOG_PANEL_CLASS.popup,
        data: address,
        autoFocus: false,
      })
    );
  }

  @Action(OpenInitialAddressDialog)
  openInitialAddressDialog(
    { dispatch }: StateContext<DialogStateModel>,
    { address }: OpenInitialAddressDialog
  ) {
    dispatch(
      new OpenDialog(InitialAddressPopupComponent, {
        id: MatDialogId.initial_address,
        panelClass: DIALOG_PANEL_CLASS.popup,
        data: address,
        autoFocus: false,
      })
    );
  }

  @Action(OpenSavedAddressesDialog)
  openSavedAddressesDialog({ dispatch }: StateContext<DialogStateModel>) {
    dispatch(
      new OpenDialog(SelectAddressComponent, {
        maxHeight: '90vh',
        id: MatDialogId.saved_addresses,
        panelClass: DIALOG_PANEL_CLASS.newAddress,
        autoFocus: false,
      })
    );
  }

  @Action(OpenEditCheckoutInfoDialog)
  async openEditCheckoutInfo(
    { dispatch }: StateContext<DialogStateModel>,
    { isCheckout }: OpenEditCheckoutInfoDialog
  ) {
    const isMobile = await firstValueFrom(this.deviceService.isMobile());

    if (isMobile && !isCheckout) {
      dispatch(
        new OpenBottomSheet(ScheduleOrderBottomSheetComponent, {
          data: { isCheckout },
          panelClass: [
            BOTTOM_SHEET_CLASS.showHeaderUnderBackdrop,
            BOTTOM_SHEET_CLASS.scheduleBottomScheet,
          ],
        } as MatBottomSheetConfig)
      );
    } else {
      dispatch(
        new OpenDialog(CheckoutOrderInformationPopupComponent, {
          id: MatDialogId.edit_delivery_info,
          ...FULL_SCREEN_DIALOG_CONFIG,
          panelClass: [
            DIALOG_PANEL_CLASS.editDeliveryInfo,
            DIALOG_PANEL_CLASS.fullScreenModal,
          ],
          data: { isCheckout },
          autoFocus: false,
        })
      );
    }
  }

  @Action(OpenLogoutConfirmationDialog)
  openLogoutConfirmationDialog({
    dispatch,
  }: StateContext<DialogStateModel>): void {
    dispatch(
      new OpenDialog(LogoutConfirmationDialogComponent, {
        id: MatDialogId.logout_confirmation,
        autoFocus: false,
      })
    );
  }

  @Action(RemoveDialogFromHistory)
  removeDialogFromHistory(
    { getState, patchState }: StateContext<DialogStateModel>,
    { id }: RemoveDialogFromHistory
  ): void {
    const history = getState().history.filter(dialogId => dialogId !== id);

    patchState({ history });
  }

  @Action(ClearHistory)
  clearHistory({ patchState }: StateContext<DialogStateModel>): void {
    patchState({ history: [] });
  }

  @Action(OpenSelectCardForPaymentDialog)
  openSelectCardForPaymentDialog({
    dispatch,
  }: StateContext<DialogStateModel>): void {
    dispatch(
      new OpenDialog(SelectPaymentCardDialogComponent, {
        id: MatDialogId.select_payment_card,
        panelClass: DIALOG_PANEL_CLASS.paymentCards,
        autoFocus: false,
      })
    );
  }

  @Action(OpenDineInChangePaymentmethodDialog)
  openDineInChangePaymentmethodDialog({
    dispatch,
  }: StateContext<DialogStateModel>): void {
    dispatch(
      new OpenDialog(
        DineInEditPaymentDialogComponent,
        DINE_IN_CHANGE_PAYMENT_DIALOG_CONFIG
      )
    );
  }

  @Action(OpenDineInAddPaymentmethodDialog)
  openDineInAddPaymentmethodDialog({
    dispatch,
  }: StateContext<DialogStateModel>): void {
    dispatch(
      new OpenDialog(
        DineInAddPaymentDialogComponent,
        DINE_IN_ADD_PAYMENT_DIALOG_CONFIG
      )
    );
  }

  @Action(OpenItemDetailsMobileDialog)
  openItemDetailsMobileDialog(
    { dispatch }: StateContext<DialogStateModel>,
    {
      menuId,
      itemId,
      sectionId,
      isMobile,
      menuName,
      menu,
    }: OpenItemDetailsMobileDialog
  ): void {
    dispatch([
      new OpenDialog(ItemDetailsMobileComponent, {
        id: MatDialogId.item_details_dialog_screen,
        data: { menuId, itemId, sectionId, isMobile, menuName, menu },
        ...FULL_SCREEN_DIALOG_CONFIG,
        panelClass: [
          DIALOG_PANEL_CLASS.dialogWith100dvhHeight,
          DIALOG_PANEL_CLASS.dialogWithMax100dvhHeight,
        ],
        disableClose: true,
        autoFocus: false,
      }),
    ]);
  }
}
