import { NgModule } from '@angular/core';

import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';

import { SharedModule } from '../../_modules/shared.module';
import { RsInputModule } from '../input/input.module';
import { RsPhoneInputComponent } from './phone-input.component';

@NgModule({
  declarations: [RsPhoneInputComponent],
  exports: [RsPhoneInputComponent],
  imports: [
    SharedModule,
    CurrencyMaskModule,
    NgxMatIntlTelInputComponent,
    NgxMaskModule.forChild(),
    RsInputModule,
  ],
})
export class RsPhoneInputModule {}
