import {
  BurqWebhookRequestBody,
  CreateDeliveryRequest,
} from '../_interfaces/delivery.model';

export class CreateDelivery {
  static readonly type = '[Delivery] Create Delivery';
  constructor(public requestBody: CreateDeliveryRequest) {}
}

export class InitiateDelivery {
  static readonly type = '[Delivery] Initiate Delivery';
  constructor(public deliveryId: string) {}
}

export class GetDeliveryById {
  static readonly type = '[Delivery] Get Delivery By Id';
  constructor(public deliveryId: string, public orderId?: string) {}
}

export class CancelDelivery {
  static readonly type = '[Delivery] Cancel Delivery';
  constructor(public deliveryId: string) {}
}

export class HandleBurqWebhook {
  static readonly type = '[Delivery] Handle Burq Webhook';
  constructor(public requestBody: BurqWebhookRequestBody) {}
}
