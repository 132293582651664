<div
  class="rs-powered-by-rockspoon"
  [class.rs-powered-by-rockspoon--dark]="darkTheme"
  [dir]="'languageDirection' | translate"
>
  <img
    *ngIf="showLogo && (venue$ | async)?.venue as venue"
    rsImageLoader
    loading="lazy"
    class="rs-powered-by-rockspoon__venue-logo"
    [src]="venue?.logo"
    [alt]="venue?.name"
  />
  <span *ngIf="showPowered" class="rs-powered-by-rockspoon--powered">
    {{ 'POWERED_BY' | translate }}
    <a href="https://www.rockspoon.com" target="_blank" rel="noopener">
      <img
        src="../../../../../assets/img/rockspoon-logo.svg"
        class="rs-powered-by-rockspoon__rs-logo"
        alt="Rockspoon"
        loading="lazy"
      />
    </a>
  </span>
</div>
