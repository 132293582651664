<rs-autocomplete-field
  class="d-block mb-4"
  [autocompleteFieldPlaceholder]="placeholder"
  [control]="rsFormControl"
  [id]="id"
  [matFormFieldClass]="matFormFieldClass"
  [options]="addressLabels"
  [formErrors]="controlErrors"
  (optionSelected)="reactOnSelectFromAutocomplete($event)"
  (filterAutocompleteOptions)="reactOnInputText($event)"
  (enterPressedInInput)="selectFirstSuggestion()"
  (cleared)="reactOnClear()"
  [iconType]="iconType"
></rs-autocomplete-field>
