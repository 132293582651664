<section class="rs-tips-selector">
  <div class="rs-tips-selector__values">
    <div *ngFor="let tip of tips$ | async" class="rs-tips-selector__value">
      <rs-grid-item
        rs-style="icon-label"
        [active]="!isCustom && tip === selectedPercentage"
        (click)="dispatchSetTips(0, tip, false)"
      >
        <rs-grid-item-label>{{
          tip + '%' | rsEasternArabicNumerals
        }}</rs-grid-item-label>
      </rs-grid-item>
    </div>
    <div class="rs-tips-selector__value">
      <rs-grid-item
        rs-style="icon-label"
        (click)="reactOnClickByCustomControl()"
      >
        <rs-grid-item-label *ngIf="!isCustom; else customTips">{{
          'PAY_NOW.Other' | translate
        }}</rs-grid-item-label>
      </rs-grid-item>
    </div>
  </div>
</section>

<ng-template #customTips>
  <div class="rs-tips-selector__custom">
    <input
      class="rs-input"
      currencyMask
      [options]="dollarsCurrencyOptions"
      [formControl]="customTipsControl"
      inputmode="numeric"
    />
  </div>
</ng-template>
