import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import {
  Modifier,
  ModifierOption,
  SelectedModifier,
} from 'src/app/_shared/_interfaces/item.model';

@Component({
  selector: 'rs-bundle-item-modifiers',
  templateUrl: './bundle-item-modifiers.component.html',
  styleUrls: [
    '../_styles/item-details.shared.scss',
    './bundle-item-modifiers.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundleItemModifiersComponent implements OnInit {
  @Input() modifiersControl: FormControl = new FormControl([]);
  @Input() sizeId: string = '';
  @Input() metadata: any = null;
  @Input() modifiers: Modifier[] = [];
  @Input() disableOrdering = false;

  @Output() modifierSelected: EventEmitter<ModifierOption> = new EventEmitter();
  @Output() modifiersSelected: EventEmitter<ModifierOption[]> =
    new EventEmitter();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.setDefaultSelectedModifiers();
  }

  public reactOnModifierSelected(
    option: ModifierOption,
    modifier: Modifier
  ): void {
    this.modifierSelected.emit(option);

    const selectedModifiers: SelectedModifier[] =
      this.modifiersControl.value.filter(
        (modifierValue: SelectedModifier) =>
          modifierValue.modifierId !== modifier.id
      );

    this.modifiersControl.reset([
      ...selectedModifiers,
      {
        modifierTitle: modifier.title,
        modifierId: modifier.id,
        selectedOptionTitle: option.title,
        selectedOptionId: option.id,
        mandatory: modifier.itemModifier.mandatory,
      },
    ]);

    this.modifiersSelected.next(this.modifiersControl.value);
    this.changeDetectorRef.detectChanges();
  }

  public reactOnModifiersSelected(
    selected: { option: ModifierOption; modifier: Modifier }[]
  ): void {
    const selectedModifiers: SelectedModifier[] = [];
    let notSelectedModifiers: SelectedModifier[] = this.modifiersControl.value;

    selected.forEach(({ option, modifier }) => {
      notSelectedModifiers = this.modifiersControl.value.filter(
        (modifierValue: SelectedModifier) =>
          modifierValue.modifierId !== modifier.id
      );
      selectedModifiers.push({
        modifierTitle: modifier.title,
        modifierId: modifier.id,
        selectedOptionTitle: option.title,
        selectedOptionId: option.id,
        mandatory: modifier.itemModifier.mandatory,
      });
    });

    this.modifiersControl.reset([
      ...notSelectedModifiers,
      ...selectedModifiers,
      // {
      //   modifierTitle: modifier.title,
      //   modifierId: modifier.id,
      //   selectedOptionTitle: option.title,
      //   selectedOptionId: option.id,
      //   mandatory: modifier.itemModifier.mandatory,
      // },
    ]);

    this.modifiersSelected.next(this.modifiersControl.value);
    this.changeDetectorRef.detectChanges();
  }

  public increaseModifierQuantity(
    option: ModifierOption,
    modifier: Modifier
  ): void {
    const currentModifiers = this.modifiersControl.value;

    this.modifiersControl.reset([
      ...currentModifiers,
      {
        modifierTitle: modifier.title,
        modifierId: modifier.id,
        selectedOptionTitle: option.title,
        selectedOptionId: option.id,
      },
    ]);

    this.modifiersSelected.next(this.modifiersControl.value);
  }

  public decreaseModifierQuantity(
    option: ModifierOption,
    modifier: Modifier
  ): void {
    const currentModifiers = this.modifiersControl.value as any[];
    const decreasedIndex = currentModifiers.findIndex(
      item =>
        item.modifierId === modifier.id && item.selectedOptionId === option.id
    );

    if (decreasedIndex < 0) {
      return;
    }

    this.modifiersControl.reset([
      ...currentModifiers.filter((_, index) => index !== decreasedIndex),
    ]);

    this.modifiersSelected.next(this.modifiersControl.value);
  }

  private setDefaultSelectedModifiers(): void {
    const modifiers = this.modifiers;
    if (this.metadata?.modifierSelections?.length && modifiers?.length) {
      for (let i = 0; i < this.metadata?.modifierSelections?.length; i++) {
        const modifierOptionIds: string[] =
          this.metadata?.modifierSelections[i].modifierOptionIds;
        const selectedModifier: Modifier | undefined = modifiers.find(
          ({ id }: Modifier) =>
            this.metadata?.modifierSelections[i].modifierId === id
        );

        if (!selectedModifier || !modifierOptionIds?.length) {
          return;
        }
        const selected: { option: ModifierOption; modifier: Modifier }[] = [];
        modifierOptionIds.forEach((id: string) => {
          const selectedOption: ModifierOption | undefined =
            selectedModifier.options.find(option => option.id === id);
          if (!selectedOption?.itemModifierOption?.available) {
            return;
          }
          selected.push({
            option: selectedOption,
            modifier: selectedModifier,
          });
        });
        this.reactOnModifiersSelected(selected);
      }
    } else if (modifiers) {
      (modifiers as Modifier[]).forEach(modifier => {
        modifier.options.forEach((option: ModifierOption) => {
          if (option.default && option.itemModifierOption.available) {
            this.reactOnModifierSelected(option, modifier);
          }
        });
      });
    }
  }
}
