<div *ngIf="ratings?.length" class="rs-wine-reviews d-flex flex-column">
  <p class="rs-wine-reviews__title fw-bold m-0">
    {{ 'WINE.Critic tasting note' | translate | titlecase }}
  </p>
  <ng-template [ngForOf]="ratings" let-index="index" let-rating ngFor>
    <rs-wine-review
      *ngIf="showAll || (!showAll && index === 0)"
      [rating]="rating"
    >
    </rs-wine-review>
  </ng-template>

  <ng-template [ngIf]="ratings.length > 1">
    <button
      (click)="showAll = !showAll"
      class="rs-wine-reviews__button p-0"
      mat-button
    >
      <span>
        {{
          (showAll ? 'Hide Reviews' : 'View All Reviews')
            | translate
            | uppercase
        }}
      </span>
    </button>
  </ng-template>
</div>
