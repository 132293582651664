export const HEADERS = {
  ACCESS_TOKEN: 'access_token',
};
export const WELCOME_PAGE: string = 'dine-in/welcome';
export const AUTHENTICATION_PAGES_PATHS: string[] = [
  'login',
  'sign-up',
  WELCOME_PAGE,
];

export const PROFILE_PAGES: string[] = ['profile'];

export const SALES_OPTIONS: string[] = [
  'dine-in',
  'delivery',
  'takeout',
  'curbside',
];

export const DEFAULT_LANGUAGE: string = 'en';
export const DEFAULT_SEPARATOR = ', ';
export const SPACE = ' ';
export const SEMICOLON_SIGN = ';';
export const COLON_SIGN = ':';
export const DEFAULT_SIZE = 'SINGLE_PRICE';
