import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emptyOrNoCharactersValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (typeof value !== 'string' || !value.trim()) {
      return { emptyOrNoCharacters: true };
    }

    return null;
  };
}
