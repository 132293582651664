import { NgModule } from '@angular/core';
import { RouterModule, ROUTES } from '@angular/router';

import { Store } from '@ngxs/store';
import { PlatformLocation } from '@angular/common';

import { GetVenueConfig } from './_shared/_ngxs/venue.actions';
import { environment } from 'src/environments/environment';

const EXAMPLES_ROUTE = [
  {
    path: 'example',
    pathMatch: 'full',
    loadComponent: () =>
      import('./_shared/_rs-design/examples/rs-design-example.component').then(
        m => m.RsDesignExampleComponent
      ),
  },
];

function routesFactory(store: Store, platformLocation: PlatformLocation) {
  return [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'classic',
    },
    {
      path: 'entry-point',
      loadChildren: () =>
        import('./crawler-entry-point/entry-point.module').then(
          m => m.EntryPointModule
        ),
    },
    ...(environment.production ? [] : EXAMPLES_ROUTE),
    // {
    //   path: ':venueName',
    //   loadChildren: async () => {
    //     const venueId = platformLocation.pathname.split('/')[1];
    //
    //     // const venueId = location.href.split('/')[3];
    //
    //     await store.dispatch(new GetVenueConfig(venueId)).toPromise();
    //     const template = store.selectSnapshot(VenueState.template);
    //
    //     switch (template) {
    //       case 'asian': {
    //         return import(`./asian/venue.module`).then(m => m.VenueModule);
    //       }
    //       case 'hash': {
    //         return import(`./hash/venue.module`).then(m => m.VenueModule);
    //       }
    //       case 'classic': {
    //         return import(`./classic/venue.module`).then(m => m.VenueModule);
    //       }
    //       default: {
    //         return import(`./venue/venue.module`).then(m => m.VenueModule);
    //       }
    //     }
    //   },
    // },
    {
      path: ':venueName',
      loadChildren: () => {
        const cwName = platformLocation.pathname.split('/')[1];
        store.dispatch(new GetVenueConfig(cwName));
        return import(`./venue/venue.module`).then(m => m.VenueModule);
      },
    },
  ];
}
@NgModule({
  imports: [RouterModule.forRoot([], { initialNavigation: 'enabledBlocking' })],
  providers: [
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
      deps: [Store, PlatformLocation],
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
