import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SharedModule } from '../../_modules/shared.module';
import { RsDialogComponent } from 'src/app/_shared/_rs-design/dialog/dialog.component';
import { AddressFormComponent } from 'src/app/_shared/_components/address-form/address-form.component';

import { SessionState } from 'src/app/_shared/_ngxs/authentication.state';
import { OpenLoginDialog } from 'src/app/_shared/_ngxs/dialog.actions';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';
import {
  DEFAULT_COUNTRY,
  PHONE_DEFAULT_TYPE,
} from 'src/app/_shared/_rs-design/phone-input/phone-input.constants';

@Component({
  selector: 'rs-initial-address-popup',
  standalone: true,
  imports: [SharedModule, RsDialogComponent, AddressFormComponent],
  templateUrl: './initial-address-popup.component.html',
  styleUrls: ['./initial-address-popup.component.scss'],
})
export class InitialAddressPopupComponent {
  public readonly isLoggedIn$: Observable<boolean> = this.store.select(
    SessionState.isLoggedIn
  );

  public formGroup!: FormGroup;
  public addressForm!: FormGroup;
  public phoneFormGroup!: FormGroup;
  public readonly id = TEST_ID;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private address: UserProfileAddress,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.addressForm = this.formBuilder.group({
      country: 'US',
      address1: [this.address?.address1 || '', Validators.required],
      address2: [this.address?.address2 || ''],
      city: [this.address?.city || '', Validators.required],
      state: [this.address?.state || '', Validators.required],
      zipcode: [this.address?.zipcode || '', Validators.required],
      instructions: [this.address?.instructions || ''],
      latitude: [this.address?.latitude || null],
      longitude: [this.address?.longitude || null],
    });

    this.phoneFormGroup = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      countryCode: [DEFAULT_COUNTRY],
      type: [PHONE_DEFAULT_TYPE, [Validators.required]],
    });

    this.formGroup = this.formBuilder.group({
      address: this.addressForm,
      phone: this.phoneFormGroup,
    });
  }

  public goToLogin(): void {
    this.store.dispatch(new OpenLoginDialog(true));
  }
}
