// Note: Action type naming convention is https://www.ngxs.io/concepts/actions

export class StartDigitalStorefrontExpirience {
  static readonly type =
    '[Storefront Welcome Page] StartDigitalStorefrontExpirience';
}

export class GuestRedirect {
  static readonly type = '[Storefront Welcome Page] GuestRedirect';
}
