import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ENDPOINTS } from '../_shared/_constants/endpoints';
import {
  Delivery,
  Quote,
  CreateDeliveryRequest,
  BurqWebhookRequestBody,
  CreateQuoteRequestBody,
} from '../_shared/_interfaces/delivery.model';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  constructor(private http: HttpClient) {}

  public createDelivery(
    requestBody: CreateDeliveryRequest
  ): Observable<Delivery> {
    return this.http.post<Delivery>(
      `${apiUrl}/${ENDPOINTS.delivery.createDelivery}}`,
      requestBody
    );
  }

  public initiateDelivery(deliveryId: string): Observable<void> {
    return this.http.post<void>(
      `${apiUrl}/${ENDPOINTS.delivery.initiateDelivery(deliveryId)}}`,
      {}
    );
  }

  public createDeliveryQuote(
    deliveryQuoteBody: CreateQuoteRequestBody
  ): Observable<Quote> {
    return this.http.post<Quote>(
      `${apiUrl}/${ENDPOINTS.delivery.createDeliveryQuote}`,
      deliveryQuoteBody
    );
  }

  public getDeliveryById(deliveryId: string): Observable<Delivery> {
    return this.http.get<Delivery>(
      `${apiUrl}/${ENDPOINTS.delivery.getDelivery(deliveryId)}`
    );
  }

  public cancelDelivery(deliveryId: string): Observable<void> {
    return this.http.post<void>(
      `${apiUrl}/${ENDPOINTS.delivery.cancelDelivery(deliveryId)}}`,
      {}
    );
  }

  public handleBurqWebhook(
    requestBody: BurqWebhookRequestBody
  ): Observable<void> {
    return this.http.post<void>(
      `${apiUrl}/${ENDPOINTS.delivery.handleBurqWebhook}}`,
      requestBody
    );
  }
}
