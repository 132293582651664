import { Component, Input } from '@angular/core';

import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';

@Component({
  selector: 'rs-input-action-icon',
  styleUrls: ['./action-icon.component.scss'],
  templateUrl: './action-icon.component.html',
})
export class RsInputActionIconComponent {
  @Input() isClearIcon: boolean = false;

  public readonly clearBold = CommonIcons.ClearBold;

  constructor(private readonly svgIconService: SvgIconService) {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons([this.clearBold], NEW_ICONS_DIRECTORY);
  }
}
