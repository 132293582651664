import { OrderItemStatus } from '../_enums/order-item-status.enum';
import { OrderType } from '../_enums/order.enum';

export const OrderItemStatusTitle = {
  [OrderItemStatus.Received]: 'placed',
  [OrderItemStatus.InProgress]: 'in_progress',
  [OrderItemStatus.ReadyForDelivery]: 'ready_for_delivery',
  [OrderItemStatus.ReadyForPickUp]: 'ready_for_pick_up',
  [OrderItemStatus.ReadyForPickup]: 'ready_for_pick_up',
  [OrderItemStatus.OutForDelivery]: 'out_for_delivery',
  [OrderItemStatus.Delivered]: 'delivered',
  [OrderItemStatus.Refunded]: 'refunded',
  [OrderItemStatus.Finished]: 'closed',
  [OrderItemStatus.Deleted]: 'deleted',
  [OrderItemStatus.Canceled]: 'canceled',
};

export const OrderStatusColor: Record<string, string> = {
  [OrderItemStatus.Received]: '#FA6400',
  [OrderItemStatus.InProgress]: '#00A7EF',
  [OrderItemStatus.ReadyForDelivery]: '#0028D7',
  [OrderItemStatus.ReadyForPickUp]: '#0028D7',
  [OrderItemStatus.ReadyForPickup]: '#0028D7',
  [OrderItemStatus.OutForDelivery]: '#9A73BD',
  [OrderItemStatus.Delivered]: '#9A73BD',
  [OrderItemStatus.Finished]: '#9A73BD',
  [OrderItemStatus.Refunded]: '#FB1D09',
  [OrderItemStatus.Deleted]: '#FB1D09',
  [OrderItemStatus.Canceled]: '#FB1D09',
};

export const orderTypesIcons: Record<string, OrderType[]> = {
  local_dining: [OrderType.DineIn],
  shopping_basket: [OrderType.Curbside, OrderType.Takeout],
  directions_bike: [OrderType.Delivery],
};

export const DELIVERY_ORDER_STATUSES_IN_ORDER = [
  OrderItemStatus.Received,
  OrderItemStatus.InProgress,
  OrderItemStatus.ReadyForDelivery,
  OrderItemStatus.OutForDelivery,
  OrderItemStatus.Finished,
];

// for takeout, curbside and dine-in
export const ORDER_STATUSES_IN_ORDER = DELIVERY_ORDER_STATUSES_IN_ORDER.filter(
  status => status !== OrderItemStatus.OutForDelivery
);

export const IN_PROGRESS_ORDER_STATUSES = new Set([
  OrderItemStatus.Received,
  OrderItemStatus.InProgress,
  OrderItemStatus.ReadyForDelivery,
  OrderItemStatus.ReadyForPickUp,
  OrderItemStatus.OutForDelivery,
]);
