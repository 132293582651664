import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { filter, find } from 'lodash';

import { CatalogService } from 'src/app/_services/catalog.service';

import {
  ClearItem,
  GetItemDetails,
  GetItemModifiers,
  GetItemNutritionalValues,
  SetItemData,
} from './item.actions';

import {
  Allergen,
  Diet,
  ItemData,
  ItemDetails,
  Modifier,
  NutritionalAttribute,
} from 'src/app/_shared/_interfaces/item.model';

import { getValidatedItemAllergens } from '../_utils/allergens';
import { copy } from '../_utils/common';

interface ItemStateModel {
  item: ItemDetails | null;
  allergens: Allergen[] | null;
  diets: Diet[] | null;
  calories: NutritionalAttribute | null;
  nutritionalValues?: NutritionalAttribute[] | null;
  modifiers?: Modifier[] | null;
  itemData: ItemData | null;
}

const DEFAULT_ITEM: ItemStateModel = {
  item: null,
  allergens: null,
  diets: null,
  calories: null,
  nutritionalValues: null,
  modifiers: null,
  itemData: null,
};

@State<ItemStateModel>({
  name: 'item',
  defaults: DEFAULT_ITEM,
})
@Injectable()
export class ItemState {
  constructor(private readonly service: CatalogService) {}

  @Selector()
  static item({ item }: ItemStateModel): ItemDetails | null {
    return item;
  }

  @Selector()
  static calories(state: ItemStateModel) {
    return state.calories?.amount;
  }

  @Selector()
  static allergens(state: ItemStateModel): Allergen[] | null {
    return getValidatedItemAllergens(state.allergens || []);
  }

  @Selector()
  static diets(state: ItemStateModel): Diet[] | null {
    // Note them are not working from BE side
    return state.diets;
  }

  @Selector()
  static nutritionalValues(state: ItemStateModel) {
    return state.nutritionalValues;
  }

  @Selector()
  static modifiers(state: ItemStateModel): Modifier[] {
    return copy(state.modifiers || []);
  }

  @Selector()
  static itemData(state: ItemStateModel): ItemData | null {
    return state.itemData;
  }

  @Action(SetItemData)
  setItemData(
    { getState, patchState }: StateContext<ItemStateModel>,
    { itemData }: SetItemData
  ) {
    const previousItemData: ItemData = copy(getState().itemData);

    patchState({
      itemData: {
        ...previousItemData,
        ...itemData,
      },
    });
  }

  @Action(GetItemDetails)
  getItemDetails(
    { patchState }: StateContext<ItemStateModel>,
    { itemId }: GetItemDetails
  ) {
    return this.service.getItemDetails(itemId).pipe(
      tap(item =>
        patchState({
          item,
          allergens: filter(item.tags, ['type', 'allergen']),
          diets: filter(item.tags, ['type', 'diet']),
        })
      )
    );
  }

  @Action(GetItemNutritionalValues)
  getItemNutritionalValues(
    { patchState }: StateContext<ItemStateModel>,
    { itemId }: GetItemNutritionalValues
  ) {
    return this.service.getItemNutritionalValues(itemId).pipe(
      tap(nutritionalValues =>
        patchState({
          nutritionalValues,
          calories: find(nutritionalValues, ['name', 'calories']) || null,
        })
      )
    );
  }

  @Action(GetItemModifiers)
  getItemModifiers(
    { patchState }: StateContext<ItemStateModel>,
    { itemId }: GetItemModifiers
  ) {
    return this.service
      .getItemModifiers(itemId)
      .pipe(tap(modifiers => patchState({ modifiers })));
  }

  @Action(ClearItem)
  clearItem({ getState, patchState }: StateContext<ItemStateModel>) {
    const itemData: ItemData = copy(getState().itemData) as ItemData;

    patchState({
      ...DEFAULT_ITEM,
      itemData: { ...itemData },
    });
  }
}
