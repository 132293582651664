import { PaymentMethods } from '../_enums/payment-methods.enum';
import { PaymentViewDescription } from '../_interfaces/payment.model';

export const DEFAULT_PAYMENT_VIEW_DESCRIPTION: PaymentViewDescription[] = [
  {
    icon: 'assets/icons/credit-card-solid.svg',
    iconHeight: '17',
    iconWidth: '20',
    placeholder: PaymentMethods.card,
  },
  {
    icon: 'assets/img/apple-pay.png',
    iconHeight: '19',
    iconWidth: '45',
    placeholder: PaymentMethods.apple_pay,
  },
  {
    icon: 'assets/img/google-pay.png',
    iconHeight: '25',
    iconWidth: '47',
    placeholder: PaymentMethods.google_pay,
  },
];

export const PAYMENT_VIEW_DESCRIPTION: PaymentViewDescription[] = [
  ...DEFAULT_PAYMENT_VIEW_DESCRIPTION,
  {
    icon: 'assets/img/gift-card.png',
    iconHeight: '21',
    iconWidth: '32',
    placeholder: PaymentMethods.gift_card,
  },
];
