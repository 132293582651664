import { Pipe, PipeTransform } from '@angular/core';

import { TransactionType } from 'src/app/_shared/_enums/transaction.enum';
import { GiftCard } from 'src/app/_shared/_interfaces/gift-card.model';

@Pipe({ name: 'composeGiftCardAmount' })
export class ComposeGiftCardAmountPipe implements PipeTransform {
  public transform(giftCard: GiftCard): number {
    return (giftCard?.transactions || []).reduce(
      (currentBalance, transaction) =>
        transaction.type === TransactionType.Debit
          ? Number(currentBalance) + Number(transaction.amount)
          : Number(currentBalance),
      Number(giftCard.currentBalance)
    );
  }
}
