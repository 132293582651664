<rs-full-height-dialog>
  <ng-container rs-dialog-header>
    <mat-icon
      tabindex="-1"
      mat-dialog-close
      [svgIcon]="backIcon"
      (click)="closePopup()"
    ></mat-icon>

    <p class="m-0">
      {{ 'PAYING_WITH.Add Payment Method' | translate | titlecase }}
    </p>
  </ng-container>

  <div class="d-flex flex-column rs-add-payment-dialog">
    <span class="rs-add-payment-dialog__add-new fw-bold mb-2">
      {{ 'CARD.new_card' | translate }}
    </span>

    <form
      class="rs-add-payment-dialog__card-information-form row mx-auto w-100"
      [formGroup]="newCardGroup"
    >
      <mat-form-field class="rs-v3-input--small w-100" appearance="outline">
        <input
          matInput
          formControlName="cardHolderName"
          placeholder="{{ 'CARD.cardholder_name' | translate }}"
        />
      </mat-form-field>

      <mat-form-field class="rs-v3-input--small w-100" appearance="outline">
        <input
          matInput
          formControlName="cardNumber"
          [textMask]="cardNumberMaskConfig"
          [minLength]="8"
          [maxLength]="23"
          inputmode="numeric"
          placeholder="{{ 'CARD.card_number' | translate }}"
        />
      </mat-form-field>

      <mat-form-field
        class="rs-add-payment-dialog__expiration rs-v3-input--small w-50"
        appearance="outline"
      >
        <input
          matInput
          [textMask]="expirationDateMaskConfig"
          formControlName="expirationDate"
          placeholder="{{ 'CARD.expiration' | translate }}"
        />
      </mat-form-field>

      <mat-form-field
        class="rs-add-payment-dialog__cvv-field rs-v3-input--small w-50"
        appearance="outline"
      >
        <input
          matInput
          formControlName="cvv"
          placeholder="{{ 'CARD.cvv' | translate }}"
        />
      </mat-form-field>
    </form>

    <div class="mt-2 rs-add-payment-dialog__address-details">
      <span class="d-block fw-bold">
        {{ 'CART.Billing_Address' | translate }}
      </span>
      <div *ngIf="addresses$ | async as addresses">
        <span class="d-block">
          {{ 'CART.Select from existing addresses' | translate }}
        </span>
        <div
          class="d-flex flex-column rs-add-payment-dialog__user-addresses-wraper mt-2"
        >
          <rs-address-item
            *ngFor="let addressItem of addresses; let i = index"
            [address]="addressItem"
            [isAddressSelected]="addressItem === address"
            [activeCard]="selectedCardIndex === i"
            (addressSelected)="address = addressItem"
            (click)="onSelectCard(i)"
          ></rs-address-item>
        </div>
      </div>

      <div class="rs-address-form-wrapper mt-3 mb-3">
        <span class="rs-address-form-wrapper__label d-block text-center">
          {{
            ((addresses$ | async)?.length
              ? 'CART.OR ADD A NEW BILLING ADDRESS'
              : 'CART.ADD A NEW BILLING ADDRESS'
            ) | translate
          }}
        </span>
        <rs-address-form
          class="rs-add-payment-dialog__address-form"
          [hideCountry]="true"
          [hideInstructions]="true"
          [showLocationIcon]="true"
          [hideNickName]="true"
          [address1Placeholder]="'CART.enter_address'"
          [address2Placeholder]="'CARD.Apt Suite Floor'"
          [rsAddressForm]="addressForm"
          (click)="clearSelectedAddress()"
        ></rs-address-form>
      </div>
    </div>
  </div>

  <ng-container rs-dialog-actions-direction="row">
    <button
      [disabled]="!newCardGroup.valid || !(addressForm.valid || address)"
      rs-style="bold"
      class="w-100"
      (click)="createCard()"
    >
      <span>{{ 'CARD.add_card' | translate | uppercase }}</span>
    </button>
  </ng-container>
</rs-full-height-dialog>
