import moment from 'moment';

import { TimePeriod } from '../_interfaces/menu.model';
import { ScheduleRange } from 'src/app/_shared/_interfaces/date-settings';
import {
  OperatingHours,
  ServiceOpeningHours,
} from '../_models/common.interface';

export const fromDayNameToDayNumber = new Map<string, number>([
  ['MONDAY', 1],
  ['TUESDAY', 2],
  ['WEDNESDAY', 3],
  ['THURSDAY', 4],
  ['FRIDAY', 5],
  ['SATURDAY', 6],
  ['SUNDAY', 0],
]);

export const getLastDayOfMonth = (month: string, year: string): number => {
  return moment(moment(`20${year}-${month}`).endOf('month').format()).date();
};

export const getDateForRequestFromString = (expiration: string): string => {
  const splittedExpiration: string[] = expiration.split('/');

  return `20${splittedExpiration[1]}-${
    splittedExpiration[0]
  }-${getLastDayOfMonth(
    splittedExpiration[0],
    splittedExpiration[1]
  )}T00:00:00Z`;
};

export const convertTimeAMStringToPeriod = (time: string) => {
  const selectedNonFormedTime: moment.Moment = moment(time, 'h:mm A');
  const selectedFormedTime: string[] = selectedNonFormedTime
    .format('HH:mm')
    .split(':');

  return {
    hours: +selectedFormedTime[0],
    minutes: +selectedFormedTime[1],
  };
};

export const setAMPMTimeForMomentDate = (
  date: moment.Moment,
  ampmTime: string
): moment.Moment => {
  const from = moment(ampmTime, ['h:mm A']).format('HH:mm');
  const [hours, minutes] = from.split(':');

  return !isNaN(+hours) && !isNaN(+minutes)
    ? (date || moment()).set({
        hour: +hours,
        minute: +minutes,
      })
    : date || moment();
};

export const convertDeliveryTimeToPeriod = (
  deliveryTime: string
): TimePeriod => {
  const fromToString = deliveryTime.split(' ').join('').split('-');

  return {
    from: convertTimeAMStringToPeriod(fromToString[0]),
    to: convertTimeAMStringToPeriod(fromToString[1]),
  };
};

export const isPeriodIsInAnotherPeriod = (
  period: TimePeriod,
  anotherPeriod: TimePeriod
): boolean => {
  const periodFromAsMoment = moment().set(period.from);
  const periodToAsMoment = moment().set(period.to);
  const anotherPeriodFromAsMoment = moment().set(anotherPeriod.from);
  const anotherPeriodToAsMoment = moment().set(anotherPeriod.to);

  return (
    anotherPeriodFromAsMoment.isSameOrBefore(periodFromAsMoment) &&
    anotherPeriodToAsMoment.isSameOrAfter(periodToAsMoment)
  );
};

export const isNowIsInPeriod = (period: TimePeriod): boolean => {
  const periodFromAsMoment = moment().set(period.from);
  const periodToAsMoment = moment().set(period.to);
  const now = moment();

  return (
    periodFromAsMoment.isSameOrBefore(now) &&
    periodToAsMoment.isSameOrAfter(now)
  );
};

export const SCHEDULE_DEFAULT_DAYS_NUMBER = 10;

export const getScheduleRangeBasedOnVenueOperatingHours = (
  openingHours: OperatingHours[],
  allowScheduledOrders: boolean = false
): ScheduleRange[] => {
  if (!openingHours?.length) {
    return [];
  }

  const days: ScheduleRange[] = [];
  const howMuchDaysShouldBeShown: number = allowScheduledOrders
    ? SCHEDULE_DEFAULT_DAYS_NUMBER
    : 1;

  for (let i = 0; i < howMuchDaysShouldBeShown; i++) {
    const currentDate = moment().add(i, 'day');

    const currentDayHours = openingHours.filter(openingHours => {
      return openingHours.dayOfTheWeek === currentDate.get('day');
    });

    days.push({
      day: currentDate,
      periods: currentDayHours?.map(currentDayHour => ({
        from: currentDayHour.from,
        to: currentDayHour.to,
      }))!,
    });
  }

  return days.filter(day => !!day.periods?.length);
};

export const getScheduleRangeBasedOnPacingSchedule = (
  schedule: ServiceOpeningHours[],
  allowScheduledOrders: boolean = false
): ScheduleRange[] => {
  if (!schedule.length) {
    return [];
  }

  const days: ScheduleRange[] = [];
  const howMuchDaysShouldBeShown: number = allowScheduledOrders
    ? SCHEDULE_DEFAULT_DAYS_NUMBER
    : 1;

  for (let i = 0; i < howMuchDaysShouldBeShown; i++) {
    const currentDate = moment().add(i, 'day');

    const currentDayInServiceHours = schedule.find(
      ({ daysOfWeek }) =>
        !!daysOfWeek?.find(
          dayOfWeek =>
            fromDayNameToDayNumber.get(dayOfWeek) === currentDate.get('day')
        )
    );

    days.push({
      day: currentDate,
      periods: currentDayInServiceHours?.periods!,
    });
  }

  return days.filter(day => !!day.periods?.length);
};

export const secondsToMins = (seconds: number | undefined | null): number =>
  !!seconds ? parseInt(String(seconds / 60)) : 0;
