import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { RsBadgeStatusComponent } from 'src/app/_shared/_rs-design/badge/badge.component';

import { WineRating } from 'src/app/_shared/_interfaces/item.model';
import { WineReviewsSummaryPipe } from 'src/app/_shared/_components/wine-reviews/wine-reviews-summary.pipe';

@Component({
  selector: 'rs-wine-review',
  templateUrl: './wine-review.component.html',
  styleUrls: ['./wine-review.component.scss'],
  standalone: true,
  imports: [CommonModule, RsBadgeStatusComponent, WineReviewsSummaryPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WineReviewComponent {
  @Input() rating!: WineRating;
}
