export enum PasswordCheck {
  MinCharacters,
  MinCharacter,
  MinLowerCaseCharacter,
  MinUpperCaseCharacter,
  MinNumber,
  MinSymbol,
}

export enum PasswordCheckCount {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
}

export enum PasswordStrengthLabel {
  Weak = 'CREATE_PASSWORD.password_strength.weak',
  Moderate = 'CREATE_PASSWORD.password_strength.moderate',
  Strong = 'CREATE_PASSWORD.password_strength.strong',
  VeryStrong = 'CREATE_PASSWORD.password_strength.very_strong',
}

export enum PasswordStrengthValue {
  Weak = 10,
  Moderate = 30,
  Strong = 70,
  VeryStrong = 100,
  None = 0,
}

export enum PasswordStrengthColor {
  Weak = 'rs-weak',
  Moderate = 'rs-moderate',
  Strong = 'rs-strong',
  VeryStrong = 'rs-very-strong',
}
