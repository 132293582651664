import { DeliveryStatuses } from '../_enums/delivery-status.enum';
import { DeliveryMethod } from '../_enums/order.enum';

export const DELIVERY_STEP_LABEL = {
  [DeliveryStatuses.Received]: 'ORDER.DELIVERY_STEP.received',
  [DeliveryStatuses.InProgress]: 'ORDER.DELIVERY_STEP.in_progress',
  [DeliveryStatuses.DriverOnTheWay]: 'ORDER.DELIVERY_STEP.driver_on_the_way',
  [DeliveryStatuses.ReadyForPickup]: 'ORDER.DELIVERY_STEP.ready_for_pickup',
  [DeliveryStatuses.DeliveryInProgress]:
    'ORDER.DELIVERY_STEP.delivery_in_progress',
  [DeliveryStatuses.Delivered]: 'ORDER.DELIVERY_STEP.delivered',
  [DeliveryStatuses.Canceled]: 'ORDER.DELIVERY_STEP.canceled',
};

export const DEFAULT_DELIVERY_METHOD = DeliveryMethod.Delivery;
