import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import {
  defaultCurrencyCode,
  defaultCurrencyDisplayType,
  defaultCurrencyRounding,
} from '../_constants/currency.constant';

@Pipe({ name: 'cents2Dollars' })
export class CentsToDollarsPipe implements PipeTransform {
  transform(value: number | null | undefined): number {
    if (!value || isNaN(value)) {
      return 0;
    } else {
      return value / 100;
    }
  }
}

@Pipe({ name: 'cents2DollarsView' })
export class CentsToDollarsViewPipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(
    value: number | null | undefined,
    currencyCode: string = defaultCurrencyCode,
    display: string = defaultCurrencyDisplayType,
    rounding: string = defaultCurrencyRounding
  ): string | null {
    if (!value || isNaN(value)) {
      return '$0.00';
    } else {
      const dollarsAmount = value / 100;
      return this.currencyPipe.transform(
        dollarsAmount,
        currencyCode,
        display,
        rounding
      );
    }
  }
}
