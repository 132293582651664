<div *ngIf="items?.length">
  <p class="d-flex align-items-center fw-bold m-0 pb-3">
    {{ title }}
  </p>

  <div class="rs-suggestion-items-list__list d-flex">
    <rs-food-suggested-item
      *ngFor="let item of items"
      [item]="item"
      class="rs-suggestion-items-list__item"
    >
    </rs-food-suggested-item>
  </div>
</div>
