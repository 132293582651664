import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { EasternArabicNumeralsPipe } from './eastern-arabic-numerals.pipe';
@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService,
    private readonly easternArabicNumeralsPipe: EasternArabicNumeralsPipe
  ) {}

  transform(value: any, pattern: string = 'mediumDate'): string {
    const currentLang = this.translateService.currentLang;
    const datePipe: DatePipe = new DatePipe(currentLang);

    const formattedDate =
      datePipe
        .transform(value, pattern)
        ?.replaceAll(' ,', ',')
        .replaceAll(' .', '.') || '';

    return currentLang === 'ar' && formattedDate
      ? this.easternArabicNumeralsPipe.transform(formattedDate)
      : formattedDate;
  }
}
