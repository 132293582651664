<rs-dialog rs-dialog-style="default" rs-show-close-button="true">
  <ng-container rs-dialog-header>
    <span>{{ 'DELIVERY_METHOD.Enter your address' | translate }}</span>
  </ng-container>
  <div class="rs-initial-address" [dir]="'languageDirection' | translate">
    <div class="rs-initial-address__form">
      <rs-address-form
        [id]="id.onlineOrdering.addressForm"
        [focusOnInput]="true"
        [rsAddressForm]="addressForm"
        [hideInstructions]="true"
        [hidePhoneNumber]="true"
        [instructionsPlaceholder]="'PROFILE.payment.more_instructions_delivery'"
        [address1Placeholder]="'PROFILE.payment.Address'"
        [address2Placeholder]="'PROFILE.payment.Address2'"
      ></rs-address-form>
    </div>
  </div>

  <div
    *ngIf="addressForm.controls['address1'].value"
    class="d-flex w-100"
    [ngClass]="
      ('languageDirection' | translate) === 'rtl'
        ? 'justify-content-start'
        : 'justify-content-end'
    "
  >
    <button
      rs-style="bold"
      [disabled]="addressForm.invalid"
      [mat-dialog-close]="formGroup.value"
    >
      <span>{{ 'DELIVERY_METHOD.continue' | translate }}</span>
    </button>
  </div>

  <ng-container
    rs-dialog-actions-direction="column"
    *ngIf="!(isLoggedIn$ | async)"
  >
    <div
      class="d-flex w-100 rs-initial-address__button-wrapper"
      [ngClass]="
        ('languageDirection' | translate) === 'rtl'
          ? 'justify-content-start'
          : 'justify-content-end'
      "
    >
      <span class="d-flex align-items-center">
        {{ 'DELIVERY_METHOD.or' | translate }}
      </span>
      <button rs-style="text" (click)="goToLogin()">
        <span>{{ 'DELIVERY_METHOD.Sign in' | translate }}</span>
      </button>
    </div>
  </ng-container>
</rs-dialog>
