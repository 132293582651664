import { NgModule } from '@angular/core';
import {
  DietaryRestrictionIconPipe,
  ExistsAllergenType,
  GetAllergenLabelPipe,
  GetAllergensFreePipe,
  GetAllergensTracePipe,
  GetAllergensContainsPipe,
  GetNotSelectedAllergensPipe,
  GetSpicyLevelsPipe,
  IncludesAllergenPipe,
  IncludesOppositeByMeaningAllergenPipe,
  IsAllergenFreePipe,
  SpicyLevelIconPipe,
  GetAllergenWithoutIcons,
  GetAllergenWithIcons,
} from '../_pipes/allergen.pipe';

const pipes = [
  DietaryRestrictionIconPipe,
  IsAllergenFreePipe,
  SpicyLevelIconPipe,
  GetAllergensTracePipe,
  GetAllergensContainsPipe,
  GetAllergensFreePipe,
  GetSpicyLevelsPipe,
  GetNotSelectedAllergensPipe,
  IncludesAllergenPipe,
  IncludesOppositeByMeaningAllergenPipe,
  ExistsAllergenType,
  GetAllergenLabelPipe,
  GetAllergenWithoutIcons,
  GetAllergenWithIcons,
];

@NgModule({
  imports: [...pipes],
  exports: [...pipes],
})
export class RsAllergenPipesModule {}
