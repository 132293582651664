import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { CountryFlagComponent } from 'src/app/_shared/_components/country/country-flag.component';
import { ItemAllergensComponent } from 'src/app/_shared/_components/allergens-dietary-restrictions/item-allergens/item-allergens/item-allergens.component';

import { ItemData } from 'src/app/_shared/_interfaces/item.model';
import { WineReviewsSummaryPipe } from 'src/app/_shared/_components/wine-reviews/wine-reviews-summary.pipe';
import { GetCountryNameByCode3Pipe } from 'src/app/_shared/_components/country/get-country-code.pipe';

@Component({
  selector: 'rs-item-description',
  templateUrl: './rs-item-description.component.html',
  styleUrls: ['./rs-item-description.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    WineReviewsSummaryPipe,
    CountryFlagComponent,
    GetCountryNameByCode3Pipe,
    ItemAllergensComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsItemDescriptionComponent {
  @Input() itemData: ItemData | null = null;
  @Input() price: string | undefined = undefined;
  @Input() calories: number | undefined = undefined;
  @Input() itemHasAllergens: boolean | null = false;
}
