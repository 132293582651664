import { Component } from '@angular/core';

import { SharedModule } from '../../_modules/shared.module';
import { RsDialogComponent } from '../../_rs-design/dialog/dialog.component';
import { RsFullHeightDialogComponent } from '../../_rs-design/dialog/full-height-dialog.component';

import version from 'src/utils/version.json';

import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';
import { NotificationService } from '../../_services/notification.service';

interface Version {
  hash: number;
  author: string;
  relative: string;
  iso: string;
  message: string;
  branch: string;
}

@Component({
  selector: 'rs-version-dialog',
  standalone: true,
  imports: [SharedModule, RsFullHeightDialogComponent, RsDialogComponent],
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent {
  public readonly version: Version = version as unknown as Version;
  public readonly bookIcon = CommonIcons.Book;
  public readonly clockIcon = CommonIcons.Clock;
  public readonly starIcon = CommonIcons.Star;

  constructor(
    private readonly svgIconService: SvgIconService,
    private readonly notifierService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.bookIcon, this.clockIcon, this.starIcon],
      NEW_ICONS_DIRECTORY
    );
  }

  public copy(): void {
    navigator.clipboard.writeText(JSON.stringify(this.version, null, 4));

    this.notifierService.showSuccess('Copied');
  }
}
