import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngxs/store';

import { RsAllergenPipesModule } from 'src/app/_shared/_modules/allergen-pipes.module';
import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsChipSuggestionComponent } from 'src/app/_shared/_rs-design/chip/suggestion/chip-suggestion.component';

import { LanguageState } from 'src/app/_shared/_ngxs/language.state';

import { SvgIconService } from 'src/app/_services/svg-icon.service';

import {
  DIGITAL_STOREFRONT_ALLERGEN_ICON_DIRECTORY,
  DIGITAL_STOREFRONT_ALLERGEN_ICON_PREFIX,
} from 'src/app/_shared/_constants/allergens.constants';
import { Allergen } from 'src/app/_shared/_interfaces/item.model';
import {
  getDigitalStoreFrontAllergenIconRealName,
  isAllergenFree,
} from 'src/app/_shared/_utils/allergens';

@Component({
  selector: 'rs-dietary-restrictions-button',
  standalone: true,
  imports: [SharedModule, RsAllergenPipesModule, RsChipSuggestionComponent],
  templateUrl: './dietary-restrictions-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DietaryRestrictionButtonComponent {
  @Input() allergen!: Allergen;

  @Input() selected!: boolean;
  @Input() disabled!: boolean;

  public readonly language$ = this.store.select(LanguageState.language);

  public isAllergenFree!: boolean;

  @Output() toggled: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private readonly svgIconService: SvgIconService,
    private readonly store: Store
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const allergen: Allergen = changes['allergen']?.currentValue || '';

    allergen && this.initializeAllergen(allergen);
  }

  public initializeAllergen(allergen: Allergen): void {
    this.registerAllergenIcon(allergen.value);

    this.isAllergenFree = isAllergenFree(allergen.value);
  }

  public toggle(): void {
    this.toggled.emit(!this.selected);
  }

  private registerAllergenIcon(allergen: string): void {
    const iconName: string = getDigitalStoreFrontAllergenIconRealName(allergen);
    iconName &&
      this.svgIconService.registerSvgIcons(
        [iconName],
        DIGITAL_STOREFRONT_ALLERGEN_ICON_DIRECTORY,
        DIGITAL_STOREFRONT_ALLERGEN_ICON_PREFIX
      );
  }
}
