import {
  Directive,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

type ScrollOrientation = 'horizontal' | 'vertical';

@Directive({
  selector: '[scrollIntoView]',
})
export class ScrollIntoViewDirective {
  @Input() className = 'active';
  @Input() scrollOrientation: ScrollOrientation = 'horizontal';

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) public platformId: Object
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const targetEl = mutation.target as HTMLElement;
          if (targetEl.classList.contains(this.className)) {
            const containerEl = this.el.nativeElement as HTMLElement;
            const activeEl = targetEl as HTMLElement;

            const containerRect = containerEl?.getBoundingClientRect();
            const activeRect = activeEl?.getBoundingClientRect();
            if (this.scrollOrientation === 'horizontal') {
              if (
                activeRect.right > containerRect.right ||
                activeRect.left < containerRect.left
              ) {
                const newScrollLeft =
                  activeEl.offsetLeft -
                  (containerRect.width - activeEl.offsetWidth) / 2;
                containerEl.scrollTo({
                  left: newScrollLeft,
                  behavior: 'smooth',
                });
              }
            } else {
              if (
                activeRect.bottom > containerRect.bottom ||
                activeRect.top < containerRect.top
              ) {
                const newScrollTop =
                  activeEl.offsetTop -
                  (containerRect.height - activeEl.offsetHeight) / 2;
                containerEl.scrollTo({
                  top: newScrollTop,
                  behavior: 'smooth',
                });
              }
            }
          }
        }
      });
    });

    observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
    });
  }
}
