<div
  *ngIf="_item"
  [dir]="'languageDirection' | translate"
  class="rs-food-item-suggested rs-food-item-suggested--with-image d-flex"
>
  <div class="rs-food-item-suggested__description">
    <div
      [ngClass]="{
        'rs-food-item-suggested__item-name-wrapper-with-favorite-button':
          showFavoriteButton && !itemImage
      }"
      [style.marginLeft]="
        ('languageDirection' | translate) === 'rtl'
          ? titleMargin + 'px'
          : 'unset'
      "
      [style.marginRight]="
        ('languageDirection' | translate) === 'rtl'
          ? 'unset'
          : titleMargin + 'px'
      "
      class="rs-food-item-suggested__item-name-wrapper d-flex flex-wrap"
    >
      <div
        class="rs-food-item-suggested__item-name rs-two-lines-text-ellipsis flex-1"
      >
        {{ itemName }}
      </div>

      <div
        *ngIf="showCalories"
        class="rs-food-item-suggested__item-calories align-self-end"
      >
        <!-- TODO change to specific value when calories are available -->
        (300 cal)
        <!-- TODO end -->
      </div>
    </div>
    <div
      *ngIf="_item.alcoholicAttributes?.string === 'wine' || _item.description"
      class="rs-food-item-suggested__item-description rs-two-lines-text-ellipsis"
    >
      <ng-container
        *ngIf="
          _item.alcoholicAttributes?.string === 'wine';
          else rsNoAlcoholicDescription
        "
      >
        <ng-container *ngTemplateOutlet="rsAlcoholicDescription"></ng-container>
      </ng-container>
    </div>
    <div
      class="rs-food-item-suggested__footer w-100 d-flex justify-content-between"
    >
      <div class="rs-food-item-suggested__price">
        {{ (
            _item?.prices?.[0]?.price?.amount
            || _item?.price?.amount
            || _item?.price?.value
            || 0
            ) | cents2DollarsView
              | rsEasternArabicNumerals }}
      </div>
      <div
        (click)="$event.stopPropagation()"
        *ngIf="_item.allergens?.length"
        [ngClass]="{
          'rs-food-item-suggested__allergens--right-spacing':
            showRating && !itemImage,
          'rs-food-item-suggested__allergens--mobile': !_item.allergens?.length,
          'rs-food-item-suggested__allergens--desktop':
            !!_item.allergens?.length
        }"
      >
        <rs-item-allergens
          [itemAllergens]="_item.allergens"
          [itemName]="itemName"
          [showOnlyOneLine]="true"
          [small20pxIcons]="true"
        ></rs-item-allergens>
      </div>
    </div>
  </div>
  <div aria-hidden="true" class="rs-food-item-suggested__image-wrapper">
    <ng-container *ngTemplateOutlet="rsImage"></ng-container>
  </div>
  <div
    [ngClass]="{
      'justify-content-between': showFavoriteButton,
      'justify-content-end': !showFavoriteButton,
      'rs-food-item-suggested__actions-on-image--small': itemImage
    }"
    class="rs-food-item-suggested__actions-on-image d-flex flex-column align-items-end"
  >
    <div
      (click)="goToItem()"
      class="rs-food-item-suggested__icon-wrapper rs-food-item-suggested__icon-wrapper--on-image d-flex align-items-center justify-content-center cursor-pointer"
    >
      <mat-icon
        [svgIcon]="addIcon"
        class="rs-food-item-suggested__icon"
      ></mat-icon>
    </div>
    <div
      [ngClass]="{
        'rs-food-item-suggested__rating--grey-background': !itemImage,
        'rs-food-item-suggested__rating--white-background': !!itemImage
      }"
      class="rs-food-item-suggested__rating d-flex align-items-center justify-content-center"
    >
      <mat-icon
        [svgIcon]="starIcon"
        class="rs-food-item-suggested__icon"
      ></mat-icon>
      <span class="rs-food-item-suggested__rating-value">
        <!-- TODO change to specific value when rating is available -->
        5.0
        <!-- TODO end -->
      </span>
    </div>
  </div>
</div>

<ng-template #rsNoAlcoholicDescription>
  {{ _item.description }}
</ng-template>

<ng-template #rsAlcoholicDescription>
  <div class="rs-food-item-suggested__title">
    <div
      class="rs-food-item-suggested__title-line rs-food-item-suggested__title-line--colum"
    >
      <div class="m-0 rs-food-item-suggested__item-description-text">
        <span>
          {{ _item.alcoholicAttributes?.wineAttributes?.origin?.chateauDomain }}
        </span>
        <rs-country-flag
          *ngIf="_item?.origin"
          [code3]="_item?.origin?.country || ''"
        ></rs-country-flag>
      </div>
      <div class="m-0 rs-food-item-suggested__item-description-text">
        {{ _item.alcoholicAttributes?.wineAttributes?.origin?.winery }},
        {{ _item.origin?.country || '' | getCountryNameByCode3 }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rsImage>
  <ng-container *ngIf="itemImage; else rsDefaultImage">
    <img
      [src]="itemImage"
      [alt]="_item?.name"
      class="rs-food-item-suggested__image"
    />
  </ng-container>

  <ng-template #rsDefaultImage>
    <div class="rs-food-item-suggested__image">
      <rs-default-food-icon
        [category]=""
        [size]="DefaultFoodIconSize.Medium"
      ></rs-default-food-icon>
    </div>
  </ng-template>
</ng-template>
