<ng-container *ngIf="orderType$ | async as orderType">
  <ng-container *ngFor="let isDelivery of [orderType === deliveryMethod]">
    <ng-container *ngFor="let isLoggedIn of [isLoggedIn$ | async]">
      <div class="rs-pay-now" [dir]="'languageDirection' | translate">
        <ng-container *ngIf="items$ | async; let items">
          <ng-template
            [ngIf]="orderType === OrderType.DineIn && (isOpenTab$ | async)"
          >
            <div class="rs-pay-now__mobile-contact-info">
              <p class="rs-marketing-checkout__title mb-1">
                {{ 'PAY_NOW.Authorise Payment' | translate | uppercase }}
              </p>

              <span>
                {{ 'PAY_NOW.Your payment will be authorized only' | translate }}
              </span>
            </div>
          </ng-template>

          <div class="rs-pay-now__mobile-contact-info">
            <rs-contact-info></rs-contact-info>
          </div>
          <div
            class="rs-pay-now__header d-flex align-items-center justify-content-between"
          >
            <p class="fw-bold">
              {{ 'PAY_NOW.PAY_YOUR_SHARE' | translate }}
            </p>
            <p class="fw-bold" [ngPlural]="items.length">
              <ng-template ngPluralCase="=0">
                {{ 'PAY_NOW.NO_ITEMS' | translate }}
              </ng-template>
              <ng-template ngPluralCase="=1">
                1 {{ 'PAY_NOW.ITEM' | translate }}
              </ng-template>
              <ng-template ngPluralCase="other">
                {{ badgeNumber$ | async | rsCartItemsAmount }}
              </ng-template>
            </p>
          </div>
          <section>
            <rs-marketing-checkout-check
              [isGiftCard]="isGiftCard"
              [isSearchingDriver]="isSearchingDriver"
            ></rs-marketing-checkout-check>
          </section>
        </ng-container>

        <div class="p-2">
          <rs-order-information
            *ngIf="showDelivery"
            [orderMethod]="orderType | rsDeliveryTypeToDeliveryMethod"
            [isCheckout]="true"
          ></rs-order-information>
        </div>

        <section class="rs-pay-now__with-offset">
          <p class="fw-bold mb-2">{{ 'PAY_NOW.PAY_WITH' | translate }}</p>
          <rs-pay-with
            (paymentMethodChanged)="reactOnPaymentMethodChanged($event)"
          ></rs-pay-with>
          <ng-container *ngIf="checkCalculations$ | async as checkCalculations">
            <ng-container *ngIf="venue$ | async as venue">
              <div
                class="rs-pay-now__min-amount-error"
                *ngIf="
                  !isGiftCard &&
                  isDelivery &&
                  checkCalculations.grossTotal <
                    (venue?.orderMinimum?.amount || 0)
                "
              >
                <rs-error-info [showClose]="false">
                  <div class="rs-pay-now__min-amount-error-content">
                    {{
                      'PAY_NOW.Delivery Orders have a minimum charge of'
                        | translate
                    }}
                    <b>{{
                      venue?.orderMinimum?.amount
                        | cents2Dollars
                        | currency: 'USD':'symbol':'1.2-2'
                    }}</b>
                  </div>
                </rs-error-info>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="
              (paymentMethod$ | async) === paymentMethods.card &&
              isCardExpired === true
            "
          >
            <div class="rs-pay-now__min-amount-error">
              <rs-error-info [showClose]="false">
                <div class="rs-pay-now__min-amount-error-content">
                  {{
                    'PAY_NOW.The selected card has expired, please use a different one'
                      | translate
                  }}
                </div>
              </rs-error-info>
            </div>
          </ng-container>
          <ng-container
            *ngIf="!isGiftCard && isDelivery && !isLoggedIn && !phoneIsEntered"
          >
            <div class="rs-pay-now__min-amount-error">
              <rs-error-info [showClose]="false">
                <div class="rs-pay-now__min-amount-error-content">
                  {{
                    'PAY_NOW.Phone number is required for delivery orders'
                      | translate
                  }}
                </div>
              </rs-error-info>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              (currentPaymentMethod === 'card' ||
                currentPaymentMethod === 'gift_card') &&
              !isLoggedIn &&
              !(isShadowUser$ | async) &&
              !(guestUserData$ | async)?.isFormDataValid
            "
          >
            <div class="rs-pay-now__min-amount-error">
              <rs-error-info [showClose]="false">
                <div class="rs-pay-now__min-amount-error-content">
                  {{ 'PAY_NOW.enter_contact_info' | translate }}
                </div>
              </rs-error-info>
            </div>
          </ng-container>

          <div
            class="d-flex justify-content-center mb-5"
            [ngClass]="{
              'd-none':
                (paymentMethod$ | async) == paymentMethods.card
                  ? isLoggedIn
                    ? !(selectedPaymentCard$ | async)?.id
                    : !(guestUserData$ | async)?.isCardValid
                  : false
            }"
          >
            <ng-container
              *ngFor="
                let payDisabled of [
                  (!isGiftCard &&
                    isDelivery &&
                    !(isAddressCoveredByDelivery$ | async)) ||
                    !(isOrderReadyForPayment$ | async) ||
                    (!isGiftCard &&
                      ((isDelivery &&
                        ((checkCalculations$ | async)!.grossTotal <
                          (venue$ | async)?.orderMinimum?.amount ||
                          !(isLoggedIn || phoneIsEntered))) ||
                        !(
                          isLoggedIn ||
                          (isShadowUser$ | async) ||
                          (guestUserData$ | async)?.isFormDataValid
                        ))) ||
                    (!isGiftCard &&
                      (checkCalculations$ | async)!.grossTotal <= 0)
                ]
              "
            >
              <button
                rs-style="bold"
                class="rs-pay-now__place-order"
                [disabled]="
                  payDisabled ||
                  ((paymentMethod$ | async) === paymentMethods.card &&
                    isCardExpired === true)
                "
                (click)="payClicked.emit()"
                *ngIf="
                  currentPaymentMethod === 'card' ||
                  currentPaymentMethod === 'gift_card'
                "
              >
                <span>{{ 'PAY_NOW.PAY' | translate | uppercase }}</span>
              </button>
              <ng-container
                *ngIf="{
                  total: checkCalculations$ | async,
                  tips: tips$ | async
                } as result"
              >
                <button
                  *ngIf="currentPaymentMethod === 'apple-pay' && payDisabled"
                  rs-style="bold"
                  disabled
                  class="rs-apple-pay__placeholder-button"
                >
                  <mat-icon [svgIcon]="appleIcon"></mat-icon>
                </button>

                <button
                  *ngIf="currentPaymentMethod === 'google-pay' && payDisabled"
                  rs-style="bold"
                  disabled
                  class="rs-google-pay__placeholder-button"
                >
                  <mat-icon [svgIcon]="googleIcon"></mat-icon>
                </button>

                <rs-apple-pay
                  *ngIf="currentPaymentMethod === 'apple-pay' && !payDisabled"
                  [venueName]="venueName"
                  (payClicked)="payClicked.emit()"
                  [totalPrice]="
                    (isGiftCard
                      ? (price$ | async | cents2Dollars)
                      : ((result.total?.netTotal || 0) +
                          (result.tips?.absolute || 0) | cents2Dollars)
                    )?.toString() || '0.00'
                  "
                ></rs-apple-pay>

                <rs-google-pay
                  *ngIf="currentPaymentMethod === 'google-pay' && !payDisabled"
                  [venueName]="venueName"
                  (payClicked)="payClicked.emit()"
                  [totalPrice]="
                    (isGiftCard
                      ? (price$ | async | cents2Dollars)
                      : ((result.total?.netTotal || 0) +
                          (result.tips?.absolute || 0) | cents2Dollars)
                    )?.toString() || '0.00'
                  "
                ></rs-google-pay>
              </ng-container>
            </ng-container>
          </div>
        </section>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
