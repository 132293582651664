<section
  *ngIf="addressForm"
  class="rs-payment-address-form"
  [formGroup]="addressForm!"
>
  <rs-address-autocomplete
    [id]="id.addressAutocomplete.addressAutocomplete"
    [selectedCountryCode]="'US'"
    placeholder="{{ 'CART.enter_address' | translate }}"
    [showLocationIcon]="false"
    (addressDetails)="reactOnAddressDetailsFound($event)"
    [rsFormControl]="$any(addressForm.controls).address1"
  ></rs-address-autocomplete>
  <rs-input
    rs-input-style="default"
    [hasClearButton]="false"
    placeholder="{{ 'CARD.Apt Suite Floor' | translate }}"
    inputType="text"
    [control]="addressForm.get('address2')"
  ></rs-input>
  <ng-container *ngIf="$any(addressForm.controls).address1.value">
    <div class="rs-half-part">
      <rs-input
        rs-input-style="default"
        [hasClearButton]="false"
        placeholder="{{ 'CARD.city' | translate }}"
        inputType="text"
        [control]="addressForm.get('city')"
        [required]="true"
        [disabled]="true"
      ></rs-input>
      <rs-input
        rs-input-style="default"
        [hasClearButton]="false"
        placeholder="{{ 'CARD.zip_code' | translate }}"
        inputType="text"
        [required]="true"
        [control]="addressForm.get('zipcode')"
        [disabled]="true"
      ></rs-input>
    </div>
    <rs-state-select
      [countryCode]="$any(addressForm.controls).country.value"
      [rsFormControl]="$any(addressForm.controls).state"
      [shouldDisable]="true"
      [markAsTouched]="false"
      [placeholder]="'CARD.state' | translate"
    ></rs-state-select>
  </ng-container>
</section>
