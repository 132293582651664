<div class="rs-schedule-order-bottom-sheet__title">
  {{ (isCheckout ? 'PAY_NOW.CHECKOUT' : 'SCHEDULE.schedule') | translate }}
</div>

<div class="rs-schedule-order-bottom-sheet__body">
  <rs-full-order-information
    [isCheckout]="isCheckout"
    (update)="partialOrderData = $event"
  ></rs-full-order-information>
</div>

<div class="w-100 position-sticky rs-schedule-order-bottom-sheet__continue">
  <button rs-style="bold" (click)="finishUpdate()" class="w-100">
    <span>{{ 'DELIVERY_METHOD.continue' | translate }}</span>
  </button>
</div>
