<section class="rs-add-new-card__wrapper">
  <header class="rs-dialog-header-with-title">
    <h3>
      {{ 'CARD.add_card' | translate }}
    </h3>

    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </header>

  <div class="rs-add-new-card" [formGroup]="newCardForm">
    <mat-form-field appearance="outline" class="rs-v3-input--small w-100">
      <input
        formControlName="cardholderName"
        matInput
        placeholder="{{ 'CARD.cardholder_name' | translate }}"
      />
      <mat-error
        *ngIf="newCardForm.get('cardholderName')!.hasError('required')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="rs-v3-input--small w-100">
      <input
        formControlName="cardNumber"
        autocomplete="nope"
        matInput
        [minLength]="8"
        [maxLength]="23"
        [textMask]="cardNumberMaskConfig"
        inputmode="numeric"
        placeholder="{{ 'CARD.card_number' | translate }}"
      />
      <mat-error
        *ngIf="newCardForm.get('cardNumber')!.hasError('required')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.REQUIRED' | translate }}
      </mat-error>
      <mat-error
        *ngIf="newCardForm.get('cardNumber')!.hasError('minLength')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.card_length' | translate }}
      </mat-error>
      <mat-error
        *ngIf="newCardForm.get('cardNumber')!.hasError('maxLength')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.card_length' | translate }}
      </mat-error>
      <mat-error
        *ngIf="newCardForm.get('cardNumber')!.hasError('invalid')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.card_number' | translate }}
      </mat-error>
      <mat-error
        *ngIf="newCardForm.get('cardNumber')!.hasError('wrongType')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.wrong_card_type' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="rs-v3-input--small w-100">
      <input
        mask="000"
        formControlName="cvv"
        matInput
        placeholder="{{ 'CARD.cvv' | translate }}"
        [textMask]="cvvNumberMaskConfig"
        autocomplete="nope"
        inputmode="numeric"
      />
      <mat-error
        *ngIf="newCardForm.get('cvv')!.hasError('required')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.REQUIRED' | translate }}
      </mat-error>
      <mat-error
        *ngIf="newCardForm.get('cvv')!.hasError('minLength')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.length' | translate }}
      </mat-error>
      <mat-error
        *ngIf="newCardForm.get('cvv')!.hasError('maxLength')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.length' | translate }}
      </mat-error>
      <mat-error
        *ngIf="newCardForm.get('cvv')!.hasError('pattern')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.incorrect_value' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="rs-v3-input--small w-100">
      <input
        formControlName="cardExpirationDate"
        autocomplete="nope"
        [placeholder]="'PROFILE.payment.expiration_placeholder' | translate"
        matInput
        [textMask]="expirationDateMaskConfig"
        inputmode="numeric"
      />
      <mat-error
        *ngIf="newCardForm.get('cardExpirationDate')!.hasError('required')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.REQUIRED' | translate }}
      </mat-error>
      <mat-error
        *ngIf="newCardForm.get('cardExpirationDate')!.hasError('expired')"
        class="rs-new-payment__error"
      >
        {{ 'VALIDATION.expiration' | translate }}
      </mat-error>
    </mat-form-field>

    <div
      class="rs-add-new-card__billing-address"
      [class.rs-add-new-card__billing-address--with-margin-bottom]="
        !data.deliveryAddress
      "
    >
      <h4 class="rs-add-new-card__title">
        {{ 'CARD.billing_address' | translate }}
      </h4>

      <button
        *ngIf="isLoggedIn$ | async"
        class="rs-change-button"
        rs-style="bold"
        (click)="openChangeAddressPopup()"
      >
        <span>{{ 'DELIVERY_METHOD.change' | translate }}</span>
      </button>
    </div>

    <mat-checkbox
      *ngIf="data?.deliveryAddress"
      [formControl]="useDeliveryAddressControl"
      class="rs-add-new-card__same-as-delivery"
    >
      {{ 'CARD.same_as_delivery' | translate }}
    </mat-checkbox>
  </div>
  <rs-billing-address
    [addressForm]="newCardForm"
    [isHalfSeparatedInputs]="true"
    [isCountrySelector]="true"
  ></rs-billing-address>
  <button
    [disabled]="newCardForm.invalid"
    rs-style="bold"
    class="m-1 w-100"
    (click)="saveCard()"
  >
    <span>{{ 'save' | translate | uppercase }}</span>
  </button>
</section>
