export class GetAvailableAllergens {
  public static readonly type = '[FirstSitePageOpen] GetAvailableAllergens';

  constructor(public venueId: string) {}
}

export class SaveSelectedDefaultAllergens {
  public static readonly type = '[AllergensState] SaveSelectedDefaultAllergens';

  constructor(public selected: string[]) {}
}

export class SaveSelectedCustomAllergens {
  public static readonly type = '[AllergensState] SaveSelectedCustomAllergens';

  constructor(public selected: string[]) {}
}

// SaveUserAllergens - save default used allergens
export class SaveUserAllergens {
  public static readonly type =
    '[ProfilePage, AllergensState] SaveUserAllergens';

  constructor(public selected: string[]) {}
}

// SaveSelectedAllergens - saves all used allergens
// fire SaveUserAllergens to save default allergens for a user
// and saves custom used allergens in the state
export class SaveSelectedAllergens {
  public static readonly type =
    '[DietaryRestrictionsPopup, FoodMenuEmptyComponent] SaveSelectedAllergens';

  constructor(public selected: string[] = []) {}
}

export class SaveGuestAllergens {
  public static readonly type = '[AllergensState] SaveGuestAllergens';

  constructor(public selected: string[]) {}
}

// if user is guest we set selected to empty
// if user is logged in we reset selected custom and
// set selected default to saved on BE
export class ResetSelectedAllergens {
  public static readonly type =
    '[DietaryRestrictionsPopup] ResetSelectedAllergens';
}
