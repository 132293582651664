<ng-container *ngFor="let isDelivery of [isDelivery$ | async]">
  <ng-container *ngIf="checkCalculations$ | async; let checkCalculations">
    <section
      class="rs-pay-now rs-marketing-checkout-check"
      [dir]="'languageDirection' | translate"
    >
      <div class="rs-marketing-checkout-total-check__items">
        <p class="rs-marketing-checkout__title">
          {{ 'PAY_NOW.Your Order' | translate | uppercase }}
        </p>

        <div
          class="rs-marketing-checkout__block-with-y-borders pt-3"
          [ngClass]="{
            'rs-marketing-checkout-check--no-border-bottom': isGiftCard
          }"
        >
          <div class="rs-marketing-checkout-total-check__row">
            <p>{{ 'PAY_NOW.SUBTOTAL' | translate }}:</p>
            <p *ngIf="isGiftCard" [id]="id.checkout.subtotalAmount">
              {{ price$ | async | cents2DollarsView | rsEasternArabicNumerals }}
            </p>
            <p *ngIf="!isGiftCard">
              {{
                checkCalculations.grossTotal
                  | cents2DollarsView
                  | rsEasternArabicNumerals
              }}
            </p>
          </div>

          <ng-container *ngIf="!isGiftCard">
            <div
              class="rs-marketing-checkout-total-check__row rs-marketing-checkout-total-check__div"
              *ngFor="let entry of checkCalculations.entries"
            >
              <!-- TODO, remove later on when backend handles it -->
              <ng-container
                *ngIf="
                  !(
                    entry.amountType === AmountType.Percentage &&
                    entry.percentage === 0
                  ) &&
                  !(
                    entry.amountType === AmountType.Absolute &&
                    entry.grossPrice === 0
                  ) &&
                  !(entry.percentage === 0 && entry.grossPrice === 0) &&
                  entry.name !== 'Service Charge'
                "
              >
                <p>
                  {{ entry.name }}
                  <ng-template
                    [ngIf]="entry.amountType === AmountType.Percentage"
                  >
                    ({{
                      entry.percentage
                        | percent: '1.0-2'
                        | rsEasternArabicNumerals
                    }})
                  </ng-template>
                </p>
                <p>
                  {{
                    entry.grossPrice
                      | cents2DollarsView
                      | rsEasternArabicNumerals
                  }}
                </p>
              </ng-container>
            </div>

            <div
              *ngIf="isSearchingDriver"
              class="rs-marketing-checkout-total-check__row rs-marketing-checkout-total-check__div"
            >
              <p>{{ 'PAY_NOW.DELIVERY_FEES' | translate }}</p>
              <p>{{ 'PAY_NOW.SEARCHING_FOR_DRIVER' | translate }}</p>
            </div>
          </ng-container>

          <div
            class="rs-marketing-checkout-total-check__row"
            *ngIf="!isGiftCard"
          >
            <p class="fw-bold">
              {{ 'PAY_NOW.AMOUNT_DUE' | translate }}
            </p>
            <p class="rs-marketing-checkout-total-check__money">
              {{
                checkCalculations.netTotal
                  | cents2DollarsView
                  | rsEasternArabicNumerals
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="pt-3 pb-4 px-4" *ngIf="!isGiftCard">
        <div class="rs-marketing-checkout-total-check__row pt-2">
          <p class="fw-bold m-0">
            {{
              (isDelivery ? 'PAY_NOW.DRIVER_TIP' : 'PAY_NOW.TIP') | translate
            }}
          </p>
          <p class="fw-bold m-0">
            <span class="rs-marketing-checkout-total-check__row--grey">
              ( {{ (tips?.percentage || 0) + '%' | rsEasternArabicNumerals }})
            </span>
            {{
              tips?.absolute || 0 | cents2DollarsView | rsEasternArabicNumerals
            }}
          </p>
        </div>
        <div
          class="rs-marketing-checkout-total-check__row--info d-flex flex-row align-items-center"
        >
          <mat-icon class="rs-marketing-checkout__icon--info">info</mat-icon>
          <span>
            {{
              (isDelivery ? 'PAY_NOW.DRIVER_TIP_INFO' : 'PAY_NOW.TIP_INFO')
                | translate
                | rsEasternArabicNumerals
            }}
          </span>
        </div>
        <rs-tips-selector></rs-tips-selector>
      </div>

      <div
        class="rs-marketing-checkout__block-with-y-borders rs-marketing-checkout-total-check__row rs-marketing-checkout-total-check__items py-3 mx-2"
      >
        <p class="rs-marketing-checkout__title m-0">
          {{ 'PAY_NOW.TOTAL' | translate }}
        </p>

        <p
          [id]="id.checkout.totalAmount"
          class="rs-marketing-checkout-total-check__money m-0"
        >
          {{
            isGiftCard
              ? (price$ | async | cents2DollarsView | rsEasternArabicNumerals)
              : ((checkCalculations?.netTotal || 0) + (tips?.absolute || 0)
                | cents2DollarsView
                | rsEasternArabicNumerals)
          }}
        </p>
      </div>
    </section>
  </ng-container>
</ng-container>
