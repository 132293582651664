import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsDialogActionsComponent } from './actions/dialog-actions.component';
import { RsDefaultDialogHeaderComponent } from './header/default-dialog-header.component';
import { RsStatusDialogHeaderComponent } from './status-dialog-header/status-dialog-header.component';

import { DialogType, DialogTypeOptions, StatusDialogType } from './dialog-type';

@Component({
  selector: 'rs-dialog',
  standalone: true,
  imports: [
    SharedModule,
    RsDefaultDialogHeaderComponent,
    RsDialogActionsComponent,
    RsStatusDialogHeaderComponent,
  ],
  templateUrl: './dialog.component.html',
})
export class RsDialogComponent {
  @Input('rs-show-close-button') showCloseButton!: string;
  @Input('rs-dialog-style') set dialogStyle(dialogStyle: DialogType | string) {
    this.type = dialogStyle as DialogType;
    this.type !== DialogTypeOptions.Default && (this.status = this.type);
    this.isStatusDialog = dialogStyle !== DialogTypeOptions.Default;
  }
  @Input('rs-dialog-header-with-border') set dialogHeaderBorder(_: any) {
    this.dialogHeaderHasBorder = true;
  }
  @ViewChild('rsDialogActions', { read: ElementRef, static: false })
  dialogActions: ElementRef<any> | undefined;

  public status: StatusDialogType = DialogTypeOptions.Success;
  public type: DialogType = DialogTypeOptions.Default;
  public dialogHeaderHasBorder!: boolean;
  public isStatusDialog!: boolean;
  public noButtons!: boolean;

  ngOnChanges(): void {
    setTimeout(() => this.checkIfButtons());
  }

  private checkIfButtons(): void {
    this.noButtons = !this.dialogActions?.nativeElement.offsetHeight;
  }
}
