import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ReviewSubjectType } from '../_shared/_enums/review.enum';
import { ReviewsResponse } from '../_shared/_interfaces/review.model';

const apiUrl = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class ReviewsService {
  constructor(private readonly http: HttpClient) {}

  public getHomePageReviews(venueId: string): Observable<ReviewsResponse> {
    const params = new HttpParams()
      .set('venueId', venueId)
      .set('pageSize', '5')
      .set('page', '1')
      .set('sortBy', 'date')
      .set('order', 'desc')
      .set('subjectType', ReviewSubjectType.Venue)
      .set('rating', 5);
    return this.http.get<ReviewsResponse>(`${apiUrl}/review/v1/venue`, {
      params,
    });
  }
}
