<div
  [matMenuTriggerFor]="isHoverAvailable ? menu : null"
  #menuTrigger="matMenuTrigger"
  (click)="openPopupIfHoverNotAvailable()"
  (mouseenter)="showMenu(menuTrigger)"
  (mouseleave)="hideMenu(menuTrigger)"
>
  <ng-content select="[rs-element]"></ng-content>
</div>

<mat-menu
  class="rs-tooltip"
  #menu="matMenu"
  [hasBackdrop]="false"
  [xPosition]="xPosition"
  [yPosition]="yPosition"
>
  <div class="rs-tooltip__tooltip">
    <div
      #rsTooltipHeader
      class="rs-tooltip__tooltip-content-header"
      [ngClass]="{
        'rs-tooltip__tooltip-content--on-dialog': !isHoverAvailable
      }"
    >
      <ng-content select="[rs-tooltip-header]"></ng-content>
    </div>
    <div
      #rsTooltip
      class="rs-tooltip__tooltip-content"
      [ngClass]="{
        'rs-tooltip__tooltip-content--on-dialog': !isHoverAvailable
      }"
    >
      <ng-content select="[rs-tooltip]"></ng-content>
    </div>
  </div>
</mat-menu>
