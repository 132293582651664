import { isEqual, omit, uniqWith } from 'lodash';
import { ItemCategory } from '../_enums/item-category.enum';
import { GenericCheck } from '../_interfaces/check-calculation.model';
import {
  GenericOrderResponse,
  OrderSuccessItem,
} from '../_interfaces/order.model';

export const getOrderSuccessItemsFromOrderDetails = (
  orderDetails: GenericOrderResponse,
  checkId: string
): OrderSuccessItem[] => {
  const mappedItems = getMappedOrderItems(orderDetails, checkId);

  return Object.values(mappedItems);
};

function getMappedOrderItems(
  orderDetails: GenericOrderResponse,
  checkId: string
) {
  const orderItemFullPriceById = getOrderItemFullPriceById(
    orderDetails.checks || [],
    checkId
  );
  const itemsForCheck = getOrderItemsForCurrentCheck(orderDetails, checkId);

  return itemsForCheck.reduce((accumulator, item) => {
    let itemMapId = (item.itemId || '') + item.description;
    const bundleItemsDescription: string[] = [];

    if (item.bundleItems?.length) {
      const uniqBundleItems = uniqWith(item.bundleItems, isEqual);

      uniqBundleItems.forEach(uniqBundleItem => {
        const itemsAmount: number = (item.bundleItems || []).filter(
          bundleItem => isEqual(uniqBundleItem, bundleItem)
        ).length;
        itemMapId =
          itemMapId +
          ' ' +
          uniqBundleItem.name +
          (itemsAmount > 1 ? `x${itemsAmount}` : '');

        bundleItemsDescription.push(
          uniqBundleItem.name + (itemsAmount > 1 ? `x${itemsAmount}` : '')
        );
      });
    }

    const getItemResult = {
      name: item.name!,
      photo: orderItemFullPriceById[item.orderItemId!].photo,
      count: 1,
      itemId: item.itemId!,
      category: item.category as ItemCategory,
      netPrice: orderItemFullPriceById[item.orderItemId!].netPrice,
      grossPrice: orderItemFullPriceById[item.orderItemId!].grossPrice,
      description: (item.description || '')
        .split('; ')
        .sort((a, b) => a.localeCompare(b)),
      bundleItems: item.bundleItems || [],
    };

    if (
      accumulator[itemMapId] &&
      isEqual(
        omit(getItemResult, ['count']),
        omit(accumulator[itemMapId], ['count'])
      )
    ) {
      accumulator[itemMapId].count = accumulator[itemMapId].count + 1;
    } else {
      accumulator[itemMapId] = getItemResult;
    }

    return accumulator;
  }, {} as Record<string, OrderSuccessItem>);
}

function getOrderItemsForCurrentCheck(
  orderDetails: GenericOrderResponse,
  checkId: string
) {
  const check = (orderDetails.checks || []).find(
    check => checkId === check.checkId
  );
  const orderItemIdsInCheck = (check?.entries || []).reduce(
    (accumulator, entry) => accumulator.add(entry.orderItemId),
    new Set()
  );

  return (orderDetails.items || []).filter(item =>
    orderItemIdsInCheck.has(item.orderItemId)
  );
}

function getOrderItemFullPriceById(checks: GenericCheck[], checkId: string) {
  const check = checks.find(check => checkId === check.checkId);

  return (check?.entries || []).reduce((accumulator, entry) => {
    if (entry.type !== 'order_item') {
      return accumulator;
    }

    accumulator[entry.orderItemId!] = {
      grossPrice: entry.grossPrice || 0,
      netPrice: entry.netPrice || 0,
      photo: entry.photo || '',
    };

    return accumulator;
  }, {} as Record<string, { grossPrice: number; photo: string; netPrice: number }>);
}
