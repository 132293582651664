import { Pipe, PipeTransform } from '@angular/core';

import { countries } from './countries.constants';

@Pipe({ name: 'getCountryCodeByName', standalone: true })
export class GetCountryCodeByNamePipe implements PipeTransform {
  transform(countryName: string): string {
    if (!countryName) {
      return '';
    }
    return countries.find(({ name }) => name === countryName)?.code || '';
  }
}

@Pipe({ name: 'getCountryCodeByCode3', standalone: true })
export class GetCountryCodeByCode3Pipe implements PipeTransform {
  transform(code3: string): string {
    if (!code3) {
      return '';
    }
    return (
      countries.find(({ code3: countryCode3 }) => countryCode3 === code3)
        ?.code || ''
    );
  }
}

@Pipe({ name: 'getCountryNameByCode3', standalone: true })
export class GetCountryNameByCode3Pipe implements PipeTransform {
  transform(code3: string): string {
    if (!code3) {
      return '';
    }
    return (
      countries.find(({ code3: countryCode3 }) => countryCode3 === code3)
        ?.name ||
      code3 ||
      ''
    );
  }
}

@Pipe({ name: 'getCountryNameByCode', standalone: true })
export class GetCountryNameByCodePipe implements PipeTransform {
  transform(code: string): string {
    if (!code) {
      return '';
    }
    return (
      countries.find(({ code: countryCode }) => countryCode === code)?.name ||
      code ||
      ''
    );
  }
}
