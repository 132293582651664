import { Injectable } from '@angular/core';

@Injectable()
export class CustomStorage implements Storage {
  private data: { [key: string]: string } = {};

  public get length(): number {
    return Object.keys(this.data).length;
  }

  public clear(): void {
    this.data = {};
  }

  public getItem(key: string): string | null {
    return key in this.data ? this.data[key] : null;
  }

  public key(index: number): string | null {
    const keys = Object.keys(this.data);

    return index >= 0 && keys.length < index ? keys[index] : null;
  }

  public removeItem(key: string): void {
    delete this.data[key];
  }

  public setItem(key: string, value: string): void {
    this.data[key] = value;
  }
}
