<ng-template [ngIf]="code3 && code3 | getCountryCodeByCode3">
  <ng-container
    *ngTemplateOutlet="
      rsCountryFlag;
      context: { code: code3 | getCountryCodeByCode3 }
    "
  ></ng-container>
</ng-template>
<ng-template [ngIf]="code">
  <ng-container
    *ngTemplateOutlet="rsCountryFlag; context: { code: code }"
  ></ng-container>
</ng-template>
<ng-template [ngIf]="name && name | getCountryCodeByName">
  <ng-container
    *ngTemplateOutlet="
      rsCountryFlag;
      context: { code: name | getCountryCodeByName }
    "
  ></ng-container>
</ng-template>

<ng-template #rsCountryFlag let-code="code">
  <span class="fi fi-{{ code | lowercase }}"></span>
</ng-template>
