import { DeliveryMethod, OrderType } from '../_enums/order.enum';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { ScheduleRange } from 'src/app/_shared/_interfaces/date-settings';
import { Phone } from '../_models/common.interface';
import { Car } from 'src/app/profile/_interfaces/car.model';
import { OrderData } from '../_interfaces/delivery.model';

export class UpdateOrderAddress {
  static readonly type = '[OrderAddressButtonComponent] UpdateOrderAddress';

  constructor(public address: UserProfileAddress) {}
}

export class UpdateOrderPhone {
  static readonly type =
    '[CheckoutOrderInformationPopupComponent] UpdateOrderPhone';

  constructor(public phone: Phone) {}
}

export class UpdateOrderCar {
  static readonly type =
    '[CheckoutOrderInformationPopupComponent] UpdateOrderCar';

  constructor(public car: Car) {}
}

export class UpdateOrderPeriod {
  static readonly type = '[ScheduleComponent] UpdateOrderPeriod';

  constructor(public day: ScheduleRange, public time: string) {}
}

export class CheckIfCurrentOrderMethodIsAvailableAndSetDefaultIfNot {
  static readonly type =
    '[OrderOnlineHeaderComponent] CheckIfCurrentOrderMethodIsAvailableAndSetDefaultIfNot';
}

export class UpdateOrderType {
  static readonly type = '[ScheduleComponent] UpdateOrderType';

  constructor(public orderType: OrderType | DeliveryMethod) {}
}

export class InitializeOrderData {
  static readonly type = '[OrderDataState] InitializeOrderData';
}

export class UpdateOrderDataInSessionStorage {
  static readonly type = '[OrderDataState] UpdateOrderDataInSessionStorage';
}

export class ClearOrderDataPartAfterLogout {
  static readonly type = '[SessionState] ClearOrderDataPartAfterLogout';
}

export class UpdateOrderData {
  static readonly type =
    '[CheckoutOrderInformationPopupComponent, OrderInformationComponent] UpdateOrderData';

  constructor(public partialOrderData: Partial<OrderData>) {}
}
