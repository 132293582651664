import { Component, Input } from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import { SvgIconService } from 'src/app/_services/svg-icon.service';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { StatusDialogType, DialogTypeOptions } from '../dialog-type';

@Component({
  selector: 'rs-status-dialog-header',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './status-dialog-header.component.html',
})
export class RsStatusDialogHeaderComponent {
  @Input('rs-status') set status(status: StatusDialogType) {
    this.isSuccessMessage = status === DialogTypeOptions.Success;
  }

  public isSuccessMessage: boolean = true;

  public readonly successIcon = CommonIcons.Success;
  public readonly clearIcon = CommonIcons.Close;

  constructor(private readonly svgIconService: SvgIconService) {
    this.registerIcons();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.clearIcon, this.successIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
