import { Pipe, PipeTransform } from '@angular/core';
import { Profile } from 'src/app/profile/_interfaces/profile.model';
import { getFullName } from '../_utils/common';

@Pipe({ name: 'rsGetProfileFullName' })
export class FullNamePipe implements PipeTransform {
  transform = (profile: Profile): string =>
    profile ? getFullName(profile.firstName, profile.lastName) : '';
}

@Pipe({ name: 'rsGetProfileInitials' })
export class InitialsPipe implements PipeTransform {
  transform(profile: Profile): string {
    const fullName = profile
      ? getFullName(profile.firstName, profile.lastName)
      : '';
    let initialsRegExp =
      [...fullName.matchAll(new RegExp(/(\p{L}{1})\p{L}+/, 'gu'))] || [];

    return (
      (initialsRegExp.shift()?.[1] || '') + (initialsRegExp.pop()?.[1] || '')
    ).toUpperCase();
  }
}
