<ng-container *ngIf="orderType$ | async as orderType">
  <ng-container *ngIf="selectedOrderPeriod$ | async as selectedOrderPeriod">
    <div
      [dir]="'languageDirection' | translate"
      [formGroup]="itemForm"
      class="rs-item-details"
    >
      <div
        *ngIf="!dialogData.isMobile"
        class="rs-item-details__header d-flex justify-content-between align-items-center"
      >
        <button
          (click)="goBack()"
          class="d-flex align-items-center"
          rs-style="text"
        >
          <mat-icon [svgIcon]="backIcon"></mat-icon>
          <span>
            {{
              (cameFromFavorites
                ? 'DELIVERY_METHOD.Back to favorites'
                : cameFromCart
                ? 'DELIVERY_METHOD.Back to cart'
                : currentMenu?.menuName && currentSection?.sectionName
                ? 'DELIVERY_METHOD.Back to {section} in {menu}'
                : currentMenu?.menuName
                ? 'DELIVERY_METHOD.Back to {menu}'
                : 'DELIVERY_METHOD.Back to results'
              )
                | translate
                  : {
                      menu: currentMenu?.menuName,
                      section: currentSection?.sectionName
                    }
            }}
          </span>
        </button>

        <button rs-shape="circle" rs-style="standart" (click)="share()">
          <mat-icon [svgIcon]="exportIcon"></mat-icon>
        </button>
      </div>

      <ng-container *ngIf="data?.menuId; let menuId">
        <rs-item-unavailable-message
          [disableOrdering]="disableOrdering"
          [noAvailableOrderTypes]="!(isThereAnyAvailableOrderMethods$ | async)"
          [itemUnavailable]="!data?.item?.availability?.isAvailable"
          [itemUnavailableForCurrentTime]="
            !(
              menuId
              | rsIsAvailableMenuForSelectedTime
                : orderType
                : selectedOrderPeriod
                : [currentMenu!]
                : data!
            )
          "
          [itemUnavailbleForCurrentOrderType]="
            orderType &&
            !(
              data!.menuId
              | rsIsAvailableMenuForCurrentOrderType: orderType:[currentMenu!]
            )
          "
          [orderType]="orderType"
        ></rs-item-unavailable-message>
      </ng-container>

      <div *ngIf="data && data.item" class="rs-item-details__wrapper d-flex">
        <div class="rs-food-item">
          <rs-food-item
            [disableOrdering]="!!data.disableOrdering"
            [disableRedirection]="true"
            [item]="data.item"
            [menuId]="data.menuId"
            [showAllergens]="true"
            [showFullAlcoholDetails]="true"
            [showItemNumberInCart]="false"
          ></rs-food-item>
        </div>
        <div class="rs-item-details__img">
          <ng-container *ngIf="data!.item.image as image; else defaultImage">
            <img
              [src]="image"
              alt="{{ data!.item.customerFacingName || data!.item.name }}"
            />
          </ng-container>

          <mat-icon
            (click)="setFavorite($event)"
            *ngIf="isVisibleFavoriteButton$ | async"
            [ngClass]="{
              'rs-item-details__icon--active': isFavorite$ | async
            }"
            [svgIcon]="(isFavorite$ | async) ? heartIcon : heartOutlineIcon"
            class="rs-item-details__icon rs-item-details__favorite rs-item-details__icon--active"
          ></mat-icon>

          <div *ngIf="showRating" class="rs-item-details__rating">
            <mat-icon
              [svgIcon]="starIcon"
              class="rs-item-details__icon-rating"
            ></mat-icon>
            <span class="rs-item-details__rating-value">5.0</span>
          </div>
        </div>
        <div class="rs-item-details__content">
          <rs-item-description
            [calories]="(calories$ | async)!"
            [itemData]="data"
            [itemHasAllergens]="itemHasAllergens$ | async"
            [price]="
              selectedSizePrice
                | itemFullPrice
                  : selectedSizeId
                  : quantity
                  : itemForm.value.modifiers!
                | itemPriceWithBundleItems
                  : itemForm.controls.bundleItems.value
                  : quantity
                | cents2DollarsView
                | rsEasternArabicNumerals
            "
            class="rs-item-details__description"
          ></rs-item-description>

          <rs-nutritional-values class="d-block"></rs-nutritional-values>

          <rs-wine-reviews
            *ngIf="
              (data?.item?.alcoholicAttributes?.type === 'wine' ||
                data?.item?.alcoholicAttributes?.string === 'wine') &&
              data?.item?.alcoholicAttributes?.wineAttributes?.alcoholicBeverage
                ?.ratings?.length
            "
            [ratings]="
              data?.item?.alcoholicAttributes?.wineAttributes?.alcoholicBeverage
                ?.ratings || []
            "
          ></rs-wine-reviews>
          <ng-template
            [ngIf]="
              data?.item?.alcoholicAttributes?.type === 'wine' ||
              data?.item?.alcoholicAttributes?.string === 'wine'
            "
          >
            <rs-wine-details
              *ngIf="
                data?.item?.alcoholicAttributes?.wineAttributes
                  ?.alcoholicBeverage as wineDetails
              "
              [acidity]="wineDetails.acidity"
              [body]="wineDetails.body"
              [sweetness]="wineDetails.sweetness"
              [tanin]="wineDetails.tanin"
              class="rs-item-details__wine-details d-block"
            >
            </rs-wine-details>
          </ng-template>

          <div class="mb-3">
            <rs-item-sizes
              (sizeSelection)="reactOnSizeSelection($event)"
              [formControl]="itemForm.controls.size"
            ></rs-item-sizes>
          </div>

          <div class="rs-item-details__modifiers">
            <rs-item-modifiers
              [disableOrdering]="!!data?.disableOrdering"
              [metadata]="metadata"
              [modifiersControl]="itemForm.controls.modifiers"
            ></rs-item-modifiers>
          </div>

          <ng-container *ngIf="item$ | async as itemDetails">
            <div
              *ngIf="
                (itemDetails | getBundleType) === BundleType.PrixFixe ||
                (itemDetails | getBundleType) ===
                  BundleType.MulticategoryCombo ||
                (itemDetails | getBundleType) === BundleType.Combo
              "
              class="rs-item-details__section"
            >
              <ng-template [ngIf]="itemDetails | isBundleItem">
                <!--Prix Fixe and All you can eat -->
                <rs-bundle-sections
                  (allItemsSelected)="reactOnAllBundleItemsSelected($event)"
                  (allModifiersValid)="
                    reactOnBundleItemsModifiersValidation($event)
                  "
                  *ngIf="
                    (itemDetails | getBundleType) === BundleType.PrixFixe ||
                    (itemDetails | getBundleType) ===
                      BundleType.MulticategoryCombo
                  "
                  [bundleItemsControl]="itemForm.controls.bundleItems"
                  [disableOrdering]="disableOrdering"
                ></rs-bundle-sections>
                <!--Combo -->
                <rs-combo
                  (allItemsSelected)="reactOnAllBundleItemsSelected($event)"
                  (allModifiersValid)="
                    reactOnBundleItemsModifiersValidation($event)
                  "
                  *ngIf="(itemDetails | getBundleType) === BundleType.Combo"
                  [bundleItemsControl]="itemForm.controls.bundleItems"
                  [disableOrdering]="disableOrdering"
                  [itemAmount]="maxItemAmount | getMaxAmountOfItemInCombo"
                  [selectedSizeId]="selectedSizeId"
                ></rs-combo>
              </ng-template>
            </div>
          </ng-container>

          <div
            *ngIf="!disableOrdering && !isEarlyAdopter"
            class="rs-item-details__special-request mt-3 pt-2"
            [ngClass]="{
              'rs-item-details__special-request--no-border':
                data?.item?.alcoholicAttributes?.type === 'wine' ||
                data?.item?.alcoholicAttributes?.string === 'wine'
            }"
          >
            <rs-text-area
              [control]="specialRequest"
              [label]="'CART.special_requests' | translate"
              [placeholder]="'CART.tell_us_if_u_need_smth' | translate"
            ></rs-text-area>
          </div>

          <footer
            *ngIf="!disableOrdering"
            class="rs-item-details__footer d-flex justify-content-between align-items-center"
          >
            <div class="rs-item-details__footer-counter">
              <rs-counter
                (deleted)="reactOnDeleteItem()"
                (quantityChanged)="reactOnQuantityChanged($event)"
                [quantity]="quantity"
                [withDelete]="false"
                [withDisabling]="true"
              ></rs-counter>
            </div>
            <div class="rs-item-details__footer-add">
              <button
                (click)="reactOnAddToCart()"
                *ngIf="!data?.menuUnavailable && quantity"
                [disabled]="
                  itemForm.invalid ||
                  (!isDineInPage && (isOnlyDineItem || isOnlyDineInMenu)) ||
                  !selectedAllBundleItems ||
                  !allBundleItemsModifiersValid ||
                  (isDineInPage &&
                    (!data.isCurrentMenuAvailable ||
                      (data!.menuId
                        | rsIsOrderWarning
                          : orderType
                          : selectedOrderPeriod
                          : [currentMenu!]
                          : data!)))
                "
                rs-style="bold"
              >
                <span>
                  {{
                    ((isCartEditing$ | async)
                      ? 'CART.update_order'
                      : 'CART.{amount} add_to_order'
                    )
                      | translate: { amount: quantity }
                      | uppercase
                  }}
                  ({{
                    selectedSizePrice
                      | itemFullPrice
                        : selectedSizeId
                        : quantity
                        : itemForm.value.modifiers!
                      | itemPriceWithBundleItems
                        : itemForm.controls.bundleItems.value
                        : quantity
                      | cents2DollarsView
                      | rsEasternArabicNumerals
                  }})
                </span>
              </button>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #defaultImage>
  <rs-default-food-icon
    [category]="(item$ | async)?.category"
  ></rs-default-food-icon>
</ng-template>
