import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'rs-row-item-list',
  templateUrl: './row-item-list.component.html',
  styleUrls: ['./row-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowItemListComponent {
  @Input() items: any[] = [];
  @Input() isTotal: boolean = false;

  @ContentChild('item', { static: false }) public itemTemplateRef:
    | TemplateRef<any>
    | undefined;
}
