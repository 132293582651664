import { Pipe, PipeTransform } from '@angular/core';

import { Store } from '@ngxs/store';

import { CartState } from '../_ngxs/cart.state';
import { CheckCalculation } from '../_interfaces/check-calculation.model';
import { Tips } from '../_interfaces/item.model';

@Pipe({ name: 'calculateRemainingTotalAfterGiftCard', pure: false })
export class CalculateRemainingTotalAfterGiftCardsPipe
  implements PipeTransform
{
  constructor(private readonly store: Store) {}

  transform = (giftCardBalance: number | null): number => {
    const itemsInCart = this.store.selectSnapshot(
      CartState.selectedInCartItems
    );
    const tips: Tips = this.store.selectSnapshot(CartState.tips);
    const checkCalculations: CheckCalculation = this.store.selectSnapshot(
      CartState.checkCalculations
    );
    const total: number = checkCalculations.netTotal;
    const tipsInCents: number = tips.absolute || 0;

    let totalAtCheckout: number = itemsInCart.length
      ? total + tipsInCents
      : this.store.selectSnapshot(CartState.overallPrice);

    if (!giftCardBalance) {
      return totalAtCheckout;
    }

    return giftCardBalance <= totalAtCheckout
      ? totalAtCheckout - giftCardBalance
      : 0;
  };
}
