import { LoyaltyRecord } from '../_shared/_interfaces/loyalty.models';
import { v4 as uuidv4 } from 'uuid';
import { sortByDate } from '../_shared/_utils/common';
import {
  getBalance,
  getRandomDate,
  getRandomSign,
} from './generate-mock-data.util';

const getRandomLoyaltyRecord = (
  id: string = uuidv4(),
  isPositiveValue?: boolean
): LoyaltyRecord => {
  const balance = getBalance();
  const bonus = isPositiveValue
    ? balance.decimal
    : getRandomSign(balance.decimal) * balance.decimal;
  return {
    id,
    code: id,
    date: getRandomDate(),
    name: bonus > 0 ? 'Name ' + id.slice(-5) : '',
    balance,
    bonus,
  };
};
const getLoyaltyRecordForUsedPoints = (bonus: number): LoyaltyRecord => {
  const id: string = uuidv4();
  return {
    id,
    code: id,
    date: String(new Date()),
    name: '',
    balance: getBalance(bonus),
    bonus: -1 * bonus,
  };
};

const LOYALTY_RECORDS: LoyaltyRecord[] = [];

for (let i = 0; i < 8; i++) {
  const id = i ? undefined : '9BGP3XTLX1388KKG';
  LOYALTY_RECORDS.push(getRandomLoyaltyRecord(id));
}
LOYALTY_RECORDS.sort(sortByDate);

export {
  LOYALTY_RECORDS,
  getRandomLoyaltyRecord,
  getLoyaltyRecordForUsedPoints,
};
