import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';

import { Store } from '@ngxs/store';

import { untilDestroyed } from '../../_utils/until-destroyed';
import { SpinnerState } from './spinner.state';

@Component({
  selector: 'rs-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements AfterViewInit {
  public isVisible$ = this.store.select(SpinnerState.isVisible);
  public isVisible: boolean = false;
  private destroy$ = untilDestroyed();

  constructor(
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    this.isVisible$
      .pipe(this.destroy$())
      .subscribe((isShowSpinner: boolean) => {
        this.isVisible = isShowSpinner;
        this.changeDetectorRef.detectChanges();
      });
  }
}
