import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rs-confirmation-popup',
  templateUrl: 'confirmation-popup.component.html',
  styleUrls: ['confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public question: string) {}
}
