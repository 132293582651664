import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { Store } from '@ngxs/store';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import { Phone } from 'src/app/_shared/_models/common.interface';
import { PhoneNumber } from 'src/app/_shared/_models/phone-number.model';

import { REGEX } from '../../create-password-form/_constants/password-strength.constants';
import { PreferredCountries } from 'src/app/_shared/_constants/preferred-countries.constant';
import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';

import { clearString } from '../../phone/phone-number/utils/phone.helper';

import { SignUp } from 'src/app/_shared/_ngxs/authentication.actions';
import { AuthorisationCreatePasswordComponent } from '../authorisation-create-password/authorisation-create-password.component';
import { RsInputModule } from 'src/app/_shared/_rs-design/input/input.module';
import { RsPhoneInputModule } from 'src/app/_shared/_rs-design/phone-input/phone-input.module';
import {
  DEFAULT_COUNTRY,
  PHONE_DEFAULT_TYPE,
} from 'src/app/_shared/_rs-design/phone-input/phone-input.constants';

@Component({
  selector: 'rs-authorisation-sign-up',
  standalone: true,
  imports: [
    SharedModule,
    AuthorisationCreatePasswordComponent,
    RsInputModule,
    RsPhoneInputModule,
  ],
  templateUrl: 'authorisation-sign-up.component.html',
  styleUrls: [
    'authorisation-sign-up.component.scss',
    '../authorisation.common.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorisationSignUpComponent {
  @Input() public showCreatePasswordPart!: boolean;

  public preferredCountries = PreferredCountries;
  public phoneFormGroup = new FormGroup({
    phoneNumber: new FormControl(''),
    countryCode: new FormControl(DEFAULT_COUNTRY),
    type: new FormControl(PHONE_DEFAULT_TYPE),
  });
  public registrationForm: FormGroup = this.formBuilder.group({
    name: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl(null, Validators.pattern(REGEX.EMAIL)),
    phone: this.phoneFormGroup,
  });

  public get nameControl(): AbstractControl | null {
    return this.registrationForm.controls['name'];
  }

  public get lastNameControl(): AbstractControl | null {
    return this.registrationForm.controls['lastName'];
  }

  public get emailControl(): AbstractControl | null {
    return this.registrationForm.controls['email'];
  }

  public ifUserAgreedOnTerms!: boolean;
  public readonly acceptTermsId = TEST_ID.signUp.acceptTerms;

  @Output() showCreatePassword: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store
  ) {}

  public signUp(password: string): void {
    const {
      name: firstName,
      lastName,
      email,
    } = this.registrationForm.getRawValue();
    const phone: Phone = {
      countryCode: this.phoneFormGroup.value.countryCode || '',
      phoneNumber: `${clearString(
        this.phoneFormGroup.value.phoneNumber || ''
      )}`,
    };

    this.store.dispatch(
      new SignUp(firstName, lastName, email, phone, password, true)
    );
  }

  public handlePhoneControlChange(phoneValue: PhoneNumber): void {
    this.phoneFormGroup.patchValue({
      phoneNumber: phoneValue.phoneNumber,
      countryCode: phoneValue.countryCode,
      type: phoneValue.type,
    });
  }

  public submitRegistrationForm(): void {
    this.showCreatePasswordPart = true;
    this.showCreatePassword.emit(this.showCreatePasswordPart);
  }

  public changeAgreeValue({ checked }: MatCheckboxChange): void {
    this.ifUserAgreedOnTerms = checked;
  }
}
