export enum PaymentType {
  MyOwn = 'my-own',
  EntireBill = 'entire-bill',
  PayPerItem = 'pay-per-item',
  None = 'none',
}

export enum AmountType {
  Absolute = 'absolute',
  Percentage = 'percentage',
}

export enum CurrencyType {
  USD = 'USD',
  EUR = 'EUR',
  BRL = 'BRL',
  CLP = 'CLP',
  JOD = 'JOD',
}
