import { ItemData } from '../_interfaces/item.model';

export class GetItemDetails {
  static readonly type = '[Item] Get Item Details';
  constructor(public itemId: string) {}
}

export class GetItemNutritionalValues {
  static readonly type = '[Item] Get Item Nutritional Values';
  constructor(public itemId: string) {}
}

export class GetItemModifiers {
  static readonly type = '[Item] Get Item Modifiers';
  constructor(public itemId: string) {}
}

export class ClearItem {
  static readonly type = '[Item] Clear Item';
  constructor() {
    // Clears the Item in the state
  }
}

export class SetItemData {
  static readonly type = '[Item Data] Set';

  constructor(public itemData: ItemData) {}
}
