<div
  class="d-flex flex-column rs-add-payment-card"
  [dir]="'languageDirection' | translate"
>
  <span
    class="rs-add-payment-card__add-new"
    [ngClass]="isDialog ? 'mx-auto' : 'fw-bold'"
  >
    {{ 'CARD.' + (isDialog ? 'or_add_new' : 'enter__info') | translate }}
  </span>

  <form
    class="rs-add-payment-card__card-information-form row mx-auto w-100"
    [formGroup]="newCardGroup"
  >
    <rs-input
      [label]="'CARD.cardholder_name' | translate"
      [required]="true"
      [placeholder]="'CARD.cardholder_name' | translate"
      [control]="cardHolderNameControl"
    ></rs-input>

    <rs-input
      rs-input-style="card"
      [required]="true"
      [placeholder]="'CARD.card_number' | translate"
      [label]="'CARD.card_number' | translate"
      [control]="cardNumberControl"
    >
    </rs-input>

    <div class="col-xs-6 col-md-6 col-xxs-6 col-lg-8 col-xl-8">
      <rs-input
        rs-input-style="masked"
        [control]="expirationDateControl"
        [required]="true"
        [label]="'CARD.expiration' | translate"
        [placeholder]="'CARD.expiration' | translate"
        [textMask]="expirationDateMaskConfig"
        [maskErrors]="cardExpirationDateErrors"
        [maskValidator]="expirationDateValidator"
      >
      </rs-input>
    </div>

    <div class="col-md-6 col-lg-4 col-xl-4">
      <rs-input
        rs-input-style="cvv"
        [control]="cvvControl"
        [label]="'CARD.cvv' | translate"
        [required]="true"
        [placeholder]="'CARD.cvv' | translate"
        [hasClearButton]="false"
      ></rs-input>
    </div>
  </form>

  <div class="mt-2 rs-add-payment-card__address-details">
    <div class="pt-3" *ngIf="address">
      <span class="d-block fw-bold pb-3">
        {{ 'CART.Billing_Address' | translate }}
      </span>

      <div class="rs-checkbox-wrapper">
        <mat-checkbox
          [checked]="isAddressSameAsBilling"
          (change)="onBillingAddressChange($event)"
        >
          <span>
            {{ 'CART.same_as_address' | translate }}
          </span>
        </mat-checkbox>
      </div>

      <div
        *ngIf="isAddressSameAsBilling"
        class="pt-1 rs-add-payment-card__profile-address"
      >
        <rs-location
          [description]="address | rsOrderFullAddress"
          [text]="address.name!"
        ></rs-location>
      </div>
    </div>

    <div class="rs-address-form-wrapper mt-3">
      <rs-address-form
        *ngIf="!isAddressSameAsBilling"
        class="rs-add-payment-card__address-form"
        [hideCountry]="true"
        [hideInstructions]="true"
        [showLocationIcon]="true"
        [hideNickName]="false"
        [address1Placeholder]="'CART.Billing_Address'"
        [rsAddressForm]="addressForm"
        [resetFormSubject]="resetFormSubject"
      ></rs-address-form>
    </div>

    <div class="d-flex justify-content-center pt-3">
      <button
        *ngIf="newCardGroup.valid"
        [disabled]="!(isAddressSameAsBilling || addressForm.valid)"
        rs-style="bold"
        (click)="emitNewCardDetails()"
      >
        <span>{{ 'CART.use_card' | translate | uppercase }}</span>
      </button>
    </div>
  </div>
</div>
