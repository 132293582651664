import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

type BadgeSize = 'extra-small' | 'small' | 'medium';
type BadgeLabelColor = 'primary' | 'error';
type BadgeStatusStyle = 'stadium' | 'text' | 'round' | 'flat';

@Component({
  selector: 'rs-badge',
  standalone: true,
  imports: [CommonModule],
  template: `<div
      class="rs-badge rs-badge--dot"
      *ngIf="isDot; else rsBadge"
    ></div>
    <ng-template #rsBadge>
      <div class="rs-badge" [ngClass]="'rs-badge--' + size">
        <ng-content></ng-content>
      </div>
    </ng-template>`,
})
export class RsBadgeComponent {
  @Input('rs-size') size: BadgeSize = 'medium';
  @Input('rs-style') set dot(_: 'dot') {
    this.isDot = true;
  }
  public isDot: boolean = false;
}

@Component({
  selector: 'rs-badge-label',
  standalone: true,
  imports: [CommonModule],
  template: `<div
    class="rs-badge-label"
    [ngClass]="{
      'rs-badge-label--disabled': disabled,
      'rs-badge-label--error': color === 'error',
      'rs-badge-label--uppercase': isUpperCase
    }"
  >
    <ng-content></ng-content>
  </div>`,
})
export class RsBadgeLabelComponent {
  @Input('rs-uppercase') set uppercase(isUpperCase: string) {
    this.isUpperCase = isUpperCase === 'true';
  }
  @Input('rs-color') color: BadgeLabelColor = 'primary';
  @Input() disabled: boolean = false;

  public isUpperCase: boolean = false;
}

@Component({
  selector: 'rs-badge-legend',
  standalone: true,
  imports: [CommonModule],
  template: `<div class="rs-badge-legend" [style.--rs-badge-color]="color">
    <ng-content></ng-content>
  </div>`,
})
export class RsBadgeLegendComponent {
  @Input('rs-color') color!: string;
}

@Component({
  selector: 'rs-badge-status',
  standalone: true,
  imports: [CommonModule],
  template: `<div
    class="rs-badge-status"
    [style.--rs-badge-color]="color"
    [ngClass]="{
      'rs-badge-status--stadium': style === 'stadium',
      'rs-badge-status--text': style === 'text',
      'rs-badge-status--round': style === 'round',
      'rs-badge-status--flat': style === 'flat'
    }"
  >
    <ng-content></ng-content>
  </div>`,
})
export class RsBadgeStatusComponent {
  @Input('rs-style') style: BadgeStatusStyle = 'text';
  @Input('rs-color') color!: string;
}
