import { CommonIcon } from '../_enums/custom-icons.enum';
import { MarkerData } from '../_interfaces/map.model';

export class GetGeocodeByText {
  static readonly type = '[Map] Get Geocode By Text';

  constructor(public icon: CommonIcon | string, public searchString: string) {}
}

export class GetGeocodeByCoordinates {
  static readonly type = '[Map] Get Geocode By Coordinates';

  constructor(public longitude: number, public latitude: number) {}
}

export class AddMapMarker {
  static readonly type = '[Map] Add Map Marker';

  constructor(
    public addressName: CommonIcon | string,
    public markerData: MarkerData
  ) {}
}

export class AddUserLocationMarker {
  static readonly type = '[Map] Add User Location Marker';

  constructor(public customUserAddress?: string) {}
}

export class RemoveAllMapMarkers {
  static readonly type = '[Map] Remove All Map Markers';
}

export class RemoveHomeMapMarker {
  static readonly type = '[Map] Remove Home Map Marker';
}
