import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  RendererStyleFlags2,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngxs/store';
import { filter, Observable } from 'rxjs';
import { parsePhoneNumber } from 'libphonenumber-js';

import { SharedModule } from '../_modules/shared.module';

import { SaveUserName } from '../_ngxs/authentication.actions';
import { SetGuestUserData } from '../_ngxs/cart.actions';
import { SetApplePay } from '../_ngxs/order.actions';
import { OrderState } from '../_ngxs/order.state';

import { ApplePayService } from './_services/apple-pay.service';
import { untilDestroyed } from '../_utils/until-destroyed';

declare var ApplePaySession: any;

@Component({
  selector: 'rs-apple-pay',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './apple-pay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplePayComponent {
  @Input() venueName: string = '';
  @Input() totalPrice: string = '0.00';
  @Input() disabled: boolean = true;

  @ViewChild('rsApplePay', { static: false }) applePayButton:
    | ElementRef<any>
    | undefined;

  public readonly orderStatus$: Observable<string | null> = this.store.select(
    OrderState.status
  );
  public readonly destroyed$ = untilDestroyed();

  @Output() payClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly store: Store,
    private readonly renderer: Renderer2,
    private readonly platform: Platform,
    private readonly _applePayService: ApplePayService
  ) {}

  ngAfterViewInit(): void {
    if (this.platform.isBrowser && (window as any).ApplePaySession) {
      this.createButton();
    }
  }

  private createButton(): void {
    const button = this.renderer.createElement('apple-pay-button');

    this.renderer.setAttribute(button, 'buttonstyle', 'black');
    this.renderer.setAttribute(button, 'type', 'pay');
    this.renderer.setAttribute(button, 'locale', 'en-US');
    this.renderer.setStyle(
      button,
      'display',
      'inline-block',
      RendererStyleFlags2.Important
    );
    // this.renderer.setStyle(
    //   button,
    //   'height',
    //   '36px',
    //   RendererStyleFlags2.Important
    // );
    // this.renderer.setStyle(
    //   button,
    //   'width',
    //   '100%',
    //   RendererStyleFlags2.Important
    // );
    // this.renderer.setStyle(
    //   button,
    //   'max-width',
    //   '337px',
    //   RendererStyleFlags2.Important
    // );
    this.renderer.listen(button, 'click', () => this.onApplePayClick());
    this.renderer.appendChild(this.applePayButton?.nativeElement, button);
  }

  private request = this._applePayService.request;
  onApplePayClick() {
    console.log('On Apple Pay Clicked');
    this.request.shop.product_price = this.totalPrice;
    this.request.total.amount = this.totalPrice;
    this.request.total.label = this.venueName;
    console.log(this.request);

    // Create ApplePaySession
    const session = new (window as any).ApplePaySession(3, this.request);
    console.log(session);

    session.onvalidatemerchant = async (event: any) => {
      // Call your own server to request a new merchant session.
      console.log('Inside On Validate Merchant');
      console.log(event);

      let merchantSession;
      // const merchantSession = await this.validateMerchant(event.validationURL);
      this._applePayService
        .validateMerchant(event.validationURL)
        .subscribe((data: any) => {
          merchantSession = JSON.parse(atob(data.opaqueObject));
          console.log(merchantSession);
          session.completeMerchantValidation(merchantSession);
          console.log('after completeMerchantValidation');
        });
    };

    // session.onshippingmethodselected = (event: any) => {
    //   console.log('onshippingmethodselected!');
    //   const newTotal = {
    //     type: 'final',
    //     label: this.request.shop.shop_name,
    //     amount: this.request.shop.product_price,
    //   };
    //   const newLineItems = [
    //     {
    //       type: 'final',
    //       label: 'Subtotal',
    //       amount: this.request.shop.product_price,
    //     },
    //     {
    //       type: 'final',
    //       label: event.shippingMethod.label,
    //       amount: event.shippingMethod.amount,
    //     },
    //   ];
    //   session.completeShippingMethodSelection(
    //     session.STATUS_SUCCESS,
    //     newTotal,
    //     newLineItems
    //   );
    //   console.log('onshippingmethodselected.');
    // };

    // session.onpaymentmethodselected = (event: any) => {
    //   // Define ApplePayPaymentMethodUpdate based on the selected payment method.
    //   // No updates or errors are needed, pass an empty object.
    //   console.log(`onpaymentmethodselected`);
    //
    //   console.log(event);
    //   const update = {
    //     newTotal: {
    //       type: 'final',
    //       label: this.request.shop.shop_name,
    //       amount: this.request.shop.product_price
    //     }
    //   };
    //
    //   session.completePaymentMethodSelection(update);
    // };

    session.onpaymentauthorized = (event: any) => {
      const applePay = {
        token: event.payment.token,
        billingContact: event.payment.billingContact,
      };

      // GET Email and Phone number
      // console.log(event.payment.shippingContact);
      const userInfo = {
        name: `${applePay.billingContact.givenName} ${applePay.billingContact.familyName}`,
        email: event.payment.shippingContact.emailAddress,
        phone: {
          phoneNumber: parsePhoneNumber(
            event.payment.shippingContact.phoneNumber
          ).nationalNumber,
          countryCode: `+${
            parsePhoneNumber(event.payment.shippingContact.phoneNumber)
              .countryCallingCode
          }`,
        },
      };

      this.orderStatus$
        .pipe(filter(Boolean), this.destroyed$())
        .subscribe(status =>
          session.completePayment(
            status === 'success'
              ? ApplePaySession.STATUS_SUCCESS
              : ApplePaySession.STATUS_FAILURE
          )
        );

      this.store.dispatch([
        new SaveUserName(userInfo.name),
        new SetGuestUserData(userInfo, true),
        new SetApplePay(applePay),
      ]);

      this.payClicked.emit();

      console.log('onpaymentauthorized.');
    };

    session.begin();
  }
}
