import { Pipe, PipeTransform } from '@angular/core';

const getShowedCardDigits = (cardNumber: string | undefined): string => {
  if (cardNumber) {
    const SHOWED_NUMBER_CARD_DIGITS: number = 4;
    const cardNumberLength: number = cardNumber.length;
    const showedCardDigits: string =
      cardNumberLength > SHOWED_NUMBER_CARD_DIGITS
        ? cardNumber.substring(
            cardNumberLength - SHOWED_NUMBER_CARD_DIGITS,
            cardNumberLength
          )
        : cardNumber;

    return showedCardDigits;
  } else return '';
};

@Pipe({ name: 'rsStarsCard' })
export class StarsCardPipe implements PipeTransform {
  public transform(cardNumber: string | undefined): string {
    return cardNumber
      ? `**** **** **** ${getShowedCardDigits(cardNumber)}`
      : '';
  }
}

@Pipe({ name: 'rsShortStarsCard' })
export class ShortStarsCardPipe implements PipeTransform {
  public transform(cardNumber: string | undefined): string {
    return cardNumber ? `****${getShowedCardDigits(cardNumber)}` : '';
  }
}

@Pipe({ name: 'rsCardLastFourDigits' })
export class CardLastFourDigitsPipe implements PipeTransform {
  public transform(cardNumber: string | undefined): string {
    return cardNumber ? getShowedCardDigits(cardNumber) : '';
  }
}
