import { Component } from '@angular/core';

import { Store } from '@ngxs/store';

import { SharedModule } from '../../_modules/shared.module';
import { OpenLoginDialogWithoutGuest } from '../../_ngxs/dialog.actions';
import { TEST_ID } from '../../_constants/e2e-ids.constants';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';
import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';

import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-contact-info-notification',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './contact-info-notification.component.html',
  styleUrls: ['./contact-info-notification.component.scss'],
})
export class ContactInfoNotificationComponent {
  public readonly id = TEST_ID;

  public readonly personIcon = CommonIcons.User;

  constructor(
    private readonly store: Store,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcons();
  }

  public login(): void {
    this.store.dispatch(new OpenLoginDialogWithoutGuest(true));
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.personIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
