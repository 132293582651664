export enum MenuItemKey {
  Profile = 'profile',
  ChangePassword = 'change_password',
  GiftCards = 'gift_cards',
  Orders = 'orders',
  Favorites = 'favorites',
  AddressBook = 'address_book',
  PaymentMethods = 'payment_methods',
  Reservations = 'reservations',
  Loyalty = 'loyalty',
  LogOut = 'log_out',
}
