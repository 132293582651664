import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngxs/store';

import { SharedModule } from '../../shared.module';
import { RsFullHeightDialogComponent } from 'src/app/_shared/_rs-design/dialog/full-height-dialog.component';
import { DialogHeaderComponent } from 'src/app/venue/_components/dialog-header/dialog-header.component';
import { ScheduleOrderComponent } from 'src/app/_shared/_components/schedule/schedule-order/schedule-order.component';
import { FullOrderInformationInnerComponent } from '../full-order-information-inner/full-order-information-inner.component';

import { UpdateOrderData } from 'src/app/_shared/_ngxs/order-data.actions';
import { SetGuestUserPhoneData } from 'src/app/_shared/_ngxs/cart.actions';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { TEST_ID } from 'src/app/_shared/_constants/e2e-ids.constants';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { OrderData } from 'src/app/_shared/_interfaces/delivery.model';
import { isObjectEmpty } from 'src/app/_shared/_utils/common';

@Component({
  selector: 'rs-checkout-order-information-popup',
  standalone: true,
  imports: [
    SharedModule,
    RsFullHeightDialogComponent,
    DialogHeaderComponent,
    ScheduleOrderComponent,
    FullOrderInformationInnerComponent,
  ],
  templateUrl: './checkout-order-information-popup.component.html',
  styleUrls: ['./checkout-order-information-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutOrderInformationPopupComponent {
  public isCheckout: boolean = false;
  public partialOrderData!: Partial<OrderData>;

  public readonly backIcon = CommonIcons.Back;
  public readonly backButtonId: string = TEST_ID.editInfoPopup.backButton;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly dialogData: { isCheckout: boolean },
    private readonly dialogRef: MatDialogRef<CheckoutOrderInformationPopupComponent>,
    private readonly store: Store,
    private readonly svgIconService: SvgIconService
  ) {
    this.registerIcons();
    this.isCheckout = !!dialogData.isCheckout;
  }

  public finishUpdate(): void {
    if (!this.partialOrderData) {
      return;
    }

    const actions: any[] = [];

    !!this.partialOrderData.phone?.phoneNumber &&
      actions.push(new SetGuestUserPhoneData(this.partialOrderData.phone));
    !isObjectEmpty(this.partialOrderData) &&
      this.store.dispatch([
        ...actions,
        new UpdateOrderData(this.partialOrderData),
      ]);

    this.close();
  }

  public close(): void {
    this.dialogRef.close();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons([this.backIcon], NEW_ICONS_DIRECTORY);
  }
}
