import { Injectable } from '@angular/core';

import {
  Action,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import moment from 'moment';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { isEqual } from 'lodash';

import { VenueOrderSettingsState } from './venue-order-settings.state';
import { SessionState } from './authentication.state';
import { ProfileState } from './profile.state';
import {
  CheckIfCurrentOrderMethodIsAvailableAndSetDefaultIfNot,
  ClearOrderDataPartAfterLogout,
  InitializeOrderData,
  UpdateOrderAddress,
  UpdateOrderCar,
  UpdateOrderData,
  UpdateOrderDataInSessionStorage,
  UpdateOrderPeriod,
  UpdateOrderPhone,
  UpdateOrderType,
} from './order-data.actions';

import { DeliveryMethod, DeliveryType, OrderType } from '../_enums/order.enum';
import { SessionStorageKeys } from '../_enums/session-storage-keys.enum';
import {
  CurbsideMethodData,
  DeliveryData,
  DeliveryDataDateTime,
  DeliveryMethodData,
  TakeoutMethodData,
} from '../_interfaces/order.model';
import { Car } from 'src/app/profile/_interfaces/car.model';
import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';
import { AddressForm } from '../_interfaces/address.model';
import { OrderData } from '../_interfaces/delivery.model';
import { Phone } from '../_models/common.interface';

import {
  EMPTY_DELIVERY_DATE_TIME,
  getHoursAndMinutesFromInterval,
} from '../_constants/date';
import { EMPTY_CAR } from '../_constants/user.constants';

import { SessionStorageEngine } from 'src/app/_services/session-storage.service';
import { isCarValid } from '../_utils/car.utils';
import { setAMPMTimeForMomentDate } from '../_utils/dates';

export interface OrderDataStateModel extends OrderData {}

const DEFAULT_ORDER_DATA: OrderDataStateModel = {
  orderType: DeliveryMethod.Delivery,
  orderMethod: DeliveryMethod.Delivery,
  selectedOrderPeriod: { ...EMPTY_DELIVERY_DATE_TIME },
  car: EMPTY_CAR,
  address: undefined,
  phone: undefined,
};

@State<OrderDataStateModel>({
  name: 'orderData',
  defaults: DEFAULT_ORDER_DATA,
})
@Injectable()
export class OrderDataState implements NgxsOnInit, NgxsOnChanges {
  @Selector()
  static orderType({ orderType }: OrderDataStateModel): DeliveryType {
    return orderType;
  }

  @Selector()
  static orderMethod({ orderMethod }: OrderDataStateModel): DeliveryMethod {
    return orderMethod;
  }

  // quick fix
  // if we don't have selected time or it is before now
  // we don't add scheduledTime
  @Selector()
  static scheduledTime({
    selectedOrderPeriod,
  }: OrderDataStateModel): string | undefined {
    if (
      !selectedOrderPeriod?.day ||
      selectedOrderPeriod?.time?.toLowerCase().includes('asap')
    ) {
      return;
    }

    let momentTime = moment();
    !!selectedOrderPeriod.day.day &&
      (momentTime = selectedOrderPeriod?.day?.day);

    if (selectedOrderPeriod.time) {
      const [hours, minutes] = getHoursAndMinutesFromInterval(
        selectedOrderPeriod.time,
        0
      );

      momentTime.set({ hours, minutes });
    }

    return moment().isAfter(momentTime) ? undefined : momentTime.format();
  }

  @Selector()
  static selectedOrderPeriod({
    selectedOrderPeriod,
  }: OrderDataStateModel): DeliveryDataDateTime {
    return selectedOrderPeriod;
  }

  @Selector()
  static orderData({
    orderType,
    orderMethod,
    selectedOrderPeriod,
    car,
    address,
    phone,
  }: OrderDataStateModel): DeliveryData {
    // who know the actual delivery type?
    if (orderType !== orderMethod) {
      return { orderType: OrderType.DineIn };
    }

    const { day, time } = selectedOrderPeriod;
    const asap: boolean = !time || time.includes('ASAP');

    switch (orderMethod) {
      case DeliveryMethod.Delivery:
        const scheduledFor: string = moment(day.day)?.toISOString() || '';
        const deliveryMethodData: DeliveryMethodData = {
          asap,
          day,
          time,
          scheduledFor,
        } as DeliveryMethodData;

        !!address && (deliveryMethodData.address = address as AddressForm);
        !!phone && (deliveryMethodData.phone = phone);

        return {
          orderType: orderMethod,
          data: deliveryMethodData,
        };
      case DeliveryMethod.Curbside:
        const curbsideMethodData: CurbsideMethodData = {
          day,
          time,
        } as CurbsideMethodData;

        !!car && (curbsideMethodData.carInfo = car);
        return {
          orderType: orderMethod,
          data: curbsideMethodData,
        };
      case DeliveryMethod.Takeout:
        return {
          orderType: orderMethod,
          data: { asap, day, time } as TakeoutMethodData,
        };
    }
  }

  @Selector()
  static deliveryDay({ orderType, selectedOrderPeriod }: OrderDataStateModel) {
    if (orderType === OrderType.DineIn || !selectedOrderPeriod) {
      return {} as moment.Moment;
    }

    const { day, time } = selectedOrderPeriod;
    const asap: boolean = !time || time.includes('ASAP');
    const deliveryDay = moment(day?.day);
    const deliveryTime = moment(time?.split(' - ')[0], 'h:mm A');

    if (
      (orderType === DeliveryMethod.Takeout ||
        orderType === DeliveryMethod.Delivery) &&
      !asap
    ) {
      deliveryDay.set({
        hour: deliveryTime.hour(),
        minute: deliveryTime.minute(),
        second: deliveryTime.second(),
      });
    }

    return deliveryDay;
  }

  // Note: if current delivery method is not Delivery
  // using this method is your fault :D
  @Selector()
  static deliveryMethodData({
    selectedOrderPeriod,
    address,
    phone,
  }: OrderDataStateModel): DeliveryMethodData {
    const { day, time } = selectedOrderPeriod;
    const asap: boolean = !time || time.includes('ASAP');
    const scheduledFor: string = moment(day.day)?.toISOString() || '';
    const deliveryMethodData: DeliveryMethodData = {
      asap,
      day,
      time,
      scheduledFor,
    } as DeliveryMethodData;

    !!address && (deliveryMethodData.address = address as AddressForm);
    !!phone && (deliveryMethodData.phone = phone);

    return deliveryMethodData;
  }

  @Selector()
  static orderCar({ car }: OrderDataStateModel): Car {
    return car;
  }

  @Selector()
  static orderAddress({
    address,
  }: OrderDataStateModel): UserProfileAddress | undefined {
    return address;
  }

  @Selector()
  static orderPhone({ phone }: OrderDataStateModel): Phone | undefined {
    return phone;
  }

  @Selector()
  static orderPhoneAsString({ phone }: OrderDataStateModel): string {
    return phone
      ? `${phone.countryCode}${phone.phoneNumber}`
          .split(' ')
          .join('')
          .split('-')
          .join('')
      : '';
  }

  @Selector()
  static isCurrentOrderDataMissedInformation({
    orderType,
    orderMethod,
    selectedOrderPeriod,
    car,
    address,
    phone,
  }: OrderDataStateModel): boolean {
    if (
      orderType === OrderType.DineIn ||
      orderType === DeliveryMethod.Takeout
    ) {
      return false;
    }

    if (!selectedOrderPeriod.time || !selectedOrderPeriod.day.periods.length) {
      return true;
    }

    return (
      (orderMethod === DeliveryMethod.Delivery && (!address || !phone)) ||
      !isCarValid(car)
    );
  }

  constructor(
    private readonly store: Store,
    private readonly sessionStorage: SessionStorageEngine
  ) {}

  ngxsOnInit({
    dispatch,
    patchState,
    getState,
  }: StateContext<OrderDataStateModel>) {
    dispatch(new InitializeOrderData());

    combineLatest([
      this.store.select(SessionState.isLoggedIn),
      this.store.select(ProfileState.cars),
    ])
      .pipe(
        distinctUntilChanged(
          (previous, current) =>
            isEqual(previous, current) &&
            !!previous[1]?.length === !!current[1]?.length
        )
      )
      .subscribe(([isLoggedIn, cars]) => {
        if (!cars?.length) {
          return;
        }

        if (isLoggedIn) {
          patchState({ car: cars.find(car => car.isDefault) || cars[0] });
        } else {
          const orderCar: Car = getState().car;

          !isEqual(orderCar, EMPTY_CAR) &&
            !!cars.find(car => isEqual(car, orderCar)) &&
            patchState({ car: { ...EMPTY_CAR } });
        }
      });
  }

  ngxsOnChanges({ firstChange }: NgxsSimpleChange) {
    !firstChange && this.store.dispatch(new UpdateOrderDataInSessionStorage());
    // if delivery we need to update Quote ?
    // if no delivery we need to remove Quote
  }

  @Action(InitializeOrderData)
  initializeOrderData({
    patchState,
    dispatch,
  }: StateContext<OrderDataStateModel>) {
    const sessionOrderData = JSON.parse(
      this.sessionStorage.getItem(SessionStorageKeys.tempDeliveryData) || 'null'
    ) as Partial<OrderDataStateModel>;
    const allowScheduledOrders: boolean = this.store.selectSnapshot(
      VenueOrderSettingsState.allowScheduledOrders
    );

    if (sessionOrderData?.selectedOrderPeriod?.day?.day) {
      const day = moment(sessionOrderData?.selectedOrderPeriod?.day?.day);
      const dayForComparison = moment(
        sessionOrderData?.selectedOrderPeriod?.day?.day
      );
      const now = moment();
      const isDayFromSessionToday: boolean = day.diff(now, 'days') === 0;

      if (!allowScheduledOrders && !isDayFromSessionToday) {
        sessionOrderData.selectedOrderPeriod = { ...EMPTY_DELIVERY_DATE_TIME };
      } else {
        if (
          !sessionOrderData?.selectedOrderPeriod?.time
            ?.toLowerCase()
            .includes('asap')
        ) {
          const fromTime: string =
            sessionOrderData?.selectedOrderPeriod?.time.split(' - ')[0];

          !!fromTime && setAMPMTimeForMomentDate(dayForComparison, fromTime);
        }

        if (dayForComparison.isBefore(now)) {
          sessionOrderData.selectedOrderPeriod = {
            ...EMPTY_DELIVERY_DATE_TIME,
          };
        } else {
          sessionOrderData.selectedOrderPeriod.day.day = day;
        }
      }
    }

    !!sessionOrderData?.orderType && patchState({ ...sessionOrderData });

    dispatch(new CheckIfCurrentOrderMethodIsAvailableAndSetDefaultIfNot());
  }

  @Action(UpdateOrderDataInSessionStorage)
  updateOrderDataInSessionStorage({
    getState,
  }: StateContext<OrderDataStateModel>) {
    const { orderType, orderMethod, selectedOrderPeriod, car, address, phone } =
      getState();
    const data: Partial<OrderDataStateModel> = {};
    !!orderType && (data.orderType = orderType);
    !!orderMethod && (data.orderMethod = orderMethod);
    !!selectedOrderPeriod && (data.selectedOrderPeriod = selectedOrderPeriod);
    !!car && (data.car = car);
    !!address && (data.address = address);
    !!phone && (data.phone = phone);

    this.sessionStorage.setItem(
      SessionStorageKeys.tempDeliveryData,
      JSON.stringify(data)
    );
  }

  @Action(UpdateOrderAddress)
  updateOrderAddress(
    { patchState }: StateContext<OrderDataStateModel>,
    { address }: UpdateOrderAddress
  ) {
    patchState({ address });
  }

  @Action(UpdateOrderPhone)
  updateOrderPhone(
    { patchState }: StateContext<OrderDataStateModel>,
    { phone }: UpdateOrderPhone
  ) {
    patchState({ phone });
  }

  @Action(UpdateOrderCar)
  updateOrderCar(
    { patchState }: StateContext<OrderDataStateModel>,
    { car }: UpdateOrderCar
  ) {
    patchState({ car });
  }

  @Action(UpdateOrderPeriod)
  updateOrderPeriod(
    { patchState }: StateContext<OrderDataStateModel>,
    { day, time }: UpdateOrderPeriod
  ) {
    if (!day?.periods.length || !time) {
      return;
    }

    patchState({ selectedOrderPeriod: { day, time } });
  }

  @Action(UpdateOrderType)
  updateOrderType(
    { patchState }: StateContext<OrderDataStateModel>,
    { orderType }: OrderDataStateModel
  ) {
    const orderTypeData: Partial<OrderDataStateModel> = {
      orderType: orderType,
    };
    orderType !== OrderType.DineIn && (orderTypeData.orderMethod = orderType);

    patchState({ ...orderTypeData });
  }

  @Action(CheckIfCurrentOrderMethodIsAvailableAndSetDefaultIfNot)
  checkIfCurrentOrderMethodIsAvailableAndSetDefaultIfNot(
    { getState, patchState }: StateContext<OrderDataStateModel>,
    _: CheckIfCurrentOrderMethodIsAvailableAndSetDefaultIfNot
  ) {
    let { orderMethod, orderType } = getState();
    const stateUpdate: Partial<OrderDataStateModel> = {};

    if (
      !this.store.selectSnapshot(
        VenueOrderSettingsState.isDeliveryMethodActive(orderMethod)
      )
    ) {
      const availableOrderMethods = this.store.selectSnapshot(
        VenueOrderSettingsState.availableDeliveryMethods
      );

      for (let method of availableOrderMethods) {
        if (
          this.store.selectSnapshot(
            VenueOrderSettingsState.isDeliveryMethodActive(method)
          )
        ) {
          orderMethod = method;
          stateUpdate.orderMethod = method;

          break;
        }
      }
    }

    orderType !== OrderType.DineIn &&
      orderMethod !== orderType &&
      (stateUpdate.orderType = orderMethod);
    Object.keys(orderMethod).length !== 0 && patchState({ ...stateUpdate });
  }

  @Action(ClearOrderDataPartAfterLogout)
  clearOrderDataPartAfterLogout({
    patchState,
  }: StateContext<OrderDataStateModel>) {
    patchState({
      car: EMPTY_CAR,
      address: undefined,
      phone: undefined,
    });
  }

  @Action(UpdateOrderData)
  updateOrderData(
    { patchState }: StateContext<OrderDataStateModel>,
    { partialOrderData }: UpdateOrderData
  ) {
    patchState({
      ...partialOrderData,
    });
  }
}
