import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

import { StorageEngine } from '@ngxs/storage-plugin';

import { CustomStorage } from './custom-storage.service';

@Injectable({ providedIn: 'root' })
export class SessionStorageEngine implements StorageEngine {
  private storage: Storage;
  constructor(private platform: Platform) {
    if (this.platform.isBrowser && window?.sessionStorage) {
      this.storage = window.sessionStorage;
    } else {
      this.storage = new CustomStorage();
    }
  }

  public get length(): number {
    return this.storage.length;
  }

  public clear(): void {
    this.storage.clear();
  }

  public getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  public key(index: number): string | null {
    return this.storage.key(index);
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }
}
