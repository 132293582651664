export enum CommonIcon {
  Smile = 'smile',
  AngleLeft = 'angle-left',
  AngleLeftGrey = 'angle-left-grey',
  AngleLeftBlack = 'angle-left-black',
  User = 'user-solid',
  Calendar = 'calendar',
  LocationArrow = 'location-arrow',
  Location = 'location',
  Home = 'home',
  Spoon = 'spoon-icon',
  Check = 'check-solid',
  Star = 'star-solid',
  Headphones = 'headphones-solid',
  Heart = 'heart-regular',
  Download = 'download-solid',
  RedSpoon = 'red-spoon-icon',
  HeartSolid = 'heart-solid',
  CreditCard = 'credit-card-solid',
  Gift = 'gift-solid',
  WhiteRockspoonLogo = 'white-rockspoon-logo',
  Wallet = 'wallet-solid',
  Print = 'print',
  RotateRight = 'rotate-right',
  BillingAddress = 'billing-address',
  ClockETA = 'clock-eta',
  Clock = 'clock',
  MapPin = 'map-pin',
  Square = 'square',
  Circle = 'circle',
  Trash = 'trash',
  Minus = 'minus',
  Plus = 'plus',
  Info = 'info',
  Close = 'close',
  QuestionMark = 'dine-in-question-mark',
  ViewCart = 'view-cart-icon',
}

export enum BrandIcon {
  Facebook = 'facebook-brands',
  Instagram = 'instagram-brands',
  Youtube = 'youtube-brands',
  GooglePlay = 'google-play-brands',
  AppStore = 'app-store-ios-brands',
  Twitter = 'twitter-brands',
  Website = 'website-brands',
  Blog = 'blog-brands',
}
