import { ShadowUserUsername } from '../_interfaces/session.model';
import {
  ChangePasswordRequest,
  ForgetPasswordRequest,
} from '../_models/authentication.model';
import { Phone } from '../_models/common.interface';
import { SessionStateModel } from './authentication.state';

export class SaveUserName {
  static readonly type = '[Authentication] Set user name';
  constructor(public readonly name: string) {}
}

export class SetSessionData {
  static readonly type = '[Authentication] Set User Data';
  constructor(public readonly SessionState: SessionStateModel) {}
}

export class SendRecoveryLink {
  static readonly type = '[Authentication] Send Recovery Link';
  constructor(public readonly email: string, public readonly venueId: string) {}
}

export class Login {
  static readonly type = '[Authentication] Login';
  constructor(
    public readonly email: string,
    public readonly password: string
  ) {}
}

export class GetShadowUserToken {
  static readonly type = '[Authentication] Get Shadow User Token';
  constructor(public readonly shadowUserUsername?: ShadowUserUsername) {}
}

export class RefreshToken {
  static readonly type = '[Authentication] Refresh Token';
}

export class SignUp {
  static readonly type = '[Authentication] Sign Up';
  constructor(
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly email: string,
    public readonly phone: Phone,
    public readonly password: string,
    public readonly allowUnconfirmedLogin?: boolean
  ) {}
}

export class Logout {
  static readonly type = '[Authentication] Logout';
}

export class RemoveShadowAndLoggedUserBoth {
  static readonly type =
    '[DigitalStorefrontState] RemoveShadowAndLoggedUserBoth';
}

export class ChangePassword {
  static readonly type = '[Authentication] Change Profile Password';

  constructor(public data: ChangePasswordRequest) {}
}

export class ForgetPassword {
  static readonly type = '[Authentication] Forget Password';
  constructor(public data: ForgetPasswordRequest) {}
}
