import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { point, lineString, bezierSpline } from '@turf/turf';

import { SharedModule } from '../../_modules/shared.module';
import { GeolocationService } from 'src/app/_services/geolocation.service';
import { MapboxService } from 'src/app/_services/mapbox.service';

import { MapState } from '../../_ngxs/mapbox.state';
import {
  GetGeocodeByText,
  RemoveAllMapMarkers,
} from '../../_ngxs/mapbox.actions';

import { environment } from 'src/environments/environment';
import { CommonIcon } from '../../_enums/custom-icons.enum';
import { MarkerData, Markers } from '../../_interfaces/map.model';
import { DEFAULT_MAP_CONFIG } from '../../_constants/map.constant';
import { getMarkerTemplate } from '../../_utils/map.helper';
import { untilDestroyed } from '../../_utils/until-destroyed';

// please, pay attention on order for coordinates - [longitude, latitude]

@Component({
  selector: 'rs-map',
  standalone: true,
  imports: [SharedModule],
  providers: [GeolocationService, MapboxService],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent {
  @Input() isNeedToAddDestinationPoint: boolean = false;
  @Input() mapName: string = 'map';

  private readonly markers$: Observable<Markers> = this.store.select(
    MapState.markers
  );

  private mapboxgl: any;
  private map: any;
  private markers: any[] = [];
  private isDestinationAdded: boolean = false;

  private readonly destroy$ = untilDestroyed();

  constructor(
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.store.dispatch(new RemoveAllMapMarkers());
  }

  public ngAfterViewInit(): void {
    if (typeof window === 'undefined') {
      return;
    }

    this.mapboxgl = require('mapbox-gl');
    (this.mapboxgl as any).accessToken = environment.mapbox.accessToken;
    this.map = new this.mapboxgl.Map({
      ...DEFAULT_MAP_CONFIG,
      container: this.mapName,
    });

    this.map.on('load', () => {
      if (this.isDestinationAdded || !this.isNeedToAddDestinationPoint) {
        return;
      }

      this.isDestinationAdded = true;
      this.store.dispatch(
        new GetGeocodeByText(
          CommonIcon.Square,
          'University Ave, Palo Alto, CA 94301'
        )
      );
    });

    this.initializeMarkersSubscription();

    this.changeDetectorRef.markForCheck();
  }

  private initializeMarkersSubscription(): void {
    this.markers$.pipe(this.destroy$()).subscribe((data: Markers) => {
      const markers = (Object.values(data) || []).reduce(
        (accumulator: MarkerData[], markersData: MarkerData[]) => [
          ...accumulator,
          ...markersData,
        ],
        []
      );

      this.markers.forEach(marker => marker.remove());
      markers.forEach(marker =>
        this.markers.push(this.getCustomMarker(marker))
      );

      if (!markers?.length) {
        return;
      }

      this.updateMapCenterCoordinates(markers);
      this.removeLine();

      markers.length === 2 &&
        markers.some(({ icon }) => this.isIconCorrect(icon)) &&
        this.addLine(
          [markers[0].longitude, markers[0].latitude],
          [markers[1].longitude, markers[1].latitude]
        );

      this.changeDetectorRef.markForCheck();
    });
  }

  private updateMapZooming(markers: MarkerData[]): void {
    if (markers.length > 1) {
      const bounds = new this.mapboxgl.LngLatBounds();

      markers.forEach(marker => {
        bounds.extend([marker.longitude, marker.latitude]);
      });

      this.map.fitBounds(bounds, { padding: 30 });

      this.changeDetectorRef.markForCheck();
    }
  }

  private getCustomMarker(marker: MarkerData): void {
    const element = document.createElement('div');
    element.innerHTML = getMarkerTemplate(marker.icon);

    return new this.mapboxgl.Marker(element)
      .setLngLat([marker.longitude, marker.latitude])
      .addTo(this.map);
  }

  private updateMapCenterCoordinates(markers: MarkerData[]): void {
    const sortedByLongitude = markers.sort((a, b) => {
      return a.longitude < b.longitude ? 1 : a.longitude > b.longitude ? -1 : 0;
    });
    const sortedByLatitude = markers.sort((a, b) => {
      return a.latitude < b.latitude ? 1 : a.latitude > b.latitude ? -1 : 0;
    });

    if (sortedByLongitude?.length) {
      const longitudeCenter =
        (sortedByLongitude[0].longitude +
          sortedByLongitude[markers.length - 1].longitude) /
        2;
      const latitudeCenter =
        (sortedByLatitude[0].latitude +
          sortedByLatitude[markers.length - 1].latitude) /
        2;

      this.map.flyTo({
        center: [longitudeCenter, latitudeCenter],
        zoom: 17,
      });

      this.updateMapZooming(markers);
    }

    this.changeDetectorRef.markForCheck();
  }

  private addLine(startCoordinates: number[], endCoordinates: number[]): void {
    if (typeof window !== undefined) {
      const start = point(startCoordinates);
      const end = point(endCoordinates);
      const midpoint = this.getMiddleCoordinates(
        startCoordinates,
        endCoordinates
      );
      const curvedLine = bezierSpline(
        lineString([
          start.geometry.coordinates,
          midpoint,
          end.geometry.coordinates,
        ])
      );

      if (!!this.map.getSource('LineSource')) {
        this.map.getSource('LineSource').setData(curvedLine);
      } else {
        this.map.addSource('LineSource', {
          type: 'geojson',
          data: curvedLine,
        });
      }

      this.map.addLayer({
        id: `${this.mapName || 'LineLayer'}`,
        type: 'line',
        source: 'LineSource',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#333',
          'line-width': 3,
        },
      });
    }

    this.changeDetectorRef.markForCheck();
  }

  private removeLine(): void {
    if (this.map.getLayer(`${this.mapName || 'LineLayer'}`)) {
      this.map.removeLayer(`${this.mapName || 'LineLayer'}`);
    }

    if (this.map.getSource('LineSource')) {
      this.map.removeSource('LineSource');
    }

    this.changeDetectorRef.markForCheck();
  }

  private getMiddleCoordinates(start: number[], end: number[]): number[] {
    const longitudeDiff = Math.abs(start[0] - end[0]);
    const latitudeDiff = Math.abs(start[1] - end[1]);
    const longitudeDiffMore: boolean = longitudeDiff > latitudeDiff;
    let longitude: number;
    let latitude: number;

    longitude = (start[0] < end[0] ? start[0] : end[0]) + longitudeDiff / 2;
    latitude = (start[1] < end[1] ? start[1] : end[1]) + latitudeDiff / 2;

    if (longitudeDiffMore) {
      latitude += latitudeDiff / 2;
    } else {
      longitude += longitudeDiff / 2;
    }

    return [longitude, latitude];
  }

  private isIconCorrect(icon: string): boolean {
    return (
      icon === CommonIcon.Home ||
      icon === CommonIcon.Location ||
      icon === CommonIcon.Square ||
      icon === CommonIcon.Circle
    );
  }
}
