import { Pipe, PipeTransform } from '@angular/core';

import { isEqual, uniqWith } from 'lodash';

import {
  BundleInformation,
  BundleItem,
  BundleItemSizeAttributes,
  BundleSectionSizeAttributes,
  ComboAttributes,
  ItemDetails,
  Modifier,
} from '../_interfaces/item.model';

import {
  allYouCanEatTag,
  bundleCategory,
} from '../_constants/bundle.constants';
import { BundleType } from '../_enums/item.enum';
import { getBundleItemsAmount } from '../_utils/items.helper';

@Pipe({ name: 'isBundleItem' })
export class IsBundleItemPipe implements PipeTransform {
  transform = (itemDetails: ItemDetails): boolean => {
    return itemDetails.category === bundleCategory;
  };
}

@Pipe({ name: 'getBundleType' })
export class GetBundleTypePipe implements PipeTransform {
  transform = (itemDetails: ItemDetails): BundleType => {
    return itemDetails?.bundleAttributes?.type;
  };
}

@Pipe({ name: 'isAllYouCanEatItem' })
export class IsAllYouCanEatItemPipe implements PipeTransform {
  transform = (itemDetails: ItemDetails, bundleType: string = ''): boolean => {
    if (
      itemDetails.category !== bundleCategory ||
      bundleType !== BundleType.PrixFixe ||
      !itemDetails.tags.length
    ) {
      return false;
    }
    return !!itemDetails?.tags?.find(
      (tag: any) => tag?.['label']?.[0]?.['value'] === allYouCanEatTag
    );
  };
}

@Pipe({ name: 'getBundleSectionSizeAttributes' })
export class GetBundleSectionSizePipe implements PipeTransform {
  transform = (
    bundleSectionSizeAttributes: BundleSectionSizeAttributes[] | undefined,
    bundleSectionId: string
  ): BundleSectionSizeAttributes | undefined => {
    return bundleSectionSizeAttributes?.find(
      ({ bundleSectionId: id }) => bundleSectionId === id
    );
  };
}

@Pipe({ name: 'getBundleItemPrice' })
export class GetBundleItemPricePipe implements PipeTransform {
  transform = (
    bundleItemSizeAttributes: BundleItemSizeAttributes[],
    bundleItemId: string
  ): number => {
    if (!bundleItemSizeAttributes?.length) {
      return 0;
    }
    const bundleItemSizeAttribute = bundleItemSizeAttributes.find(
      ({ bundleItemId: id }) => bundleItemId === id
    );

    if (!bundleItemSizeAttribute) {
      return 0;
    }

    return Number(bundleItemSizeAttribute.extraPrice.value);
  };
}

@Pipe({ name: 'getBundleItemSizeAttribute' })
export class GetBundleItemSizeAttributePipe implements PipeTransform {
  transform = (
    bundleItemSizeAttributes: BundleItemSizeAttributes[] | undefined,
    bundleItemId: string
  ): BundleItemSizeAttributes | undefined => {
    return bundleItemSizeAttributes?.find(
      ({ bundleItemId: id }) => bundleItemId === id
    );
  };
}

@Pipe({ name: 'getBundleItemsCount', pure: false })
export class GetBundleItemsCountPipe implements PipeTransform {
  transform = (
    addedBundleItems: BundleInformation[],
    bundleItemId: string,
    bundleSectionId: string
  ): number => {
    return (
      addedBundleItems.filter(
        ({ bundleSectionId: sectionId, bundleItemId: id }) =>
          bundleSectionId === sectionId && bundleItemId === id
      )?.length ?? 0
    );
  };
}

@Pipe({ name: 'getBundleItemMaximum', pure: false })
export class GetBundleItemMaximumPipe implements PipeTransform {
  transform = (
    addedBundleItems: BundleInformation[],
    bundleItemId: string,
    bundleSectionId: string,
    bundleSectionSizeAttributes: BundleSectionSizeAttributes | undefined,
    bundleItemSizeAttributes: BundleItemSizeAttributes[] = []
  ): number => {
    const bundleItemSizeAttribute: BundleItemSizeAttributes | undefined =
      bundleItemSizeAttributes.find(
        ({ bundleItemId: id }) => bundleItemId === id
      );
    const maximumItemQuantity: number =
      bundleItemSizeAttribute?.maximumQuantity || 1000;
    const maximumBundleQuantity: number =
      bundleSectionSizeAttributes?.maximumQuantity || 1000;

    // if there is no added items max possible items for current bundle item
    // is min (bundle_max, item_max)
    if (!addedBundleItems?.length) {
      return Math.min(maximumBundleQuantity, maximumItemQuantity);
    }

    // if there is added items, max possible is
    // min(max - sum(added_items_for_current_bundle) + sum (added_current_items), item_max)
    const addedCurrentItems =
      addedBundleItems.filter(
        ({ bundleSectionId: sectionId, bundleItemId: id }) =>
          bundleSectionId === sectionId && bundleItemId === id
      )?.length ?? 0;
    const addedItemsInSection =
      addedBundleItems.filter(
        ({ bundleSectionId: sectionId }) => bundleSectionId === sectionId
      )?.length ?? 0;

    return (
      Math.min(
        maximumBundleQuantity + addedCurrentItems - addedItemsInSection,
        maximumItemQuantity
      ) ?? 0
    );
  };
}

@Pipe({ name: 'getComboItemMaximum', pure: false })
export class GetComboItemMaximumPipe implements PipeTransform {
  transform = (
    addedBundleItems: BundleInformation[],
    bundleItemId: string,
    comboAttributes: ComboAttributes,
    bundleItemSizeAttributes: BundleItemSizeAttributes[] = []
  ): number => {
    const bundleItemSizeAttribute: BundleItemSizeAttributes | undefined =
      bundleItemSizeAttributes?.find(
        ({ bundleItemId: id }) => bundleItemId === id
      );
    const maximumItemQuantity: number =
      bundleItemSizeAttribute?.maximumQuantity ?? 1000;
    const maximumBundleQuantity: number =
      comboAttributes?.itemsQuantity ?? 1000;

    // if there is no added items max possible items for current bundle item
    // is min (bundle_max, item_max)
    if (!addedBundleItems?.length) {
      return Math.min(maximumBundleQuantity, maximumItemQuantity);
    }

    // if there is added items, max possible is
    // min(max - sum(added_items_for_current_bundle) + sum (added_current_items), item_max)
    const addedBundleItemsTotal = addedBundleItems?.length ?? 0;
    const addedCurrentItems =
      addedBundleItems.filter(({ bundleItemId: id }) => bundleItemId === id)
        ?.length ?? 0;

    return (
      Math.min(
        maximumBundleQuantity + addedCurrentItems - addedBundleItemsTotal,
        maximumItemQuantity
      ) ?? 0
    );
  };
}

@Pipe({ name: 'isBundleItemRepeated' })
export class isBundleItemRepeatedPipe implements PipeTransform {
  transform(
    item: BundleItem,
    bundleItemModifiers: { item: BundleItem; modifiers: Modifier[] }[]
  ): boolean {
    return (
      bundleItemModifiers.filter(
        ({ item: bundleItem }) => item.id === bundleItem.id
      ).length > 1
    );
  }
}

@Pipe({ name: 'bundleItemNumber' })
export class BundleItemNumberPipe implements PipeTransform {
  transform(
    item: BundleItem,
    bundleItemModifiers: { item: BundleItem; modifiers: Modifier[] }[],
    currentIndex: number
  ): number {
    return bundleItemModifiers.filter(
      ({ item: bundleItem }, index) =>
        bundleItem.id === item.id && index <= currentIndex
    ).length;
  }
}

// TODO check probably delete
@Pipe({ name: 'uniqueBundleItems' })
export class UniqueBundleItemsPipe implements PipeTransform {
  transform(items: any[]): any[] {
    return uniqWith(items, isEqual);
  }
}

// TODO check probably delete
@Pipe({ name: 'rsGetBundleItemsAmount' })
export class GetBundleItemsAmountPipe implements PipeTransform {
  transform = <T>(item: T, items: T[]): string =>
    getBundleItemsAmount(item, items);
}

@Pipe({ name: 'getComboItemSizeAttributes' })
export class GetComboItemSizeAttributesPipe implements PipeTransform {
  transform(item: ItemDetails, sizeId: string): BundleItemSizeAttributes[] {
    return (
      item?.sizesAndPrices?.find(sizeAndPrice => sizeAndPrice.id === sizeId)
        ?.bundleItemSizeAttributes || []
    );
  }
}

@Pipe({ name: 'getComboAttributes' })
export class GetComboAttributesPipe implements PipeTransform {
  transform(item: ItemDetails, sizeId: string): ComboAttributes {
    return item?.sizesAndPrices?.find(
      sizeAndPrice => sizeAndPrice.id === sizeId
    )?.comboAttributes as ComboAttributes;
  }
}

@Pipe({ name: 'getMaxAmountOfItemInCombo' })
export class GetMaxAmountOfItemInComboPipe implements PipeTransform {
  transform(maxItemAmount: number): number {
    const MIN_AMOUNT_OF_ITEMS_IN_COMBO = 1;

    if (!maxItemAmount || maxItemAmount < MIN_AMOUNT_OF_ITEMS_IN_COMBO) {
      maxItemAmount = MIN_AMOUNT_OF_ITEMS_IN_COMBO;
    }

    return maxItemAmount;
  }
}
