<div
  class="rs-schedule-order-date position-relative"
  [dir]="'languageDirection' | translate"
>
  <div class="rs-schedule-order-date__title">
    {{ title }}
  </div>
  <div
    class="rs-schedule-order-date__days d-flex overflow-auto align-items-center"
    #rsDaysRef
  >
    <ng-container
      *ngIf="scheduleRange?.length && !noAvailableTime; else rsNoAvailableDay"
    >
      <div
        *ngFor="let day of scheduleRange; let index = index"
        class="rs-schedule-order-date__day d-flex flex-column justify-content-center align-items-center cursor-pointer"
        [class.rs-schedule-order-date__day--selected]="
          selectedDay
            ? (day | rsIsScheduleDaySelected: selectedDay)
            : index === 0
        "
        (click)="selectDay(day)"
      >
        <div class="rs-schedule-order-date__day-title">
          {{ day | rsScheduleOrderDayTitle | async }}
        </div>
        <div class="rs-schedule-order-date__day-description">
          {{ day.day | localizedDate: 'MMM d' | titlecase }}
        </div>
      </div>

      <div
        *ngIf="showScrollToStartButton"
        class="rs-schedule-order-date__action rs-schedule-order-date__action--before cursor-pointer"
        (click)="scrollToStart()"
      >
        <mat-icon
          class="rs-schedule-order-date__action-icon d-flex"
          [svgIcon]="arrowIcon"
        ></mat-icon>
      </div>

      <div
        *ngIf="showScrollToEndButton"
        class="rs-schedule-order-date__action rs-schedule-order-date__action--after cursor-pointer"
        (click)="scrollToEnd()"
      >
        <mat-icon
          class="rs-schedule-order-date__action-icon d-flex"
          [svgIcon]="arrowIcon"
        ></mat-icon>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #rsNoAvailableDay>
  <p>
    {{ 'SCHEDULE.no_available_time' | translate }}
  </p>
</ng-template>
