<ng-container *ngIf="modifiers$ | async; let modifiers">
  <ng-container *ngFor="let modifier of modifiers">
    <div class="rs-modifier" *ngIf="modifier.type ==='cooking'">
      <ng-container
        *ngTemplateOutlet="modifierTitle; context: { modifier: modifier }"
      ></ng-container>

      <mat-icon
        id="clear-{{ modifier.id }}"
        (click)="clearSelectedOption(modifier.id)"
        class="d-none"
      ></mat-icon>

      <mat-radio-group
        *ngIf="!disableOrdering"
        class="rs-modifier-radio-group"
        labelPosition="before"
        [(ngModel)]="selectedOption[modifier.id]"
      >
        <mat-radio-button
          *ngFor="let option of modifier.options"
          (click)="reactOnModifierClicked(option, modifier)"
          [value]="option.id"
          [checked]="
          (option.default &&
            !(modifier.id | rsIsSelectedItemModifier: metadata)) ||
          (option | rsIsSelectedItemOption: modifier.id:metadata)
        "
          class="rs-modifier-option"
        >
          {{ option.title | titlecase }}
        </mat-radio-button>
      </mat-radio-group>

      <ng-container *ngIf="disableOrdering">
        <ng-container
          *ngTemplateOutlet="
          unselectableModifierOptions;
          context: { options: modifier.options }
        "
        ></ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="modifier.type !=='cooking'">
      <ng-container
        *ngTemplateOutlet="modifierSelectors; context: { modifier: modifier }"
      ></ng-container>
    </ng-container>
  </ng-container>

<!--  <ng-container-->
<!--    *ngTemplateOutlet="modifierSelectors; context: { type: 'ingredient' }"-->
<!--  ></ng-container>-->
<!--  <ng-container-->
<!--    *ngTemplateOutlet="modifierSelectors; context: { type: 'side' }"-->
<!--  ></ng-container>-->
  <ng-template #modifierSelectors let-modifier="modifier">
      <ng-container
        *ngTemplateOutlet="modifierTitle; context: { modifier: modifier }"
      ></ng-container>
      <mat-icon
        id="clear-{{ modifier.id }}"
        (click)="clearSelectedOption(modifier.id)"
        class="d-none"
      ></mat-icon>

      <mat-radio-group
        class="rs-modifier-radio-group"
        *ngIf="!disableOrdering && modifier.maxModifiers === 1"
        labelPosition="before"
        [(ngModel)]="selectedOption[modifier.id]"
      >
        <div
          class="w-100 d-flex align-items-center justify-content-between rs-modifier-option"
          *ngFor="let option of modifier.options"
        >
          <mat-radio-button
            class="rs-modifier__option"
            [value]="option.id"
            [checked]="
              (option.default &&
                !(modifier.id | rsIsSelectedItemModifier: metadata)) ||
              (option | rsIsSelectedItemOption: modifier.id:metadata)
            "
            (click)="reactOnModifierClicked(option, modifier)"
          >
            <span class="w-100 d-flex justify-content-between">
              <span>{{ option.title | titlecase }}</span>
              <span
                class="rs-items rs-item-modifier__price"
                *ngIf="(option | rsGetModifierConfig).price > 0"
              >
                +
                {{ (option | rsGetModifierConfig).price | cents2DollarsView }}
              </span>
            </span>
          </mat-radio-button>
        </div>
      </mat-radio-group>

      <ng-container *ngIf="disableOrdering">
        <ng-container
          *ngTemplateOutlet="
            unselectableModifierOptions;
            context: { options: modifier.options }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="modifier.maxModifiers > 1">
        <div *ngFor="let option of modifier.options">
          <div
            *ngIf="option.itemModifierOption.available"
            class="d-flex align-items-center justify-content-between rs-modifier-option"
          >
            <ng-container *ngFor="let config of [option | rsGetModifierConfig]">
              <ng-container
                *ngFor="
                  let maxQuantity of [
                    modifiersControl.value
                      | rsGetMaxModifiersCount
                        : modifier
                        : config.maxRepetition
                        : option
                  ]
                "
              >
                <div class="rs-items d-flex justify-content-between flex-1">
                  <span class="d-flex rs-item-modifier__name">
                    {{ option.title }}
                    <span
                      class="d-flex rs-item-modifier__max"
                      *ngIf="!disableOrdering && !!config && maxQuantity > 0"
                    >
                      max: {{ maxQuantity }}
                    </span>
                  </span>
                  <span
                    class="rs-items rs-item-modifier__price"
                    *ngIf="(option | rsGetModifierConfig).price > 0"
                  >
                    +
                    {{
                      (option | rsGetModifierConfig).price | cents2DollarsView
                    }}
                  </span>
                </div>
                <div class="d-flex align-items-center" *ngIf="!!config">
                  <rs-counter
                    *ngIf="!disableOrdering"
                    class="ms-3"
                    [withDelete]="false"
                    [withDisabling]="true"
                    [quantity]="
                      option
                        | rsGetSelectedCountOfItemOption
                          : modifier
                          : metadata
                          : maxQuantity
                          : modifiersControl.value
                    "
                    [maxQuantity]="maxQuantity"
                    [isSmall]="true"
                    (quantityIncreased)="
                      increaseModifierQuantity(option, modifier)
                    "
                    (quantityDecreased)="
                      decreaseModifierQuantity(option, modifier)
                    "
                  ></rs-counter>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      <!-- TODO: KEEP THIS COMMENTED CODE FOR THE TIME BEING TO CHECK IF THIS WILL BE NEEDED FOR SOME TYPES OF MODIFIERS -->
      <!--    <span class="rs-checklist" *ngIf="modifier.maxModifiers > 1 && !modifier.subtype.includes('extra_ingredient')">-->
      <!--      <ul>-->
      <!--        <li *ngFor="let option of modifier.options">-->
      <!--          <mat-checkbox [value]="option.id">-->
      <!--              {{option.title}}-->
      <!--          </mat-checkbox>-->
      <!--        </li>-->
      <!--      </ul>-->
      <!--    </span>-->
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #unselectableModifierOptions let-options="options">
  <div class="rs-modifier-option" *ngFor="let option of options">
    {{ option.title | titlecase }}
  </div>
</ng-template>

<ng-template #modifierTitle let-modifier="modifier">
  <div class="d-flex mb-2 rs-title-wrapper">
    <div class="rs-title flex-grow-1 d-flex align-items-center rs-default-gap">
      {{ modifier.title }}

      <rs-badge-status
        *ngIf="!disableOrdering && modifier.itemModifier.mandatory"
      >
        {{ 'VALIDATION.required_short' | translate }}
      </rs-badge-status>

      <rs-badge-status
        rs-color="#615F5F"
        *ngIf="!disableOrdering && !modifier.itemModifier.mandatory"
      >
        {{ 'VALIDATION.optional_short' | translate }}
      </rs-badge-status>
    </div>
    <span
      class="rs-title-info"
      *ngIf="modifier.maxModifiers > 1 && !disableOrdering"
    >
      {{ 'Up to' | translate: { amount: modifier.maxModifiers } }}
    </span>
  </div>
</ng-template>
