import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { CommonOrderItemComponent } from './common-order-item.component';

import { StateItem } from '../../_interfaces/item.model';
import { CommonOrderItemBundleItemsForView } from './common-order-item.interface';

import { getBundleItemsForViewFromBundleItemsElements } from './common-order-item.utils';

@Component({
  selector: 'rs-checkout-order-item',
  standalone: true,
  imports: [SharedModule, CommonOrderItemComponent],
  templateUrl: './checkout-order-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutOrderItemComponent {
  @Input() set item(item: StateItem) {
    this.orderItem = item;
  }
  @Input() bundleItemsNames!: { [id: string]: string } | null | undefined;
  @Input() unavailableMessage: string = '';

  public bundleItems: CommonOrderItemBundleItemsForView[] = [];
  public orderItem!: StateItem;

  @Output() clickedOnItem: EventEmitter<void> = new EventEmitter();

  public ngOnChanges(changes: SimpleChanges): void {
    changes['item'].firstChange &&
      (this.bundleItems = getBundleItemsForViewFromBundleItemsElements(
        this.orderItem?.bundleItems,
        this.bundleItemsNames
      ));
  }

  public onClick(): void {
    this.clickedOnItem.next();
  }
}
