import { Component } from '@angular/core';
import { SharedModule } from 'src/app/_shared/_modules/shared.module';

@Component({
  selector: 'rs-dialog-actions',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './dialog-actions.component.html',
})
export class RsDialogActionsComponent {}
