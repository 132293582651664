import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { NotifierService } from 'angular-notifier';

enum NotificationType {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private notifierService: NotifierService,
    private translateService: TranslateService
  ) {}

  public showSuccess(text: string = ''): void {
    this.notifierService.notify(
      NotificationType.success,
      this.getTranslatedText(text)
    );
  }

  public showError(text: string, prefix: string = ''): void {
    this.notifierService.notify(
      NotificationType.error,
      `${prefix} ${this.getTranslatedText(text)}`
    );
  }

  public showWarn(text: string): void {
    this.notifierService.notify(
      NotificationType.warning,
      this.getTranslatedText(text)
    );
  }

  public hideAll(): void {
    this.notifierService.hideAll();
  }

  private getTranslatedText(text: string): string {
    const translation = this.translateService.instant(text);

    return translation !== text && translation !== '' ? translation : text;
  }
}
