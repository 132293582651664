export enum TaxRules {
  default = 'default',
  taxFree = 'tax-free',
}

export enum ServingOption {
  Split = 'split',
  Share = 'share',
  Passed = 'passed',
  None = 'none',
}

export enum BundleType {
  PrixFixe = 'prix_fixe',
  Combo = 'combo',
  MulticategoryCombo = 'multicategory_combo',
  Sushi = 'sushi',
  MultiflavorPizza = 'multiflavor_pizza',
}
