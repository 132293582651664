import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rsMaskError' })
export class MaskErrorPipe implements PipeTransform {
  transform(errors: Object, errorObject: any): string {
    const errorKey = Object.keys(errors)[0];
    const errorString: string = errorObject[errorKey];

    return errorString;
  }
}
