import { Pipe, PipeTransform } from '@angular/core';

import { isEqual, cloneDeep } from 'lodash';

import { Item } from '../_interfaces/item.model';

interface ItemForItemsString {
  itemId: string;
  name: string;
  description: string;
  quantity?: number;
}

@Pipe({ name: 'itemListAsString' })
export class ItemListAsStringPipe implements PipeTransform {
  transform(items: Item[]): string {
    const uniqItems: ItemForItemsString[] = this.getEqualItems(items);
    const resultString = this.getStringFromUniqItems(uniqItems);

    return resultString;
  }

  private getEqualItems(items: Item[]): ItemForItemsString[] {
    const transformed: ItemForItemsString[] = items.map(item => ({
      itemId: item.itemId,
      name: item.name,
      description: item.description,
    }));
    let uniqItems: ItemForItemsString[] = [];

    transformed.forEach(item => {
      const uniqItemIndex = uniqItems.findIndex(uniqItem => {
        let uniqItemCopy = cloneDeep(uniqItem);
        delete uniqItemCopy.quantity;
        return isEqual(uniqItemCopy, item);
      });
      const quantity = uniqItems[uniqItemIndex]?.quantity;

      if (uniqItemIndex === -1) {
        uniqItems.push({
          ...item,
          quantity: 1,
        });
      } else if (quantity) {
        uniqItems[uniqItemIndex].quantity = quantity + 1;
      }
    });

    return uniqItems;
  }

  private getStringFromUniqItems(uniqItems: ItemForItemsString[]): string {
    const lastUniqItemIndex = uniqItems.length - 1;
    let resultString = '';

    uniqItems.forEach((item, index) => {
      resultString += `${item.quantity}x ${item.name}`;
      resultString += item.description ? ` (${item.description})` : '';
      resultString += index === lastUniqItemIndex ? '' : ', ';
    });

    return resultString;
  }
}
