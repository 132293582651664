<section class="rs-qr-scanner__wrapper">
  <ng-container *ngIf="activateCameraScanner">
    <zxing-scanner
      [enable]="enableScanner"
      [device]="desiredDevice"
      [formats]="[allowedFormats]"
      (camerasFound)="camerasFoundHandler($event)"
      (camerasNotFound)="disableScan()"
      (scanSuccess)="scanSuccessHandler($event)"
    ></zxing-scanner>
  </ng-container>
</section>
