import { Component, Input } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { SharedModule } from '../../shared.module';
import { RsBannerAlertComponent } from 'src/app/_shared/_rs-design/banner/alert/banner-alert.component';
import { GetOrderFullAddressPipe } from 'src/app/_shared/_pipes/address.pipe';
import { LocationComponent } from '../../../_components/location/location.component';

import { OrderDataState } from 'src/app/_shared/_ngxs/order-data.state';
import { OpenEditCheckoutInfoDialog } from 'src/app/_shared/_ngxs/dialog.actions';

import { DeliveryMethod } from 'src/app/_shared/_enums/order.enum';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { TIME_WITH_ASAP_TRANSLATIONS_PATHS } from 'src/app/_shared/_constants/paths-to-translations.constant';

import { SvgIconService } from 'src/app/_services/svg-icon.service';

@Component({
  selector: 'rs-order-type-info',
  standalone: true,
  imports: [
    SharedModule,
    RsBannerAlertComponent,
    LocationComponent,
    GetOrderFullAddressPipe,
  ],
  templateUrl: './order-type-info.component.html',
  styleUrls: ['./order-type-info.component.scss'],
})
export class OrderTypeInfoComponent {
  @Input() isCheckout!: boolean;

  public readonly orderMethod$: Observable<DeliveryMethod> = this.store.select(
    OrderDataState.orderMethod
  );
  public readonly orderAddress$: Observable<UserProfileAddress | undefined> =
    this.store.select(OrderDataState.orderAddress);
  public readonly time$: Observable<string> = this.store
    .select(OrderDataState.selectedOrderPeriod)
    .pipe(map(selectedOrderPeriod => selectedOrderPeriod.time));

  public readonly translationsPaths = TIME_WITH_ASAP_TRANSLATIONS_PATHS;
  public readonly deliveryMethod = DeliveryMethod.Delivery;
  public readonly bookmarkIcon = CommonIcons.Bookmark;
  public readonly clockIcon = CommonIcons.Clock;
  public readonly editIcon = CommonIcons.Edit;

  constructor(
    private readonly store: Store,
    private readonly svgService: SvgIconService
  ) {
    this.registerIcons();
  }

  public openScheduleDialog(): void {
    this.store.dispatch(new OpenEditCheckoutInfoDialog(this.isCheckout));
  }

  private registerIcons(): void {
    this.svgService.registerSvgIcons(
      [this.bookmarkIcon, this.clockIcon, this.editIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
