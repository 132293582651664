import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable, filter, map, tap } from 'rxjs';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsAllergenPipesModule } from 'src/app/_shared/_modules/allergen-pipes.module';
import { TooltipComponent } from '../../../../_rs-design/tooltip/tooltip/tooltip.component';

import { ItemState } from 'src/app/_shared/_ngxs/item.state';
import { LanguageState } from 'src/app/_shared/_ngxs/language.state';
import { AllergensState } from 'src/app/_shared/_ngxs/allergens.state';

import { Allergen } from 'src/app/_shared/_interfaces/item.model';

import {
  DIGITAL_STOREFRONT_ALLERGEN_ICON_DIRECTORY,
  DIGITAL_STOREFRONT_ALLERGEN_ICON_PREFIX,
} from 'src/app/_shared/_constants/allergens.constants';

import {
  getCustomAllergens,
  getDefaultAllergens,
  getDigitalStoreFrontAllergenIconRealName,
} from 'src/app/_shared/_utils/allergens';
import { SvgIconService } from 'src/app/_services/svg-icon.service';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';

@Component({
  selector: 'rs-item-allergens',
  standalone: true,
  imports: [SharedModule, RsAllergenPipesModule, TooltipComponent],
  styleUrls: ['./item-allergens.component.scss'],
  templateUrl: './item-allergens.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemAllergensComponent {
  @Input() showOnlyOneLine!: boolean;
  @Input() small20pxIcons!: boolean;

  public readonly itemName$: Observable<string> = this.store
    .select(ItemState.item)
    .pipe(
      map(item =>
        item
          ? item?.alcoholicAttributes?.string === 'wine'
            ? item.alcoholicAttributes?.style +
              ', ' +
              item.alcoholicAttributes?.vintage
            : item.title
          : ''
      ),
      tap(itemName => (this.itemName = itemName))
    );
  private readonly itemAllergens$: Observable<Allergen[] | null> =
    this.store.select(ItemState.allergens);
  public readonly language$ = this.store.select(LanguageState.language);

  public allergens!: Allergen[];
  public customAllergens!: Allergen[];
  public defaultAllergens!: Allergen[];
  public itemName!: string;

  private defaultAllergensList: Allergen[] = this.store.selectSnapshot(
    AllergensState.defaultAllergens
  );

  private readonly destroy$ = untilDestroyed();

  constructor(
    protected readonly store: Store,
    protected readonly svgIconService: SvgIconService,
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.itemAllergens$
      .pipe(
        this.destroy$(),
        filter(allergens => !!allergens?.length)
      )
      .subscribe(
        itemAllergens => itemAllergens && this.formAllergens(itemAllergens)
      );
  }

  protected formAllergens(itemAllergens: Allergen[]): void {
    const allergens: Allergen[] =
      (itemAllergens?.length && itemAllergens) || [];

    this.registerAllergenIcons(allergens);

    this.defaultAllergens = getDefaultAllergens(
      allergens,
      this.defaultAllergensList
    );
    this.customAllergens = getCustomAllergens(
      allergens,
      this.defaultAllergensList
    );

    this.allergens = allergens;

    this.changeDetectorRef.detectChanges();
  }

  protected registerAllergenIcons(allergens: Allergen[]): void {
    const iconsName: string[] = allergens.map(({ value }) =>
      getDigitalStoreFrontAllergenIconRealName(value)
    );

    iconsName?.length &&
      this.svgIconService.registerSvgIcons(
        iconsName,
        DIGITAL_STOREFRONT_ALLERGEN_ICON_DIRECTORY,
        DIGITAL_STOREFRONT_ALLERGEN_ICON_PREFIX
      );
  }
}
