import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  BackgroundImagePipe,
  GetDefaultImageUrlFromResolutionsPipe,
} from '../_pipes/background-image.pipe';
import {
  CentsToDollarsPipe,
  CentsToDollarsViewPipe,
} from '../_pipes/cents-to-dollars.pipe';
import { FullTextSearchPipe } from '../_pipes/full-text-search.pipe';
import { IsEmptyObjectPipe } from '../_pipes/is-empty-object.pipe';
import {
  ItemCounterPipe,
  RearrangeItemsPipe,
  ItemFullPrice,
  UniqueItemModifiersNames,
  GetModifierConfigPipe,
  GetMaxModifiersCount,
  ItemPriceWithBundleItemsPipe,
} from '../_pipes/menu-item';
import { IsObjectInArrayPipe } from '../_pipes/is-object-in-array.pipe';
import {
  OrderItemStatusTitlePipe,
  OrderItemStatusColorPipe,
  OrderItemPricePipe,
  GetItemsNamesFromOrderPipe,
  GetUniqItemsByNamePipe,
  GetUniqItemsByNameForListOfOrdersPipe,
  FilterItemsByNamePipe,
  GetItemsWithUniqModifiersPipe,
} from '../_pipes/order-item.pipe';
import {
  ItemsNamesPipe,
  UnavailableItemsFromSectionPipe,
} from '../_pipes/unavailable-items.pipe';
import {
  FormateDateTimeStringPipe,
  TransformDeliveryDatePipe,
} from '../_pipes/formate-date-time-string.pipe';
import { CalculateRemainingTotalAfterGiftCardsPipe } from '../_pipes/calc-tips.pipe';
import { DaysAgoPipe, MomentPipe } from '../_pipes/moment.pipe';
import { FullNamePipe, InitialsPipe } from '../_pipes/name.pipe';
import {
  GetPriceFromSizesAndPricesPipe,
  ItemsWithPricePipe,
  LowestPricePipe,
  SizesWithPricesPipe,
} from '../_pipes/items-with-price.pipe';
import {
  AddressWithoutCommas,
  FullAddressPipe,
  RearrangeAddressesListPipe,
  GetUSAddressesPipe,
} from '../_pipes/address.pipe';
import {
  CardLastFourDigitsPipe,
  ShortStarsCardPipe,
  StarsCardPipe,
} from '../_pipes/card.pipe';
import { FilterFavoriteOrdersPipe } from '../_pipes/filter-favorite-orders.pipe';
import { ConvertItemsToGalleryItemsPipe } from '../_pipes/gallery-items-coverted.pipe';
import { ToCDNPipe, ToNoResPipe } from '../_pipes/src-to-cdn.pipe';
import { PhonePipe } from '../_pipes/phone.pipe';
import { TimeAgoPipe } from '../_pipes/time-ago.pipe';
import { CardImageByBrandPipe } from '../_pipes/card-image.pipe';
import {
  CartItemsAmountPipe,
  ModifierTitleWithAmount,
} from '../_pipes/cart.pipes';
import { IsFavoriteItemPipe } from '../_pipes/is-favorite-item.pipe';
import { GiftCarNumberMaskPipe } from '../_pipes/gift-card-number-mask.pipe';
import { GetPageVisibilityFromConfig } from '../_pipes/config.pipe';
import {
  GetSelectedCountOfItemOptionPipe,
  IsSelectedItemModifierPipe,
  IsSelectedItemOptionPipe,
} from '../_pipes/metadata.pipe';
import { ETATimePipe } from '../_pipes/eta-time.pipe';
import {
  BundleItemNumberPipe,
  GetBundleItemMaximumPipe,
  GetBundleItemPricePipe,
  GetBundleItemsAmountPipe,
  GetBundleItemsCountPipe,
  GetBundleItemSizeAttributePipe,
  GetBundleSectionSizePipe,
  GetBundleTypePipe,
  GetComboAttributesPipe,
  GetComboItemMaximumPipe,
  GetComboItemSizeAttributesPipe,
  GetMaxAmountOfItemInComboPipe,
  IsBundleItemPipe,
  isBundleItemRepeatedPipe,
  UniqueBundleItemsPipe,
} from '../_pipes/bundle.pipe';
import { SplitBySymbolPipe } from '../_pipes/common.pipe';
import {
  OrderEntriesPipe,
  OrderItemsToItemsInListPipe,
  IsSelectedItem,
  AddItemsAvailabilityPipe,
} from '../_pipes/item-list.pipe';
import {
  IsAvailableMenuForCurrentOrderTypePipe,
  IsAvailableMenuForSelectedTimePipe,
  IsAvailableMenuItemForSelectedTimePipe,
  MenuAvailabilityPipe,
} from '../_pipes/menu.pipe';
import {
  RsCheckIfTodayHasDeliveryPipe,
  RsFormulateOrderTimePipe,
} from '../_pipes/date-time.pipe';
import { LocalizedDatePipe } from '../_pipes/locolized-date.pipe';
import {
  TranslateByPathsInFile,
  TranslateDaysOfWeekPipe,
  TranslateFormatedDateTimeStringPipe,
  TranslateIfExistsPipe,
  TranslatedTimeAgoPipe,
} from '../_pipes/translations.pipe';
import { UppercaseForFirstSymbolPipe } from '../_pipes/uppercase-for-first-symbol.pipe';
import { ItemListAsStringPipe } from '../_pipes/items-list-as-string.pipe';
import { ItemCategoryToImagePlaceholderPipe } from '../_pipes/item-category-to-image-placeholder.pipe';
import { GetRouterSegments } from '../_pipes/router-segments.pipe';
import { RsComposeOrderDatePipe } from '../_pipes/compose-order-date.pipe';
import { EasternArabicNumeralsPipe } from '../_pipes/eastern-arabic-numerals.pipe';
import { IsProfileMenuItemSelectedPipe } from '../_pipes/profile.pipe';
import { GetArrayFromNumberToIteratePipe } from '../_pipes/ng-for-helper.pipe';

const pipes = [
  CentsToDollarsPipe,
  CentsToDollarsViewPipe,
  OrderItemStatusTitlePipe,
  OrderItemStatusColorPipe,
  OrderItemPricePipe,
  ItemCounterPipe,
  RearrangeItemsPipe,
  FullTextSearchPipe,
  ItemFullPrice,
  UniqueItemModifiersNames,
  GetModifierConfigPipe,
  GetMaxModifiersCount,
  BackgroundImagePipe,
  GetDefaultImageUrlFromResolutionsPipe,
  IsEmptyObjectPipe,
  UnavailableItemsFromSectionPipe,
  ItemsNamesPipe,
  IsObjectInArrayPipe,
  FormateDateTimeStringPipe,
  TransformDeliveryDatePipe,
  CalculateRemainingTotalAfterGiftCardsPipe,
  MomentPipe,
  DaysAgoPipe,
  InitialsPipe,
  FullNamePipe,
  ItemsWithPricePipe,
  LowestPricePipe,
  SizesWithPricesPipe,
  GetPriceFromSizesAndPricesPipe,
  AddressWithoutCommas,
  FullAddressPipe,
  StarsCardPipe,
  ShortStarsCardPipe,
  CardLastFourDigitsPipe,
  FilterFavoriteOrdersPipe,
  GetItemsNamesFromOrderPipe,
  FilterItemsByNamePipe,
  GetUniqItemsByNamePipe,
  GetUniqItemsByNameForListOfOrdersPipe,
  ConvertItemsToGalleryItemsPipe,
  ToCDNPipe,
  ToNoResPipe,
  PhonePipe,
  TimeAgoPipe,
  CardImageByBrandPipe,
  CartItemsAmountPipe,
  ModifierTitleWithAmount,
  IsFavoriteItemPipe,
  GiftCarNumberMaskPipe,
  GetItemsWithUniqModifiersPipe,
  GetPageVisibilityFromConfig,
  IsSelectedItemModifierPipe,
  IsSelectedItemOptionPipe,
  GetSelectedCountOfItemOptionPipe,
  ETATimePipe,
  IsBundleItemPipe,
  GetBundleTypePipe,
  GetBundleItemPricePipe,
  GetBundleItemMaximumPipe,
  GetComboItemMaximumPipe,
  GetBundleSectionSizePipe,
  GetBundleItemSizeAttributePipe,
  GetBundleItemsCountPipe,
  ItemPriceWithBundleItemsPipe,
  isBundleItemRepeatedPipe,
  BundleItemNumberPipe,
  UniqueBundleItemsPipe,
  GetBundleItemsAmountPipe,
  SplitBySymbolPipe,
  GetComboItemSizeAttributesPipe,
  GetComboAttributesPipe,
  GetMaxAmountOfItemInComboPipe,
  OrderEntriesPipe,
  OrderItemsToItemsInListPipe,
  AddItemsAvailabilityPipe,
  IsSelectedItem,
  IsAvailableMenuForCurrentOrderTypePipe,
  IsAvailableMenuForSelectedTimePipe,
  IsAvailableMenuItemForSelectedTimePipe,
  MenuAvailabilityPipe,
  LocalizedDatePipe,
  TranslateDaysOfWeekPipe,
  TranslateByPathsInFile,
  UppercaseForFirstSymbolPipe,
  TranslatedTimeAgoPipe,
  TranslateFormatedDateTimeStringPipe,
  TranslateIfExistsPipe,
  ItemListAsStringPipe,
  RsFormulateOrderTimePipe,
  RsCheckIfTodayHasDeliveryPipe,
  RearrangeAddressesListPipe,
  GetUSAddressesPipe,
  ItemCategoryToImagePlaceholderPipe,
  GetRouterSegments,
  RsComposeOrderDatePipe,
  EasternArabicNumeralsPipe,
  IsProfileMenuItemSelectedPipe,
  GetArrayFromNumberToIteratePipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
  providers: [
    CurrencyPipe,
    CentsToDollarsViewPipe,
    EasternArabicNumeralsPipe,
    GetUSAddressesPipe,
  ],
})
export class RsPipesModule {}
