<div class="rs-wine-details d-flex flex-column">
  <div class="rs-wine-details__item">
    <rs-wine-details-item
      from="Light"
      to="Bold"
      [items]="bodyItems"
      [value]="body"
    ></rs-wine-details-item>
  </div>
  <div class="rs-wine-details__item">
    <rs-wine-details-item
      from="Smooth"
      to="Tannic"
      [items]="taninItems"
      [value]="tanin"
    ></rs-wine-details-item>
  </div>
  <div class="rs-wine-details__item">
    <rs-wine-details-item
      from="Dry"
      to="Sweet"
      [items]="sweetnessItems"
      [value]="sweetness"
    ></rs-wine-details-item>
  </div>
  <div class="rs-wine-details__item">
    <rs-wine-details-item
      from="Soft"
      to="Acidic"
      [items]="acidityItems"
      [value]="acidity"
    ></rs-wine-details-item>
  </div>
</div>
