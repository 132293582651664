import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RsListItemSegmentActionComponent,
  RsListItemSegmentComponent,
  RsListItemSegmentHintComponent,
  RsListItemSegmentLabelComponent,
} from './list-item.component';

const components = [
  RsListItemSegmentActionComponent,
  RsListItemSegmentComponent,
  RsListItemSegmentHintComponent,
  RsListItemSegmentLabelComponent,
];

@NgModule({
  imports: [CommonModule, ...components],
  exports: [...components],
})
export class ListItemSegmentModule {}
