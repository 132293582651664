import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { Store } from '@ngxs/store';
import { first, Observable } from 'rxjs';
import {
  Modifier,
  ModifierOption,
  SelectedModifier,
} from 'src/app/_shared/_interfaces/item.model';

import { ItemState } from '../../../_ngxs/item.state';

@Component({
  selector: 'rs-item-modifiers',
  templateUrl: './item-modifiers.component.html',
  styleUrls: ['../_styles/item-details.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemModifiersComponent {
  @Input() modifiersControl: FormControl = new FormControl([]);
  @Input() metadata!: any;
  @Input() disableOrdering = false;

  public readonly modifiers$: Observable<Modifier[]> = this.store.select(
    ItemState.modifiers
  );

  @Output() modifierSelected: EventEmitter<ModifierOption> = new EventEmitter();

  constructor(
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setDefaultSelectedModifiers();
  }

  public selectedOption: any = {};

  public clearSelectedOption(modifierId: string): void {
    this.selectedOption[modifierId] = null;
  }

  public reactOnModifierClicked(
    option: ModifierOption,
    modifier: Modifier
  ): void {
    const isModifierSelected = this.modifiersControl.value.some(
      (modifierValue: SelectedModifier) =>
        modifierValue.modifierId === modifier.id &&
        modifierValue.selectedOptionId === option.id
    );

    if (isModifierSelected && !modifier.itemModifier.mandatory) {
      this.clearModifierSelection(modifier.id);
    } else {
      this.updateModifierSelection(option, modifier);
    }

    this.changeDetectorRef.detectChanges();
  }

  private clearModifierSelection(modifierId: string): void {
    this.modifiersControl.setValue(
      this.modifiersControl.value.filter(
        (modifierValue: SelectedModifier) =>
          modifierValue.modifierId !== modifierId
      )
    );
    setTimeout(() => {
      document.getElementById(`clear-${modifierId}`)?.click();
    }, 50);
  }

  private updateModifierSelection(
    option: ModifierOption,
    modifier: Modifier
  ): void {
    this.selectedOption[modifier.id] = option.id;

    const selectedModifiers = this.modifiersControl.value.filter(
      (modifierValue: SelectedModifier) =>
        modifierValue.modifierId !== modifier.id
    );

    this.modifiersControl.setValue([
      ...selectedModifiers,
      {
        modifierTitle: modifier.title,
        modifierId: modifier.id,
        selectedOptionTitle: option.title,
        selectedOptionId: option.id,
        mandatory: modifier.itemModifier.mandatory,
      },
    ]);

    this.modifierSelected.emit(option);
  }

  public reactOnModifierSelected(
    option: ModifierOption,
    modifier: Modifier
  ): void {
    this.modifierSelected.emit(option);

    const selectedModifiers: SelectedModifier[] =
      this.modifiersControl.value.filter(
        (modifierValue: SelectedModifier) =>
          modifierValue.modifierId !== modifier.id
      );

    this.modifiersControl.reset([
      ...selectedModifiers,
      {
        modifierTitle: modifier.title,
        modifierId: modifier.id,
        selectedOptionTitle: option.title,
        selectedOptionId: option.id,
        mandatory: modifier.itemModifier.mandatory,
      },
    ]);

    this.changeDetectorRef.detectChanges();
  }

  public reactOnModifiersSelected(
    selected: { option: ModifierOption; modifier: Modifier }[]
  ): void {
    const selectedModifiers: SelectedModifier[] = [];
    let notSelectedModifiers: SelectedModifier[] = this.modifiersControl.value;

    selected.forEach(({ option, modifier }) => {
      notSelectedModifiers = this.modifiersControl.value.filter(
        (modifierValue: SelectedModifier) =>
          modifierValue.modifierId !== modifier.id
      );
      selectedModifiers.push({
        modifierTitle: modifier.title,
        modifierId: modifier.id,
        selectedOptionTitle: option.title,
        selectedOptionId: option.id,
        mandatory: modifier.itemModifier.mandatory,
      });
    });

    this.modifiersControl.reset([
      ...notSelectedModifiers,
      ...selectedModifiers,
    ]);

    this.changeDetectorRef.detectChanges();
  }

  public increaseModifierQuantity(
    option: ModifierOption,
    modifier: Modifier
  ): void {
    const currentModifiers = this.modifiersControl.value;

    this.modifiersControl.reset([
      ...currentModifiers,
      {
        modifierTitle: modifier.title,
        modifierId: modifier.id,
        selectedOptionTitle: option.title,
        selectedOptionId: option.id,
      },
    ]);
  }

  public decreaseModifierQuantity(
    option: ModifierOption,
    modifier: Modifier
  ): void {
    const currentModifiers = this.modifiersControl.value as any[];
    const decreasedIndex = currentModifiers.findIndex(
      item =>
        item.modifierId === modifier.id && item.selectedOptionId === option.id
    );

    if (decreasedIndex < 0) {
      return;
    }

    this.modifiersControl.reset([
      ...currentModifiers.filter((_, index) => index !== decreasedIndex),
    ]);
  }

  private setDefaultSelectedModifiers(): void {
    this.modifiers$
      .pipe(first(modifiers => !!modifiers?.length))
      .subscribe(modifiers => {
        // we don't care about metadata for food which is not pizza
        (modifiers as Modifier[]).forEach(modifier =>
          modifier.options.forEach(
            (option: ModifierOption) =>
              !!(option.default && option.itemModifierOption.available) &&
              this.updateModifierSelection(option, modifier)
          )
        );
      });
  }
}
