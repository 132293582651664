export enum ExistingRoutes {
  AboutUs = 'about-us',
  Menu = 'menu',
  DigitalOrdering = 'digital-ordering',
  OrderHistory = 'order-history',
  News = 'news',
  Gallery = 'gallery',
  Hours = 'hours',
  Catering = 'catering',
  Press = 'press',
  GiftCard = 'gift-card',
  ProfileMain = 'profile-main',
  ProfileGiftCards = 'profile-gift-cards',
  Password = 'profile-password',
  ProfileOrders = 'profile-orders',
  ProfileFavorites = 'profile-favorites',
  ProfileAddress = 'profile-address',
  ProfileWallet = 'profile-wallet',
  ProfileReservation = 'profile-reservations',
  LogOut = 'profile-log-out',
  Home = 'home',
  OrderSuccess = 'order-success',
  AddGuestToYourTable = 'table-number',
  Welcome = 'welcome',
}

export interface BaseRoutes {
  path: string;
  title: string;
  name: ExistingRoutes;
  hide?: boolean;
}

export interface MenuRoute extends BaseRoutes {
  icon: string;
  withDelimiter?: boolean;
  visibilityKey?: string;
}
