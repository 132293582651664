import moment from 'moment';
import { cloneDeep, flatMap, sortBy } from 'lodash';

import { OperatingHours } from '../_models/common.interface';
import {
  ScheduleRange,
  ScheduleSettings,
} from 'src/app/_shared/_interfaces/date-settings';
import {
  ASAPTimeShouldBeAdded,
  WEEK_DAYS,
  changePeriodInAvailablePeriodsToASAPTime,
  createArrayOfTimes,
} from '../_constants/date';

export const getMappedOpeningHours = (
  openingHours: OperatingHours[]
): OperatingHours[] => {
  const mappedOperatingHours: OperatingHours[] = cloneDeep(openingHours)?.map(
    (openingHour: OperatingHours) => {
      const { from, to } = openingHour;
      const newDayOfTheWeek: number =
        openingHour.dayOfTheWeek === 0 ? 7 : openingHour.dayOfTheWeek;

      return {
        ...openingHour,
        from: meridianTime(from),
        to: meridianTime(to),
        dayOfTheWeek: newDayOfTheWeek,
        weekDay: WEEK_DAYS[newDayOfTheWeek],
      };
    }
  );
  const sortedOperatingHours: OperatingHours[] = sortBy(mappedOperatingHours, [
    'dayOfTheWeek',
  ]);

  return sortedOperatingHours;
};

export const meridianTime = (time: string, locale: string = 'en'): string =>
  moment(time, 'HH:mm').locale(locale).format('hh:mm A');

export const getAvailableOrderTimeList = (
  selectedDay: ScheduleRange,
  scheduleSettings: ScheduleSettings
): string[] => {
  const { asapMinTime, asapMaxTime, rangeStep } = scheduleSettings;
  let timeList =
    flatMap(
      selectedDay?.periods.map(period =>
        createArrayOfTimes(
          {
            from: period.from,
            to: period.to,
          },
          rangeStep,
          selectedDay.day
        )
      )
    ) || [];
  const isSelectedDayToday: boolean =
    moment(selectedDay?.day).date() === moment().date();

  return isSelectedDayToday &&
    ASAPTimeShouldBeAdded(timeList, asapMinTime, asapMaxTime)
    ? changePeriodInAvailablePeriodsToASAPTime(
        timeList,
        asapMinTime,
        asapMaxTime
      )
    : timeList;
};
