import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

// Note: use it while localhost has CORS issue
// WARNING: do not commit changes here! use ONLY locally
// import { of } from 'rxjs';
// import tonyAndBBQConfig from '../_mock/tony-and-bbq.mock';
// import automatedQASTGConfig from '../_mock/automated-qa-stg.mock';

const cdnUrl = environment.cdnUrl;

@Injectable({ providedIn: 'root' })
export class VenueConfigService {
  constructor(private http: HttpClient) {}

  getVenueConfig(id: any) {
    return this.http.get(`${cdnUrl}/${id}.json`);

    // Automated QA config http://localhost:4200/automated-qa-stg/order
    // return of(automatedQASTGConfig);
    // tony and bbq config http://localhost:4200/tonys-bbq--grill/order
    // return of(tonyAndBBQConfig);
  }
}
