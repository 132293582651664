import { NgModule } from '@angular/core';

import { OutsideClickDirective } from './outside-click-handler.directive';
import { PreventNegativeNumber } from './prevent-negative-number.directive';
import { RepeatHtmlElementTimesDirective } from './repeat-html-element-times.directive';
import { ImageLoaderDirective } from './image-loader.directive';
import { ScrollIntoViewDirective } from './scrollIntoView.directive';

const directives = [
  PreventNegativeNumber,
  OutsideClickDirective,
  RepeatHtmlElementTimesDirective,
  ImageLoaderDirective,
  ScrollIntoViewDirective,
];

@NgModule({
  declarations: directives,
  exports: directives,
})
export class DirectivesModule {}
