import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';

import { SvgIconService } from 'src/app/_services/svg-icon.service';

import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { isStringTrue } from 'src/app/_shared/_utils/common';

const matIconTagName = 'mat-icon';

@Component({
  selector: 'rs-default-dialog-header',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './default-dialog-header.component.html',
})
export class RsDefaultDialogHeaderComponent {
  @Input('rs-show-close-button') set showCloseButton(showCloseButton: string) {
    this.isCloseButton = isStringTrue(showCloseButton);
  }
  @Input() dialogHeaderHasBorder!: boolean;

  @ViewChild('rsDialogHeader', { static: false }) dialogHeader:
    | ElementRef<any>
    | undefined;

  public isCloseButton: boolean = false;
  public isOnlyIcon: boolean = false;
  public isOnlyText: boolean = false;
  public hasIconFirst: boolean = false;
  public readonly clearIcon = CommonIcons.Close;

  constructor(
    private readonly svgIconService: SvgIconService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.registerIcons();
  }

  ngAfterViewInit(): void {
    this.detectHeaderDetails();
    this.changeDetectorRef.detectChanges();
  }

  private detectHeaderDetails(): void {
    const dialogHeaderChildren = this.dialogHeader?.nativeElement.children;

    this.hasIconFirst =
      dialogHeaderChildren[0]?.nodeName.toLowerCase() === matIconTagName;
    this.isOnlyIcon = this.hasIconFirst && dialogHeaderChildren?.length === 1;
    this.isOnlyText = !this.hasIconFirst && dialogHeaderChildren?.length === 1;
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons([this.clearIcon], NEW_ICONS_DIRECTORY);
  }
}
