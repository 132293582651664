import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

@Pipe({ name: 'rsMoment' })
export class MomentPipe implements PipeTransform {
  transform(value: string, format: string = DEFAULT_DATE_FORMAT): string {
    return moment(value).utc().format(format);
  }
}
@Pipe({ name: 'rsDaysAgo' })
export class DaysAgoPipe implements PipeTransform {
  transform(date: string): string {
    if (!date) {
      return '';
    }
    const now = moment();

    const months = now.diff(date, 'months');
    if (months < 12 && months > 0) {
      return months + ' month(s) ago';
    } else if (months === 0) {
      const days = now.diff(date, 'days');
      if (days === 0) {
        return 'today';
      } else if (days < 7) {
        return days + ' day(s) ago';
      } else {
        const weeks = now.diff(date, 'weeks');
        return weeks + ' week(s) ago';
      }
    }
    const years = now.diff(date, 'years');
    return years + ' year(s) ago';
  }
}
