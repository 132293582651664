<ng-template [ngIf]="orderMethod$ | async" let-orderMethod>
  <ng-container *ngFor="let time of [time$ | async]">
    <ng-container *ngFor="let address of [orderAddress$ | async]">
      <ng-container *ngFor="let isDelivery of [orderMethod === deliveryMethod]">
        <div class="rs-order-type" [dir]="'languageDirection' | translate">
          <div class="rs-order-type__text">
            {{ 'DELIVERY_METHOD.' + orderMethod + '_details' | translate }}
          </div>

          <div class="mt-2" *ngIf="isDelivery && (!address || !time)">
            <rs-banner-alert rs-status="error" rs-rounded="true">
              {{ 'MENUS.incomplete_order_details' | translate }}
            </rs-banner-alert>
          </div>

          <div
            class="rs-order-type__item"
            [dir]="'languageDirection' | translate"
          >
            <div class="rs-order-type__datetime">
              <mat-icon
                [svgIcon]="clockIcon"
                class="rs-order-type__item-icon"
              ></mat-icon>
              <span
                class="rs-order-type__text"
                [class.rs-order-type__empty]="!time"
              >
                {{
                  (time
                    | uppercaseForFirstSymbol
                    | translateByPathsInFile: translationsPaths
                    | async
                    | rsEasternArabicNumerals) || 'MENUS.N/A' | translate
                }}
              </span>
            </div>
            <button
              rs-style="text"
              class="cursor-pointer"
              (click)="openScheduleDialog()"
            >
              <mat-icon [svgIcon]="editIcon"></mat-icon>
              <span>{{ 'DELIVERY_METHOD.edit' | translate | uppercase }}</span>
            </button>
          </div>

          <ng-template [ngIf]="isDelivery">
            <rs-location
              [description]="address | rsOrderFullAddress"
              [text]="address?.name!"
            ></rs-location>

            <div class="rs-order-type__item">
              <div class="rs-order-type__location">
                <mat-icon
                  [svgIcon]="bookmarkIcon"
                  class="rs-order-type__item-icon"
                ></mat-icon>
                <div class="rs-order-type__empty">
                  <ng-container
                    *ngIf="address?.instructions; else rsNoInstructions"
                  >
                    {{ address?.instructions }}
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #rsNoInstructions>
  {{ 'DELIVERY_METHOD.No delivery instructions' | translate }}
</ng-template>
