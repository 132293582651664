<form [dir]="'languageDirection' | translate">
  <rs-country-select
    *ngIf="!hideCountry"
    [rsCountryControl]="$any(rsAddressForm.controls).country"
  ></rs-country-select>
  <rs-input
    *ngIf="isAddressDetailsVisible && !hideNickName"
    [placeholder]="'PROFILE.payment.address_nickname' | translate"
    [label]="'PROFILE.payment.address_nickname' | translate"
    [control]="$any(rsAddressForm.controls).name"
    [iconType]="locationIcon"
  >
  </rs-input>
  <rs-address-autocomplete
    [id]="id.addressAutocomplete.addressAutocomplete"
    [selectedCountryCode]="
      hideCountry ? 'US' : $any(rsAddressForm.controls).country.value
    "
    placeholder="{{ address1Placeholder | translate }}"
    [focusOnInput]="focusOnInput"
    [rsFormControl]="$any(rsAddressForm.controls).address1"
    [showLocationIcon]="showLocationIcon"
    (addressDetails)="reactOnAddressDetailsFound($event)"
    (clearInput)="clearAutoCompleteField()"
    [iconType]="locationIcon"
  ></rs-address-autocomplete>
  <ng-container *ngIf="isAddressDetailsVisible">
    <rs-input
      [control]="$any(rsAddressForm.controls).address2"
      [placeholder]="address2Placeholder | translate"
      [label]="address2Placeholder | translate"
    >
    </rs-input>

    <div class="row">
      <div class="col-6">
        <rs-input
          [control]="$any(rsAddressForm.controls).city"
          [disabled]="true"
          [placeholder]="'PROFILE.payment.city' | translate"
          [label]="'PROFILE.payment.city' | translate"
        >
        </rs-input>
      </div>

      <div class="col-6">
        <rs-input
          [control]="$any(rsAddressForm.controls).zipcode"
          [disabled]="true"
          [placeholder]="'PROFILE.payment.zip_code' | translate"
          [label]="'PROFILE.payment.zip_code' | translate"
        >
        </rs-input>
      </div>
    </div>
    <rs-state-select
      [countryCode]="$any(rsAddressForm.controls).country.value"
      [rsFormControl]="$any(rsAddressForm.controls).state"
      [shouldDisable]="true"
      [markAsTouched]="markStateAsTouched"
    ></rs-state-select>
  </ng-container>

  <rs-phone-input
    *ngIf="!hidePhoneNumber"
    [phoneGroup]="phoneFormGroup"
    [required]="true"
    (phoneNumber)="handlePhoneControlChange($event)"
    [placeholder]="'PROFILE.payment.phone_number' | translate"
  ></rs-phone-input>

  <rs-input
    *ngIf="!hideInstructions"
    [control]="$any(rsAddressForm.controls).instructions"
    [placeholder]="instructionsPlaceholder | translate"
    [label]="instructionsPlaceholder | translate"
    [iconType]="bookmarkIcon"
  >
  </rs-input>
</form>
