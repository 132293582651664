import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { GiftCardsListInOrderComponent } from './gift-cards-list-in-order.component';
import { RsPipesModule } from '../../_modules/pipes.module';
import { GiftCardModule } from '../gift-card/gift-card.module';
import { DirectivesModule } from '../../_directives/directives.module';
import { MaterialModule } from '../../_modules/material.module';
import { GiftCardItemViewComponent } from './gift-card-item-view/gift-card-item-view.component';
import { RsCounterComponent } from '../rs-counter/rs-counter.component';

@NgModule({
  declarations: [GiftCardsListInOrderComponent, GiftCardItemViewComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RsPipesModule,
    GiftCardModule,
    DirectivesModule,
    MaterialModule,
    FormsModule,
    RsCounterComponent,
  ],
  exports: [GiftCardsListInOrderComponent, GiftCardItemViewComponent],
})
export class GiftCardsListInOrderModule {}
