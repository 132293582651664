import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { RsPipesModule } from '../../_modules/pipes.module';
import { DirectivesModule } from '../../_directives/directives.module';
import { SharedModule } from '../../_modules/shared.module';

import { UserProfileAddress } from 'src/app/profile/_interfaces/address.model';

import { untilDestroyed } from '../../_utils/until-destroyed';

@Component({
  selector: 'rs-payment-address-selector',
  standalone: true,
  imports: [SharedModule, RsPipesModule, DirectivesModule],
  templateUrl: 'payment-address-selector.component.html',
  styleUrls: ['payment-address-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentAddressSelectorComponent {
  @Input() addresses: UserProfileAddress[] = [];
  @Input() resetListener: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  @Output() selectAddress: EventEmitter<UserProfileAddress | null> =
    new EventEmitter<UserProfileAddress | null>();

  public selectedIndex: number | undefined = undefined;
  private readonly destroyed$ = untilDestroyed();

  public ngOnInit(): void {
    this.resetListener
      .pipe(this.destroyed$())
      .subscribe((isNeedToReset: boolean) => {
        if (isNeedToReset) {
          this.resetSelectorState();
          this.resetListener.next(false);
        }
      });
  }

  public selectAddressEvent(address: UserProfileAddress, index: number): void {
    if (this.selectedIndex === index) {
      this.resetSelectorState();
    } else {
      this.selectedIndex = index;
      this.selectAddress.emit(address);
    }
  }

  private resetSelectorState(): void {
    this.selectedIndex = undefined;
    this.selectAddress.emit(null);
  }
}
