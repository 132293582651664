import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emptyOrNoCharactersValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (typeof value !== 'string' || !value.trim()) {
      return { emptyOrNoCharacters: true };
    }

    return null;
  };
}

export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailRegex: RegExp =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (control.value && !emailRegex.test(control.value)) {
      return { invalidEmail: true };
    }

    return null;
  };
}

export function timeValidator(minTime: string, maxTime: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const time = control.value;
    if (!time) return null;

    const timeNumber: number = convertTimeToNumber(time);
    const minTimeNumber: number = convertTimeToNumber(minTime);
    const maxTimeNumber: number = convertTimeToNumber(maxTime);

    if (timeNumber < minTimeNumber || timeNumber > maxTimeNumber) {
      return { timeOutOfRange: true };
    }

    return null;
  };
}

function convertTimeToNumber(time: string): number {
  if (time) {
    const [hours, minutes] = time?.split(':').map(Number);
    return hours * 60 + minutes;
  } else {
    return 0;
  }
}
