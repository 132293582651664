export enum NewsPageDataType {
  News = 'news',
  Event = 'event',
}

export enum NewsPageRoutesInfo {
  all = 'all-info',
  news = 'news-info',
  events = 'events-info',
}

export enum NewsPageTitle {
  all = 'News & Events',
  news = 'News',
  events = 'Events',
}
