<!--<div class="rs-spinner" *ngIf="isVisible">-->
<!--  <mat-spinner color="primary"></mat-spinner>-->
<!--</div>-->

<div class="rs-spinner" *ngIf="isVisible">
  <div class="rs-loader">
    <div class="rs-dot rs-dot1"></div>
    <div class="rs-dot rs-dot2"></div>
    <div class="rs-dot rs-dot3"></div>
    <div class="rs-dot rs-dot4"></div>
  </div>
</div>
