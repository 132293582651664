import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { map, Observable } from 'rxjs';

import {
  Breakpoints,
  MenuBreakpoints,
} from '../_shared/_enums/breakpoints.enum';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  public isLessThenExpectedBreakpoint(breakpoint: number): Observable<boolean> {
    return this.breakpointObserver
      .observe(`(max-width: ${breakpoint}px)`)
      .pipe(map(({ matches }: BreakpointState) => matches));
  }

  public isWithinExpectedBreakpoints(
    minBreakpoint: number,
    maxBreakpoint: number
  ): Observable<boolean> {
    return this.breakpointObserver
      .observe(
        `(min-width: ${minBreakpoint}px) and (max-width: ${maxBreakpoint}px)`
      )
      .pipe(map(({ matches }: BreakpointState) => matches));
  }

  public isGreaterThenExpectedBreakpoint(
    breakpoint: number
  ): Observable<boolean> {
    return this.breakpointObserver
      .observe(`(min-width: ${breakpoint}px)`)
      .pipe(map(({ matches }: BreakpointState) => matches));
  }

  public isMenuMobile(): Observable<boolean> {
    return this.breakpointObserver
      .observe(`(max-width: ${MenuBreakpoints.MobileOneCard}px)`)
      .pipe(map(({ matches }: BreakpointState) => matches));
  }

  public isMobile(): Observable<boolean> {
    return this.breakpointObserver
      .observe(`(max-width: ${Breakpoints.small}px)`)
      .pipe(map((state: BreakpointState) => state.matches));
  }

  public isTablet(): Observable<boolean> {
    return this.breakpointObserver
      .observe(
        `(min-width: ${Breakpoints.small}px) and (max-width: ${Breakpoints.medium}px)`
      )
      .pipe(map((state: BreakpointState) => state.matches));
  }

  public isLaptop(): Observable<boolean> {
    return this.breakpointObserver
      .observe(
        `(min-width: ${Breakpoints.medium}px) and (max-width: ${Breakpoints.large}px)`
      )
      .pipe(map((state: BreakpointState) => state.matches));
  }

  public isDesktop(): Observable<boolean> {
    return this.breakpointObserver
      .observe(`(min-width: ${Breakpoints.large}px)`)
      .pipe(map((state: BreakpointState) => state.matches));
  }
}
