import {
  Directive,
  Renderer2,
  Output,
  EventEmitter,
  Input,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MatAutocomplete } from '@angular/material/autocomplete';

import { take } from 'rxjs';

import { untilDestroyed } from '../../_utils/until-destroyed';
import {
  AUTOCOMPLETE_ERROR_ID,
  AUTOCOMPLETE_FORM_FIELD_ID,
  AUTOCOMPLETE_PANEL_CLASS,
} from './autocomplete.constants';

@Directive({ selector: '[rsMatAutocompletePosition]', standalone: true })
export class MatAutocompletePositionDirective {
  @Input() idVariablePart!: string;

  private destroyed$ = untilDestroyed();
  private fieldObserver: MutationObserver | null = null;
  private panelObserver: MutationObserver | null = null;
  private fallbackElement!: HTMLElement;

  private readonly PANEL_CONFIG: MutationObserverInit = {
    attributes: true,
    attributeFilter: ['style'],
  };

  private readonly FIELD_CONFIG: MutationObserverInit = {
    attributes: true,
    attributeFilter: ['class'],
  };

  @Output() rsCloseAutocomplete: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: string,
    private readonly renderer: Renderer2,
    private readonly autocomplete: MatAutocomplete
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.fieldObserver = new MutationObserver(() => {});
      this.panelObserver = new MutationObserver(() => {});
      this.fallbackElement = this.createFallbackElement();
    }
  }

  private autocompleteClass = (): string =>
    `${AUTOCOMPLETE_PANEL_CLASS}-${this.idVariablePart}`;

  private overlayPanel = (): HTMLElement => {
    if (isPlatformServer(this.platformId)) {
      return this.fallbackElement;
    }

    const overlays = document.querySelectorAll('.cdk-overlay-pane');

    if (overlays.length > 1) {
      for (let i = 0; i < overlays.length; i++) {
        const overlay = overlays[i] as HTMLElement;

        for (let j = 0; j < overlay.children.length; j++) {
          const className = overlay.children.item(j)?.className;

          if (className && className.indexOf(this.autocompleteClass()) > -1) {
            return overlay;
          }
        }
      }
    }

    const defaultOverlay = document.querySelector('.cdk-overlay-pane');

    return defaultOverlay
      ? (defaultOverlay as HTMLElement)
      : this.fallbackElement;
  };

  private formFieldMatError = (): HTMLElement | null => {
    if (isPlatformBrowser(this.platformId)) {
      return document.getElementById(
        `${AUTOCOMPLETE_ERROR_ID}-${this.idVariablePart}`
      ) as HTMLElement;
    }

    return this.fallbackElement;
  };

  private formField = (): HTMLElement => {
    if (isPlatformBrowser(this.platformId)) {
      return document.getElementById(
        `${AUTOCOMPLETE_FORM_FIELD_ID}-${this.idVariablePart}`
      ) as HTMLElement;
    }

    return this.fallbackElement;
  };

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.reactOnAutocompleteOpen();
    }
  }

  private reactOnAutocompleteOpen(): void {
    this.autocomplete.opened.pipe(this.destroyed$()).subscribe(() => {
      this.setInitialState();
      this.setObservers();
      this.reactOnAutocompleteClose();
    });
  }

  private createFallbackElement(): HTMLElement {
    const element = document.createElement('div');
    element.className = 'fallback-overlay-pane';

    return element;
  }

  private setInitialState(): void {
    const overrideClasses = ['rs-override-autocomplete', 'rs-override-default'];
    this.addClass(this.overlayPanel(), overrideClasses);

    this.updatePanelStyle();
    this.updatePanelPosition();
  }

  private setObservers(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.panelObserver = new MutationObserver(this.autocompletePanelObserver);
      this.fieldObserver = new MutationObserver(this.formFieldObserver);

      this.panelObserver.observe(this.overlayPanel(), this.PANEL_CONFIG);
      this.fieldObserver.observe(this.formField(), this.FIELD_CONFIG);
    }
  }

  private reactOnAutocompleteClose(): void {
    this.autocomplete.closed.pipe(take(1)).subscribe(() => {
      this.removeUpdates();
    });
  }

  private autocompletePanelObserver = (mutationsList: MutationRecord[]) => {
    for (let mutation of mutationsList) {
      mutation.type === 'attributes' &&
        mutation.attributeName === 'style' &&
        this.updatePanelPosition();
    }
  };

  private formFieldObserver = (mutationsList: MutationRecord[]) => {
    for (let mutation of mutationsList) {
      mutation.type === 'attributes' &&
        mutation.attributeName === 'class' &&
        this.updatePanelStyle();
    }
  };

  private updatePanelStyle(): void {
    const panel = this.overlayPanel();

    if (!panel) {
      this.removeUpdates();

      return;
    }

    const fieldClasses = this.formField()?.classList.value;

    if (fieldClasses) {
      fieldClasses.includes('mat-form-field-invalid')
        ? this.addClass(panel, ['rs-override-error'])
        : this.removeClass(panel, ['rs-override-error']);

      fieldClasses.includes('mat-focused')
        ? (this.addClass(panel, ['rs-override-focused']),
          this.removeClass(panel, ['rs-override-error']))
        : this.removeClass(panel, ['rs-override-focused']);
    }
  }

  private updatePanelPosition(): void {
    const panel = this.overlayPanel();

    this.updateMatErrorPosition(panel.clientHeight);

    if (panel?.classList.value.includes('mat-autocomplete-panel-above')) {
      const bottomMatch = panel.style.cssText.match(/bottom:\s*([\d.]+)px;/);

      if (!bottomMatch) {
        this.rsCloseAutocomplete.emit();

        return;
      }

      const bottomValue = `${
        window.innerHeight - parseFloat(bottomMatch[1]) + 50
      }px`;

      this.setStyle(panel, 'top', bottomValue);
    }
  }

  private updateMatErrorPosition(clientHeight: number): void {
    const formError = this.formFieldMatError();

    if (!formError) {
      return;
    }

    const styles = [
      { property: 'position', value: 'relative' },
      { property: 'z-index', value: '1000' },
      { property: 'top', value: `${clientHeight - 8}px` },
    ];

    styles.forEach(style => {
      this.setStyle(formError, style.property, style.value);
    });
  }

  private removeMatErrorUpdatedStyles(): void {
    const formError = this.formFieldMatError();

    if (!formError) {
      return;
    }

    const properties = ['position', 'top', 'z-index'];

    this.removeStyle(formError, properties);
  }

  private removePanelUpdatedClasses(): void {
    const overrideClasses = [
      'rs-override-autocomplete',
      'rs-override-default',
      'rs-override-focused',
      'rs-override-error',
    ];

    this.removeClass(this.overlayPanel(), overrideClasses);
  }

  private setStyle(element: HTMLElement, style: string, value: any): void {
    this.renderer.setStyle(element, style, value);
  }

  private addClass(element: HTMLElement, classes: string[]): void {
    classes.forEach(overrideClass => {
      this.renderer.addClass(element, overrideClass);
    });
  }

  private removeClass(element: HTMLElement, classes: string[]): void {
    if (!element) {
      return;
    }

    classes.forEach(overrideClass => {
      this.renderer.removeClass(element, overrideClass);
    });
  }

  private removeStyle(element: HTMLElement, properties: string[]): void {
    properties.forEach(property => {
      this.renderer.removeStyle(element, property);
    });
  }

  private removeUpdates(): void {
    if (this.panelObserver) {
      this.panelObserver.disconnect();
    }

    if (this.fieldObserver) {
      this.fieldObserver.disconnect();
    }

    this.removeMatErrorUpdatedStyles();
    this.removePanelUpdatedClasses();
  }
}
