import { parse, ParsedNumber, parsePhoneNumber } from 'libphonenumber-js';

import { COUNTRIES } from '../constants/countries.constant';
import { COUNTRY_DIAL_CODES } from '../constants/country-dial-codes.constant';
import { FLAG_IMAGES } from '../constants/flag-images.constant';
import { ISO2_COUNTRY_CODES } from '../constants/iso2-country-codes.constant';
import { NANP_COUNTRIES_ISO2_CODES } from '../constants/nanp-countries-iso2-codes.constant';
import { PLACEHOLDERS } from '../constants/placeholders.constant';
import { CountryPhoneData } from '../interfaces/phone-based-on-country.interface';

export const DEFAULT_COUNTRY = 'US';
enum PhoneFormat {
  NATIONAL = 'NATIONAL',
  INTERNATIONAL = 'INTERNATIONAL',
}
/*
  Object contains all country phones data
*/
const ALL_PHONES: { [key: string]: CountryPhoneData } = COUNTRIES.reduce(
  (phones, country, index) => {
    const iso2 = ISO2_COUNTRY_CODES[index];

    return {
      ...phones,
      [iso2]: {
        country: country,
        dialCode: COUNTRY_DIAL_CODES[index],
        flag: FLAG_IMAGES[index],
        iso2: iso2,
        placeholder: PLACEHOLDERS[index],
      },
    };
  },
  {}
);

type phoneType = string | null | undefined;
const clearString = (phone: phoneType): string =>
  phone ? phone.replace(/\+|\-|\(|\)| |_|\ /g, '') : '';

const getFormat = (iso2: string): PhoneFormat => {
  return NANP_COUNTRIES_ISO2_CODES.includes(iso2)
    ? PhoneFormat.NATIONAL
    : PhoneFormat.INTERNATIONAL;
};

const getParsedPhone = (phoneNumber: string): any =>
  parsePhoneNumber(phoneNumber);

const getPhoneDataFromPhoneNumber = (phoneNumber: string): CountryPhoneData => {
  const parsed: ParsedNumber = parse(phoneNumber);
  const phoneData = ALL_PHONES[
    parsed?.country || DEFAULT_COUNTRY
  ] as CountryPhoneData;

  return phoneData;
};

export { clearString, getFormat, getParsedPhone, getPhoneDataFromPhoneNumber };
