<div
  class="d-flex flex-row rs-gift-cards-list__wrapper"
  [dir]="'languageDirection' | translate"
>
  <div class="rs-gift-cards-list__gift-card">
    <rs-gift-card
      [amount]="card.amount || 0"
      [card]="card"
      [recipientName]="card?.name || ''"
      [title]="venueName"
      [isQrPreview]="false"
      [giftCardNumberDefined]="giftCardNumberDefined"
      [resizeOnMobile]="true"
    ></rs-gift-card>
  </div>
  <div class="d-flex flex-column rs-gift-cards-list__content">
    <div
      class="d-flex justify-content-between"
      [ngClass]="
        isMobileView ? 'flex-column' : 'flex-row-reverse align-items-center'
      "
    >
      <div
        class="d-flex flex-row justify-content-between align-items-center rs-counter-and-price"
        [class.mb-3]="isMobileView"
      >
        <p class="fw-bold m-0 rs-gift-cards-list__amount">
          <rs-counter
            *ngIf="isDeleteAvailable"
            [deleteButtonId]="elementId + '-delete-button'"
            [isSmall]="true"
            [showIncreaseButton]="false"
            [quantity]="1"
            (quantityDecreased)="decreaseItemQuantity.emit(card.cartItemId)"
            (click)="$event.preventDefault()"
          ></rs-counter>

          {{ (!isDeleteAvailable ? '1' : '') | rsEasternArabicNumerals }}
        </p>
        <p
          [id]="elementId + '-amount'"
          class="fw-bold my-0 mr-0 rs-gift-cards-list__price"
        >
          {{ card.amount | cents2DollarsView | rsEasternArabicNumerals }}
        </p>
      </div>
      <p
        class="fw-bold m-0 rs-gift-cards-list__title"
        [class.mb-3]="isMobileView"
      >
        {{ 'PAYMENT.Gift Card' | translate }}
      </p>
    </div>
    <div class="d-flex flex-column rs-gift-cards-list__description">
      <ng-container *ngIf="card.recipientEmail; else rsDeliverByPhone">
        <p class="fw-bold mt-3 mb-1">
          {{ 'GIFT_CARDS.Deliver By Email to' | translate }}:
        </p>
        <p class="m-0 rs-gift-cards-list__description-text">
          <span>{{ card.recipientEmail }}</span>
        </p>
      </ng-container>
      <ng-template #rsDeliverByPhone>
        <p class="fw-bold mt-3 mb-1">
          {{ 'GIFT_CARDS.Deliver By Phone to' | translate }}:
        </p>
        <p class="m-0" *ngIf="card.recipientPhone">
          {{
            card.recipientPhone.countryCode + card.recipientPhone.phoneNumber
          }}
        </p>
      </ng-template>

      <p class="m-0 rs-gift-cards-list__description-text">
        <span>
          {{ card.recipientName !== '' ? card.recipientName : card.name }}
        </span>
      </p>
      <p *ngIf="card.message" class="m-0 rs-gift-cards-list__description-text">
        <span
          >{{ 'GIFT_CARDS.Special Message:' | translate }}
          {{ card.message }}</span
        >
      </p>
      <p class="m-0">
        {{ 'GIFT_CARDS.Delivery Date:' | translate }}
        {{
          card.deliveryDate
            | localizedDate
              : (('languageDirection' | translate) !== 'rtl'
                  ? 'EEE, MMM d y'
                  : 'EEEE, dd MMM yyy')
        }}
      </p>

      <p class="fw-bold mt-3 mb-1" *ngIf="card.senderName || card.senderEmail">
        {{ 'GIFT_CARDS.From' | translate }}:
      </p>
      <p class="m-0 rs-gift-cards-list__description-text">
        <span>{{ card.senderName }}</span>
      </p>
      <p class="m-0 rs-gift-cards-list__description-text">
        <span>{{ card.senderEmail }}</span>
      </p>
    </div>
    <div
      *ngIf="showPrintButton"
      class="d-flex flex-row w-100 justify-content-end pt-3"
    >
      <button rs-style="text" (click)="printPhysicalCard.emit(card)">
        <span>
          {{ 'GIFT_CARDS.print_physical_gift_card' | translate | titlecase }}
        </span>
        <mat-icon
          aria-hidden="true"
          focusable="false"
          [svgIcon]="CommonIcon.Print"
        ></mat-icon>
      </button>
    </div>
  </div>
</div>
