import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { SharedModule } from '../../_modules/shared.module';
import { MatAutocompletePositionDirective } from './autocomplete-position.directive';

import { FormFieldErrors } from '../../_interfaces/form-errors.interface';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { CommonIcons } from 'src/app/_shared/_enums/digital-storefront-icons.enum';
import { NEW_ICONS_DIRECTORY } from 'src/app/_shared/_constants/digital-storefront.constants';
import { TEST_ID } from '../../_constants/e2e-ids.constants';
import {
  AUTOCOMPLETE_ERROR_ID,
  AUTOCOMPLETE_FORM_FIELD_ID,
  AUTOCOMPLETE_PANEL_CLASS,
} from './autocomplete.constants';
import { getRandomNumber } from '../../_utils/common';

@Component({
  selector: 'rs-autocomplete-field',
  standalone: true,
  imports: [CommonModule, SharedModule, MatAutocompletePositionDirective],
  templateUrl: './rs-autocomplete-field.component.html',
  styleUrls: ['./rs-autocomplete-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RsAutocompleteFieldComponent {
  @Input() public autocompleteFieldPlaceholder: string = '';
  @Input() public autocompleteFieldLabel: string = '';
  @Input() public control: FormControl = new FormControl();
  @Input() public options: any[] = [];
  @Input() public formErrors: FormFieldErrors[] = [];
  @Input() public id!: string;
  @Input() public matFormFieldClass: string = '';
  @Input() public iconType!: CommonIcons;

  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  public autoComplete: MatAutocompleteTrigger | undefined;

  public readonly angleDown = CommonIcons.AngleDown;
  public readonly clearBold = CommonIcons.ClearBold;

  public idVariablePart!: string;
  public matFormFieldId!: string;
  public errorId!: string;
  public autocompleteClass!: string;

  @Output() public clearValidators: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() public inputBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() public enterPressedInInput: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() public filterAutocompleteOptions: EventEmitter<Event> =
    new EventEmitter<Event>();
  @Output() public cleared: EventEmitter<void> = new EventEmitter<void>();
  @Output() public optionSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private readonly svgIconService: SvgIconService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.registerIcons();
    if (isPlatformBrowser(this.platformId)) {
      this.id = `${TEST_ID.autocompleteField}-${getRandomNumber()}`;
      this.idVariablePart = String(getRandomNumber());
      this.matFormFieldId = `${AUTOCOMPLETE_FORM_FIELD_ID}-${this.idVariablePart}`;
      this.errorId = `${AUTOCOMPLETE_ERROR_ID}-${this.idVariablePart}`;
      this.autocompleteClass = `${AUTOCOMPLETE_PANEL_CLASS}-${this.idVariablePart}`;
    } else {
      const randomNumber = parseInt(String(Math.random() * 1000));
      this.idVariablePart = String(randomNumber);
      this.id = `${TEST_ID.autocompleteField}-${randomNumber}`;
      this.matFormFieldId = `${AUTOCOMPLETE_FORM_FIELD_ID}-${this.idVariablePart}`;
      this.errorId = `${AUTOCOMPLETE_ERROR_ID}-${this.idVariablePart}`;
      this.autocompleteClass = `${AUTOCOMPLETE_PANEL_CLASS}-${this.idVariablePart}`;
    }
  }

  ngOnInit(): void {
    this.updateAutoCompletePanelPosition();
  }

  private registerIcons(): void {
    this.svgIconService.registerSvgIcons(
      [this.iconType, this.angleDown, this.clearBold],
      NEW_ICONS_DIRECTORY
    );
  }

  public reactOnOptionSelect(selectedOption: any): void {
    this.optionSelected.emit(selectedOption);
    this.autoComplete?.closePanel();
  }

  public toggleAutocompletePanel(): void {
    this.autoComplete?.panelOpen
      ? this.autoComplete?.closePanel()
      : this.autoComplete?.openPanel();
  }

  private updateAutoCompletePanelPosition(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('scroll', this.scrollHandler, true);
    }
  }

  private scrollHandler = (): void => {
    if (this.autoComplete && this.autoComplete?.panelOpen) {
      this.autoComplete.updatePosition();
    }
  };

  public reactOnReset(): void {
    this.control.reset();
    this.cleared.emit();
  }
}
