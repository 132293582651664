<ng-container *ngIf="itemDetails$ | async as itemDetails">
  <!-- <ng-template
    [ngIf]="itemDetails.bundleAttributes.items"
    let-itemsWithoutSections
  >
    <rs-bundle-section
      *ngFor="let item of itemsWithoutSections"
    ></rs-bundle-section>
  </ng-template> -->

  <ng-template
    ngFor
    let-section
    [ngForOf]="itemDetails.bundleAttributes.sections"
    let-sections
  >
    <ng-container
      *ngTemplateOutlet="
        rsBundleTitle;
        context: {
          section: section,
          maximum: (
            itemDetails.sizesAndPrices[0].bundleSectionSizeAttributes
            | getBundleSectionSizeAttributes: section.id
          )?.maximumQuantity
        }
      "
    ></ng-container>

    <div class="my-3">
      <rs-bundle-section
        [bundleSectionId]="section.id"
        [items]="section.items"
        [bundleItemSizeAttributes]="
          itemDetails.sizesAndPrices[0].bundleItemSizeAttributes || []
        "
        [bundleSectionSizeAttributes]="
          itemDetails.sizesAndPrices[0].bundleSectionSizeAttributes
            | getBundleSectionSizeAttributes: section.id
        "
        [addedBundleItems]="bundleItemsControl.value"
        (bundleItemsSelected)="updateBundleItemsSelected($event)"
        [disableOrdering]="disableOrdering"
      ></rs-bundle-section>
    </div>
  </ng-template>

  <ng-template #rsBundleTitle let-section="section" let-maximum="maximum">
    <div class="d-flex mb-1">
      <div class="flex-grow-1 d-flex align-items-center">
        <span class="rs-title m-0 me-3">{{ section.name }}</span>
      </div>

      <div
        class="rs-title-info m-0"
        *ngIf="maximum && maximum > 1 && maximum < 999 && !disableOrdering"
      >
        {{ 'Up to' | translate: { amount: maximum } }}
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="bundleItemModifiers$ | async; let bundleItemModifiers">
  <ng-template
    ngFor
    let-itemModifiers
    [ngForOf]="bundleItemModifiers"
    let-index="index"
  >
    <rs-bundle-item
      *ngIf="itemModifiers.modifiers?.length"
      [item]="itemModifiers.item"
      [bundleSectionId]="itemModifiers.bundleSectionId"
      [modifiers]="itemModifiers.modifiers"
      [metadata]="metadata"
      [itemIndex]="
        (itemModifiers.item | isBundleItemRepeated: bundleItemModifiers)
          ? (itemModifiers.item | bundleItemNumber: bundleItemModifiers:index)
          : 0
      "
      (bundleItemUpdated)="updateBundleItem(itemModifiers.item.id, $event)"
      (modifiersControlsValid)="
        reactOnModifiersValidity(itemModifiers.item.id, $event)
      "
      [disableOrdering]="disableOrdering"
    >
    </rs-bundle-item>
  </ng-template>
</ng-container>
