<form
  *ngIf="!showCreatePasswordPart"
  [formGroup]="registrationForm"
  class="d-flex flex-column align-items-center rs-authorisation-sign-up"
>
  <rs-input
    rs-input-style="default"
    [required]="true"
    inputType="text"
    [placeholder]="'WELCOME_SCREEN.Name' | translate"
    [control]="nameControl"
  ></rs-input>

  <rs-input
    rs-input-style="default"
    [required]="true"
    inputType="text"
    [placeholder]="'WELCOME_SCREEN.Last Name' | translate"
    [control]="lastNameControl"
  ></rs-input>

  <rs-input
    rs-input-style="email"
    [required]="true"
    [placeholder]="'WELCOME_SCREEN.Email' | translate"
    [control]="emailControl"
  ></rs-input>

  <rs-phone-input
    [phoneGroup]="phoneFormGroup"
    (phoneNumber)="handlePhoneControlChange($event)"
    [placeholder]="'WELCOME_SCREEN.Phone Number' | translate"
  ></rs-phone-input>

  <div class="w-100 mb-3">
    <mat-checkbox
      [id]="acceptTermsId"
      [checked]="ifUserAgreedOnTerms"
      (change)="changeAgreeValue($event)"
    ></mat-checkbox>

    <span
      class="rs-authorisation-sign-up__terms-of-use-description"
      [innerHTML]="
        'SIGN_UP_SCREEN.read_and_agree_with_terms_of_use' | translate
      "
    >
    </span>
  </div>
  <button
    rs-style="bold"
    [disabled]="
      registrationForm.controls['name'].errors ||
      registrationForm.controls['lastName'].errors ||
      registrationForm.controls['email'].errors ||
      registrationForm.controls['phone'].errors ||
      !this.phoneFormGroup?.value?.phoneNumber ||
      !this.phoneFormGroup?.valid ||
      !ifUserAgreedOnTerms
    "
    class="w-100 m-1"
    (click)="submitRegistrationForm()"
  >
    <span>{{ 'WELCOME_SCREEN.CONTINUE' | translate }}</span>
  </button>
</form>
<ng-template [ngIf]="showCreatePasswordPart">
  <rs-authorisation-create-password
    (passwordSubmitted)="signUp($event)"
  ></rs-authorisation-create-password>
</ng-template>
