import { Injectable } from '@angular/core';

import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { distinctUntilChanged, filter, first } from 'rxjs';
import { isEqual } from 'lodash';

import { VenueState } from './venue.state';
import { AllergensState } from './allergens.state';
import { OrderDataState } from './order-data.state';

import { Menu, Section } from '../_interfaces/menu.model';
import { Allergen, Item } from '../_interfaces/item.model';
import { DeliveryType, OrderType } from '../_enums/order.enum';
import {
  filterMenusByAllergens,
  getFilteredMenusByOrderType,
  getFilteredMenusBySearchParameters,
  getFilteredMenusForToGo,
  getItemsList,
  getSectionsList,
  getVisibleMenus,
} from '../_utils/menus';

export class SetSearchText {
  static readonly type = '[SearchDialog, FoodMenuEmptyComponent] SetSearchText';

  constructor(public searchText: string = '') {}
}

export interface FilteredMenuStateModel {
  menus: Menu[];
  searchText: string;
  selectedAllergens: Allergen[];
  visibleMenusIds: string[];
}

const defaults = {
  menus: [],
  searchText: '',
  selectedAllergens: [],
  visibleMenusIds: [],
};

@State<FilteredMenuStateModel>({
  name: 'filteredMenu',
  defaults,
})
@Injectable()
export class FilteredMenuState implements NgxsOnInit {
  @Selector()
  static searchText({ searchText }: FilteredMenuStateModel): string {
    return searchText;
  }

  @Selector([FilteredMenuState.menus])
  static items(_: FilteredMenuStateModel, filteredMenus: Menu[]): Item[] {
    return getItemsList(filteredMenus);
  }

  @Selector([OrderDataState.orderType])
  static menus(
    {
      menus,
      selectedAllergens,
      searchText,
      visibleMenusIds,
    }: FilteredMenuStateModel,
    orderType: DeliveryType
  ) {
    return getVisibleMenus(
      getFilteredMenusBySearchParameters(
        orderType === OrderType.DineIn
          ? getFilteredMenusByOrderType(menus, orderType)
          : getFilteredMenusForToGo(menus),
        selectedAllergens,
        searchText
      ),
      visibleMenusIds
    );
  }

  static areThereItemsIfAllergensApplied(selectedAllergens: Allergen[]) {
    return createSelector(
      [FilteredMenuState],
      ({ visibleMenusIds, menus }: FilteredMenuStateModel) => {
        const filteredVisibleMenus: Menu[] = getVisibleMenus(
          filterMenusByAllergens(menus, selectedAllergens),
          visibleMenusIds
        );

        return !getItemsList(filteredVisibleMenus)?.length;
      }
    );
  }

  @Selector([FilteredMenuState.menus])
  static availableSections(
    _: FilteredMenuStateModel,
    filteredVisibleMenus: Menu[]
  ): Section[] {
    return getSectionsList(filteredVisibleMenus);
  }

  @Selector([FilteredMenuState.menus])
  static filteredVisibleItems(
    _: FilteredMenuStateModel,
    filteredVisibleMenus: Menu[]
  ): Item[] {
    return getItemsList(filteredVisibleMenus);
  }

  constructor(private readonly store: Store) {}

  ngxsOnInit({ patchState }: StateContext<FilteredMenuStateModel>) {
    this.store
      .select(VenueState.menus)
      .pipe(
        filter(menus => !!menus?.length),
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        first()
      )
      .subscribe(menus => patchState({ menus }));

    this.store
      .select(VenueState.visibleMenusIds)
      .pipe(
        filter(ids => !!ids?.length),
        distinctUntilChanged((previous, current) => isEqual(previous, current)),
        first()
      )
      .subscribe(visibleMenusIds => patchState({ visibleMenusIds }));

    this.store
      .select(AllergensState.selectedAllergens)
      .pipe(
        distinctUntilChanged((previous, current) => isEqual(previous, current))
      )
      .subscribe(selectedAllergens => patchState({ selectedAllergens }));
  }

  @Action(SetSearchText)
  setSearchText(
    { patchState }: StateContext<FilteredMenuStateModel>,
    { searchText }: SetSearchText
  ) {
    patchState({ searchText });
  }
}
