<mat-accordion class="w-100 rs-authorisation-panel">
  <rs-panel-wrapper
    *ngIf="hiddenAutorisationType !== AutorisationType.SignUp"
    [expanded]="isResetPassword || isSignUp"
    (closed)="reactOnCloseFirstTab()"
  >
    <ng-container rs-panel-header>
      {{
        (showCreatePasswordPart
          ? isResetPassword
            ? 'CREATE_PASSWORD.reset'
            : 'CREATE_PASSWORD.title'
          : 'WELCOME_SCREEN.Create an account'
        ) | translate
      }}
    </ng-container>

    <ng-container rs-panel-header-hint>
      {{
        showCreatePasswordPart
          ? ''
          : ('WELCOME_SCREEN.New to RockSpoon' | translate)
      }}
    </ng-container>

    <rs-authorisation-sign-up
      [showCreatePasswordPart]="showCreatePasswordPart"
      (showCreatePassword)="showCreatePasswordPart = $event"
    ></rs-authorisation-sign-up>
  </rs-panel-wrapper>

  <rs-panel-wrapper
    [expanded]="isGuest"
    *ngIf="!isShadowUser && hiddenAutorisationType !== AutorisationType.Guest"
  >
    <ng-container rs-panel-header>
      {{ 'WELCOME_SCREEN.Enter as a guest' | translate }}
    </ng-container>
    <rs-authorisation-guest></rs-authorisation-guest>
  </rs-panel-wrapper>

  <rs-panel-wrapper [expanded]="isLogin" (closed)="showForgotPassword = false">
    <ng-container rs-panel-header>
      {{
        showForgotPassword
          ? ('RESET_PASSWORD.TITLE' | translate)
          : previouslySavedName
          ? ('WELCOME_SCREEN.Welcome back, {name}.'
            | translate: { name: previouslySavedName })
          : ('WELCOME_SCREEN.Log in.' | translate)
      }}
    </ng-container>

    <ng-container rs-panel-header-hint>
      {{
        showForgotPassword
          ? ''
          : ((previouslySavedName
              ? 'WELCOME_SCREEN.Log in.'
              : 'WELCOME_SCREEN.Already a RockSpoon user?'
            ) | translate)
      }}
    </ng-container>

    <rs-authorisation-login
      *ngIf="hiddenAutorisationType !== AutorisationType.Login"
      [showForgotPassword]="showForgotPassword"
      (showedForgotPassword)="showForgotPassword = $event"
    ></rs-authorisation-login>
  </rs-panel-wrapper>
</mat-accordion>
