import { Component, Input } from '@angular/core';
import { SharedModule } from '../../_modules/shared.module';
import { CommonIcons } from '../../_enums/digital-storefront-icons.enum';
import { SvgIconService } from 'src/app/_services/svg-icon.service';
import { NEW_ICONS_DIRECTORY } from '../../_constants/digital-storefront.constants';

@Component({
  selector: 'rs-location',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent {
  @Input() text!: string;
  @Input() description!: string;

  public readonly locationIcon = CommonIcons.Location;

  constructor(private readonly svgIconService: SvgIconService) {
    this.registerIcon();
  }

  private registerIcon(): void {
    this.svgIconService.registerSvgIcons(
      [this.locationIcon],
      NEW_ICONS_DIRECTORY
    );
  }
}
