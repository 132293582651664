import { Component, Input } from '@angular/core';

import { Acidity, WineBody, Sweetness, Tanin } from './enums';

@Component({
  selector: 'rs-wine-details',
  templateUrl: 'wine-details.component.html',
  styleUrls: ['wine-details.component.scss'],
})
export class WineDetailsComponent {
  @Input() sweetness: Sweetness = Sweetness.dry;
  @Input() acidity: Acidity = Acidity.acidic;
  @Input() tanin: Tanin = Tanin.low;
  @Input() body: WineBody = WineBody.low;

  public sweetnessItems = [
    Sweetness.dry,
    Sweetness.offDry,
    Sweetness.semiSweet,
    Sweetness.mediumSweet,
    Sweetness.sweet,
  ];
  public acidityItems = [
    Acidity.highAcid,
    Acidity.acidic,
    Acidity.lowAcid,
    Acidity.neutral,
    Acidity.alkalineBasic,
  ];
  public taninItems = [Tanin.low, Tanin.medium, Tanin.high];
  public bodyItems = [WineBody.low, WineBody.medium, WineBody.high];
}
