<ng-container *ngIf="nutritionalValues$ | async; let nutritionalValues">
  <div *ngIf="nutritionalValues.length" class="rs-nutritional-wrapper">
    <mat-accordion>
      <rs-panel-wrapper>
        <ng-container rs-panel-header>
          {{ 'Nutritional Values' | translate }}
        </ng-container>
        <div>
          <div class="row gx-0" *ngFor="let item of nutritionalValues">
            <div class="col-7 fw-bold">
              {{ 'NUTRIENTS.' + item.name | translate }}
            </div>
            <div class="col-3 px-2 text-end">
              {{
                (item.amount.toString() | rsEasternArabicNumerals) +
                  ' ' +
                  (item.unit | translateIfExists: 'NUTRIENTS')
              }}
            </div>
            <div class="col-2 ps-2 text-end">
              {{
                item.dayPercentage / 10000
                  | percent: '1.0-2'
                  | rsEasternArabicNumerals
              }}
            </div>
            <div
              class="row gx-0"
              *ngFor="let sub of item.subNutritionalAttributes"
            >
              <div class="col-7">
                <div class="ps-2">
                  {{ 'NUTRIENTS.' + sub.name | translate }}
                </div>
              </div>
              <div class="col-3 px-2 text-end">
                {{
                  (sub.amount.toString() | rsEasternArabicNumerals) +
                    ' ' +
                    (sub.unit | translateIfExists: 'NUTRIENTS')
                }}
              </div>
              <div class="col-2 ps-2 text-end">
                {{
                  sub.dayPercentage / 10000
                    | percent: '1.0-2'
                    | rsEasternArabicNumerals
                }}
              </div>
            </div>
          </div>
        </div>
        <p>
          {{ 'NUTRIENTS.dv_description' | translate | rsEasternArabicNumerals }}
        </p>
      </rs-panel-wrapper>
    </mat-accordion>
  </div>
</ng-container>
