import { isEqual, uniqWith } from 'lodash';

import { SelectedModifier } from '../../_interfaces/item.model';
import { CommonOrderItemBundleItemsForView } from './common-order-item.interface';
import {
  getBundleModifiersDescriptions,
  getBundleName,
} from '../../_utils/items.helper';

export const getBundleItemsForViewFromBundleItemsElements = (
  bundleItems: {
    bundleItemId: string;
    modifiers: SelectedModifier[];
  }[],
  bundleItemsNames: { [id: string]: string } | null | undefined
): CommonOrderItemBundleItemsForView[] => {
  if (!bundleItems) {
    return [];
  }

  const uniqueBundleItems: any[] = uniqWith(bundleItems, isEqual);

  return uniqueBundleItems.map(
    (bundleItem: { bundleItemId: string; modifiers: SelectedModifier[] }) => ({
      name: getBundleName(
        bundleItem,
        bundleItems || [],
        bundleItemsNames || {}
      ),
      modifiers: getBundleModifiersDescriptions(bundleItem.modifiers),
    })
  );
};
