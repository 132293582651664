import { TextMaskConfig } from 'angular2-text-mask';

import { createAutoCorrectedDatePipe } from 'text-mask-addons';
import { maskFunction } from '../_utils/payment-card';
import { CVV_MASK } from './payment';

export const EXPIRATION_DATE_MASK_CONFIG: TextMaskConfig = {
  guide: false,
  showMask: true,
  mask: [/\d/, /\d/, '/', /[0-4]/, /\d/],
  pipe: createAutoCorrectedDatePipe('mm/yy'),
  keepCharPositions: true,
};

export const CARD_NUMBER_MASK_CONFIG: TextMaskConfig = {
  guide: false,
  showMask: false,
  mask: maskFunction,
  keepCharPositions: true,
};

export const CVV_NUMBER_MASK_CONFIG: TextMaskConfig = {
  guide: false,
  showMask: true,
  mask: CVV_MASK[4],
  keepCharPositions: true,
};

export const CARD_NOT_PRESENT_PAYMENT = 'cardnotpresent';
export const GIFT_CARD_SHOW_SUFFIX_DIGITS: number = 4;

export const DEFAULT_GIFT_CARD_MIN_AMOUNT = 1; // cents
export const DEFAULT_GIFT_CARD_MAX_AMOUNT = 10000; // cents
