import { CommonIcons } from '../_enums/digital-storefront-icons.enum';
import { PROFILE_ROUTE_TITLES } from '../_enums/routes-titles.enums';
import {
  BaseRoutes,
  ExistingRoutes,
  MenuRoute,
} from '../_interfaces/venue-route.model';

export const VENUE_ROUTES: BaseRoutes[] = [
  {
    title: 'Home',
    path: 'home',
    name: ExistingRoutes.Home,
  },
  {
    title: 'Order placed successfully',
    path: 'order-success',
    name: ExistingRoutes.OrderSuccess,
  },
  {
    title: 'Add Guests to your table',
    path: 'order-success',
    name: ExistingRoutes.AddGuestToYourTable,
  },
  {
    title: 'Welcome',
    path: 'welcome',
    name: ExistingRoutes.Welcome,
  },
  {
    title: 'Order History',
    path: 'order-history',
    name: ExistingRoutes.OrderHistory,
  },
];

export const MENU_ROUTES: MenuRoute[] = [
  {
    title: 'About Us',
    visibilityKey: 'about',
    path: 'about-us',
    icon: 'keyboard_arrow_right',
    name: ExistingRoutes.AboutUs,
  },
  {
    title: 'Menu',
    visibilityKey: 'menu',
    path: 'menu',
    icon: 'keyboard_arrow_right',
    name: ExistingRoutes.Menu,
  },
  {
    title: 'Gift Card',
    visibilityKey: 'showGiftCards',
    path: 'gift-card',
    icon: 'keyboard_arrow_right',
    name: ExistingRoutes.GiftCard,
  },
  {
    title: 'Photos',
    visibilityKey: 'gallery',
    path: 'gallery',
    icon: 'keyboard_arrow_right',
    name: ExistingRoutes.Gallery,
  },
  {
    title: 'News',
    visibilityKey: 'news',
    path: 'news',
    icon: 'keyboard_arrow_right',
    name: ExistingRoutes.News,
  },
  {
    title: 'Order Online',
    visibilityKey: 'menu',
    path: 'order',
    icon: 'keyboard_arrow_right',
    name: ExistingRoutes.DigitalOrdering,
  },
];

export const PROFILE_MAIN_PATH = 'profile';

export const PROFILE_ROUTES_WITHOUT_LOG_OUT: MenuRoute[] = [
  {
    title: PROFILE_ROUTE_TITLES.Profile,
    path: PROFILE_MAIN_PATH,
    icon: CommonIcons.User,
    name: ExistingRoutes.ProfileMain,
  },
  {
    title: PROFILE_ROUTE_TITLES.Password,
    path: 'profile/password',
    icon: CommonIcons.Lock,
    name: ExistingRoutes.Password,
  },
  {
    title: PROFILE_ROUTE_TITLES.GiftCards,
    path: 'profile/gift-cards',
    icon: CommonIcons.Gift,
    name: ExistingRoutes.ProfileGiftCards,
  },
  {
    title: PROFILE_ROUTE_TITLES.Orders,
    path: 'profile/orders',
    icon: CommonIcons.Orders,
    name: ExistingRoutes.ProfileOrders,
  },
  {
    title: PROFILE_ROUTE_TITLES.Favorites,
    path: 'profile/favorites',
    icon: CommonIcons.Heart,
    name: ExistingRoutes.ProfileFavorites,
  },
  {
    title: PROFILE_ROUTE_TITLES.Address,
    path: 'profile/address-book',
    icon: CommonIcons.Map,
    name: ExistingRoutes.ProfileAddress,
  },
  {
    title: PROFILE_ROUTE_TITLES.Payment,
    path: 'profile/payment-methods',
    icon: CommonIcons.Wallet,
    name: ExistingRoutes.ProfileWallet,
  },
];

export const LOGOUT_BUTTON: MenuRoute = {
  title: 'PROFILE.menu.log_out',
  path: '',
  icon: '',
  name: ExistingRoutes.LogOut,
};

export const PROFILE_ROUTES: MenuRoute[] = [
  ...PROFILE_ROUTES_WITHOUT_LOG_OUT,
  LOGOUT_BUTTON,
];

export const LOGGED_IN_USER_ROUTES: MenuRoute[] = [
  {
    title: 'PROFILE.menu.profile',
    path: 'profile',
    icon: 'account_box',
    name: ExistingRoutes.ProfileMain,
  },
  {
    title: 'PROFILE.menu.change_password',
    path: 'profile/password',
    icon: 'lock',
    name: ExistingRoutes.Password,
  },
  {
    title: 'PROFILE.menu.gift_cards',
    path: 'profile/gift-cards',
    icon: 'card_giftcard',
    name: ExistingRoutes.ProfileGiftCards,
  },
  {
    title: 'PROFILE.menu.orders',
    path: 'profile/orders',
    icon: 'list',
    name: ExistingRoutes.ProfileOrders,
  },
  {
    title: 'PROFILE.menu.favorites',
    path: 'profile/favorites',
    icon: 'favorite',
    name: ExistingRoutes.ProfileFavorites,
  },
  {
    title: 'PROFILE.menu.address_book',
    path: 'profile/address-book',
    icon: 'map',
    name: ExistingRoutes.ProfileAddress,
  },
  {
    title: 'PROFILE.menu.payment_methods',
    path: 'profile/payment-methods',
    icon: 'account_balance_wallet',
    name: ExistingRoutes.ProfileWallet,
  },
  // {
  //   title: 'PROFILE.menu.reservations',
  //   path: 'profile/reservations',
  //   icon: 'calendar_today',
  //   name: ExistingRoutes.ProfileReservation,
  // },
  ...MENU_ROUTES,
  {
    title: 'PROFILE.menu.log_out',
    path: '',
    icon: 'exit_to_app',
    withDelimiter: true,
    name: ExistingRoutes.LogOut,
  },
];
