import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'rs-select-gift-card-dialog',
  templateUrl: './select-gift-card-dialog.component.html',
  styleUrls: ['./select-gift-card-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectGiftCardDialogComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<SelectGiftCardDialogComponent>
  ) {}
}
