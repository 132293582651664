import { copy } from 'src/app/_shared/_utils/common';
import {
  PaymentCardPattern,
  Range,
} from 'src/app/profile/_interfaces/payment.model';
import { PAYMENT_SYSTEM_MEASURES } from '../_constants/cards-patterns';
import {
  DEFAULT_CARD_MASK,
  MAX_CARD_LENGTH,
  MAX_SECURITY_CODE_LENGTH,
  MIN_CARD_LENGTH,
  MIN_SECURITY_CODE_LENGTH,
} from '../_constants/payment';

const isInRanges = (ranges: Range[], cardNumber: string): boolean => {
  const rangeIndex = ranges.findIndex(range => {
    const rangeLength = range.min.toString().length;
    const croppedCardNumber = Number(cardNumber?.substring(0, rangeLength));

    return (
      range.min === croppedCardNumber ||
      range.max === croppedCardNumber ||
      (range.min < croppedCardNumber && croppedCardNumber < range.max)
    );
  });

  return rangeIndex > -1;
};

const getPaymentCardPattern = (
  cardNumber: string
): PaymentCardPattern | null => {
  const paymentCardMeasure = PAYMENT_SYSTEM_MEASURES.find(
    (measure: PaymentCardPattern) =>
      isInRanges(measure.iinRanges as Range[], cardNumber)
  );
  return paymentCardMeasure ? paymentCardMeasure : null;
};

const getPossiblePaymentCardPatterns = (
  cardNumber: string
): PaymentCardPattern[] => {
  const paymentCardMeasures = PAYMENT_SYSTEM_MEASURES.filter(
    (measure: PaymentCardPattern) =>
      isInRanges(measure.iinRanges as Range[], cardNumber)
  );
  return paymentCardMeasures;
};

const getMinCardNumberLength = (
  possibleCardPatterns: PaymentCardPattern[]
): number => {
  if (possibleCardPatterns && possibleCardPatterns.length) {
    const minArrayForAllCardPattern: number[] = possibleCardPatterns.map(
      ({ cardNumberLength }) => Math.min(...cardNumberLength)
    );

    return Math.min(...minArrayForAllCardPattern);
  }

  return MIN_CARD_LENGTH;
};

const getMaxCardNumberLength = (
  possibleCardPatterns: PaymentCardPattern[]
): number => {
  if (possibleCardPatterns && possibleCardPatterns.length) {
    const minArrayForAllCardPattern: number[] = possibleCardPatterns.map(
      ({ cardNumberLength }) => Math.max(...cardNumberLength)
    );

    return Math.max(...minArrayForAllCardPattern);
  }

  return MAX_CARD_LENGTH;
};

const getMinCVVLength = (
  possibleCardPatterns: PaymentCardPattern[]
): number => {
  if (possibleCardPatterns && possibleCardPatterns.length) {
    const minArrayForAllCVV: number[] = possibleCardPatterns.map(
      ({ securityCodeLength }) => Math.min(...securityCodeLength)
    );

    return Math.min(...minArrayForAllCVV);
  }

  return MIN_SECURITY_CODE_LENGTH;
};

const getMaxCVVLength = (
  possibleCardPatterns: PaymentCardPattern[]
): number => {
  if (possibleCardPatterns && possibleCardPatterns.length) {
    const minArrayForAllCardCVV: number[] = possibleCardPatterns.map(
      ({ securityCodeLength }) => Math.max(...securityCodeLength)
    );

    return Math.max(...minArrayForAllCardCVV);
  }

  return MAX_SECURITY_CODE_LENGTH;
};

const isSecurityCodeRequired = (
  possibleCardPatterns: PaymentCardPattern[]
): boolean => {
  if (possibleCardPatterns && possibleCardPatterns.length) {
    const isExistPatternWithOptionalSecurityCode: boolean =
      possibleCardPatterns.findIndex(
        ({ isSecurityCodeOptional }) => isSecurityCodeOptional
      ) > -1;

    return !isExistPatternWithOptionalSecurityCode;
  }

  return true;
};

const isSecurityCode = (
  possibleCardPatterns: PaymentCardPattern[]
): boolean => {
  if (possibleCardPatterns && possibleCardPatterns.length) {
    return (
      possibleCardPatterns.findIndex(({ isSecurityCode }) => isSecurityCode) >
      -1
    );
  }

  return true;
};

const isSatisfiedLuhnAlgorithm = (cardNumber: string): boolean => {
  const checkDigit = parseInt(cardNumber.charAt(cardNumber.length - 1), 10);
  let isEven = true;
  let sum = 0;

  for (let i = cardNumber.length - 2; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);

    if (isEven && (digit *= 2) > 9) {
      digit -= 9;
    }

    sum += digit;
    isEven = !isEven;
  }

  return (sum * 9) % 10 === checkDigit;
};

const removeSpecialCharactersFromCardNumber = (
  cardNumber: string = ''
): string => copy(cardNumber.toString().replace(/-/gi, '').replace(/ /gi, ''));

const maskFunction = (cardNumber: string): (string | RegExp)[] => {
  const clearCardNumber: string =
    removeSpecialCharactersFromCardNumber(cardNumber);
  const paymentCardPattern: PaymentCardPattern | null =
    getPaymentCardPattern(clearCardNumber);

  if (paymentCardPattern?.cardNumberMask?.length) {
    const cardNumberLength: number = cardNumber.length;
    const numberMask = paymentCardPattern.cardNumberMask.find(cardNumberMask =>
      cardNumberMask.cardNumberLength.includes(cardNumberLength)
    );

    if (numberMask) {
      return numberMask.mask;
    }
  }

  return DEFAULT_CARD_MASK;
};

const getCardExpirationDate = (expirationDate: string): string => {
  const expDate = expirationDate.split('/');

  return '20' + expDate[1] + '-' + expDate[0] + '-01T00:00:00Z';
};

const isObjectExist = (object: Object): boolean =>
  Object.entries(object).length !== 0 &&
  Object.values(object).every(value => !!value);

export {
  getMinCardNumberLength,
  getMinCVVLength,
  getMaxCardNumberLength,
  getMaxCVVLength,
  getPossiblePaymentCardPatterns,
  isSatisfiedLuhnAlgorithm,
  isSecurityCode,
  isSecurityCodeRequired,
  maskFunction,
  removeSpecialCharactersFromCardNumber,
  getPaymentCardPattern,
  getCardExpirationDate,
  isObjectExist,
};
