import moment from 'moment';
import { Format } from 'src/app/_shared/_enums/date-time.enum';
import { QRSettingsType } from 'src/app/_shared/_interfaces/qrSettings.model';
import { isDineIn } from 'src/app/_shared/_utils/common';

export const isMainMenusPage = (url: string): boolean => {
  const isDineInPage = isDineIn(url) && url.endsWith('/menus');
  const isDigitalMenu = url.endsWith('/menu');
  const isOrderOnline = url.endsWith('/order');

  return isDineInPage || isDigitalMenu || isOrderOnline;
};

export const getCurrentSettingsTypeBasedOnURL = (
  url: string
): QRSettingsType | undefined => {
  const isDineInPage = isDineIn(url);
  const isDigitalMenu = url.endsWith('/menu');
  const isOrderOnline = url.endsWith('/order');

  const currentSettingsType = isDineInPage
    ? 'dine-in'
    : isDigitalMenu
    ? 'menu'
    : isOrderOnline
    ? 'togo'
    : undefined;

  return currentSettingsType;
};

export const getCurrentMomentTime = (
  currentMoment = moment()
): moment.Moment => {
  const currentMomentTime = moment(
    currentMoment.format(Format.TwelveHours),
    Format.TwelveHours
  );

  return currentMomentTime;
};

export const isCurrentPeriodOfTime = ({
  from,
  to,
}: {
  from: string;
  to: string;
}): boolean => {
  const [startHours, startMinutes] = from.split(':').map(str => Number(str));
  const [endHours, endMinutes] = to.split(':').map(str => Number(str));
  const startTimeAM = from.toLowerCase().includes('am');
  const endTimeAM = to.toLowerCase().includes('am');

  const startTime = moment()
    .set(
      'h',
      startHours === 12 && startTimeAM
        ? 0
        : startTimeAM
        ? startHours
        : startHours + 12
    )
    .set('m', startMinutes);

  const endTime = moment()
    .set('h', endTimeAM ? endHours : endHours + 12)
    .set('m', endMinutes);
  const currentMomentTime = getCurrentMomentTime();

  return (
    currentMomentTime.isSameOrAfter(startTime) &&
    currentMomentTime.isBefore(endTime)
  );
};
