import { Injectable } from '@angular/core';

import { tap } from 'rxjs';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { cloneDeep, uniq } from 'lodash';

import {
  GetGeocodeByText,
  GetGeocodeByCoordinates,
  AddMapMarker,
  RemoveAllMapMarkers,
  RemoveHomeMapMarker,
  AddUserLocationMarker,
} from './mapbox.actions';
import { HideSpinner, ShowSpinner } from '../_components/spinner/spinner.state';

import {
  MarkerData,
  GeocodeByTextResult,
  GeocodeByTextResultFeature,
  GeocodeByCoordinatesResult,
  GeocodeByCoordinatesFeature,
  Markers,
} from '../_interfaces/map.model';
import { ContextId, PlaceType } from '../_enums/map.enum';
import { CommonIcon } from '../_enums/custom-icons.enum';
import { AddressForm } from '../_interfaces/address.model';
import { MapboxService } from 'src/app/_services/mapbox.service';
import { NotificationService } from '../_services/notification.service';

export interface MapStateModel {
  geocodeByTextResult: {
    [key: string]: GeocodeByTextResultFeature;
  };
  markers: Markers;
  address: AddressForm | null;
}

@State<MapStateModel>({
  name: 'map',
  defaults: {
    geocodeByTextResult: {},
    markers: {},
    address: null,
  },
})
@Injectable()
export class MapState {
  constructor(
    private readonly mapboxService: MapboxService,
    private readonly notificationService: NotificationService
  ) {}

  @Selector()
  static markers({ markers }: MapStateModel): Markers {
    return markers;
  }

  @Selector()
  static address({ address }: MapStateModel): AddressForm | null {
    return address;
  }

  @Selector()
  static geocodeByText({ geocodeByTextResult }: MapStateModel): any {
    return geocodeByTextResult;
  }

  @Action(GetGeocodeByText)
  getGeocodeByText(
    { dispatch, getState, patchState }: StateContext<MapStateModel>,
    { icon, searchString }: GetGeocodeByText
  ) {
    dispatch(new ShowSpinner());

    return this.mapboxService.getGeocodeByText(searchString).pipe(
      tap({
        next: (result: GeocodeByTextResult) => {
          const feature = this.getAddressFeatureFromFeaturesList(
            result.features
          ) as GeocodeByTextResultFeature;

          if (!!feature) {
            const geocodeByTextResult = cloneDeep(
              getState().geocodeByTextResult
            ) as { [key: string]: GeocodeByTextResultFeature };
            const markers = cloneDeep(getState().markers || {}) as {
              [key: string]: MarkerData[];
            };
            const longitude = feature.center[0];
            const latitude = feature.center[1];

            geocodeByTextResult[icon] = feature;
            markers[icon] = markers[icon] || [];
            markers[icon].push({
              longitude,
              latitude,
              icon: icon as CommonIcon,
            });
            markers[icon] = uniq(markers[icon]);

            patchState({ geocodeByTextResult, markers });
          }
        },
        error: error => console.error('ERROR from GetGeocodeByText', error),
        finalize: () => dispatch(new HideSpinner()),
      })
    );
  }

  @Action(GetGeocodeByCoordinates)
  getGeocodeByCoordinates(
    { dispatch, patchState }: StateContext<MapStateModel>,
    { longitude, latitude }: GetGeocodeByCoordinates
  ) {
    dispatch(new ShowSpinner());

    return this.mapboxService.getGeocodeByCoordinates(longitude, latitude).pipe(
      tap(
        (result: GeocodeByCoordinatesResult) => {
          let feature = this.getAddressFeatureFromFeaturesList(
            result?.features
          ) as GeocodeByCoordinatesFeature;
          feature = cloneDeep(feature);

          if (!!feature) {
            const address1 = `${feature.text}, ${feature.address}`;
            const country = this.getValueOfFeatureContext(
              feature,
              ContextId.Country
            );
            const city = this.getValueOfFeatureContext(feature, ContextId.City);
            const state = this.getValueOfFeatureContext(
              feature,
              ContextId.State
            );
            const zipcode = this.getValueOfFeatureContext(
              feature,
              ContextId.Zipcode
            );
            const address: AddressForm = {
              country,
              address1,
              city,
              state,
              zipcode,
            };

            patchState({ address });
          }
        },
        error => console.error('ERROR from GetGeocodeByCoordinates', error),
        () => dispatch(new HideSpinner())
      )
    );
  }

  @Action(AddMapMarker)
  addMapMarker(
    { getState, patchState }: StateContext<MapStateModel>,
    { addressName, markerData }: AddMapMarker
  ) {
    let markers = getState().markers;

    markers = cloneDeep(markers);

    if (!!markers[addressName]) {
      markers[addressName].push(markerData);
      markers[addressName] = uniq(markers[addressName]);
    } else {
      markers[addressName] = [markerData];
    }

    patchState({ markers });
  }

  @Action(RemoveHomeMapMarker)
  removeHomeMapMarker({ getState, patchState }: StateContext<MapStateModel>) {
    const markers = cloneDeep(getState().markers);

    markers['home'] = [];

    patchState({ markers });
  }

  @Action(RemoveAllMapMarkers)
  removeAllMapMarkers({ patchState }: StateContext<MapStateModel>): void {
    patchState({ markers: {} });
  }

  @Action(AddUserLocationMarker)
  addUserLocationMarker(
    { dispatch }: StateContext<MapStateModel>,
    { customUserAddress }: AddUserLocationMarker
  ) {
    let longitude: string | number = '';
    let latitude: string | number = '';
    let icon: CommonIcon;

    dispatch([new ShowSpinner(), new RemoveHomeMapMarker()]);

    /*
      for testing of functionality of 'USE MY LOCATION' defining in a localhost env
      please change 'localhost' with '127.0.0.1' in url into browser address string
    */

    if (window?.navigator?.geolocation && !customUserAddress) {
      return window.navigator.geolocation.getCurrentPosition(
        (data: GeolocationPosition) => {
          longitude = data.coords.longitude;
          latitude = data.coords.latitude;
          icon = CommonIcon.Circle;

          dispatch([
            new AddMapMarker(icon, { longitude, latitude, icon }),
            new GetGeocodeByCoordinates(longitude, latitude),
          ]);
        },
        (error: GeolocationPositionError) => {
          dispatch(new HideSpinner());
          console.error('error from navigator:', error);
          this.notificationService.showError('ERRORS.location_permission');
        }
      );
    } else if (customUserAddress) {
      return this.mapboxService.getGeocodeByText(customUserAddress).pipe(
        tap({
          next: (result: GeocodeByTextResult) => {
            const { center } = result.features[0];

            longitude = center[0];
            latitude = center[1];
            icon = CommonIcon.Circle;

            dispatch([
              new HideSpinner(),
              new AddMapMarker(icon, { longitude, latitude, icon }),
            ]);
          },
          error: error => {
            dispatch(new HideSpinner());
            console.error('error from navigator:', error);
            this.notificationService.showError(error.message);
          },
        })
      );
    }
  }

  private getAddressFeatureFromFeaturesList(
    features: (GeocodeByTextResultFeature | GeocodeByCoordinatesFeature)[]
  ): GeocodeByTextResultFeature | GeocodeByCoordinatesFeature | undefined {
    const addressFeature = features.find(feature =>
      feature.place_type.includes(PlaceType.Address)
    );

    if (!!addressFeature) {
      return addressFeature;
    } else {
      const placeFeature = features.find(feature =>
        feature.place_type.includes(PlaceType.Place)
      );

      return placeFeature;
    }
  }

  private getValueOfFeatureContext(
    feature: GeocodeByCoordinatesFeature,
    contextId: ContextId
  ): string {
    return (
      feature.context.find(value => value.id.includes(contextId))?.text || ''
    );
  }
}
