import { NgModule } from '@angular/core';

import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { FormInputComponent } from './form-input.component';

@NgModule({
  imports: [SharedModule, CurrencyMaskModule, NgxMaskModule.forChild()],
  declarations: [FormInputComponent],
  exports: [FormInputComponent],
})
export class FormInputModule {}
