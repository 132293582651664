import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { cloneDeep } from 'lodash';

import countries from '../../../../assets/files/countries.json';

@Component({
  selector: 'rs-country-select',
  templateUrl: 'country-select.component.html',
  styleUrls: ['country-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectComponent implements OnInit {
  @Input() rsCountryControl: FormControl = new FormControl('');
  @Input() defaultCountry: string | undefined;

  public countries: any[] = cloneDeep(countries);

  ngOnInit(): void {
    if (this.defaultCountry) {
      this.rsCountryControl.reset(this.defaultCountry);
    }
  }

  public compareWithFn(code2_1: string, code2_2: string): boolean {
    if (code2_1 === code2_2) {
      return true;
    }

    const currentContry = countries.find(({ code2 }) => code2_1 === code2);

    if (!currentContry) {
      return false;
    }

    return currentContry.name === code2_2 || currentContry.code3 === code2_2;
  }
}
