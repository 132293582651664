export const TAGS_SPLITTER_SIGN = '>';

export const ALLERGEN_CATEGORY = 'allergen';

export const getNameFromTagName = (tagName: string): string => {
  const [, name] = tagName.split(TAGS_SPLITTER_SIGN);

  return name;
};

export const getTagsForSaveWithCategory = (
  tags: string[],
  category: string
) => {
  return tags.map(tag => `${category}${TAGS_SPLITTER_SIGN}${tag}`);
};
