<div
  class="rs-dialog-header"
  [ngClass]="{
    'rs-dialog-header--show-only-logo-left': showOnlyLogoLeft,
    'rs-dialog-header--show-logo-and-back-button-left':
      showOnlyLogoLeft && showBackButton
  }"
  [dir]="'languageDirection' | translate"
>
  <button
    *ngIf="!showOnlyLogoLeft || showBackButton"
    [id]="backButtonId"
    mat-icon-button
    class="rs-dialog-header__back-button"
    mat-dialog-close
    disableRipple
    [aria-label]="'Go Back' | translate"
  >
    <mat-icon svgIcon="angle-left"></mat-icon>
  </button>

  <div *ngIf="venue$ | async as venue">
    <img
      *ngIf="showLogo"
      class="rs-dialog-header__logo"
      [src]="venue.logo"
      [alt]="venue.name"
      height="48px"
      rsImageLoader
      loading="lazy"
    />
  </div>
</div>
