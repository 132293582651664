export enum Sweetness {
  dry = 'dry',
  offDry = 'off-dry',
  semiSweet = 'semi-sweet',
  mediumSweet = 'medium-sweet',
  sweet = 'sweet',
}

export enum Acidity {
  highAcid = 'high-acid',
  acidic = 'acidic',
  lowAcid = 'low',
  neutral = 'neutral',
  alkalineBasic = 'alkaline-basic',
}

export enum Tanin {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum WineBody {
  low = 'low',
  medium = 'medium',
  high = 'full',
}
