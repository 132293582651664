import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Store } from '@ngxs/store';
import { isEqual } from 'lodash';
import { combineLatest, first } from 'rxjs';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { RsAllergenPipesModule } from 'src/app/_shared/_modules/allergen-pipes.module';

import { DietaryRestrictionAddNewAllergenComponent } from '../button/add-new-allergen/add-new-allergen.component';
import { DietaryRestrictionListWrapperComponent } from './dietary-restrictions-list-wrapper.component';
import { SpicyLevelButtonComponent } from '../button/spicy-level-button/spicy-level-button.component';
import { DietaryRestrictionButtonComponent } from '../button/dietary-restrictions-button/dietary-restrictions-button.component';

import { AllergensState } from 'src/app/_shared/_ngxs/allergens.state';

import { untilDestroyed } from 'src/app/_shared/_utils/until-destroyed';
import { getAllergenLabelWithDifferentModifier } from 'src/app/_shared/_utils/allergens';

import { AllergenType } from 'src/app/_shared/_enums/allergens.enum';
import { Allergen } from 'src/app/_shared/_interfaces/item.model';

@Component({
  selector: 'rs-dietary-restrictions-list',
  standalone: true,
  imports: [
    SharedModule,
    RsAllergenPipesModule,
    DietaryRestrictionButtonComponent,
    DietaryRestrictionAddNewAllergenComponent,
    DietaryRestrictionListWrapperComponent,
    SpicyLevelButtonComponent,
  ],
  templateUrl: './dietary-restrictions-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DietaryRestrictionListComponent {
  @Input() showOnlyDefaultAllergens!: boolean;

  public readonly defaultAllergens$ = this.store.select(
    AllergensState.defaultAllergens
  );
  public readonly customAllergens$ = this.store.select(
    AllergensState.customAllergens
  );

  public readonly selectedDefaultAllergens$ = this.store.select(
    AllergensState.selectedDefaultAllergens
  );
  public readonly selectedCustomAllergens$ = this.store.select(
    AllergensState.selectedCustomAllergens
  );

  public readonly AllergenType = AllergenType;

  public defaultAllergens: Allergen[] = [];
  public customAllergens: Allergen[] = [];

  public initialSelectedAllergens: Allergen[] = [];
  public selectedDefaultAllergens: Allergen[] = [];
  public selectedCustomAllergens: Allergen[] = [];

  protected readonly destroy$ = untilDestroyed();

  @Output() allergensSelected: EventEmitter<string[]> = new EventEmitter();
  @Output() allergensUpdated: EventEmitter<boolean> = new EventEmitter();

  constructor(
    protected readonly store: Store,
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getAllergens();
  }

  protected getAllergens(): void {
    combineLatest([
      this.selectedDefaultAllergens$,
      this.selectedCustomAllergens$,
    ])
      .pipe(first())
      .subscribe(([selectedDefaultAllergens, selectedCustomAllergens]) => {
        this.initializeSelectedAllergens(
          selectedDefaultAllergens,
          selectedCustomAllergens
        );
        this.emitChanges();
      });

    this.defaultAllergens$
      .pipe(this.destroy$())
      .subscribe(
        defaultAllergens => (this.defaultAllergens = [...defaultAllergens])
      );

    this.customAllergens$
      .pipe(this.destroy$())
      .subscribe(
        customAllergens => (this.customAllergens = [...customAllergens])
      );
  }

  private initializeSelectedAllergens(
    selectedDefaultAllergens: Allergen[],
    selectedCustomAllergens: Allergen[]
  ): void {
    this.selectedDefaultAllergens = [...selectedDefaultAllergens];
    this.selectedCustomAllergens = [...selectedCustomAllergens];

    this.initialSelectedAllergens = [
      ...this.selectedDefaultAllergens,
      ...this.selectedCustomAllergens,
    ];
    this.changeDetectorRef.markForCheck();
  }

  public clearFilters(): void {
    this.selectedDefaultAllergens = [];
    this.selectedCustomAllergens = [];

    this.emitChanges();
  }

  public emitChanges(): void {
    // Note by the flow this component used only to show and emit up changes
    // so we can't save changes here
    const selectedAllergens: Allergen[] = [
      ...this.selectedDefaultAllergens,
      ...this.selectedCustomAllergens,
    ];
    const isSelectedAllergensChanged: boolean = isEqual(
      selectedAllergens,
      this.initialSelectedAllergens
    );

    this.allergensSelected.next(selectedAllergens.map(({ value }) => value));
    this.allergensUpdated.next(!isSelectedAllergensChanged);
  }

  // Note 01/22/2024:
  // Ms. Bombastic: Is it possible to select multiply spicy levels or only one?
  // martina: let's allow them to select more than one
  // Note 01/23/2024:
  // Choose allergen_free and allergen_traces flow:
  // https://rockspoon.slack.com/archives/C06C94JA1RC/p1706005181884429
  public updateSelectedAllergens(
    allergen: string,
    selected: boolean,
    isCustom: boolean = false
  ): void {
    if (selected) {
      const allergenWithDifferentModifier: string =
        getAllergenLabelWithDifferentModifier(allergen);

      this.updateSelectedAllergens(
        allergenWithDifferentModifier,
        false,
        isCustom
      );

      if (isCustom) {
        const selectedAllergen: Allergen | undefined =
          this.customAllergens.find(
            ({ value }: Allergen) => value === allergen
          );

        selectedAllergen && this.selectedCustomAllergens.push(selectedAllergen);
      } else {
        const selectedAllergen: Allergen | undefined =
          this.defaultAllergens.find(
            ({ value }: Allergen) => value === allergen
          );

        selectedAllergen &&
          this.selectedDefaultAllergens.push(selectedAllergen);
      }
    } else {
      const filter = ({ value }: Allergen) => value !== allergen;

      if (isCustom) {
        this.selectedCustomAllergens =
          this.selectedCustomAllergens.filter(filter);
      } else {
        this.selectedDefaultAllergens =
          this.selectedDefaultAllergens.filter(filter);
      }
    }

    this.emitChanges();
  }
}
