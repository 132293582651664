export class FetchDineIn {
  public static readonly type = '[Dine In] Fetch';
}

export class InviteGuests {
  public static readonly type = '[Dine In] Invite Guests';

  constructor(public phones: string[]) {}
}

export class SetDineInOrderId {
  public static readonly type = '[Dine In] Set Dine In Order';

  constructor(public orderId: string) {}
}

export class ClearDineInState {
  public static readonly type = '[Dine In] Clear';
}
