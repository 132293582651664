import { getCurrentMomentTime } from 'src/app/profile/_helpers/default-menu.helpers';

export class GetVenueConfig {
  static readonly type = '[Venue] Get venue config';

  constructor(public cwName: string) {}
}

export class CheckItemAvailability {
  static readonly type = '[Item] Check Availability';
}

export class GetVenueMenusAvailability {
  public static readonly type = '[Venue Menus] Get Availability';

  constructor(
    public selectedMomentTime: moment.Moment = getCurrentMomentTime()
  ) {}
}

export class SetFilteringActivitiesValue {
  public static readonly type =
    '[Venue MenusPage] Set Filtering Activities Value';

  constructor(public readonly value: string) {}
}

export class SetFilteringGalleryImageValue {
  public static readonly type =
    '[Venue MenusPage] Set Filtering Gallery Image Value';

  constructor(public readonly value: string) {}
}

export class GetHomePageReviews {
  public static readonly type = '[Venue Home Page] Get Reviews';
}
