import { Injectable } from '@angular/core';
import { StorageEngine } from '@ngxs/storage-plugin';

import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({ providedIn: 'root' })
export class CookieEngine implements StorageEngine {
  constructor(private readonly ssrCookieService: SsrCookieService) {}

  public get length(): number {
    return Object.keys(this.ssrCookieService.getAll()).length;
  }

  public clear(): void {
    this.ssrCookieService.deleteAll();
  }

  public getItem(key: string): string | null {
    return this.ssrCookieService.get(key);
  }

  public key(index: number): string | null {
    const keys = Object.keys(this.ssrCookieService.getAll());

    return index >= 0 && keys.length < index ? keys[index] : null;
  }

  public removeItem(key: string): void {
    this.ssrCookieService.delete(key);
  }

  public setItem(key: string, value: string): void {
    return this.ssrCookieService.set(key, value);
  }
}
