<rs-dialog rs-dialog-style="default" rs-show-close-button="true">
  <ng-container rs-dialog-header>
    <span> {{ 'PROFILE.menu.gift_cards' | translate }}</span>
  </ng-container>
  <div
    class="d-flex flex-column justify-content-center align-items-center w-100 rs-add-gift-card-dialog__scanner-wrapper"
  >
    <h4 class="mb-0 pb-3 fw-normal rs-add-gift-card-dialog__subtitle">
      {{ 'GIFT_CARDS.scan_qr_code' | translate }}
    </h4>
    <rs-qr-scanner
      class="pt-1"
      [activateCameraScanner]="true"
      (qrScanResult)="dialogRef.close($event)"
    ></rs-qr-scanner>
  </div>
</rs-dialog>
