<ng-container *ngIf="(giftCards$ | async) || []; let giftCards">
  <ng-container *ngIf="items$ | async; let items">
    <rs-cart-dialog-main-view
      [checkoutButtonDisabled]="
        !((giftCards | isSelectedItem) || (items | isSelectedItem))
      "
      [hidePayingWith]="!(giftCards.length || items.length)"
      [isLoggedIn]="isLoggedIn$ | async"
      [isShadowUser]="isShadowUser$ | async"
      [isGiftCardInCart]="isGiftCardInCart"
    >
      <rs-gift-cards-list-in-order
        *ngIf="isGiftCardInCart; else rsFoodItemsInCart"
        [items]="giftCards"
        [showCheckboxes]="true"
        (changedItemSelection)="selectItem($event)"
        (changedItemsSelection)="selectAllItems($event)"
        (decreaseItemQuantity)="decreaseItemQuantityInCart($event)"
        (toOrdering)="goToOrdering()"
      >
      </rs-gift-cards-list-in-order>

      <ng-template #rsFoodItemsInCart>
        <rs-list-of-items-in-order
          *ngIf="items.length"
          [items]="
            items | rsAddItemsAvailability: orderType:selectedOrderPeriod:menus
          "
          [amount]="(badgeNumber$ | async) || '0'"
          [showCheckboxes]="true"
          (changedItemSelection)="selectItem($event)"
          (changedItemsSelection)="selectAllItems($event)"
          (toOrdering)="goToOrdering()"
          (clickByItem)="handleClickByItem($event)"
          (increaseItemQuantity)="increaseItemQuantityInCart($event)"
          (decreaseItemQuantity)="decreaseItemQuantityInCart($event)"
        >
        </rs-list-of-items-in-order>

        <ng-contaner *ngIf="!items.length" [ngTemplateOutlet]="rsEmptyCart">
        </ng-contaner>

        <!-- TODO when suggestion is in production, you need to show suggestions
        even we don't have food items in cart, also most probably you need to chande the title for it -->
        <ng-container
          *ngIf="!isProduction && !!items.length && (isLoggedIn$ | async)"
        >
          <ng-container *ngIf="suggestions$ | async; let suggestions">
            <div
              *ngIf="suggestions?.length"
              class="mt-5 pt-3 rs-cart-dialog__suggestions"
            >
              <rs-suggestions-items-list
                [suggestedItems]="suggestions"
                [title]="'CART.Goes_Well_With' | translate"
              >
              </rs-suggestions-items-list>
            </div>
          </ng-container>
        </ng-container>
      </ng-template>
    </rs-cart-dialog-main-view>
  </ng-container>
</ng-container>

<!-- TODO update icon and text, add translations when designs are ready -->
<ng-template #rsEmptyCart>
  <div
    class="rs-cart-dialog-empty"
    [ngClass]="{
      'rs-cart-dialog-empty--guest': !(isLoggedIn$ | async)
    }"
  >
    <mat-icon
      class="rs-cart-dialog-empty__icon"
      [svgIcon]="noItemsFoundIcon"
    ></mat-icon>
    <div class="rs-cart-dialog-empty__content">
      <div class="rs-cart-dialog-empty__title">Your cart is empty</div>
      <div class="rs-cart-dialog-empty__description">
        When you add items from menu, your order will be shown right here.
      </div>
      <div class="rs-cart-dialog-empty__button">
        <button rs-style="bold" (click)="goToOrdering()">Start shopping</button>
      </div>
    </div>
  </div>
</ng-template>
