<section class="rs-payment-methods" [dir]="'languageDirection' | translate">
  <div class="rs-payment-methods__padded-section">
    <mat-button-toggle-group
      class="d-flex flex-row flex-wrap justify-content-start w-100"
      (change)="onPaymentMethodChange.emit($event)"
    >
      <ng-container *ngFor="let paymentMethod of availablePaymentMethods">
        <mat-button-toggle
          mat-button
          #tooltipElement
          *ngIf="
            !(
              paymentMethod.placeholder === paymentMethods.gift_card &&
              (isOpenTab$ | async)
            ) &&
            (paymentMethod.placeholder !== paymentMethods.apple_pay ||
              (paymentMethod.placeholder === paymentMethods.apple_pay &&
                supportApplePay))
          "
          [id]="id.checkout.paymentMethodButtons(paymentMethod.placeholder)"
          class="d-flex align-items-center flex-wrap rs-payment-methods__toggle-button"
          matTooltipShowDelay="0"
          matTooltipHideDelay="200"
          matTooltipPosition="above"
          [matTooltipClass]="'rs-checkout-tooltip'"
          [matTooltip]="'PAY_NOW.' + paymentMethod.placeholder | translate"
          [value]="paymentMethod.placeholder"
          [class.rs-payment-methods__selected]="
            paymentMethod.placeholder === selectedPaymentMethod
          "
          (change)="reactOnPaymentMethodChange(paymentMethod.placeholder)"
        >
          <div class="d-flex flex-column">
            <img
              [src]="paymentMethod.icon"
              [height]="paymentMethod.iconHeight"
              [width]="paymentMethod.iconWidth"
              alt=""
            />
            <span class="rs-payment-methods__title">
              {{ 'PAY_NOW.' + paymentMethod.placeholder | translate }}
            </span>
          </div>
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </div>

  <ng-container *ngIf="selectedPaymentMethod == paymentMethods.card">
    <ng-container *ngIf="!!selectedCard; else selectPaymentCardRef">
      <div class="rs-payment-methods__card rs-payment-methods__padded-section">
        <div class="rs-payment-methods__card-title d-flex flex-row">
          <img
            width="21px"
            height="18px"
            [src]="selectedCard.cardBrand | rsCardImageByBrand"
            loading="lazy"
            alt=""
          />

          <div
            class="rs-payment-methods__card-description d-flex flex-column ms-2"
          >
            <span class="rs-payment-methods__card-details">
              {{ selectedCard.name || selectedCard.cardholderName }}
              {{
                selectedCard.redactedCardNumber || selectedCard.cardNumber
                  | rsStarsCard
              }}
            </span>
            <span class="rs-payment-methods__card-expiration">
              {{
                'CARD.Expiration_date_only {date}'
                  | translate
                    : {
                        date:
                          selectedCard.cardExpirationDate | rsMoment: 'MM/YY'
                      }
              }}
            </span>
          </div>
        </div>

        <div class="rs-payment-methods__card-actions">
          <button rs-style="text" (click)="managePaymentCard.emit()">
            <mat-icon>edit</mat-icon>
            <span>{{ 'PAY_NOW.edit' | translate | uppercase }}</span>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</section>

<ng-template #selectPaymentCardRef>
  <div class="rs-payment-methods__padded-section">
    <rs-add-payment-card-form
      [address]="(deliveryDataAddress$ | async)!"
      (newCardDetails)="emitNewPaymentCard($event)"
    ></rs-add-payment-card-form>
  </div>
</ng-template>
