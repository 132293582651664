import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { SharedModule } from '../../../shared.module';
import { SelectAddressComponent } from '../select-address/select-address.component';
import { CarItemComponent } from './car-item/car-item.component';
import { CarFormComponent } from 'src/app/_shared/_components/car-form/car-form.component';

import { ProfileState } from 'src/app/_shared/_ngxs/profile.state';
import { Car } from 'src/app/profile/_interfaces/car.model';

@Component({
  selector: 'rs-select-car',
  standalone: true,
  imports: [SharedModule, CarItemComponent, CarFormComponent],
  templateUrl: 'select-car.component.html',
  styleUrls: ['../select-address/select-address.component.scss'],
})
export class SelectCarComponent extends SelectAddressComponent {
  @Input() selectedCar: Car | undefined | null;
  @Input() carForm: FormGroup | undefined;

  public readonly cars$ = this.store.select(ProfileState.cars);

  @Output() selectedCarChange = new EventEmitter<Car>();

  override ngOnInit() {
    this.initCarForm();
  }

  public reactOnCarClick(car: Car): void {
    this.selectedCar = car;
    this.selectedCarChange.emit(car);
  }

  private initCarForm(): void {
    this.carForm = new FormGroup({
      make: new FormControl(this.selectedCar?.make || ''),
      model: new FormControl(this.selectedCar?.model || ''),
      color: new FormControl(this.selectedCar?.color || ''),
      plate: new FormControl(this.selectedCar?.plate || ''),
      isDefault: new FormControl(this.selectedCar?.isDefault || false),
    });

    this.carForm.valueChanges
      .pipe(this.destroy$())
      .subscribe(value => this.reactOnCarClick(value as Car));
  }
}
