<form
  [formGroup]="form"
  [class.ng-invalid]="isInvalid"
  [class.rs-maximum-height]="label"
  class="rs-input__form"
>
  <span *ngIf="label" class="rs-input__label">
    {{ label }}
  </span>
  <button
    class="rs-input"
    [class.rs-input-rtl]="canBeRightToLeft"
    [class.ltr]="
      type === InputOptions.Email ||
      type === InputOptions.Card ||
      type === InputOptions.Website
    "
    tabindex="-1"
    [isActive]="isActive"
    (rsClickOutside)="onOutsideClick()"
    [ngClass]="[
      disabled ? 'rs-input-disabled' : '',
      isInvalid && !disabled ? 'rs-input-invalid' : '',
      isValid && !disabled ? 'rs-input-valid' : ''
    ]"
  >
    <ng-container *ngIf="type === InputOptions.Website">
      <div
        class="d-flex align-items-center cursor-pointer"
        (click)="toggleWebsiteDropdown()"
      >
        <span class="rs-input__website-http me-1">{{ websiteOption }}</span>
        <mat-icon
          class="rs-input-icon"
          [class.rs-input-icon-up]="isWebsiteDropdownOpen"
          [svgIcon]="angleDown"
        ></mat-icon>
      </div>

      <div class="rs-input__website-separator-wrapper">
        <div class="rs-input__website-separator"></div>
      </div>
    </ng-container>

    <div
      class="rs-input__prefix"
      *ngIf="type !== InputOptions.Website && (iconType || cardBrand)"
    >
      <ng-container *ngIf="iconType && !cardBrand">
        <mat-icon
          class="rs-input__icon"
          [ngClass]="iconType === dollar ? 'rs-input__icon-dollar' : ''"
          [svgIcon]="iconType"
        ></mat-icon>
      </ng-container>
      <ng-container *ngIf="cardBrand">
        <img
          width="20px"
          src="{{ cardBrand | rsCardImageByBrand }}"
          alt=""
          loading="lazy"
        />
      </ng-container>
    </div>

    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="InputOptions.Default">
        <input
          #defaultInput
          class="rs-input__text-field m-0"
          [class.rs-input__text-field-padding]="applyPadding"
          matInput
          [type]="inputType"
          [required]="required"
          [formControl]="formControl"
          [placeholder]="placeholder"
          [min]="min"
          [max]="max"
          (input)="reactOnInput()"
          (focus)="reactOnFocus()"
          (keydown.enter)="reactOnKeyDownEnter($event)"
          (keydown.tab)="reactOnKeyDownTab()"
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputOptions.Email">
        <input
          #emailInput
          dir="ltr"
          class="rs-input__text-field m-0 ms-2"
          [class.rs-input__text-field-padding]="applyPadding"
          matInput
          type="email"
          [required]="required"
          autocomplete="email"
          [formControl]="formControl"
          [placeholder]="placeholder"
          (input)="reactOnInput()"
          (focus)="reactOnFocus()"
          (keydown.enter)="reactOnKeyDownEnter($event)"
          (keydown.tab)="reactOnKeyDownTab()"
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputOptions.Website">
        <input
          #websiteInput
          class="rs-input__text-field m-0 ms-2"
          [class.rs-input__text-field-padding]="applyPadding"
          matInput
          type="text"
          [required]="required"
          [formControl]="formControl"
          [placeholder]="placeholder"
          (input)="reactOnInput()"
          (focus)="reactOnFocus()"
          (keydown.enter)="reactOnKeyDownEnter($event)"
          (keydown.tab)="reactOnKeyDownTab()"
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputOptions.Sales">
        <input
          #salesInput
          class="rs-input__text-field m-0 rs-input-sales"
          [class.rs-input__text-field-padding]="applyPadding"
          matInput
          type="text"
          currencyMask
          [required]="required"
          [formControl]="formControl"
          [options]="currencyOptions"
          [placeholder]="placeholder"
          (input)="reactOnInput()"
          (focus)="reactOnFocus()"
          (keydown.enter)="reactOnKeyDownEnter($event)"
          (keydown.tab)="reactOnKeyDownTab()"
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputOptions.Card">
        <input
          #cardInput
          class="rs-input__text-field ms-2"
          [ngClass]="[hasClearButton || isValid || isInvalid ? 'pe-3' : '']"
          matInput
          type="text"
          rsCreditCardMask
          [required]="required"
          [formControl]="formControl"
          [placeholder]="placeholder"
          (input)="reactOnInput()"
          (focus)="reactOnFocus()"
          (keydown.enter)="reactOnKeyDownEnter($event)"
          (keydown.tab)="reactOnKeyDownTab()"
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputOptions.Password">
        <input
          class="rs-input__text-field m-0 me-2"
          matInput
          [type]="ifVisibilityOff ? 'password' : 'text'"
          [required]="required"
          [formControl]="formControl"
          [placeholder]="placeholder"
          (focus)="reactOnFocus()"
          (keydown.enter)="reactOnKeyDownEnter($event)"
          (keydown.tab)="reactOnKeyDownTab()"
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputOptions.Masked">
        <input
          #maskedInput
          class="rs-input__text-field m-0"
          [class.rs-input__text-field-padding]="applyPadding"
          [textMask]="textMask"
          matInput
          [required]="required"
          [formControl]="formControl"
          [placeholder]="placeholder"
          (focus)="reactOnFocus()"
          (keydown.enter)="reactOnKeyDownEnter($event)"
          (keydown.tab)="reactOnKeyDownTab()"
        />
      </ng-container>
      <ng-container *ngSwitchCase="InputOptions.CVV">
        <input
          #cvvInput
          class="rs-input__text-field m-0"
          [class.rs-input__text-field-padding]="applyPadding"
          [textMask]="cardCVVConfig"
          type="text"
          matInput
          [required]="required"
          [formControl]="formControl"
          [placeholder]="placeholder"
          (focus)="reactOnFocus()"
          (keydown.enter)="reactOnKeyDownEnter($event)"
          (keydown.tab)="reactOnKeyDownTab()"
        />
      </ng-container>
    </ng-container>

    <span class="rs-input__suffix" *ngIf="showClearButton">
      <rs-input-action-icon
        [isClearIcon]="true"
        tabindex="-1"
        (click)="clearValue()"
      ></rs-input-action-icon>
    </span>

    <span
      class="rs-input__suffix rs-cursor-pointer"
      *ngIf="
        type === InputOptions.Password && !isInvalid && !disabled && !isValid
      "
    >
      <rs-input-action-icon
        (click)="changeVisibility()"
        [icon]="ifVisibilityOff ? visibility_off : visibility"
      >
      </rs-input-action-icon>
    </span>

    <span class="rs-input__suffix" *ngIf="isInvalid">
      <mat-icon
        class="rs-input__icon rs-input__icon-error"
        [svgIcon]="error"
      ></mat-icon>
    </span>

    <span class="rs-input__suffix" *ngIf="isValid && !disabled">
      <mat-icon
        class="rs-input__icon rs-input__icon-success"
        [svgIcon]="success"
      ></mat-icon>
    </span>

    <div
      *ngIf="type === InputOptions.Website"
      class="rs-input__drop-down"
      [@dropdown]="isWebsiteDropdownOpen ? 'open' : 'closed'"
    >
      <div
        class="rs-input__drop-down-item"
        *ngFor="let option of websiteOptions"
        [ngClass]="
          option === websiteOption ? 'rs-input__drop-down-item--active' : ''
        "
        (click)="selectedWebsiteOption(option)"
      >
        {{ option }}
      </div>
    </div>
  </button>

  <span *ngIf="hint && !isInvalid && !isValid" class="rs-input__hint">
    {{ hint }}
  </span>

  <ng-container *ngIf="isValid || isInvalid">
    <ng-container *ngIf="!injectedWarning && !injectedSuccess">
      <span
        *ngIf="hasSuccessStatus && successMessage && isValid && !disabled"
        class="rs-input__success"
      >
        {{ successMessage }}
      </span>

      <mat-error *ngIf="formControl?.errors?.['required'] && isInvalid">
        {{ 'VALIDATION.REQUIRED' | translate }}
      </mat-error>

      <ng-container *ngIf="isInvalid && !formControl?.errors?.['required']">
        <mat-error *ngIf="type === InputOptions.Masked">
          {{ formControl?.errors | rsMaskError: maskErrors | translate }}
        </mat-error>

        <ng-container *ngIf="type === InputOptions.CVV">
          <mat-error *ngIf="formControl?.errors?.['minLength']">
            {{ 'VALIDATION.length' | translate }}
          </mat-error>
        </ng-container>

        <mat-error *ngIf="formControl?.errors?.['passwordsMatch']">
          {{ 'CREATE_PASSWORD.errors.passwordsMatch' | translate }}
        </mat-error>

        <mat-error *ngIf="type === InputOptions.Email">
          {{ 'VALIDATION.EMAIL' | translate }}
        </mat-error>

        <ng-container
          *ngIf="
            type === InputOptions.Default && inputType === DefaultOptions.Time
          "
        >
          <mat-error *ngIf="convertedTimeMin && convertedTimeMax">
            {{
              'VALIDATION.time_range {min} {max}'
                | translate: { min: convertedTimeMin, max: convertedTimeMax }
            }}
          </mat-error>
          <mat-error *ngIf="convertedTimeMin && !convertedTimeMax">
            {{
              'VALIDATION.time_min {min}' | translate: { min: convertedTimeMin }
            }}
          </mat-error>
          <mat-error *ngIf="!convertedTimeMin && convertedTimeMax">
            {{
              'VALIDATION.time_max {max}' | translate: { max: convertedTimeMax }
            }}
          </mat-error>
        </ng-container>

        <ng-container
          *ngIf="
            type === InputOptions.Default && inputType === DefaultOptions.Number
          "
        >
          <mat-error *ngIf="min && max">
            {{
              'VALIDATION.number_range {min} {max}'
                | translate: { min: min, max: max }
            }}
          </mat-error>
          <mat-error *ngIf="min && !max">
            {{ 'VALIDATION.number_min {min}' | translate: { min: min } }}
          </mat-error>
          <mat-error *ngIf="!min && max">
            {{ 'VALIDATION.number_max {max}' | translate: { max: max } }}
          </mat-error>
        </ng-container>

        <ng-container *ngIf="type === InputOptions.Card">
          <mat-error *ngIf="formControl?.errors?.['minLength']">
            {{ 'VALIDATION.card_length' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl?.errors?.['maxLength']">
            {{ 'VALIDATION.card_length' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl?.errors?.['invalid']">
            {{ 'VALIDATION.card_number' | translate }}
          </mat-error>
          <mat-error *ngIf="formControl?.errors?.['wrongType']">
            {{ 'VALIDATION.wrong_card_type' | translate }}
          </mat-error>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="injectedWarning || injectedSuccess">
      <span
        *ngIf="injectedSuccess && isValid"
        class="rs-input__success"
        [innerHTML]="injectedSuccess"
      >
      </span>

      <mat-error *ngIf="injectedWarning && isInvalid">
        {{ injectedWarning }}
      </mat-error>
    </ng-container>
  </ng-container>
</form>
