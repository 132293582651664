import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

import { Store } from '@ngxs/store';
import { filter, take } from 'rxjs';

import { SharedModule } from 'src/app/_shared/_modules/shared.module';
import { CreatePasswordFormComponent } from '../../create-password-form/create-password-form.component';

import {
  confirmPasswordValidator,
  passwordStrengthValidator,
} from '../../create-password-form/_validators/password.validators';

import { ForgetPassword } from 'src/app/_shared/_ngxs/authentication.actions';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';

@Component({
  selector: 'rs-authorisation-create-password',
  standalone: true,
  imports: [SharedModule, CreatePasswordFormComponent],
  templateUrl: 'authorisation-create-password.component.html',
  styleUrls: ['../authorisation.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorisationCreatePasswordComponent {
  public venue$ = this.store.select(VenueState.venue);

  public formGroup!: FormGroup;

  public isResetPassword!: boolean;
  public formIsValid!: boolean;
  private password!: string;
  private confirmPassword!: string;

  private token!: string;

  @Output() passwordSubmitted: EventEmitter<string> = new EventEmitter();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: any
  ) {}

  ngOnInit(): void {
    this.initializeForm();

    this.checkIfResetPasswordPage();
  }

  private checkIfResetPasswordPage(): void {
    this.isResetPassword =
      this.document?.location?.href.indexOf('resetPassword') > -1;

    this.isResetPassword &&
      this.activatedRoute.queryParams
        .pipe(
          filter((params: Params) => !!params['token']),
          take(1)
        )
        .subscribe(({ token }: Params) => (this.token = token));
  }

  public updateValues({
    password,
    confirmPassword,
  }: {
    password: string;
    confirmPassword: string;
  }): void {
    this.password = password;
    this.confirmPassword = confirmPassword;
  }

  public submitPassword(): void {
    if (this.isResetPassword) {
      this.resetPassword();
    } else {
      this.signUp();
    }
  }

  protected signUp(): void {
    this.passwordSubmitted.next(this.password);
  }

  private resetPassword(): void {
    this.store.dispatch(
      new ForgetPassword({
        password: this.password,
        confirmation: this.confirmPassword,
        token: this.token,
      })
    );
  }

  public getControlErrors(control: AbstractControl | null): string[] {
    return Object.keys(control?.errors || {});
  }

  private initializeForm(): void {
    this.formGroup = this.formBuilder.group(
      {
        password: ['', [Validators.required, passwordStrengthValidator]],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [confirmPasswordValidator],
      }
    );
  }
}
